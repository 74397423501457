import React, { useEffect, useState, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import OrderListComp from "../Order/OrderListComp";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { RiAlertFill } from "react-icons/ri";
import "react-circular-progressbar/dist/styles.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { RxDropdownMenu } from "react-icons/rx";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import EsimDetailsService from "../../Services/EsimDetailsService";
import QRCode from "qrcode.react";
import { SlClose } from "react-icons/sl";
import { Doughnut } from "react-chartjs-2";
import Loader from "../Alert_Popup/Loader";
import ContactUsForm from "../Common/ContactUsForm";
import toast, { Toaster } from "react-hot-toast";
import { IoMdShare } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import { MdContentCopy } from "react-icons/md";
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import {
  CSmartTable,
  CButton,
  CCollapse,
  CCardBody,
  CAvatar,
} from "@coreui/react-pro";
import useScrollToTop from "../custom_hooks/useScrollToTop";

const PackageList = ({ validity, data, status }) => {
  return (
    <div className="col-12 cards-bg mt-2 p-2">
      <div className="row m-0">
        <div className="col-4 d-flex justify-content-center align-items-center">
          <span className={`font-weight-bold`}>{status}</span>
        </div>
        <div className="col-4 d-flex flex-column justify-content-between align-items-center">
          <div className="text-center w-100">
            <p className="m-0 font-weight-bold h5 text-center">
              {validity} Days
            </p>
          </div>
          <div className="text-center w-100">
            <p className="m-0 mt-1 text-center">validity</p>
          </div>
        </div>
        <div className="col-4 d-flex flex-column justify-content-between align-items-center">
          <div className="text-center w-100">
            <p className="m-0 font-weight-bold h5">{data}</p>
          </div>
          <div className="text-center w-100">
            <p className="m-0 mt-1">DATA</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const EsimDetail = (props) => {
  useScrollToTop()
  const history = useHistory();
  const [openAndroidGuide, setOpenAndroidGuide] = useState(false);
  const [openIosGuide, setOpenIosGuide] = useState(false);

  const [qrBtnActive, setQrBtnActive] = useState(true);
  const [esimDetail, setEsimDetail] = useState(null);

  const [manualBtnActive, setManualBtnActive] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);

  const installationPopup = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const coverageDropdownRef = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  const [smdpCopied, setSmdpCopied] = useState(false);
  const [androidACCopied, setAndroidACCopiedCopied] = useState(false);

  const columns = [
    {
      key: "plan_name",
      _style: { width: "20%" },
    },
    {
      key: "status",
      _style: { width: "15%" },
    },
    {
      key: "data_quota",
      _style: { width: "10%" },
    },
    {
      key: "data_usage",
      _style: { width: "10%" },
    },
    {
      key: "validity",
      _style: { width: "5%" },
    },
    {
      key: "order_type",
      _style: { width: "10%" },
    },
    {
      key: "created",
      _style: { width: "20%" },
    },
  ];
  const usersData = [
    {
      id: 1,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 2,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 3,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 4,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 5,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 6,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 7,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 8,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 9,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 10,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 11,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 12,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 13,
      data_quota: "100MB",
      plan_name: "UK 100MB 5Days",
      created: "2022/01/01",
      data_usage: "0.00MB",
      validity: "5 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 14,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
    {
      id: 15,
      data_quota: "300MB",
      plan_name: "UK 300MB 5Days",
      created: "2022/12/01",
      data_usage: "0.00MB",
      validity: "10 Days",
      status: "NOT ACTIVE",
    },
  ];




  const handleAPNCopyClick = () => {
    navigator.clipboard.writeText(esimDetail.apnValue);
    toast.success("APN copied successfully!");
  };

  const downloadQRCode = () => {
    const modifiedPlanName = esimDetail.esimPlan.plan.planName.replace(/ /g, '');
    const countryRegionName = esimDetail.esimPlan.plan.region.regionId !== 0 ? esimDetail.esimPlan.plan.region.regionDescription : esimDetail.esimPlan.plan.country.countryDescription ? esimDetail.esimPlan.plan.country.countryDescription : 'Global';
    const canvas = document.getElementById("targetQRcode");
    const pngUrl = canvas
      .toDataURL("image/jpeg")
      .replace("image/jpeg", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `GKTeSIM_${esimDetail.esimId}_${countryRegionName}_${modifiedPlanName}.jpeg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    esimDetailApi();
  }, [props.match.params.esimId]);

  const esimDetailApi = () => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      console.log("props.match.params.esimId",props.match.params.esimId);
      console.log("props.match.params.planId",props.match.params.planId);
      EsimDetailsService.esimDetail(props.match.params.esimId, props.match.params.planId)
        .then((response) => {
          if (response.status === 200) {
            setEsimDetail(response.data);
            setIsLoading(false);
            setStatusCode(200);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setIsLoading(false);
            setMessage("Something went wrong, Please try again later");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode(401);
                break;
              case 403:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode(403);
                break;
              case 500:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode(500);
                break;
              case 404:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode(404);
                break;
              case 406:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode(406);
                break;
              case 412:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode(412);
                break;
              default:
                setMessage("Something went wrong, Please try again later");
                setIsLoading(false);
                setStatusCode();
                break;
            }
          }
        });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const CoverageDropdown = () => {
    let [country, setCountry] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (esimDetail !== null) {
        setCountry(esimDetail.esimPlan.plan.planCountry);
      }
    }, []);

    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="dropdowns-modal">
        <div className="coverage-dropdown">
          <div className="w-100" ref={coverageDropdownRef}>
            <h4>
              Coverage is provided by{" "}
              <span>{esimDetail.esimPlan.plan.planCountry.length}</span>{" "}
              countries
            </h4>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className="coverage-dropdown-list mt-2">
              <ul>
                {searchTerm && filteredCountries.length === 0 ? (
                  <li className="no-results">No results found</li>
                ) : (
                  filteredCountries.map((item, index) => (
                    <li key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div>
                            <span
                              key={index}
                              className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                            ></span>
                          </div>
                          <div className="ml-2">
                            <p>{item.countryDescription}</p>
                          </div>
                        </div>
                        {item.planCountryNetwork !== null ? (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            {item.planCountryNetwork.map(
                              (network, networkIndex) => (
                                <span key={networkIndex}>{network}</span>
                              )
                            )}
                          </div>
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <span></span>
                          </div>
                        )}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <SlClose className="modal-close-icon" />
        </div>
      </div>
    );
  };

  const openCoverageDropdown = () => {
    setIsCoverageDropdownOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const closeCoverageDropdown = () => {
    setIsCoverageDropdownOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const openAndroidInstallationPopup = () => {
    setOpenAndroidGuide(true);
    setOpenIosGuide(false);
    document.body.style.overflowY = "hidden";
  };
  const openIosInstallationPopup = () => {
    setOpenAndroidGuide(false);
    setOpenIosGuide(true);
    document.body.style.overflowY = "hidden";
  };
  const closeInstallationPopup = () => {
    setOpenAndroidGuide(false);
    setOpenIosGuide(false);
    setQrBtnActive(true);
    setManualBtnActive(false);
    document.body.style.overflowY = "auto";
  };

  const handleInstallationPopupClick = (event) => {
    if (
      openAndroidGuide ||
      (openIosGuide &&
        installationPopup.current &&
        !installationPopup.current.contains(event.target))
    ) {
      // closeInstallationPopup();
    }
  };


  // Extract relevant information from esimDetails
  const esimPlanRecharge = esimDetail?.esimPlan?.esimPlanRecharge || [];
  const planName = esimDetail?.esimPlan?.plan?.planName || "";
  const validity = esimDetail?.esimPlan?.plan?.validity || "";

  // Map esimPlanRecharge array to match the structure of usersData
  const dynamicUserData = esimPlanRecharge.map((recharge, index) => ({
    id: index + 1,
    plan_name: planName,
    status: recharge.dataStatus || "UNKNOWN",
    data_quota: recharge.totalData || "0.00 MB",
    data_usage: recharge.dataUsed || "0.00 MB",
    validity: `${validity} Days`,
    order_type: recharge.esimPlanRechargeOrderType.orderTypeDesc || "UNKNOWN",
    created: `${recharge.createdDate} GMT` || "N/A",
  }));

  useEffect(() => {
    document.addEventListener("click", handleInstallationPopupClick);

    return () => {
      document.removeEventListener("click", handleInstallationPopupClick);
    };
  }, [openAndroidGuide, openIosGuide]);

  ////////////////////Installation popup////////////////////

  const InstallationPopup = ({ esimDetail }) => {
    const instruction = {
      android: {
        googlepixel: {
          qr: {
            step1: [
              "Go to “Settings”, tap “Network & Internet”, then tap “(+)” next to the SIMs section, if it’s not available tap “SIMs/Mobile network” on your device.",
              "Tap “Download a SIM instead?”, then tap “Next”.",
              "Tap “Use a different network” if you need to confirm your network.",
              "Scan the QR code available on the GKT eSIM app, then tap “Download/Activate”.",
              "Tap “Settings/Done” when you see the Download Finished screen.",
            ],
            step2: [
              "Go to “SIMs”, then select the recently downloaded eSIM on your device.",
              "Enable the “Use SIM” toggle, then tap “Yes”.",
              "Enable the “Mobile data” toggle.",
              "Enable the “Roaming” toggle, then tap “OK”.",
              "Tap the “Automatically select network” toggle then choose the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
              "Tap “Access Point Names”, then tap “(+)”.",
              "Enter the APN available on the GKT eSIM app by copying it into the Name and APN fields.",
              "Tap the three dots on the right top of the screen, tap “Save”, then select the APN you have saved by clicking the radio button.",
            ],
          },
          manual: {
            step1: [
              "Go to “Settings”, tap “Network & Internet”, then tap “(+)” next to the SIMs section, if it’s not available tap “SIMs/Mobile network” on your device.",
              "Tap “Download a SIM instead?”, then tap “Next”.",
              "Tap “Use a different network” if you need to confirm your network.",
              "Tap “Need help?”, then tap “Enter it manually”.",
              <div>
                {"Enter the SM-DP+ Address & Activation Code "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.activationCode)
                      .then(() => {
                        toast.success("Activation code copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                >
                  {esimDetail.activationCode} <MdContentCopy />
                </span>
                {" tap “Continue”, then tap “Download/Activate”."}
              </div>,
              "Tap “Settings/Done” when you see the Download Finished screen.",
            ],
            step2: [
              "Go to “SIMs”, then select the recently downloaded eSIM on your device.",
              "Enable the “Use SIM” toggle, then tap “Yes”.",
              "Enable the “Mobile data” toggle.",
              "Enable the “Roaming” toggle, then tap “OK”.",
              "Tap the “Automatically select network” toggle then choose the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
              "Tap “Access Point Names”, then tap “(+)”.",
              "Enter the APN available on the GKT eSIM app by copying it into the Name and APN fields.",
              "Tap the three dots on the right top of the screen, tap “Save”, then select the APN you have saved by clicking the radio button.",
            ],
          },
        },
        samsung: {
          qr: {
            step1: [
              "Go to “Settings”, tap “Connections”, then tap “SIM card manager” on your device.",
              "Tap “Add mobile plan”, then tap “Scan carrier QR code”.",
              "Scan the QR code available on the GKT eSIM app, then tap “Confirm”.",
            ],
            step2: [
              "Go to “SIM card manager”, then turn on your eSIM by enabling the toggle, then tap “OK” on your device.",
              "Select your eSIM for mobile data.",
              "Go to “Connections”, then tap “Mobile networks”.",
              "Enable the “Data roaming” toggle.",
              "Tap “Access Point Names”, then tap “Add” on the right top of the screen.",
              "Enter the APN available on the GKT eSIM app by copying it into the Name and APN fields.",
              "Tap the three dots on the right top of the screen, tap “Save”, then select the APN you have saved by clicking the radio button.",
              "Tap “Network operators”, tap the “Select automatically” toggle then choose the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
            ],
          },
          manual: {
            step1: [
              "Go to “Settings”, tap “Connections”, then tap “SIM card manager” on your device.",
              "Tap “Add mobile plan”, then tap “Scan carrier QR code”.",
              "Tap “Enter activation code”.",
              <div>
                {"Enter the SM-DP+ Address & Activation Code "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.activationCode)
                      .then(() => {
                        toast.success("Activation code copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                >
                  {esimDetail.activationCode} <MdContentCopy />
                </span>
                {" tap “Connect”, then tap “Confirm”."}
              </div>,
            ],
            step2: [
              "Go to “SIM card manager”, then turn on your eSIM by enabling the toggle, then tap “OK” on your device.",
              "Select your eSIM for mobile data.",
              "Go to “Connections”, then tap “Mobile networks”.",
              "Enable the “Data roaming” toggle.",
              "Tap “Access Point Names”, then tap “Add” on the right top of the screen.",
              "Enter the APN available on the GKT eSIM app by copying it into the Name and APN fields.",
              "Tap the three dots on the right top of the screen, tap “Save”, then select the APN you have saved by clicking the radio button.",
              "Tap “Network operators”, tap the “Select automatically” toggle then choose the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
            ],
          },
        },
        otherdevices: {
          qr: {
            step1: [
              "Go to “Settings”, tap “Connections”, then tap “SIM card manager” on your device.",
              "Tap “Add mobile plan”, then tap “Scan carrier QR code”.",
              "Scan the QR code available on the GKT eSIM app, then tap “Confirm”.",
            ],
            step2: [
              "Go to “SIM card manager”, then turn on your eSIM by enabling the toggle, then tap “OK” on your device.",
              "Select your eSIM for mobile data.",
              "Go to “Connections”, then tap “Mobile networks”.",
              "Enable the “Data roaming” toggle.",
              "Tap “Access Point Names”, then tap “Add” on the right top of the screen.",
              "Enter the APN available on the GKT eSIM app by copying it into the Name and APN fields.",
              "Tap the three dots on the right top of the screen, tap “Save”, then select the APN you have saved by clicking the radio button.",
              "Tap “Network operators”, tap the “Select automatically” toggle then choose the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
            ],
          },
          manual: {
            step1: [
              "Go to “Settings”, tap “Connections”, then tap “SIM card manager” on your device.",
              "Tap “Add mobile plan”, then tap “Scan carrier QR code”.",
              "Tap “Enter activation code”.",
              <div>
                {"Enter the SM-DP+ Address & Activation Code "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.activationCode)
                      .then(() => {
                        toast.success("Activation code copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                >
                  {esimDetail.activationCode} <MdContentCopy />
                </span>
                {" tap “Connect”, then tap “Confirm”."}
              </div>,
            ],
            step2: [
              "Go to “SIM card manager”, then turn on your eSIM by enabling the toggle, then tap “OK” on your device.",
              "Select your eSIM for mobile data.",
              "Go to “Connections”, then tap “Mobile networks”.",
              "Enable the “Data roaming” toggle.",
              "Tap “Access Point Names”, then tap “Add” on the right top of the screen.",
              "Enter the APN available on the GKT eSIM app by copying it into the Name and APN fields.",
              "Tap the three dots on the right top of the screen, tap “Save”, then select the APN you have saved by clicking the radio button.",
              "Tap “Network operators”, tap the “Select automatically” toggle then choose the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
            ],
          },
        },
      },
      ios: {
        ios15: {
          qr: {
            step1: [
              "Go to “Settings”, tap “Cellular/Mobile Data”, then tap “Add Cellular/Data Plan” on your device.",
              "Scan the QR code available on the GKT eSIM app, then tap “Add Cellular/Data Plan”.",
              "Choose a label for your new eSIM plan.",
              "Choose “Primary” for your default line, then tap “Continue”.",
              "Choose the “Primary” you want to use with iMessage and FaceTime for your Apple ID, then tap “Continue”.",
              "Choose your new eSIM plan for cellular/mobile data, then tap “Continue”.",
            ],
            step2: [
              "Go to “Cellular/Mobile Data”, then select the recently downloaded eSIM on your device. Enable the “Turn On This Line” toggle, then select your new eSIM plan for cellular/mobile data.",
              "Tap “Network Selection”, disable the “Automatic” toggle, then select the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
              "Tap “Cellular/Mobile Data Network”, then enter the APN available on the GKT eSIM app in all APN fields by copying it.",
              "Enable the “Data Roaming” toggle for your new eSIM plan.",
            ],
          },
          manual: {
            step1: [
              "Go to “Settings”, tap “Cellular/Mobile Data”, then tap “Add Cellular/Data Plan” on your device.",
              <div>
                {"Tap “Enter Details Manually” and enter the SM-DP+ Address "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.smdpAddress)
                      .then(() => {
                        toast.success("SM-DP address copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                  style={{
                    color: "#90ACF4",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {esimDetail.smdpAddress} <MdContentCopy />
                </span>
                {" and Activation Code "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.manualCode)
                      .then(() => {
                        toast.success("Activation code copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                >
                  {esimDetail.manualCode} <MdContentCopy />
                </span>
                {
                  " , tap “Next”, then tap “Continue” twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap “Done”."
                }
              </div>,
              "Choose a label for your new eSIM plan.",
              "Choose “Primary” for your default line, then tap “Continue”.",
              "Choose the “Primary” you want to use with iMessage and FaceTime for your Apple ID, then tap “Continue”.",
              "Choose your new eSIM plan for cellular/mobile data, then tap “Continue”.",
            ],
            step2: [
              "Go to “Cellular/Mobile Data”, then select the recently downloaded eSIM on your device. Enable the “Turn On This Line” toggle, then select your new eSIM plan for cellular/mobile data.",
              "Tap “Network Selection”, disable the “Automatic” toggle, then select the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
              "Tap “Cellular/Mobile Data Network”, then enter the APN available on the GKT eSIM app in all APN fields by copying it.",
              "Enable the “Data Roaming” toggle for your new eSIM plan.",
            ],
          },
        },
        ios16: {
          qr: {
            step1: [
              "Go to Settings > Cellular/Mobile Data > Add eSIM or Set up Cellular/Mobile Service > Use QR Code on your device.",
              "Scan the QR code available on the GKT eSIM app, then tap “Continue” twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap “Done”.",
              "Choose a label for your new eSIM plan.",
              "Choose “Primary” for your default line, then tap “Continue”.",
              "Choose the “Primary” you want to use with iMessage and FaceTime for your Apple ID, then tap “Continue”.",
              "Choose your new eSIM plan for cellular/mobile data, then tap “Continue”.",
            ],
            step2: [
              "Go to “Cellular/Mobile Data”, then select the recently downloaded eSIM on your device. Enable the “Turn On This Line” toggle, then select your new eSIM plan for cellular/mobile data.",
              "Tap “Network Selection”, disable the “Automatic” toggle, then select the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
              "Tap “Cellular/Mobile Data Network”, then enter the APN available on the GKT eSIM app in all APN fields by copying it.",
              "Enable the “Data Roaming” toggle for your new eSIM plan.",
            ],
          },
          manual: {
            step1: [
              "Go to Settings > Cellular/Mobile Data > Add eSIM or Set up Cellular/Mobile Service > Use QR Code on your device.",
              <div>
                {"Tap “Enter Details Manually” and enter the SM-DP+ Address "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.smdpAddress)
                      .then(() => {
                        toast.success("SM-DP address copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                  style={{
                    color: "#90ACF4",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {esimDetail.smdpAddress} <MdContentCopy />
                </span>
                {" and Activation Code "}
                <span
                  onClick={() => {
                    navigator.clipboard
                      .writeText(esimDetail.manualCode)
                      .then(() => {
                        toast.success("Activation code copied successfully!");
                      })
                      .catch((err) =>
                        console.error("Error copying to clipboard: ", err)
                      );
                  }}
                  className="dynamic-links"
                >
                  {esimDetail.manualCode} <MdContentCopy />
                </span>
                {
                  " , tap “Next”, then tap “Continue” twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap “Done”."
                }
              </div>,
              "Choose a label for your new eSIM plan.",
              "Choose “Primary” for your default line, then tap “Continue”.",
              "Choose the “Primary” you want to use with iMessage and FaceTime for your Apple ID, then tap “Continue”.",
              "Choose your new eSIM plan for cellular/mobile data, then tap “Continue”.",
            ],
            step2: [
              "Go to “Cellular/Mobile Data”, then select the recently downloaded eSIM on your device. Enable the “Turn On This Line” toggle, then select your new eSIM plan for cellular/mobile data.",
              "Tap “Network Selection”, disable the “Automatic” toggle, then select the supported network available on the GKT eSIM app manually if your eSIM has connected to the wrong network.",
              "Tap “Cellular/Mobile Data Network”, then enter the APN available on the GKT eSIM app in all APN fields by copying it.",
              "Enable the “Data Roaming” toggle for your new eSIM plan.",
            ],
          },
        },
      },
    };

    // Android options
    const AndroidOptions = [
      {
        id: 0,
        name: "Google pixel",
      },
      {
        id: 1,
        name: "Samsung",
      },
      {
        id: 2,
        name: "Other devices",
      },
    ];
    // IOS options
    const IosOptions = [
      {
        id: 0,
        name: "IOS 15 & Previous version",
      },
      {
        id: 1,
        name: "IOS 16",
      },
    ];
    // Installation method
    const installationMethod = [
      {
        id: 0,
        type: "QR Code",
      },
      {
        id: 1,
        type: "Manual",
      },
    ];

    const [deviceOption, setDeviceOption] = useState("");
    const [deviceValue, setDeviceValue] = useState(null);
    const [deviceDropdownOpen, setDeviceDropdownOpen] = useState(false);

    const [methodOption, setMethodOption] = useState("");
    const [methodValue, setMethodValue] = useState(null);
    const [methodDropdownOpen, setMethodDropdownOpen] = useState(false);

    // Progressbar
    const [deviceStep, setDeviceStep] = useState(false);
    const [installMode, setInstallMode] = useState(false);
    const [instructionStep, stepInstructionStep] = useState(false);

    const [mainInstruction, setMainInstruction] = useState({});
    const [stepOneCount, setStepOneCount] = useState(0);
    const [stepTwoCount, setStepTwoCount] = useState(0);
    const [totalStepCount, stepTotalStepCount] = useState(0);

    const [currentStep, setCurrentStep] = useState(0);

    const [stepOneContent, setStepOneContent] = useState([]);
    const [stepTwoContent, setStepTwoContent] = useState([]);

    const totalStepContent = [...stepOneContent, ...stepTwoContent];

    const [stepOneActive, setStepOneActive] = useState(true);
    const [stepTwoActive, setStepTwoActive] = useState(false);

    const [stepOneAccount, setStepOneAccount] = useState(1);
    const [stepTwoAccount, setStepTwoAccount] = useState(1);

    const [currentStatus, setCurrentStatus] = useState("");

    const dropdownDeviceRef = useRef(null);
    const dropdownMethodRef = useRef(null);

    const [openInsideDropdown, setOpenInsideDropdown] = useState(false);

    const handleDeviceType = (option, id) => {
      setDeviceOption(option);
      setDeviceValue(id);
      setDeviceDropdownOpen(false);
      if (id != null) {
        setDeviceStep(true);
        setCurrentStep(0);
      } else {
      }
    };

    const toggleDeviceDropdown = () => {
      setDeviceDropdownOpen(!deviceDropdownOpen);
    };

    const handleInstallMethod = (option, id) => {
      setMethodOption(option);
      setMethodValue(id);
      setMethodDropdownOpen(false);
      if (id != null) {
        setInstallMode(true);
        setCurrentStep(0);
      } else {
      }
    };
    const toggleMethodDropdown = () => {
      setMethodDropdownOpen(!methodDropdownOpen);
    };

    useEffect(() => {
      if (openAndroidGuide) {
        if (deviceValue == 0) {
          if (methodValue == 0) {
            setMainInstruction(instruction.android.googlepixel.qr);
          } else if (methodValue == 1) {
            setMainInstruction(instruction.android.googlepixel.manual);
          } else {
          }
        } else if (deviceValue == 1) {
          if (methodValue == 0) {
            setMainInstruction(instruction.android.samsung.qr);
          } else if (methodValue == 1) {
            setMainInstruction(instruction.android.samsung.manual);
          } else {
          }
        } else if (deviceValue == 2) {
          if (methodValue == 0) {
            setMainInstruction(instruction.android.otherdevices.qr);
          } else if (methodValue == 1) {
            setMainInstruction(instruction.android.otherdevices.manual);
          } else {
          }
        } else {
        }
      } else if (openIosGuide) {
        if (deviceValue == 0) {
          if (methodValue == 0) {
            setMainInstruction(instruction.ios.ios15.qr);
          } else if (methodValue == 1) {
            setMainInstruction(instruction.ios.ios15.manual);
          } else {
          }
        } else if (deviceValue == 1) {
          if (methodValue == 0) {
            setMainInstruction(instruction.ios.ios16.qr);
          } else if (methodValue == 1) {
            setMainInstruction(instruction.ios.ios16.manual);
          } else {
          }
        } else {
        }
      } else {
        // setMainInstruction("");
      }
    }, [openAndroidGuide, openIosGuide, deviceValue, methodValue]);

    useEffect(() => {
      if (
        mainInstruction &&
        mainInstruction.step1 &&
        Array.isArray(mainInstruction.step1) &&
        mainInstruction.step1.length > 0
      ) {
        setStepOneCount(mainInstruction.step1.length);
        setStepOneContent(mainInstruction.step1);
      } else {
        // console.log(
        //   "Unable to access the first element of mainInstruction.step1"
        // );
      }

      if (
        mainInstruction &&
        mainInstruction.step2 &&
        Array.isArray(mainInstruction.step2) &&
        mainInstruction.step2.length > 0
      ) {
        setStepTwoCount(mainInstruction.step2.length);
        setStepTwoContent(mainInstruction.step2);
      } else {
        // console.log(
        //   "Unable to access the first element of mainInstruction.step1"
        // );
      }

      stepTotalStepCount(stepOneCount + stepTwoCount);
    }, [mainInstruction, stepOneCount, stepTwoCount]);

    const handleSteps = (value) => {
      if (totalStepContent.length > 0) {
        if (value === "prev" && currentStep > 0) {
          setCurrentStep(currentStep - 1);
          setCurrentStatus("prev");
        } else if (
          value === "next" &&
          currentStep < totalStepContent.length - 1
        ) {
          setCurrentStep(currentStep + 1);
          setCurrentStatus("next");
        } else if (value === "reset") {
          setCurrentStep(0);
          setCurrentStatus("reset");
        }
      } else if (
        value === "next" &&
        currentStep < totalStepContent.length - 1
      ) {
        setCurrentStep(currentStep + 1);
      } else {
        // Handle case when the array is empty
      }
    };

    useEffect(() => {
      if (currentStep + 1 <= stepOneCount) {
        setStepOneActive(true);
        setStepTwoActive(false);
      } else if (currentStep >= stepOneCount) {
        setStepOneActive(false);
        setStepTwoActive(true);
      } else {
      }
    }, [currentStep, stepOneCount]);

    useEffect(() => {
      if (currentStatus == "next") {
        if (stepOneActive) {
          if (currentStep < stepOneCount && stepOneActive == true) {
            setStepOneAccount(stepOneAccount + 1);
          } else {
          }
        } else {
          setStepTwoAccount(stepTwoAccount + 1);
        }
      } else if (currentStatus == "prev") {
        if (stepOneActive) {
          setStepOneAccount(stepOneAccount - 1);
        } else {
          if (stepTwoAccount > 1) {
            setStepTwoAccount(stepTwoAccount - 1);
          } else {
          }
        }
      } else if (currentStatus == "reset") {
        setStepOneAccount(1);
        setStepTwoAccount(1);
      } else {
      }
    }, [currentStep, currentStatus]);

    useEffect(() => {
      if (
        deviceValue == 0 ||
        deviceValue == 1 ||
        deviceValue == 2 ||
        methodValue == 0 ||
        methodValue == 1
      ) {
        setStepOneAccount(1);
        setStepTwoAccount(1);
      }
    }, [deviceStep, installMode, deviceValue, methodValue]);

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (
          dropdownDeviceRef.current &&
          !dropdownDeviceRef.current.contains(event.target)
        ) {
          setDeviceDropdownOpen(false);
        }
        if (
          dropdownMethodRef.current &&
          !dropdownMethodRef.current.contains(event.target)
        ) {
          setMethodDropdownOpen(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);

    // Inside coverage dropdown
    const openInsideCoverageDropdown = () => {
      setOpenInsideDropdown(true);
      const installPopup = document.getElementById("installation-popup");
      installPopup.style.overflowY = "hidden";
    };

    const closeInsideCoverageDropdown = () => {
      setOpenInsideDropdown(false);
      const installPopup = document.getElementById("installation-popup");
      installPopup.style.overflowY = "auto";
    };

    const handleInsideCoverageClick = (event) => {
      if (
        openInsideDropdown &&
        coverageDropdownRef.current &&
        !coverageDropdownRef.current.contains(event.target)
      ) {
        closeInsideCoverageDropdown();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleInsideCoverageClick);

      return () => {
        document.removeEventListener("click", handleInsideCoverageClick);
      };
    }, [openInsideDropdown]);

    const QrModeComponent = () => {
      return (
        <div className="d-flex">
          {esimDetail.esimPlan.plan.vendor.vendorId === 2 ? (
            <QRCode
              id="targetQRcode"
              level={"H"}
              includeMargin={true}
              value={esimDetail.activationCode}
              size={130}
            />
          ) : (
            <QRCode
              id="targetQRcode"
              level={"H"}
              includeMargin={true}
              value={esimDetail.activationCode}
              size={130}
            />
          )}
          <div className="d-flex flex-column justify-content-start align-items-start pl-3">
            <div>
              <h5>Scan QR code</h5>
              <p className="mb-2" style={{ lineHeight: "1", fontSize: "12px" }}>
                Scan the QR code by printing out or displaying the code on
                another device to install your eSIM. *Make sure your device has
                a stable internet connection before installing.{" "}
              </p>
            </div>
            <div className="mt-auto w-100">
              <button
                className="w-100 rounded-pill btn btn-outline-light btn-md"
                onClick={downloadQRCode}
              >
                SHARE QR
                <IoMdShare className="ml-3" />
              </button>
            </div>
          </div>
        </div>
      );
    };

    const ManualModeComponent = () => {
      const handleCopyClick = () => {
        navigator.clipboard.writeText(esimDetail.manualCode);
        toast.success("Activation code copied successfully!");
      };
      const handleCopyClickForSmdpAddress = () => {
        openAndroidGuide
          ? navigator.clipboard.writeText(esimDetail.activationCode)
          : navigator.clipboard.writeText(esimDetail.smdpAddress);
        openAndroidGuide
          ? toast.success("Activation code copied successfully!")
          : toast.success("SM-DP address copied successfully!");
      };

      return (
        <>
          <div
            onClick={handleCopyClickForSmdpAddress}
            className="manual-card access-data-btn mb-2 p-2 w-100 d-flex justify-content-between align-items-center"
          >
            <div className="w-100">
              <p className="m-0">
                {openAndroidGuide
                  ? "SM-DP+ ADDRESS & ACTIVATION CODE"
                  : "SM-DP+ ADDRESS"}
              </p>
              <h4 style={{ wordBreak: "break-all" }} className="m-0">
                {openAndroidGuide
                  ? esimDetail.activationCode
                  : esimDetail.smdpAddress}
              </h4>
            </div>
            <div className=" mx-3">
              <BiCopy title="Copy" style={{ fontSize: "25px" }} />
              {/* {smdpCopied && (
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#4CAF50",
                    fontWeight: "bold",
                  }}
                >
                  Copied
                </span>
              )} */}
            </div>
          </div>
          {openIosGuide && (
            <div
              onClick={handleCopyClick}
              className="manual-card access-data-btn mb-2 p-2 w-100 d-flex justify-content-between align-items-center"
            >
              <div className="w-100">
                <p className="m-0">ACTIVATION CODE </p>
                <p
                  style={{ wordBreak: "break-all" }}
                  className="font-weight-bold m-0"
                >
                  {esimDetail.manualCode}
                </p>
              </div>
              <div className=" mx-3">
                <BiCopy title="Copy" style={{ fontSize: "25px" }} />
                {isCopied && (
                  <span
                    style={{
                      marginLeft: "5px",
                      color: "#4CAF50",
                      fontWeight: "bold",
                    }}
                  >
                    Copied
                  </span>
                )}
              </div>
            </div>
          )}
          <p style={{ fontSize: "10px", lineHeight: 1.2 }} className="m-0">
            Copy this information and enter details manually to install your
            eSIM. *Make sure your device has a stable internet connection before
            installing.
          </p>
        </>
      );
    };

    const AccessDataComponent = () => {
      const [popoverActive, setPopoverActive] = useState(false);

      const handleInfoDRCopyClick = () => {
        setPopoverActive(true);
      };
      const closeInfoDRCopyClick = () => {
        setPopoverActive(false);
      };

      return (
        <div className="w-100">
          <div
            style={{ gap: "5px" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            {esimDetail.esimPlan.plan.region.regionId || esimDetail.esimPlan.plan.planType.planTypeId === 3 ? (
              <div
                onClick={openInsideCoverageDropdown}
                className="access-data-btn d-flex justify-content-between align-items-center w-100"
              >
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <p>Network</p>
                  <h4>Check Available Network</h4>
                </div>
                <RxDropdownMenu />
              </div>
            ) : esimDetail.esimPlan.plan.planCountry[0].planCountryNetwork !==
              null ? (
              <div className="access-data-btn d-flex justify-content-between align-items-center w-100">
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <p>Network available</p>
                  <h4>
                    {esimDetail.esimPlan.plan.planCountry.map(
                      (country) => country.countryDescription
                    )}{" "}
                    -{" "}
                    {esimDetail.esimPlan.plan.planCountry[0].planCountryNetwork
                      .map((network) => network)
                      .join(", ")}
                  </h4>
                </div>
              </div>
            ) : (
              <div className="access-data-btn d-flex justify-content-between align-items-center w-100">
                <span>
                  {esimDetail.esimPlan.plan.planCountry.map(
                    (country) => country.countryDescription
                  )}
                </span>
              </div>
            )}

            {esimDetail.apnValue !== "null" || esimDetail.apnValue !== "" && (
              <div className="access-data-btn d-flex justify-content-between align-items-center w-100">
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <p>APN</p>
                  <h4>{esimDetail.apnValue}</h4>
                </div>
                <BiCopy
                  title="Copy"
                  //  style={{ fontSize: "25px" }}
                  onClick={handleAPNCopyClick}
                />
              </div>
            )}

            <div className="access-data-btn d-flex justify-content-between align-items-center w-100">
              <div className="d-flex flex-column justify-content-center align-items-start">
                <p>DATA ROAMING</p>
                <h4>{esimDetail.roaming == true ? "ON" : "OFF"}</h4>
              </div>
              <div style={{ position: "relative" }}>
                <AiOutlineInfoCircle onClick={handleInfoDRCopyClick} />

                {popoverActive ? (
                  <div id="popover" className="popOver p-3">
                    <p>
                      Make sure to turn data roaming on for your eSIM line.
                      You'll have access to the data plan you've purchased.{" "}
                      <br />
                      IMPORTANT: Make sure to turn data roaming off on your
                      PRIMARY LINE. You'll avoid roaming charges from your
                      primary carrier.
                    </p>
                    <button
                      onClick={closeInfoDRCopyClick}
                      className="w-100 btn btn-outline-dark mt-2 rounded-pill"
                    >
                      OK, GOT IT
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div id="installation-popup" className="installation-popup">
          <div
            ref={installationPopup}
            className="installation-popup-content p-3 "
          >
            <AiFillCloseCircle
              onClick={closeInstallationPopup}
              className="close-icon"
            />
            <div className="installation-popup-header">
              <div>
                <h2
                  style={{ borderBottom: "1px solid #969696" }}
                  className="font-weight-bold mt-2 pb-2"
                >
                  {openIosGuide ? "IOS Devices" : "Android Devices"}
                </h2>
              </div>
              {/* <div
              style={{ position: "relative" }}
              className="d-flex justify-content-center align-items-center "
            >
              <button
                onClick={() => {
                  setQrBtnActive(true);
                  setManualBtnActive(false);
                }}
                className={`${
                  qrBtnActive ? "btn-custom-active" : ""
                } btn btn-custom  mx-2`}
              >
                QR Code
              </button>
              <button
                onClick={() => {
                  setManualBtnActive(true);
                  setQrBtnActive(false);
                }}
                className={`${
                  manualBtnActive ? "btn-custom-active" : ""
                } btn btn-custom mx-2`}
              >
                Manual
              </button>
              <div className="line-seprator"></div>
            </div> */}
            </div>
            {esimDetail !== null && (
              <div className="installation-popup-body">
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <div
                    className="d-flex justify-content-start align-items-start"
                    style={{
                      borderRadius: "8px",
                      background:
                        "linear-gradient(268deg, #E84437 -10.44%, #E2AF04 110.7%)",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <RiAlertFill
                        style={{ fontSize: "20px", margin: "0 10px 0 0" }}
                      />
                    </span>
                    <p className="mb-0">
                      WARNING! Most eSIMs can only be installed once. If you
                      remove the eSIM from your device, you cannot install it
                      again.
                    </p>
                  </div>
                </div>

                <div
                  style={{ position: "relative" }}
                  className={`row ${deviceStep ? "mt-0" : "mt-2"}`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="col-12 col-md-6"
                  >
                    <div style={{ flex: "1" }} className="">
                      <div className="">
                        <div className="w-100 d-flex flex-column">
                          {deviceStep && (
                            <div className="w-100 d-flex justify-content-end align-items-center mt-3">
                              <button
                                onClick={() => {
                                  setDeviceStep(false);
                                  setDeviceOption("");
                                  setDeviceValue(null);
                                  setDeviceDropdownOpen(false);

                                  setInstallMode(false);
                                  setMethodOption("");
                                  setMethodValue(null);
                                  setMethodDropdownOpen(false);

                                  stepInstructionStep(false);

                                  setMainInstruction({});
                                  setStepOneCount(0);
                                  setStepTwoCount(0);
                                  stepTotalStepCount(0);
                                  setCurrentStep(0);

                                  setStepOneContent([]);
                                  setStepTwoContent([]);

                                  setStepOneActive(true);
                                  setStepTwoActive(false);
                                  setStepOneAccount(1);
                                  setStepTwoAccount(1);
                                  setCurrentStatus("");
                                }}
                                // style={{
                                //   position: "absolute",
                                //   left: "50%",
                                //   transform: "translateX(-50%)",
                                //   zIndex: "9",
                                // }}
                                className="btn btn-outline-light rounded-pill"
                              >
                                Reset all
                              </button>
                            </div>
                          )}
                          <div className="instruction-dropdown-1">
                            <div className="d-flex">
                              <div className="d-flex mr-3 flex-column justify-content-center align-items-center">
                                <div>
                                  {deviceStep == true ? (
                                    <FaCircleCheck
                                      className="progress-success"
                                      style={{ fontSize: "30px" }}
                                    />
                                  ) : (
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 0.999999 16 0.999999C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31Z"
                                        stroke="#D9D9D9"
                                        stroke-width="2"
                                      />
                                      <path
                                        d="M17.9176 8.45455V23H15.7159V10.6562H15.6307L12.1506 12.929V10.8267L15.7798 8.45455H17.9176Z"
                                        fill="#D9D9D9"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <div
                                  className={`${deviceStep ? "progress-line-success" : ""
                                    } inst-progress-line`}
                                ></div>
                              </div>

                              <div className="w-100 d-flex flex-column notranslate">
                                <h4>Select your device</h4>
                                <div className={` bg-custom-grey mb-3`}>
                                  <div
                                    className="inst-dropdown"
                                    style={{
                                      color: "#fff",
                                      borderColor: deviceStep ? "#1CD72F" : "",
                                    }}
                                    ref={dropdownDeviceRef}
                                    onClick={toggleDeviceDropdown}
                                  >
                                    {deviceOption || "No device selected"}
                                    <span
                                      className={`dropdown-icon ${deviceDropdownOpen ? "open" : ""
                                        }`}
                                    ></span>

                                    <span className="caret-icon"></span>
                                  </div>
                                  {deviceDropdownOpen && (
                                    <ul className="custom-dropdown-options right-aligned">
                                      {(openAndroidGuide
                                        ? AndroidOptions
                                        : IosOptions
                                      ).map((item, index) => (
                                        <li
                                          key={index}
                                          className={
                                            index == deviceValue
                                              ? "option-disabled"
                                              : ""
                                          }
                                          onClick={() =>
                                            handleDeviceType(item.name, item.id)
                                          }
                                        >
                                          {item.name}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="instruction-dropdown-2">
                            <div className="d-flex">
                              <div className="d-flex mr-3 flex-column justify-content-center align-items-center">
                                <div>
                                  {installMode == true ? (
                                    <FaCircleCheck
                                      className="progress-success"
                                      style={{ fontSize: "30px" }}
                                    />
                                  ) : (
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        transform="rotate(90 16 16)"
                                        stroke="#D9D9D9"
                                        stroke-width="2"
                                      />
                                      <path
                                        d="M11.3991 23V21.4091L16.321 16.3097C16.8466 15.7557 17.2798 15.2704 17.6207 14.8537C17.9664 14.4323 18.2244 14.0322 18.3949 13.6534C18.5653 13.2746 18.6506 12.8722 18.6506 12.446C18.6506 11.9631 18.5369 11.5464 18.3097 11.196C18.0824 10.8409 17.7723 10.5687 17.3793 10.3793C16.9863 10.1851 16.5436 10.0881 16.0511 10.0881C15.5303 10.0881 15.0758 10.1946 14.6875 10.4077C14.2992 10.6207 14.0009 10.9214 13.7926 11.3097C13.5843 11.6979 13.4801 12.1525 13.4801 12.6733H11.3849C11.3849 11.7879 11.5885 11.0137 11.9957 10.3509C12.4029 9.68797 12.9616 9.17424 13.6719 8.80966C14.3821 8.44034 15.1894 8.25568 16.0938 8.25568C17.0076 8.25568 17.8125 8.43797 18.5085 8.80256C19.2093 9.1624 19.7562 9.65483 20.1491 10.2798C20.5421 10.9001 20.7386 11.6009 20.7386 12.3821C20.7386 12.9219 20.6368 13.4498 20.4332 13.9659C20.2344 14.482 19.8864 15.0573 19.3892 15.6918C18.892 16.3215 18.2008 17.0862 17.3153 17.9858L14.4247 21.0114V21.1179H20.973V23H11.3991Z"
                                        fill="white"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <div
                                  className={`${installMode ? "progress-line-success" : ""
                                    } inst-progress-line`}
                                ></div>
                              </div>
                              <div className="w-100 d-flex flex-column notranslate">
                                <h4>Select your installation method</h4>
                                <div
                                  className={`${deviceStep ? "d-block" : "d-none"
                                    } bg-custom-grey mb-3`}
                                >
                                  <div
                                    className="inst-dropdown"
                                    style={{
                                      color: "#fff",
                                      borderColor: installMode ? "#1CD72F" : "",
                                    }}
                                    ref={dropdownMethodRef}
                                    onClick={toggleMethodDropdown}
                                  >
                                    {methodOption || "select method"}
                                    <span
                                      className={`dropdown-icon ${methodDropdownOpen ? "open" : ""
                                        }`}
                                    ></span>

                                    <span className="caret-icon"></span>
                                  </div>
                                  {methodDropdownOpen && (
                                    <ul className="custom-dropdown-options right-aligned">
                                      {installationMethod.map((item, index) => (
                                        <li
                                          key={index}
                                          className={
                                            index == methodValue
                                              ? "option-disabled"
                                              : ""
                                          }
                                          onClick={() =>
                                            handleInstallMethod(
                                              item.type,
                                              item.id
                                            )
                                          }
                                        >
                                          {item.type}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="instruction-dropdown-3 mb-0">
                            <div className="d-flex">
                              <div className="mr-3">
                                {instructionStep == true ? (
                                  <FaCircleCheck
                                    className="progress-success"
                                    style={{ fontSize: "30px" }}
                                  />
                                ) : (
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="16"
                                      cy="16"
                                      r="15"
                                      transform="rotate(90 16 16)"
                                      stroke="#D9D9D9"
                                      stroke-width="2"
                                    />
                                    <path
                                      d="M16.4631 23.1989C15.4877 23.1989 14.6165 23.0308 13.8494 22.6946C13.0871 22.3584 12.4834 21.892 12.0384 21.2955C11.598 20.6941 11.3613 19.9981 11.3281 19.2074H13.5582C13.5866 19.6383 13.7311 20.0123 13.9915 20.3295C14.2566 20.642 14.6023 20.8835 15.0284 21.054C15.4545 21.2244 15.928 21.3097 16.4489 21.3097C17.0218 21.3097 17.5284 21.2102 17.9688 21.0114C18.4138 20.8125 18.7618 20.5355 19.0128 20.1804C19.2637 19.8205 19.3892 19.4062 19.3892 18.9375C19.3892 18.4498 19.2637 18.0213 19.0128 17.652C18.7666 17.2779 18.4044 16.9844 17.9261 16.7713C17.4527 16.5582 16.8797 16.4517 16.2074 16.4517H14.9787V14.6619H16.2074C16.7472 14.6619 17.2206 14.5649 17.6278 14.3707C18.0398 14.1766 18.3617 13.9067 18.5938 13.5611C18.8258 13.2107 18.9418 12.8011 18.9418 12.3324C18.9418 11.8826 18.84 11.492 18.6364 11.1605C18.4375 10.8243 18.1534 10.5616 17.7841 10.3722C17.4195 10.1828 16.9886 10.0881 16.4915 10.0881C16.018 10.0881 15.5753 10.1757 15.1634 10.3509C14.7562 10.5213 14.4247 10.7675 14.169 11.0895C13.9134 11.4067 13.776 11.7879 13.7571 12.233H11.6335C11.6572 11.447 11.8892 10.7557 12.3295 10.1591C12.7746 9.5625 13.3617 9.09612 14.0909 8.75994C14.8201 8.42377 15.6297 8.25568 16.5199 8.25568C17.4527 8.25568 18.2576 8.43797 18.9347 8.80256C19.6165 9.1624 20.142 9.64299 20.5114 10.2443C20.8854 10.8456 21.0701 11.5038 21.0653 12.2188C21.0701 13.0331 20.8428 13.7244 20.3835 14.2926C19.929 14.8608 19.3229 15.242 18.5653 15.4361V15.5497C19.5313 15.6965 20.2794 16.08 20.8097 16.7003C21.3447 17.3205 21.6098 18.09 21.6051 19.0085C21.6098 19.8087 21.3873 20.526 20.9375 21.1605C20.4924 21.795 19.884 22.2945 19.1122 22.6591C18.3404 23.0189 17.4574 23.1989 16.4631 23.1989Z"
                                      fill="white"
                                    />
                                  </svg>
                                )}
                              </div>
                              <h4>Follow the 2 step process</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="col-12 col-md-6"
                  >
                    <div
                      style={{ flex: "1" }}
                      className="d-flex justify-content-center align-items-center pl-2"
                    >
                      {installMode && (
                        <div
                          className={`w-100 flex-column instruction-dynamic-content-web justify-content-start align-items-start`}
                        >
                          {installMode &&
                            (stepOneActive ? (
                              methodValue === 0 ? (
                                <QrModeComponent />
                              ) : methodValue === 1 ? (
                                <ManualModeComponent />
                              ) : null
                            ) : (
                              <AccessDataComponent />
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`${installMode ? "d-block" : "d-none"} step-three`}
                >
                  <div
                    style={{
                      border: "1px solid #D9D9D9",
                      borderRadius: "12px",
                    }}
                    className="mt-2 px-3 py-2 cards-bg"
                  >
                    <h5
                      className="text-center"
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      {stepOneActive
                        ? "STEP 1: INSTALL eSIM"
                        : "STEP 2: ACCESS DATA"}
                    </h5>
                    <div className="progress-step d-flex">
                      <div
                        className="w-50 rounded-pill mr-1"
                        style={{ backgroundColor: "#1CD72F", height: "6px" }}
                      ></div>
                      <div
                        className="w-50 rounded-pill ml-1"
                        style={{
                          backgroundColor: stepOneActive
                            ? "#D9D9D9"
                            : "#1CD72F",
                          height: "6px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="cards-bg p-3 mt-2">
                    {/* if asked for a permanent height : style={{height:'320px'}} */}
                    <div className="d-flex flex-column justify-content-start align-items-start mb-4 mb-md-0">
                      <h5
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginBottom: "1rem",
                        }}
                      >
                        {`STEP ${stepOneActive ? stepOneAccount : stepTwoAccount
                          }/${stepOneActive ? stepOneCount : stepTwoCount}`}
                      </h5>
                      <p style={{ fontSize: "16px", marginBottom: "1rem" }}>
                        {totalStepContent[currentStep]}
                      </p>
                      {/* if asked for a permanent height : mt-auto */}
                      <div className="step-btn w-100 d-flex justify-content-between align-items-center mt-4">
                        <div>
                          {currentStep + 1 == 1 ? null : (
                            <button
                              onClick={() => handleSteps("reset")}
                              className="step-cta-btn rounded-pill btn btn-outline-light btn-md"
                            >
                              RESET
                            </button>
                          )}
                        </div>
                        <div className="d-flex">
                          {currentStep + 1 == 1 ? null : (
                            <div
                              onClick={() => handleSteps("prev")}
                              className="d-flex"
                            >
                              <button className="mr-3 step-cta-btn rounded-pill btn btn-outline-light btn-md">
                                PREVIOUS
                              </button>
                              {/* {windowWidth < 460 ? (
                              <IoIosArrowDropleft
                                style={{ fontSize: "42px" }}
                              />
                            ) : (
                            )} */}
                            </div>
                          )}
                          {currentStep + 1 == totalStepContent.length ? null : (
                            <div
                              onClick={() => handleSteps("next")}
                              className="d-flex"
                            >
                              <button className="step-cta-btn rounded-pill btn btn-outline-light btn-md">
                                NEXT
                              </button>
                              {/* {windowWidth < 460 ? (
                              <IoIosArrowDropright
                                className="ml-2"
                                style={{ fontSize: "42px" }}
                              />
                            ) : (
                            )} */}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="step-dots mt-3 mx-auto">
                        {[
                          ...Array(stepOneActive ? stepOneCount : stepTwoCount),
                        ].map((_, index) => (
                          <span
                            className={
                              stepOneActive
                                ? index == stepOneAccount - 1
                                  ? "step-dot-active"
                                  : ""
                                : index == stepTwoAccount - 1
                                  ? "step-dot-active"
                                  : ""
                            }
                            key={index}
                          ></span>
                        ))}
                      </div>
                    </div>
                    <div
                      className={`w-100 flex-column instruction-dynamic-content-mob justify-content-start align-items-start pt-4`}
                    >
                      <>
                        {installMode &&
                          (stepOneActive ? (
                            methodValue === 0 ? (
                              <QrModeComponent />
                            ) : methodValue === 1 ? (
                              <ManualModeComponent />
                            ) : null
                          ) : (
                            <AccessDataComponent />
                          ))}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {openInsideDropdown && <CoverageDropdown />}
      </>
    );
  };
  const totalDataInMb = esimDetail !== null && esimDetail.esimPlan.esimPlanRecharge[0].totalDataInMb;
  const dataRemainingInMb = esimDetail !== null && esimDetail.esimPlan.esimPlanRecharge[0].dataRemainingInMb;

  // console.log(dataRemainingInMb, totalDataInMb ,"data in mb");

   // google analytics

   useEffect(() => {
    document.title = "eSIM Details";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  const remainingData = {
    total: totalDataInMb,
    remaining: dataRemainingInMb,
  };

  const usedPercentage = Math.round(
    ((remainingData.total - remainingData.remaining) / remainingData.total) *
    100
  );

  const data = {
    datasets: [
      // {
      //   data: [usedPercentage, 100 - usedPercentage],
      //   backgroundColor: ["rgb(201, 203, 207)", "rgb(54, 162, 235)"],
      //   borderWidth: 0, // Hide the border for smoother appearance
      // },

      {

        data:
          dataRemainingInMb != 0
            ? [usedPercentage, 100 - usedPercentage]
            : [99, 100 - 99],
        backgroundColor: ["rgb(201, 203, 207)", "rgb(54, 162, 235)"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    rotation: 1 * Math.PI, // Rotate the chart to start from the top
    circumference: 1 * Math.PI, // Limit the chart to 180 degrees (half circle)
    animation: {
      animateRotate: true, // Animate rotation
      animateScale: false, // Animate scale (growing effect)
    },
  };

  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <div style={{ position: "relative" }} className="esim-detail-section">
          <Header />
          {statusCode !== 200 && <h2 className="text-center">{message}</h2>}
          <div className="esim-detail container">
            <div className="row cards-bg m-0 w-100">
              <div className=" my-3 col-12">
                <div className="detail-header w-100 d-flex justify-content-between align-items-center">
                  {esimDetail !== null && (
                    <h1 style={{ color: "#fff" }}>
                      {esimDetail.esimPlan.plan.planName}
                    </h1>
                  )}
                </div>
                {esimDetail !== null && (
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <OrderListComp
                        orderValue={"valueFunction"}
                        order={"ICCID NUMBER"}
                        value={esimDetail.iccid}
                        breakLine={true}
                      />
                      <div className="order-list list-break-line">
                        <div className="order-key">
                          <p>Coverage</p>
                        </div>
                        {esimDetail.esimPlan.plan.region.regionId || esimDetail.esimPlan.plan.planType.planTypeId === 3 ? (
                          <div
                            className="order-value order-popup-link"
                            style={{ position: "relative" }}
                          >
                            <div
                              className={`${isCoverageDropdownOpen
                                ? "order-popup-active"
                                : ""
                                }`}
                              onClick={openCoverageDropdown}
                            >
                              <span>
                                {esimDetail.esimPlan.plan.planCountry.length}{" "}
                                Countries
                              </span>
                              <RxDropdownMenu />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="order-value"
                            style={{ position: "relative" }}
                          >
                            {esimDetail.esimPlan.plan.planCountry[0]
                              .planCountryNetwork !== null ? (
                              <span>
                                {esimDetail.esimPlan.plan.planCountry.map(
                                  (country) => country.countryDescription
                                )}{" "}
                                -{" "}
                                {esimDetail.esimPlan.plan.planCountry[0].planCountryNetwork
                                  .map((network) => network)
                                  .join(", ")}
                              </span>
                            ) : (
                              <span>
                                {esimDetail.esimPlan.plan.planCountry.map(
                                  (country) => country.countryDescription
                                )}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      {/* <OrderListComp
                  orderValue={"valueFunction"}
                  order={"Network"}
                  value={"14 Countries"}
                  breakLine={true}
                /> */}
                    </div>
                    <div className="col-12 col-md-6">
                      <OrderListComp
                        orderValue={"valueFunction"}
                        order={"Plan Type"}
                        value={"Data"}
                        breakLine={true}
                      />
                      <OrderListComp
                        orderValue={"valueFunction"}
                        order={"Activation Policy"}
                        value={"link"}
                        breakLine={true}
                      />
                      <OrderListComp
                        orderValue={"valueFunction"}
                        order={"Top Up Options"}
                        value={
                          esimDetail.esimPlan.plan.topupAvailable === 1
                            ? "Available"
                            : "Not Available"
                        }
                        breakLine={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row m-0 w-100">
              <div className="esim-detail-cta col-12">
                <button className="btn btn-custom-installation shadow-sm">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <span>
                        <TbAlertTriangleFilled
                          style={{ fontSize: "20px", margin: "0 10px 0 0" }}
                        />
                        WARNING! Most eSIMs can only be installed once. If you
                        remove the eSIM from your device, you cannot install it
                        again.
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row m-0">
              <div className="col-12 my-3 p-3 esim-install-device cards-bg">
                <div className="">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <h3 className="mb-2">eSIM Installation</h3>
                  </div>
                  <div className="row ">
                    <div className="col-12 col-md-6">
                      <button
                        onClick={openAndroidInstallationPopup}
                        className="my-2 btn btn-light btn-lg rounded-pill btn-block"
                      >
                        Android Device
                      </button>
                    </div>
                    <div className="col-12 col-md-6">
                      <button
                        onClick={openIosInstallationPopup}
                        className="my-2 btn btn-light btn-lg rounded-pill btn-block"
                      >
                        IOS Device
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {esimDetail !== null && (
              <div className="row m-0">
                <div className="col-12 cards-bg p-3">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <h3 className="m-0">Usage</h3>
                    {/* <CgCarousel
                  style={{
                    marginBottom: "0.5rem",
                    lineHeight: "1.2",
                    fontSize: "30px",
                  }}
                /> */}
                  </div>
                  <div className="row m-0">
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
                      {/* latest  */}
                      <div style={{ width: "200px", height: "150px" }}>
                        <Doughnut data={data} options={options} />
                        <div className="canvas-content">
                          <span
                            style={{ fontSize: "22px", fontWeight: "600" }}
                            className="text-uppercase text-center"
                          >
                            {
                              esimDetail.esimPlan.esimPlanRecharge[0]
                                .dataRemaining
                            }
                          </span>
                          <br />
                          <p className="m-0 text-center">Data remaining</p>
                        </div>
                      </div>

                      {/* <div style={{ width: 150, height: 150 }}>
                    <AnimatedProgressProvider
                      valueStart={0}
                      valueEnd={numericDataRemainingValue}
                      duration={1.4}
                      easingFunction={easeQuadInOut}
                      // repeat
                    >
                      {(value) => {
                        const roundedValue = Math.round(value * 10) / 10;
                        return (
                          <CircularProgressbar
                            value={value}
                            text={`${roundedValue} ${unit}`}
                            styles={buildStyles({
                              pathTransition: "none",
                              pathColor: "#007aff",
                            })}
                          />
                        );
                        {
                        }
                        {
                        }
                      }}
                    </AnimatedProgressProvider>
                  </div> */}
                    </div>

                    <div className="col-12 col-md-6 m-0 row mt-3 mt-md-0">
                      <div className="col-6 col-md-12 d-flex flex-column justify-content-center align-items-center">
                        <p className="mb-2 text-center">USED DATA</p>
                        <span
                          style={{ fontSize: "22px", fontWeight: "600" }}
                          className="text-uppercase text-center"
                        >
                          {esimDetail.esimPlan.esimPlanRecharge[0].dataUsed}
                        </span>
                      </div>
                      <div className="col-6 col-md-12 d-flex flex-column justify-content-center align-items-center">
                        <p className="mb-2 text-center">TOTAL DATA</p>
                        <span
                          style={{ fontSize: "22px", fontWeight: "600" }}
                          className="text-uppercase text-center"
                        >
                          {esimDetail.esimPlan.esimPlanRecharge[0].totalData}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row m-0">
              <div className="col-12 my-3 p-3 cards-bg">
                <div className="">
                  <h3 className="mb-2">My Packages</h3>
                </div>

                <div className="table-section my-package-table mt-4 p-0">
                  {/* <GoPackage
                  style={{
                    marginBottom: "0.5rem",
                    lineHeight: "1.2",
                    fontSize: "30px",
                  }}
                /> */}
                  <CSmartTable
                    clickableRows
                    columns={columns}
                    //  columnSorter // Enable column sorting
                    items={dynamicUserData}
                    pagination
                    tableProps={{
                      className: "add-this-class",
                      responsive: true,
                      striped: true,
                      hover: true,
                    }}
                    tableBodyProps={{
                      className: "align-middle",
                    }}
                  />
                </div>
                {/* <div className="row m-0">
              {esimDetail !== null && (
                <PackageList
                  validity={esimDetail.esimPlan.validity}
                  data={esimDetail.esimPlan.data}
                  status={esimDetail.dataStatus}
                />
              )}
              <PackageList validity={14} data={3} status={true} />
                <PackageList validity={2} data={4} status={false}/>
            </div> */}
              </div>
            </div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
          {isLoading === true && <Loader />}
          <ContactUsForm />
          <Footer />
          {isCoverageDropdownOpen ? (
            // <div className="dropdowns-modal">
            <CoverageDropdown />
            // </div>
          ) : null}

          {openAndroidGuide || openIosGuide ? (
            <InstallationPopup esimDetail={esimDetail} />
          ) : null}
        </div>
      )}
    </>
  );
};

export default EsimDetail;
