// import React from "react";
import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";

import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import PartnerService from "../../Services/PartnerService";
import RegionService from "../../Services/RegionService";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import leftTopImg from "../../assets/img/left-top-img_.png";
import partenrFirst from "../../assets/img/partner-1.png";
import partenrSecond from "../../assets/img/partner-2.png";
import rightBottomImg from "../../assets/img/right-grad-top.png";
import AlertPopup from "../Alert_Popup/AlertPopup";
import Loader from "../Alert_Popup/Loader";

const Partner = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false); // State variable for modal visibility

  // ... (previous code)
  const [partnerTypeList, setPartnerTypeList] = useState();
  const [countries, setCountries] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [partnerData, setPartnerData] = useState({
    partnerTypeId: {
      partnerTypeId: 0,
    },
    companyName: "",
    companyWebsite: "",
    country: {
      countryId: 0,
    },
    industry: "",
    name: "",
    taxId: "",
    emailId: "",
    mobileNo: "",
    howHeardAboutUs: "",
    helpContent: "",
    createdBy: {
      id: localStorage.getItem("userId") ? localStorage.getItem("userId") : 0,
    },
  });

  const dataChange = (e) => {
    const { name, value } = e.target;

    setPartnerData((prevData) => ({
      ...prevData,
      [name]: value,
      country: { countryId: selectedCountry }, // Use the updated selectedCountry value
      partnerTypeId: { partnerTypeId: selectedType }, // Use the updated selectedType value
    }));

    // Perform validation for the specific input field
    const errors = { ...validationErrors };

    if (name === "name") {
      if (!value.trim()) {
        errors.name = "Name is required";
      } else {
        if (!/^[A-Za-z ]+$/.test(value)) {
          errors.name = "Please enter a valid Name";
        } else {
          errors.name = ""; // Clear the error if validation passes
        }
      }
    }

    if (name === "industry") {
      if (!value.trim()) {
        errors.industry = "Industry is required";
      } else {
        errors.industry = ""; // Clear the error if validation passes
      }
    }
    if (name === "emailId") {
      if (!value.trim()) {
        errors.emailId = "Email is required";
      } else if (
        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
      ) {
        errors.emailId = "Invalid Email";
      } else {
        errors.emailId = ""; // Clear the error if validation passes
      }
    }

    if (name === "companyName") {
      if (!value.trim()) {
        errors.companyName = "Company name is required";
      } else {
        errors.companyName = ""; // Clear the error if validation passes
      }
    }

    if (name === "companyWebsite") {
      if (!value.trim()) {
        errors.companyWebsite = "Company website is required";
      } else {
        const inputURL = value.toLowerCase(); // Convert to lowercase for case-insensitive comparison

        const isValidURL = (url) => {
          const domainParts = url.split(".");

          return (
            domainParts.length >= 2 &&
            domainParts.every((part, index) =>
              index === 0 ? part.length >= 2 : part.length >= 2
            )
          );
        };

        // Check if the URL starts with "www." or "http://www." or "https://www."
        if (
          inputURL.startsWith("www.") ||
          inputURL.startsWith("http://www.") ||
          inputURL.startsWith("https://www.")
        ) {
          if (
            isValidURL(inputURL) &&
            inputURL.split(".").length >= 3 &&
            !/\s/.test(inputURL)
          ) {
            // Valid URL for http://www. or https://www.
            // No further checks needed.
            errors.companyWebsite = "";
            // console.log("passed 1");
          } else {
            errors.companyWebsite = "Please enter a valid website";
          }
        } else {
          // Check if the URL does not start with "www." and has at least two dots in the domain
          if (
            isValidURL(inputURL) &&
            inputURL.split(".").length >= 2 &&
            !/\s/.test(inputURL)
          ) {
            // Valid URL
            errors.companyWebsite = "";
            // console.log("passed at 2");
          } else {
            errors.companyWebsite = "Please enter a valid website";
          }
        }
      }
    }

    if (name === "mobileNo") {
      if (value) {
        if (!/^[0][1-9]\d{9}$|^[1-9]\d{9}$/.test(value)) {
          errors.mobileNo = "Invalid contact number";
        } else {
          errors.mobileNo = "";
        }
      } else {
        // Clear the error if the field is empty
        errors.mobileNo = "";
      }
    }

    if (name === "howHeardAboutUs") {
      if (!value.trim()) {
        errors.howHeardAboutUs = "Required";
      } else {
        errors.howHeardAboutUs = ""; // Clear the error if validation passes
      }
    }

    if (name === "helpContent") {
      if (!value.trim()) {
        errors.helpContent = "Required";
      } else {
        errors.helpContent = ""; // Clear the error if validation passes
      }
    }
    // Update the form validity
    // Add similar validation for other fields as needed

    setValidationErrors(errors);
  };

  const [isFormValid, setIsFormValid] = useState(true);
  const [message, setMessage] = useState();

  const partnerTypeAPI = () => {
    PartnerService.partnertypeList().then((response) => {
      setPartnerTypeList(response.data);
    });
  };

  const [validationErrors, setValidationErrors] = useState({});

  const addPartnerAPI = () => {
    const errors = {};

    if (!partnerData.name.trim()) {
      errors.name = "Name is required";
    } else {
      if (!/^[A-Za-z ]+$/.test(partnerData.name)) {
        errors.name = "Please enter a valid Name";
      }
    }

    if (partnerData.mobileNo) {
      if (!/^[0][1-9]\d{9}$|^[1-9]\d{9}$/.test(partnerData.mobileNo)) {
        errors.mobileNo = "Invalid mobile number";
      }
    }

    if (!partnerData.emailId.trim()) {
      errors.emailId = "Email is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        partnerData.emailId
      )
    ) {
      errors.emailId = "Invalid Email";
    }

    if (!partnerData.companyName.trim()) {
      errors.companyName = "Company name is required";
    }

    if (!partnerData.industry.trim()) {
      errors.industry = "Industry is required";
    }
    // else {
    //   if (!/^[A-Za-z ]+$/.test(formData.name)) {
    //     errors.name = "Company website is ";
    //   }
    // }

    if (!partnerData.companyWebsite.trim()) {
      errors.companyWebsite = "Company website is required";
    } else {
      const inputURL = partnerData.companyWebsite.toLowerCase();

      const isValidURL = (url) => {
        const domainParts = url.split(".");

        return (
          domainParts.length >= 2 &&
          domainParts.every((part, index) =>
            index === 0 ? part.length >= 2 : part.length >= 2
          )
        );
      };

      // Check if the URL starts with "www." or "http://www." or "https://www." and has at least three dots in the domain
      if (
        inputURL.startsWith("www.") ||
        inputURL.startsWith("http://www.") ||
        inputURL.startsWith("https://www.")
      ) {
        if (
          isValidURL(inputURL) &&
          inputURL.split(".").length >= 3 &&
          !/\s/.test(inputURL)
        ) {
          // Valid URL for http://www. or https://www.
          // No further checks needed.
          // console.log("passed 1");
        } else {
          errors.companyWebsite = "Please enter a valid website";
        }
      } else {
        // Check if the URL does not start with "www." and has at least two dots in the domain
        if (
          isValidURL(inputURL) &&
          inputURL.split(".").length >= 2 &&
          !/\s/.test(inputURL)
        ) {
          // Valid URL
          // console.log("passed at 2");
        } else {
          errors.companyWebsite = "Please enter a valid website";
        }
      }
    }

    if (!selectedCountry) {
      errors.country = "Please select a country";
    }

    if (!selectedType) {
      errors.partnerType = "Please select a partner type";
    }

    if (!partnerData.howHeardAboutUs.trim()) {
      errors.howHeardAboutUs = "Required";
    }

    if (!partnerData.helpContent.trim()) {
      errors.helpContent = "Required";
    }
    // Check if there are any validation errors
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      PartnerService.addPartner(partnerData)
        .then((response) => {
          if (response.status === 201) {
            setIsLoading(false);
            // console.log("added",response);
            toggleModal();
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setIsLoading(false);
            setMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setIsLoading(false);
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                setIsLoading(false);
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                setIsLoading(false);
                console.log("Network Error", error.response.data.failedReason);
                setMessage(
                  "Unknown Error. Please send your details to help@esim-mobile.co.uk"
                );
                break;
              case 404:
                setIsLoading(false);
                setMessage(
                  "Unknown Error. Please send your details to help@esim-mobile.co.uk"
                );
                break;
              case 406:
                setIsLoading(false);
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setIsLoading(false);
                setMessage(error.response.data.failedReason);
                break;
              default:
                setIsLoading(false);
                setMessage(
                  "Unknown Error. Please send your details to help@esim-mobile.co.uk"
                );
                break;
            }
          }
        });
    } else {
      // Validation errors exist, set them in the state to display to the user
      setValidationErrors(errors);
    }
  };

  useEffect(() => {
    console.log("load countries");
    partnerTypeAPI();
    countryAPI();
  }, []);

  const countryAPI = () => {
    RegionService.countryAccordingToRegionList()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => console.log("Error", error));
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const redirectToHome = () => {
    history.push("/");
  };

  const handleSaveAndProceed = () => {
    // Add your logic here for handling the "Save & Proceed" button click
    // You can perform any necessary actions and then open the modal.
    // For now, let's just open the modal without any specific logic.

    toggleModal(); // Open the modal
  };

  const partnershipTypes = ["Corporate", "Distributor", "Other"];
  // const countries = [
  //   'United States',
  //   'Canada',
  //   'United Kingdom',
  //   'Australia',
  //   'Germany',
  //   // Add more countries as needed
  // ];

  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState();
  const dropdownCountryRef = useRef(null);
  const dropdownTypeRef = useRef(null);

  const handleTypeChange = (typeId, name) => {
    setSelectedType(typeId);
    setSelectedTypeName(name);
    setIsTypeDropdownOpen(false);

    // Update the partnerData here if needed
    setPartnerData((prevData) => ({
      ...prevData,
      partnerTypeId: { partnerTypeId: typeId },
    }));

    // Clear the validation error for partnerType
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      partnerType: "",
    }));
  };

  const handleCountryChange = (country, name) => {
    console.log("handleCountryChange", name);
    setSelectedCountry(country);
    setSelectedCountryName(name);
    setIsCountryDropdownOpen(false);

    // Update the partnerData here if needed
    setPartnerData((prevData) => ({
      ...prevData,
      country: { countryId: country },
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      country: "",
    }));

    setSearchTerm("");
  };

  const toggleTypeDropdown = () => {
    setIsTypeDropdownOpen(!isTypeDropdownOpen);
  };

  const toggleCountryDropdown = () => {
    setIsCountryDropdownOpen(!isCountryDropdownOpen);
  };
  useEffect(() => {
    console.log("Event Listener");
    const handleOutsideClick = (event) => {
      if (
        dropdownCountryRef.current &&
        !dropdownCountryRef.current.contains(event.target)
      ) {
        setIsCountryDropdownOpen(false);
      }
      if (
        dropdownTypeRef.current &&
        !dropdownTypeRef.current.contains(event.target)
      ) {
        setIsTypeDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);

  useEffect(() => {
    console.log("search term and countries");
    if (countries) {
      const filtered = countries.filter((item) =>
        item.countryDescription.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  }, [searchTerm, countries]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  window.onload = function () {
    // Get the element with the class "app"
    var appElement = document.querySelector(".app");

    // Apply the "position: relative" style
    if (appElement) {
      appElement.style.position = "relative";
    }
  };

  // google analytics

  useEffect(() => {
    console.log("Title");
    document.title = "Partner Form";
    document.querySelector("title").classList.add("notranslate");
  }, []);

  useEffect(() => {
    console.log("Analytics");
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-CNPPGN8KCG");
    gtag("user_id", localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div
      className="container-fluid"
      style={{ position: "relative", padding: "0px" }}
    >
      <div className="container">
        <header>
          <Header />
        </header>

        <section style={{ position: "relative" }} className="mt-3">
          <div className="row cardForpartnerModule">
            <div className="col-lg-6 pl-0 pr-0 col-sm-12">
              <div className="">
                <img
                  className="img-fluid leftCorners"
                  src={partenrSecond}
                  alt="logo"
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 d-flex align-items-center">
              <div className="text-white py-4">
                <h4>GKT eSIM For Distributor</h4>
                <p>
                  Becoming a Distribution Partner entails reselling our eSIMs to
                  your current customer base. If you are a retailer, dealer,
                  small business, or sole proprietor operating in the
                  telecommunications, travel, airline, high street shop, SIM
                  shop, or tech sectors, you are an ideal match for our
                  partnership program.
                </p>
                <p>
                  However, if your business model deviates slightly from this
                  description, we are open to discussions to explore the
                  potential alignment of our partnership requirements.
                </p>
              </div>
            </div>
          </div>

          <div className="row cardForpartnerModule">
            <div className="col-lg-6 col-sm-12 d-flex align-items-center orderTwosm py-lg-4">
              <div className="">
                <div className="text-white py-4">
                  <h4>GKT eSIM For Corporate</h4>
                  <p>
                    Partner with GKT eSIM for corporate solutions that provide
                    global connectivity to your mobile workforce. Opt for our
                    corporate program if your business has employees frequently
                    traveling and requires centralized eSIM management, allowing
                    your team to stay focused on their core tasks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 pl-0 pr-0 d-flex align-items-center">
              <div className="">
                <img
                  className="img-fluid rightCorners"
                  src={partenrFirst}
                  alt="logo"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 text-white cards-bg py-3 mb-4">
            <div className="row">
              <div className="form-group col-md-6 custom-dropdown notranslate">
                <label htmlFor="partnershipType">
                  <b>Partnership Type </b>
                </label>

                <div
                  className="custom-dropdown-container-type bg-custom-grey"
                  ref={dropdownTypeRef}
                >
                  {/* custom-dropdown-selected  */}
                  <div
                    className="form-control"
                    style={{ color: "#fff" }}
                    onClick={toggleTypeDropdown}
                  >
                    {selectedTypeName || "Select Partnership Type"}
                    <span
                      className={`dropdown-icon ${
                        isTypeDropdownOpen ? "open" : ""
                      }`}
                    ></span>

                    <span className="caret-icon"></span>
                  </div>
                  {isTypeDropdownOpen && (
                    <ul className="custom-dropdown-options right-aligned">
                      {partnerTypeList.map((type, index) => (
                        <li
                          key={type.partnerTypeId}
                          onClick={() =>
                            handleTypeChange(
                              type.partnerTypeId,
                              type.partnerTypeDesc
                            )
                          }
                        >
                          {type.partnerTypeDesc}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <FormFeedback
                  style={{
                    display:
                      validationErrors.partnerType === "" ||
                      validationErrors.partnerType === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.partnerType}
                </FormFeedback>
              </div>
            </div>
            <div className="row referral-form">
              <div className="form-group col-md-6">
                <label htmlFor="companyName">Company Name*</label>
                <input
                  type="text"
                  className="form-control text-white "
                  id="companyName"
                  name="companyName"
                  placeholder="Enter Company Name"
                  maxLength={45}
                  // valid={!errors.name}
                  // invalid={touched.name && !!errors.name}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.companyName === "" ||
                      validationErrors.companyName === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.companyName}
                </FormFeedback>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="companyWebsite">Company Website*</label>
                <input
                  type="text"
                  className="form-control text-white  whitePlaceholder"
                  id="companyWebsite"
                  name="companyWebsite"
                  placeholder="Enter Company Website"
                  maxLength={50}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.companyWebsite === "" ||
                      validationErrors.companyWebsite === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.companyWebsite}
                </FormFeedback>
              </div>
              <div className="form-group col-md-6 custom-dropdown notranslate">
                <label htmlFor="country">Country*</label>
                <div
                  className="custom-dropdown-container"
                  ref={dropdownCountryRef}
                >
                  <div
                    className="custom-dropdown-selected"
                    onClick={toggleCountryDropdown}
                  >
                    {selectedCountryName || "Select Country"}

                    <span
                      className={`dropdown-icon ${
                        isCountryDropdownOpen ? "open" : ""
                      }`}
                    ></span>
                    <span className="caret-icon"></span>
                  </div>
                  {isCountryDropdownOpen && (
                    <ul className="custom-dropdown-options dropdown-height">
                      <li className="partner-search-bar">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </li>
                      {searchTerm && filteredCountries.length === 0 ? (
                        <li className="no-results">No results found</li>
                      ) : (
                        filteredCountries.map((country, index) => (
                          <li
                            key={country.countryId}
                            value={country.countryId}
                            onClick={() =>
                              handleCountryChange(
                                country.countryId,
                                country.countryDescription
                              )
                            }
                          >
                            {country.countryDescription}
                          </li>
                        ))
                      )}
                    </ul>
                  )}
                </div>
                <FormFeedback
                  style={{
                    display:
                      validationErrors.country === "" ||
                      validationErrors.country === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.country}
                </FormFeedback>
              </div>
              {/* {console.log("data", partnerData)} */}
              <div className="form-group col-md-6">
                <label htmlFor="industry">Industry*</label>
                <input
                  type="text"
                  className="form-control text-white "
                  id="industry"
                  placeholder="Enter Industry"
                  name="industry"
                  maxLength={50}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.industry === "" ||
                      validationErrors.industry === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.industry}
                </FormFeedback>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="taxID">Tax ID (Optional)</label>
                <input
                  type="text"
                  className="form-control text-white "
                  id="taxID"
                  placeholder="Enter Tax ID"
                  name="taxID"
                  maxLength={45}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="contactName">Name of Contact*</label>
                <input
                  type="text"
                  className="form-control text-white  "
                  id="contactName"
                  placeholder="Enter Name Of Contact"
                  name="name"
                  maxLength={45}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.name === "" ||
                      validationErrors.name === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.name}
                </FormFeedback>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  className="form-control text-white "
                  id="email"
                  name="emailId"
                  placeholder="Enter Email"
                  maxLength={45}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.emailId === "" ||
                      validationErrors.emailId === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.emailId}
                </FormFeedback>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="mobileNumber">Mobile Number (Optional)</label>
                <input
                  type="tel"
                  className="form-control text-white "
                  id="mobileNumber"
                  name="mobileNo"
                  placeholder="Enter Mobile Number"
                  maxLength={11}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.mobileNo === "" ||
                      validationErrors.mobileNo === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.mobileNo}
                </FormFeedback>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="howDidYouHear">
                  How did you hear about us?*
                </label>
                <textarea
                  rows={3}
                  className="form-control text-white  py-3 border-2"
                  id="howDidYouHear"
                  placeholder="Enter How Did You Hear About Us?"
                  name="howHeardAboutUs"
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.howHeardAboutUs === "" ||
                      validationErrors.howHeardAboutUs === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.howHeardAboutUs}
                </FormFeedback>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="helpRequest">
                  Tell us more how we can help you?*
                </label>
                <textarea
                  rows={3}
                  className="form-control text-white  py-3 border-2"
                  id="helpRequest"
                  placeholder="Enter How We Can Help You?"
                  name="helpContent"
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.helpContent === "" ||
                      validationErrors.helpContent === undefined
                        ? "none"
                        : "block",
                    marginBottom: "0",
                  }}
                  className="red"
                >
                  {validationErrors.helpContent}
                </FormFeedback>
              </div>
              <div className="col-md-12 text-center">
                <div>
                  <button
                    className="view-more-btn btn btn-save hoverBtneffect"
                    onClick={addPartnerAPI}
                  >
                    Save & Proceed
                  </button>
                </div>
              </div>
              <div className="col-md-12" style={{ fontSize: "16px", fontWeight: "600" }}>
                <FormFeedback
                  style={{
                    display:
                      message === "" || message === undefined
                        ? "none"
                        : "block",
                  }}
                  className="red"
                >
                  {message}
                </FormFeedback>
              </div>
            </div>
          </div>
        </section>

        {isModalOpen ? (
          <AlertPopup
            // heading={"Title"}
            content={
              "Thank you for expressing your interest in joining us as a partner. We will reach out to you at the earliest opportunity ."
            }
            status={"success"}
            updateState={redirectToHome}
          />
        ) : null}

        {isLoading === true && <Loader />}
        <img
          className="rightBottomImg d-none d-lg-block"
          style={{ zIndex: 1 }}
          src={rightBottomImg}
          alt=""
        />
        {/* <img
          className="leftTopImg"
          src={leftTopImg}
          alt=""
          style={{ zIndex: 1 }}
        /> */}
        <img
          className="leftBottomImg"
          src={leftBottomImg}
          style={{ zIndex: 1 }}
          alt=""
        />
      </div>
    </div>
  );
};

export default Partner;
