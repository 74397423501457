import axios from 'axios';
import { API_URL } from '../../../Common/Constant';
import React, { Component } from 'react';

class KioskOrderService {
    
    order(json, kioskId, lastLoginDate) {
        const headers = {
            'kioskId': kioskId,
            'lastLoginDate': lastLoginDate
        };
        return axios.post(`${API_URL}/kiosk/order`,json, { headers });
    }
    orderAfterPayment(orderId){
        return axios.get(`${API_URL}/kiosk/orderAfterPayment`,{headers:{"orderId":orderId}})
    }
    
}



export default new KioskOrderService()
