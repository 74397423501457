import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import aupBg from "../../assets/img/aup_bg.jpg";
import Footer from "../Footer/Footer";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import CtaBanner from "./CtaBanner";
import ContactUsForm from "./ContactUsForm";

// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AccordionSelectionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, AcceptableUsePolicy } = props;
  const { header, id, text } = AcceptableUsePolicy;
  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">{text}</div>
      </div>
    </div>
  );
};

const Aup = () => {
  const [selectionActive, setSelectionActive] = useState(null);
  const AcceptableUsePolicy = [
    {
      id: 1,
      header: "Applicability",
      text: (
        <>
          <p>
            This Policy applies to you and your Covered Users concerning the
            Services. You are responsible for:
            <ul className="pl-4 my-3">
              <li>
                informing other Covered Users of this Policy and any amendments,
                updates, or modifications made to it; and
              </li>
              <li>
                agreeing to this Policy, as amended, updated, or modified from
                time to time, on behalf of all Covered Users. This Policy is an
                integral part of the GKT eSIM Terms of Service and is a
                Supplemental Use Policy thereunder. All capitalised terms not
                expressly defined in this Policy shall have the meaning found in
                the Master Services Agreement.
              </li>
            </ul>
          </p>
        </>
      ),
    },
    {
      id: 2,
      header: "Abuse",
      text: (
        <>
          <p>
            The Services may only be used for lawful purposes. Prohibited
            activities include, but are not limited to:
            <ul className="pl-4 my-3">
              <li>
                Creation or distribution of unsolicited bulk email and mailing
                lists;
              </li>
              <li>
                Disruption or interference with any data system, network,
                computer, communications system, software application, or
                network or computing device;
              </li>
              <li>
                Unauthorized monitoring of data or traffic on any network or
                system;
              </li>
              <li>
                Use of false, misleading, or deceptive header information in
                emails or newsgroup postings;
              </li>
              <li>
                Use of an Internet account or computer without the owner's
                authorization;
              </li>
              <li>
                Distribution of any advertisement delivery software without user
                consent;
              </li>
              <li>
                Distribution of any application or code that covertly gathers or
                transmits user information;
              </li>
              <li>
                Unauthorized collection or use of personally identifiable
                information, financial or health data;
              </li>
              <li>
                Any conduct likely to result in retaliation against GKT eSIM or
                our Representatives.
              </li>
            </ul>
          </p>
        </>
      ),
    },
    {
      id: 3,
      header: "Bandwidth Abuse",
      text: (
        <>
          <p>
            Roaming Data services are intended for regular mobile internet
            access. Prohibited activities include:
          </p>
          <br />
          <ul className="pl-4">
            <li>
              Downloads or uploads of large files exceeding 300MB or multiple
              files totaling 300MB in size;
            </li>
            <li>
              Continuous, prolonged background transfers of non-viewable media;
            </li>
            <li>
              Continuous, prolonged streaming to TCP or UDP applications
              (excluding actively used multimedia);
            </li>
            <li>
              Use of eSIM-enabled devices as data routers for multiple other
              devices, except for normal Wi-Fi Hotspot usage;
            </li>
            <li>
              Providing Internet connectivity to stationary, non-portable
              devices using Wi-Fi Hotspot for extended periods;
            </li>
            <li>Use of BitTorrent and other P2P file sharing networks;</li>
            <li>
              Any application or activity consuming more than 150MB of data per
              hour.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      header: "Vulnerability Testing",
      text: (
        <>
          <p>
            You shall not attempt to probe, scan, penetrate, or test the
            vulnerability of GKT eSIM or any Service without express written
            consent.
            <br />
            Newsgroups, Chat Forums, Other Networks: Comply with rules for
            postings to bulletin boards, chat groups, or other forums. Adhere to
            the rules of any other network accessed while using the Services.
          </p>
        </>
      ),
    },
    {
      id: 5,
      header: "Offensive Content",
      text: (
        <>
          <p>
            You shall not process or link to content that does not comply with
            applicable law or GKT eSIM’s community standards, including content
            that:
          </p>
          <br />
          <ul className="pl-4">
            <li>
              Constitutes, depicts, fosters, promotes, or relates to illegal or
              explicit activities;
            </li>
            <li>
              Incites violence, threatens violence, or contains harassing
              content or hate speech;
            </li>
            <li>
              Violates consumer protection laws, including chain letters and
              pyramid schemes;
            </li>
            <li>Defames or violates a person's privacy;</li>
            <li>Creates a risk to safety, health, or national security;</li>
            <li>
              Improperly exposes trade secrets or confidential information;
            </li>
            <li>
              Promotes illegal drugs, violates export control laws, involves
              illegal gambling or trafficking;
            </li>
            <li>
              Is otherwise illegal or solicits conduct that is illegal under
              applicable laws.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 6,
      header: "Intellectual Property",
      text: (
        <>
          <p>
            You shall not use GKT eSIM's network or services to download,
            publish, display, distribute, copy, or use any protected work unless
            expressly authorised by the owner or by established Intellectual
            Property law.
          </p>
        </>
      ),
    },
    {
      id: 7,
      header: "Use of Assigned Identification",
      text: (
        <>
          <p>
            Use only assigned IP addresses in connection with your use of the
            Services. If a GKT eSIM IP address assigned to your Account becomes
            listed on an abuse database, it may result in suspension or
            termination, regardless of your actions.
          </p>
        </>
      ),
    },
    {
      id: 8,
      header: "Enforcement and Effect of Violation",
      text: (
        <>
          <p>
            Violation of this Policy may result in immediate interruption,
            suspension, or termination of your access and use of the Services at
            GKT eSIM’s sole discretion. GKT eSIM is not required to issue
            warnings or reprimands prior to taking such actions.
          </p>
        </>
      ),
    },
    {
      id: 9,
      header: "Changes",
      text: (
        <>
          <p>
            Amendments to this Policy may be made at any time. Check back
            frequently for any changes. Amendments become effective within
            fourteen (10) days of being posted by GKT eSIM or by your continued
            use of the Services, whichever is later.
          </p>
        </>
      ),
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleAUPSelectionToggle = (index) => {
    if (selectionActive === index) {
      setSelectionActive(null);
    } else {
      setSelectionActive(index);
    }
  };

    // google analytics

    useEffect(() => {
      document.title = "AUP page";
      document.querySelector('title').classList.add('notranslate');
    }, []);
  
     useEffect(() => {
      // Create a script element
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";
  
      // Append the script to the document body
      document.body.appendChild(script);
  
      // Initialize gtag
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CNPPGN8KCG');
      gtag('user_id', localStorage.getItem("ip"));
  
      // Clean up function to remove the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }, []); // Empty dependency array ensures that this effect runs only once  

  return (
    <div style={{ position: "relative" }} className="about-us-section">
      <header>
        <Header />
      </header>
      <section className="about-us-container container mt-5">
        <div className="row m-0">
          <div className="col-12">
            <h1 className="mb-3">Acceptable Use Policy</h1>
          </div>
          <div className="col-12 col-lg-6">
            <p style={{ fontSize: "18px" }} className="font-weight-bold">
              The AUP outlines permissible and impermissible uses of our
              connectivity services.
            </p>
            <p style={{ textAlign: "justify" }} className="m-0">
              GKT eSIM requires your usage of the Services to comply with
              applicable laws and align with our community standards. The
              purpose of this Acceptable Use Policy (“Policy”) is to communicate
              our expectations regarding your usage of the Services. These
              expectations are in harmony with those of the global Internet
              community and mobile networks worldwide. By utilising or
              continuing to use a Service, you acknowledge and consent to the
              rights, obligations, and practices described in this Policy.
            </p>
          </div>
          <div className="col-12 col-lg-6 order-2 d-flex justify-content-center align-items-start mb-4">
            <img
              className="d-none d-lg-block"
              src={aupBg}
              alt="aupBg"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
        <div className="card">
          <div className="card-body p-2">
            {AcceptableUsePolicy.map((AcceptableUsePolicy, index) => {
              return (
                <AccordionSelectionItem
                  key={index}
                  active={selectionActive}
                  handleToggle={handleAUPSelectionToggle}
                  AcceptableUsePolicy={AcceptableUsePolicy}
                />
              );
            })}
          </div>
        </div>
      </section>
      <CtaBanner />
      <img
        className="ref-right-grad-3"
        src={refRightGrad3}
        alt="refRightGrad1"
      />
      <img className="leftBottomImg" src={leftBottomImg} alt="" />
      <ContactUsForm />
      <footer className="mt-3">
        <Footer />
      </footer>
    </div>
  );
};

export default Aup;
