import React from "react";
import ReactHlsPlayer from "react-hls-player";
import { IoIosCloseCircle } from "react-icons/io";
import ReactPlayer from "react-player";

const VideoPlayer = ({ closeVideoPlayer, isVideoOff, videoSource, id }) => {
  // const videoSource = "https://res.cloudinary.com/dujuirl6p/video/upload/v1712763455/eSIM/Promo_1_tz0sjo.mp4";
  return (
    <div className="video-player dropdowns-modal justify-content-center">
      <div className="position-relative w-auto">
        <IoIosCloseCircle onClick={closeVideoPlayer} className="close-icon" />
        {id == "1" ?
           <ReactPlayer
           url={videoSource}
           style={{ width: '150%', height: '150%', borderRadius: 12}}
           controls
           playing
           loop
           muted
         /> :
          // <div style={{ width: '100%', height: '100%', borderRadius: 12 }}>
          <ReactPlayer
            url={videoSource}
            style={{ width: '150%', height: '150%', borderRadius: 12}}
            controls
            playing
            loop
            muted
          />
          // </div>
        }
      </div>
    </div>
  );
};

export default VideoPlayer;
