import React, { useState, useEffect, useRef } from "react";
import "../../scss/flag-sprite.css";
import sliderLogo_1 from "../../assets/img/slider_logo_insta.png";
import sliderLogo_2 from "../../assets/img/slider_logo_gktele.png";
import sliderLogo_3 from "../../assets/img/slider_logo_fb.png";
import sliderLogo_4 from "../../assets/img/gkt_final_logo.png";
import sliderLogo_5 from "../../assets/img/slider_logo_linkedin.png";

import listLeftGradient1 from "../../assets/img/gr-l-1.png";
import listLeftGradient2 from "../../assets/img/gr-l-2.png";
import listRightGradient1 from "../../assets/img/gr-r-1.png";
import listRightGradient2 from "../../assets/img/gr-r-2.png";

import Australia from "../../assets/img/mapicons/Australia.png";
import Asia from "../../assets/img/mapicons/Asia.png";
import Africa from "../../assets/img/mapicons/Africa.png";
import Europe from "../../assets/img/mapicons/Europe.png";
import Balkans from "../../assets/img/mapicons/Balkans.png";
import Caribbean from "../../assets/img/mapicons/Caribbean.png";
import Caucasus from "../../assets/img/mapicons/Caucasus.png";
import LatinAmerica from "../../assets/img/mapicons/LatinAmerica.png";
import MiddleEast from "../../assets/img/mapicons/MiddleEast.png";
import World from "../../assets/img/mapicons/World.png";

import PopularDestinationServices from "../../Services/PopularDestinationServices";
import RegionListService from "../../Services/RegionService";
import { useHistory } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";

// slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './YourComponent.css';

// import $ from "jquery";
import "slick-carousel";
import TrustBox from "../Common/TrustBox";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PlansBasedOnRegionService from "../../Services/PlansBasedOnRegionService ";
import { SlClose } from "react-icons/sl";
import GlobalPlansService from "../../Services/GlobalPlansService";
import toast from "react-hot-toast";
import { MdOutlineRocket } from "react-icons/md";
import t20WcIcon from "../../assets/img/mapicons/t20-wc-icon.jpg";

const ListSection = ({ listSectionRef }) => {
  const [CountrybasedOnRegion, setCountrybasedOnRegion] = useState([]);
  const [popuplarDestination, setPopularDestination] = useState([]);
  const [region, setRegion] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [regionId, setRegionId] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedAlpha2, setSelectedAlpha2] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [alpha2, setAlpha2] = useState();

  const [europeItems, setEuropeItems] = useState([]);
  const [asiaItems, setAsiaItems] = useState([]);
  const [africaItems, setAfricaItems] = useState([]);
  const [americasItems, setAmericasItems] = useState([]);
  const [oceaniaItems, setOceaniaItems] = useState([]);

  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [hasMoreAfricaItems, setHasMoreAfricaItems] = useState(true);
  const [hasMoreAsiaItems, setHasMoreAsiaItems] = useState(true);
  const [hasMoreEuropeItems, setHasMoreEuropeItems] = useState(true);
  const [hasMoreAmaricasItems, setHasMoreAmaricasItems] = useState(true);
  const [hasMoreOceaniaItems, setHasMoreOceaniaItems] = useState(true);

  const [visiblePopularItems, setVisiblePopularItems] = useState(10);
  const [visibleEuropeItems, setVisibleEuropeItems] = useState(10);
  const [visibleAsiaItems, setVisibleAsiaItems] = useState(10);
  const [visibleAfricaItems, setVisibleAfricaItems] = useState(10);
  const [visibleAmericasItems, setVisibleAmericasItems] = useState(10);
  const [visibleOceaniaItems, setVisibleOceaniaItems] = useState(10);

  // const history = useHistory();

  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  // const [europeItems, setEuropeItems] = useState([]);
  // const [asiaItems, setAsiaItems] = useState([]);
  // const [africaItems, setAfricaItems] = useState([]);
  // const [americasItems, setAmericasItems] = useState([]);
  // const [oceaniaItems, setOceaniaItems] = useState([]);
  // const [countryBasedOnRegion, setCountrybasedOnRegion] = useState([]);
  // const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  // const history = useHistory();
  // const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  // const [isValidSuggestion, setIsValidSuggestion] = useState(true);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [changeImg, setChangeImg] = useState(true);
  const [image, setImage] = useState();
  const [dummy, setDummy] = useState();
  // const [userInput, setUserInput] = useState("");
  // const [suggestions, setSuggestions] = useState([]);
  // const [regions, setRegions] = useState([]);
  // const [countries, setCountries] = useState([]);
  // const [europeItems, setEuropeItems] = useState([]);
  // const [asiaItems, setAsiaItems] = useState([]);
  // const [africaItems, setAfricaItems] = useState([]);
  // const [americasItems, setAmericasItems] = useState([]);
  // const [oceaniaItems, setOceaniaItems] = useState([]);
  // const [countryBasedOnRegion, setCountrybasedOnRegion] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const history = useHistory();
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [isValidSuggestion, setIsValidSuggestion] = useState(true);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // clientSlider
  const clientImageSlider = [
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
    {
      image: sliderLogo_1,
      link: "https://www.instagram.com/gktesim",
    },
    {
      image: sliderLogo_2,
      link: "https://gktelecom.com/",
    },
    {
      image: sliderLogo_3,
      link: "https://www.facebook.com/gktesim",
    },
    {
      image: sliderLogo_4,
      link: "https://esim-mobile.co.uk",
    },
    {
      image: sliderLogo_5,
      link: "https://www.linkedin.com/company/gktesim/?viewAsMember=true",
    },
  ];

  const handleDragStart = (e) => e.preventDefault();

  const items = clientImageSlider.map((ele, index) => (
    <a
      className="d-flex justify-content-center align-items-center"
      target="_blank"
      href={ele.link}
      // onDragStart={handleDragStart}
      // role="presentation"
      key={index}
    >
      <img style={{ width: "60px" }} src={ele.image} />
    </a>
  ));

  // useEffect(() => {
  //   $(document).ready(function () {
  //     $(".customer-logos").slick({
  //       slidesToShow: 6,
  //       slidesToScroll: 1,
  //       autoplay: true,
  //       autoplaySpeed: 1000,
  //       arrows: false,
  //       dots: false,
  //       pauseOnHover: true,
  //       responsive: [
  //         {
  //           breakpoint: 768,
  //           settings: {
  //             slidesToShow: 4,
  //           },
  //         },
  //         {
  //           breakpoint: 520,
  //           settings: {
  //             slidesToShow: 3,
  //           },
  //         },
  //       ],
  //     });
  //   });
  // }, []);

  useEffect(() => {
    PopularDestinationListApi();
    RegionListApi();
    countryAccordingToRegion();
  }, []);

  useEffect(() => {
    if (isLoading === true) {
      // Add this conditional check
      RegionListApi(); // Call the API function to get the region data
    }
  }, []);

  const handleInputChange = (event) => {
    setActiveSuggestionIndex(0);

    const userInputValue = event.target.value.trim();
    setUserInput(userInputValue);

    if (userInputValue === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    // Convert user input to lowercase
    const userInputValueLowercase = userInputValue.toLowerCase();

    // Filter regions based on user input
    const regionSuggestions = regions.filter((region) =>
      region.regionDescription.toLowerCase().startsWith(userInputValueLowercase)
    );

    // Filter countries based on user input
    const countrySuggestions = allCountries.filter((country) =>
      country.countryDescription
        .toLowerCase()
        .startsWith(userInputValueLowercase)
    );

    // Combine region and country suggestions and set the suggestions state
    const combinedSuggestions = [
      ...regionSuggestions.map((region) => ({
        suggestionType: "region",
        description: region.regionDescription,
        regionId: region.regionId,
      })),
      ...countrySuggestions.map((country) => ({
        suggestionType: "country",
        description: country.countryDescription,
        Alpha2: country.alpha2,
      })),
    ];
    setIsValidSuggestion(combinedSuggestions.length > 0);
    setSuggestions(combinedSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionSearch = (suggestion) => {
    if (suggestion.suggestionType === "region") {
      history.push(`/rplans/${suggestion.regionId}/${suggestion.description}`);
    } else if (suggestion.suggestionType === "country") {
      history.push(`/plans/${suggestion.Alpha2}/${suggestion.description}`);
    }
  };

  const handleSearch = (suggestionType, regionId, Alpha2, description) => {
    if (suggestionType === "region") {
      setUserInput(description);
      history.push(`/rplans/${regionId}/${description}`);
    } else if (suggestionType === "country") {
      setUserInput(description);
      history.push(`/plans/${Alpha2}/${description}`);
    }
  };

  const activeSuggestionRef = useRef(null);

  const handleKeyDown = (event) => {
    const activeSuggestionElement = activeSuggestionRef.current;

    if (event.key === "ArrowDown") {
      // Move to the next suggestion when the down arrow key is pressed
      setActiveSuggestionIndex(
        (prevIndex) => (prevIndex + 1) % suggestions.length
      );
    } else if (event.key === "ArrowUp") {
      // Move to the previous suggestion when the up arrow key is pressed
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
    } else if (event.key === "Enter") {
      // Handle the Enter key press separately
      if (suggestions.length > 0 && suggestions[activeSuggestionIndex]) {
        // Set the selected suggestion before calling handleSearch
        setUserInput(suggestions[activeSuggestionIndex].description);
        setShowSuggestions(false); // Hide the suggestion list when Enter is pressed

        handleSearch(
          suggestions[activeSuggestionIndex].suggestionType,
          suggestions[activeSuggestionIndex].regionId,
          suggestions[activeSuggestionIndex].Alpha2,
          suggestions[activeSuggestionIndex].description
        );
      }
    }

    // Scroll the active suggestion into view when using the arrow keys
    if (activeSuggestionElement) {
      activeSuggestionElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const allCountries = [
    ...europeItems,
    ...asiaItems,
    ...africaItems,
    ...americasItems,
    ...oceaniaItems,
  ];

  const [regionCountry, setRegionCountry] = useState([]);
  // const [regionCountryList, setRegionCountryList] = useState([]);
  const [countryTicketIndex, setCountryTicketIndex] = useState();
  const [esimForIndex, setEsimForIndex] = useState([]);
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);

  useEffect(() => {
    if (region != null) {
      let target = region.map((items) => items);
      setRegionCountry(target);
    }
  }, [region]);

  const coverageDropdownRef = useRef(null);
  const openCoverageDropdown = (index, regionId) => {
    setIsCoverageDropdownOpen(true);
    document.body.style.overflowY = "hidden";
    setCountryTicketIndex(index);
    // setEsimForIndex(esimIndex)
  };
  var regionIndex = regionCountry[countryTicketIndex];
  const closeCoverageDropdown = () => {
    setIsCoverageDropdownOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const CoverageDropdown = ({ planCountry }) => {
    // let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);
    useEffect(() => {
      const filtered = planCountry.filter(
        (item) =>
          item &&
          item.countryDescription
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, planCountry]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          <h4>
            Coverage is provided by <span>{planCountry.length}</span> countries
          </h4>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2">
                          <p>{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const showNoCountriesToast = () => {
    toast.error("No countries available!");
  };

  const [message, setMessage] = useState("");
  const [globalCountries, setGlobalCountries] = useState("");
  useEffect(() => {
    GlobalPlansService.globalCountryList()
      .then((response) => {
        if (response.status == 200) {
          setGlobalCountries(response.data);
          // console.log("globalCountries",globalCountries);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason);
              // setErrorCode(401)
              break;
            case 403:
              setMessage(error.response.data.failedReason);
              break;
            case 500:
              setMessage(error.response.data.failedReason);
              break;
            case 404:
            case 406:
              setMessage("406");
              break;
            case 412:
              setMessage(error.response.data.failedReason);
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
  }, []);

  const CoverageDropdownForGlobal = () => {
    let [country, setCountry] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (globalCountries !== null) {
        setCountry(globalCountries);
      }
    }, []);

    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="dropdowns-modal">
        <div className="coverage-dropdown">
          <div className="w-100" ref={globalCoverageDropdownRef}>
            <h4>
              Coverage is provided by <span>{globalCountries.length}</span>{" "}
              countries
            </h4>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className="coverage-dropdown-list mt-2">
              <ul>
                {searchTerm && filteredCountries.length === 0 ? (
                  <li className="no-results">No results found</li>
                ) : (
                  filteredCountries.map((item, index) => (
                    <li key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div>
                            <span
                              key={index}
                              className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                            ></span>
                          </div>
                          <div className="ml-2">
                            <p>{item.countryDescription}</p>
                          </div>
                        </div>
                        {item.planCountryNetwork !== null ? (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            {item.planCountryNetwork.map(
                              (network, networkIndex) => (
                                <span key={networkIndex}>{network}</span>
                              )
                            )}
                          </div>
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <span></span>
                          </div>
                        )}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <SlClose className="modal-close-icon" />
        </div>
      </div>
    );
  };
  const globalCoverageDropdownRef = useRef(null);
  const [isCoverageDropdownOpenForGlobal, setIsCoverageDropdownOpenForGlobal] =
    useState();
  const openCoverageDropdownForGlobal = () => {
    setIsCoverageDropdownOpenForGlobal(true);
    document.body.style.overflowY = "hidden";
  };

  const closeCoverageDropdownForGlobal = () => {
    setIsCoverageDropdownOpenForGlobal(false);
    document.body.style.overflowY = "auto";
  };

  const handleCoverageClickForGlobal = (event) => {
    if (
      isCoverageDropdownOpenForGlobal &&
      globalCoverageDropdownRef.current &&
      !globalCoverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdownForGlobal();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClickForGlobal);

    return () => {
      document.removeEventListener("click", handleCoverageClickForGlobal);
    };
  }, [isCoverageDropdownOpenForGlobal]);

  const PopularDestinationListApi = () => {
    PopularDestinationServices.popularDestinationList().then((response) => {
      // console.log(
      //   "list",
      //   response.data.map((pD) => pD.country.countryDescription)
      // );
      setPopularDestination(response.data);
    });
  };
  const RegionListApi = () => {
    RegionListService.regionList().then((response) => {
      setRegion(response.data);
      setIsLoading(false);
      // setRegionId(response.data.map((rId)=>rId.regionId));
    });
  };
  const countryAccordingToRegion = () => {
    RegionListService.countryAccordingToRegionList().then((response) => {
      // console.log(
      //   "countryAccordingToRegion",
      //   response.data.filter(
      //     (country) => country.subRegion.region.regionDescription === "Africa"
      //   ).length
      // );
      setCountrybasedOnRegion(response.data);
      setEuropeItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Europe"
        )
      );
      setAsiaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Asia"
        )
      );
      setAfricaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Africa"
        )
      );
      setAmericasItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Americas"
        )
      );
      setOceaniaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Oceania"
        )
      );
    });
  };

  const regionClick = (regionId, region) => {
    setSelectedRegionId(regionId);
    setSelectedRegion(region);
  };

  const globalClick = () => {
    history.push(`/gplans`);
  };

  useEffect(() => {
    if (selectedRegionId !== undefined) {
      history.push(`/rplans/${selectedRegionId}/${selectedRegion}`);
    }
    // Perform any action you want with the updated selectedRegionId here
  }, [selectedRegionId]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const countryClick = (alpha2, country) => {
    setSelectedAlpha2(alpha2);
    setSelectedCountry(country);
  };
  useEffect(() => {
    if (selectedAlpha2 !== undefined) {
      history.push(`/plans/${selectedAlpha2}/${selectedCountry}`);
    }
    // Perform any action you want with the updated selectedRegionId here
  }, [selectedAlpha2]);

  const handlePopularViewMore = () => {
    if (visiblePopularItems + 5 >= popuplarDestination.length) {
      setHasMoreItems(false);
    }
    setVisiblePopularItems(visiblePopularItems + 5);
  };

  const handleEuropeViewMore = () => {
    if (visibleEuropeItems + 5 >= europeItems.length) {
      setHasMoreEuropeItems(false);
    }
    setVisibleEuropeItems(visibleEuropeItems + 5);
  };
  const handleAsiaViewMore = () => {
    if (visibleAsiaItems + 5 >= asiaItems.length) {
      setHasMoreAsiaItems(false);
    }
    setVisibleAsiaItems(visibleAsiaItems + 5);
  };
  const handleAfricaViewMore = () => {
    if (visibleAfricaItems + 5 >= africaItems.length) {
      setHasMoreAfricaItems(false);
    }
    setVisibleAfricaItems(visibleAfricaItems + 5);
  };
  const handleAmericasViewMore = () => {
    if (visibleAmericasItems + 5 >= americasItems.length) {
      setHasMoreAmaricasItems(false);
    }
    setVisibleAmericasItems(visibleAmericasItems + 5);
  };
  const handleOceaniaViewMore = () => {
    if (visibleOceaniaItems + 5 >= oceaniaItems.length) {
      setHasMoreOceaniaItems(false);
    }
    setVisibleOceaniaItems(visibleOceaniaItems + 5);
  };

  const popularList = () => {
    return popuplarDestination
      .slice(0, visiblePopularItems)
      .map((item, index) => (
        <div
          className={`grid-card ${
            selectedAlpha2 === item.country.alpha2 ? selectedAlpha2 : ""
          }`}
          key={item.country.alpha2}
          onClick={() =>
            countryClick(item.country.alpha2, item.country.countryDescription)
          }
        >
          <div>
            <div>
              <span
                className={`fflag fflag-${item.country.alpha2} ff-lg ff-round`}
              ></span>
            </div>
          </div>
          <div className="grid-tray" style={{ overflow: "hidden" }}>
            <p>
              {item.country.countryDescription} - {item.cityDescription}
            </p>
          </div>
        </div>
      ));
  };

  const europeList = () => {
    const selectedA2 = selectedAlpha2;
    return europeItems.slice(0, visibleEuropeItems).map((item, index) => (
      <div
        className={`grid-card ${selectedA2 === item.alpha2 ? selectedA2 : ""}`}
        key={item.alpha2}
        onClick={() => countryClick(item.alpha2, item.countryDescription)}
      >
        <div>
          <span className={`fflag fflag-${item.alpha2} ff-lg ff-round`}></span>
        </div>
        <div className="grid-tray" style={{ overflow: "hidden" }}>
          <p>{item.countryDescription}</p>
        </div>
      </div>
    ));
  };

  const AsiaList = () => {
    const selectedA2 = selectedAlpha2;
    return asiaItems.slice(0, visibleAsiaItems).map((item, index) => (
      <div
        className={`grid-card ${selectedA2 === item.alpha2 ? selectedA2 : ""}`}
        key={item.alpha2}
        onClick={() => countryClick(item.alpha2, item.countryDescription)}
      >
        <div>
          <span className={`fflag fflag-${item.alpha2} ff-lg ff-round`}></span>
        </div>
        <div className="grid-tray" style={{ overflow: "hidden" }}>
          <p>{item.countryDescription}</p>
        </div>
      </div>
    ));
  };

  const AmericasList = () => {
    const selectedA2 = selectedAlpha2;
    return americasItems.slice(0, visibleAmericasItems).map((item, index) => (
      <div
        className={`grid-card ${selectedA2 === item.alpha2 ? selectedA2 : ""}`}
        key={item.alpha2}
        onClick={() => countryClick(item.alpha2, item.countryDescription)}
      >
        <div>
          <span className={`fflag fflag-${item.alpha2} ff-lg ff-round`}></span>
        </div>
        <div className="grid-tray" style={{ overflow: "hidden" }}>
          <p>{item.countryDescription}</p>
        </div>
      </div>
    ));
  };

  const AfricaList = () => {
    const selectedA2 = selectedAlpha2;
    return africaItems.slice(0, visibleAfricaItems).map((item, index) => (
      <div
        className={`grid-card ${selectedA2 === item.alpha2 ? selectedA2 : ""}`}
        key={item.alpha2}
        onClick={() => countryClick(item.alpha2, item.countryDescription)}
      >
        <div>
          <span className={`fflag fflag-${item.alpha2} ff-lg ff-round`}></span>
        </div>
        <div className="grid-tray" style={{ overflow: "hidden" }}>
          <p>{item.countryDescription}</p>
        </div>
      </div>
    ));
  };

  const OceaniaList = () => {
    const selectedA2 = selectedAlpha2;
    return oceaniaItems.slice(0, visibleOceaniaItems).map((item, index) => (
      <div
        className={`grid-card ${selectedA2 === item.alpha2 ? selectedA2 : ""}`}
        key={item.alpha2}
        onClick={() => countryClick(item.alpha2, item.countryDescription)}
      >
        <div>
          <span className={`fflag fflag-${item.alpha2} ff-lg ff-round`}></span>
        </div>
        <div className="grid-tray" style={{ overflow: "hidden" }}>
          <p>{item.countryDescription}</p>
        </div>
      </div>
    ));
  };

  // const clientSlider = () => {
  //   return clientImageSlider.map((item, index) => (
  //     <div className="slide" key={index}>
  //       <a href={item.link}>
  //         <img src={item.image} alt="logo" />
  //       </a>
  //     </div>
  //   ));
  // };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const responsive = {
    0: {
      items: 4,
    },
    576: {
      items: 5,
    },
    1000: {
      items: 6,
    },
    1500: {
      items: 8,
    },
  };

  const autoPlayStrategy = screenSize < 500 ? "none" : "default";

  // const dynamicSize =(screenSize < '500px') ? autoPlayStrategy='none' : autoPlayStrategy = 'default'

  return (
    <>
      <div className="list-section list-padding">
        <div style={{ display: "none" }} className="search-section">
          <div className="search-bar" style={{ position: "relative" }}>
            <input
              placeholder="Search by country or continent..."
              type="text"
              value={userInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />

            <button
              onClick={() => {
                if (suggestions[activeSuggestionIndex]?.suggestionType) {
                  const selectedSuggestion = suggestions[activeSuggestionIndex];
                  handleSearch(
                    selectedSuggestion.suggestionType,
                    selectedSuggestion.regionId,
                    selectedSuggestion.Alpha2,
                    selectedSuggestion.description
                  );
                }
                // if (selectedSuggestion?.suggestionType) {
                //   handleSearch(
                //     selectedSuggestion.suggestionType,
                //     selectedSuggestion.regionId,
                //     selectedSuggestion.Alpha2,
                //     selectedSuggestion.description
                //   );
                // }
              }}
            >
              Search
            </button>

            {showSuggestions && suggestions.length > 0 && (
              <div className="search-suggestion-section">
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className={
                        index === activeSuggestionIndex
                          ? "suggestion-active"
                          : ""
                      }
                      ref={
                        index === activeSuggestionIndex
                          ? activeSuggestionRef
                          : null
                      }
                      onClick={() => {
                        handleSuggestionSearch(suggestion);
                        setUserInput(suggestion.description);
                        setSelectedSuggestion(suggestion);
                        setSuggestions([]);
                        setShowSuggestions(false); // Hide the suggestion list when a suggestion is selected
                      }}
                    >
                      {suggestion.suggestionType === "region" ? (
                        <span>{suggestion.description}</span>
                      ) : (
                        <span>{suggestion.description}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!isValidSuggestion && (
              <div className="search-suggestion-section">No result found</div>
            )}
          </div>
        </div>

        <div className="container">
          <div style={{ position: "relative", zIndex: "4" }} className="row">
            <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
              <h2 className="">Popular destination</h2>
              <div className="grid-container">{popularList()}</div>
              <div className="d-flex  justify-content-center align-items-center my-2 my-lg-4">
                <div className="col-md-4 col-12 p-0">
                  {hasMoreItems && (
                    <button
                      className="view-more-btn btn btn-sm"
                      onClick={handlePopularViewMore}
                    >
                      View More
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
              <h2 className="">Regions</h2>
              <div className="grid-container">
                {region.map((item, index) => (
                  <div
                    className={`grid-custom-card h-auto ${
                      selectedRegionId === item.regionId ? regionId : ""
                    }`}
                    key={item.regionId}
                  >
                    <div className="w-100 h-auto py-2 d-flex justify-content-start align-items-center">
                      <span
                        onClick={() =>
                          regionClick(item.regionId, item.regionDescription)
                        }
                        className={`${
                          item.regionDescription == "Europe" ? "fflag-EU" : ""
                        } fflag ff-xl ff-round mr-2`}
                      >
                        {item.regionDescription != "Europe" ? (
                          <img
                            className="regionLogo"
                            src={
                              item.regionDescription == "Africa"
                                ? Africa
                                : item.regionDescription == "Asia"
                                ? Asia
                                : item.regionDescription == "Australia"
                                ? Australia
                                : item.regionDescription == "Caribbean"
                                ? Caribbean
                                : item.regionDescription == "Latin America"
                                ? LatinAmerica
                                : item.regionDescription == "Balkans"
                                ? Balkans
                                : item.regionDescription == "Caucasus"
                                ? Caucasus
                                : item.regionDescription == "Middle East"
                                ? MiddleEast
                                : item.regionDescription == "T20 WC Region 1"
                                ? t20WcIcon
                                : item.regionDescription == "T20 WC Region 2" 
                                ? t20WcIcon
                                : World
                            }
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <div>
                        <p
                          onClick={() =>
                            regionClick(item.regionId, item.regionDescription)
                          }
                          className="m-0 region-title"
                        >
                          {item.regionDescription}
                        </p>

                        <div
                          onClick={() => {
                            openCoverageDropdown(index, item.regionId);
                            // open coverage dropdown here
                          }}
                          className="countries-label-dropdown d-flex justify-content-start align-items-center"
                        >
                          {item.countrys && (
                            <span>{item.countrys.length} Countries</span>
                          )}
                          <IoMdArrowDropdown />
                        </div>
                      </div>
                    </div>
                    {/* <div className="line-seperator"></div> */}
                  </div>
                ))}
              </div>
              <h2 className="mt-5">Global plans</h2>
              <div
                style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
                className="grid-container"
              >
                {message !== "406" ? (
                  <div className="grid-custom-card h-auto">
                    <div className="w-100 h-auto py-2 d-flex justify-content-start align-items-center">
                      <span
                        onClick={() => {
                          globalClick();
                        }}
                        className="fflag ff-xl ff-round mr-2"
                      >
                        <img className="regionLogo" src={World} />
                      </span>
                      <div>
                        <p
                          onClick={() => {
                            globalClick();
                          }}
                          className="m-0 region-title"
                        >
                          Explore world
                        </p>
                        <div
                          onClick={() => {
                            message !== "406"
                              ? openCoverageDropdownForGlobal()
                              : showNoCountriesToast();
                          }}
                          className="countries-label-dropdown d-flex justify-content-start align-items-start"
                        >
                          <span>{globalCountries.length} Countries</span>
                          <IoMdArrowDropdown />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ cursor: "none", pointerEvents: "none" }}
                    className="grid-card"
                  >
                    <MdOutlineRocket style={{ fontSize: "24px" }} />

                    <p style={{ fontStyle: "italic" }}>Launching Soon...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ position: "relative", zIndex: "4" }} className="row">
            {africaItems.length > 0 && (
              <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
                <h3 className="notranslate">Africa</h3>
                <div className="grid-container">{AfricaList()}</div>
                <div className="d-flex  justify-content-center align-items-center my-2 my-lg-4">
                  <div className="col-md-4 col-12 p-0">
                    {hasMoreAfricaItems && africaItems.length > 10 ? (
                      <button
                        className="view-more-btn btn btn-sm"
                        onClick={handleAfricaViewMore}
                      >
                        View More
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
              <h3 className="notranslate">America</h3>
              <div className="grid-container">{AmericasList()}</div>
              <div className="d-flex  justify-content-center align-items-center my-2 my-lg-4">
                <div className="col-md-4 col-12 p-0">
                  {hasMoreAmaricasItems && americasItems.length > 10 ? (
                    <button
                      className="view-more-btn btn btn-sm"
                      onClick={handleAmericasViewMore}
                    >
                      View More
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
              <h3 className="notranslate">Asia</h3>
              <div className="grid-container">{AsiaList()}</div>
              <div className="d-flex  justify-content-center align-items-center my-2 my-lg-4">
                <div className="col-md-4 col-12 p-0">
                  {hasMoreAsiaItems && asiaItems.length > 10 ? (
                    <button
                      className="view-more-btn btn btn-sm"
                      onClick={handleAsiaViewMore}
                    >
                      View More
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
              <h3 className="notranslate">Europe</h3>
              <div className="grid-container">{europeList()}</div>
              <div className="d-flex  justify-content-center align-items-center my-2 my-lg-4">
                <div className="col-md-4 col-12 p-0">
                  {hasMoreEuropeItems && europeItems.length > 10 ? (
                    <button
                      className="view-more-btn btn btn-sm"
                      onClick={handleEuropeViewMore}
                    >
                      View More
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 p-0 p-md-2">
              <h3 className="notranslate">Oceania</h3>
              <div className="grid-container">{OceaniaList()}</div>
              <div className="d-flex  style={{color: '#fff'}}  justify-content-center align-items-center my-2 my-lg-4">
                <div className="col-md-4 col-12 p-0">
                  {hasMoreOceaniaItems && oceaniaItems.length > 10 ? (
                    <button
                      className="view-more-btn btn btn-sm"
                      onClick={handleOceaniaViewMore}
                    >
                      View More
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Client slider */}
          <div style={{ position: "relative", zIndex: "4" }} className="row">
            <div
              className="slider"
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AliceCarousel
                mouseTracking
                infinite
                autoPlayInterval={500}
                animationDuration={1500}
                disableDotsControls
                disableButtonsControls
                responsive={responsive}
                items={items}
                autoPlay
                autoPlayStrategy={autoPlayStrategy}
                // mouseTracking
                // autoPlay
                // infinite
                // // touchTracking={false}
                // autoPlayInterval={800}
                // animationDuration={1500}
                // disableDotsControls
                // disableButtonsControls
                // responsive={responsive}
                // items={items}
                // paddingLeft={20}
                // paddingRight={20}
              />
            </div>
          </div>
          <div className="trust-pilot">
            <TrustBox />
          </div>
        </div>
        {/* <img
          className="listLeftGradient1"
          src={listLeftGradient1}
          alt="listGradient1"
        />
        <img
          className="listRightGradient1"
          src={listRightGradient1}
          alt="listGradient2"
        />
        <img
          className="listLeftGradient2"
          src={listLeftGradient2}
          alt="listGradient2"
        />
        <img
          className="listRightGradient2"
          src={listRightGradient2}
          alt="listGradient1"
        /> */}
        {isCoverageDropdownOpen ? (
          <div className="dropdowns-modal">
            <CoverageDropdown
              planCountry={
                regionIndex !== undefined ? regionIndex.countrys : []
              }
            />
          </div>
        ) : null}

        {isCoverageDropdownOpenForGlobal ? (
          // <div className="dropdowns-modal">
          <CoverageDropdownForGlobal />
        ) : // </div>
        null}
      </div>
    </>
  );
};

export default ListSection;
