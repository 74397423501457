import React, { useState, useEffect, useRef } from "react";
import orderCardBg from "../../assets/img/order-card-bg.png";
import { RxDropdownMenu } from "react-icons/rx";
import { ImInfo } from "react-icons/im";
import { BiCalendar } from "react-icons/bi";
import OrderListComp from "./OrderListComp";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { SlClose } from "react-icons/sl";
import AuthenticationServiceComponent from "../Login/AuthenticationServiceComponent";
import AuthenticationService from "../Common/AuthenticationService";
import OrderService from "../../Services/OrderService";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import Loader from "../Alert_Popup/Loader";
import Header from "../Header/Header";
import ContactUsForm from "../Common/ContactUsForm";
import { CiDiscount1 } from "react-icons/ci";
import MicroLoader from "../Alert_Popup/MicroLoader";
import toast, { Toaster } from "react-hot-toast";
import ApplyVoucherService from "../../Services/ApplyVoucherService";
import AlertPopup from "../Alert_Popup/AlertPopup";
import { jwtDecode } from 'jwt-decode';

const OrderSummary = (props) => {
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);

  const [value, onChange] = useState(new Date());
  const [isPolicyOpen, setIsPolicyOpen] = useState(true);
  const history = useHistory();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const coverageDropdownRef = useRef(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [deviceChecked, setDeviceChecked] = useState(false);
  const [message, setMessage] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [applyClick, setApplyClick] = useState(false);

  const [orderDetails, setOrderDetails] = useState({
    orderId: 0,
    orderSource: {
      orderSourceId: 4,
    },
    orderType: {
      orderTypeId: localStorage.getItem("topUpIccid") && localStorage.getItem("topUpIccid") !== "null" ? 2 : 1,
    },
    orderStatus: {
      orderStatusId: 1,
    },
    orderPlan: [
      {
        esim: null,
        orderPlanId: 0,
        plan: {
          planId: 0,
        },
        quantity: 1,
        amount: 0,
        currency: null,
        // planStartDate: "" // Initialize with an empty string, you'll set it later
      },
    ],
    customer: {
      id: localStorage.getItem("userId"),
    },

    amount: 0,
    subtotalAmount: 0,
    currency: "",
    termAgreed: 1,
    deviceCompatibilityAgreed: 1,
    voucher: null,
  });

  // AuthenticationService.setupAxiosInterceptors();
  // const { location } = props;
  // const { state } = location;
  // const plan = state && state.plan;
  const [planObject, setPlanObject] = useState(null);
  const [checkDiscount, setCheckDiscount] = useState(false);

  useEffect(() => {
    if (planObject !== null) {
      setOrderDetails({
        orderId: 0,
        orderSource: {
          orderSourceId: 4,
        },
        orderType: {
          orderTypeId: localStorage.getItem("topUpIccid") && localStorage.getItem("topUpIccid") !== "null" ? 2 : 1,
        },
        orderStatus: {
          orderStatusId: 1,
        },
        orderPlan: [
          {
            esim: esimObject !== null
              ?
              [esimObject]
              : null,
            orderPlanId: 0,
            plan: {
              planId: Number(planObject.planId),
            },
            quantity: 1,
            amount: Number(planObject.price),
            currency: planObject.currency,
            // planStartDate: formatDate(selectedStartDate) // Initialize with an empty string, you'll set it later
          },
        ],
        customer: {
          id: Number(localStorage.getItem("userId")),
        },

        amount: Number(planObject.price),
        subtotalAmount: Number(planObject.price),
        currency: planObject.currency,
        termAgreed: 1,
        deviceCompatibilityAgreed: 1,
        voucher: null,
      });
    }

  }, [planObject]);

  const [showAlertForVoucher, setShowAlertForVoucher] = useState(false)
  console.log("clicked", showAlertForVoucher)
  const orderAPI = async () => {
    const updatedOrderDetails = { ...orderDetails };
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      if (!termsChecked || !deviceChecked) {
        setMessage("Please check both checkboxes before proceeding.");
        return; // Don't proceed further
      }
      else if (termsChecked && deviceChecked) {
        setMessage("")
        if (!applyClick && (document.getElementById('voucherField').value).trim() !== "") {
          setShowAlertForVoucher(true)
        }
        else {
          setShowAlertForVoucher(false)
          setShowLoader(true);
          setMessage("");
          await OrderService.order(updatedOrderDetails)
            .then((response) => {
              if (response.status === 200) {

                // Open link in a new tab
                if (response.data.payment === null) {
                  OrderService.orderAfterPayment(response.data.orderIdEncrypted)
                    .then((response) => {
                      if (response.status === 200 && response.data.orderStatus.orderStatusId !== 1) {
                        setShowLoader(false)
                        localStorage.setItem("orderSummaryAfterPayment", JSON.stringify(response.data));
                        // clearInterval(orderInterval); // Stop calling after success
                        history.push('/OrderConfirmation');
                      }
                    })
                }
                else {
                  const link = response.data.payment.link;
                  // if (link) {
                  //     const newTab = window.open(link, '_blank', 'noopener,noreferrer');
                  //     if (newTab) {
                  //         newTab.blur(); // Blur the new tab to prevent focus steal
                  //       }
                  //     }
                  // Redirect to /timer
                  if (link) {
                    window.location.href = link; // Redirect to the link in the same tab
                  }
                  // history.push(`/countdowntimer/${response.data.orderId}`);
                }
              }
            })
            .catch((error) => {
              // Handle errors here
              if (error.message === "Network Error") {
                setMessage("Network Error");
              } else {
                switch (error.response ? error.response.status : "") {
                  case 401:
                    setMessage(error.response.data.failedReason);
                    setShowLoader(false)
                    // setErrorCode(401)
                    break;
                  case 403:
                    setMessage(error.response.data.failedReason);
                    setShowLoader(false)
                    break;
                  case 500:
                    setMessage(error.response.data.failedReason);
                    setShowLoader(false)
                    break;
                  case 404:
                  case 406:
                    setMessage(error.response.data.failedReason);
                    setShowLoader(false)
                    break;
                  case 412:
                    setMessage(error.response.data.failedReason);
                    setShowLoader(false)
                    break;
                  default:
                    setMessage("Unknown Error");
                    setShowLoader(false)
                    break;
                }
              }
            });
        }
      }
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const [esimObject, setEsimObject] = useState(null)
  useEffect(() => {
    localStorage.removeItem("countdownSeconds");
    const storedPlan = localStorage.getItem("plan");
    const esim = localStorage.getItem('topUpIccid')
    if (storedPlan) {
      const parsedPlan = JSON.parse(storedPlan);
      setPlanObject(parsedPlan);
    }
    if (esim) {
      const parsedEsim = JSON.parse(esim);
      setEsimObject(parsedEsim);
    }

  }, []);

  const openCoverageDropdown = () => {
    document.body.style.overflowY = "hidden";
    setIsCoverageDropdownOpen(true);
  };

  const closeCoverageDropdown = () => {
    document.body.style.overflowY = "auto";

    setIsCoverageDropdownOpen(false);
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const CoverageDropdown = () => {
    let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (planObject !== null) {
        setCountry(planObject.planCountry);
      }
    }, []);

    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          {planObject !== null && (
            <h4>
              Coverage is provided by{" "}
              <span>{planObject.planCountry.length}</span> countries
            </h4>
          )}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2 d-flex justify-content-center align-items-center">
                          <p className="m-0">{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>

        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);
  const networkDropdownRef = useRef(null);

  const openNetworkDropdown = () => {
    setIsNetworkDropdownOpen(true);
  };

  const closeNetworkDropdown = () => {
    setIsNetworkDropdownOpen(false);
  };

  const handleNetworkClick = (event) => {
    if (
      isNetworkDropdownOpen &&
      networkDropdownRef.current &&
      !networkDropdownRef.current.contains(event.target)
    ) {
      closeNetworkDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleNetworkClick);

    return () => {
      document.removeEventListener("click", handleNetworkClick);
    };
  }, [isNetworkDropdownOpen]);

  const NetworkDropdown = () => {
    let [country, setCountry] = useState([
      "Australia",
      "France",
      "India",
      "Australia",
      "France",
      "India",
      "Australia",
      "France",
      "India",
      "Australia",
      "France",
      "India",
    ]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];
    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={networkDropdownRef}>
          <h4>
            Network is available in <span>14</span> countries
          </h4>
          <input type="text" placeholder="Search..." />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {country.map((item, index) => (
                <li key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <span
                          key={index}
                          className={`fflag fflag-AU ff-lg ff-round`}
                        ></span>
                      </div>
                      <div className="ml-2">
                        <p>{item}</p>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <span>jio</span>
                      <span>airtel</span>
                      <span>airtel</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const [isCalendarDropdownOpen, setIsCalendarDropdownOpen] = useState(false);
  const calendarDropdownRef = useRef(null);

  const openCalendarDropdown = () => {
    setIsCalendarDropdownOpen(true);
  };

  const closeCalendarDropdown = () => {
    setIsCalendarDropdownOpen(false);
  };

  const handleCalendarClick = (event) => {
    if (
      isCalendarDropdownOpen &&
      calendarDropdownRef.current &&
      !calendarDropdownRef.current.contains(event.target)
    ) {
      closeCalendarDropdown();
    }
  };

  const handleDateChange = (date) => {
    setSelectedStartDate(date);
    closeCalendarDropdown();
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  useEffect(() => {
    document.addEventListener("click", handleCalendarClick);

    return () => {
      document.removeEventListener("click", handleCalendarClick);
    };
  }, [isCalendarDropdownOpen]);

  const CalendarDropdown = () => {
    return (
      <div style={{ top: "-255px" }} className="calendar-dropdown">
        <Calendar
          onChange={handleDateChange}
          value={selectedStartDate}
          minDate={new Date()}
        />
      </div>
    );
  };
  const [count, setCount] = useState(1);
  // console.log(count);
  const [voucherCode, setVoucherCode] = useState("");
  // const [voucherData, setVoucherData] = useState("");
  // const [updatedOrderDetails, setUpdatedOrderDetails] = useState() ;

  const dataChange = (event) => {
    const { value } = event.target;
    setVoucherCode(value);
    setVMessage("")
  };
  const [vMessage, setVMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [discount, setDiscount] = useState();
  const [discountedAmount, setDiscountedAmount] = useState();
  const [appliedClicked, setAppliedClicked] = useState(false);
  const [responseVoucherCode, setResponseVoucherCode] = useState();
  const [voucherAppliedMessage, setVoucherAppliedMessage] = useState();

  // console.log("Apl",responseVoucherCode)


  const handleDiscount = () => {
    // setCheckDiscount(!checkDiscount);
    if (voucherCode.trim() === '') {
      setVMessage('Please enter a discount code');
    } else {
      setShowLoader(true);
      
      if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        const user = jwtDecode(token);
        const exp = user.exp
        const currentTime = Math.floor(Date.now() / 1000);
        if (exp < currentTime) {
          localStorage.clear();
          history.push("/");
        }
        ApplyVoucherService.appllyVoucher(voucherCode, localStorage.getItem('userId'), planObject.price, planObject.planId)
          .then((response) => {
            // console.log("Applied",voucherCode)
            if (response.status == 200) {
              setShowLoader(false)
              setVMessage("")
              setAppliedClicked(true)
              document.getElementById('voucherField').disabled = true;
              toast.success('Discount code has been applied succesfully!')
              setResponseVoucherCode(response.data.voucherCode)
              setVoucherAppliedMessage(response.data.voucherApplyedMessage)
              setDiscount(response.data.applicableAmount)
              const discountedAmount = (planObject.price - response.data.applicableAmount).toFixed(2);
              setDiscountedAmount(discountedAmount)
              setOrderDetails((prevOrder) => ({
                ...prevOrder,
                voucher: response.data,
                amount: discountedAmount,
              }));
              // console.log("succesfully Applied",discountedAmount)
              // console.log("succesfully Applied",orderDetails)

              setCount(3)
            }
          }).catch((error) => {
            if (error.message === "Network Error") {
              setVMessage("Network Error");
              setShowLoader(false)
            } else {
              switch (error.response ? error.response.status : "") {
                case 401:
                  setVMessage(error.response.data.failedReason)
                  setShowLoader(false)
                  break;
                case 403:
                  setVMessage(error.response.data.failedReason)
                  setShowLoader(false)
                  break;
                case 500:
                  setVMessage(error.response.data.failedReason)
                  setShowLoader(false)
                  break;
                case 404:
                case 406:
                  setVMessage(error.response.data.failedReason);
                  setShowLoader(false)
                  break;
                case 412:
                  setVMessage(error.response.data.failedReason)
                  setShowLoader(false)
                  break;
                default:
                  setVMessage("Unknown Error");
                  setShowLoader(false)
                  break;
              }
            }
          });
      } else {
        localStorage.clear();
        history.push("/");
      }
    }

    // setCount((prevCount) => {
    //   if (prevCount < 3) {
    //     return prevCount + 1;
    //   } else {
    //     return 1;
    //   }
    // });
  };
  // console.log ("amount",discountedAmount)

  const OkBtn = () => {
    return (
      <button
        className="btn font-weight-bold text-uppercase btn-primary btn-md mt-2 mb-1"
        onClick={() => setShowAlertForVoucher(false)}
      >
        OK
      </button>
    );
  };

  const deleteClicked = () => {
    setResponseVoucherCode()
    setVoucherAppliedMessage()
    setVMessage("")
    setCount(1)
    setApplyClick(false)
    setAppliedClicked(false)
    document.getElementById('voucherField').disabled = false;
    setDiscountedAmount()
    setVoucherCode("")
    setOrderDetails((prevOrder) => ({
      ...prevOrder,
      voucher: null,
      amount: planObject.price,
    }));

  }
  // useEffect(() => {
  //   // Perform actions that rely on the updated orderDetails here
  //   setOrderDetails((prevOrder) => ({
  //     ...prevOrder,
  //     voucher: voucherData,
  //   }));
  //   console.log("Updated orderDetails:", orderDetails);
  // }, [handleDiscount]);

   // google analytics

   useEffect(() => {
    document.title = "Order Summary Page";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once


  // Main Return
  return (
    <div style={{ position: "relative" }} className="order-summary-section">
      <header>
        <Header />
      </header>
      <div className="container pt-3">
        <AuthenticationServiceComponent history={props.history} />
        <h2 className="mb-3 py-2 text-center">Checkout</h2>
        <div className="row">
          <div className="col-12 col-md-7 mb-3">
            <div className="order-ticket mb-2">
              <div className="order-ticket-content">
                <div>
                  <h3>Your Regional eSIM Plan</h3>
                </div>
                {planObject !== null && (
                  <div className="d-flex mt-2 mt-md-3">
                    <div className="mr-5">
                      <h4 className="m-0">{planObject.data}</h4>
                      <p className="m-0">data</p>
                    </div>
                    <div className="">
                      <h4 className="m-0">{planObject.validity} Days</h4>
                      <p className="m-0">validity</p>
                    </div>
                  </div>
                )}
              </div>
              <img
                className="order-card-bg"
                src={orderCardBg}
                style={{ width: "100%" }}
                alt=""
              />
            </div>

            {planObject !== null && (
              <div className="order-summary mb-2">
                <OrderListComp
                  orderValue={"valueFunction"}
                  order={"Plan"}
                  value={planObject.planName}
                  breakLine={true}
                />
                <div className="order-list list-break-line">
                  <div className="order-key">
                    <p>Coverage</p>
                  </div>
                  <div
                    className="order-value order-popup-link"
                    style={{ position: "relative" }}
                  >
                    {planObject.planType.planTypeId === 2 || planObject.planType.planTypeId === 3 ? (
                      <div
                        className={`${isCoverageDropdownOpen ? "order-popup-active" : ""
                          }`}
                        onClick={openCoverageDropdown}
                      >
                        <span>{planObject.planCountry.length} Countries</span>
                        <RxDropdownMenu />
                      </div>
                    ) : (
                      <div>
                        {planObject.planCountry[0].planCountryNetwork !==
                          null ? (
                          <span>
                            {planObject.planCountry.map(country => country.countryDescription)} -  {planObject.planCountry[0].planCountryNetwork.map(network => network).join(', ')}

                          </span>
                        ) : (
                          <span>
                            {planObject.planCountry.map(
                              (country) => country.countryDescription
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* ..........Network dropdown................ */}
                {/* <div className="order-list list-break-line">
                <div className="order-key">
                  <p>Network</p>
                </div>
                <div
                  style={{ position: "relative" }}
                  className="order-value order-popup-link"
                >
                  <div
                    className={`${
                      isNetworkDropdownOpen ? "order-popup-active" : ""
                    }`}
                    onClick={openNetworkDropdown}
                  >
                    <span>14 Countries</span>
                    <RxDropdownMenu />
                  </div>
                </div>
              </div> */}

                {/*.................. ADD ICCID here (for top up module)................................ */}
                {esimObject && <OrderListComp
                  orderValue={"valueFunction"}
                  order={"ICCID"}
                  value={esimObject.iccid}
                  breakLine={true}
                />}
                {/* .......................Change plan type to "Top up" (for top up module)............................... */}
                <OrderListComp
                  orderValue={"valueFunction"}
                  order={"Plan Type"}
                  value={"Data"}
                  breakLine={true}
                />
                <OrderListComp
                  orderValue={"valueFunction"}
                  order={"Order Type"}
                  value={orderDetails.orderType.orderTypeId === 2 ? "Top Up" : "New Esim"}
                  breakLine={true}
                />

                <OrderListComp
                  orderValue={"valueFunction"}
                  order={"Data"}
                  value={planObject.data}
                  breakLine={true}
                />
                <OrderListComp
                  orderValue={"valueFunction"}
                  order={"Validity"}
                  value={`${planObject.validity} Days`}
                  breakLine={true}
                />
                {/* <div className="order-list list-break-line">
                <div className="order-key">
                  <p>Start Date</p>
                </div>
                <div
                  style={{ position: "relative" }}
                  className="order-value order-calendar-link d-flex justify-content-center align-items-center"
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={openCalendarDropdown}
                  >
                    <p>{formatDate(selectedStartDate)}</p>
                    <BiCalendar
                      className={`${
                        isCalendarDropdownOpen ? "order-popup-active" : ""
                      } order-popup-link p-0`}
                    />
                  </div>
                  {isCalendarDropdownOpen ? (
                    <div ref={calendarDropdownRef}>
                      <CalendarDropdown />
                    </div>
                  ) : null}
                </div>
              </div> */}

                <div className="order-list flex-column justify-content-start align-items-center list-break-line">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="order-key">
                      <p>Activation Policy</p>
                    </div>
                    <div
                      onClick={() => setIsPolicyOpen(!isPolicyOpen)}
                      className="order-value order-info-link"
                    >
                      <ImInfo
                        className={`order-popup-link p-0 ${isPolicyOpen ? "order-popup-active" : ""
                          }`}
                      />
                    </div>
                  </div>
                  {isPolicyOpen ? (
                    <div className="order-info-link w-100">
                      <p>
                        The validity period starts when the eSIM connects to any
                        supported network/s.
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
          {planObject !== null && (
            <div className="col-12 col-md-5 mb-3">
              <div className="order-total">
                <OrderListComp
                  order={"Subtotal"}
                  value={`£${planObject.price}`}
                  breakLine={true}
                />
                <OrderListComp
                  order={"Service Fees"}
                  value={"£0.00"}
                  breakLine={true}
                />
                {
                  count == 3 &&

                  <div className={`order-list flex-column list-break-line`}>
                    <div className="d-flex justify-content-between align-items-center w-100">

                      <div className="order-key">
                        <p>Discount</p>
                      </div>
                      <div className="order-value">
                        <p>{`£${discount}`}</p>
                      </div>
                    </div>
                    <div className="discount-message success text-left m-0">{voucherAppliedMessage}.</div>
                  </div>
                  //   <OrderListComp
                  //   order={"Discount"}
                  //   value={`£${discount}`}
                  //   breakLine={true}
                  //   discountMessage={"This is a dynamical message"}
                  // />
                }
                {/* {responseVoucherCode ? (
                    // // put success or error class according to the message
                    // dont add success message show toast
                    <div className="discount-message success text-right">
                      {voucherAppliedMessage}.
                    </div>
                  ) : null} */}
                <OrderListComp
                  order={"Total"}
                  value={discountedAmount !== undefined ? `£${discountedAmount}` : `£${planObject.price}`}
                  breakLine={false}
                />
                <div className="d-flex justify-content-end align-items-center">
                  <p style={{ fontSize: '10px' }} className="m-0">(Taxes included)</p>
                </div>
                <div className="list-break-line mt-2"></div>
                <div className="order-list d-flex flex-column align-items-start mb-4">
                  <div
                    style={{ marginBottom: "0.35rem" }}
                    className="order-key d-flex justify-content-start align-items-center"
                  >
                    <CiDiscount1 className="discount-icon" />
                    <p>Apply discount</p>
                  </div>
                  <div
                    style={{ border: checkDiscount ? "1px solid #3ea662" : "" }}
                    className="order-value w-100 d-flex justify-content-start align-items-center discount-input"
                  >
                    <input
                      placeholder="Enter your discount code"
                      id="voucherField"
                      autoCapitalize="true"
                      className="pl-3 pr-1"
                      style={{ width: "100%" }}
                      type="text"
                      value={voucherCode}
                      onChange={dataChange}
                    />
                    {appliedClicked === false ?
                      <button
                        id="discountApplyBtn"
                        onClick={() => {
                          handleDiscount();
                          setApplyClick(true)
                        }}
                        className="btn discount-apply-btn shadow-sm d-flex justify-content-center align-items-center"
                      >
                        Apply
                        {/* {
                        count==1 ? "Apply" :
                        count==2 ? 
                        "Apply"  :
                        count==3? "Delete": null
                        // "Apply"
                      } */}
                      </button>
                      :
                      <button
                        id="discountApplyBtn"
                        onClick={deleteClicked}
                        className="btn discount-apply-btn shadow-sm d-flex justify-content-center align-items-center"
                      >
                        Remove
                        {/* {
                        count==1 ? "Apply" :
                        count==2 ? 
                        <MicroLoader/> :
                        count==3? "Delete": null
                        // "Apply"
                      } */}
                      </button>
                    }
                  </div>
                  {responseVoucherCode ? (
                    // // put success or error class according to the message
                    // dont add success message show toast
                    <div className="discount-message success text-right">
                      {responseVoucherCode} - Successfully applied.
                    </div>
                  ) : null}
                  {vMessage ? (
                    // // put success or error class according to the message
                    // dont add success message show toast
                    <div className="discount-message error text-right">
                      {vMessage}
                    </div>
                  ) : null}
                </div>
                {/* <div className="list-break-line mb-3"></div> */}
                <div className="mt-2">
                  <div className="d-flex justify-content-start align-items-start">
                    <label className="custom-checkbox">
                      <div style={{ paddingTop: "2px" }}>
                        <input
                          type="checkbox"
                          checked={termsChecked}
                          onChange={(e) => setTermsChecked(e.target.checked)}
                        />
                      </div>
                      <p className="m-0">
                        By completing the order, you agree to our
                        <a className="mx-1" onClick={() => history.push("/termsandcondition")} >Terms & Conditions</a>
                        and
                        <a className="mx-1" onClick={() => history.push("/privacypolicy")} >Privacy Policy</a>
                      </p>
                    </label>
                  </div>
                  <div className="d-flex justify-content-start align-items-start">
                    <label className="custom-checkbox">
                      <div style={{ paddingTop: "2px" }}>
                        <input
                          type="checkbox"
                          checked={deviceChecked}
                          onChange={(e) => setDeviceChecked(e.target.checked)}
                        />
                      </div>
                      Before completing this order, please confirm your device
                      is eSIM compatible and network-unlocked.
                    </label>
                  </div>
                  <div className="w-100 p-2 checkout-btn d-flex justify-content-center align-items-center">
                    <button
                      onClick={orderAPI}
                      className="btn btn-custom-gradient rounded-pill text-uppercase mb-2 px-5 py-lg-2 shadow-sm"
                      type="button"
                    >
                      Checkout
                    </button>
                  </div>
                  <FormFeedback
                    style={{ display: message ? "block" : "none" }}
                    className="red"
                  >
                    {message}
                  </FormFeedback>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ContactUsForm />
      <Toaster position="top-center" reverseOrder={false} />
      {isCoverageDropdownOpen ? (
        <div className="dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}

      {showAlertForVoucher ? (
        <AlertPopup
          status={"error"}
          heading={"Apply or Remove your voucher"}
          content={
            "Please apply or remove the voucher entered in voucher field."
          }
          customFunction={<OkBtn />}
          updateState={() => setShowAlertForVoucher(false)}
        />
      ) : (
        ""
      )}

      {isNetworkDropdownOpen ? (
        <div className="dropdowns-modal">
          <NetworkDropdown />
        </div>
      ) : null}

      {showLoader ? <Loader /> : null}
    </div>
  );
};

export default OrderSummary;
