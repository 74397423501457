import React, { Component } from 'react';
import AuthenticationService from '../../views/Common/AuthenticationService';


export default class AuthenticationServiceComponent extends Component {
    constructor(props) {
        super(props);
        
    }
   
    componentDidMount = () => {
        var result = AuthenticationService.validateLogin();       
        if (result == true  ) {
            // console.log("res",result)
            const message = "Access denied" 
            this.props.history.push({
                pathname: '/login',
                state: { message }
            });
        }
    }
    render() {
        return (
            <div className="animated fadeIn">

            </div>
        )
    }
}
