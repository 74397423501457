import React, { useState, useEffect } from "react";
import loginBottomGrad from "../../assets/img/login-bottom-gradient.png";
import loginLeftGrad from "../../assets/img/login-left-gradient.png";
import LoginService from "../../Services/LoginService";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import UserVarificationService from "../../Services/UserVarificationService";
import ResetPasswordService from "../../Services/ResetPasswordService";
import Header from "../Header/Header";
import ContactUsForm from "../Common/ContactUsForm";
import { IoEyeOff, IoEye } from "react-icons/io5";

let initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = function (values) {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, "Password is too short, minimum length is 8 characters")
      .required("Password is Required"),
    confirmPassword: Yup.string().required("Required"),
  });
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const SetPassword = (props) => {
  const history = useHistory();
  const [message, setMessage] = useState();
  const [propMessage, setPropMessage] = useState(null);
  const [verification, setVerification] = useState({
    verificationId: props.match.params.verificationId,
    userId: props.match.params.userId,
  });
  const [reset, setReset] = useState({
    password: "",
    confirmPassword: "",
    userId: props.match.params.userId,
    setPassword: true,
  });
  const [code, setCode] = useState();

  // useEffect(() => {
  //   forgotPasswrdVerificationAPI();
  // }, []);

  // const forgotPasswrdVerificationAPI = () => {
  //   ResetPasswordService.forgotPasswordVerification(verification)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         setCode(200);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.message === "Network Error") {
  //         setMessage("Network Error");
  //       } else {
  //         switch (error.response ? error.response.status : "") {
  //           case 401:
  //             setMessage(error.response.data.failedReason);
  //             setCode(401);
  //             break;
  //           case 403:
  //             setMessage(error.response.data.failedReason);
  //             setCode(403);
  //             break;
  //           case 500:
  //             setMessage(error.response.data.failedReason);
  //             setCode(500);
  //             break;
  //           case 404:
  //           case 406:
  //             setMessage(error.response.data.failedReason);
  //             setCode(406);
  //             break;
  //           case 412:
  //             setMessage(error.response.data.failedReason);
  //             setCode(412);
  //             break;
  //           default:
  //             setMessage("Unknown Error");
  //             break;
  //         }
  //       }
  //     });
  // };

  const touchAll = (setTouched, errors) => {
    setMessage("");
    setTouched({
      password: true,
      confirmPassword: true,
    });
    validateForm(errors);
  };

  const validateForm = (errors) => {
    findFirstError("reset", (fieldName) => {
      return Boolean(errors[fieldName]);
    });
  };

  const findFirstError = (formName, hasError) => {
    const form = document.forms[formName];
    for (let i = 0; i < form.length; i++) {
      if (hasError(form[i].name)) {
        form[i].focus();
        break;
      }
    }
  };

  const dataChange = (event) => {
    const { name, value } = event.target;
    setReset((prevLogin) => ({
      ...prevLogin,
      [name]: value,
    }));
  };

  const [isVisiblePass, setVisiblePass] = useState(false);

  const togglePass = () => {
    setVisiblePass(!isVisiblePass);
  };

  const [isVisibleConPass, setVisibleConPass] = useState(false);

  const toggleConPass = () => {
    setVisibleConPass(!isVisibleConPass);
  };

  const resetPasswordAPI = () => {
    ResetPasswordService.resetPassword(reset)
      .then((response) => {
        if (response.status === 200) {
          // setPropMessage("Password reset Sucessfully")
          localStorage.clear();
          const message = "Password set Sucessfully";
          history.push({
            pathname: "/login",
            state: { message },
          });
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason);
              break;
            case 403:
              setMessage(error.response.data.failedReason);
              break;
            case 500:
              setMessage("Error occured in setting password");
              break;
            case 404:
            case 406:
              setMessage(error.response.data.failedReason);
              break;
            case 412:
              setMessage(error.response.data.failedReason);
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
  };

   // google analytics

   useEffect(() => {
    document.title = "Set Password Page";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div style={{ overflow: "hidden" }} className="container-fluid p-0">
      <header
        className="w-100"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Header />
      </header>
      <div className="row no-gutter">
        <div className="col-md-6 login-bg first-section">
          <div className="login d-flex align-items-center py-5">
            {/* {code === 200 && ( */}
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 mx-auto px-5">
                    <h1 className="login-heading text-center">
                      Set Password
                    </h1>
                    <p className="text-muted mb-2 text-center">
                      Please enter your password
                    </p>
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validate={validate(validationSchema)}
                      validateOnChange={false}
                      validateOnBlur={false}
                      // onSubmit={registerClicked}
                      onSubmit={(values, { setSubmitting, setErrors }) => {
                        resetPasswordAPI();
                      }}
                      render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        setTouched,
                        handleReset,
                      }) => (
                        <form
                          name="reset"
                          onSubmit={handleSubmit}
                          onReset={handleReset}
                          noValidate
                          autoComplete="off"
                        >
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Password</label>
                            </div>
                            <div style={{ position: "relative" }}>
                              <input
                                name="password"
                                valid={!errors.password}
                                invalid={touched.password && !!errors.password}
                                onChange={(e) => {
                                  handleChange(e);
                                  dataChange(e);
                                }}
                                onBlur={handleBlur}
                                value={reset.password}
                                type={!isVisiblePass ? "password" : "text"}
                                id="inputPassword"
                                placeholder="Enter your password"
                                required=""
                                className={`form-control border-0 shadow-sm custom-input ${
                                  errors.password === "" ||
                                  errors.password === undefined
                                    ? ""
                                    : "input-error"
                                }`}
                              />
                              <span
                                className="password-icon"
                                onClick={togglePass}
                              >
                                {isVisiblePass ? <IoEyeOff /> : <IoEye />}
                              </span>
                            </div>

                            <FormFeedback
                              style={{
                                display:
                                  errors.password === "" ||
                                  errors.password === undefined
                                    ? "none"
                                    : "block",
                              }}
                              className="red"
                            >
                              {errors.password}
                            </FormFeedback>
                          </div>
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Type your password again</label>
                            </div>
                            <div style={{position: 'relative'}}>
                            <input
                              name="confirmPassword"
                              valid={!errors.confirmPassword}
                              invalid={
                                touched.confirmPassword &&
                                !!errors.confirmPassword
                              }
                              onChange={(e) => {
                                handleChange(e);
                                dataChange(e);
                              }}
                              onBlur={handleBlur}
                              value={reset.confirmPassword}
                              type={!isVisibleConPass ? "password" : "text"}
                              placeholder="Repeat your password"
                              required=""
                              className={`form-control border-0 shadow-sm custom-input ${
                                errors.confirmPassword === "" ||
                                errors.confirmPassword === undefined
                                  ? ""
                                  : "input-error"
                              }`}
                            />
                            <span
                              className="password-icon"
                              onClick={toggleConPass}
                              >
                              {isVisibleConPass ? <IoEyeOff /> : <IoEye />}
                            </span>
                            </div>
                            <FormFeedback
                              style={{
                                display:
                                  errors.confirmPassword === "" ||
                                  errors.confirmPassword === undefined
                                    ? "none"
                                    : "block",
                              }}
                              className=""
                            >
                              {errors.confirmPassword}
                            </FormFeedback>
                          </div>

                          <button
                            onClick={() => touchAll(setTouched, errors)}
                            type="submit"
                            className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                          >
                            Set
                          </button>
                        </form>
                      )}
                    />
                    <div>
                      <FormFeedback
                        style={{
                          display:
                            message === "" || message === undefined
                              ? "none"
                              : "block",
                        }}
                        className="red"
                      >
                        {message}
                      </FormFeedback>
                    </div>
                  </div>
                </div>
              </div>
            {/* )} */}
          </div>
          <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
          <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
        </div>
        {/* <div className="col-md-6 d-none d-md-flex login-bg-image"></div> */}
        <div className="col-md-6 d-none d-md-flex login-bg-image">
          {/* <div className="world-cup-content">
            <div>
              <h3 className="mb-2 text-center">
                Secure Your
                <br />
                 2023 World Cup Ticket
              </h3>
              <h3 className="text-center">
                <span>

                JOIN LUCKY DRAW 
                </span>
                <br />
                Register & Verify Your Email by October 31st!
              </h3>
            </div>

            <div style={{marginBottom: '10px'}} className="mt-auto">
              <h1>REGISTER NOW!</h1>
              <p>
                <span style={{fontSize: '8.8px',fontWeight: '700'}}>
                2023 World Cup Ticket Lucky Draw T&C: </span>Register by Oct 31st to
                Enter! Unique Email Id is a must. The number of users signing up
                through your unique code will be considered. The number of
                conversion will be considered. Organisers have all rights
                regarding the final decision in this campaign.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <ContactUsForm />
    </div>
  );
};

export default SetPassword;
