import axios from 'axios';
import { API_URL } from '../Common/Constant';
class PlansBasedOnCountryService {

    counrtyPlan(alpha2) {
        return axios.get(`${API_URL}/webappapi/popularPlanByCountry/${alpha2}`, {
        });
    }
    counrtyPremiumPlan(alpha2) {
        return axios.get(`${API_URL}/webappapi/premiumPlanByCountry/${alpha2}`, {
        });
    }
    tileCountryPlan(alpha2) {
        return axios.get(`${API_URL}/webappapi/plan/country/${alpha2}`, {
        });
    }
    
}
export default new PlansBasedOnCountryService()