import React, { useEffect, useRef } from "react";

const TrustBox = () => {
  const trustPilotRef = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustPilotRef.current, true);
    }
  }, []);
  return (
    <div
      ref={trustPilotRef} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-locale="en-GB"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessUnit-id="6513109f680c183fe3a6c536"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="dark"
      data-min-review-count="50"
      data-style-alignment="center"
      // [ long list of data attributes...]
    >
      <a
        href="https://www.trustpilot.com/review/example.com"
        target="_blank"
        rel="noopener"
      >
        {" "}
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
