import React from "react";

const KioskBgElement = () => {
  return (
    <ul className="bubble-group">
      <li></li>
      <li></li>
    </ul>
  );
};

export default KioskBgElement;
