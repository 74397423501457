import axios from 'axios';
import { API_URL } from '../Common/Constant';
class DashboardDetail {

    dashboardDetailService(userId) {
        return axios.get(`${API_URL}/referralApi/getDashboardDetails`,{headers:{"userId":userId, 'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }

    notificationService(userId, dateFlag) {
        return axios.get(`${API_URL}/referralApi/getReferralNotifications`,{headers:{"userId":userId, "dateFlag":dateFlag, 'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }

    updateNotificationService(userId) {
        return axios.post(`${API_URL}/referralApi/updateNotificationStatus`,{},{headers:{"userId":userId, 'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }

    bankDetailsService(userId, json) {
        return axios.post(`${API_URL}/referralApi/addOrUpdateBankDetails`,json,{headers:{"userId":userId, 'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
}
export default new DashboardDetail()