import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { BiInfoCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FormFeedback } from "reactstrap";
import * as z from "zod";
import RegionService from "../../Services/RegionService";
import StudentService from "../../Services/StudentService";
import loginCheck from "../../assets/elements/login_check.png";
import studentBanner from "../../assets/img/data_banner.png";
import loginBottomGrad from "../../assets/img/login-bottom-gradient.png";
import loginLeftGrad from "../../assets/img/login-left-gradient.png";
import Loader from "../Alert_Popup/Loader";
import ContactUsForm from "../Common/ContactUsForm";
import Tooltip from "../Common/Tooltip/ToolTip";
import Header from "../Header/Header";
import { REACT_APP_SITE_KEY } from "../../Common/Constant";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaService from "../../Services/RecaptchaService";
import CustomModal from "../Kiosk/components/CustomModal";
import AlertPopup from "../Alert_Popup/AlertPopup";

const schema = z
  .object({
    firstName: z.string().trim().min(1, { message: "Please enter first name" }),
    lastName: z.string().trim().min(1, { message: "Please enter last name" }),
    dateOfBirth: z
      .string({
        required_error: "Please select your date of birth",
      })
      .trim()
      .min(1, { message: "Please select your date of birth" }),
    emailId: z
      .string()
      .trim()
      .min(1, { message: "Please enter email address" })
      .email({ message: "Please enter valid email address" }),
    country: z.object({
      countryId: z
        .number({
          required_error: "Please select a country",
        })
        .int()
        .min(1, { message: "Please select a country" }),
    }),
    studentUniversity: z.object({
      studentUniversityId: z
        .number({
          required_error: "Please select a university",
        })
        .int()
        .min(1, { message: "Please select a university" }),
    }),

    universityAliasCollegeName: z.string().trim().optional(),
    // .min(1, { message: "Please enter your university / college name" }),
    universityEmailId: z
      .string()
      .trim()
      .email({ message: "Please enter valid email address" })
      .optional()
      .or(z.literal("")),
    courseDuration: z
      .number({
        required_error: "Please select a course duration",
        invalid_type_error: "Please select a course duration",
      })
      .int()
      .min(1, { message: "Please select a course duration" }),
    deviceEsimCompatibleValue: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, { message: "Required" }),
    intrestedInPhysicalSimValue: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, { message: "Required" }),
    currentlyInUkValue: z
      .string({
        required_error: "Required",
        invalid_type_error: "Required",
      })
      .min(1, { message: "Required" }),
    flatNo: z.string().trim().optional(),
    streetAddress: z.string().trim().optional(),
    state: z.string().trim().optional(),
    city: z.string().trim().optional(),
    postCode: z.string().trim().optional(),
    referralCode: z.string().trim().optional(),
    studentNetwork: z.object({
      studentNetworkId: z
        .number()
        .int()
        .optional()
    }),
  })
  .superRefine((data, ctx) => {
    console.log(
      "intrestedInPhysicalSimValue",
      data.intrestedInPhysicalSimValue
    );
    if (
      data.studentUniversity.studentUniversityId === 1 &&
      !data.universityAliasCollegeName
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter your university / college name",
        path: ["universityAliasCollegeName"],
      });
    }
    if (data.intrestedInPhysicalSimValue === "true" && !data.flatNo) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter the door / flat number",
        path: ["flatNo"],
      });
    }
    if (data.intrestedInPhysicalSimValue === "true" && !data.streetAddress) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter the street address",
        path: ["streetAddress"],
      });
    }
    if (data.intrestedInPhysicalSimValue === "true" && !data.state) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter the state",
        path: ["state"],
      });
    }
    if (data.intrestedInPhysicalSimValue === "true" && !data.city) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter the city",
        path: ["city"],
      });
    }
    if (data.intrestedInPhysicalSimValue === "true" && !data.postCode) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter the zip code / post code",
        path: ["postCode"],
      });
    }

    if (data.intrestedInPhysicalSimValue === "true" && !data.studentNetwork?.studentNetworkId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select a network",
        path: ["studentNetwork", "studentNetworkId"],
      });
    }
  });

let countries = [];
let studentUniversities = [];
let studentNetwork = [];
let durations = [
  { label: "12 Months", value: 12 },
  { label: "18 Months", value: 18 },
  { label: "24 Months", value: 24 },
  { label: "36 Months", value: 36 },
];

const StudentRegistration = (props) => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const [progressDotOne, setProgressDotOne] = useState(true);
  const [progressDotTwo, setProgressDotTwo] = useState(false);
  const [progressDotThree, setProgressDotThree] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState();
  const [cautionModal, setCautionModal] = useState(false);
  const [emailIdCheckError, setEmailIdCheckError] = useState(undefined);
  const [universityNameError, setUniversityNameError] = useState(undefined);
  const [recaptchaError, setRecaptchaError] = useState(undefined);
  const [haveReferralCode, setHaveReferralCode] = useState(
    props.match.params.referralCode ? true : false
  );
  const captchaRef = useRef(null);

  const {
    register,
    handleSubmit,
    watch,
    control,
    trigger,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      referralCode: props.match.params.referralCode,
    },
  });
  const onSubmit = async (data) => {
    setRecaptchaError(undefined);
    let captchaError = false;
    setShowLoader(true);
    const token = captchaRef.current.getValue();
    console.log("token", token);
    if (!token) {
      setRecaptchaError("Please solve the captcha");
      captchaError = true;
    } else {
      //validate the token
      await RecaptchaService.verify(token)
        .then((response) => {
          console.log("success", response.data);
          if (response.data === true) {
            setRecaptchaError(undefined);
            captchaError = false;
          } else {
            setRecaptchaError("Captcha verification failed. Please try again.");
            captchaError = true;
            window.grecaptcha.reset();
          }
        })
        .catch((error) => {
          console.log("error", error);
          setRecaptchaError(
            "Error occurred while captcha verification. Please try again later."
          );
          captchaError = true;
        });
    }
    console.log("captchaError: ", captchaError);
    if (!captchaError) {
      const student = {
        ...data,
        promotionalMessageSubscription: isToggled,
        usersSignupSource: { usersSignupSourceId: 1 },
        deviceEsimCompatible:
          data.deviceEsimCompatibleValue === "true" ? true : false,
        intrestedInPhysicalSim:
          data.intrestedInPhysicalSimValue === "true" ? true : false,
        currentlyInUk: data.currentlyInUkValue === "true" ? true : false,
      };
      console.log(student);

      await StudentService.student(student)
        .then((response) => {
          sessionStorage.setItem("studentRegistered", true);
          history.push("/login");
          setShowLoader(false);
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            console.log(error.message);
            setMessage("Network error occured. Please try again later.");
            setShowLoader(false);
          } else {
            switch (error.response ? error.response.status : "") {
              case 406:
                console.log("Incorrect referral code", error.response.data);
                setMessage(
                  "Incorrect referral code. Please enter correct referral code."
                );
                setShowLoader(false);
                break;
              case 500:
                console.log("Server error occurred", error.response.data);
                setMessage("Something went wrong. Please try again later.");
                setShowLoader(false);
                break;
              default:
                console.log("Server error occurred", error.response.data);
                setMessage(
                  "Unexpected error occurred. Please try again later."
                );
                setShowLoader(false);
                break;
            }
          }
        });
    }
    setShowLoader(false);
  };

  const watchIntrestedInPhysicalSimValue = watch(
    "intrestedInPhysicalSimValue",
    "true"
  );

  const watchStudentUniversity = watch(
    "studentUniversity.studentUniversityId",
    null
  );

  const watchUniversityAliasCollegeName = watch(
    "universityAliasCollegeName",
    null
  );
  const watchIntrestedInPhysicalSim = watch(
    "intrestedInPhysicalSimValue",
    true
  );
  const watchCurrentlyInUk = watch(
    "currentlyInUkValue",
    true,
  );





  const watchEmailId = watch("emailId", "");

  // Fetch country data from API
  const countryAPI = () => {
    RegionService.countryAccordingToRegionList().then((response) => {
      response.data.forEach((country) => {
        countries.push({
          label: country.countryDescription,
          value: country.countryId,
        });
      });
    });
  };

  // Fetch student university data from API
  const studentUniversityAPI = () => {
    StudentService.getStudentUniversity().then((response) => {
      response.data.forEach((studentUniversityItem) => {
        studentUniversities.push({
          label: studentUniversityItem.studentUniversityDesc,
          value: studentUniversityItem.studentUniversityId,
        });
      });
    });
  };
  // Fetch student network data from API
  const studentNetworkAPI = () => {
    StudentService.getStudentNetwork().then((response) => {
      response.data.forEach((studentNetworkItem) => {
        studentNetwork.push({
          label: studentNetworkItem.studentNetworkDesc,
          value: studentNetworkItem.studentNetworkId,
        });
      });
    });
  };
  //use effects
  useEffect(() => {
    countryAPI();
    studentUniversityAPI();
    studentNetworkAPI();
  }, []);

  useEffect(() => {
    setEmailIdCheckError(undefined);
  }, [watchEmailId]);

  useEffect(() => {
    setUniversityNameError(undefined);
  }, [watchUniversityAliasCollegeName]);

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  // const handleStepper = (step) => {

  //   // switch (step) {
  //   //   case 1:
  //   //     setActiveStep(1);
  //   //     break;
  //   //   case 2:
  //   //     setActiveStep(3);
  //   //     break;
  //   //   case 3:
  //   //     setActiveStep(3);
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }
  // };

  const handleStepperTrack = async (value) => {
    console.log("one");
    setShowLoader(true);
    let emailIdError = false;
    let universityNameError = false;

    if (
      watchStudentUniversity &&
      (watchStudentUniversity === 1 || watchStudentUniversity === "1") &&
      (!watchUniversityAliasCollegeName || watchStudentUniversity === "")
    ) {
      universityNameError = true;
      setUniversityNameError("Please enter your university / college name");
    } else {
      universityNameError = false;
      setUniversityNameError(undefined);
    }

    if (watchEmailId) {
      //check if username already exists and set error message
      await StudentService.checkStudentExistsByEmailId(watchEmailId)
        .then((response) => {
          console.log("Student is not available");
          setEmailIdCheckError(undefined);
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            console.log(error.message);
            setEmailIdCheckError(undefined);
          } else {
            switch (error.response ? error.response.status : "") {
              case 406:
                //set error message
                console.log("setErrorMessage");
                emailIdError = true;
                setEmailIdCheckError(
                  "Email Id already exists. Please try with another email ID"
                );
                break;
              case 500:
                console.log("Server error occurred");
                setEmailIdCheckError(undefined);
                break;
              default:
                console.log("Unexpected error occurred");
                setEmailIdCheckError(undefined);
                break;
            }
          }
        });
    }
    console.log("two");
    const firstStepValidated = await trigger([
      "firstName",
      "lastName",
      "dateOfBirth",
      "emailId",
      "country.countryId",
    ]);
    const secondStepValidated = await trigger([
      "studentUniversity.studentUniversityId",
      "universityAliasCollegeName",
      "universityEmailId",
      "courseDuration",
    ]);
    if (value === "next" && activeStep < 3) {
      if (
        activeStep === 1 &&
        firstStepValidated &&
        !(
          errors.firstName?.message ||
          errors.lastName?.message ||
          errors.dateOfBirth?.message ||
          errors.emailId?.message ||
          errors.country?.countryId?.message
        ) &&
        !emailIdError
      ) {
        console.log("three");
        clearErrors();
        setActiveStep(activeStep + 1);
        resetField("universityAliasCollegeName");
        resetField("universityEmailId");
      } else if (
        secondStepValidated &&
        activeStep === 2 &&
        !(
          errors.studentUniversity?.studentUniversityId?.message ||
          errors.universityAliasCollegeName?.message ||
          errors.courseDuration?.message
        ) &&
        !universityNameError
      ) {
        clearErrors();
        setActiveStep(activeStep + 1);
      }
    } else if (value === "prev" && activeStep > 1) {
      setActiveStep(activeStep - 1);
    } else {
    }
    setShowLoader(false);
  };

  useEffect(() => {
    switch (activeStep) {
      case 1:
        setProgressDotOne(true);
        setProgressDotTwo(false);
        setProgressDotThree(false);
        break;
      case 2:
        setProgressDotOne(true);
        setProgressDotTwo(true);
        setProgressDotThree(false);
        break;
      case 3:
        setProgressDotOne(true);
        setProgressDotTwo(true);
        setProgressDotThree(true);
        break;
      default:
        break;
    }
  }, [activeStep]);
  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  useEffect(() => {
    console.log(typeof watchIntrestedInPhysicalSim, typeof watchCurrentlyInUk);

    if (watchIntrestedInPhysicalSim == "true" && watchCurrentlyInUk == "false") {
      setCautionModal(true);
    } else {
      setCautionModal(false);

    }

  }, [watchCurrentlyInUk, watchIntrestedInPhysicalSim]);

  return (
    <div style={{ overflow: "hidden" }} className="container-fluid p-0">
      <header
        className="w-100"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Header />
      </header>

      <div className="row no-gutter">
        <div className="col-md-6 login-bg first-section pt-2 pt-md-4">
          <div className="login d-flex py-5">
            <div className="container mt-2">
              {/* <div className="world-cup-mob-container">
                <img style={{width: '100%'}} src={worldCupMob} alt="world-cup-mob" />
                </div> */}
              <div className="row">
                <div className="col-lg-10 col-xl-10 d-flex flex-column justify-content-center align-items-center mx-auto px-0">
                  <h1 className="login-heading text-center mt-4">
                    Student Registration
                  </h1>
                  <div>
                    <img
                      width={180}
                      src={studentBanner}
                      alt="FREE eSIM 2GB  DATA"
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-between align-items-center my-4 px-3">
                    <div
                      className={`form-control position-relative d-flex justify-content-center align-items-center`}
                      style={{ height: 40, width: 40, fontSize: "18px" }}
                    >
                      {progressDotOne ? (
                        <img
                          src={loginCheck}
                          alt="checked"
                          style={{ position: "absolute", top: 0, left: 0 }}
                        />
                      ) : (
                        1
                      )}
                    </div>
                    <span className="login-progress-line"></span>
                    <div
                      className={`form-control position-relative d-flex justify-content-center align-items-center`}
                      style={{ height: 40, width: 40, fontSize: "18px" }}
                    >
                      {progressDotTwo ? (
                        <img
                          src={loginCheck}
                          alt="checked"
                          style={{ position: "absolute", top: 0, left: 0 }}
                        />
                      ) : (
                        2
                      )}
                    </div>
                    <span className="login-progress-line"></span>

                    <div
                      className={`form-control position-relative d-flex justify-content-center align-items-center`}
                      style={{ height: 40, width: 40, fontSize: "18px" }}
                    >
                      {progressDotThree ? (
                        <img
                          src={loginCheck}
                          alt="checked"
                          style={{ position: "absolute", top: 0, left: 0 }}
                        />
                      ) : (
                        3
                      )}
                    </div>
                  </div>

                  <form
                    className="w-100 px-3"
                    name="login"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="students-register-form px-1">
                      {activeStep === 1 ? (
                        <fieldset>
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>First Name*</label>
                            </div>
                            <input
                              name="firstName"
                              id="inputFirstName"
                              type="text"
                              placeholder="Please enter your first name"
                              {...register("firstName")}
                              className={`form-control border-0 shadow-sm custom-input`}
                              maxLength={60}
                            />
                            {errors.firstName?.message && (
                              <span className="error">
                                {errors.firstName?.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Last Name*</label>
                            </div>
                            <input
                              name="lastName"
                              id="inputLastName"
                              type="text"
                              placeholder="Please enter your last name"
                              {...register("lastName")}
                              className={`form-control border-0 shadow-sm custom-input`}
                              maxLength={60}
                            />
                            {errors.lastName?.message && (
                              <span className="error">
                                {errors.lastName?.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Date of Birth*</label>
                            </div>
                            <div
                              style={{ position: "relative" }}
                              className="notranslate"
                            >
                              <Controller
                                name="dateOfBirth"
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <DatePicker
                                    autoComplete="off"
                                    name="dateOfBirth"
                                    selected={
                                      value === "" || value === undefined
                                        ? undefined
                                        : new Date(value)
                                    }
                                    onChange={(val) =>
                                      val
                                        ? onChange(
                                          moment(val).format("YYYY-MM-DD")
                                        )
                                        : onChange("")
                                    }
                                    dateFormat="yyyy-MM-dd"
                                    showMonthDropdown
                                    showYearDropdown
                                    minDate={new Date(1900, 0, 1)} // Adjust the minimum date
                                    maxDate={new Date()} // Set the maximum date to today
                                    dropdownMode="select" // Use select mode for year and month dropdowns
                                    className={`form-control ${errors.dateOfBirth?.message
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    placeholderText="YYYY-MM-DD"
                                    disabledKeyboardNavigation={true}
                                  />
                                )}
                              />
                            </div>
                            {errors.dateOfBirth?.message && (
                              <span className="error">
                                {errors.dateOfBirth?.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Email*</label>
                            </div>
                            <input
                              name="emailId"
                              id="inputemailId"
                              type="email"
                              placeholder="Please enter your email"
                              {...register("emailId")}
                              className={`form-control border-0 shadow-sm custom-input`}
                              maxLength={60}
                            />
                            {errors.emailId?.message && (
                              <span className="error">
                                {errors.emailId?.message}
                              </span>
                            )}
                            {emailIdCheckError && (
                              <span className="error">{emailIdCheckError}</span>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Country of Residence*</label>
                            </div>
                            <div
                              className="custom-country-dropdown"
                              style={{ position: "relative" }}
                            >
                              <Controller
                                name="country.countryId"
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <Select
                                    classNames="form-control"
                                    placeholder={<div>Type to search</div>}
                                    options={countries}
                                    value={countries.find(
                                      (c) => c.value === value
                                    )}
                                    onChange={(val) =>
                                      val ? onChange(val.value) : onChange(0)
                                    }
                                    isClearable={true}
                                  // menuIsOpen={true}
                                  />
                                )}
                              />
                              {errors.country?.countryId?.message && (
                                <span className="error">
                                  {errors.country?.countryId?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </fieldset>
                      ) : activeStep === 2 ? (
                        <>
                          <fieldset>
                            <div className="form-group mb-3">
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <label>University / College*</label>
                              </div>
                              <div
                                className="custom-country-dropdown university-dropdown"
                                style={{ position: "relative" }}
                              >
                                <Controller
                                  name="studentUniversity.studentUniversityId"
                                  control={control}
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      classNames="form-control"
                                      placeholder={<div>Type to search</div>}
                                      options={studentUniversities}
                                      value={studentUniversities.find(
                                        (u) => u.value === value
                                      )}
                                      onChange={(val) =>
                                        val
                                          ? onChange(val.value)
                                          : onChange(null)
                                      }
                                      isClearable={true}
                                    // menuIsOpen={true}
                                    />
                                  )}
                                />
                                <p
                                  style={{ fontSize: 12 }}
                                  className="text-custom-muted m-0"
                                >
                                  Please select Other if university is not
                                  available in the above list
                                </p>
                                {errors.studentUniversity?.studentUniversityId
                                  ?.message && (
                                    <span className="error">
                                      {
                                        errors.studentUniversity
                                          ?.studentUniversityId?.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            {watchStudentUniversity &&
                              watchStudentUniversity === 1 && (
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <label>University / College Name*</label>
                                  </div>
                                  <input
                                    autoComplete="nope"
                                    name="universityAliasCollegeName"
                                    id="inputuniversityAliasCollegeName"
                                    type="text"
                                    placeholder="Please enter your university / college name"
                                    {...register("universityAliasCollegeName")}
                                    className={`form-control border-0 shadow-sm custom-input`}
                                    maxLength={200}
                                  />
                                  {errors.universityAliasCollegeName
                                    ?.message && (
                                      <span className="error">
                                        {
                                          errors.universityAliasCollegeName
                                            ?.message
                                        }
                                      </span>
                                    )}
                                  {universityNameError && (
                                    <span className="error">
                                      {universityNameError}
                                    </span>
                                  )}
                                </div>
                              )}
                            <div className="form-group mb-3">
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <label>University Email</label>
                              </div>
                              <input
                                autoComplete="nope"
                                name="universityEmailId"
                                id="inputuniversityEmailId"
                                type="text"
                                placeholder="Please enter the university email to claim a 20% discount code"
                                {...register("universityEmailId")}
                                className={`form-control border-0 shadow-sm custom-input`}
                                maxLength={60}
                              />
                              {errors.universityEmailId?.message && (
                                <span className="error">
                                  {errors.universityEmailId?.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group mb-3">
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <label>Duration of your course*</label>
                              </div>
                              <div
                                className="custom-country-dropdown"
                                style={{ position: "relative" }}
                              >
                                <Controller
                                  name="courseDuration"
                                  control={control}
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <Select
                                      classNames="form-control"
                                      placeholder={<div>Type to search</div>}
                                      options={durations}
                                      value={durations.find(
                                        (c) => c.value === value
                                      )}
                                      onChange={(val) =>
                                        val ? onChange(val.value) : onChange(0)
                                      }
                                      isClearable={true}
                                    // menuIsOpen={true}
                                    />
                                  )}
                                />
                                {errors.courseDuration?.message && (
                                  <span className="error">
                                    {errors.courseDuration?.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </>
                      ) : activeStep === 3 ? (
                        <>
                          <fieldset>
                            <div className="form-group mb-4">
                              <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                                <div className="w-100 d-flex flex-column">
                                  <div className="w-100 position-relative">
                                    <div className="w-100 position-relative d-flex justify-content-between">
                                      <label>
                                        Do you have an eSIM-compatible device?*
                                      </label>

                                    </div>
                                  </div>
                                  <p
                                    style={{ fontSize: 12 }}
                                    className="text-custom-muted m-0"
                                  >
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      target="_blank"
                                      rel="noopener norefferer"
                                      onClick={() =>
                                        window.open(
                                          "/esimCompatible",
                                          "_blank"
                                        )
                                      }
                                    >
                                      Click here
                                    </a>{" "}
                                    to check device compatibility.
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center ">
                                <>
                                  <div className="form-check col-6">
                                    <input
                                      {...register("deviceEsimCompatibleValue")}
                                      name="deviceEsimCompatibleValue"
                                      className="form-check-input"
                                      type="radio"
                                      value={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="deviceEsimCompatibleValue1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check col-6">
                                    <input
                                      {...register("deviceEsimCompatibleValue")}
                                      name="deviceEsimCompatibleValue"
                                      className="form-check-input"
                                      type="radio"
                                      value={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="deviceEsimCompatibleValue2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </>
                              </div>
                              {errors.deviceEsimCompatibleValue?.message && (
                                <span className="error">
                                  {errors.deviceEsimCompatibleValue?.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group mb-4">
                              <div className="d-flex justify-content-between align-items-start w-100 mb-3">
                                <label style={{ display: "block", textWrap: "nowrap" }}>
                                  Are you interested in a physical SIM?*
                                </label>
                                <Tooltip
                                  style={{ fontSize: 12 }}
                                  text="If your device isn't eSIM compatible, we can provide a physical SIM upon arrival"
                                >
                                  <BiInfoCircle
                                    style={{
                                      fontSize: 22,
                                      marginRight: 180,
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <div className="d-flex align-items-center ">
                                <>
                                  <div className="form-check col-6">
                                    <input
                                      {...register(
                                        "intrestedInPhysicalSimValue"
                                      )}
                                      name="intrestedInPhysicalSimValue"
                                      className="form-check-input"
                                      type="radio"
                                      value={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="intrestedInPhysicalSimValue1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check col-6">
                                    <input
                                      {...register(
                                        "intrestedInPhysicalSimValue"
                                      )}
                                      name="intrestedInPhysicalSimValue"
                                      className="form-check-input"
                                      type="radio"
                                      value={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="intrestedInPhysicalSimValue2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </>
                              </div>
                              {errors.intrestedInPhysicalSimValue?.message && (
                                <span className="error">
                                  {errors.intrestedInPhysicalSimValue?.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group mb-4">
                              <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                                <label>Are you currently in the UK?*</label>
                              </div>
                              <div className="d-flex align-items-center ">
                                <>
                                  <div className="form-check col-6">
                                    <input
                                      {...register("currentlyInUkValue")}
                                      name="currentlyInUkValue"
                                      className="form-check-input"
                                      type="radio"
                                      value={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="currentlyInUkValue1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check col-6">
                                    <input
                                      {...register("currentlyInUkValue")}
                                      name="currentlyInUkValue"
                                      className="form-check-input"
                                      type="radio"
                                      value={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="currentlyInUkValue2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </>
                              </div>
                              {errors.currentlyInUkValue?.message && (
                                <span className="error">
                                  {errors.currentlyInUkValue?.message}
                                </span>
                              )}
                            </div>
                            {watchIntrestedInPhysicalSimValue === "true" ? (
                              <>
                                <div className="form-group mb-3">
                                  <p style={{ color: "#E8BE61" }}>
                                    *Since you have opted for a physical SIM,
                                    please provide your UK address so we can
                                    deliver it to you.
                                  </p>
                                  <div className="form-group mb-3">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <label>Select which network SIM card would you like to receive?*</label>
                                    </div>
                                    <div
                                      className="custom-country-dropdown"
                                      style={{ position: "relative" }}
                                    >
                                      <Controller
                                        name="studentNetwork.studentNetworkId"
                                        control={control}
                                        render={({
                                          field: { onChange, value, ref },
                                        }) => (
                                          <Select
                                            classNames="form-control"
                                            placeholder={<div>Type to search</div>}
                                            options={studentNetwork}
                                            value={studentNetwork.find(
                                              (c) => c.value === value
                                            )}
                                            onChange={(val) =>
                                              val ? onChange(val.value) : onChange(0)
                                            }
                                            isClearable={true}
                                          // menuIsOpen={true}
                                          />
                                        )}
                                      />
                                      {errors.studentNetwork?.studentNetworkId && (
                                        <span className="error">
                                          {errors.studentNetwork.studentNetworkId.message}
                                        </span>
                                      )}

                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <label>Door / Flat no*</label>
                                  </div>
                                  <input
                                    name="flatNo"
                                    id="inputFlatNo"
                                    type="text"
                                    placeholder="Please enter the door / flat number"
                                    {...register("flatNo")}
                                    className={`form-control border-0 shadow-sm custom-input`}
                                    maxLength={20}
                                  />
                                  {errors.flatNo?.message && (
                                    <span className="error">
                                      {errors.flatNo?.message}
                                    </span>
                                  )}
                                </div>
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <label>Street address*</label>
                                  </div>
                                  <input
                                    name="streetAddress"
                                    id="inputstreetAddress"
                                    type="text"
                                    placeholder="Please enter the street address"
                                    {...register("streetAddress")}
                                    className={`form-control border-0 shadow-sm custom-input`}
                                    maxLength={100}
                                  />
                                  {errors.streetAddress?.message && (
                                    <span className="error">
                                      {errors.streetAddress?.message}
                                    </span>
                                  )}
                                </div>
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <label>State*</label>
                                  </div>
                                  <input
                                    name="state"
                                    id="inputstate"
                                    type="text"
                                    placeholder="Please enter the state"
                                    {...register("state")}
                                    className={`form-control border-0 shadow-sm custom-input`}
                                    maxLength={30}
                                  />
                                  {errors.state?.message && (
                                    <span className="error">
                                      {errors.state?.message}
                                    </span>
                                  )}
                                </div>
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <label>City*</label>
                                  </div>
                                  <input
                                    name="city"
                                    id="inputcity"
                                    type="text"
                                    placeholder="Please enter the city"
                                    {...register("city")}
                                    className={`form-control border-0 shadow-sm custom-input`}
                                    maxLength={30}
                                  />
                                  {errors.city?.message && (
                                    <span className="error">
                                      {errors.city?.message}
                                    </span>
                                  )}
                                </div>
                                <div className="form-group mb-3">
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <label>Zipcode / Postcode*</label>
                                  </div>
                                  <input
                                    name="postCode"
                                    id="inputpostCode"
                                    type="text"
                                    placeholder="Please enter the zip code / post code"
                                    {...register("postCode")}
                                    className={`form-control border-0 shadow-sm custom-input`}
                                    maxLength={10}
                                  />
                                  {errors.postCode?.message && (
                                    <span className="error">
                                      {errors.postCode?.message}
                                    </span>
                                  )}
                                </div>

                              </>
                            ) : null}
                            <div className="form-group mb-3">
                              <div className="d-flex align-items-center w-100">
                                <div
                                  className="custom-ref-checkbox m-0"
                                  style={{ paddingTop: "2px" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="haveReferralCode"
                                    checked={haveReferralCode}
                                    onChange={(e) =>
                                      setHaveReferralCode(e.target.checked)
                                    }
                                  />
                                </div>
                                <p className="m-0">
                                  Do you have a referral code?
                                </p>
                              </div>
                            </div>
                            {haveReferralCode && (
                              <div className="form-group mb-3">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label>Referral Code</label>
                                </div>
                                <input
                                  type="text"
                                  name="referralCode"
                                  {...register("referralCode")}
                                  placeholder="Enter your Referral Code"
                                  className={`form-control border-0 shadow-sm custom-input`}
                                  maxLength={10}
                                  readOnly={
                                    props.match.params.referralCode
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            )}
                            <div className="d-flex justify-content-start align-items-center custom-control register_subscribe_text ml-auto mb-3 p-0">
                              <div>
                                <input
                                  type="checkbox"
                                  name="toggle"
                                  id="toggle-button"
                                  className="toggle-button"
                                  checked={isToggled}
                                  onChange={handleToggle}
                                ></input>
                              </div>

                              <p className="m-0 ml-2">
                                I wish to subscribe to promotional emails.
                              </p>
                            </div>
                            <ReCAPTCHA
                              sitekey={REACT_APP_SITE_KEY}
                              ref={captchaRef}
                              onChange={() => setRecaptchaError(undefined)}
                            />
                            {recaptchaError && (
                              <span className="error">{recaptchaError}</span>
                            )}
                          </fieldset>
                        </>
                      ) : null}
                    </div>

                    <FormFeedback
                      style={{
                        display: message ? "block" : "none",
                        fontSize: 15,
                      }}
                      className="red"
                    >
                      {message}
                    </FormFeedback>
                    <div
                      style={{ gap: 4 }}
                      className="d-flex flex-column flex-md-row align-items-center"
                    >
                      {activeStep == 2 || activeStep == 3 ? (
                        <button
                          type="button"
                          onClick={() => handleStepperTrack("prev")}
                          style={{
                            fontSize: "0.875rem",
                            height: "50px",
                            fontWeight: 600,
                            borderRadius: "50rem",
                          }}
                          className="btn btn-outline-light btn-block text-uppercase my-2 px-4 py-lg-2 shadow-sm"
                        >
                          Back
                        </button>
                      ) : null}
                      {activeStep === 3 ? (
                        <button
                          type="submit"
                          style={{
                            fontSize: "0.875rem",
                            height: "50px",
                          }}
                          className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                        >
                          Register
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleStepperTrack("next")}
                          style={{
                            fontSize: "0.875rem",
                            height: "50px",
                          }}
                          className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                        >
                          Save & Proceed
                        </button>
                      )}
                    </div>
                  </form>

                  {activeStep === 3 && (
                    <div className="px-4 mt-4 register-footer notranslate">
                      <p className="text-muted mb-4 text-center">
                        By registering, you agree to our{" "}
                        <a
                          onClick={() => history.push("/termsandcondition")}
                          className="text-center"
                        >
                          {" "}
                          Terms & Conditions
                        </a>{" "}
                        and <br />
                        <a
                          onClick={() => history.push("/privacypolicy")}
                          className="text-center"
                        >
                          {" "}
                          Privacy Policy
                        </a>
                        . Rest assured, we'll keep your personal information
                        private and spam-free.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
          <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
        </div>
        <div className="col-md-6 d-none d-md-flex justify-content-center align-items-center student-login-bg-image">
          <div
            style={{ minHeight: "100%", padding: "160px 0" }}
            className="w-100 d-flex flex-column justify-content-between align-items-center"
          >
            <div className="login-card text-white text-center p-3">
              <p className="m-0">
                <span
                  style={{ color: "#E8BE61", fontWeight: 600, fontSize: 20 }}
                >
                  For International & UK Student’s
                </span>
                <br />
                <b style={{ fontSize: 24, margin: "6 0" }}>
                  FREE eSIM & 2GB DATA
                </b>
                <br />
                for use in the UK for your first month.
              </p>
            </div>
            <div className="login-card text-white text-center p-3">
              <p style={{ fontSize: 18 }} className="m-0">
                Enjoy a
                <b style={{ fontSize: 22, margin: "0 4px" }}> 20% discount </b>
                on your second month's plan. <br />
                Receive a
                <b style={{ fontSize: 22, margin: "0 4px" }}> 30% discount </b>
                on your third month's plan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ContactUsForm />
      {showLoader === true && <Loader />}

      {
        cautionModal &&
        <AlertPopup
          heading="Important Delivery Information for Non-UK Residents"
          content={
            <>
              <p style={{ color: "#fff" }}>
                Please ensure you have provided a correct UK address where someone will be available to receive the SIM delivery.
              </p>
              <span className="error">
                Please Note that we do not deliver outside the UK.

              </span>
            </>
          }
          status="warning"
          updateState={() => setCautionModal(false)}
          customFunction={
            <button
              className="btn rounded-pill font-weight-bold text-uppercase btn-light custom-width-btn btn-lg mt-2 mb-1"
              onClick={() => setCautionModal(false)}
            >
              Okay
            </button>
          }
        />
      }

      {/* <CustomModal
  width={500}
  backButton={false}
  isOpen={cautionModal}
  onClose={() => setCautionModal(false)}
  customPadding={30} // Reduced padding to allow more space for content
>
  <div style={{ color: '#000', textAlign: 'center', padding: '20px' }} className="normal-custom-modal">
    <h3 className="title mb-3"> Important Delivery Information for Non-UK Residents</h3>
    <p style={{ fontSize: 18, margin: 0 }}>
      Please ensure you have provided a correct UK address where someone will be available to receive the SIM delivery.
    </p>
    <p style={{ fontSize: 18, fontWeight: 'bold', color: 'red', marginTop: '10px' }}>
      Please Note that we do not deliver outside the UK.
    </p>
    <button 
      style={{ padding: '10px 20px', fontSize: '16px', marginTop: '20px', border: '2px solid black', backgroundColor: '#fff', color: 'black' }}
      onClick={() => setCautionModal(false)}
    >
      Okay
    </button>
  </div>
</CustomModal> */}


    </div>
  );
};

export default StudentRegistration;
