import React, { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import BackButton from "./components/BackButton";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";
import "../../scss/kiosk.scss";
import CustomModal from "./components/CustomModal";
import { BiExit } from "react-icons/bi";
import KioskBgElement from "./components/KioskBgElement";
import attentionpayment from "../../assets/img/icon-park-outline_caution.png";

const KioskQuickInstallation = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const history = useHistory();

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    if(!kioskDetails || kioskDetails.kioskId<=0){
      //not logged in
      history.push("/kiosk/login");
      return;
    }
    const storedOrderDetails = localStorage.getItem("orderSummaryAfterPayment");
    if (storedOrderDetails) {
      const parsedOrderDetails = JSON.parse(storedOrderDetails);
      setOrderDetails(parsedOrderDetails);
    }
  }, []);
  const backPrompt = () => {
    // clearInterval(orderInterval)
    history.push("/kiosk/paymentSuccess");
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const goToHome = () => {
    history.push("/kiosk");
  };
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    // if(localStorage.getItem("QRLink")){
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        history.push("/kiosk");
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
    //   }
  }, [seconds]); 


  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  return (
    <div className="kiosk-app">
      <Header />
      <section className="w-100 m-auto kiosk-container kiosk-checkout">
        <div className="mt-3">
          <div className="kiosk-heading-grid">
            <BackButton handleClick={backPrompt} />
            <h2 className="align-items-center kiosk-QuickInstall-title mb-2">
              Quick installation
            </h2>
          </div>
          {orderDetails && (
            <div className="col-12 py-2 pr-2 pl-2 mt-4 px-4 ScanQrCodeDiv auto-margin">
              {/* <h2 className="align-items-center kiosk-QuickInstall-title mb-2">
                      Quick installation
                    </h2> */}
              <div>
                <p style={{ textAlign: "center", fontSize: 22 }}>
                  Scan this QR code to get Immediate installation
                </p>
                <div>
                  <QRCode
                    id="targetQRcode"
                    level={"H"}
                    includeMargin={true}
                    value={orderDetails.orderPlan[0].esim[0].activationCode}
                    size={300}
                  />
                </div>
                <div className="flex items-center bottomText">
                  <div className="Or-text w-50 mx-auto">OR</div>
                  <p>
                    We have sent the installation instructions <br></br>to your
                    registered email address:<br></br>
                    <span className="font-weight-bold text-white">
                      {orderDetails.customer.email}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <div className="my-4">
                  <button
                    className="explore text-uppercase kiosk-grad-btn btn py-3 px-5"
                    onClick={handleOpen}
                  >
                    Explore more plans
                  </button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <div>
                    <img src={attentionpayment} alt="" width={"30px"} />
                  </div>
                  <h2
                    className="kiosk-sub-title-bottomattentiontext mb-2 ml-2"
                    I
                    style={{ textAlign: "center" }}
                  >
                    Please close this window after installation or it will close automatically when the timer ends.
                  </h2>
                </div>
                <h1 className="kiosk-title">
                  {formattedTime}
                  </h1>
                <div
                  style={{ gap: 50 }}
                  className="w-100 d-flex justify-content-center align-items-center mx-auto my-3"
                >
                  <button
                    className="text-uppercase btn btn-lg btn-outline-light rounded-pill w-10 font-weight-bold py-3"
                    onClick={handleOpen}
                  >
                    Close
                  </button>
                </div>
              </div>

              {/* <div className="justify-content-between align-items-center mt-4">
                <button
                  className="explore text-uppercase kiosk-grad-btn btn py-3 px-5"
                  onClick={goToHome}
                >
                  Explore more plans
                </button>
              </div>
              <div className="kiosk-view-more pb-3">
                <button
                  className="btn btn-lg btn-outline-light rounded-pill w-10"
                  onClick={handleOpen}
                >
                  Close
                </button>
              </div> */}
            </div>
          )}
        </div>
      </section>
      <CustomModal isOpen={open} onClose={handleClose} width={"normal"}>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <>
            <BiExit style={{ fontSize: "90px" }} />
            <h2 className="kiosk-title text-center">Exit </h2>
            <h3 className="kiosk-sub-title mb-3 text-center">
              Are you sure you want to exit?<br></br>
              Do you successfully installed the esim?
            </h3>
            <div
              style={{ gap: "200px" }}
              className="w-100 d-flex justify-content-center align-items-center"
            >
              <button
                onClick={goToHome}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                Yes
              </button>
              <button
                onClick={handleClose}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                No
              </button>
            </div>
          </>
        </div>
      </CustomModal>
      <KioskBgElement />
    </div>
  );
};

export default KioskQuickInstallation;
