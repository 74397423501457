import axios from 'axios';
import { API_URL } from '../../../Common/Constant';
import React, { Component } from 'react';

class KioskApplyVoucherService {
    
    appllyVoucher(voucherCode,userId,orderAmount,planId, kioskId, lastLoginDate) {
        const headers = {
            'kioskId': kioskId,
            'lastLoginDate': lastLoginDate
        };
        return axios.get(`${API_URL}/kiosk/applyVoucher?voucherCode=${voucherCode}&userId=${userId}&orderAmount=${orderAmount}&planId=${planId}`, { headers });
    }
}



export default new KioskApplyVoucherService()