import axios from 'axios';
import { API_URL } from '../Common/Constant';
class ResetPassword {

    forgotPasswordVerification(varification) {
        return axios.get(`${API_URL}/authentication/forgotPasswordVerification`, {headers:varification});      
    }
    resetPassword(reset){
        return axios.get(`${API_URL}/authentication/forgotPasswordReset`, {headers:reset});      
}
}
export default new ResetPassword()