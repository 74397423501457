import React, { useState, useEffect } from "react";
import loginBottomGrad from '../../assets/img/login-bottom-gradient.png';
import loginLeftGrad from '../../assets/img/login-left-gradient.png';
import LoginService from "../../Services/LoginService";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import UserVarificationService from "../../Services/UserVarificationService";

const Verify = (props) => {

  const history = useHistory();
  const [message, setMessage] = useState();
  const [verification, setVerification] = useState({
    verificationId: props.match.params.verificationId,
    userId: props.match.params.userId,
  });
  const [code, setCode] = useState();

  
  useEffect(() => {    
    verificationAPI();
  }, []);
 

  const verificationAPI = () => {
    UserVarificationService.verify(verification)
      .then((response) => {
        if(response.status == 200)
        {
            setCode(200)
    }
      }).catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason)
              setCode(401)
              break;
            case 403:
              setMessage(error.response.data.failedReason)
              setCode(403)
              break;
            case 500:
              setMessage(error.response.data.failedReason)
              setCode(500)
              break;
            case 404:
            case 406:
              setMessage(error.response.data.failedReason)
              setCode(406)
              break;
              case 409:
              setMessage(error.response.data.failedReason)
              setCode(409)
              break;
            case 412:
              setMessage(error.response.data.failedReason)
              setCode(412)
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
    
  }

  const loginClick = () => {
    history.push(`/login`)
  }

   // google analytics

   useEffect(() => {
    document.title = "Verify Page";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div style={{overflow: 'hidden'}} className="container-fluid p-0">
      <div className="row no-gutter">
        <div className="col-md-6 login-bg first-section">
          <div className="login d-flex align-items-center py-5">
            {code===200 && 
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                  {/* {message==}   */}
                  <h1 className="login-heading text-center">Congratulations! 🎉</h1>
                  <p className="">
                  Your email address is verified!
                  </p>
                  <h4 className=" mb-2 text-center">
                  After verification please sign out and sign in again*
                  </h4><br/>
                  <h6 className="text-muted mb-2 text-center">🔐 Log in to GKT eSIM website</h6>

                  <form>
                    
                    {/* <div className="custom-control ml-auto mb-3">
                      
                      <a className="pull-right" href="#">Forgot password?</a>
                      
                    </div> */}
                    <button
                      onClick={() => loginClick()}
                      type="submit"
                      className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                    >
                      Login
                    </button>
                    {/* <p className="text-muted mb-4 text-center"> */}
                    {/* 🔐 Log in to GKT eSIM website <span onClick={loginClick}><a href="#">
                    
                    Login Now
                    </a> 
                    </span> */}
                  {/* </p> */}
                 
             {/* <br/>🌐 Explore and buy our eSIM plans: */}
             <br/>

                  </form>
                  <div>
                  <FormFeedback
                            style={{
                              display:
                                message === "" ||
                                message === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {message}
                          </FormFeedback>
                  </div>
                  <div className="px-4 mt-4 register-footer">
                    <p className="text-muted mb-4 text-center">
                    📱 Download our{" "}
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
                      >
                        iOS
                      </a>{" "}
                      and 
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
                      >
                        Android app<br />
                      </a>
                      Welcome to GKT eSIM family. Enjoy the convenience and flexibility of our services!.
                    </p>
                  </div>
                </div>
              </div>
            </div>}

            {code===409 && 
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                  {/* {message==}   */}
                  <h1 className="login-heading text-center">You are already Verified! </h1><br/>
                  <h4 className=" mb-2 text-center">
                  Your profile is already verified, Please log in

                  </h4><br/>
                  
                  <form>
                    {/* <h6 className="text-muted mb-2 text-center">
                    We apologise for any inconvenience. Let us help you get verified and start enjoying our services.
                    </h6>
                    <br/> */}
                    <h6 className="text-muted mb-2 text-center">🔐 Log in to GKT eSIM website

                    </h6>
                    {/* <div className="custom-control ml-auto mb-3">
                      
                      <a className="pull-right" href="#">Forgot password?</a>
                      
                    </div> */}
                    <button
                      onClick={() => loginClick()}
                      type="submit"
                      className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                    >
                      Login
                    </button>
                    {/* <p className="text-muted mb-4 text-center"> */}
                    {/* 🔐 Log in to GKT eSIM website <span onClick={loginClick}><a href="#">
                    
                    Login Now
                    </a> 
                    </span> */}
                  {/* </p> */}
                 
             {/* <br/>🌐 Explore and buy our eSIM plans: */}
             <br/>

                  </form>
                  {/* <div>
                  <FormFeedback
                            style={{
                              display:
                                message === "" ||
                                message === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {message}
                          </FormFeedback>
                  </div> */}
                  <div className="px-4 mt-4 register-footer">
                    <p className="text-muted mb-4 text-center">
                    📱 Download our{" "}
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
                      >
                        iOS
                      </a>{" "}
                      and 
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
                      >
                        Android app<br />
                      </a>
                      Welcome to GKT eSIM family. Enjoy the convenience and flexibility of our services!.
                    </p>
                  </div>
                </div>
              </div>
            </div>}
            {code===406 && 
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                  {/* {message==}   */}
                  <h1 className="login-heading text-center">Verification Failed! 😔</h1><br/>
                  <h4 className=" mb-2 text-center">
                  This verification link has expired. Kindly request a new one through your account.

                  </h4><br/>
                  
                  <form>
                    <h6 className="text-muted mb-2 text-center">
                    We apologise for any inconvenience. Let us help you get verified and start enjoying our services.
                    </h6><br/>
                    <h6 className="text-muted mb-2 text-center">🔐 Log in to GKT eSIM website

                    </h6>
                    {/* <div className="custom-control ml-auto mb-3">
                      
                      <a className="pull-right" href="#">Forgot password?</a>
                      
                    </div> */}
                    <button
                      onClick={() => loginClick()}
                      type="submit"
                      className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                    >
                      Login
                    </button>
                    {/* <p className="text-muted mb-4 text-center"> */}
                    {/* 🔐 Log in to GKT eSIM website <span onClick={loginClick}><a href="#">
                    
                    Login Now
                    </a> 
                    </span> */}
                  {/* </p> */}
                 
             {/* <br/>🌐 Explore and buy our eSIM plans: */}
             <br/>

                  </form>
                  {/* <div>
                  <FormFeedback
                            style={{
                              display:
                                message === "" ||
                                message === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {message}
                          </FormFeedback>
                  </div> */}
                  <div className="px-4 mt-4 register-footer">
                    <p className="text-muted mb-4 text-center">
                    📱 Download our{" "}
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
                      >
                        iOS
                      </a>{" "}
                      and 
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
                      >
                        Android app<br />
                      </a>
                      Welcome to GKT eSIM family. Enjoy the convenience and flexibility of our services!.
                    </p>
                  </div>
                </div>
              </div>
            </div>}
            {code===500 && 
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                  {/* {message==}   */}
                  <h1 className="login-heading text-center">Verification Failed! 😔</h1><br/>
                  <h4 className=" mb-2 text-center">
                  We're sorry, but we couldn't verify your email address.<br/>
                 

                  </h4><br/>
                 <p className=" mb-2 text-center"> ⚙️ Please make sure you clicked on the correct verification link.</p> 
                  <form>
                    <h6 className="text-muted mb-2 text-center">
                    We apologise for any inconvenience. Let us help you get verified and start enjoying our services.
                    </h6><br/>
                    <h6 className="text-muted mb-2 text-center">🔐 Log in to GKT eSIM website

                    </h6>
                    {/* <div className="custom-control ml-auto mb-3">
                      
                      <a className="pull-right" href="#">Forgot password?</a>
                      
                    </div> */}
                    <button
                      onClick={() => loginClick()}
                      type="submit"
                      className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                    >
                      Login
                    </button>
                    {/* <p className="text-muted mb-4 text-center"> */}
                    {/* 🔐 Log in to GKT eSIM website <span onClick={loginClick}><a href="#">
                    
                    Login Now
                    </a> 
                    </span> */}
                  {/* </p> */}
                 
             {/* <br/>🌐 Explore and buy our eSIM plans: */}
             <br/>

                  </form>
                  {/* <div>
                  <FormFeedback
                            style={{
                              display:
                                message === "" ||
                                message === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {message}
                          </FormFeedback>
                  </div> */}
                  <div className="px-4 mt-4 register-footer">
                    <p className="text-muted mb-4 text-center">
                    📱 Download our{" "}
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
                      >
                        iOS
                      </a>{" "}
                      and 
                      <a
                        className="text-center"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
                      >
                        Android app<br />
                      </a>
                      Welcome to GKT eSIM family. Enjoy the convenience and flexibility of our services!.
                    </p>
                  </div>
                </div>
              </div>
            </div>}
          </div>
      <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
      <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
        </div>
        {/* <div className="col-md-6 d-none d-md-flex login-bg-image"></div> */}
        <div className="col-md-6 d-none">
        {/* <div className="world-cup-content">
            <div>
              <h3 className="mb-2 text-center">
                Secure Your
                <br />
                 2023 World Cup Ticket
              </h3>
              <h3 className="text-center">
                <span>

                JOIN LUCKY DRAW 
                </span>
                <br />
                Register & Verify Your Email by October 31st!
              </h3>
            </div>

            <div style={{marginBottom: '10px'}} className="mt-auto">
              <h1>REGISTER NOW!</h1>
              <p>
                <span style={{fontSize: '8.8px',fontWeight: '700'}}>
                2023 World Cup Ticket Lucky Draw T&C: </span>Register by Oct 31st to
                Enter! Unique Email Id is a must. The number of users signing up
                through your unique code will be considered. The number of
                conversion will be considered. Organisers have all rights
                regarding the final decision in this campaign.
              </p>
            </div>
          </div> */}
        </div>
      </div>


    </div>
  );
};

export default Verify;
