import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import AboutUsHeroBg from "../../assets/img/Team-img.jpg";
import Footer from "../Footer/Footer";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import googlePlay from "../../assets/img/googleplay.png";
import appStore from "../../assets/img/appstore.png";
import downloadRedirect from "../../assets/img/esim_download.png";
import { useHistory } from "react-router-dom";
import CtaBanner from "../Common/CtaBanner";
import ContactUsForm from "../Common/ContactUsForm";
import useScrollToTop from "../custom_hooks/useScrollToTop";

// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AboutUS = () => {
  const history = useHistory();
  useScrollToTop()
   // google analytics

   useEffect(() => {
    document.title = "About Us";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div style={{ position: "relative" }} className="about-us-section">
      <header>
        <Header />
      </header>
      <section className="about-us-container container mt-5">
        <div className="row m-0">
          <div className="col-12">
            <h1 className="mb-3">GK Telecom, A Trusted Brand.</h1>
          </div>
          <div className="col-12 col-md-6 order-1 pr-5 pt-3 mb-4">
            <p className="">
              GK Telecom was founded over 20 years ago and has steadily grown
              into one of the largest and most reputable SIM and calling card
              distributors in the UK.
            </p>
            <p>
              GK Telecom was founded in 2000 predominately focusing on calling
              cards. GK Telecom diversified into the pre paid SIM market in 2004
              and over a period of time this market has developed into maturity
              and GK Telecom is now one of only four SIM Only Distributors in
              the UK selling in excess of 100,000 SIMS per month across various
              networks.
            </p>
            <div className="mt-4">
              <button
                onClick={() => history.push("/")}
                className="btn btn-custom-gradient text-uppercase shadow-sm px-4"
              >
                Get Started
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 order-2 d-flex justify-content-center align-items-start mb-4">
            <img
              src={AboutUsHeroBg}
              style={{ width: "100%", borderRadius: "10px" }}
              alt="AboutUsHeroBg"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="col-12">
              <h2 className="font-weight-bold">Know more about us</h2>
            </div>
            <div className="col-12">
              <p>
                With a wealth of experience as a SIM distributor, GK has
                established itself as a leader in the industry. However, we are
                now embarking on an exciting transformational journey by
                embracing the new frontier of eSIM technology. Our mission is to
                continue providing a world-className experience to users through
                our innovative eSIM platform. eSIM represents a groundbreaking
                shift that is set to replace traditional methodologies. At GK,
                we are passionate about ensuring that this revolutionary
                technology reaches every stakeholder, empowering them with
                seamless connectivity. Our goal is to make eSIM adoption
                accessible to all, offering a simple and straightforward process
                that opens doors to a new era of convenience and connectivity.
                Through our expertise and dedication, we are committed to
                delivering exceptional service, safeguarding data, and providing
                secure transactions. Join us on this remarkable journey as we
                revolutionize the way people connect and experience the world
                through the power of eSIM technology.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="row mt-5">
          <div className="col-12">
            <div className="pt-5 team4">
              <div className="container">
                <div className="row justify-content-center mb-4">
                  <div className="col-md-7 text-center">
                    <h2 className="font-weight-bold">Meet our team</h2>
                    <h6 className="subtitle">
                      You can relay on our amazing features list and also our
                      customer services will be great experience for you without
                      doubt and in no-time
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 mb-4">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-6 d-flex justify-content-center align-items-center">
                        <img
                          src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t1.jpg"
                          alt="wrapkit"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <div className="pt-2">
                          <h5 className="mt-4 font-weight-medium mb-0">
                            Michael Doe
                          </h5>
                          <h6 className="subtitle mb-3">Property Specialist</h6>
                          <p>
                            You can relay on our amazing features list and also
                            our customer services will be great experience.
                          </p>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-facebook"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-twitter"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-instagram"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-behance"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mb-4">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-6 d-flex justify-content-center align-items-center">
                        <img
                          src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg"
                          alt="wrapkit"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <div className="pt-2">
                          <h5 className="mt-4 font-weight-medium mb-0">
                            Michael Doe
                          </h5>
                          <h6 className="subtitle mb-3">Property Specialist</h6>
                          <p>
                            You can relay on our amazing features list and also
                            our customer services will be great experience.
                          </p>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-facebook"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-twitter"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-instagram"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-behance"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mb-4">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-6 d-flex justify-content-center align-items-center">
                        <img
                          src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg"
                          alt="wrapkit"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <div className="pt-2">
                          <h5 className="mt-4 font-weight-medium mb-0">
                            Michael Doe
                          </h5>
                          <h6 className="subtitle mb-3">Property Specialist</h6>
                          <p>
                            You can relay on our amazing features list and also
                            our customer services will be great experience.
                          </p>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-facebook"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-twitter"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-instagram"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-behance"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mb-4">
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-6 d-flex justify-content-center align-items-center">
                        <img
                          src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t4.jpg"
                          alt="wrapkit"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <div className="pt-2">
                          <h5 className="mt-4 font-weight-medium mb-0">
                            Michael Doe
                          </h5>
                          <h6 className="subtitle mb-3">Property Specialist</h6>
                          <p>
                            You can relay on our amazing features list and also
                            our customer services will be great experience.
                          </p>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-facebook"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-twitter"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-instagram"></i>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a
                                href="#"
                                className="text-decoration-none d-block px-1"
                              >
                                <i className="icon-social-behance"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="break-line mt-5"></div>
        <div className="download-redirect container">
          <div className="col-12 col-md-6 px-2 px-lg-5 d-flex flex-column justify-content-center align-items-center">
            <h2>Get your GK eSIM app</h2>
            <p>Seamless experience for users. </p>
            <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-2">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
              >
                <img
                  style={{ width: "140px", margin: "0 10px" }}
                  src={appStore}
                  alt="appStore"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
              >
                
                <img
                  style={{ width: "140px", margin: "0 10px" }}
                  src={googlePlay}
                  alt="googlePlay"
                />
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <img src={downloadRedirect} alt="downloadRedirect" />
          </div>
        </div>
        <div className="break-line mt-5"></div> */}
      </section>
      <CtaBanner/>

      <img className="leftBottomImg" src={leftBottomImg} alt="" />
      <img
        className="ref-right-grad-3"
        src={refRightGrad3}
        alt="refRightGrad1"
      />
      <ContactUsForm/>
      <footer className="mt-3">
        <Footer />
      </footer>
    </div>
  );
};

export default AboutUS;
