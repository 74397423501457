import React from "react";
import googlePlay from "../../assets/img/googleplay.png";
import appStore from "../../assets/img/appstore.png";

const CtaBanner = () => {
  return (
    <div style={{position: 'relative', zIndex: '3', color: '#fff'}} className="container p-0">
      <div className="my-4 cta-banner cards-bg row m-0 w-100">
        <div className="cta-banner-content ">
          <div className="cta-body">
            <h2 className="m-0 mb-lg-2 text-center">Get your GKT eSIM app</h2>
            <p className="m-0 text-center">Seamless experience for users.</p>
          </div>

          <div className="cta-footer mt-lg-0 mt-4 d-flex">
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <p>Available for</p>
              <span>DOWNLOAD!</span>
            </div>
            <div className="w-100 d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
              >
                <img
                  src={appStore}
                  alt="appStore"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
              >
                <img
                  src={googlePlay}
                  alt="googlePlay"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
