import axios from 'axios';
import { API_URL } from '../Common/Constant';
import React, { Component } from 'react';

class ContactUsService {
    
    contactUs(json) {
        return axios.post(`${API_URL}/webappapi/user/contactUs`,json, {
        });
    }
    
}



export default new ContactUsService()