import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import "../../scss/kiosk.scss";
import star from "../../assets/img/stars.png";
import popularIcon from "../../assets/elements/popular_icon.png";
import premiumIcon from "../../assets/elements/premium_icon.png";
import unlimitedIcon from "../../assets/elements/unlimited_icon.png";
import CustomModal from "./components/CustomModal";
import BackButton from "./components/BackButton";
import MicroLoader from "../Alert_Popup/MicroLoader";
import { IoMdStopwatch } from "react-icons/io";
import { FaPaperPlane } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const KioskPlanDetails = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const history = useHistory();

  const firstPlansSection = [
    {
      data: 1,
      details: "1 GB - 30 Days",
      validity: 30,
      price: 84.99,
      type: "Popular",
      typeIcon: popularIcon,
    },
    {
      data: 5,
      details: "5 GB - 40 Days",
      validity: 40,
      price: 94.99,
      type: "Premium",
      typeIcon: premiumIcon,
    },
    {
      data: "Unlimited",
      details: "Unlimited - enhance 40 Days",
      validity: 40,
      price: 104.99,
      type: "Unlimited",
      typeIcon: unlimitedIcon,
    },
    {
      data: 15,
      details: "15 GB - 60 Days",
      validity: 60,
      price: 104.99,
      type: "Popular",
      typeIcon: popularIcon,
    },
    {
      data: 20,
      details: "20 GB - 40 Days",
      validity: 40,
      price: 129.99,
      type: "Premium",
      typeIcon: premiumIcon,
    },
  ];
  const secondPlansSection = [
    {
      data: 15,
      details: "Random_1",
      validity: 60,
      price: 104.99,
      type: "Popular",
      typeIcon: popularIcon,
    },
    {
      data: 20,
      details: "Random_2",
      validity: 40,
      price: 129.99,
      type: "Premium",
      typeIcon: premiumIcon,
    },
    {
      data: 15,
      details: "Random_1",
      validity: 60,
      price: 104.99,
      type: "Popular",
      typeIcon: popularIcon,
    },
    {
      data: 20,
      details: "Random_2",
      validity: 40,
      price: 129.99,
      type: "Premium",
      typeIcon: premiumIcon,
    },
    {
      data: 15,
      details: "Random_1",
      validity: 60,
      price: 104.99,
      type: "Popular",
      typeIcon: popularIcon,
    },
    {
      data: 20,
      details: "Random_2",
      validity: 40,
      price: 129.99,
      type: "Premium",
      typeIcon: premiumIcon,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClick = () => { };
  const handleModalClick = () => {
    setCount(count + 1);
    if (count == 3) {
      setCount(0);
    } else {
    }
  };

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    if (!kioskDetails || kioskDetails.kioskId <= 0) {
      //not logged in
      history.push("/kiosk/login");
      return;
    }
  }, []);

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  return (
    <div className="kiosk-app">
      <Header />
      <div className="w-100 m-auto kiosk-container">
        <div className="d-flex justify-content-between align-items-center">
          <BackButton handleClick={handleClick} />
          <div
            title="United Kingdom of Great Britain and Northern Ireland"
            className="d-flex justify-content-end align-items-center kiosk-country-bar"
          >
            <span className="fflag fflag-GB ff-lg ff-round mr-3"></span>
            <p className="kiosk-title text-truncate m-0">
              United Kingdom of Great Britain and Northern Ireland
            </p>
          </div>
        </div>
        <div className="mt-4">
          <div className="d-flex">
            <h2 className="kiosk-sub-title mb-2" style={{ textAlign: "start" }}>
              Explore limitless connectivity with our plans!
            </h2>
            <div className="ml-3">
              <img src={star} alt="" />
            </div>
          </div>
          <div className="d-flex flex-wrap kiosk-plan-container m-0 pr-3">
            {firstPlansSection.map((item, index) => (
              <div style={{ width: "33.33%" }} key={index} className="p-2">
                <div className="kiosk-card">
                  <div className="golden-label d-flex align-items-center">
                    <img
                      className="mr-1"
                      alt="popular"
                      style={{ width: "22px" }}
                      src={item.typeIcon}
                    />
                    <span className="d-inline-block text-truncate number">
                      {item.type} #1
                    </span>
                  </div>
                  <h2 className="mb-2 number kiosk-text">{item.data} GB</h2>
                  <div className="d-flex align-items-center kiosk-sub-text">
                    <p className="muted-text m-0">Plan details:</p>
                    <p className="number m-0 ml-2 text-truncate">
                      {item.details}
                    </p>
                  </div>
                  <div className="w-100 d-flex flex-wrap justify-content-start align-items-center my-3">
                    <div className="kiosk-sub-text">
                      <p className="muted-text m-0 mb-2">Validity: </p>
                      <p className="number m-0">30 Days</p>
                    </div>
                    <div className="line-seperator"></div>
                    <div className="kiosk-sub-text">
                      <p className="muted-text m-0 mb-2">Price</p>
                      <p className="number m-0">£ 84.62</p>
                    </div>
                  </div>
                  <button
                    onClick={handleOpen}
                    className="btn btn-block btn-lg btn-light rounded-pill"
                  >
                    BUY NOW
                  </button>
                </div>
              </div>
            ))}

            {secondPlansSection.map((item, index) => (
              <div style={{ width: "33.33%" }} key={index} className="p-2">
                <div className="kiosk-card">
                  <div className="golden-label d-flex align-items-center">
                    <img
                      className="mr-1"
                      alt="popular"
                      style={{ width: "22px" }}
                      src={item.typeIcon}
                    />
                    <span className="d-inline-block text-truncate number">
                      {item.type} #2
                    </span>
                  </div>
                  <h2 className="mb-2 number kiosk-text">{item.data} GB</h2>
                  <div className="d-flex align-items-center kiosk-sub-text">
                    <p className="muted-text m-0">Plan details:</p>
                    <p className="number m-0 ml-2 text-truncate">
                      {item.details}
                    </p>
                  </div>
                  <div className="w-100 d-flex flex-wrap justify-content-start align-items-center my-3">
                    <div className="kiosk-sub-text">
                      <p className="muted-text m-0 mb-2">Validity: </p>
                      <p className="number m-0">30 Days</p>
                    </div>
                    <div className="line-seperator"></div>
                    <div className="kiosk-sub-text">
                      <p className="muted-text m-0 mb-2">Price</p>
                      <p className="number m-0">£ 84.62</p>
                    </div>
                  </div>
                  <button
                    onClick={handleOpen}
                    className="btn btn-block btn-lg btn-light rounded-pill"
                  >
                    BUY NOW
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="kiosk-view-more">
            <button className="btn btn-lg btn-outline-light rounded-pill">
              View more plans
            </button>
          </div>
        </div>
      </div>
      <CustomModal isOpen={open} onClose={handleClose} backButton={true} width={"normal"}>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          {count == 0 || count == 1 ? (
            <>
              <div style={{ textAlign: "start" }}>
                <h3 className="kiosk-sub-title mb-3">
                  To continue please enter your email id
                </h3>
              </div>
              <div className="input-section my-2">
                <input type="text" placeholder="Please enter your email id" />
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  onClick={handleModalClick}
                  style={{ height: "60px" }}
                  className="btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  {count == 0 ? (
                    "Continue"
                  ) : count == 1 ? (
                    <MicroLoader />
                  ) : null}
                </button>
              </div>
            </>
          ) : count == 2 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span className="font-weight-bold text-white">
                    Resend email
                  </span>
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div className="kiosk-timer">
                  <IoMdStopwatch />
                  <h2>{formattedTime}</h2>
                </div>
                <span className="info">
                  *Verification status checking is in progress
                </span>
              </div>
            </>
          ) : count == 3 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span className="font-weight-bold text-white">
                    Resend email
                  </span>
                </p>
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  onClick={handleModalClick}
                  style={{ height: "60px" }}
                  className="btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  Proceed
                </button>
                <span className="error mt-3">
                  *Verification hasn't been completed from your end.
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default KioskPlanDetails;
