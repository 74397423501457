import axios from "axios";
import { API_URL } from "../Common/Constant";
class RecaptchaService {
  verify(token) {
    return axios.get(`${API_URL}/recaptcha/verify`, {
      headers: { 'token': token },
    });
  }
}
export default new RecaptchaService();
