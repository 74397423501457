import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import CtaBanner from "../Common/CtaBanner";
import ContactUsForm from "../Common/ContactUsForm";
import toast, { Toaster } from "react-hot-toast";

const AccordionPrivacyItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, PrivacyPolicy } = props;
  const { header, id, text } = PrivacyPolicy;
  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const AccordionSelectionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, PrivacyPolicy } = props;
  const { header, id, text } = PrivacyPolicy;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const AccordionThirdItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, PrivacyPolicy } = props;
  const { header, id, text } = PrivacyPolicy;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const PrivacyPolicy = () => {
  const [active, setActive] = useState(null);
  const [selectionActive, setSelectionActive] = useState(null);
  const [thirdActive, setThirdActive] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoggidIn, setIsLoggidOn] = useState();
  const [hTIStatus, setHTIStatus] = useState(false);

  const esimButtonRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let user = localStorage.getItem("token");
    setIsLoggidOn(user);
  }, [isLoggidIn, hTIStatus]);

  const handleCoverageClick = (event) => {
    if (
      esimButtonRef.current &&
      !esimButtonRef.current.contains(event.target)
    ) {
      setHTIStatus(!hTIStatus);
    } else {
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);
    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [hTIStatus]);

  const handlePrivacyToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handlePrivacySelectionToggle = (index) => {
    if (selectionActive === index) {
      setSelectionActive(null);
    } else {
      setSelectionActive(index);
    }
  };

  const handleThirdToggle = (index) => {
    if (thirdActive === index) {
      setThirdActive(null);
    } else {
      setThirdActive(index);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // google analytics

  useEffect(() => {
    document.title = "Privacy Policy";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  const PrivacyPolicy = [
    {
      id: 1,
      header: "Purpose",
      text: "The purpose of this Policy is to ensure that necessary records and documents of GK Telecom Limited are adequately protected and maintained and to ensure that records that are no longer needed by GK Telecom Limited or are of no value are discarded at the proper time. This Policy is also for the purpose of aiding employees of GK Telecom Limited in understanding their obligations in retaining electronic documents – including e-mail, Web files, text files, sound and video files, PDF documents, and all Microsoft Office or other formatted files.",
    },
    {
      id: 2,
      header: "Policy",
      text: `This Policy represents GK Telecom Limited’s policy regarding the retention and disposal of records and the retention and disposal of electronic documents.`,
    },
    {
      id: 3,
      header: "Administration",
      text: (
        <>
          {
            "Attached as Appendix A is a Record Retention Schedule that is approved as the initial maintenance, retention and disposal schedule for physical records of GK Telecom Limited and the retention and disposal of electronic documents. We will make modifications to the Record Retention Schedule from time to time to ensure that it follows National legislation and includes the appropriate document and record categories for GK Telecom Limited; monitor legislation affecting record retention; annually review the record retention and disposal program; and monitor compliance with this Policy."
          }
          <br />
          <br />

          {
            "In addition, any retained information can only be used for the purpose for which it is stored. This is compliant with the Data Protection Act 1998 and the General Data"
          }
          <br />
          <br />

          {"Protection Regulation (GDPR) (Regulation (EU) 2016/679)"}
        </>
      ),
    },
    {
      id: 4,
      header:
        "Suspension of Record Disposal in Event of Legal Proceedings or Claims",
      text: (
        <>
          {
            "There are certain occasions when information needs to be preserved beyond any limits set out in the Policy. The Policy must be SUSPENDED relating to a specific customer or document and the information retained beyond the period specified in GK Telecom Limited’s Data Retention Schedule in the following circumstances:"
          }
          <br />
          <br />
          {
            "Legal proceedings or a regulatory or similar investigation or obligation to produce information are known to be likely, threatened or actual."
          }
          <br />
          <br />
          {"A crime is suspected or detected."}
          <br />
          <br />
          {
            "Information is relevant to a company in liquidation or receivership, where a debt is due to GK Telecom Limited. Information is considered by the owning unit to be of potential historical importance and this has been confirmed by the Administrator."
          }
          <br />
          <br />
          {
            "In the case of possible or actual legal proceedings, investigations or crimes occurring, the type of information that needs to be retained relates to any that will help or harm GK Telecom Limited or the other side’s case or liability or amount involved."
          }
          <br />
          <br />
          {
            "If there is any doubt over whether legal proceedings, an investigation or a crime could occur, or what information is relevant or material in these circumstances, the Administrator should be contacted, and legal advice sought. The Administrator shall take such steps as is necessary to promptly inform all staff of any suspension in the further disposal of documents."
          }
        </>
      ),
    },
    {
      id: 5,
      header: "Security of personal information",
      text: (
        <>
          {
            "GK Telecom Limited will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information."
          }
          <br />
          <br />
          {
            "GK Telecom Limited will store all personal information on our secure (password- and firewall-protected) servers."
          }
          <br />
          <br />
          {
            "The Client should acknowledge that the transmission of information over the internet is inherently insecure, and that GK Telecom Limited cannot guarantee the security of data sent over the internet."
          }
          <br />
          <br />
          {
            "The Client will be responsible for keeping their Username and Password used for accessing GK Telecom Limited’s website confidential; GK Telecom Limited will not ask for password other than when needed to log in to our website."
          }
        </>
      ),
    },
    {
      id: 6,
      header: "Amendments",
      text: `GK Telecom Limited may update this policy from time to time by publishing a new version.

      This page should be checked occasionally to ensure that the policy remains relevant.`,
    },
    {
      id: 7,
      header: "Applicability",
      text: `This Policy applies to all physical records generated during GK Telecom Limited’s operation, including both original documents and reproductions. It also applies to the electronic documents described above.`,
    },
  ];

  const PrivacyPolicySectionTopic = [
    {
      id: 1,
      header: "ACCOUNTING AND FINANCE",
      text: (
        <>
          {"Record Type and then Retention Period"}
          {
            <ul className="pl-4">
              <li>Annual Audit Reports and Financial Statements: Permanent</li>
              <li>
                Annual Audit Records, including work papers and other documents
                that relate to the audit: 7 years after completion of audit
              </li>
              <li>Annual Plans and Budgets: 7 years</li>
              <li>Bank Statements and Cancelled Cheques: 7 years</li>
              <li>Employee Expense Reports: 7 years</li>
              <li>Interim Financial Statements: 7 years</li>
            </ul>
          }
        </>
      ),
    },
    {
      id: 2,
      header: "CONTRACTS",
      text: (
        <>
          {"Record Type and then Retention Period"} <br />
          {
            "Contracts and Related Correspondence (including any proposal that resulted in the contract and all other supportive documentation):  7 years after expiration or termination"
          }
        </>
      ),
    },
    {
      id: 3,
      header: "CORPORATE RECORDS",
      text: (
        <>
          {"Record Type and then Retention Period"} <br />
          {
            <ul className="pl-4">
              <li>
                Corporate Records (minutes, signed minutes of the Board and all
                committees, record of incorporation, articles of incorporation,
                annual corporate reports): Permanent
              </li>
              <li>Licenses and Permits: Permanent</li>
            </ul>
          }
        </>
      ),
    },
    {
      id: 4,
      header: "CORRESPONDENCE AND INTERNAL MEMORANDA",
      text: (
        <>
          {
            "General Principle: Most correspondence and internal memoranda should be retained for the same period as the document to which they pertain or support. For instance, a letter pertaining to a particular contract would be retained as long as the contract (7 years after expiration). It is recommended that records that support a particular project be kept with the project and take on the retention time of that particular project file."
          }{" "}
          <br />
          {
            "Correspondence or memoranda that do not pertain to documents having a prescribed retention period should generally be discarded sooner. These may be divided into two general categories:"
          }{" "}
          <br />
          {
            <ol className="pl-4">
              <li>
                Those pertaining to non-routine matters or having significant
                lasting consequences should generally be retained permanently.
              </li>
              <li>
                Those pertaining to routine matters and having no significant,
                lasting consequences should be discarded within five years. Some
                examples include:
              </li>
              <ul className="pl-3">
                <li>
                  Routine letters and notes that require no acknowledgment or
                  follow up, such as notes of appreciation, congratulations,
                  letters of transmittal, and plans for meetings.
                </li>
                <li>Form letters that require no follow up.</li>
                <li>
                  Letters of general inquiry and replies that complete a cycle
                  of correspondence.
                </li>
                <li>
                  Letters or complaints requesting specific action that have no
                  further value after changes are made or action taken (such as
                  name or address change).
                </li>
                <li>
                  Other letters of inconsequential subject matter or that
                  definitely close correspondence to which no further reference
                  will be necessary.
                </li>
                <li>Chronological correspondence files.</li>
                <li>
                  Please note that copies of interoffice correspondence and
                  documents where a copy will be in the originating department
                  file should be read and destroyed, unless that information
                  provides reference to or direction to other documents and must
                  be kept for project traceability.
                </li>
              </ul>
            </ol>
          }
        </>
      ),
    },
    {
      id: 5,
      header: "RETAINING PERSONAL INFORMATION",
      text: (
        <>
          <ol className="pl-4">
            <li>
              This Section sets out the data retention policies and procedure of
              GK Telecom Limited, which are designed to help ensure compliance
              with legal obligations in relation to the retention and deletion
              of personal information
            </li>
            <li>
              Personal information that is processed by GK Telecom Limited for
              any purpose or purposes shall not be kept for longer than is
              necessary for that purpose or those purposes.
            </li>
          </ol>
          {
            "Without prejudice to point 2 (above) GK Telecom Limited will usually delete personal data falling within the categories set out below at the date/time set out below:"
          }
          <br />
          {"Record Type and then Retention Period"}
          <ul className="pl-4">
            <li>
              Information about a computer and about visits to and use of this
              website (including an IP address, geographical location, browser
              type and version, operating system, referral source, length of
              visit, page views and website navigation paths: 2 years
            </li>
            <li>
              Information provided when registering with our website (including
              email address): 2 years
            </li>
            <li>
              Information provided when completing a profile on our website
              (including a name, gender, date of birth, interests and hobbies,
              educational details): 2 years
            </li>
          </ul>
          <br />
          {
            "Information provided for subscribing to email notifications and/or newsletters (including a name and email address):    Indefinitely or until the client chooses to ‘unsubscribe’"
          }
          <ul className="pl-4">
            <li>
              Information provided when using the services on the website, or
              that is generated during the use of those services (including the
              timing, frequency and pattern of service use) : Indefinitely
            </li>
            <li>
              Information relating to any subscriptions made (including name,
              address, telephone number, email address and sector sought): 2
              years or until consent is withdrawn
            </li>
            <li>
              Information posted to our website for publication on the internet:
              5 years after post
            </li>
            <li>
              Information contained in or relating to any communications sent
              through the website (including the communication content and meta
              data associated with the communication): 2 years following contact
            </li>
            <li>
              Any other personal information chosen to be sent: 2 years
              following contact
            </li>
          </ul>
          <br />
          {
            "Notwithstanding the other provisions of this Section, GK Telecom Limited will retain documents (including electronic documents) containing personal data:"
          }
          <ul className="pl-4">
            <li>
              to the extent that GK Telecom Limited is required to do so by law;
            </li>
            <li>
              if GK Telecom Limited believes that the documents may be relevant
              to any ongoing or prospective legal proceedings;
            </li>
            <li>
              and to establish, exercise or defend GK Telecom Limited’s legal
              rights (including providing information to others for the purposes
              of fraud prevention and reducing credit risk).
            </li>
            <li>
              if explicit consent is given by the data subject. Consent is
              requested at least every 2 years from candidates seeking contract
              roles and at least every 12 months for candidates seeking
              permanent employment.
            </li>
          </ul>
          {
            "Each day GK Telecom Limited will run a database backup copy of all electronic data contained on GK Telecom Limited data centre, except for the one financial package. All other databases are in the cloud, connected to a Tier 3 data centre. This backup will include all information relating to current users, as well as any information that remains due to any reason contained in this policy."
          }
        </>
      ),
    },
    {
      id: 6,
      header: "ELECTRONIC DOCUMENTS",
      text: (
        <>
          {
            <ol className="pl-4">
              <li>
                Electronic Mail: Not all email needs to be retained, depending
                on the subject matter.
              </li>
              <ol className="pl-5" type="a">
                <li>
                  All e-mail—from internal or external sources – is to be
                  deleted after 12 months. Staff will strive to keep all but an
                  insignificant minority of their e-mail related to business
                  issues.
                </li>
                <li>
                  GK Telecom Limited will archive e-mail for 90 days after the
                  staff has deleted it, after which time the e-mail will be
                  permanently deleted.
                </li>
                <li>
                  Staff will take care not to send confidential/proprietary
                  information held by GK Telecom Limited to outside sources
                </li>
                <li>
                  Any e-mail staff deems vital to the performance of their job
                  should be copied to the relevant client or candidate record in
                  GK Telecom Limited CRM system.
                </li>
              </ol>
              <li>
                Electronic Documents: including Office 365 and PDF files,
                retention also depends on the subject matter.
              </li>
            </ol>
          }
          <br />
          {
            "GK Telecom Limited does not automatically delete electronic files beyond the dates specified in this Policy. It is the responsibility of all staff to adhere to the guidelines specified in this policy."
          }{" "}
          <br />
          {
            "In certain case’s a document will be maintained in both paper and electronic form. In such cases the official document will be the electronic document."
          }
        </>
      ),
    },
    {
      id: 7,
      header: "INSURANCE RECORDS",
      text: (
        <>
          {"Record Type and then Retention Period"}
          <ol className="pl-4">
            <li>Certificates Issued to GK Telecom Limited Permanent</li>
            <li>
              Claims Files (including correspondence, medical records, etc.)
              Permanent
            </li>
            <li>Insurance Policies (including expired policies) Permanent</li>
          </ol>
        </>
      ),
    },
    {
      id: 8,
      header: "LEGAL FILES AND PAPERS",
      text: (
        <>
          {"Record Type and then Retention Period"}
          <ol className="pl-4">
            <li>
              Legal Memoranda and Opinions (including all subject matter files):
              7 years after close of matter
            </li>
            <li>
              Litigation Files: 1 year after expiration of appeals or time for
              filing appeals
            </li>
            <li>Court Orders: Permanent MISCELLANEOUS</li>
          </ol>
          {"Record Type and then Retention Period"} <br />
          {
            "Material of Historical Value (including pictures, publications):    Permanent"
          }
          <ol className="pl-4">
            <li>
              Policy and Procedures Manuals – Original: Current version with
              revision history
            </li>
            <li>Annual Reports: Permanent</li>
          </ol>
        </>
      ),
    },
    {
      id: 9,
      header: "PERSONNEL RECORDS",
      text: (
        <>
          {"Record Type and then Retention Period"}
          <ul className="pl-4">
            <li>
              Employee Personnel Records (including individual attendance
              records, application forms, job or status change records,
              performance evaluations, termination papers, withholding
              information, garnishments, test results, training and
              qualification records): 6 years after separation
            </li>
            <li>
              Employment Contracts – Individual: 6 years after separation ▪
              Employment Records Correspondence with Employment Agencies and
              Advertisements for Job Openings: 3 years from date of hiring
              decision
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 10,
      header: "TAX RECORDS",
      text: (
        <>
          {
            "General Principle: GK Telecom Limited must keep books of account or records as are sufficient to establish amount of gross income, deductions, credits, or other matters required to be shown in any such return."
          }
          <br />
          {
            "These documents and records shall be kept for as long as the contents thereof may become material in the administration of state, and local income, franchise, and property tax laws."
          }
          <br />
          {"Record Type and then Retention Period"}
          <ul className="pl-4">
            <li>
              Tax-Exemption Documents and Related Correspondence: Permanent
            </li>
            <li>Tax Bills, Receipts, Statements: 7 years</li>
            <li>Tax Returns: Permanent</li>
            <li>Sales/Use Tax Records: 7 years</li>
            <li>Annual Information Returns: Permanent</li>
          </ul>
        </>
      ),
    },
  ];

  const PrivacyPolicyThird = [
    {
      id: 1,
      header: "Consent",
      text: "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
    },
    {
      id: 2,
      header: "Information we collect",
      text: (
        <>
          {
            "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information."
          }{" "}
          <br />
          {
            "If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide."
          }{" "}
          <br />
          {
            "When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number."
          }
        </>
      ),
    },
    {
      id: 3,
      header: "How we use your information",
      text: (
        <>
          {"We use the information we collect in various ways, including to:"}{" "}
          <br />
          {
            <ul className="pl-4">
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>
          }
        </>
      ),
    },
    {
      id: 4,
      header: "Log Files",
      text: "gktelecom.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.",
    },
    {
      id: 5,
      header: "Cookies and Web Beacons",
      text: "Like any other website, gktelecom.com uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimise the users’ experience by customizing our web page content based on visitors’ browser type and/or other information. For more general information on cookies, please read the Cookies article on Generate Privacy Policy website.",
    },
    {
      id: 6,
      header: "Advertising Partners Privacy Policies",
      text: "You may consult this list to find the Privacy Policy for each of the advertising partners of gktelecom.com. Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on gktelecom.com, which are sent directly to users’ browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalise the advertising content that you see on websites that you visit. Note that gktelecom.com has no access to or control over these cookies that are used by third-party advertisers.",
    },
    {
      id: 7,
      header: "Third Party Privacy Policies",
      text: "gktelecom.com’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.",
    },
    {
      id: 8,
      header: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
      text: (
        <>
          {
            "Under the CCPA, among other rights, California consumers have the right to:"
          }
          <ol className="pl-4">
            <li>
              Request that a business that collects a consumer’s personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </li>
            <li>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </li>
            <li>
              Request that a business that sells a consumer’s personal data, not
              sell the consumer’s personal data.
            </li>
          </ol>
          {
            "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."
          }
        </>
      ),
    },
    {
      id: 9,
      header: "GDPR Data Protection Rights",
      text: (
        <>
          {
            "We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:"
          }
          <ol className="pl-4">
            <li>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </li>
            <li>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
          </ol>
          {
            "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."
          }
        </>
      ),
    },
    {
      id: 10,
      header: "Children’s Information",
      text: "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. gktelecom.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.",
    },
  ];
  return (
    <div style={{ position: "relative" }} className="PrivacyPolicy-section">
      <header>
        <Header />
      </header>
      <section className="PrivacyPolicy-container container mt-3">
        <div className="row m-0">
          <div className="col-12 p-0 mb-3">
            <h1 className="mb-3">Privacy Policy</h1>
            <p
              style={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                fontStyle: "italic",
              }}
            >
              GK Telecom Limited <br />
              Privacy & Data Retention Policy <br />
              Effective Date: 01-Sept-19
            </p>
          </div>
          <div className="container mb-3">
            <div className="row justify-content-center">
              <div className="col-12 mt-2 p-0">
                <h2>Data Retention Policy</h2>
                <div className="card">
                  <div className="card-body p-2">
                    {PrivacyPolicy.map((PrivacyPolicy, index) => {
                      return (
                        <AccordionPrivacyItem
                          key={index}
                          active={active}
                          handleToggle={handlePrivacyToggle}
                          PrivacyPolicy={PrivacyPolicy}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mb-3">
            <div className="row justify-content-center">
              <div className="col-12 mt-2 p-0">
                <h2>APPENDIX A RECORD RETENTION SCHEDULE</h2>
                <span
                  style={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}
                >
                  The Record Retention Schedule is organised as follows: <br />
                  SECTION TOPIC
                </span>
                <ol className="pl-4 text-white pt-3">
                  <li>Accounting and Finance</li>
                  <li>Contracts</li>
                  <li>Corporate Records</li>
                  <li>Correspondence and Internal Memoranda </li>
                  <li>Personal Information </li>
                  <li>Electronic Records</li>
                  <li>Insurance Records</li>
                  <li>Legal</li>
                  <li>Miscellaneous</li>
                  <li>Personnel Records </li>
                  <li>Tax Records</li>
                </ol>
                <div className="card">
                  <div className="card-body p-2">
                    {PrivacyPolicySectionTopic.map((PrivacyPolicy, index) => {
                      return (
                        <AccordionSelectionItem
                          key={index}
                          active={selectionActive}
                          handleToggle={handlePrivacySelectionToggle}
                          PrivacyPolicy={PrivacyPolicy}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 text-white">
            <div className="row justify-content-center">
              <div className="col-12 mt-2 p-0">
                <p>
                  At gktelecom.com, accessible from https://gktelecom.com/, one
                  of our main priorities is the privacy of our visitors. This
                  Privacy Policy document contains types of information that is
                  collected and recorded by gktelecom.com and how we use it.
                  <br />
                  If you have additional questions or require more information
                  about our Privacy Policy, do not hesitate to contact us.
                  <br />
                  This Privacy Policy applies only to our online activities and
                  is valid for visitors to our website with regards to the
                  information that they shared and/or collected in
                  gktelecom.com. This policy is not applicable to any
                  information collected offline or via channels other than this
                  website.
                </p>

                <div className="card">
                  <div className="card-body p-2">
                    {PrivacyPolicyThird.map((PrivacyPolicy, index) => {
                      return (
                        <AccordionThirdItem
                          key={index}
                          active={thirdActive}
                          handleToggle={handleThirdToggle}
                          PrivacyPolicy={PrivacyPolicy}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CtaBanner />
      <img
        className="ref-right-grad-3"
        src={refRightGrad3}
        alt="refRightGrad1"
      />
      <img className="leftBottomImg" src={leftBottomImg} alt="" />
      <ContactUsForm />
      <footer className="mt-3">
        <Footer />
      </footer>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default PrivacyPolicy;
