import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import googlePlay from "../../assets/img/googleplay.png";
import appStore from "../../assets/img/appstore.png";
import downloadRedirect from "../../assets/img/esim_download.png";
import CtaBanner from "../Common/CtaBanner";
import ContactUsForm from "../Common/ContactUsForm";
import { React_URL } from "../../Common/Constant";
import { MdContentCopy } from "react-icons/md";
import { RiShareBoxLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// How to install images
import faqHti1 from "../../assets/img/faq/hti_1.png";
import faqHti2 from "../../assets/img/faq/hti_2.png";
import faqHti3 from "../../assets/img/faq/hti_3.png";
import faqHti4 from "../../assets/img/faq/hti_4.png";
import faqHti5 from "../../assets/img/faq/hti_5.png";
import faqHti6 from "../../assets/img/faq/hti_6.png";

import faqHtiW1 from "../../assets/img/faq/hti_w_1.png";
import faqHtiW2 from "../../assets/img/faq/hti_w_2.png";
import faqHtiW3 from "../../assets/img/faq/hti_w_3.png";
import faqHtiW4 from "../../assets/img/faq/hti_w_4.png";
import faqHtiW5 from "../../assets/img/faq/hti_w_5.png";
import faqHtiW6 from "../../assets/img/faq/hti_w_6.png";

// How to Access data
import faqAd1 from "../../assets/img/faq/ad_1.png";
import faqAd2 from "../../assets/img/faq/ad_2.png";
import faqAd3 from "../../assets/img/faq/ad_3.png";
import faqAd4 from "../../assets/img/faq/ad_4.png";
import faqAd5 from "../../assets/img/faq/ad_5.png";
import faqAd6 from "../../assets/img/faq/ad_6.png";

import faqAdW1 from "../../assets/img/faq/ad_w_1.png";
import faqAdW2 from "../../assets/img/faq/ad_w_2.png";
import faqAdW3 from "../../assets/img/faq/ad_w_3.png";
import faqAdW4 from "../../assets/img/faq/ad_w_4.png";
import faqAdW5 from "../../assets/img/faq/ad_w_5.png";
import faqAdW6 from "../../assets/img/faq/ad_w_6.png";
import useScrollToTop from "../custom_hooks/useScrollToTop";

const howToInstallImages = [
  {
    0: faqHti1,
    1: faqHti2,
    2: faqHti3,
    3: faqHti4,
    4: faqHti5,
    5: faqHti6,
  },
  {
    0: faqHtiW1,
    1: faqHtiW2,
    2: faqHtiW3,
    3: faqHtiW4,
    4: faqHtiW5,
    5: faqHtiW6,
  },
];

const howToAccessDataImages = [
  {
    0: faqAd1,
    1: faqAd2,
    2: faqAd3,
    3: faqAd4,
    4: faqAd5,
    5: faqAd6,
  },
  {
    0: faqAdW1,
    1: faqAdW2,
    2: faqAdW3,
    3: faqAdW4,
    4: faqAdW5,
    5: faqAdW6,
  },
];

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  useScrollToTop()
  const [active, setActive] = useState(null);
  const history = useHistory();
  const [howToInstall, setHowToInstall] = useState(0);
  const [howToAccessData, setHowToAccessData] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoggidIn, setIsLoggidOn] = useState();
  const [hTIStatus, setHTIStatus] = useState(false);

  const esimButtonRef = useRef(null);

  useEffect(() => {
    let user = localStorage.getItem("token");
    setIsLoggidOn(user);
  }, [isLoggidIn, hTIStatus]);

  const handleCoverageClick = (event) => {
    if (
      esimButtonRef.current &&
      !esimButtonRef.current.contains(event.target)
    ) {
      setHTIStatus(!hTIStatus);
    } else {
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);
    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [hTIStatus]);

  const howToInstallContent = [
    {
      id: 1,
      content: (
        <>
          {"After purchasing the eSIM, Go to eSIM section"}
          {
            <span
              style={{ cursor: "pointer" }}
              className="ml-2 btn btn-sm esimRedirectButton"
              ref={esimButtonRef}
              onClick={() => {
                setHTIStatus(!hTIStatus);
                if (isLoggidIn != null) {
                } else {
                  toast((t) => (
                    <div className="w-100 d-flex flex-column justify-content-between align-items-center">
                      <span
                        style={{ fontSize: "32px" }}
                        className=" d-flex justify-content-start align-items-center"
                      >
                        ⚠️
                      </span>
                      <p className="m-0 text-center">
                        <b>You've been logged out</b>
                        <br />
                        Please log back in
                      </p>
                      <button
                        className="mt-3 w-50 btn btn-sm rounded-pill btn-outline-dark"
                        onClick={() => toast.dismiss(t.id)}
                      >
                        OK
                      </button>
                    </div>
                  ));
                }
              }}
            >
              {isLoggidIn ? (
                <a
                  className="my-esim-link"
                  target="_blank"
                  rel="noopener norefferer"
                  href={`${React_URL}myesim`}
                >
                  eSIM section
                </a>
              ) : (
                "eSIM section"
              )}
              <RiShareBoxLine className="ml-1 mb-1" />
            </span>
          }
        </>
      ),
    },
    {
      id: 1,
      content: "Tap on your preferred eSIM's Details button.",
    },
    {
      id: 2,
      content: "Choose your device type Android/IOS device.",
    },
    {
      id: 3,
      content: "Choose your device from the “Select your device” dropdown.",
    },
    {
      id: 4,
      content:
        "Choose your Installation method QR/Manual from the “Select your installation method” dropdown.",
    },
    {
      id: 5,
      content: "Follow to installation process.",
    },
  ];

  const AccessDataContent = [
    {
      id: 1,
      content: (
        <>
          {"After purchasing the eSIM, Go to eSIM section"}
          {
            <span
              style={{ cursor: "pointer" }}
              className="ml-2 btn btn-sm esimRedirectButton"
              ref={esimButtonRef}
              onClick={() => {
                setHTIStatus(!hTIStatus);
                if (isLoggidIn != null) {
                } else {
                  toast((t) => (
                    <div className="w-100 d-flex flex-column justify-content-between align-items-center">
                      <span
                        style={{ fontSize: "32px" }}
                        className=" d-flex justify-content-start align-items-center"
                      >
                        ⚠️
                      </span>
                      <p className="m-0 text-center">
                        <b>You've been logged out</b>
                        <br />
                        Please log back in
                      </p>
                      <button
                        className="mt-3 w-50 btn btn-sm rounded-pill btn-outline-dark"
                        onClick={() => toast.dismiss(t.id)}
                      >
                        OK
                      </button>
                    </div>
                  ));
                }
              }}
            >
              {isLoggidIn ? (
                <a
                  className="my-esim-link"
                  target="_blank"
                  rel="noopener norefferer"
                  href={`${React_URL}myesim`}
                >
                  eSIM section
                </a>
              ) : (
                "eSIM section"
              )}
              <RiShareBoxLine className="ml-1 mb-1" />
            </span>
          }
        </>
      ),
    },
    {
      id: 1,
      content: "Tap on your preferred eSIM's Details button.",
    },
    {
      id: 2,
      content: "Choose your device type Android/IOS device.",
    },
    {
      id: 3,
      content: "Choose your device from the “Select your device” dropdown.",
    },
    {
      id: 4,
      content:
        "Choose your Installation method QR/Manual from the “Select your installation method” dropdown.",
    },
    {
      id: 5,
      content: "Complete the Step1: Install eSIM to get “Access data”",
    },
  ];

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const howToInstallFunction = (status) => {
    if (status === "incr" && howToInstall < 5) {
      setHowToInstall(howToInstall + 1);
    } else if (status === "decr" && howToInstall > 0) {
      setHowToInstall(howToInstall - 1);
    } else {
    }
  };

  const howToAccessFunction = (status) => {
    if (status === "incr" && howToAccessData < 5) {
      setHowToAccessData(howToAccessData + 1);
    } else if (status === "decr" && howToAccessData > 0) {
      setHowToAccessData(howToAccessData - 1);
    } else {
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const faqs = [
    {
      id: 1,
      header: "What are the prerequisites for eSIM installation?",
      text: (
        <>
          <div>
            <p className="mb-2 font-weight-bold">
              To install an eSIM, you typically need the three things:
            </p>
            <ul className="pl-3">
              <li>Device Compatibility</li>
              <li>eSIM Activation Code</li>
              <li>Stable Internet Connection</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      id: 2,
      header: "What is the speed of internet connectivity with eSIMs?",
      text: `eSIMs offer support for 4G LTE and even 5G networks in specific regions. However, in remote areas, you may encounter reduced internet speeds, which can be attributed to limited network coverage rather than any issue with the eSIM itself.`,
    },
    {
      id: 3,
      header: "How can I know if my smartphone supports eSIM?",
      text: `Our GKT eSIM app comes equipped with intelligent detection features that swiftly determine the compatibility of your device. Simply download our app, and we will provide you with an instant assessment to confirm if your device is eSIM compatible or not.`,
    },
    {
      id: 4,
      header: "Does the eSIM package include voice and data plans?",
      text: `While certain packages offer a combination of voice and data plans, others are specifically tailored for data-only usage.`,
    },
    {
      id: 5,
      header: "What is an eSIM?",
      text: `An eSIM is an integrated SIM card within your device, eliminating the need for physical insertion and allowing for remote programming. Functionally, the eSIM operates in the same way as a traditional SIM card, with the primary distinction lying in the installation process and activation of the eSIM profile.`,
    },
    {
      id: 6,
      header:
        "What is the maximum number of eSIM profiles that can be installed or stored?",
      text: `The maximum number of eSIM profiles that can be installed or stored on a device depends on the device's specifications and capabilities. Some devices may support a single eSIM profile, while others can accommodate multiple profiles. It is best to consult the manufacturer's documentation or check the device settings to determine the specific limit for your device.`,
    },
    {
      id: 7,
      header: "How to install eSIM?",
      text: (
        <>
          <div className="row m-0 flex-column-reverse flex-lg-row">
            <div className="col-12 col-lg-6 p-0 d-flex flex-column justify-content-start align-items-start">
              <ol className="pl-0 pl-md-3 mb-0 mt-3 custom-faq-list">
                {howToInstallContent.map((step, index) => (
                  <li
                    key={index}
                    className={index === howToInstall ? "point-active" : ""}
                  >
                    {step.content}
                  </li>
                ))}
              </ol>
              <div className="w-100 d-flex justify-content-end align-items-center mt-3 mt-md-2">
                <button
                  disabled={howToInstall == 0 ? true : false}
                  onClick={() => howToInstallFunction("decr")}
                  className="btn btn-outline-light rounded-pill"
                >
                  PREVIOUS
                </button>
                <button
                  disabled={howToInstall == 5 ? true : false}
                  onClick={() => howToInstallFunction("incr")}
                  className="btn btn-outline-light rounded-pill ml-3"
                >
                  NEXT
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex points-wrapper-div">
              <div className="">
                <p className="m-0 points-wrapper d-lg-none d-block">
                  {howToInstall + 1}/6
                </p>
                <p style={{ width: "25px" }} className="mx-2 d-none d-lg-block">
                  {howToInstall + 1}/6
                </p>
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center pr-2">
                <img
                  className="how-to-install-img"
                  src={
                    howToInstallImages[windowWidth < 992 ? 0 : 1][howToInstall]
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 8,
      header: "How to Access Data?",
      text: (
        <>
          <div className="row m-0 flex-column-reverse flex-lg-row">
            <div className="col-12 col-lg-6 p-0 d-flex flex-column justify-content-start align-items-start">
              <ol className="pl-0 pl-md-3 mb-0 mt-3 custom-faq-list">
                {AccessDataContent.map((step, index) => (
                  <li
                    key={index}
                    className={index === howToAccessData ? "point-active" : ""}
                  >
                    {step.content}
                  </li>
                ))}
              </ol>
              <div className="w-100 d-flex justify-content-end align-items-center mt-3 mt-md-2">
                <button
                  disabled={howToAccessData == 0 ? true : false}
                  onClick={() => howToAccessFunction("decr")}
                  className="btn btn-outline-light rounded-pill"
                >
                  PREVIOUS
                </button>
                <button
                  disabled={howToAccessData == 5 ? true : false}
                  onClick={() => howToAccessFunction("incr")}
                  className="btn btn-outline-light rounded-pill ml-3"
                >
                  NEXT
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex points-wrapper-div">
              <div className="">
                <p className="m-0 points-wrapper d-lg-none d-block">
                  {howToAccessData + 1}/6
                </p>
                <p style={{ width: "25px" }} className="mx-2 d-none d-lg-block">
                  {howToAccessData + 1}/6
                </p>
              </div>
              <div className="w-100 d-flex justify-content-center align-items-center pr-2">
                <img
                  className="how-to-install-img"
                  src={
                    howToAccessDataImages[windowWidth < 992 ? 0 : 1][
                      howToAccessData
                    ]
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 9,
      header: "What does the fair use policy entail for unlimited plans?",
      text: `Unlimited data plan is provided with a daily allocation of 1GB / 2GB / 3GB / 5GB at high speed, after which the speed will be reduced to 1 Mbps. The plans are also categorized as Mini = 1GB / Standard = 2GB / Enhance = 3GB / Premium = 5GB per day.`,
    },
    {
      id: 10,
      header: "Unlimited Data Plan Usage Guidelines",
      text: (
        <>
          <p className="m-0 font-weight-bold">Unlimited Roaming Data</p>
          <p className="m-0">
            Our Unlimited Data Plans are intended for travelers and mobile
            businesspeople who need reliable access to their apps and
            communications while roaming. They are not intended for high
            frequency or intense data usage, automated or machine usage, or
            large file transfers.
          </p>
          <br />
          <p className="m-0 font-weight-bold">
            Examples of Regular Roaming Usage
          </p>
          <ul style={{ listStyleType: "none" }}>
            <li>✅ Streaming music or video a few hours a day - OK!</li>
            <li>
              ✅ Using social media apps like WhatsApp, Instagram, and TikTok -
              OK!
            </li>
            <li>
              ✅ Browsing the internet, email, and messaging throughout the day
              - OK!
            </li>
            <li>
              ✅ Using Mobile Hotspot for your laptop or tablet to join Zoom
              calls - OK!
            </li>
            <li>
              ✅ Connecting to your corporate VPN for regular business usage -
              OK!
            </li>
          </ul>

          <p className="m-0 font-weight-bold">
            Examples of Usage That May Be Limited
          </p>

          <ul style={{ listStyleType: "none" }}>
            <li>
              ❌ Downloading huge files from a server - Better to use Wi-Fi.
            </li>
            <li>
              ❌ Uploading/downloading an entire library of photos or music -
              Better to use Wi-Fi.
            </li>
            <li>
              ❌ Using the Mobile Hotspot as a router or proxy for multiple
              computers - Better to use Wi-Fi.
            </li>
            <li>
              ❌ Connecting to a TV and streaming video 12 hours a day - Better
              to use Wi-Fi.
            </li>
            <li>
              ❌ Using the connection for BitTorrent or P2P file sharing uploads
              - Better to use Wi-Fi.
            </li>
          </ul>
          <p className="m-0">
            <span className="font-weight-bold">
              Network Acceptable Use Policy (AUP)
            </span>
            <br />
            Please note that the above examples are guidelines only, and are not
            exhaustive. Our Acceptable Use Policy (AUP) lays out the standards
            of acceptable Internet usage while connected to our network, and
            prohibits activities such as sending SPAM, posting offensive or
            illegal content, and interfering with other computer networks.
          </p>
        </>
      ),
    },
  ];

   // google analytics

   useEffect(() => {
    document.title = "FAQ";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once


  return (
    <div style={{ position: "relative" }} className="faq-section">
      <header>
        <Header />
      </header>
      <section className="faq-container container mt-3">
        <div className="row m-0">
          <div className="col-12">
            <h1 className="mb-1">Learn How eSIM Works</h1>
          </div>
          <div className="container mb-3">
            <div className="row justify-content-center">
              <div className="col-12 mt-2 p-0">
                <div className="card">
                  <div className="card-body p-2">
                    {faqs.map((faq, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          active={active}
                          handleToggle={handleToggle}
                          faq={faq}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CtaBanner />
      <img
        className="ref-right-grad-3"
        src={refRightGrad3}
        alt="refRightGrad1"
      />
      <img className="leftBottomImg" src={leftBottomImg} alt="" />
      <ContactUsForm />
      <footer className="mt-3">
        <Footer />
      </footer>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Faq;
