import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FormFeedback } from "reactstrap";
import slideOneBgSm from "../../assets/img/carousel/advt_small_1.jpg";
import slideTwoBgSm from "../../assets/img/carousel/advt_small_2.jpg";
import slideThreeBgSm from "../../assets/img/carousel/advt_small_3.png";
import slideOneBgLg from "../../assets/kiosk/carousel/frame-1.jpg";
import slideTwoBgLg from "../../assets/kiosk/carousel/frame-2.jpg";
import slideThreeBgLg from "../../assets/kiosk/carousel/frame-3.jpg";
import signature from "../../assets/kiosk/eSIM_Signature.png";
import logo from "../../assets/img/gkt_final_logo.png";
import Loader from "../Alert_Popup/Loader";
import Carousel from "../Common/Carousel";
import KioskLoginService from "./Services/KioskLoginService";
import CustomModal from "./components/CustomModal";
import Header from "./components/Header";
import KioskBgElement from "./components/KioskBgElement";

const KioskLogin = () => {
  const successText = "";
  const errorText = "2px solid rgba(255,44,44,0.5)";
  const normalText = "1px solid rgba(255, 255, 255, 0.7)";
  const carouselData = [
    {
      id: 1,
      title: "Experience the World Without Limits! 🌐✈️",
      description:
        "GKT eSIM Roam-Free Data Plans are designed for celebrities like you, providing uninterrupted connectivity on your global adventures. Enjoy the freedom to stay connected in iconic destinations",
      largeBg: slideOneBgLg,
      smallBg: slideOneBgSm,
      alt: "1",
    },
    {
      id: 2,
      title: "Wherever Business Takes You! 🌍👔",
      description:
        "Stay Connected, Stay Productive! GKT eSIM offers hassle-free global connectivity for your business travels. Roam-Free Data Plans, Reliable Coverage, and Instant Activation. Elevate your business mobility!",
      largeBg: slideTwoBgLg,
      smallBg: slideTwoBgSm,
      alt: "2",
    },
    {
      id: 3,
      title: "Earn while you learn",
      description: "Explore eSIM Potential with AI-Enhanced Sales",
      largeBg: slideThreeBgLg,
      smallBg: slideThreeBgSm,
      alt: "3",
    },
  ];

  //hooks
  const history = useHistory();

  //use states
  const formInitialValues = { kioskId: "", mpin: "" };
  const [formValues, setFormValues] = useState(formInitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState({ heading: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  //effects
  useEffect(() => {
    const kioskSessionExpired = localStorage.getItem("kioskSessionExpired") ? localStorage.getItem("kioskSessionExpired") : false;
    if (kioskSessionExpired) {
      localStorage.removeItem("kioskSessionExpired");
      setMessage({ heading: "Login Session Expired", message: "Login session expired due to force logout or another device login." });
    }
  }, [])

  useEffect(() => {
    if (Object.keys(formErrors).length == 0 && isSubmit === true) {
      //submit the page
      kioskLoginApi();
    }
  }, [formErrors])

  //functions
  const kioskLoginApi = () => {
    setIsLoading(true);
    KioskLoginService.login(formValues.kioskId, formValues.mpin)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          // console.log("in success");
          //setPlan(response.data);
          localStorage.setItem("kioskLoginDetails", JSON.stringify(response.data));
          // console.log("result:", response.data);
          history.push(`/kiosk`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.message === "Network Error") {
          setMessage({ heading: "Network Error", message: "Network error occurred. Please try again." });
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              if(error.response.data.failedReason === "Kiosk Account Locked") {
                setMessage({ heading: "Login Failed", message: "Kiosk device is locked. Please reset the failed attempts and try with correct MPIN." });
              } else if(error.response.data.failedReason ==="Kiosk is disabled") {
                setMessage({ heading: "Login Failed", message: "Kiosk device is disabled. Please activate the device and try again." });
              } else if(error.response.data.failedReason ==="Incorrect MPIN") {
                setMessage({ heading: "Login Failed", message: "Entered MPIN is incorrect. Please enter correct MPIN." });
              } else {
                setMessage({ heading: "Login Failed", message: error.response.data.failedReason });
              }
              break;
            case 406:
              //setPlansFound(false);
              setMessage({ heading: "No kiosk device found", message: "No kiosk device found. Please enter the correct kiosk device ID." });
              break;
            case 500:
              setMessage({ heading: "Error occurred", message: "Something went wrong. Please try again later." });
              break;
            default:
              setMessage({ heading: "Unknown Error", message: "Something went wrong. Please try again later." });
              break;
          }
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }

  const handleMpinChange = (val) => {
    setFormValues({ ...formValues, mpin: val });
  }

  const handleMpinComplete = (val) => {
    setFormValues({ ...formValues, mpin: val });
  }

  const closeMessagePopup = () => {
    setMessage({ heading: "", message: "" });
  };

  function validate(values) {
    const error = {};
    if (!values.kioskId) {
      error.kioskId = "Kiosk Code is required";
    }
    if (!values.mpin) {
      error.mpin = "MPIN is required";
    } else if (values.mpin.length < 6) {
      error.mpin = "MPIN is required";
    }
    return error;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //validate form
    setFormErrors(validate(formValues));
    // console.log("form errors:", formErrors);
    setIsSubmit(true);

  }

  return (
    <div className="kiosk-app d-flex flex-column">
      <div>
        <Header isLoginPage={true}/>
        <section className="kiosk-container px-4">
          <div className="w-100 mx-auto mt-4">
            <h1 className="kiosk-title">Welcome Back!</h1>
            <h2 className="kiosk-sub-title mb-3">
              Please log in to access the kiosk
            </h2>
          </div>

          <div className="login-form">
            <form action="">
              <fieldset className="mb-4">
                <label htmlFor="" className="mb-3 kiosk-sub-title">
                  Kiosk Code
                </label>
                <input name="kioskId" placeholder="Please enter Kiosk Code" type="text"
                  value={formValues.kioskId} onChange={handleChange} />
                <FormFeedback
                  style={{
                    display:
                      formErrors.kioskId === "" ||
                        formErrors.kioskId === undefined
                        ? "none"
                        : "block",
                  }}
                  className="kiosk-error"
                >
                  {formErrors.kioskId}
                </FormFeedback>
              </fieldset>

              <fieldset className="mb-4">
                <label htmlFor="" className="mb-3 kiosk-sub-title">
                  MPIN
                </label>
                <PinInput
                  length={6}
                  initialValue=""
                  // secret
                  // secretDelay={200}
                  onChange={(value) => handleMpinChange(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ width: "auto" }}
                  inputStyle={{ border: normalText }}
                  inputFocusStyle={{ border: "2px solid #fff" }}
                  onComplete={(value) => handleMpinComplete(value)}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                <FormFeedback
                  style={{
                    display:
                      formErrors.mpin === "" ||
                        formErrors.mpin === undefined
                        ? "none"
                        : "block",
                  }}
                  className="kiosk-error"
                >
                  {formErrors.mpin}
                </FormFeedback>
              </fieldset>

              <button type="submit" onClick={handleSubmit} className="notranslate btn-custom-gradient shadow-sm kiosk-grad-btn btn mb-3 btn-block">
                Log In
              </button>
            </form>
          </div>
        </section>
        <section className="kiosk-container px-4 mt-5">
          <div>
            <div className="card carousel-card m-0 overflow-hidden position-relative">
              <Carousel>
                {carouselData.map((item) => (
                  <>
                    <img
                      className="carousel-image"
                      src={item.largeBg}
                      alt={item.alt}
                    />
                  </>
                ))}
              </Carousel>
            </div>
          </div>
        </section>
        <div className="kiosk-container px-4 mt-5">
          <img
            style={{ width: "100%", borderRadius: 12 }}
            src={signature}
            alt=""
          />
        </div>
      </div>

      <footer className="mt-auto">
        <div className="row copyright-section mt-0">
          <div className="break-line"></div>
          <div className=" col-lg-12 col-12 d-flex justify-content-center align-items-center">
            <img className="copyright-logo mr-2" src={logo} alt="" />
            <p className="copyright">
              Copyright 2022 © Gk Telecom | All rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      <KioskBgElement />
      {isLoading === true && <Loader />}
      <CustomModal
        isOpen={message && message.heading.length > 0 ? true : false}
        onClose={closeMessagePopup}
        backButton={false}
        width={768}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          <>
            <div style={{ textAlign: "center" }}>
              <h3 className="kiosk-title kiosk-error mb-3">
                {message.heading}
              </h3>
            </div>

            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              <p className="kiosk-sub-title">
                {message.message}
              </p>
            </div>
          </>
        </div>
      </CustomModal>
    </div>
  );
};

export default KioskLogin;
