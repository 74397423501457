import axios from 'axios';
import { API_URL } from '../Common/Constant';
import React, { Component } from 'react';
import { jwtDecode } from 'jwt-decode';

class OrderService {
    
    
    order(json) {
        
        return axios.post(`${API_URL}/webappapi/order`,json,{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}});
    }
    orderAfterPayment(orderId){
        return axios.get(`${API_URL}/webappapi/orderAfterPayment`,{headers:{ "orderId":orderId}})
    }
    
}



export default new OrderService()
