import React, { useEffect, useState } from "react";
import { FaPaperPlane } from "react-icons/fa6";
import { IoMdStopwatch } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import UserVarificationService from "../../../Services/UserVarificationService";
import "../../../scss/kiosk.scss";
import MicroLoader from "../../Alert_Popup/MicroLoader";
import AddKioskUserService from "../Services/AddKioskUserService";
import KioskSTWService from "../Services/KioskSTWService";
import CustomModal from "../components/CustomModal";
import Header from "../components/Header";
import KioskBgElement from "../components/KioskBgElement";
import STWModal from "./STWModal";
import WheelComponent from "./WheelComponent";
import clPrize from "./assets/prizeImages/clPrize.png";
import eePrize from "./assets/prizeImages/ee.png";
import giffGaffPrize from "./assets/prizeImages/giffgaff.png";
import gktPrize from "./assets/prizeImages/gktPrize.png";
import happy from "./assets/prizeImages/happyRobot.png";
import lebaraPrize from "./assets/prizeImages/lebara.png";
import o2Prize from "./assets/prizeImages/o2.png";
import sad from "./assets/prizeImages/sadRobot.png";
import smartyPrize from "./assets/prizeImages/smarty.png";
import threePrize from "./assets/prizeImages/threePrize.png";
import vodafonePrize from "./assets/prizeImages/vodafonePrize.png";
import voxiPrize from "./assets/prizeImages/voxi.png";
import jaguarLogo from "./assets/prizeImages/jaguar_logo.png";
import stars from "./assets/stars.png";
import stars1 from "./assets/stars_1.png";
import jaguarGolfImg from "./assets/prizeImages/jaguar_logo.png";

const STWMain = (props) => {
  const history = useHistory();
  const [videoSource, setVideoSource] = useState({
    videoSourceType: 0,
    videoSourceUrl: "",
    youtubeVideoId: "",
  });
  const [showReward, setShowReward] = useState(false);
  const [prize, setPrize] = useState("");
  const [prizeImg, setPrizeImg] = useState("");
  const [prizePos, setPrizePos] = useState(0);
  const [prizeId, setPrizeId] = useState();
  const [prizeName, setPrizeName] = useState("");
  const [prizeTitle, setPrizeTitle] = useState("");
  const [prizeSubTitleOne, setPrizeSubTitleOne] = useState("");
  const [prizeSubTitleTwo, setPrizeSubTitleTwo] = useState("");
  const [prizeBody, setPrizeBody] = useState("");
  const [confetti, setConfetti] = useState(false);
  const [VName, setVName] = useState(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [user, setUser] = useState();
  const [intervalId, setIntervalId] = useState(null);
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [showVerificationError, setShowVerificationError] = useState(false);

  function getYoutubeVideoId(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  useEffect(() => {
    showReward
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  }, [showReward]);

  const toggleSTW = () => {
    setOpen(true);
    setCount(0);
  };

  const addKioskUserApi = () => {
    if (!email) {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
      // If email is valid, add the user
      const emailId = email;
      // console.log("emailId=>", emailId);
      setCount(1);
      document.getElementById("email").disabled = true;
      const kioskDetails = localStorage.getItem("kioskLoginDetails")
        ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
        : undefined;
      AddKioskUserService.addKioskUser(
        emailId,
        kioskDetails.kioskId,
        kioskDetails.lastLoginDate
      )
        .then((response) => {
          if (response.status === 200) {
            setUser(response.data);
            localStorage.setItem("userId", response.data.id);
            // console.log("user=> ", response.data);
            //code for new user verification
            /*
            if (response.data.isVerified == "0") {
              setCount(2);
              document.getElementById("resend").disabled = true;
              document.getElementById("resend").style.pointerEvents = "none";

              setSeconds(30);
              let totalTime = 0;
              const interval = setInterval(() => {
                // Call the API every 10 seconds
                const kioskDetails = localStorage.getItem("kioskLoginDetails")
                  ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
                  : undefined;
                AddKioskUserService.getVerificationStatus(
                  response.data.id,
                  kioskDetails.kioskId,
                  kioskDetails.lastLoginDate
                )
                  .then((response1) => {
                    if (response1.status === 200) {
                      if (response1.data === 0) {
                        // Continue calling every 10 seconds for 30 seconds
                        totalTime += 10;
                        if (totalTime >= 30) {
                          clearInterval(interval);
                          if (response1.data === 0) {
                            // console.log(response1.data);
                            setCount(3);
                            document.getElementById(
                              "resend"
                            ).style.pointerEvents = "auto";
                          }
                        }
                      } else if (response1.data === 1) {
                        // Redirect to checkout page
                        clearInterval(interval);
                        kisoskSTWPrizeAvailable(response.data.id);
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.message === "Network Error") {
                      setMessage(
                        "Something went wrong, Please try again later"
                      );
                    } else {
                      // Handle other errors
                      switch (error.response ? error.response.status : "") {
                        case 401:
                          // Handle 401 error
                          localStorage.clear();
                          localStorage.setItem("kioskSessionExpired", true);
                          history.push("/kiosk/login");
                          break;
                        case 403:
                          // Handle 403 error
                          break;
                        // Handle other error cases
                        default:
                          setMessage(
                            "Something went wrong, Please try again later"
                          );
                          break;
                      }
                    }
                  });
              }, 10000); // Repeat every 10 seconds

              setIntervalId(interval);
            } else {
              */
            kisoskSTWPrizeAvailable(response.data.id);
            //}
          }
        })
        .catch((error) => {
          // Handle error if user addition fails
          if (error.message === "Network Error") {
            setMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                // setErrorCode(401)
                localStorage.clear();
                localStorage.setItem("kioskSessionExpired", true);
                history.push("/kiosk/login");
                break;
              case 403:
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                setMessage(error.response.data.failedReason);
                break;
              case 404:
              case 406:
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setMessage(error.response.data.failedReason);
                break;
              default:
                setMessage("Unknown Error");
                break;
            }
          }
        });
    }
  };
  const [StartWheel, setStartWheel] = useState(false);
  const kisoskSTWPrizeAvailable = (userId) => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
    KioskSTWService.kisoskSTWPrizeAvailable(
      userId,
      kioskDetails.kioskId,
      kioskDetails.lastLoginDate
    )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.prizeOrder >= 0) {
            localStorage.setItem("stopPos", response.data.prizeOrder);
            localStorage.setItem("prize", JSON.stringify(response.data));
            if (response.data.prizeOrder) {
              // console.log("here reached=>",response.data)
              handleClose();
              setStartWheel(true);
              spinTheWheelApi();
            }

            // history.push("/kiosk/spinTheWheel");
          }
          if (response.data.prizeOrder == -1) {
            setShowReward(true);
            setPrize(null);
            setConfetti(false);
            setPrizeId(-1);
            setPrizeName(null);
            setPrizeTitle(null);
            setPrizeImg(sad);
            handleClose();
          }
          if (response.data.prizeOrder == -2) {
            setShowReward(true);
            setPrize(null);
            setConfetti(false);
            setPrizeId(-2);
            setPrizeName(null);
            setPrizeTitle(null);
            setPrizeImg(happy);
            handleClose();
          }
        }
        if (response.status == 204) {
          setShowReward(true);
          setPrize(null);
          setConfetti(false);
          setPrizeId(-3);
          setPrizeName(null);
          setPrizeTitle(null);
          setPrizeImg(sad);
          handleClose();
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setShowReward(true);
          setPrize(null);
          setConfetti(false);
          setPrizeId(-3);
          setPrizeName(null);
          setPrizeTitle(null);
          setPrizeImg(sad);
          handleClose();
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
            case 500:
              setShowReward(true);
              setPrize(null);
              setConfetti(false);
              setPrizeId(-3);
              setPrizeName(null);
              setPrizeTitle(null);
              setPrizeImg(sad);
              handleClose();
              break;
            default:
              setShowReward(true);
              setPrize(null);
              setConfetti(false);
              setPrizeId(-3);
              setPrizeName(null);
              setPrizeTitle(null);
              setPrizeImg(sad);
              handleClose();
              break;
          }
        }
      });
  };
  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("kioskSelectedPlanToBuy");
    setEmailError("");
    setEmail();
    setSeconds(30); // Reset the countdown timer to 30 seconds
    clearInterval(intervalId); // Clear the interval for verification API calls
    setCount(0); // Reset the count to 0
  };

  const backClick = () => {
    // console.log("here");
    setCount(0);
    clearInterval(intervalId);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    // Validate email format
    if (!value) {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const [resendClicked, setResendClicked] = useState(false);
  const resendEmailClick = () => {
    setResendClicked(true);
    // console.log("here1");
    document.getElementById("proceed").disabled = true;
    document.getElementById("resend").style.pointerEvents = "none";
    UserVarificationService.resendVerificationLink(email).then((response) => {
      if (response.status == 200) {
        // if (response.data.isVerified == "0") {
        setCount(2);
        setSeconds(30);
        let totalTime = 0;
        const interval = setInterval(() => {
          // Call the API every 10 seconds
          const kioskDetails = localStorage.getItem("kioskLoginDetails")
            ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
            : undefined;
          AddKioskUserService.getVerificationStatus(
            localStorage.getItem("userId"),
            kioskDetails.kioskId,
            kioskDetails.lastLoginDate
          )
            .then((response1) => {
              if (response1.status === 200) {
                if (response1.data === 0) {
                  // Continue calling every 10 seconds for 30 seconds
                  totalTime += 10;
                  if (totalTime >= 30) {
                    clearInterval(interval);
                    if (response1.data === 0) {
                      // console.log(response1.data);
                      setCount(3);
                      document.getElementById("proceed").disabled = false;
                      document.getElementById("resend").style.pointerEvents =
                        "auto";
                      setResendClicked(false);
                    }
                  }
                } else if (response1.data === 1) {
                  // Redirect to checkout page
                  clearInterval(interval);
                  kisoskSTWPrizeAvailable(response.data.id);
                }
              }
            })
            .catch((error) => {
              if (error.message === "Network Error") {
                setMessage("Something went wrong, Please try again later");
              } else {
                // Handle other errors
                switch (error.response ? error.response.status : "") {
                  case 401:
                    // Handle 401 error
                    localStorage.clear();
                    localStorage.setItem("kioskSessionExpired", true);
                    history.push("/kiosk/login");
                    break;
                  case 403:
                    // Handle 403 error
                    break;
                  // Handle other error cases
                  default:
                    setMessage("Something went wrong, Please try again later");
                    break;
                }
              }
            });
        }, 10000); // Repeat every 10 seconds

        setIntervalId(interval);
        // }
      }
      // console.log("status", response.status);
    });
  };

  const proceedClick = () => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
    AddKioskUserService.getVerificationStatus(
      user.id,
      kioskDetails.kioskId,
      kioskDetails.lastLoginDate
    )
      .then((response1) => {
        if (response1.status === 200) {
          if (response1.data === 0) {
            setShowVerificationError(true);
          } else if (response1.data === 1) {
            // Redirect to checkout page
            kisoskSTWPrizeAvailable(localStorage.getItem("userId"));
          }
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Something went wrong, Please try again later");
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              // Handle 401 error
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
              // Handle 403 error
              break;
            // Handle other error cases
            default:
              setMessage("Something went wrong, Please try again later");
              break;
          }
        }
      });
  };

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  useEffect(() => {
    if (count === 2) {
      setSeconds(30); // Reset the countdown timer to 30 seconds when count becomes 2
    }
  }, [count]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  const spinTheWheelApi = () => {
    const prize1 = localStorage.getItem("prize");
    const parsedPrize = JSON.parse(prize1);
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
      // console.log("inside calling play api---------------------");
      
    KioskSTWService.kisoskSTWPlay(
      localStorage.getItem("userId"),
      parsedPrize,
      kioskDetails.kioskId,
      kioskDetails.lastLoginDate
    )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.prizeOrder >= 0) {
            setPrizePos(response.data.prizeOrder);
            setPrizeId(response.data.kioskPrizeId);
            setPrizeName(response.data.kioskPrizeDesc);
            setPrizeTitle(response.data.kioskPrizeTitle);
            setPrizeSubTitleOne(response.data.kioskPrizeSubTitleOne);
            setPrizeSubTitleTwo(response.data.kioskPrizeSubTitleTwo);
            setPrizeBody(response.data.kioskPrizeBody);
            setConfetti(true);
            if (
              response.data.kioskPrizeId == 1 ||
              response.data.kioskPrizeId == 5 ||
              response.data.kioskPrizeId == 9
            ) {
              setPrize("network3_1");
              setPrizeImg(threePrize);
            } else if (
              response.data.kioskPrizeId == 2 ||
              response.data.kioskPrizeId == 6 ||
              response.data.kioskPrizeId == 10
            ) {
              setPrize("clearLifestyle_3");
              setPrizeImg(clPrize);
            } else if (
              response.data.kioskPrizeId == 3 ||
              response.data.kioskPrizeId == 7 ||
              response.data.kioskPrizeId == 11
            ) {
              setPrize("vodafone_3");
              setPrizeImg(vodafonePrize);
            } else if (
              response.data.kioskPrizeId == 4 ||
              response.data.kioskPrizeId == 8 ||
              response.data.kioskPrizeId == 12
            ) {
              setPrize("gkt_3");
              setPrizeImg(gktPrize);
              if (
                response.data.kioskPrizeId == 8 ||
                response.data.kioskPrizeId == 12
              ) {
                setVName(response.data.voucher.voucherApplyedMessage);
              }
            } else if (response.data.kioskPrizeId == 25) {
              setPrize("gkt");
              setPrizeImg(jaguarLogo);
              setVName(response.data.voucher.voucherApplyedMessage);
            } else if (
              response.data.kioskPrizeId == 13 ||
              response.data.kioskPrizeId == 22
            ) {
              setPrize("vodafone_1");
              setPrizeImg(jaguarLogo);
            } else if (response.data.kioskPrizeId == 14) {
              setPrize("smarty");
              setPrizeImg(jaguarLogo);
            } else if (response.data.kioskPrizeId == 15) {
              setPrize("ee");
              setPrizeImg(jaguarLogo);
            } else if (
              response.data.kioskPrizeId == 16 ||
              response.data.kioskPrizeId == 20
            ) {
              setPrize("network3_1");
              setPrizeImg(jaguarLogo);
            } else if (response.data.kioskPrizeId == 17) {
              setPrize("voxi");
              setPrizeImg(jaguarLogo);
            } else if (
              response.data.kioskPrizeId == 18 ||
              response.data.kioskPrizeId == 23
            ) {
              setPrize("o2_1");
              setPrizeImg(jaguarLogo);
            } else if (response.data.kioskPrizeId == 19) {
              setPrize("lebara");
              setPrizeImg(jaguarLogo);
            } else if (
              response.data.kioskPrizeId == 21 ||
              response.data.kioskPrizeId == 24
            ) {
              setPrize("giffGaff_1");
              setPrizeImg(jaguarLogo);
            }
            else if (
              response.data.kioskPrizeId == 26 ||
              response.data.kioskPrizeId == 28 ||
              response.data.kioskPrizeId == 30 ||
              response.data.kioskPrizeId == 32 ||
              response.data.kioskPrizeId == 34 ||

              response.data.kioskPrizeId == 36 ||
              response.data.kioskPrizeId == 38 ||
              response.data.kioskPrizeId == 40 ||
              response.data.kioskPrizeId == 42 ||
              response.data.kioskPrizeId == 44 ||

              response.data.kioskPrizeId == 46 ||
              response.data.kioskPrizeId == 48 ||
              response.data.kioskPrizeId == 50 ||
              response.data.kioskPrizeId == 52 ||
              response.data.kioskPrizeId == 54 
            ) {
              // console.log("here came 1")
              setConfetti(false);
              // setPrize("gkt_3");
              setPrizeImg(sad);
            }
            else if (
              response.data.kioskPrizeId == 27 ||
              response.data.kioskPrizeId == 29 ||
              response.data.kioskPrizeId == 31 ||
              response.data.kioskPrizeId == 33 ||
              response.data.kioskPrizeId == 35 )
              {
                // console.log("here came 2")
                setPrizeImg(voxiPrize);
              }
              else if (
                response.data.kioskPrizeId == 37 ||
                response.data.kioskPrizeId == 39 ||
                response.data.kioskPrizeId == 41 ||
                response.data.kioskPrizeId == 43 ||
                response.data.kioskPrizeId == 45 )
                {
                  // console.log("here came 3")
                  setPrizeImg(smartyPrize);
                }
                else if (
                  response.data.kioskPrizeId == 47 ||
                  response.data.kioskPrizeId == 49 ||
                  response.data.kioskPrizeId == 51 ||
                  response.data.kioskPrizeId == 53 ||
                  response.data.kioskPrizeId == 55 )
                  {
                    // console.log("here came 4")
                    setPrizeImg(giffGaffPrize);
                  }
         
          }
          // console.log("here came 0",response.data)
          if (response.data.prizeOrder == -1) {
            setShowReward(true);
            setPrize(null);
            setConfetti(false);
            setPrizeId(-1);
            setPrizeName(null);
            setPrizeTitle(null);
            setPrizeImg(sad);
          }
          if (response.data.prizeOrder == -2) {
            setShowReward(true);
            setPrize(null);
            setConfetti(false);
            setPrizeId(-2);
            setPrizeName(null);
            setPrizeTitle(null);
            setPrizeImg(happy);
          }
        }
        if (response.status == 204) {
          setShowReward(true);
          setPrize(null);
          setConfetti(false);
          setPrizeId(-3);
          setPrizeName(null);
          setPrizeTitle(null);
          setPrizeImg(sad);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setShowReward(true);
          setPrize(null);
          setConfetti(false);
          setPrizeId(-3);
          setPrizeName(null);
          setPrizeTitle(null);
          setPrizeImg(sad);
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
            case 500:
            case 204:
              setShowReward(true);
              setPrize(null);
              setConfetti(false);
              setPrizeId(-3);
              setPrizeName(null);
              setPrizeTitle(null);
              setPrizeImg(sad);
              break;
            default:
              setShowReward(true);
              setPrize(null);
              setConfetti(false);
              setPrizeId(-3);
              setPrizeName(null);
              setPrizeTitle(null);
              setPrizeImg(sad);
              break;
          }
        }
      });
  };

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
    if (!kioskDetails || kioskDetails.kioskId <= 0) {
      //not logged in
      history.push("/kiosk/login");
      return;
    }

    var youtubeVideoId = "";
    if (kioskDetails.otherPageVideoLinkUrl) {
      youtubeVideoId = getYoutubeVideoId(kioskDetails.otherPageVideoLinkUrl);
    }

    setVideoSource({
      videoSourceType: kioskDetails.otherPageVideoSource.videoSourceId,
      videoSourceUrl: kioskDetails.otherPageVideoLinkUrl,
      youtubeVideoId: youtubeVideoId,
    });

    const startButton = document.querySelector(".start-btn");
    if (startButton) {
      startButton.addEventListener("click", toggleSTW);
    }
    return () => {
      // Clean up the event listener when the component unmounts
      if (startButton) {
        startButton.removeEventListener("click", toggleSTW);
      }
    };
  }, []);

  return (
    <div className="kiosk-app">
      {showReward ? (
        <>
          {/* if player wins prize keep confetti true else keep false */}
          {/* to show happy or sad keep start_pos as null in <WheelComponent /> and confetti false*/}
          {/* if happy or sad showed the keep has_reset: false and restrict this function in <STWModal /> :  onClick={() => {
              setShowReward(false);
              const $ = (s) => document.querySelector(s);
              $(".reset-btn").click();
            }}
            */}
          <STWModal
            confetti={confetti}
            prize={prize}
            prizeId={prizeId}
            prizeName={prizeName}
            prizeTitle={prizeTitle}
            prizeSubTitleOne={prizeSubTitleOne}
            prizeSubTitleTwo={prizeSubTitleTwo}
            prizeBody={prizeBody}
            prizeImg={prizeImg}
            showReward={showReward}
            setShowReward={setShowReward}
            voucherName={VName}
          />
        </>
      ) : null}
      {/* modal for jaguar prize */}
      {/* <STWModal
        confetti={true}
        prize={jaguarGolfImg}
        prizeId={16}
        prizeName={prizeName}
        prizeImg={jaguarGolfImg}
        showReward={showReward}
        setShowReward={setShowReward}
        voucherName={VName}
        jaguarGolf={true}
      /> */}
      <Header />
      <section className="kiosk-container px-4">
        <div className="spin-the-wheel mb-3">
          <nav className="p-4">
            <div></div>
            <div className="stw-heading">
              <img src={stars} alt="" />
              <img src={stars1} alt="" />
              <h1 className="m-0">Spin To Win!</h1>
            </div>
            <div className="d-flex justify-content-end align-items-center stw-close-btn">
              {/* <button className="stw-close-btn btn btn-outline-danger rounded-pill">
              <p className="m-0">Close</p>
              <IoClose />
             </button> */}
            </div>
          </nav>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "0",
              paddingBottom: "0",
              position: "relative",
            }}
          >
            <WheelComponent
              showReward={showReward}
              setShowReward={setShowReward}
              stopPos={prizePos}
              start={StartWheel}
              reset={showReward}
              campaign={localStorage.getItem("cId")}
              // prize={prize}
              // setPrize={setPrize}
              // prizeImg={prizeImg}
              // setPrizeImg={setPrizeImg}
            />
          </div>
          <div className="w-100 pb-4 pt-3 d-flex justify-content-center align-items-center stw-close-btn">
            <button
              className="notranslate my-2 py-2 px-5 d-flex justify-content-center align-items-center btn btn-close rounded-pill px-3 "
              onClick={() => {
                history.push("/kiosk");
              }}
            >
              {/* <IoClose className="mr-2" /> */}
              <font>Close</font>
            </button>
          </div>
        </div>
        <div className="video-container position-relative m-auto">
          {videoSource.videoSourceType === 2 &&
          videoSource.videoSourceUrl.length > 0 ? (
            <video
              style={{ width: "100%", height: "100%", borderRadius: 12 }}
              autoPlay
              loop
              muted
            >
              <source src={videoSource.videoSourceUrl} type="video/mp4" />
            </video>
          ) : videoSource.videoSourceType === 1 &&
            videoSource.videoSourceUrl.length > 0 ? (
            <div className="container_">
              <iframe
                className="responsive-iframe"
                src={
                  videoSource.videoSourceUrl.replace("watch?v=", "embed/") +
                  "?loop=1&controls=0&autoplay=1&mute=1&playlist="+videoSource.youtubeVideoId
                }
                frameborder="0"
              ></iframe>
            </div>
          ) : (
            <div className="container_">
              <iframe
                className="responsive-iframe"
                src={
                  "https://www.youtube.com/embed/J6hwe7HBTYA?loop=1&controls=0&autoplay=1&mute=1&playlist=J6hwe7HBTYA"
                }
                frameborder="0"
              ></iframe>
            </div>
          )}
        </div>
      </section>
      <KioskBgElement />
      <CustomModal
        isOpen={open}
        onClose={handleClose}
        backButton={count > 1 ? true : false}
        onBackClick={backClick}
        width={"normal"}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          {count == 0 || count == 1 ? (
            <>
              <div style={{ textAlign: "start" }}>
                <h3 className="kiosk-sub-title mb-3">
                  To continue please enter your email id
                </h3>
              </div>
              <div className="input-section my-2">
                <input
                  type="text"
                  id="email"
                  placeholder="Please enter your email id"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <FormFeedback
                style={{
                  display:
                    emailError === "" || emailError === undefined
                      ? "none"
                      : "block",
                }}
                className="error"
              >
                {emailError}
              </FormFeedback>

              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  onClick={addKioskUserApi}
                  style={{ height: "60px" }}
                  className="notranslate btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  {count == 0 ? (
                    "Continue"
                  ) : count == 1 ? (
                    <MicroLoader />
                  ) : null}
                </button>
              </div>
            </>
          ) : count == 2 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span
                    className="font-weight-bold text-white"
                    onClick={resendEmailClick}
                    id="resend"
                  >
                    Resend email
                  </span>
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div className="kiosk-timer">
                  <IoMdStopwatch />
                  <h2>{formattedTime}</h2>
                </div>
                <span className="info">
                  *Verification status checking is in progress
                </span>
              </div>
            </>
          ) : count == 3 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span
                    className="font-weight-bold text-white"
                    onClick={resendEmailClick}
                    id="resend"
                  >
                    Resend email
                  </span>
                </p>
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  id="proceed"
                  onClick={proceedClick}
                  style={{ height: "60px" }}
                  className="notranslate btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  {!resendClicked ? (
                    "Proceed"
                  ) : resendClicked ? (
                    <MicroLoader />
                  ) : null}
                </button>
                {showVerificationError === true && (
                  <span className="error mt-3">
                    *Verification hasn't been completed from your end.
                  </span>
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default STWMain;
