import axios from 'axios';
import { API_URL } from '../../../Common/Constant';

class KioskLoginService{
    login(kioskId, mpin) {
        return axios.get(`${API_URL}/kiosk/kioskLogin`, 
        { headers:{
            'kioskId': kioskId,
            'mpin': mpin
        }});
    }
}

export default new KioskLoginService()