import React from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";

const BackButton = ({handleClick}) => {
  return (
    <div onClick={handleClick} className="kiosk-back-btn d-flex align-items-center kiosk-sub-text font-weight-bold">
      <MdOutlineArrowBackIos className="mr-2" />
      <p className="m-0">Back</p>
    </div>
  );
};

export default BackButton;
