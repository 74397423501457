import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Routes } from "react-router-dom/cjs/react-router-dom.min";
import "./App.scss";
import Hero from "./views/Main/Hero";
import Plans from "./views/Plans/Plans";
import Login from "./views/Login/Login";
import Register from "./views/Login/Register";
import Verify from "./views/Login/Verify";
import OrderSummary from "./views/Order/OrderSummary";
import AlertPopup from "./views/Alert_Popup/AlertPopup";
import CountdownTimer from "./views/Order/CountDownTimer";
import EsimDetail from "./views/Esim_Detail/EsimDetail";
import ReferralModule from "./views/Referral_module/ReferralModule";
import ForgotPassword from "./views/Login/ForgotPassword";
import MyEsim from "./views/Esim_Detail/MyEsim";
import AboutUS from "./views/Main/AboutUS";
import Faq from "./views/Main/Faq";
import PrivacyPolicy from "./views/Common/PrivacyPolicy";
import TermsAndCondition from "./views/Common/TermsAndCondition";
import Partner from "./views/Main/Partner";
import Aup from "./views/Common/Aup";
import KioskPaymentRedirect from "./views/Login/KioskPaymentRedirect";
import SetPassword from "./views/Login/SetPassword";
import ResetPassword from "./views/Login/ResetPassword";
import KioskHome from "./views/Kiosk/KioskHome";
import KioskPlans from "./views/Kiosk/KioskPlans";
import KioskPlanDetails from "./views/Kiosk/KioskPlanDetails";
import KioskCheckout from "./views/Kiosk/KioskCheckout";
import KioskPayment from "./views/Kiosk/KioskPayment";
import KioskPaymentSuccess from "./views/Kiosk/KioskPaymentSuccess";
import KioskQuickInstallation from "./views/Kiosk/QuickInstallation";
import KioskLogin from "./views/Kiosk/KioskLogin";
import StudentRegistration from "./views/Login/StudentRegistration";
import OrderConfirmation from "./views/Order/OrderConfirmation";
import Dashboard from "./views/Dashboard";
import EsimCompatible from "./views/Common/EsimCompatible";
import STWMain from "./views/Kiosk/SpinTheWheel/STWMain";
import Loader from "./views/Alert_Popup/Loader";

export default function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact component={Hero} />
          {/* <Hero /> */}
          {/* </Route> */}
          <Route path="/plans/:alpha2/:country" component={Plans} />
          <Route path="/rplans/:regionId/:region" component={Plans} />
          <Route path="/plans/:planId/:alpha2/:country" component={Plans} />
          <Route path="/rplans/:planId/:regionId/:region" component={Plans} />
          <Route path="/gplans" component={Plans} />
          <Route path="/login" component={Login} />
          <Route path="/Register/:referralCode" component={Register} />
          <Route path="/Register" component={Register} />
          <Route path="/verify/:verificationId/:userId" component={Verify} />
          <Route path="/OrderSummary" component={OrderSummary} />
          <Route path="/OrderConfirmation" component={OrderConfirmation} />
          <Route path="/AlertPopup" component={AlertPopup} />
          <Route path="/countdowntimer/:orderId" component={CountdownTimer} />
          <Route path="/Loader" component={Loader} />
          <Route path="/esimDetails/:esimId/:planId" component={EsimDetail} />
          <Route path="/ReferralModule" component={ReferralModule} />
          <Route path="/Dashboard" component={Dashboard} />
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route
            path="/forgotPasswordVerify/:verificationId/:userId"
            component={ResetPassword}
          />
          <Route path="/MyEsim" component={MyEsim} />
          <Route path="/aboutus" component={AboutUS} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/termsandcondition" component={TermsAndCondition} />
          <Route path="/partner" component={Partner} />
          <Route path="/acceptableusepolicy" component={Aup} />
          <Route
            path="/kioskpayment/:userId"
            component={KioskPaymentRedirect}
          />
          <Route path="/setPassword/:userId" component={SetPassword} />
          <Route path="/kiosk/plans/:alpha2/:country" component={KioskPlans} />
          <Route
            path="/kiosk/rplans/:regionId/:region"
            component={KioskPlans}
          />
          <Route path="/kiosk/planDetails" component={KioskPlanDetails} />
          <Route path="/kiosk/checkout" component={KioskCheckout} />
          <Route path="/kiosk/payment" component={KioskPayment} />
          <Route path="/kiosk/paymentSuccess" component={KioskPaymentSuccess} />
          <Route
            path="/kiosk/quickInstallation"
            component={KioskQuickInstallation}
          />
          <Route path="/kiosk/spinTheWheel" component={STWMain} />
          <Route path="/kiosk/login" component={KioskLogin} />
          <Route path="/kiosk" component={KioskHome} />
          <Route
            path="/studentRegistration/:referralCode"
            component={StudentRegistration}
          />
          <Route path="/studentRegistration" component={StudentRegistration} />
          <Route path="/esimCompatible" component={EsimCompatible} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}
