import axios from 'axios';
import { API_URL } from '../Common/Constant';
import React, { Component } from 'react';

class RegisterService {
    
    registerUser(json) {
        return axios.post(`${API_URL}/authentication/signup`,json, {
        });

    }
    
}



export default new RegisterService()
