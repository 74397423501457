import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { CSmartTable, CButton } from "@coreui/react-pro";
import StudentService from "../../Services/StudentService";

const deviceList = [];

const CustomFilterSection = (props) => {
  return (
    <div className="table-filter-section m-0">
      <div
        style={{ gap: "20px" }}
        className="w-100 d-flex flex-wrap justify-content-between align-items-center mb-3"
      >
        <div className="search-filter">
          <label htmlFor="">Search: </label>
          <input
            type="text"
            placeholder=""
            value={props.searchText}
            onChange={props.handleSearchChange}
          />
        </div>
      </div>
    </div>
  );
};

const EsimCompatible = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [data, setData] = useState([{}]);

  const columns = [
    {
      key: "MANUFACTURER",
      _style: { width: "10%" },
    },
    {
      key: "MODEL",
      _style: { width: "10%" },
    },
    {
      key: "ESIMCOMPATIBLE",
      _style: { width: "10%" },
    },
    {
      key: "COMMENTS",
      _style: { width: "20%" },
    },
  ];

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const compatibleDeviceAPI = async () => {
    await StudentService.getCompatibleDevice().then((response) => {
      response.data.forEach((deviceItem) => {
        deviceList.push({
          manufacturer: deviceItem.manufacturer,
          model: deviceItem.model,
          compatible: deviceItem.isEsimCompatible === 1 ? "Yes" : "No",
          comment: deviceItem.comment === "none" ? "" : deviceItem.comment,
        });
      });
      const formattedData = deviceList.map((item) => {
        return {
          MANUFACTURER: item.manufacturer,
          MODEL: item.model,
          ESIMCOMPATIBLE: item.compatible+"",
          COMMENTS: item.comment,
        };
      });
      setData(formattedData);
    });
  };

  useEffect(() => {
    compatibleDeviceAPI();
  }, []);

  useEffect(() => {
    
  }, [deviceList])
  

  const filterPlans = () => {
    const filteredData = data.filter((plan) => {
      // Convert all the plan data fields to lowercase for case-insensitive search
      const searchData = Object.values(plan)
        .map((value) => String(value).toLowerCase())
        .join(" ");
      return searchData.includes(searchText.toLowerCase());
    });
    setFilteredPlans(filteredData);
  };
  useEffect(() => {
    filterPlans();
  }, [searchText, data]);
  return (
    <div>
      <Header />
      <main style={{ zIndex: 5 }} className="container position-relative">
        <h2 className="mb-3 text-white" style={{ fontWeight: 600 }}>
          eSIM Compatible Devices
        </h2>
        <section className="d-flex flex-column justify-content-center align-items-center">
          <div id="table-section" className="table-section">
            <CustomFilterSection
              searchText={searchText}
              handleSearchChange={handleSearchChange}
            />
            <CSmartTable
              clickableRows
              columns={columns}
              //  columnSorter // Enable column sorting
              items={filteredPlans}
              pagination
              //  pagination={false} // Disable pagination
              // onFilteredItemsChange={(items) => {
              // }}
              // onSelectedItemsChange={(items) => {
              // }}
              scopedColumns={{
                //   avatar: (item) => (
                //     <td>
                //       <CAvatar src={logo} />
                //     </td>
                //   ),
                // VALIDITY: (item) => (
                //   <td>
                //     <CBadge color={getBadge(item.VALIDITY)}>{item.VALIDITY}</CBadge>
                //   </td>
                // ),
                // show_details: (item, rowIndex) => {
                //   return (
                //     <td className="py-2" style={{ textAlign: "right" }}>
                //       <CButton
                //         className="table-view-more-btn"
                //         color="light"
                //         variant="outline"
                //         shape="square"
                //         size="sm"
                //         onClick={() => {
                //           toggleDetails(rowIndex);
                //         }}
                //       >
                //         {icon ? "View" : "View Plan"}
                //       </CButton>
                //     </td>
                //   );
                // },
                show_Buy: (item, rowIndex) => {
                  return (
                    <td className="py-2" style={{ textAlign: "center" }}>
                      <CButton
                        className="table-view-more-btn"
                        color="light"
                        variant="outline"
                        shape="square"
                        size="sm"
                        onClick={() => {}}
                      >
                        TOP UP
                      </CButton>
                    </td>
                  );
                },
                //   details: (item, rowIndex) => {
                //     return (
                //       // <p>Sanket</p>
                //       <CCollapse visible={true}>
                //         <CCardBody className="p-3">
                //           <h4>{item.username}</h4>
                //           <p className="text-muted">User since: {item.PLANNAME}</p>
                //           <CButton size="sm" color="info">
                //             User Settings
                //           </CButton>
                //           <CButton size="sm" color="danger" className="ml-1">
                //             Delete
                //           </CButton>
                //         </CCardBody>
                //       </CCollapse>
                //     );
                //   },
              }}
              // selectable
              // sorterValue={{ column: "VALIDITY", state: "asc" }}
              // tableFilterLabel="Search:"
              // tableFilter
              tableProps={{
                className: "add-this-class",
                responsive: true,
                striped: true,
                hover: true,
              }}
              tableBodyProps={{
                className: "align-middle",
              }}
            />
            {/* ))} */}
          </div>
        </section>
      </main>
      <div className="left-top-grad"></div>
      <div className="left-bottom-grad"></div>
      <Footer />
    </div>
  );
};

export default EsimCompatible;
