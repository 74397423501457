import axios from 'axios';
import { API_URL } from '../Common/Constant';
class PartnerService {

    partnertypeList() {
        return axios.get(`${API_URL}/webappapi/partnerTypeList`, {
        });
    }
    addPartner(json) {
        return axios.post(`${API_URL}/webappapi/partner`,json);
    }
    
}
export default new PartnerService()