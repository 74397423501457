import React, { useState, useEffect, useRef } from "react";
import loginBottomGrad from "../../assets/img/login-bottom-gradient.png";
import loginLeftGrad from "../../assets/img/login-left-gradient.png";
import AlertPopup from "../Alert_Popup/AlertPopup";
import RegisterService from "../../Services/RegisterService";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import Header from "../Header/Header";
import worldcupWebBanner from "../../assets/img/w2023_web.jpg";
import worldCupMob from "../../assets/img/w2023_mob.png";
import Loader from "../Alert_Popup/Loader";
import ContactUsForm from "../Common/ContactUsForm";
import { IoEyeOff, IoEye } from "react-icons/io5";
import RegionService from "../../Services/RegionService";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import ReactSwitch from "react-switch";

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  conformPassword: "",
  countryValue: 0,
};

const validationSchema = function (values) {
  return Yup.object().shape({
    email: Yup.string().required("Email is Required").email("Invalid Email"),

    // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/, ('Invalid Email Format')),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    conformPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm password is required"),
    countryValue: Yup.number()
      .min(1, "Please select a country")
      .required("Please select a country"),
  });
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const Register = (props) => {
  const history = useHistory();
  const [pMessaage, setPMessaage] = useState();
  const [message, setMessage] = useState();
  const { state } = useLocation();
  const loginEmail = state && state.email;
  const [loginEmailId, setLoginEmailId] = useState(loginEmail);
  const [isToggled, setIsToggled] = useState(false);
  const [countries, setCountries] = useState();
  const dropdownCountryRef = useRef(null);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [haveReferralCode, setHaveReferralCode] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [currentCountryAlpha2, setCurrentCountryAlpha2] = useState("");
  const [register, setregister] = useState({
    firstName: "",
    lastName: "",
    email: loginEmailId ? loginEmailId : "",
    password: "",
    conformPassword: "",
    country: {
      countryId: 0,
    },
    usersSignupSource: {
      usersSignupSourceId: 1,
    },
    referralCode: props.match.params.referralCode
      ? props.match.params.referralCode
      : "",
    promotionalMessageSubscription: isToggled,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Update promotionalMessageSubscription when isToggled changes
  useEffect(() => {
    setregister((prevRegister) => ({
      ...prevRegister,
      promotionalMessageSubscription: isToggled,
    }));
  }, [isToggled]);

  const touchAll = (setTouched, errors) => {
    setMessage("");
    setTouched({
      email: true,
      password: true,
      conformPassword: true,
    });
    validateForm(errors);
  };

  const toggleCountryDropdown = () => {
    setIsCountryDropdownOpen(!isCountryDropdownOpen);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCountryChange = (country, name) => {
    setSelectedCountry(country);
    setSelectedCountryName(name);
    setIsCountryDropdownOpen(false);
    setregister((prevRegister) => ({
      ...prevRegister,
      countryValue: country,
      country: { countryId: country }, // Use the updated selectedCountry value
    }));
    console.log("register", register);

    // Update the partnerData here if needed
    // setPartnerData((prevData) => ({
    //   ...prevData,
    //   country: { countryId: country },
    // }));

    // setValidationErrors((prevErrors) => ({
    //   ...prevErrors,
    //   country: "",
    // }));
  };

  useEffect(() => {
    setLoginEmailId(loginEmail);

    const initialValues = {
      firstName: "",
      lastName: "",
      email: loginEmail || "",
      password: "",
      conformPassword: "",
      countryValue: 0,
    };
    setregister((prevRegister) => ({
      ...prevRegister,
      ...initialValues,
    }));
  }, [loginEmail]);

  const validateForm = (errors) => {
    findFirstError("register", (fieldName) => {
      return Boolean(errors[fieldName]);
    });
  };

  const findFirstError = (formName, hasError) => {
    const form = document.forms[formName];
    for (let i = 0; i < form.length; i++) {
      if (hasError(form[i].name)) {
        form[i].focus();
        break;
      }
    }
  };

  // const dataChange = (event) => {

  //   let { registerChange } = register

  //   if (event.target.name === "email") {
  //     registerChange.email = event.target.value;
  //   }
  //   if (event.target.name === "password") {
  //     registerChange.password = event.target.value;
  //   }
  //   if (event.target.name === "conformPassword") {
  //     registerChange.conformPassword = event.target.value;
  //   }
  //   setregister(registerChange)
  // };

  const dataChange = (event) => {
    const { name, value } = event.target;
    setregister((prevRegister) => ({
      ...prevRegister,
      [name]: value,
    }));
  };
  const goToLoginBtn = (e) => {
    e.preventDefault();
    const email = register.email;
    history.push({
      pathname: "/login",
      state: { email },
    });
  };
  const goToLogin = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  const registerClicked = (event) => {
    setIsLoading(true);
    console.log("register user data:", register);
    RegisterService.registerUser(register)
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("emailId", response.data.email);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("countryName", response.data.countryName);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("referralCode", response.data.referralCode);
          // localStorage.setItem("userVerificationStatus", response.data.isVerified);
          localStorage.setItem(
            "affiliateUserId",
            response.data.affiliateUserId
          );
          // setPMessaage("Password send to registered email");
          const message = "Email Sent";

          history.push({
            pathname: "/",
            state: { message },
          });
          setIsLoading(false);

          // }
        } else {
          setPMessaage("");
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
          setIsLoading(false);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              break;
            case 403:
              break;
            case 500:
            case 404:
            case 406:
              setIsLoading(false);
              setMessage(error.response.data.failedReason);
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");
              setIsLoading(false);
              break;
          }
        }
      });
  };
  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };
  const countryAPI = () => {
    RegionService.countryAccordingToRegionList().then((response) => {
      setCountries(response.data);
    });
  };
  useEffect(() => {
    countryAPI();
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCurrentCountryAlpha2(data.country_code);
        console.log("data.country_code", data.country_code);
      })
      .catch((error) => {
        console.log("error", error);
      });
    const targetElement = document.querySelector(".sign-up-btn");
    if (targetElement) {
      targetElement.style.display = "none";
    }
  }, []);

  useEffect(() => {
    console.log("insite searchTerm, countries");
    if (countries) {
      const filtered = countries.filter((item) =>
        item.countryDescription.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  }, [searchTerm, countries]);

  useEffect(() => {
    console.log("currentCountryAlpha2", currentCountryAlpha2);
    if (countries) {
      let currentCountry = countries.filter(
        (country) => country.alpha2 === currentCountryAlpha2
      );
      if (currentCountry && currentCountry.length > 0) {
        handleCountryChange(
          currentCountry[0].countryId,
          currentCountry[0].countryDescription
        );
      }
    }
  }, [currentCountryAlpha2]);

  const [isVisiblePass, setVisiblePass] = useState(false);

  const togglePass = () => {
    setVisiblePass(!isVisiblePass);
  };

  const [isVisibleConPass, setVisibleConPass] = useState(false);

  const toggleConPass = () => {
    setVisibleConPass(!isVisibleConPass);
  };

  const closeForgotPopup = () => {
    setMessage("");
  };

  const OKBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={closeForgotPopup}
      >
        OK
      </button>
    );
  };

  const LoginBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={goToLoginBtn}
      >
        LOGIN
      </button>
    );
  };

  // google analytics

  useEffect(() => {
    document.title = "Register Page";
    document.querySelector("title").classList.add("notranslate");
  }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];

    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));


    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownCountryRef.current &&
        !dropdownCountryRef.current.contains(event.target)
      ) {
        setIsCountryDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      style={{ overflow: "hidden", position: "relative" }}
      className="container-fluid p-0"
    >
      <header
        className="w-100"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Header />
      </header>
      <div className="row no-gutter" style={{ position: "relative" }}>
        <div className="col-md-6 login-bg first-section ">
          <div className="login d-flex align-items-center mt-3 mt-md-0">
            <div className="container">
              {/* <div className="world-cup-mob-container">
                  <img style={{width: '100%'}} src={worldCupMob} alt="world-cup-mob" />

                  </div> */}
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5 register-content">
                  <div className="d-md-none login-card text-white text-center p-3 mb-5">
                    <p className="m-0">
                      <span
                        style={{
                          color: "#E8BE61",
                          fontWeight: 600,
                          fontSize: 20,
                        }}
                      >
                        For International & UK Student’s
                      </span>
                      <br />
                      <b style={{ fontSize: 24, margin: "6 0" }}>
                        FREE eSIM & 2GB DATA
                      </b>
                      <br />
                      for use in the UK for your first month.
                    </p>
                    <button
                      style={{ fontSize: 16 }}
                      className="notranslate btn-custom-gradient carousel-cta-btn shadow-sm text-sm btn rounded-pill text-capitalize font-weight-normal mt-2 px-3"
                      onClick={() => history.push("/studentRegistration")}
                    >
                      <font>Join Now</font>
                    </button>
                  </div>

                  <h1 className="login-heading text-center">Create Account</h1>
                  <p className="text-muted mb-2 text-center">
                    Get started by creating your new account
                  </p>
                  <Formik
                    enableReinitialize={true}
                    initialValues={register}
                    validate={validate(validationSchema)}
                    validateOnChange={false}
                    validateOnBlur={false}
                    // onSubmit={registerClicked}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      registerClicked();
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      setTouched,
                      handleReset,
                    }) => (
                      <form
                        name="register"
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        noValidate
                        autoComplete="on"
                      >
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>First Name</label>
                          </div>
                          <input
                            name="firstName"
                            valid={!errors.firstName}
                            invalid={touched.firstName && errors.firstName}
                            maxLength={30}
                            onChange={(e) => {
                              handleChange(e);
                              dataChange(e);
                            }}
                            onBlur={handleBlur}
                            value={register.firstName}
                            id="inputFirstName"
                            type="text"
                            placeholder="Enter your first name"
                            className={`form-control border-0 shadow-sm custom-input ${
                              errors.firstName === "" ||
                              errors.firstName === undefined
                                ? ""
                                : "input-error"
                            }`}
                          />
                          <FormFeedback
                            style={{
                              display:
                                errors.firstName === "" ||
                                errors.firstName === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {errors.firstName}
                          </FormFeedback>
                        </div>

                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Last Name</label>
                          </div>
                          <input
                            name="lastName"
                            valid={!errors.lastName}
                            invalid={touched.lastName && errors.lastName}
                            maxLength={29}
                            onChange={(e) => {
                              handleChange(e);
                              dataChange(e);
                            }}
                            onBlur={handleBlur}
                            value={register.lastName}
                            id="inputLastName"
                            type="text"
                            placeholder="Enter your last name"
                            className={`form-control border-0 shadow-sm custom-input ${
                              errors.lastName === "" ||
                              errors.lastName === undefined
                                ? ""
                                : "input-error"
                            }`}
                          />
                          <FormFeedback
                            style={{
                              display:
                                errors.lastName === "" ||
                                errors.lastName === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {errors.lastName}
                          </FormFeedback>
                        </div>

                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Email ID*</label>
                          </div>
                          <input
                            name="email"
                            valid={!errors.email}
                            invalid={touched.email && errors.email}
                            maxLength={65}
                            onChange={(e) => {
                              handleChange(e);
                              dataChange(e);
                            }}
                            onBlur={handleBlur}
                            value={register.email}
                            id="inputEmail"
                            type="email"
                            placeholder="Enter your email ID"
                            required=""
                            className={`form-control border-0 shadow-sm custom-input ${
                              errors.email === "" || errors.email === undefined
                                ? ""
                                : "input-error"
                            }`}
                          />
                          <FormFeedback
                            style={{
                              display:
                                errors.email === "" ||
                                errors.email === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {errors.email}
                          </FormFeedback>
                        </div>

                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Password*</label>
                          </div>
                          <div style={{ position: "relative" }}>
                            <input
                              name="password"
                              valid={!errors.password}
                              invalid={touched.password && errors.password}
                              onChange={(e) => {
                                handleChange(e);
                                dataChange(e);
                              }}
                              onBlur={handleBlur}
                              value={register.password}
                              id="inputPassword"
                              type={!isVisiblePass ? "password" : "text"}
                              placeholder="Enter your password"
                              required=""
                              className={`form-control border-0 shadow-sm custom-input ${
                                errors.password === "" ||
                                errors.password === undefined
                                  ? ""
                                  : "input-error"
                              }`}
                            />
                            <span
                              className="password-icon"
                              onClick={togglePass}
                            >
                              {isVisiblePass ? <IoEyeOff /> : <IoEye />}
                            </span>
                          </div>

                          <FormFeedback
                            style={{
                              display:
                                errors.password === "" ||
                                errors.password === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {/* <ul style={{ listStylePosition: "inside" }}>
                              <li>
                                Password must be at least 8 characters long and
                                contains
                              </li>
                              <li>1 uppercase letter</li>
                              <li>1 lowercase letter</li>
                              <li>1 number</li>
                              <li>1 special character</li>
                            </ul> */}
                            {errors.password}
                          </FormFeedback>
                        </div>
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Re-Enter password*</label>
                          </div>
                          <div style={{ position: "relative" }}>
                            <input
                              name="conformPassword"
                              valid={!errors.conformPassword}
                              invalid={
                                touched.conformPassword &&
                                errors.conformPassword
                              }
                              onChange={(e) => {
                                handleChange(e);
                                dataChange(e);
                              }}
                              onBlur={handleBlur}
                              value={register.conformPassword}
                              type={!isVisibleConPass ? "password" : "text"}
                              placeholder="Re-Enter your password"
                              required=""
                              className={`form-control border-0 shadow-sm custom-input ${
                                errors.conformPassword === "" ||
                                errors.conformPassword === undefined
                                  ? ""
                                  : "input-error"
                              }`}
                            />
                            <span
                              className="password-icon"
                              onClick={toggleConPass}
                            >
                              {isVisibleConPass ? <IoEyeOff /> : <IoEye />}
                            </span>
                          </div>
                          <FormFeedback
                            style={{
                              display:
                                errors.conformPassword === "" ||
                                errors.conformPassword === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className=""
                          >
                            {errors.conformPassword}
                          </FormFeedback>
                        </div>
                        <div className="form-group md-3 custom-dropdown ">
                          <label htmlFor="country">Country*</label>
                          <div
                            className="custom-dropdown-container register-dropdown"
                            ref={dropdownCountryRef}
                          >
                            <div
                              className="custom-dropdown-selected"
                              onClick={toggleCountryDropdown}
                            >
                              {selectedCountryName || "Select Country"}

                              <span
                                className={`dropdown-icon ${
                                  isCountryDropdownOpen ? "open" : ""
                                }`}
                              ></span>
                              <span className="caret-icon"></span>
                            </div>
                            {isCountryDropdownOpen && (
                              <ul className="custom-dropdown-options dropdown-height login-dropdown">
                                <li className="partner-search-bar">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                  />
                                </li>
                                {searchTerm &&
                                filteredCountries.length === 0 ? (
                                  <li className="no-results">
                                    No results found
                                  </li>
                                ) : (
                                  filteredCountries.map((country, index) => (
                                    <li
                                      key={country.countryId}
                                      value={country.countryId}
                                      onClick={() =>
                                        handleCountryChange(
                                          country.countryId,
                                          country.countryDescription
                                        )
                                      }
                                    >
                                      {country.countryDescription}
                                    </li>
                                  ))
                                )}
                              </ul>
                            )}
                          </div>

                          <FormFeedback
                            style={{
                              display:
                                errors.countryValue === "" ||
                                errors.countryValue === undefined
                                  ? "none"
                                  : "block",
                              marginBottom: "0",
                            }}
                            className="red"
                          >
                            {errors.countryValue}
                          </FormFeedback>
                        </div>

                        <div className="form-group mb-3">
                          <div className="d-flex align-items-center w-100">
                            <div
                              className="custom-ref-checkbox m-0"
                              style={{ paddingTop: "2px" }}
                            >
                              <input
                                type="checkbox"
                                checked={haveReferralCode}
                                onChange={(e) =>
                                  setHaveReferralCode(e.target.checked)
                                }
                              />
                            </div>
                            <p className="m-0">Do you have a referral code?</p>
                          </div>
                        </div>

                        {haveReferralCode && (
                          <div className="form-group mb-3">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <label>Referral Code</label>
                            </div>
                            <input
                              name="referralCode"
                              onChange={(e) => {
                                handleChange(e);
                                dataChange(e);
                              }}
                              onBlur={handleBlur}
                              readOnly={
                                props.match.params.referralCode ? true : false
                              }
                              value={
                                props.match.params.referralCode
                                  ? props.match.params.referralCode
                                  : register.referralCode
                              }
                              id="refCode"
                              type="text"
                              placeholder="Enter your Referral Code"
                              className={`form-control border-0 shadow-sm custom-input`}
                            />
                          </div>
                        )}

                        <div className="d-flex justify-content-start align-items-center custom-control register_subscribe_text ml-auto mb-3 p-0">
                          {/* <label className="checkbox-container">
                            <input defaultChecked type="checkbox" />
                            <span className="checkmark"></span>
                          </label> */}
                          {/* <ReactSwitch
                           checked={true}
                          /> */}
                          <div>
                            <input
                              type="checkbox"
                              name="toggle"
                              id="toggle-button"
                              className="toggle-button"
                              checked={isToggled}
                              onChange={handleToggle}
                            ></input>
                          </div>

                          <p className="m-0 ml-2">
                            I wish to subscribe to promotional emails.
                          </p>
                        </div>
                        <button
                          onClick={() => touchAll(setTouched, errors)}
                          type="submit"
                          className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                        >
                          Create
                        </button>
                        <p className="text-muted mb-4 text-center">
                          Already have an Account?{" "}
                          <span onClick={goToLogin}>
                            <a href="#">Login</a>
                          </span>
                        </p>
                      </form>
                    )}
                  />
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    <FormFeedback
                      style={{
                        display:
                          message === "" || message === undefined
                            ? "none"
                            : "block",
                      }}
                      className="red"
                    >
                      {message}
                    </FormFeedback>
                  </div>
                  <div className="px-4 mt-4 register-footer notranslate">
                    <p className="text-muted mb-4 text-center">
                      By registering, you agree to our{" "}
                      <a
                        onClick={() => history.push("/termsandcondition")}
                        className="text-center"
                      >
                        {" "}
                        Terms & Conditions
                      </a>{" "}
                      and <br />
                      <a
                        onClick={() => history.push("/privacypolicy")}
                        className="text-center"
                      >
                        {" "}
                        Privacy Policy
                      </a>
                      . Rest assured, we'll keep your personal information
                      private and spam-free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
          <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
        </div>
        {/* login-bg-image */}
        <div className="col-md-6 d-none d-md-flex login-bg-image">
          <div
            style={{ minHeight: "100%", padding: "85px 0 60px 0" }}
            className="w-100 d-flex flex-column justify-content-between align-items-center"
          >
            <div className="login-card text-white text-center p-3">
              <p className="m-0">
                <span
                  style={{ color: "#E8BE61", fontWeight: 600, fontSize: 20 }}
                >
                  For International & UK Student’s
                </span>
                <br />
                <b style={{ fontSize: 24, margin: "6 0" }}>
                  FREE eSIM & 2GB DATA
                </b>
                <br />
                for use in the UK for your first month.
              </p>
              <button
                style={{ fontSize: 16 }}
                className="btn-custom-gradient carousel-cta-btn shadow-sm text-sm btn rounded-pill text-capitalize font-weight-normal mt-2 px-3"
                onClick={() => history.push("/studentRegistration")}
              >
                Join Now
              </button>
            </div>
            <div className="login-card text-white text-center p-3">
              <p style={{ fontSize: 18 }} className="m-0">
                Enjoy a
                <b style={{ fontSize: 22, margin: "0 4px" }}> 20% discount </b>
                on your second month's plan. <br />
                Receive a
                <b style={{ fontSize: 22, margin: "0 4px" }}> 30% discount </b>
                on your third month's plan.
              </p>
            </div>
          </div>
          {/* <div className="world-cup-content">
            <div>
              <h3 className="mb-2 text-center">
                Secure Your
                <br />
                 2023 World Cup Ticket
              </h3>
              <h3 className="text-center">
                <span>

                JOIN LUCKY DRAW 
                </span>
                <br />
                Register & Verify Your Email by October 31st!
              </h3>
            </div>

            <div style={{marginBottom: '10px'}} className="mt-auto">
              <h1>REGISTER NOW!</h1>
              <p>
                <span tyle={{fontSize: '8.8px',fontWeight: '700'}}>
                2023 World Cup Ticket Lucky Draw T&C: </span>Register by Oct 31st to
                Enter! Unique Email Id is a must. The number of users signing up
                through your unique code will be considered. The number of
                conversion will be considered. Organisers have all rights
                regarding the final decision in this campaign.
              </p>
            </div>
          </div> */}
        </div>
        {/* place for AlertPopup */}
        {/* <AlertPopup/> */}
        {message ===
        "Password and confirm password do not match. Please try again" ? (
          <AlertPopup
            status={"error"}
            heading={"Password Mismatch"}
            content={
              "Password and Confirm Password do not match. Please ensure both fields contain the same password for successful registration."
            }
            customFunction={<OKBtn />}
            updateState={closeForgotPopup}
          />
        ) : (
          ""
        )}

        {message === "User already exists " ? (
          <AlertPopup
            status={"error"}
            heading={"User already exists"}
            content={
              "This email ID is already registered with us. Please ensure your email ID or click 'Login' below."
            }
            customFunction={<LoginBtn />}
            updateState={closeForgotPopup}
          />
        ) : (
          ""
        )}
      </div>
      <ContactUsForm />
      {isLoading === true && <Loader />}
    </div>
  );
};

export default Register;
