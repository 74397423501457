// export const API_URL='https://esimapitest.altius.cc';
export const API_URL='https://esimapi.altius.cc';

//For FAQ installation link
// export const React_URL = 'https://esimuat.altius.cc/';
export const React_URL = 'https://esim-mobile.co.uk/';
// export const React_URL = 'http://localhost:3000/';

//recaptcha data
export const REACT_APP_SITE_KEY = "6LcNGhgqAAAAAJceyiNR3d9vJb74UxULd9kJ1nYQ";
