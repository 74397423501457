import React, { useEffect, useRef, useState } from "react";
import orderConfirmGif from "../../assets/img/order-confirm.gif";
import orderPending from "../../assets/img/caution.png";
import orderFailed from "../../assets/img/cross_.png";
import dummyCreditCard from "../../assets/img/dummy-credit-card.png";
import dummyCreditCard2 from "../../assets/img/dummy-credit-card_2.png";
import {RiRefreshLine} from 'react-icons/ri';

import OrderListComp from "./OrderListComp";
import { BiLink } from "react-icons/bi";
import { RxDropdownMenu } from "react-icons/rx";
import creditCardChip from "../../assets/img/credit-card-chip.png";
import amexLogo from "../../assets/img/logos_amex.png";
import visaLogo from "../../assets/img/logos_visaelectron.png";
import masterCardLogo from "../../assets/img/logos_mastercard.png";
import gliterStar from "../../assets/img/gliter.png";
import { FaLocationArrow } from "react-icons/fa";
import { SlClose } from "react-icons/sl";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import ContactUsForm from "../Common/ContactUsForm";

const cardType = {
  amex: amexLogo,
  visa: visaLogo,
  master: masterCardLogo,
};

const OrderConfirmation = () => {
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const history = useHistory();

  const esimDetailsRedirect = () => {
    if(orderDetails!==null){
    if(orderDetails.orderPlan[0].esim[0]!==undefined && orderDetails.orderPlan[0].esim[0].esimPlan.plan.planIdEncrypted){
      const esimId = orderDetails.orderPlan[0].esim[0].esimIdEncrypted
      const planId = orderDetails.orderPlan[0].esim[0].esimPlan.plan.planIdEncrypted
      history.push(`/esimdetails/${esimId}/${planId}`)
    }
  }
  }

  useEffect(() => {
    const storedOrderDetails = localStorage.getItem("orderSummaryAfterPayment");
    if (storedOrderDetails) {
      const parsedOrderDetails = JSON.parse(storedOrderDetails);
      setOrderDetails(parsedOrderDetails);
    }
  }, []);

  const coverageDropdownRef = useRef(null);

  const openCoverageDropdown = () => {
    document.body.style.overflowY = "hidden";
    setIsCoverageDropdownOpen(true);
  };

  const closeCoverageDropdown = () => {
    document.body.style.overflowY = "auto";
    setIsCoverageDropdownOpen(false);
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  const EmailId = orderDetails !== null && orderDetails.customer.email;

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const CoverageDropdown = () => {
    // let coverageData = {  }
    let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (orderDetails !== null) {
        setCountry(orderDetails.orderPlan[0].plan.planCountry);
      }
    }, []);

    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          <h4>
            Coverage is provided by{" "}
            <span>{orderDetails.orderPlan[0].plan.planCountry.length}</span>{" "}
            countries
          </h4>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2">
                          <p>{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
        <SlClose className="modal-close-icon" />
      </div>
    );
  };


  const goToHome = () => {
    history.push("/");
  };

  const retryPayment = () => {
    if (orderDetails !== null) {
      const link = orderDetails.payment.link;
      if (link) {
        window.location.href = link; // Redirect to the link in the same tab
      }
    }
  };

   // google analytics

   useEffect(() => {
    document.title = "Order Confirmation Page";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once


  return (
    <div className="order-confirmation-section">
      <header>
        <Header />
      </header>
      {orderDetails !== null &&
          // orderDetails.orderStatus.orderStatusId === 3 || orderDetails.orderStatus.orderStatusId === 2 || orderDetails.orderStatus.orderStatusId === 4 &&
          orderDetails.amount === 0 ? (

      <div className="container">
        {orderDetails !== null &&
          orderDetails.orderStatus.orderStatusId === 3 &&
                  <div className="order-confirm-header row ">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <img
                  style={{ width: "120px", height: "120px" }}
                  src={orderConfirmGif}
                  alt="order confirm"
                />
                <h2 className="text-center">Thank you for your order.</h2>
                <p className="text-center">
                  We’ve sent you a confirmation email to {EmailId}
                </p>
                <div className="">
                  <button
                    onClick={esimDetailsRedirect}
                    className="btn btn-custom-gradient rounded-pill text-uppercase mb-4 shadow-sm d-flex justify-content-center align-items-center"
                  >
                        <img width={20} className="mr-2" src={gliterStar} alt="gliterStar" />
                        Get your eSIM details here
                      
                        {/* <FaLocationArrow /> */}
                      
                  </button>
                </div>
              </div>
            </div>}
            {orderDetails !== null &&
          orderDetails.orderStatus.orderStatusId === 4 && (
            <div className="order-confirm-header row ">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <img
                  className="my-3"
                  style={{ width: "60px", height: "60px" }}
                  src={orderPending}
                  alt="order pending"
                />
                <h2 className="text-center mb-3">Thank you for your order, it is pending!</h2>
                {/* <p className="text-center mb-4">
                </p> */}
                <div className="cards-bg custom-instructions p-4 mb-4">

                <p>
                Your order is now being processed.
                <br /> 
                <br />
                Please allow a little time for our team to complete the necessary checks and activate your eSIM. You will receive a confirmation email with further details shortly. If you have any questions or need assistance, feel free to reach out to our customer support team. 
                </p>
                <span className="font-weight-bold">
                We appreciate your business and look forward to providing you with a seamless eSIM experience!
                </span>
                </div>

                <div className="">
            
        </div>

              </div>
            </div>
          )}
           {orderDetails !== null &&
          orderDetails.orderStatus.orderStatusId === 2 && (
            <div className="order-confirm-header row ">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <img
                  className="my-3"
                  style={{ width: "60px", height: "60px" }}
                  src={orderFailed}
                  alt="order failed"
                />
                <h2 className="text-center mb-3">Order Failed !</h2>
                {/* <p className="text-center mb-4">
                 
                </p> */}
                <div className="cards-bg custom-instructions text-center mb-3 p-4">

                <p style={{fontSize: '23px'}} >
                  We're sorry, but it seems there was an issue processing your
                  order. 
                  {/* To ensure you can enjoy our services hassle-free, Here are a few steps you can take: */}
                </p>
                {/* <ul className="mb-3">
                  <li>Double-check your payment details for accuracy.</li>
                  <li>
                    Verify that your payment method is up to date and has
                    sufficient funds.
                  </li>
                  <li>
                    Consider trying an alternative payment method if available.
                  </li>
                </ul>
                <span className="font-weight-bold m-0">
                  Once you've done that, please click 'Retry Payment' below to
                  complete your transaction. If you encounter any further
                  difficulties, 
                  don't hesitate to reach out to our support team at help@esim-mobile.co.uk
                </span> */}

                </div>

                {/* <div className="">
                  <button
                    onClick={retryPayment}
                    className="btn btn-custom-gradient btn-lg text-uppercase mb-4 shadow-sm d-flex justify-content-center align-items-center"
                  >
                    <RiRefreshLine className="mr-2" style={{fontSize: '20px'}}/>
                       Retry Payment
                      
                  </button>
                </div> */}
              </div>
            </div>
          )}
            <div className="col-12 col-md-8 d-flex justify-content-center align-items-center w-100" style={{margin: "0 auto"}}>
              <div className="order-summary w-100 mb-2">
                <div className="mb-3">
                  <h3>Order Summary</h3>
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"ORDER"}
                    value={`GKT-${orderDetails.orderId}`}
                    breakLine={true}
                  />
                  {orderDetails.orderPlan[0].esim[0] !== undefined && (
                    <OrderListComp
                      orderValue={"valueFunction"}
                      order={"ICCID"}
                      value={orderDetails.orderPlan[0].esim[0].iccid}
                      breakLine={true}
                    />
                  )}
                </div>

                <div className="mb-3">
                  <h3>Plan Details</h3>

                  <div className="order-list list-break-line">
                    <div className="order-key">
                      <p>Coverage</p>
                    </div>
                    {orderDetails.orderPlan[0].plan.planType.planTypeId ===
                    2 ? (
                      <div
                        className="order-value order-popup-link"
                        style={{ position: "relative" }}
                      >
                        <div
                          className={`${
                            isCoverageDropdownOpen ? "order-popup-active" : ""
                          }`}
                          onClick={openCoverageDropdown}
                        >
                          <span>
                            {orderDetails.orderPlan[0].plan.planCountry.length}{" "}
                            Countries
                          </span>
                          <RxDropdownMenu />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="order-value"
                        style={{ position: "relative" }}
                      >
                        {orderDetails.orderPlan[0].plan.planCountry[0]
                          .planCountryNetwork !== null ? (
                          <span>
                            {orderDetails.orderPlan[0].plan.planCountry.map(
                              (country) => country.countryDescription
                            )}{" "}
                            -
                            {orderDetails.orderPlan[0].plan.planCountry[0].planCountryNetwork.map(
                              (network) => network
                            )}
                          </span>
                        ) : (
                          <span>
                            {orderDetails.orderPlan[0].plan.planCountry.map(
                              (country) => country.countryDescription
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"Plan Type"}
                    value={"Data"}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"Order Type"}
                    value={orderDetails.orderType.orderTypeId === 2 ? "Top Up" : "New Esim"}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={orderDetails.orderPlan[0].plan.planName}
                    value={`£${orderDetails.amount}`}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"4G/LTE Data"}
                    value={orderDetails.orderPlan[0].plan.data}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"Validity"}
                    value={`${orderDetails.orderPlan[0].plan.validity} Days`}
                    breakLine={true}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <button
              onClick={goToHome}
              className="btn btn-custom-gradient rounded-pill text-uppercase mb-4 shadow-sm"
            >
              Explore More Plans
            </button>
          </div>
        </div>
      </div>)
          :
      (<div className="container">
        {/* {orderDetails !== null &&
          console.log("status", orderDetails.orderStatus.orderStatusId)
          } */}
        {orderDetails !== null &&
          orderDetails.orderStatus.orderStatusId === 3 &&
          orderDetails.payment.paymentStatus.paymentStatusId === 2 && (
            <div className="order-confirm-header row ">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <img
                  style={{ width: "120px", height: "120px" }}
                  src={orderConfirmGif}
                  alt="order confirm"
                />
                <h2 className="text-center">Thank you for your order.</h2>
                <p className="text-center">
                  We’ve sent you a confirmation email to {EmailId}
                </p>
                <div className="">
                  <button
                    onClick={esimDetailsRedirect}
                    className="btn btn-custom-gradient rounded-pill text-uppercase mb-4 shadow-sm d-flex justify-content-center align-items-center"
                  >
                        <img width={20} className="mr-2" src={gliterStar} alt="gliterStar" />
                        Get your eSIM details here
                      
                        {/* <FaLocationArrow /> */}
                      
                  </button>
                </div>
              </div>
            </div>
          )}
        {orderDetails !== null &&
          orderDetails.orderStatus.orderStatusId === 4 &&
          orderDetails.payment.paymentStatus.paymentStatusId === 2 && (
            <div className="order-confirm-header row ">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <img
                  className="my-3"
                  style={{ width: "60px", height: "60px" }}
                  src={orderPending}
                  alt="order pending"
                />
                <h2 className="text-center mb-3">Thank you for your payment, it was successful!</h2>
                {/* <p className="text-center mb-4">
                </p> */}
                <div className="cards-bg custom-instructions p-4 mb-4">

                <p>
                Your order is now being processed.
                <br /> 
                <br />
                Please allow a little time for our team to complete the necessary checks and activate your eSIM. You will receive a confirmation email with further details shortly. If you have any questions or need assistance, feel free to reach out to our customer support team. 
                </p>
                <span className="font-weight-bold">
                We appreciate your business and look forward to providing you with a seamless eSIM experience!
                </span>
                </div>

                <div className="">
            
        </div>

              </div>
            </div>
          )}
        {orderDetails !== null &&
          orderDetails.orderStatus.orderStatusId === 2 &&
          orderDetails.payment.paymentStatus.paymentStatusId === 3 && (
            <div className="order-confirm-header row ">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <img
                  className="my-3"
                  style={{ width: "60px", height: "60px" }}
                  src={orderFailed}
                  alt="order failed"
                />
                <h2 className="text-center mb-3">Payment Failed !</h2>
                {/* <p className="text-center mb-4">
                 
                </p> */}
                <div className="cards-bg custom-instructions p-4 mb-4">

                <p style={{fontSize: '16px'}} >
                  We're sorry, but it seems there was an issue processing your
                  payment. To ensure you can enjoy our services hassle-free, Here are a few steps you can take:
                </p>
                <ul className="mb-3">
                  <li>Double-check your payment details for accuracy.</li>
                  <li>
                    Verify that your payment method is up to date and has
                    sufficient funds.
                  </li>
                  <li>
                    Consider trying an alternative payment method if available.
                  </li>
                </ul>
                <span className="font-weight-bold m-0">
                  Once you've done that, please click 'Retry Payment' below to
                  complete your transaction. If you encounter any further
                  difficulties, 
                  don't hesitate to reach out to our support team at help@esim-mobile.co.uk
                </span>

                </div>

                <div className="">
                  <button
                    onClick={retryPayment}
                    className="btn btn-custom-gradient btn-lg text-uppercase mb-4 shadow-sm d-flex justify-content-center align-items-center"
                  >
                    <RiRefreshLine className="mr-2" style={{fontSize: '20px'}}/>
                       Retry Payment
                      
                  </button>
                </div>
              </div>
            </div>
          )}
        {orderDetails !== null && (
          <div className="row order-confirm-body">
            <div className="col-12 col-md-6">
              <div className="order-summary mb-2">
                <div className="mb-3">
                  <h3>Order Summary</h3>
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"ORDER"}
                    value={`GKT-${orderDetails.orderId}`}
                    breakLine={true}
                  />
                  {orderDetails.orderPlan[0].esim[0] !== undefined && (
                    <OrderListComp
                      orderValue={"valueFunction"}
                      order={"ICCID"}
                      value={orderDetails.orderPlan[0].esim[0].iccid}
                      breakLine={true}
                    />
                  )}
                </div>

                <div className="mb-3">
                  <h3>Plan Details</h3>

                  <div className="order-list list-break-line">
                    <div className="order-key">
                      <p>Coverage</p>
                    </div>
                    {orderDetails.orderPlan[0].plan.planType.planTypeId ===
                    2 || orderDetails.orderPlan[0].plan.planType.planTypeId ===
                    3 ? (
                      <div
                        className="order-value order-popup-link"
                        style={{ position: "relative" }}
                      >
                        <div
                          className={`${
                            isCoverageDropdownOpen ? "order-popup-active" : ""
                          }`}
                          onClick={openCoverageDropdown}
                        >
                          <span>
                            {orderDetails.orderPlan[0].plan.planCountry.length}{" "}
                            Countries
                          </span>
                          <RxDropdownMenu />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="order-value"
                        style={{ position: "relative" }}
                      >
                        {orderDetails.orderPlan[0].plan.planCountry[0]
                          .planCountryNetwork !== null ? (
                          <span>
                            {orderDetails.orderPlan[0].plan.planCountry.map(
                              (country) => country.countryDescription
                            )}{" "}
                            -  {orderDetails.orderPlan[0].plan.planCountry[0].planCountryNetwork.map(
                              (network) => network
                            ).join(', ')}
                          </span>
                        ) : (
                          <span>
                            {orderDetails.orderPlan[0].plan.planCountry.map(
                              (country) => country.countryDescription
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"Plan Type"}
                    value={"Data"}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"Order Type"}
                    value={orderDetails.orderType.orderTypeId === 2 ? "Top Up" : "New Esim"}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={orderDetails.orderPlan[0].plan.planName}
                    value={`£${orderDetails.amount}`}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"4G/LTE Data"}
                    value={orderDetails.orderPlan[0].plan.data}
                    breakLine={true}
                  />
                  <OrderListComp
                    orderValue={"valueFunction"}
                    order={"Validity"}
                    value={`${orderDetails.orderPlan[0].plan.validity} Days`}
                    breakLine={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h3 className="text-center">Payment Details</h3>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ position: "relative", zIndex: "0" }}
              >
                <div className="credit-card-content">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <img src={creditCardChip} alt="creditCardChip" />
                    <img src={cardType.master} alt="cardType" />
                  </div>
                  <div className="w-100">
                    <span className="credit-card-number">
                      {orderDetails.payment.cardNumber}
                    </span>
                  </div>
                  <div>*** ****</div>
                </div>
                <img
                  style={{ margin: "8px 0", width: "330px" }}
                  src={dummyCreditCard}
                  alt=""
                />
              </div>
              <OrderListComp
                orderValue={"valueFunction"}
                order={"Transaction Id"}
                value={orderDetails.payment.transactionId}
                breakLine={true}
              />
              <OrderListComp
                orderValue={"valueFunction"}
                order={"Total Price"}
                value={`${orderDetails.currency} £${orderDetails.amount}`}
                breakLine={true}
              />
              <OrderListComp
                orderValue={"valueFunction"}
                order={"Billing"}
                value={"Prepaid"}
                breakLine={true}
              />
            </div>
          </div>
        )}

        <div className="row mt-4">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <button
              onClick={goToHome}
              className="btn btn-custom-gradient rounded-pill text-uppercase mb-4 shadow-sm"
            >
              Explore More Plans
            </button>
          </div>
        </div>
      </div>)}
      <ContactUsForm/>
      {isCoverageDropdownOpen ? (
        <div className="dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}
    </div>
  );
};

export default OrderConfirmation;
