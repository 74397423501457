import React, { useEffect, useState, useContext } from "react";
import ContactUsService from "../../Services/ContactUsService";
import toast, { Toaster } from 'react-hot-toast';
import contactBtn from "../../assets/img/contact_icon.svg";
import contactCloseBtn from "../../assets/img/contact_close_icon.svg";
import { FormFeedback } from "reactstrap";
import Loader from "../Alert_Popup/Loader";
import { ContactContext } from "./ContactProvider";
// import { ContactContext } from "../Common/ContactProvider";
// import { ContactContext } from './ContactProvider';

const ContactUsForm = (props) => {



  const [isLoading, setIsLoading] = useState(false);
  const { showContactForm, handleContacForm } = useContext(ContactContext);

  // const [showContactForm, setShowContactForm] = useState(false);
  // const [message, setMessage] = useState();
  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    mobileNo: "",
    content: "",
    createdBy: {
      id: localStorage.getItem('userId') ? localStorage.getItem('userId') : 0
    }
  });
  const dataChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [validationErrors, setValidationErrors] = useState({});

  const sendClicked = (e) => {
    e.preventDefault();
    // Manually validate the form fields
    const errors = {};
  
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }else {
      if (!/^[A-Za-z ]+$/.test(formData.name)) {
        errors.name = "Please enter a valid Name";
      }
    }
  
    if (!formData.emailId.trim()) {
      errors.emailId = "Email is required";
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(formData.emailId)) {
      errors.emailId = "Invalid Email";
    }
  
    if (formData.mobileNo) {
      if (!/^[0][1-9]\d{9}$|^[1-9]\d{9}$/.test(formData.mobileNo)) {
        errors.mobileNo = "Invalid contact number";
      }
    }
  
    if (!formData.content.trim()) {
      errors.content = "Issue is required";
    }
  
    // Check if there are any validation errors
    if (Object.keys(errors).length === 0) {
      setIsLoading(true)
      // No validation errors, proceed with the API call
      ContactUsService.contactUs(formData).then((response) => {
        if (response.status === 201) {
          document.getElementById("name").value="";
          document.getElementById("emailId").value="";
          document.getElementById("mobileNo").value="";
          document.getElementById("content").value="";
          // console.log("sended mail");
          toast.success('Mail sent successfully!')
          setFormData({
            name: "",
            emailId: "",
            mobileNo: "",
            content: "",
            createdBy: {
              id: localStorage.getItem("userId") ? localStorage.getItem("userId") : 0,
            },
          });
          handleContacForm(false)
          // setShowContactForm(false);
          setValidationErrors({});
           setIsLoading(false)
        }
      });
    } else {
      // Validation errors exist, set them in the state to display to the user
      setValidationErrors(errors);
    }
  };

  return (
    <>
    <div onClick={()=>{handleContacForm();setValidationErrors({})}} className="contactBtn">
        {!showContactForm ? (
          <img
            className="contact-btn-img"
            title="Contact us"
            // onClick={showContacForm}
            src={contactBtn}
            alt="contactBtn"
          />
        ) : (
          <img
            className="contact-close-btn-img"
            title="Contact us"
            // onClick={hideContacForm}
            src={contactCloseBtn}
            alt="contactBtn"
          />
        )}
      </div>

      <div
        style={{background: 'linear-gradient(135deg, rgb(0 0 0 / 40%) 0%, rgba(255, 255, 255, 0) 100%)'}}
        className={`contact-form ${
          (showContactForm || props.showContactForm) ? "contact-form-active" : ""
        } cards-bg`}
      >
        <div className="grad-heading">
          <h2>Contact Us</h2>
          <p>We will respond you as soon as possible</p>
        </div>
        {/* <Formik
          enableReinitialize={true}
          initialValues={ initialValues}
          validate={validate(validationSchema)}
          validateOnChange={false}
          validateOnBlur={false}
          // onSubmit={registerClicked}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            sendClicked();
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setTouched,
            handleReset,
          }) => ( */}
            <form
              // name="contactUs"
              // onSubmit={handleSubmit}
              // onReset={handleReset}
              // noValidate
              // autoComplete="off"
            >
              <div className="form-group mb-2 w-100">
                <input
                  type="name"
                  name="name"
                  // valid={!errors.name}
                  // invalid={touched.name && !!errors.name}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                  // onBlur={handleBlur}
                  // value={formData.name}
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Type your name *"
                />
                <FormFeedback
                  style={{
                    display:
                      validationErrors.name === "" || validationErrors.name === undefined
                        ? "none"
                        : "block",
                        marginBottom: '0'
                  }}
                  className="red"
                >
                  {validationErrors.name}
                </FormFeedback>
              </div>
              <div className="form-group mb-2 w-100">
                <input
                  type="emailId"
                  name="emailId"
                  // valid={!errors.emailId}
                  // invalid={touched.emailId && !!errors.emailId}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                  // onBlur={handleBlur}
                  // value={formData.emailId}
                  id="emailId"
                  aria-describedby="emailHelp"
                  placeholder="Type you email address *"
                />
                <FormFeedback
                  style={{
                    display:
                    validationErrors.emailId === "" || validationErrors.emailId === undefined

                        ? "none"
                        : "block",
                        marginBottom: '0'

                  }}
                  className="red"
                >
                  {validationErrors.emailId}
                </FormFeedback>
              </div>
              <div className="form-group mb-2 w-100">
                <input
                  type="text"
                  name="mobileNo"
                  // valid={!errors.mobileNo}
                  // invalid={touched.mobileNo && !!errors.mobileNo}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                  // onBlur={handleBlur}
                  // value={formData.mobileNo}
                  id="mobileNo"
                  aria-describedby="emailHelp"
                  placeholder="Type your contact number"
                />
                <FormFeedback
                  style={{
                    display:
                    validationErrors.mobileNo === "" ||
                    validationErrors.mobileNo === undefined
                        ? "none"
                        : "block",
                        marginBottom: '0'

                  }}
                  className="red"
                >
                  {validationErrors.mobileNo}
                </FormFeedback>
              </div>
              <div className="form-group mb-1 w-100">
                <textarea
                  name="content"
                  // valid={!errors.content}
                  // invalid={touched.content && !!errors.content}
                  onChange={(e) => {
                    // handleChange(e);
                    dataChange(e);
                  }}
                  // onBlur={handleBlur}
                  // value={formData.content}
                  placeholder="Describe your issue *"
                  id="content"
                  rows="3"
                ></textarea>
                <FormFeedback
                  style={{
                    display:
                    validationErrors.content === "" ||
                    validationErrors.content === undefined
                        ? "none"
                        : "block",
                        marginBottom: '0'

                  }}
                  className="red"
                >
                  {validationErrors.content}
                </FormFeedback>
              </div>
              <button
                onClick={(e) => sendClicked(e)}
                type="submit"
                className="w-50 btn-custom-gradient shadow-sm btn btn-md text-uppercase mt-2"
              >
                Send
              </button>
            </form>
          {/* )}
        /> */}
      </div>
      <Toaster
  position="top-center"
  reverseOrder={false}
/>
{isLoading===true && <Loader/>}
    </>
  )
}

export default ContactUsForm
