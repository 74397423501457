import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import TermsAndConditionHeroBg from "../../assets/img/termsncondition_bg.jpg";
import Footer from "../Footer/Footer";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import { useHistory } from "react-router-dom";
import CtaBanner from "../Common/CtaBanner";
import ContactUsForm from "../Common/ContactUsForm";
import useScrollToTop from "../custom_hooks/useScrollToTop";

const AccordionSelectionItem = (props) => {
  useScrollToTop()
  const contentEl = useRef();
  const { handleToggle, active, TermsNCondition } = props;
  const { header, id, text } = TermsNCondition;
  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">{text}</div>
      </div>
    </div>
  );
};

const TermsAndCondition = () => {
  const [selectionActive, setSelectionActive] = useState(null);
  const TermsAndConditions = [
    {
      id: 1,
      header: "VALIDITY OF GENERAL TERMS AND CONDITIONS",
      text: (
        <>
          <p>
            The following Terms and Conditions shall apply to all services
            rendered by GKT Telecom Ltd., hereafter referred to as GKT eSIM, in
            connection with the prepaid eSIM reselling.
            <br />
            <br />
            The following Terms and Conditions are provided on the website
            <a className="pl-2" href="https://esim-mobile.co.uk/">
              https://esim-mobile.co.uk/.
            </a>
            <br />
            <br />
            GKT eSIM may accept variant clauses only in the case of an explicit
            written agreement.
            <br />
            <br />
            <span
              style={{ fontSize: "16px" }}
              className="font-weight-bold mb-2"
            >
              1.1 Definitions
            </span>
            <br />
            <span className="font-weight-bold">‘eSim’</span> – Embedded
            Subscriber Identity Module issued to the Customer to use the network
            without having to use a physical SIM.
            <br />
            <span className="font-weight-bold">‘Network-unlocked’</span>
            – The customer’s device is not a locked device to a specific network
            <br />
            <span className="font-weight-bold">‘Prepaid’</span> - Pay for in
            advance
            <br />
            <span className="font-weight-bold">‘Customer’</span>
            - a user of the Services
            <br />
            <span className="font-weight-bold">‘Activation Policy’</span>
            – The user has the option to activate the eSIM within the first 6
            months from the date of purchase. The validity period of the plan
            commences when the eSIM
            <br />
            <span className="font-weight-bold">‘Contract’</span>– You have the
            legal capacity to enter into contracts within the jurisdiction of
            the United Kingdom and your current place of residence. We will
            guarantee that our Telecommunications Service Providers (TSPs) are
            only enlisted through a written contract that enforces processing
            terms that are essentially no less protective of the Covered User
            Data than those outlined in the Terms of Service.
          </p>
        </>
      ),
    },
    {
      id: 2,
      header: "DESCRIPTION USE OF SERVICES",
      text: (
        <>
          <ol className="pl-4">
            <li className="font-weight-bold">
              eSIM RESELLING
              <p className="font-weight-normal">
                GKT eSIM resells prepaid eSIMs.
                <br />
                The Customer registers and buys its sim cards on{" "}
                <a href="https://esim-mobile.co.uk/">
                  https://esim-mobile.co.uk/
                </a>{" "}
                website and/or GKT eSIM App. Our payments are operated by
                Pay360.
              </p>
            </li>
            <br />
            <li className="font-weight-bold">
              REGISTRATION FOR USING GKT eSIM SERVICES
              <p>
                The Customer must accept the General Terms and Conditions to use
                GKT eSIM services. The client gives directly or by the
                intermediary of the service provider (Hotel, Travel Agency…) on
                the web browser under{" "}
                <a href="https://esim-mobile.co.uk/">
                  https://esim-mobile.co.uk/
                </a>{" "}
                the following information: First Name, Last Name, Address
                (billing address), Email address. The customer bears full
                responsibility for keeping their account and SIM secure codes
                confidential.
                <br />
                Eligibility, the Customer must be at least 18 years old to use
                the Services
              </p>
            </li>
            <br />
            <li className="font-weight-bold">
              GKT eSIM ENGAGEMENTS
              <p>
                GKT eSIM shall use reasonable endeavours to provide Customer
                quality service. However, GKT eSIM does not guarantee that the
                service will not be interrupted, provided on time, and be safe
                or fault-free.
                <br />
                Subject to the terms of this Agreement, the Company grants the
                Customer a personal, revocable, non-exclusive,
                non-sub-licensable, and non-transferable license to use the
                Services. The Customer is not allowed to reverse engineer,
                disassemble, or de-compile the SIM card or Services, nor resell,
                sublicense, or use them for data processing services to third
                parties. The Customer must not share or disclose the SIM card or
                Services with anyone else. Export and import laws apply to the
                Customer's use of the SIM card and Services, and the Customer
                agrees not to violate these laws or use them for prohibited
                purposes.
              </p>
            </li>
            <br />
            <li className="font-weight-bold">
              CUSTOMER ENGAGEMENTS
              <p>
                In using the Equipment or Services provided by GKT eSIM, the
                Customer must not engage in any action: that is abusive,
                illegal, or fraudulent; that causes the Network to be impaired
                or damaged. When the Customer breaches its obligations under
                Sec. 2.4, GKT eSIM may suspend the Customer's use of the
                Service. During any period of suspension, the Customer shall
                continue to pay all Charges due under this Agreement in respect
                of the suspended Services.
              </p>
            </li>
            <br />

            <li className="font-weight-bold">
              UNAUTHORIZED USE OF SIM CARD
              <p>
                If the Customer's eSIM card, used for accessing the Services, is
                lost, stolen, or used without permission to access their Account
                or the Services, they must promptly inform the Company. The
                Customer remains responsible for all charges on their Account
                until the Company can suspend the Services.
              </p>
            </li>
            <br />

            <li className="font-weight-bold">
              DEVICE COMPATIBILITY
              <p>
                The Customer is responsible for ensuring that their device is
                eSIM compatible and network-unlocked. Device compatibility may
                depend on the carrier and country of origin, the Customer must
                check the list of eSIM-compatible devices provided at the
                checkout.
                <br />
                By checking the box that confirms that the Customer's device is
                eSIM compatible, the Customer is then held responsible for the
                accuracy of the information they provide.
                <br />
                The eSIM compatibility list is not exhaustive, meaning newly
                announced eSIM-compatible devices may still need to be added.
              </p>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 3,
      header: "START, DURATION, AND TERMINATION OF THE CONTRACT",
      text: (
        <>
          <p>
            The service contract between GKT eSIM and the Customer begins upon
            completing the order at the GKT eSIM website{" "}
            <a href="https://esim-mobile.co.uk/">https://esim-mobile.co.uk/</a>{" "}
            or via the GKT eSIM app.
            <br />
            The Activation of the eSIM and acknowledgment of the Activation
            Policy is the Customer's responsibility.
            <br />
            The contract will be terminated if the Customer does not have an
            active data package or has deleted the eSIM from the target device.
          </p>
        </>
      ),
    },
    {
      id: 4,
      header: "CHARGES AND PAYMENT",
      text: (
        <>
          <p>
            The Company’s rates and charges are listed in the Rates of Services
            and are available on the Website. The rates are based on per data
            bundle charge, the volume of traffic (megabytes).Volume-based
            tariffs and rounded up in 1 MB increments at the end of each
            session. 5 day tariff Service availability time starts at the time
            of activation of the Service and ends in 5 times 24 hours. All times
            are local for a place of Service usage. The Customer is responsible
            for checking the applicable rate, before using the Services.
            <br />
            The rates and charges may be varied by GKT from time to time. The
            Customer can obtain the current rate of fees and charges by checking
            GKT eSIM website https://esim-mobile.co.uk/ or via the GKT eSIM app
            at any time.
          </p>
          <br />
          <ol className="pl-4">
            <li className="font-weight-bold">
              PAYMENT CONDITIONS
              <p>
                GKT eSIM resells prepaid eSIMs.
                <br />
                The supported payment methods for GKT eSIM services are
                Credit/Debit Card, Google Pay, Apple Pay.
                <br />
                The currency of payment is in GBP(£).
                <br />
                The credit card transaction will be processed and secured by GKT
                eSIM providers Pay360 (
                <a href="https://portal.pay360.com/">
                  https://portal.pay360.com/
                </a>
                ).
              </p>
            </li>
            <br />

            <li className="font-weight-bold">
              CHARGES FOR USE
              <ol className="pl-3 font-weight-normal">
                <li>
                  GKT eSIM states all Charges are inclusive of VAT unless
                  otherwise specified.
                </li>
                <br />

                <li>
                  The Customer shall not be entitled to offset any of its claims
                  against claims of GKT eSIM, except where the Customer's claims
                  are undisputed or have been confirmed by final court judgment.
                </li>
                <br />

                <li>
                  When Service usage results in a charge to the Customer’s
                  Account, the Company will initially deduct from any existing
                  bonus, promotional, or other credits in the Customer’s
                  Account. After exhausting these credits, the Company will then
                  deduct from any available balance purchased using the
                  Customer’s payment methods (credit or debit card, etc.).
                </li>
                <br />

                <li>
                  The expiration date of the plan aligns with the plan purchase
                  and its validity. The validity period of the plan begins when
                  the eSIM connects to any supported network(s).
                </li>
              </ol>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 5,
      header: "DELIVERY",
      text: (
        <>
          <p>
            The Customer will immediately see the purchased eSIM under the "My
            eSIMs" tab on the GKT eSIM website{" "}
            <a href="https://esim-mobile.co.uk/">https://esim-mobile.co.uk/</a>{" "}
            and/or the GKT eSIM app. The Customer will receive a confirmation
            email after the purchase. All the information for installing the
            eSIM will be available only on the user's GKT eSIM account.
          </p>
        </>
      ),
    },
    {
      id: 6,
      header: "REFUND / CANCELLATION / MODIFICATION POLICY",
      text: (
        <>
          <p>
            The Customer has the right to ask for a refund or eSIM-replacement
            if the eSIM cannot be installed and used due to a technical problem
            from GKT eSIM.
          </p>
          <br />
          <ol className="pl-4">
            <li className="font-weight-bold mb-2">
              REFUNDS AND CANCELLATION
              <p>
                6.1.1. POLICIES AND GUIDELINES
                <br />
                <br />
                6.1.2. A refund request can be made within thirty (30) days from
                the date of purchase when the activation is no longer possible
                following extensive collaborative troubleshooting.
                <br />
                <br />
                6.1.3. Cooperation with the Customer to resolve the issue
                promptly is required for a refund to be granted
                <br />
                <br />
                6.1.4. Each data package has its own validity period. No refund
                of any form will be offered for the remaining data when the
                validity period expires.
                <br />
                <br />
                6.1.5. Compensation: No refund or remuneration of any kind will
                be issued due to charges from alternate phones, alternate SIM
                cards, alternate providers, hotel phones, or other charges that
                are not directly linked to the Customer's GKT eSIM eSIM account.
                (See section 7. LIABILITY AND WARRANTY in our Terms and
                Conditions)
                <br />
                <br />
                6.1.6. Fraudulent purchases: GKT eSIM reserves the right to
                refuse any form of refund if there is evidence of abuse,
                violation of our Terms and Conditions, or any fraudulent
                activity connected with using GKT eSIM products and services.
                <br />
                <br />
                6.1.7.Unauthorized purchases: The case will be subject to
                investigation and approval before processing any refund. GKT
                eSIM reserves the right to suspend any account associated with
                fraudulent activity.
                <br />
                <br />
                6.1.8. Accidental purchases: Once Customers install the eSIM, it
                will be considered used. No refunds will be offered.
                <br />
                <br />
                6.1.9. Incorrect charges: If the Customer reasonably and in good
                faith disputes an invoice or part of it, the Customer shall
                notify GKT eSIM of such a dispute within twelve (12) days of
                receipt of the invoice, providing details of why the invoiced
                amount is incorrect and, if possible, how much the Customer
                considers due. (See details in section 4.2 CHARGES FOR USE under
                Terms and Conditions)
                <br />
                <br />
                6.1.10. Replacements: eSIMs acquired exclusively through the GKT
                eSIM web (https://esim-mobile.co.uk/ ) or GKT eSIM app are
                eligible for replacement in the event of non-usage or technical
                faults, and such replacements can be processed within 30 days
                from the date of purchase.
                <br />
                <br />
                6.1.11. Other Reasons: If the refund request is not within the
                above, we will investigate the request on a case-by-case basis.
                If the refund is approved, a processing fee may apply. The
                maximum refund of credit a Customer can apply for must be equal
                to or less than the total amount they paid.
                <br />
                <br />
                6.1.12. REFUND PROCESS
                <br />
                To request a refund, contact GKT eSIM's support team via the
                Contact Us page or send a message to help@esim-mobile.co.uk.
                Please be aware that our refund policy above will apply.
                <br />
                Depending on the nature of the issue, Customers will be asked
                for further information to support their refund request, such as
                screenshots of the device settings for technical issues or
                details of why the invoiced amount is incorrect and, if
                possible, how much the Customer considers is due, etc.
                <br />
                Please refer to section 6.1.2 in this same document for refunds
                related to technical issues.
                <br />
                Customers will have the option to credit back via their original
                payment method or issued to their account.
                <br />
                Refund to the original method: Once a refund is approved and
                issued, it can take up to thirty (30) business days to appear on
                a statement depending on the bank.
              </p>
            </li>

            <li className="font-weight-bold">
              MODIFICATION
              <p>
                The eSIM data packages from GKT eSIM are offered as-is, and no
                further modifications or customization can be made based on
                individual requests once purchased.
              </p>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 7,
      header: "LIABILITY AND WARRANTY",
      text: (
        <>
          <p>
            GKT eSIM is not responsible for detriments arising due to the
            proposed service not being constantly available. GKT eSIM provides
            no guarantee of constant availability of the network service.
          </p>
        </>
      ),
    },
    {
      id: 8,
      header: "CUSTOMER SERVICE AND SUPPORT",
      text: (
        <>
          <p>
            For any request, please write an email to help@esim-mobile.co.uk.
            <br />
            Customer Support operates within standard business hours Central
            European Time, and the response time can range from 24 to 72 hours,
            depending on the nature of the query.
          </p>
        </>
      ),
    },
    {
      id: 9,
      header: "ACCEPTABLE USE POLICY (AUP)",
      text: (
        <>
          <p>
            The Acceptable Use Policy (AUP) outlines the permissible and
            prohibited uses of our connectivity services. Specifically tailored
            for traveler's and mobile business professionals, our Unlimited Data
            Plans offer dependable access to applications and communication
            while roaming. However, these plans are not designed for
            high-frequency or intensive data usage, automated or machine-based
            activities, or large file transfers.
            <br />
            Our AUP establishes the guidelines for acceptable internet usage
            when connected to our network. It strictly prohibits activities such
            as SPAM transmission, posting offensive or illegal content, and
            interference with other computer networks.
            <br />
            <br />
            <ol className="pl-4">
              <li className="font-weight-bold">
                Fair use policy
                <p>
                  Customers must not use the GTK eSim service for any
                  machine-to-machine data application Fair use policy as
                  follows: Unlimited data is offered with daily high-speed
                  allocations ranging from 1GB to 5GB. After reaching this
                  limit, the speed reduces to 1 Mbps. Plans are categorized as
                  Mini (1GB), Standard (2GB), Enhance (3GB), and Premium (5GB)
                  maximum per day.
                </p>
              </li>
            </ol>
          </p>
        </>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleTnCSelectionToggle = (index) => {
    if (selectionActive === index) {
      setSelectionActive(null);
    } else {
      setSelectionActive(index);
    }
  };

   // google analytics

   useEffect(() => {
    document.title = "Terms and Conditions";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div style={{ position: "relative" }} className="about-us-section">
      <header>
        <Header />
      </header>
      <section className="about-us-container container mt-5">
        <div className="row m-0">
          <div className="col-12">
            <h1 style={{ lineHeight: "1" }} className="mb-2">
              Terms & Conditions
            </h1>
            <p>GENERAL TERMS AND CONDITIONS</p>
          </div>
          <div className="card">
            <div className="card-body p-2">
              {TermsAndConditions.map((TermsNCondition, index) => {
                return (
                  <AccordionSelectionItem
                    key={index}
                    active={selectionActive}
                    handleToggle={handleTnCSelectionToggle}
                    TermsNCondition={TermsNCondition}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <CtaBanner />
      <img
        className="ref-right-grad-3"
        src={refRightGrad3}
        alt="refRightGrad1"
      />
      <img className="leftBottomImg" src={leftBottomImg} alt="" />
      <ContactUsForm />
      <footer className="mt-3">
        <Footer />
      </footer>
    </div>
  );
};

export default TermsAndCondition;
