import React, { useState, useEffect, useRef } from "react";
import "../../scss/kiosk.scss";
import Header from "./components/Header";
import BackButton from "./components/BackButton";
import CustomModal from "./components/CustomModal";
import scanImg from "../../assets/img/QRScanimg.png";
import attentionpayment from "../../assets/img/icon-park-outline_caution.png";
import paymentSuccess from "../../assets/img/paymentsuccessIcon.png";
import exitIcon from "../../assets/img/exiticon.png";
import cardImg from "../../assets/img/card-payment.png";
import pendingpaymentIcon from "../../assets/img/caution.png";
import paymentfailcrossIcon from "../../assets/img/popup-cross.png";
import { useHistory } from "react-router-dom";
import { RxDropdownMenu } from "react-icons/rx";
import { SlClose } from "react-icons/sl";
import creditCardChip from "../../assets/img/credit-card-chip.png";
import amexLogo from "../../assets/img/logos_amex.png";
import visaLogo from "../../assets/img/logos_visaelectron.png";
import masterCardLogo from "../../assets/img/logos_mastercard.png";
import gliterStar from "../../assets/img/gliter.png";
import dummyCreditCard from "../../assets/img/dummy-credit-card.png";
import KioskBgElement from "./components/KioskBgElement";
import { BiExit } from "react-icons/bi";
import QRCode from "qrcode.react";
const cardType = {
  amex: amexLogo,
  visa: visaLogo,
  master: masterCardLogo,
};

const KioskPaymentSuccess = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [showScanQrCodeDiv, setShowScanQrCodeDiv] = useState(false);
  const [showApplyBtnDiv, setShowApplyBtnDiv] = useState(true);

  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const history = useHistory();

  const esimDetailsRedirect = () => {
    if (orderDetails !== null) {
      if (
        orderDetails.orderPlan[0].esim[0] !== undefined &&
        orderDetails.orderPlan[0].esim[0].esimPlan.plan.planIdEncrypted
      ) {
        const esimId = orderDetails.orderPlan[0].esim[0].esimIdEncrypted;
        const planId =
          orderDetails.orderPlan[0].esim[0].esimPlan.plan.planIdEncrypted;
        history.push(`/esimdetails/${esimId}/${planId}`);
      }
    }
  };

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    if(!kioskDetails || kioskDetails.kioskId<=0){
      //not logged in
      history.push("/kiosk/login");
      return;
    }
    const storedOrderDetails = localStorage.getItem("orderSummaryAfterPayment");
    if (storedOrderDetails) {
      const parsedOrderDetails = JSON.parse(storedOrderDetails);
      setOrderDetails(parsedOrderDetails);
    }
  }, []);
  // console.log("orderDetails",orderDetails);

  const coverageDropdownRef = useRef(null);

  const openCoverageDropdown = () => {
    document.body.style.overflowY = "hidden";
    setIsCoverageDropdownOpen(true);
  };

  const closeCoverageDropdown = () => {
    document.body.style.overflowY = "auto";
    setIsCoverageDropdownOpen(false);
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  const EmailId = orderDetails !== null && orderDetails.customer.email;

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const CoverageDropdown = () => {
    // let coverageData = {  }
    let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (orderDetails !== null) {
        setCountry(orderDetails.orderPlan[0].plan.planCountry);
      }
    }, []);
    
    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          <h4>
            Coverage is provided by{" "}
            <span>{orderDetails.orderPlan[0].plan.planCountry.length}</span>{" "}
            countries
          </h4>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2">
                          <p>{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const goToHome = () => {
    history.push("/kiosk");
  };

  const retryPayment = () => {
    if (orderDetails !== null) {
      const link = orderDetails.payment.link;
      localStorage.setItem("QRLink", link);
      if (link) {
        history.push("/kiosk/payment"); // Redirect to the link in the same tab
      }
    }
  };

  const handleClick = () => {};
  const handleClose = () => {
    setOpen(false);
    setShowApplyBtnDiv(true); // Show part1 div when modal is closed
  };

  const handleOpen = () => {
    setOpen(true);
    setShowApplyBtnDiv(true); // Hide part1 div when modal is opened
  };
  const handleModalClick = () => {
    setShowScanQrCodeDiv(!showScanQrCodeDiv); // Toggle the visibility of the main page div
    setShowApplyBtnDiv(false); // Hide part1 div
    setOpen(false); // Close the modal when Buy button is clicked
  };

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  return (
    <div className="kiosk-app">
      <Header />
      {orderDetails !== null && (
        // orderDetails.orderStatus.orderStatusId === 3 || orderDetails.orderStatus.orderStatusId === 2 || orderDetails.orderStatus.orderStatusId === 4 &&
        // orderDetails.amount === 0 ? (
        <section className="w-100 m-auto kiosk-container kiosk-checkout px-4">
        
        {orderDetails !== null &&
        orderDetails.amount === 0 && 
            orderDetails.orderStatus.orderStatusId === 4 && (
              <div className="mt-3">
                <div className="align-items-center">
                <img
                    className="mr-1 ml-2"
                    alt="payment success"
                    style={{ width: "85px" }}
                    src={pendingpaymentIcon}
                  />
                  <h1 className="kiosk-title mb-3">Thank you for your order, it is pending!</h1>
                </div>
                <div className="row m-0 pr-3 Checkout-kiosk">
                <div className="w-100 d-flex flex-column justify-content-center align-items-start mt-3 kioskPaymentTopBox">
                    <p>
                      Your order is now being processed.<br></br>Please allow a
                      little time for our team to complete the necessary checks
                      and activate your eSIM. You will receive a confirmation
                      email with further details shortly. If you have any
                      questions or need assistance, feel free to reach out to
                      our customer support team.<br></br>
                      <span className="font-weight-bold text-white">
                        We appreciate your business and look forward to
                        providing you with a seamless eSIM experience!
                      </span>
                    </p>
                  </div>
                  <div className="col-12 col-lg-6 py-2 pr-2 pl-0 mt-4 px-4 mx-auto">
                    {/* Do map from here */}
                    <ul className="list-group Kiosk-payment">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Order summary
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order ID
                        <span className="badge">
                          {" "}
                          GKT-{orderDetails.orderId}{" "}
                        </span>
                      </li>
                      {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                        ICCID
                        <span className="badge text-end">
                          {orderDetails.orderPlan[0].esim[0].iccid}
                        </span>
                      </li> */}
                    </ul>
                    <ul className="list-group Kiosk-payment mt-3">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Plan details
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Coverage
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.planType
                            .planTypeId === 2 ||
                          orderDetails.orderPlan[0].plan.planType.planTypeId ===
                            3 ? (
                            <div
                              className="order-value order-popup-link"
                              style={{ position: "relative" }}
                            >
                              <div
                                className={`${
                                  isCoverageDropdownOpen
                                    ? "order-popup-active"
                                    : ""
                                }`}
                                onClick={openCoverageDropdown}
                              >
                                <span>
                                  {
                                    orderDetails.orderPlan[0].plan.planCountry
                                      .length
                                  }{" "}
                                  Countries
                                </span>
                                <RxDropdownMenu />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="order-value"
                              style={{ position: "relative" }}
                            >
                              {orderDetails.orderPlan[0].plan.planCountry[0]
                                .planCountryNetwork !== null ? (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}{" "}
                                  -{" "}
                                  {orderDetails.orderPlan[0].plan.planCountry[0].planCountryNetwork
                                    .map((network) => network)
                                    .join(", ")}
                                </span>
                              ) : (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}
                                </span>
                              )}
                            </div>
                          )}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order type
                        <span className="badge">New eSIM</span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Data
                        <span className="badge">
                          {" "}
                          {orderDetails.orderPlan[0].plan.data}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Validity
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.validity} Days
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Total
                        <span className="badge">£ {orderDetails.amount}</span>
                      </li>
                    </ul>
                  </div>

                  
                </div>
                {/* <div className="my-4">
                  <button
                    style={{width: 340}}
                    // "w-100 kiosk-btn-lg kiosk-grad-btn btn mb-4 px-3
                    className="explore text-uppercase kiosk-grad-btn btn py-3 px-5"
                    onClick={() => history.push("/kiosk/quickInstallation")}
                  >
                    Quick Installation
                  </button>
                </div> */}
                {/* <div className="d-flex justify-content-center mt-4">
                  <div>
                    <img src={attentionpayment} alt="" width={"30px"} />
                  </div>
                  <h2
                    className="kiosk-sub-title-bottomattentiontext mb-2 ml-2"
                    I
                    style={{ textAlign: "center" }}
                  >
                    Please close this window after completing your installation.
                  </h2>
                </div> */}
                <div
                  style={{ gap: 50 }}
                  className="w-100 d-flex justify-content-center align-items-center mx-auto my-3"
                >
                  <button
                    style={{width: 340}}
                    className="text-uppercase btn btn-lg btn-outline-light rounded-pill w-10 font-weight-bold py-3"
                    onClick={handleOpen}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}


          {orderDetails !== null &&
            orderDetails.orderStatus.orderStatusId === 3 && (
              <div className="mt-3">
                <div className="align-items-center">
                  <img
                    className="mr-1 ml-2"
                    alt="payment success"
                    style={{ width: "85px" }}
                    src={paymentSuccess}
                  />
                  {orderDetails.amount===0 ? 
                  <h1 className="kiosk-title mb-3">Thank you for your order.</h1>:
                  <h1 className="kiosk-title mb-3">Payment successful !</h1>}
                </div>
                <div className="row m-0 pr-3 Checkout-kiosk">
                  <div className={"col-12 col-lg-6 py-2 pr-2 pl-0 mt-4 px-4" + (orderDetails.amount === 0 ? " mx-auto" : "")}>
                    {/* Do map from here */}
                    <ul className="list-group Kiosk-payment">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Order summary
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order ID
                        <span className="badge">
                          {" "}
                          GKT-{orderDetails.orderId}{" "}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        ICCID
                        <span className="badge text-end">
                          {orderDetails.orderPlan[0].esim[0].iccid}
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group Kiosk-payment mt-3">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Plan details
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Coverage
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.planType
                            .planTypeId === 2 ||
                          orderDetails.orderPlan[0].plan.planType.planTypeId ===
                            3 ? (
                            <div
                              className="order-value order-popup-link"
                              style={{ position: "relative" }}
                            >
                              <div
                                className={`${
                                  isCoverageDropdownOpen
                                    ? "order-popup-active"
                                    : ""
                                }`}
                                onClick={openCoverageDropdown}
                              >
                                <span>
                                  {
                                    orderDetails.orderPlan[0].plan.planCountry
                                      .length
                                  }{" "}
                                  Countries
                                </span>
                                <RxDropdownMenu />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="order-value"
                              style={{ position: "relative" }}
                            >
                              {orderDetails.orderPlan[0].plan.planCountry[0]
                                .planCountryNetwork !== null ? (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}{" "}
                                  -{" "}
                                  {orderDetails.orderPlan[0].plan.planCountry[0].planCountryNetwork
                                    .map((network) => network)
                                    .join(", ")}
                                </span>
                              ) : (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}
                                </span>
                              )}
                            </div>
                          )}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order type
                        <span className="badge">New eSIM</span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Data
                        <span className="badge">
                          {" "}
                          {orderDetails.orderPlan[0].plan.data}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Validity
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.validity} Days
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Total
                        <span className="badge">£ {orderDetails.amount}</span>
                      </li>
                    </ul>
                  </div>

                   {orderDetails.amount !== 0 && 
                  <div className="col-12 col-lg-6 py-2 pr-2 pl-2 mt-4 px-4 ScanQrCodeDiv">
                    <h2 className="align-items-center kiosk-QuickInstall-title mb-3">
                      Payment details
                    </h2>
                    <div>
                      <div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ position: "relative", zIndex: "0" }}
                        >
                          <div className="kiosk-credit-card-content">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <img src={creditCardChip} alt="creditCardChip" />
                              <img src={cardType.master} alt="cardType" />
                            </div>
                            <div className="w-100">
                              <span className="credit-card-number">
                                {orderDetails.payment.cardNumber.replace(
                                  /\d{4}(?=\d)/g,
                                  "$& "
                                )}
                              </span>
                            </div>
                            <div>
                              <p className="m-0" style={{ fontSize: 25 }}>*** ***</p>
                            </div>
                          </div>
                          <img
                            style={{ margin: "8px 0", width: "430px" }}
                            src={dummyCreditCard}
                            alt=""
                          />
                        </div>
                      </div>
                      <ul className="list-group Kiosk-payment mt-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Transaction ID
                          <span className="badge">
                            {orderDetails.payment.transactionId}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Total Price
                          <span className="badge">
                            {orderDetails.currency} £{orderDetails.amount}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Billing
                          <span className="badge">Prepaid</span>
                        </li>
                      </ul>
                    </div>
                  </div>}
                </div>
                <div className="my-4">
                  <button
                    style={{width: 340}}
                    // "w-100 kiosk-btn-lg kiosk-grad-btn btn mb-4 px-3
                    className="explore text-uppercase kiosk-grad-btn btn py-3 px-5"
                    onClick={() => history.push("/kiosk/quickInstallation")}
                  >
                    Quick Installation
                  </button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <div>
                    <img src={attentionpayment} alt="" width={"30px"} />
                  </div>
                  <h2
                    className="kiosk-sub-title-bottomattentiontext mb-2 ml-2"
                    I
                    style={{ textAlign: "center" }}
                  >
                    Please close this window after completing your installation.
                  </h2>
                </div>
                <div
                  style={{ gap: 50 }}
                  className="w-100 d-flex justify-content-center align-items-center mx-auto my-3"
                >
                  <button
                    style={{width: 340}}
                    className="text-uppercase btn btn-lg btn-outline-light rounded-pill w-10 font-weight-bold py-3"
                    onClick={handleOpen}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

          {/*Payment pending */}
          {orderDetails !== null &&
            orderDetails.orderStatus.orderStatusId === 4 &&
            orderDetails.payment.paymentStatus.paymentStatusId === 2 && (
              <div className="mt-3">
                <div className="align-items-center">
                  <img
                    className="mr-1 ml-2"
                    alt="payment success"
                    style={{ width: "85px" }}
                    src={pendingpaymentIcon}
                  />
                  <h1 className="kiosk-title mb-3">
                    Thank you for your payment, it was successful!
                  </h1>
                </div>
                <div className="row m-0 pr-3 Checkout-kiosk">
                  <div className="w-100 d-flex flex-column justify-content-center align-items-start mt-3 kioskPaymentTopBox">
                    <p>
                      Your order is now being processed.<br></br>Please allow a
                      little time for our team to complete the necessary checks
                      and activate your eSIM. You will receive a confirmation
                      email with further details shortly. If you have any
                      questions or need assistance, feel free to reach out to
                      our customer support team.<br></br>
                      <span className="font-weight-bold text-white">
                        We appreciate your business and look forward to
                        providing you with a seamless eSIM experience!
                      </span>
                    </p>
                  </div>
                  <div className="col-12 col-lg-6 py-2 pr-2 pl-0 mt-4 px-4">
                    <ul className="list-group Kiosk-payment">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Order summary
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order ID
                        <span className="badge">
                          {" "}
                          GKT-{orderDetails.orderId}{" "}
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group Kiosk-payment mt-3">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Plan details
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Coverage
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.planType
                            .planTypeId === 2 ||
                          orderDetails.orderPlan[0].plan.planType.planTypeId ===
                            3 ? (
                            <div
                              className="order-value order-popup-link"
                              style={{ position: "relative" }}
                            >
                              <div
                                className={`${
                                  isCoverageDropdownOpen
                                    ? "order-popup-active"
                                    : ""
                                }`}
                                onClick={openCoverageDropdown}
                              >
                                <span>
                                  {
                                    orderDetails.orderPlan[0].plan.planCountry
                                      .length
                                  }{" "}
                                  Countries
                                </span>
                                <RxDropdownMenu />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="order-value"
                              style={{ position: "relative" }}
                            >
                              {orderDetails.orderPlan[0].plan.planCountry[0]
                                .planCountryNetwork !== null ? (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}{" "}
                                  -{" "}
                                  {orderDetails.orderPlan[0].plan.planCountry[0].planCountryNetwork
                                    .map((network) => network)
                                    .join(", ")}
                                </span>
                              ) : (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}
                                </span>
                              )}
                            </div>
                          )}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Plan type
                        <span className="badge">Data</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order type
                        <span className="badge">New eSIM</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Price
                        <span className="badge">£ {orderDetails.amount}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Data
                        <span className="badge">
                          {" "}
                          {orderDetails.orderPlan[0].plan.data}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Validity
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.validity} Days
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-6 py-2 pr-2 pl-2 mt-4 px-4 ScanQrCodeDiv">
                    <h2 className="align-items-center kiosk-QuickInstall-title mb-3">
                      Payment details
                    </h2>
                    <div>
                      <div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ position: "relative", zIndex: "0" }}
                        >
                          <div className="kiosk-credit-card-content">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <img src={creditCardChip} alt="creditCardChip" />
                              <img src={cardType.master} alt="cardType" />
                            </div>
                            <div className="w-100">
                              <span className="credit-card-number">
                                {orderDetails.payment.cardNumber.replace(
                                  /\d{4}(?=\d)/g,
                                  "$& "
                                )}
                              </span>
                            </div>
                            <div>
                              <p className="m-0" style={{ fontSize: 25 }}>*** ***</p>
                            </div>
                          </div>
                          <img
                            style={{ margin: "8px 0", width: "430px" }}
                            src={dummyCreditCard}
                            alt=""
                          />
                        </div>
                      </div>
                      <ul className="list-group Kiosk-payment mt-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Transaction ID
                          <span className="badge">
                            {orderDetails.payment.transactionId}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Total Price
                          <span className="badge">
                            {orderDetails.currency} £{orderDetails.amount}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Billing
                          <span className="badge">Prepaid</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: 500 }}
                  className="mx-auto d-flex flex-column justify-content-between align-items-center my-4"
                >
                  <button
                    className="w-100 explore text-uppercase kiosk-grad-btn btn py-3 px-5"
                    onClick={goToHome}
                  >
                    Explore more plans
                  </button>
                  <button
                  style={{fontSize: 20, fontWeight: 600, color: '#fff'}}
                    className="btn explore text-uppercase py-3 px-5 btn-outline-light rounded-pill w-100 mt-4"
                    onClick={handleOpen}
                  >
                    Close
                  </button>
                </div>
                {/* <div className="kiosk-view-more pb-3">
                </div> */}
              </div>
            )}
          {/*Payment failed */}
          {orderDetails !== null &&
            orderDetails.orderStatus.orderStatusId === 2 &&
            orderDetails.payment.paymentStatus.paymentStatusId === 3 && (
              <div className="mt-3">
                <div className="align-items-center">
                  <img
                    className="mr-1 ml-2"
                    alt="payment failed"
                    style={{ width: "85px" }}
                    src={paymentfailcrossIcon}
                  />
                  <h1 className="kiosk-title mb-3">Payment failed!</h1>
                </div>
                <div className="row m-0 pr-3 Checkout-kiosk">
                  <div className="w-100 d-flex flex-column justify-content-center align-items-start mt-3 kioskPaymentTopBox">
                    <p>
                      We're sorry, but it seems there was an issue processing
                      your payment. To ensure you can enjoy our services
                      hassle-free, Here are a few steps you can take:
                      <ul className="pl-5">
                        <li>
                          Double-check your payment details for accuracy. Verify
                          that your{" "}
                        </li>
                        <li>
                          payment method is up to date and has sufficient funds.
                        </li>
                        <li>
                          Consider trying an alternative payment method if
                          available.{" "}
                        </li>
                      </ul>
                      <span className="font-weight-bold text-white">
                        Once you've done that, please click 'Retry Payment'
                        below to complete your transaction. If you encounter any
                        further difficulties, don't hesitate to reach out to our
                        support team at help@esim-mobile.co.uk
                      </span>
                    </p>
                  </div>
                  <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-4">
                    <button
                      className="kiosk-btn-xl-retryPayment btn-custom-gradient btn mb-3"
                      onClick={retryPayment}
                    >
                      Retry payment
                    </button>
                  </div>
                  <div className="col-12 col-lg-6 py-2 pr-2 pl-0 mt-4 px-4">
                    <ul className="list-group Kiosk-payment">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Order summary
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order ID
                        <span className="badge">
                          {" "}
                          GKT-{orderDetails.orderId}{" "}
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group Kiosk-payment mt-3">
                      <h4
                        className="kiosk-title-payment mb-2"
                        style={{ textAlign: "start" }}
                      >
                        Plan details
                      </h4>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Coverage
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.planType
                            .planTypeId === 2 ||
                          orderDetails.orderPlan[0].plan.planType.planTypeId ===
                            3 ? (
                            <div
                              className="order-value order-popup-link"
                              style={{ position: "relative" }}
                            >
                              <div
                                className={`${
                                  isCoverageDropdownOpen
                                    ? "order-popup-active"
                                    : ""
                                }`}
                                onClick={openCoverageDropdown}
                              >
                                <span>
                                  {
                                    orderDetails.orderPlan[0].plan.planCountry
                                      .length
                                  }{" "}
                                  Countries
                                </span>
                                <RxDropdownMenu />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="order-value"
                              style={{ position: "relative" }}
                            >
                              {orderDetails.orderPlan[0].plan.planCountry[0]
                                .planCountryNetwork !== null ? (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}{" "}
                                  -{" "}
                                  {orderDetails.orderPlan[0].plan.planCountry[0].planCountryNetwork
                                    .map((network) => network)
                                    .join(", ")}
                                </span>
                              ) : (
                                <span>
                                  {orderDetails.orderPlan[0].plan.planCountry.map(
                                    (country) => country.countryDescription
                                  )}
                                </span>
                              )}
                            </div>
                          )}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Plan type
                        <span className="badge">Data</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Order type
                        <span className="badge">New eSIM</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Price
                        <span className="badge">£ {orderDetails.amount}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Data
                        <span className="badge">
                          {" "}
                          {orderDetails.orderPlan[0].plan.data}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Validity
                        <span className="badge">
                          {orderDetails.orderPlan[0].plan.validity} Days
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-lg-6 py-2 pr-2 pl-2 mt-4 px-4 ScanQrCodeDiv">
                    <h2 className="align-items-center kiosk-QuickInstall-title mb-3">
                      Payment details
                    </h2>
                    <div>
                      <div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ position: "relative", zIndex: "0" }}
                        >
                          <div className="credit-card-content">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <img src={creditCardChip} alt="creditCardChip" />
                              <img src={cardType.master} alt="cardType" />
                            </div>
                            <div className="w-100">
                              <span className="credit-card-number">
                                {orderDetails.payment.cardNumber}
                              </span>
                            </div>
                            <div>
                              <p className="m-0" style={{ fontSize: 25 }}>*** ***</p>
                            </div>
                          </div>
                          <img
                            style={{ margin: "8px 0", width: "430px" }}
                            src={dummyCreditCard}
                            alt=""
                          />
                        </div>
                      </div>
                      <ul className="list-group Kiosk-payment mt-3">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Transaction ID
                          <span className="badge">
                            {orderDetails.payment.transactionId}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Total Price
                          <span className="badge">
                            {orderDetails.currency} £{orderDetails.amount}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          Billing
                          <span className="badge">Prepaid</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="kiosk-view-more pb-3">
                  <button
                    className="btn btn-lg btn-outline-light rounded-pill w-10"
                    onClick={handleOpen}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
        </section>
      )}
      <CustomModal isOpen={open} onClose={handleClose} width={"normal"}>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <>
            <BiExit style={{ fontSize: "90px" }} />
            <h2 className="kiosk-title text-center">Exit </h2>
            <h3 className="kiosk-sub-title mb-3 text-center">
              Are you sure you want to exit?<br></br>
              Do you successfully installed the esim?
            </h3>
            <div
              style={{ gap: "200px" }}
              className="w-100 d-flex justify-content-center align-items-center"
            >
              <button
                onClick={goToHome}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                Yes
              </button>
              <button
                onClick={handleClose}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                No
              </button>
            </div>
          </>
        </div>
      </CustomModal>
      {isCoverageDropdownOpen ? (
        <div className="kiosk-dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}
      <KioskBgElement />
    </div>
  );
};

export default KioskPaymentSuccess;
