import axios from 'axios';
import { API_URL } from '../../../Common/Constant';
import React, { Component } from 'react';

class KioskSTWService {
    
    kisoskSTWPrizeAvailable(userId, kioskId, lastLoginDate) {
        const headers = {
            'kioskId': kioskId,
            'lastLoginDate': lastLoginDate
        };
        return axios.get(`${API_URL}/kiosk/stw/prizeAvailable/${userId}`, { headers });
    }

    kisoskSTWPlay(userId,json, kioskId, lastLoginDate) {
        const headers = {
            'kioskId': kioskId,
            'lastLoginDate': lastLoginDate
        };
        return axios.post(`${API_URL}/kiosk/stw/play/${userId}`,json, { headers });
    }
}



export default new KioskSTWService()