import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaPaperPlane } from "react-icons/fa6";
import { IoMdStopwatch } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import UserVarificationService from "../../Services/UserVarificationService";
import slideOneBgSm from "../../assets/img/carousel/advt_small_1.jpg";
import slideTwoBgSm from "../../assets/img/carousel/advt_small_2.jpg";
import slideThreeBgSm from "../../assets/img/carousel/advt_small_3.png";
import slideOneBgLg from "../../assets/kiosk/carousel/frame-1.jpg";
import slideTwoBgLg from "../../assets/kiosk/carousel/frame-2.jpg";
import slideThreeBgLg from "../../assets/kiosk/carousel/frame-3.jpg";
import signature from "../../assets/kiosk/eSIM_Signature.png";
import logo from "../../assets/img/gkt_final_logo.png";
import "../../scss/kiosk.scss";
import MicroLoader from "../Alert_Popup/MicroLoader";
import Carousel from "../Common/Carousel";
import happy from "../Kiosk/SpinTheWheel/assets/prizeImages/happyRobot.png";
import sad from "../Kiosk/SpinTheWheel/assets/prizeImages/sadRobot.png";
import AddKioskUserService from "./Services/AddKioskUserService";
import KioskSTWService from "./Services/KioskSTWService";
import STWModal from "./SpinTheWheel/STWModal";
import "./SpinTheWheel/WheelComponent.css";
import CustomModal from "./components/CustomModal";
import Header from "./components/Header";
import KioskBgElement from "./components/KioskBgElement";
import KioskService from "../../Services/KioskService";

const KioskHome = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [user, setUser] = useState();
  const [intervalId, setIntervalId] = useState(null);
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [videoSource, setVideoSource] = useState({
    videoSourceType: 0,
    videoSourceUrl: "",
    youtubeVideoId: "",
  });

  function getYoutubeVideoId(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  useEffect(() => {
    localStorage.removeItem("order");
    localStorage.removeItem("QRLink");
    localStorage.removeItem("userId");
    localStorage.removeItem("stopPos");
    localStorage.removeItem("prize");
    localStorage.removeItem("orderSummaryAfterPayment");
    localStorage.removeItem("kioskSelectedPlanToBuy");
    document.cookie =
      "googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
    if (!kioskDetails || kioskDetails.kioskId <= 0) {
      //not logged in
      history.push("/kiosk/login");
      return;
    }
    
    var youtubeVideoId ="";
    if (kioskDetails.heroPageVideoLinkUrl) {
        youtubeVideoId = getYoutubeVideoId(
        kioskDetails.heroPageVideoLinkUrl
      );
    }

    setVideoSource({
      videoSourceType: kioskDetails.heroVideoSource.videoSourceId,
      videoSourceUrl: kioskDetails.heroPageVideoLinkUrl,
      youtubeVideoId: youtubeVideoId,
    });

    // console.log(
    //   "kioskDetails.heroPageVideoLinkUrl",
    //   kioskDetails.heroPageVideoLinkUrl
    // );
  }, []);
  const [campaignId,setCampaignId] = useState();
  const spinClick=() => {
    // Check if kioskLoginDetails is present in localStorage
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
      // console.log("here1",kioskDetails);
    // If not present or invalid, take action
    if (kioskDetails ||  Object.keys(kioskDetails).length != 0) {
      // console.log("here2");
      // console.log("kioskId------------>>>>"+kioskDetails.kioskId);
      // api call
      KioskService.getActiveCampaign(kioskDetails.kioskId)
        .then((response) => {
          if(response.status == 200){
            // console.log("here3");
          setCampaignId(response.data)
          localStorage.setItem("cId",response.data)
          history.push(`/kiosk/spinTheWheel/${response.data}`);
          }
        }).catch((error) => {
          if (error.message === "Network Error") {
            // setIsLoading(false);
            setMessage("Something went wrong, Please try again later");
          } else {
            switch (error.response ? error.response.status : "") {
              
              case 406:
                localStorage.setItem("cId",0)
                setShowReward(true);
                setPrize(null);
                setConfetti(false);
                setPrizeId(-1);
                setPrizeName(null);
                setPrizeTitle(null);
                setPrizeImg(sad);
                handleClose();
                break;
              case 500:
                localStorage.setItem("cId",-1)
                setShowReward(true);
                setPrize(null);
                setConfetti(false);
                setPrizeId(-3);
                setPrizeName(null);
                setPrizeTitle(null);
                setPrizeImg(sad);
                handleClose();
                break;
              default:
                
                break;
            }
          }
        });
      // setActiveCampaign()
    } else {
     
    }
  };
  // console.log("campaingId",campaignId)
  //old video link
  // "https://res.cloudinary.com/dujuirl6p/video/upload/v1712763455/eSIM/Promo_1_tz0sjo.mp4";
  const goToPlans = () => {
    axios.get("https://ipapi.co/json/").then((response) => {
      let data = response.data;
      history.push(`/kiosk/plans/${data.country_code}/${data.country_name}`);
    });
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    // Validate email format
    if (!value) {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const addKioskUserApi = () => {
    if (!email) {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
      // If email is valid, add the user
      const emailId = email;
      // console.log("emailId=>", emailId);
      setCount(1);
      document.getElementById("email").disabled = true;
      const kioskDetails = localStorage.getItem("kioskLoginDetails")
        ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
        : undefined;
      AddKioskUserService.addKioskUser(
        emailId,
        kioskDetails.kioskId,
        kioskDetails.lastLoginDate
      )
        .then((response) => {
          if (response.status === 200) {
            setUser(response.data);
            localStorage.setItem("userId", response.data.id);
            // console.log("user=> ", response.data);
            if (response.data.isVerified == "0") {
              setCount(2);
              document.getElementById("resend").disabled = true;
              document.getElementById("resend").style.pointerEvents = "none";

              setSeconds(30);
              let totalTime = 0;
              const interval = setInterval(() => {
                // Call the API every 10 seconds
                const kioskDetails = localStorage.getItem("kioskLoginDetails")
                  ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
                  : undefined;
                AddKioskUserService.getVerificationStatus(
                  response.data.id,
                  kioskDetails.kioskId,
                  kioskDetails.lastLoginDate
                )
                  .then((response1) => {
                    if (response1.status === 200) {
                      if (response1.data === 0) {
                        // Continue calling every 10 seconds for 30 seconds
                        totalTime += 10;
                        if (totalTime >= 30) {
                          clearInterval(interval);
                          if (response1.data === 0) {
                            // console.log(response1.data);
                            setCount(3);
                            document.getElementById(
                              "resend"
                            ).style.pointerEvents = "auto";
                          }
                        }
                      } else if (response1.data === 1) {
                        // Redirect to checkout page
                        clearInterval(interval);
                        kisoskSTWPrizeAvailable(response.data.id);
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.message === "Network Error") {
                      setMessage(
                        "Something went wrong, Please try again later"
                      );
                    } else {
                      // Handle other errors
                      switch (error.response ? error.response.status : "") {
                        case 401:
                          // Handle 401 error
                          localStorage.clear();
                          localStorage.setItem("kioskSessionExpired", true);
                          history.push("/kiosk/login");
                          break;
                        case 403:
                          // Handle 403 error
                          break;
                        // Handle other error cases
                        default:
                          setMessage(
                            "Something went wrong, Please try again later"
                          );
                          break;
                      }
                    }
                  });
              }, 10000); // Repeat every 10 seconds

              setIntervalId(interval);
            } else {
              kisoskSTWPrizeAvailable(response.data.id);
            }
          }
        })
        .catch((error) => {
          // Handle error if user addition fails
          if (error.message === "Network Error") {
            setMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                // setErrorCode(401)
                localStorage.clear();
                localStorage.setItem("kioskSessionExpired", true);
                history.push("/kiosk/login");
                break;
              case 403:
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                setMessage(error.response.data.failedReason);
                break;
              case 404:
              case 406:
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setMessage(error.response.data.failedReason);
                break;
              default:
                setMessage("Unknown Error");
                break;
            }
          }
        });
    }
  };
  const [showReward, setShowReward] = useState(false);
  const [prize, setPrize] = useState("");
  const [prizeImg, setPrizeImg] = useState("");
  const [prizePos, setPrizePos] = useState(0);
  const [prizeId, setPrizeId] = useState();
  const [prizeTitle, setPrizeTitle] = useState("");
  const [prizeName, setPrizeName] = useState("");
  const [confetti, setConfetti] = useState(false);

  const kisoskSTWPrizeAvailable = (userId) => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
    KioskSTWService.kisoskSTWPrizeAvailable(
      userId,
      kioskDetails.kioskId,
      kioskDetails.lastLoginDate
    )
      .then((response) => {
        if (response.status == 200) {
          if (response.data.prizeOrder >= 0) {
            localStorage.setItem("stopPos", response.data.prizeOrder);
            localStorage.setItem("prize", JSON.stringify(response.data));
            history.push("/kiosk/spinTheWheel");
          }
          if (response.data.prizeOrder == -1) {
            setShowReward(true);
            setPrize(null);
            setConfetti(false);
            setPrizeId(-1);
            setPrizeName(null);
            setPrizeTitle(null);
            setPrizeImg(sad);
            handleClose();
          }
          if (response.data.prizeOrder == -2) {
            setShowReward(true);
            setPrize(null);
            setConfetti(false);
            setPrizeId(-2);
            setPrizeName(null);
            setPrizeTitle(null);
            setPrizeImg(happy);
            handleClose();
          }
        }
        if (response.status == 204) {
          setShowReward(true);
          setPrize(null);
          setConfetti(false);
          setPrizeId(-3);
          setPrizeName(null);
          setPrizeTitle(null);
          setPrizeImg(sad);
          handleClose();
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setShowReward(true);
          setPrize(null);
          setConfetti(false);
          setPrizeId(-3);
          setPrizeName(null);
          setPrizeTitle(null);
          setPrizeImg(sad);
          handleClose();
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
            case 500:
              setShowReward(true);
              setPrize(null);
              setConfetti(false);
              setPrizeId(-3);
              setPrizeName(null);
              setPrizeTitle(null);
              setPrizeImg(sad);
              handleClose();
              break;
            default:
              setShowReward(true);
              setPrize(null);
              setConfetti(false);
              setPrizeId(-3);
              setPrizeName(null);
              setPrizeTitle(null);
              setPrizeImg(sad);
              handleClose();
              break;
          }
        }
      });
  };
  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("kioskSelectedPlanToBuy");
    setEmailError("");
    setEmail();
    setSeconds(30); // Reset the countdown timer to 30 seconds
    clearInterval(intervalId); // Clear the interval for verification API calls
    setCount(0); // Reset the count to 0
  };

  const backClick = () => {
    // console.log("here");
    setCount(0);
    clearInterval(intervalId);
  };
  const [resendClicked, setResendClicked] = useState(false);
  const resendEmailClick = () => {
    setResendClicked(true);
    // console.log("here1");
    document.getElementById("proceed").disabled = true;
    document.getElementById("resend").style.pointerEvents = "none";
    UserVarificationService.resendVerificationLink(email).then((response) => {
      if (response.status == 200) {
        // if (response.data.isVerified == "0") {
        setCount(2);
        setSeconds(30);
        let totalTime = 0;
        const interval = setInterval(() => {
          // Call the API every 10 seconds
          const kioskDetails = localStorage.getItem("kioskLoginDetails")
            ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
            : undefined;
          AddKioskUserService.getVerificationStatus(
            localStorage.getItem("userId"),
            kioskDetails.kioskId,
            kioskDetails.lastLoginDate
          )
            .then((response1) => {
              if (response1.status === 200) {
                if (response1.data === 0) {
                  // Continue calling every 10 seconds for 30 seconds
                  totalTime += 10;
                  if (totalTime >= 30) {
                    clearInterval(interval);
                    if (response1.data === 0) {
                      // console.log(response1.data);
                      setCount(3);
                      document.getElementById("proceed").disabled = false;
                      document.getElementById("resend").style.pointerEvents =
                        "auto";
                      setResendClicked(false);
                    }
                  }
                } else if (response1.data === 1) {
                  // Redirect to checkout page
                  clearInterval(interval);
                  kisoskSTWPrizeAvailable(response.data.id);
                }
              }
            })
            .catch((error) => {
              if (error.message === "Network Error") {
                setMessage("Something went wrong, Please try again later");
              } else {
                // Handle other errors
                switch (error.response ? error.response.status : "") {
                  case 401:
                    // Handle 401 error
                    localStorage.clear();
                    localStorage.setItem("kioskSessionExpired", true);
                    history.push("/kiosk/login");
                    break;
                  case 403:
                    // Handle 403 error
                    break;
                  // Handle other error cases
                  default:
                    setMessage("Something went wrong, Please try again later");
                    break;
                }
              }
            });
        }, 10000); // Repeat every 10 seconds

        setIntervalId(interval);
        // }
      }
      // console.log("status", response.status);
    });
  };

  const proceedClick = () => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails")
      ? JSON.parse(localStorage.getItem("kioskLoginDetails"))
      : undefined;
    AddKioskUserService.getVerificationStatus(
      user.id,
      kioskDetails.kioskId,
      kioskDetails.lastLoginDate
    )
      .then((response1) => {
        if (response1.status === 200) {
          if (response1.data === 0) {
            setShowVerificationError(true);
          } else if (response1.data === 1) {
            // Redirect to checkout page
            kisoskSTWPrizeAvailable(localStorage.getItem("userId"));
          }
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Something went wrong, Please try again later");
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              // Handle 401 error
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
              // Handle 403 error
              break;
            // Handle other error cases
            default:
              setMessage("Something went wrong, Please try again later");
              break;
          }
        }
      });
  };

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  useEffect(() => {
    if (count === 2) {
      setSeconds(30); // Reset the countdown timer to 30 seconds when count becomes 2
    }
  }, [count]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  const toggleSTW = () => {
    setOpen(true);
    setCount(0);
  };

  useEffect(() => {
    showReward
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  }, [showReward]);
  const carouselData = [
    {
      id: 1,
      title: "Experience the World Without Limits! 🌐✈️",
      description:
        "GKT eSIM Roam-Free Data Plans are designed for celebrities like you, providing uninterrupted connectivity on your global adventures. Enjoy the freedom to stay connected in iconic destinations",
      largeBg: slideOneBgLg,
      smallBg: slideOneBgSm,
      // cta1: "Buy now",
      // cta2: "Learn more",
      alt: "1",
      // ctaOnefunction: goToPlans,
      // ctaTwofunction: "",
    },
    {
      id: 2,
      title: "Wherever Business Takes You! 🌍👔",
      description:
        "Stay Connected, Stay Productive! GKT eSIM offers hassle-free global connectivity for your business travels. Roam-Free Data Plans, Reliable Coverage, and Instant Activation. Elevate your business mobility!",
      largeBg: slideTwoBgLg,
      smallBg: slideTwoBgSm,
      // cta1: "Buy now",
      // cta2: "Know more",
      alt: "2",
      // ctaOnefunction: goToPlans,
      // ctaTwofunction: "",
    },
    {
      id: 3,
      title: "Earn while you learn",
      description: "Explore eSIM Potential with AI-Enhanced Sales",
      largeBg: slideThreeBgLg,
      smallBg: slideThreeBgSm,
      // cta1: "Join now",
      // cta2: (
      //   <>
      //     Watch <IoIosPlay />
      //   </>
      // ),
      alt: "3",
      // ctaOnefunction: joinNowClick,
      // ctaTwofunction: openVideoPlayer,
    },
  ];
  // console.log("resendClicked=>",resendClicked);

  return (
    <div className="kiosk-app d-flex flex-column">
      {showReward ? (
        <>
          {/* if player wins prize keep confetti true else keep false */}
          {/* to show happy or sad keep start_pos as null in <WheelComponent /> and confetti false*/}
          {/* if happy or sad showed the keep has_reset: false and restrict this function in <STWModal /> :  onClick={() => {
              setShowReward(false);
              const $ = (s) => document.querySelector(s);
              $(".reset-btn").click();
            }}
            */}
          <STWModal
            confetti={confetti}
            prize={prize}
            prizeId={prizeId}
            prizeName={prizeName}
            prizeImg={prizeImg}
            showReward={true}
            setShowReward={setShowReward}
            voucherName={null}
          />
        </>
      ) : null}
      <div>
        <Header />
        <section className="kiosk-container px-4">
          <div className="video-container position-relative m-auto">
            {videoSource.videoSourceType === 2 &&
            videoSource.videoSourceUrl.length > 0 ? (
              <video
                style={{ width: "100%", height: "100%", borderRadius: 12 }}
                autoPlay
                loop
                muted
              >
                <source src={videoSource.videoSourceUrl} type="video/mp4" />
              </video>
            ) : videoSource.videoSourceType === 1 &&
              videoSource.videoSourceUrl.length > 0 ? (
              <div className="container_">
                <iframe
                  className="responsive-iframe"
                  src={
                    videoSource.videoSourceUrl.replace("watch?v=", "embed/") +
                    "?loop=1&controls=0&autoplay=1&mute=1&playlist="+videoSource.youtubeVideoId
                  }
                  frameborder="0"
                ></iframe>
              </div>
            ) : (
              <div className="container_">
                <iframe
                  className="responsive-iframe"
                  src={
                    "https://www.youtube.com/embed/J6hwe7HBTYA?loop=1&controls=0&autoplay=1&mute=1&playlist=J6hwe7HBTYA"
                  }
                  frameborder="0"
                ></iframe>
              </div>
            )}
          </div>
          <div className="w-100 mx-auto mt-4">
            <h1 className="kiosk-title">
              Find the best local & regional eSIMs
            </h1>
            <h2 className="kiosk-sub-title mb-3">
              Search over 10,000+ eSIM data plans in 200+ countries
            </h2>
            <div className="d-flex justify-content-between align-items-center mt-3 kiosk-home-btn">
              <button
                onClick={goToPlans}
                className="notranslate kiosk-btn-xl btn-custom-gradient shadow-sm kiosk-grad-btn btn mb-3"
              >
                Buy eSIM
              </button>
              <button
                className="notranslate kiosk-btn-xl btn btn-light rounded-pill mb-3"
                onClick={() => {
                 spinClick();
                }}
              >
                Spin the wheel
              </button>
            </div>
          </div>
        </section>
        <section className="kiosk-container px-4 mt-5">
          <div>
            <div className="card carousel-card m-0 overflow-hidden position-relative">
              {/* <img
                className="carousel-image d-block d-lg-none"
                src={item.smallBg}
                alt={item.alt}
              /> */}
              <Carousel>
                {carouselData.map((item, index) => (
                    <img
                      key={index}
                      className="carousel-image"
                      src={item.largeBg}
                      alt={item.alt}
                    />
                ))}
              </Carousel>
            </div>
          </div>
        </section>
        <div className="kiosk-container px-4 mt-5">
          <img
            style={{ width: "100%", borderRadius: 12 }}
            src={signature}
            alt=""
          />
        </div>
      </div>

      <footer className="mt-auto">
        <div className="row copyright-section mt-0">
          <div className="break-line"></div>
          <div className=" col-lg-12 col-12 d-flex justify-content-center align-items-center">
            <img className="copyright-logo mr-2" src={logo} alt="" />
            <p className="copyright">
              Copyright 2022 © Gk Telecom | All rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      <KioskBgElement />
      <CustomModal
        isOpen={open}
        onClose={handleClose}
        backButton={count > 1 ? true : false}
        onBackClick={backClick}
        width={"normal"}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          {count == 0 || count == 1 ? (
            <>
              <div style={{ textAlign: "start" }}>
                <h3 className="kiosk-sub-title mb-3">
                  To continue please enter your email id
                </h3>
              </div>
              <div className="input-section my-2">
                <input
                  type="text"
                  id="email"
                  placeholder="Please enter your email id"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <FormFeedback
                style={{
                  display:
                    emailError === "" || emailError === undefined
                      ? "none"
                      : "block",
                }}
                className="error"
              >
                {emailError}
              </FormFeedback>

              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  onClick={addKioskUserApi}
                  style={{ height: "60px" }}
                  className="notranslate btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  {count == 0 ? (
                    "Continue"
                  ) : count == 1 ? (
                    <MicroLoader />
                  ) : null}
                </button>
              </div>
            </>
          ) : count == 2 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span
                    className="font-weight-bold text-white"
                    onClick={resendEmailClick}
                    id="resend"
                  >
                    Resend email
                  </span>
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div className="kiosk-timer">
                  <IoMdStopwatch />
                  <h2>{formattedTime}</h2>
                </div>
                <span className="info">
                  *Verification status checking is in progress
                </span>
              </div>
            </>
          ) : count == 3 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span
                    className="font-weight-bold text-white"
                    onClick={resendEmailClick}
                    id="resend"
                  >
                    Resend email
                  </span>
                </p>
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  id="proceed"
                  onClick={proceedClick}
                  style={{ height: "60px" }}
                  className="notranslate btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  {!resendClicked ? (
                    "Proceed"
                  ) : resendClicked ? (
                    <MicroLoader />
                  ) : null}
                </button>
                {showVerificationError === true && (
                  <span className="error mt-3">
                    *Verification hasn't been completed from your end.
                  </span>
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default KioskHome;
