import React, { useEffect, useRef, useState } from "react";
import { FaPaperPlane } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { IoMdStopwatch } from "react-icons/io";
import { useHistory } from "react-router-dom";
import RegionListService from "../../Services/RegionService";
import popularIcon from "../../assets/elements/popular_icon.png";
import premiumIcon from "../../assets/elements/premium_icon.png";
import unlimitedIcon from "../../assets/elements/unlimited_icon.png";
import Africa from "../../assets/img/mapicons/Africa.png";
import Asia from "../../assets/img/mapicons/Asia.png";
import Australia from "../../assets/img/mapicons/Australia.png";
import Caribbean from "../../assets/img/mapicons/Caribbean.png";
import Caucasus from "../../assets/img/mapicons/Caucasus.png";
import Balkans from "../../assets/img/mapicons/Balkans.png";
import MiddleEast from "../../assets/img/mapicons/MiddleEast.png";
import LatinAmerica from "../../assets/img/mapicons/LatinAmerica.png";
import "../../scss/kiosk.scss";
import MicroLoader from "../Alert_Popup/MicroLoader";
import BackButton from "./components/BackButton";
import CustomModal from "./components/CustomModal";
import Header from "./components/Header";

// import World from "../../assets/img/mapicons/World.png";
import { FormFeedback } from "reactstrap";
import UserVarificationService from "../../Services/UserVarificationService";
import AddKioskUserService from "../../views/Kiosk/Services/AddKioskUserService";

import { BsInfoCircleFill } from "react-icons/bs";
import { SlClose } from "react-icons/sl";
import World from "../../assets/img/mapicons/World.png";
import KioskPlansService from "./Services/KioskPlansService";
import KioskBgElement from "./components/KioskBgElement";
const secondPlansSection = [
  {
    data: 15,
    details: "CON-65cf9170d9a3b Europe 1GB 30 Days 31 countries",
    validity: 60,
    price: 104.99,
    type: "Popular",
    typeIcon: popularIcon,
  },
  {
    data: 20,
    details: "Random_2",
    validity: 40,
    price: 129.99,
    type: "Premium",
    typeIcon: premiumIcon,
  },
  {
    data: 15,
    details: "CON-65cf9170d9a3b Europe 1GB 30 Days 31 countries",
    validity: 60,
    price: 104.99,
    type: "Popular",
    typeIcon: popularIcon,
  },
];
// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("Error caught by error boundary:", error, errorInfo);
    // Set hasError to true
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Reload the page if an error occurs
      window.location.reload();
      return null;
    }
    return this.props.children;
  }
}

const KioskPlans = (props) => {
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [plan, setPlan] = useState([]);
  const [plansFound, setPlansFound] = useState(false);
  const [userPlans, setUserPlans] = useState([{}]);
  const [premium, setPremium] = useState([{}]);
  const [popular, setPopular] = useState([{}]);
  const [unlimitedOrOther, setUnlimitedOrOther] = useState([{}]);
  const [other, setOtherPlans] = useState([{}]);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState({});
  const [modalDropdown, setModalDropdown] = useState(false);

  const [europeItems, setEuropeItems] = useState([]);
  const [asiaItems, setAsiaItems] = useState([]);
  const [africaItems, setAfricaItems] = useState([]);
  const [americasItems, setAmericasItems] = useState([]);
  const [oceaniaItems, setOceaniaItems] = useState([]);
  const [countryBasedOnRegion, setCountrybasedOnRegion] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isValidSuggestion, setIsValidSuggestion] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [dummy, setDummy] = useState();
  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [regions, setRegions] = useState([]);

  const [placement, setPlacement] = useState("bottom");
  const targetRef = useRef(null);
  const [view, setView] = useState(false);

  // const OutlineTooltip = ({ placement, planDetails, target, ...rest }) => {
  //   // Check if planDetails is defined and is an object
  //   if (!planDetails || typeof planDetails !== 'object') {
  //     return null; // Return null if planDetails is not valid
  //   }

  //   return (
  //     <KioskTooltipBottom placement={placement} target={target}>
  //       {/* <div> */}
  //         <ul>
  //           {Object.keys(planDetails).map((key, index) => (
  //             <li key={index}>
  //               <strong>{key}:</strong> {planDetails[key]}
  //             </li>
  //           ))}
  //         </ul>
  //       {/* </div> */}
  //     </KioskTooltipBottom>
  //   );
  // };

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    if (!kioskDetails || kioskDetails.kioskId <= 0) {
      //not logged in
      history.push("/kiosk/login");
      return;
    }
    RegionListApi();
    countryList();
  }, []); // Empty dependency array ensures this runs only on component mount

  const RegionListApi = () => {
    // RegionService.
    RegionListService.regionList().then((response) => {
      setRegions(response.data);
      // setIsLoading(false)
      // setRegionId(response.data.map((rId)=>rId.regionId));
    });
  };
  const countryList = () => {
    RegionListService.countryAccordingToRegionList().then((response) => {
      // console.log(
      // "countryAccordingToRegion",
      // response.data.filter(
      // (country) => country.subRegion.region.regionDescription === "Europe"
      // ).length
      // );
      setCountrybasedOnRegion(response.data);
      setEuropeItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Europe"
        )
      );
      setAsiaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Asia"
        )
      );
      setAfricaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Africa"
        )
      );
      setAmericasItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Americas"
        )
      );
      setOceaniaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Oceania"
        )
      );
    });
  };
  const handleInputChange = (event) => {
    setActiveSuggestionIndex(0);

    const userInputValue = event.target.value.trim();
    setUserInput(userInputValue);

    if (userInputValue === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    // Convert user input to lowercase
    const userInputValueLowercase = userInputValue.toLowerCase();

    // Filter regions based on user input
    const regionSuggestions = regions.filter((region) =>
      region.regionDescription.toLowerCase().startsWith(userInputValueLowercase)
    );

    // Filter countries based on user input
    const countrySuggestions = allCountries.filter((country) =>
      country.countryDescription
        .toLowerCase()
        .startsWith(userInputValueLowercase)
    );

    // Combine region and country suggestions and set the suggestions state
    const combinedSuggestions = [
      ...regionSuggestions.map((region) => ({
        suggestionType: "region",
        description: region.regionDescription,
        regionId: region.regionId,
      })),
      ...countrySuggestions.map((country) => ({
        suggestionType: "country",
        description: country.countryDescription,
        Alpha2: country.alpha2,
      })),
    ];

    setIsValidSuggestion(combinedSuggestions.length > 0);
    setSuggestions(combinedSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionSearch = (suggestion) => {
    if (suggestion.suggestionType === "region") {
      // Handle region search using the regionId
      // Call your API here with the regionId
      history.push(
        `/kiosk/rplans/${suggestion.regionId}/${suggestion.description}`
      );
    } else if (suggestion.suggestionType === "country") {
      // Handle country search using the Alpha2
      // Call your API here with the Alpha2
      history.push(
        `/kiosk/plans/${suggestion.Alpha2}/${suggestion.description}`
      );
    }
  };

  const handleSearch = (suggestionType, regionId, Alpha2, description) => {
    if (suggestionType === "region") {
      // Handle region search using the regionId
      // Call your API here with the regionId
      history.push(`/kiosk/rplans/${regionId}/${description}`);
    } else if (suggestionType === "country") {
      // Handle country search using the Alpha2
      // Call your API here with the Alpha2
      history.push(`/kiosk/plans/${Alpha2}/${description}`);
    }
  };

  const activeSuggestionRef = useRef(null);

  const handleKeyDown = (event) => {
    const activeSuggestionElement = activeSuggestionRef.current;

    if (event.key === "ArrowDown") {
      // Move to the next suggestion when the down arrow key is pressed
      setActiveSuggestionIndex(
        (prevIndex) => (prevIndex + 1) % suggestions.length
      );
    } else if (event.key === "ArrowUp") {
      // Move to the previous suggestion when the up arrow key is pressed
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
    } else if (event.key === "Enter") {
      // Handle the Enter key press separately
      if (suggestions.length > 0 && suggestions[activeSuggestionIndex]) {
        // Set the selected suggestion before calling handleSearch
        setUserInput(suggestions[activeSuggestionIndex].description);
        setShowSuggestions(false); // Hide the suggestion list when Enter is pressed

        handleSearch(
          suggestions[activeSuggestionIndex].suggestionType,
          suggestions[activeSuggestionIndex].regionId,
          suggestions[activeSuggestionIndex].Alpha2,
          suggestions[activeSuggestionIndex].description
        );
      }
    }

    // Scroll the active suggestion into view when using the arrow keys
    if (activeSuggestionElement) {
      activeSuggestionElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };
  const allCountries = [
    ...europeItems,
    ...asiaItems,
    ...africaItems,
    ...americasItems,
    ...oceaniaItems,
  ];

  function extractNumericValue(dataString) {
    const numericValue = parseFloat(dataString); // Attempt to convert the whole string to a number
    return isNaN(numericValue) ? null : numericValue; // Return null if the conversion fails
  }
  const [randomplans, setRandomPlans] = useState();
  const [randomplans2, setRandomPlans2] = useState();
  const [otherPlansDiv, setOtherPlansDiv] = useState();
  const [showViewButton, setShowViewButton] = useState(false);
  let randomPlansDiv;
  useEffect(() => {
    // setView(false)
    if (randomplans2 && randomplans2.length > 0) {
      setView(false);
      setShowViewButton(true);
    }
  }, [randomplans2]);

  const plansAPI = () => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    KioskPlansService.counrtyPlan(props.match.params.alpha2, kioskDetails.kioskId, kioskDetails.lastLoginDate)
      .then((response) => {
        if (response.status === 200) {
          setPlan(response.data);
          // console.log("kioskPlans api response",response.data);
          setPlansFound(true);
          // console.log("plans", response.data);
          const popularPlans = response.data.slice(0, 2);
          setPopular(popularPlans);
          const premium = response.data.slice(2, 4);
          setPremium(premium);
          const unlimitedOrOther = response.data.slice(4, 6);
          setUnlimitedOrOther(unlimitedOrOther);
          const randomPlans = response.data.slice(6);
          setRandomPlans2(randomPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
  };
  // console.log("randomPlans2", randomplans2);

  const viewMoreClick = () => {
    setView(true);
    setShowViewButton(false);
  };
  const regionPlansAPI = () => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    KioskPlansService.regionPlan(props.match.params.regionId, kioskDetails.kioskId, kioskDetails.lastLoginDate)
      .then((response) => {
        if (response.status === 200) {
          setPlan(response.data);
          // console.log("kioskPlans api response",response.data);
          setPlansFound(true);
          // console.log("plans", response.data);
          const popularPlans = response.data.slice(0, 2);
          setPopular(popularPlans);
          const premium = response.data.slice(2, 4);
          setPremium(premium);
          const unlimitedOrOther = response.data.slice(4, 6);
          setUnlimitedOrOther(unlimitedOrOther);
          const randomPlans = response.data.slice(6);
          setRandomPlans2(randomPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
              // this.props.history.push(`/accessDenied`)
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");

              break;
          }
        }
      });
  };

  useEffect(() => {
    // Check if 'alpha2' is available in the route params
    if (props.match.params.alpha2) {
      plansAPI();
    }
    // Check if 'regionId' is available in the route params
    else if (props.match.params.regionId) {
      regionPlansAPI();
    }
    // Add other conditions if needed
  }, [props.match.params.alpha2, props.match.params.regionId]);

  const toggleBuy = (plan, rowIndex) => {
    setOpen(true);
    localStorage.setItem("kioskSelectedPlanToBuy", JSON.stringify(plan));
    setCount(0);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [user, setUser] = useState();
  const [intervalId, setIntervalId] = useState(null);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    // Validate email format
    if (!value) {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const addKioskUserApi = () => {
    if (!email) {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
      // If email is valid, add the user
      const emailId = email;
      // console.log("emailId=>", emailId);
      setCount(1);
      document.getElementById("email").disabled = true;
      const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
      AddKioskUserService.addKioskUser(emailId, kioskDetails.kioskId, kioskDetails.lastLoginDate)
        .then((response) => {
          if (response.status === 200) {
            setUser(response.data);
            localStorage.setItem("userId", response.data.id);
            // console.log("user=> ", response.data);
            if (response.data.isVerified == "0") {
              setCount(2);
              document.getElementById("resend").style.pointerEvents = "none";
              setSeconds(30);
              let totalTime = 0;
              const interval = setInterval(() => {
                // Call the API every 10 seconds
                const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
                AddKioskUserService.getVerificationStatus(response.data.id, kioskDetails.kioskId, kioskDetails.lastLoginDate)
                  .then((response1) => {
                    if (response1.status === 200) {
                      if (response1.data === 0) {
                        // Continue calling every 10 seconds for 30 seconds
                        totalTime += 10;
                        if (totalTime >= 30) {
                          clearInterval(interval);
                          if (response1.data === 0) {
                            // console.log(response1.data);
                            setCount(3);
                            document.getElementById(
                              "resend"
                            ).style.pointerEvents = "auto";
                          }
                        }
                      } else if (response1.data === 1) {
                        // Redirect to checkout page
                        clearInterval(interval);
                        history.push("/kiosk/checkout");
                      }
                    }
                  })
                  .catch((error) => {
                    if (error.message === "Network Error") {
                      setMessage(
                        "Something went wrong, Please try again later"
                      );
                    } else {
                      // Handle other errors
                      switch (error.response ? error.response.status : "") {
                        case 401:
                          // Handle 401 error
                          localStorage.clear();
                          localStorage.setItem("kioskSessionExpired", true);
                          history.push("/kiosk/login");
                          break;
                        case 403:
                          // Handle 403 error
                          break;
                        // Handle other error cases
                        default:
                          setMessage(
                            "Something went wrong, Please try again later"
                          );
                          break;
                      }
                    }
                  });
              }, 10000); // Repeat every 10 seconds

              setIntervalId(interval);
            } else {
              history.push("/kiosk/checkout");
            }
          }
        })
        .catch((error) => {
          // Handle error if user addition fails
          console.error("Error adding user:", error);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("kioskSelectedPlanToBuy");
    setEmailError("");
    setEmail();
    setSeconds(30); // Reset the countdown timer to 30 seconds
    clearInterval(intervalId); // Clear the interval for verification API calls
    setCount(0); // Reset the count to 0
  };

  const [statusCode, setStatusCode] = useState();
  const [verificationStatus, setVarificationStatus] = useState();

  // const handleOpenDetails = () => {
  //   setDetailsOpen(true);
  // };
  const handleClick = () => {
    history.push("/kiosk");
  };
  const handleModalClick = () => {
    setCount(count + 1);
    if (count == 3) {
      setCount(0);
    } else {
    }
  };

  useEffect(() => {
    if (count == 2) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, seconds]);

  useEffect(() => {
    if (count === 2) {
      setSeconds(30); // Reset the countdown timer to 30 seconds when count becomes 2
    }
  }, [count]);

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;
  // console.log(seconds, count);
  const [resendClicked, setResendClicked] = useState(false);
  const resendEmailClick = () => {
    setResendClicked(true);
    // console.log("here1");
    document.getElementById("proceed").disabled = true;
    document.getElementById("resend").style.pointerEvents = "none";
    UserVarificationService.resendVerificationLink(email).then((response) => {
      if (response.status == 200) {
        // if (response.data.isVerified == "0") {
        setCount(2);
        setSeconds(30);
        let totalTime = 0;
        const interval = setInterval(() => {
          // Call the API every 10 seconds
          const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
          AddKioskUserService.getVerificationStatus(
            localStorage.getItem("userId"), kioskDetails.kioskId, kioskDetails.lastLoginDate
          )
            .then((response1) => {
              if (response1.status === 200) {
                if (response1.data === 0) {
                  // Continue calling every 10 seconds for 30 seconds
                  totalTime += 10;
                  if (totalTime >= 30) {
                    clearInterval(interval);
                    if (response1.data === 0) {
                      // console.log(response1.data);
                      setCount(3);
                      document.getElementById("proceed").disabled = false;
                      document.getElementById("resend").style.pointerEvents =
                        "auto";
                      setResendClicked(false);
                    }
                  }
                } else if (response1.data === 1) {
                  // Redirect to checkout page
                  clearInterval(interval);
                  history.push("/kiosk/checkout");
                }
              }
            })
            .catch((error) => {
              if (error.message === "Network Error") {
                setMessage("Something went wrong, Please try again later");
              } else {
                // Handle other errors
                switch (error.response ? error.response.status : "") {
                  case 401:
                    // Handle 401 error
                    localStorage.clear();
                    localStorage.setItem("kioskSessionExpired", true);
                    history.push("/kiosk/login");
                    break;
                  case 403:
                    // Handle 403 error
                    break;
                  // Handle other error cases
                  default:
                    setMessage("Something went wrong, Please try again later");
                    break;
                }
              }
            });
        }, 10000); // Repeat every 10 seconds

        setIntervalId(interval);
        // }
      }
      // console.log("status", response.status);
    });
  };
  const [showVerificationError, setShowVerificationError] = useState(false);
  const proceedClick = () => {
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    AddKioskUserService.getVerificationStatus(user.id, kioskDetails.kioskId, kioskDetails.lastLoginDate)
      .then((response1) => {
        if (response1.status === 200) {
          if (response1.data === 0) {
            setShowVerificationError(true);
          } else if (response1.data === 1) {
            // Redirect to checkout page
            history.push("/kiosk/checkout");
          }
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Something went wrong, Please try again later");
        } else {
          // Handle other errors
          switch (error.response ? error.response.status : "") {
            case 401:
              // Handle 401 error
              localStorage.clear();
              localStorage.setItem("kioskSessionExpired", true);
              history.push("/kiosk/login");
              break;
            case 403:
              // Handle 403 error
              break;
            // Handle other error cases
            default:
              setMessage("Something went wrong, Please try again later");
              break;
          }
        }
      });
  };
  const backClick = () => {
    // console.log("here");
    setCount(0);
    clearInterval(intervalId);
  };

  const [regionCountry, setRegionCountry] = useState([]);
  // const [regionCountryList, setRegionCountryList] = useState([]);
  const [countryTicketIndex, setCountryTicketIndex] = useState();
  const [esimForIndex, setEsimForIndex] = useState([]);
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);

  useEffect(() => {
    if (regions != null) {
      let target = regions.map((items) => items);
      setRegionCountry(target);
    }
  }, [regions]);
  const coverageDropdownRef = useRef(null);
  const openCoverageDropdown = (index, regionId) => {
    setIsCoverageDropdownOpen(true);
    document.body.style.overflowY = "hidden";
    setCountryTicketIndex(index);
    // setEsimForIndex(esimIndex)
  };
  var regionIndex = regionCountry[countryTicketIndex];
  const closeCoverageDropdown = () => {
    setIsCoverageDropdownOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const CoverageDropdown = ({ planCountry }) => {
    // let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);
    useEffect(() => {
      const filtered = planCountry.filter(
        (item) =>
          item &&
          item.countryDescription
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, planCountry]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          <h4>
            Coverage is provided by <span>{planCountry.length}</span> countries
          </h4>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2">
                          <p>{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const handleDetailsOpen = (data) => {
    setDetailsOpen(true);
    setCurrentDetails(data);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
    setCurrentDetails({});
  };

  const openModalDropdown = (res) => {
    setModalDropdown(true);
  };
  return (
    // <ErrorBoundary>
    <div className="kiosk-app">
      <Header />
      <section className="kiosk-container px-4">
        <div className="d-flex justify-content-between align-items-center">
          <BackButton handleClick={handleClick} />
          <div
            title="United Kingdom of Great Britain and Northern Ireland"
            className="d-flex justify-content-end align-items-center kiosk-country-bar"
          >
            {props.match.params.alpha2 ? (
              <span
                className={`fflag fflag-${props.match.params.alpha2} ff-lg ff-round mr-3`}
              ></span>
            ) : (
              <span
                className={`${props.match.params.region == "Europe" ? "fflag-EU" : ""
                  } fflag ff-lg ff-round mr-3`}
              >
                {props.match.params.region != "Europe" ? (
                  <img
                    className="regionLogo"
                    src={
                      props.match.params.region == "Africa"
                        ? Africa
                        : props.match.params.region == "Asia"
                          ? Asia
                          : props.match.params.region == "Australia"
                            ? Australia
                            : props.match.params.region == "Caribbean"
                              ? Caribbean
                              : props.match.params.region == "Caucasus"
                                ? Caucasus
                                : props.match.params.region == "Balkans"
                                  ? Balkans
                                  : props.match.params.region == "Middle East"
                                    ? MiddleEast
                                    : props.match.params.region == "Latin America"
                                      ? LatinAmerica
                                      : World
                    }
                  />
                ) : (
                  ""
                )}
              </span>
            )}
            {props.match.params.country ? (
              <p className="kiosk-title text-truncate m-0">
                {props.match.params.country}
              </p>
            ) : (
              <p className="kiosk-title text-truncate m-0">
                {props.match.params.region}
              </p>
            )}
          </div>
        </div>
        <div>
          <h1 className="mb-3" style={{ fontWeight: "600" }}>
            Find the best local & regional eSIMs
          </h1>
          <div className="position-relative">
            {showSuggestions && suggestions.length > 0 && (
              <div className="kiosk-search-suggestion-section">
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className={
                        index === activeSuggestionIndex
                          ? "suggestion-active"
                          : ""
                      }
                      ref={
                        index === activeSuggestionIndex
                          ? activeSuggestionRef
                          : null
                      }
                      onClick={() => {
                        handleSuggestionSearch(suggestion);
                        setUserInput(suggestion.description);
                        setSelectedSuggestion(suggestion);
                        setSuggestions([]);
                        setShowSuggestions(false); // Hide the suggestion list when a suggestion is selected
                      }}
                    >
                      {suggestion.Alpha2 ? (
                        <span
                          className={`fflag fflag-${suggestion.Alpha2} ff-lg ff-round mr-3`}
                        ></span>
                      ) : (
                        <span
                          className={`${suggestion.description == "Europe" ? "fflag-EU" : ""
                            } fflag ff-lg ff-round mr-3`}
                        >
                          {/* {console.log(
                            suggestion.description,
                            "suggestion.description"
                          )} */}
                          {suggestion.description != "Europe" ? (
                            <img
                              className="regionLogo"
                              src={
                                suggestion.description == "Africa"
                                  ? Africa
                                  : suggestion.description == "Asia"
                                    ? Asia
                                    : suggestion.description == "Australia"
                                      ? Australia
                                      : suggestion.description == "Caribbean"
                                        ? Caribbean
                                        : suggestion.description == "Caucasus"
                                          ? Caucasus
                                          : suggestion.description == "Balkans"
                                            ? Balkans
                                            : suggestion.description == "Middle East"
                                              ? MiddleEast
                                              : suggestion.description == "Latin America"
                                                ? LatinAmerica
                                                : World
                              }
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      )}
                      {suggestion.suggestionType === "region" ? (
                        <span>{suggestion.description}</span>
                      ) : (
                        <span>{suggestion.description}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!isValidSuggestion && userInput && (
              <div className="kiosk-search-suggestion-section">
                <span
                  style={{ fontSize: "2.1875rem", fontWeight: 600 }}
                  className="py-2 ml-3"
                >
                  No result found
                </span>
              </div>
            )}

            <div className="kiosk-card w-100 rounded-pill d-flex flex-row align-items-center justify-content-start mb-3">
              <div width={42} height={42}>
                <FiSearch className="kiosk-search-icon" />
              </div>
              <input
                className="pl-2"
                placeholder="Search by country or continent"
                type="text"
                value={userInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div className="d-flex justify-content-center align-items-center">
                <button
                  onClick={() => {
                    if (suggestions[activeSuggestionIndex]?.suggestionType) {
                      handleSearch(
                        suggestions[activeSuggestionIndex]?.suggestionType,
                        suggestions[activeSuggestionIndex]?.regionId,
                        suggestions[activeSuggestionIndex]?.Alpha2,
                        suggestions[activeSuggestionIndex]?.description
                      );
                    }
                    // if (selectedSuggestion?.suggestionType) {
                    //   handleSearch(
                    //     selectedSuggestion.suggestionType,
                    //     selectedSuggestion.regionId,
                    //     selectedSuggestion.Alpha2,
                    //     selectedSuggestion.description
                    //   );
                    // }
                  }}
                >
                  {/* <div width={42} height={42}>
    <FiSearch />
  </div> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`w-100 m-auto kiosk-container kiosk-plan-container kiosk-plans px-4 ${view ? "expand-plans" : "collapse-plans"
          }`}
      >
        {/* <div className="d-flex justify-content-between align-items-center">
          <BackButton handleClick={handleClick} />
          <div
            title="United Kingdom of Great Britain and Northern Ireland"
            className="d-flex justify-content-end align-items-center kiosk-country-bar"
          >
            {props.match.params.alpha2 ? (
            <span className={`fflag fflag-${props.match.params.alpha2} ff-lg ff-round mr-3`}></span> )
            :(
              <span
              className={`${
                props.match.params.region == "Europe" ? "fflag-EU" : ""
              } fflag ff-lg ff-round`}
            >
              {props.match.params.region != "Europe" ? (
                <img
                  className="regionLogo"
                  src={
                    props.match.params.region == "Africa"
                      ? Africa
                      : props.match.params.region == "Asia"
                      ? Asia
                      : props.match.params.region == "Australia"
                      ? Australia
                      : World
                  }
                />
              ) : (
                ""
              )}
            </span>
            )}
            {props.match.params.country ? (
            <p className="kiosk-title text-truncate m-0">
              {props.match.params.country}
            </p>
            ):(
              <p className="kiosk-title text-truncate m-0">
              {props.match.params.region}
            </p>
            )}
          </div>
        </div> */}

        <div className="">
          {/* <div className="d-flex justify-content-center align-items-center">
              <h2
                className="kiosk-sub-title mb-2"
                style={{ textAlign: "start" }}
              >
                Explore limitless connectivity with our plans!
              </h2>
              <div className="ml-3">
                <img src={star} alt="" />
              </div>
            </div> */}
          <div className="d-flex flex-wrap ">
            <div id="target" ref={targetRef} className="d-flex w-100 h-auto">
              <div style={{ width: "33.33%" }} className="px-2 flex-column">
                {popular.map((item, index) => (
                  <div key={index} className="kiosk-card kiosk-card-height">
                    <div className="kiosk-golden-label d-flex align-items-center">
                      <img className="mr-1" alt="popular" src={popularIcon} />
                      <span className="d-inline-block text-truncate number">
                        Popular #{index + 1}
                      </span>
                    </div>
                    <h2 className="number mt-3 m-0 lh-1 p-0">{item.data}</h2>
                    <div className="w-100 d-flex align-items-center kiosk-sub-text">
                      <p className="number m-0 plan-detail">{item.planName}</p>
                    </div>
                    <div className="kiosk-feature-container w-100 d-flex flex-wrap justify-content-around align-items-center my-0">
                      <div className="kiosk-plan-sub-text">
                        <p className="">Validity </p>
                        <p className="number m-0 d-flex justify-content-start align-items-center">
                          <span>{item.validity}</span>
                          <span className="ml-2">Days</span>
                        </p>
                      </div>
                      <div className="line-seperator first"></div>
                      <div className="kiosk-plan-sub-text">
                        <p className="">Price</p>
                        <p className="number m-0">£ {item.price}</p>
                      </div>
                      <div className="line-seperator second"></div>
                      <div
                        onClick={() => handleDetailsOpen(item)}
                        id={`popular-${index}`}
                        className="kiosk-plan-details d-flex align-items-center mt-2"
                      >
                        <BsInfoCircleFill className="mr-2 mx-0" />
                        <p className="m-0">Plan details</p>

                        {/* <OutlineTooltip
                          placement={placement}
                          target={`popular-${index}`}
                          planDetails={{
                            "Plan Name": item.planName,
                            "Top up": item.topupAvailable == 1 ? "Available" : "Unavailable",
                            "Coverage": item.region ? (
                              <div
                                onClick={() => {
                                  openCoverageDropdown(index, item.regionId)
                                }}
                                className="countries-label-dropdown d-flex justify-content-center align-items-center"
                              >
                                {item.countrys && <span>{item.countrys.length} Countries</span>}
                                <IoMdArrowDropdown />
                              </div>
                            ) : 
                            (
                              <div>
                                {item.planCountry && item.planCountry.length > 0 ? (
                                  <span>
                                    {item.planCountry.map((country) => country.countryDescription).join(", ")}
                                    {" - "}
                                    {item.planCountry[0].planCountryNetwork ? item.planCountry[0].planCountryNetwork.join(", ") : ''}
                                  </span>
                                ) : (
                                  <span>No coverage information available</span>
                                )}
                              </div>
                            ),
                            "Maximum Data": item.data
                          }}
                        /> */}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        toggleBuy(item, index);
                      }}
                      className="btn btn-block btn-lg btn-light rounded-pill"
                    >
                      <font>BUY NOW</font>
                    </button>
                  </div>
                ))}
              </div>
              <div style={{ width: "33.33%" }} className="px-2">
                {premium.map((item, index) => (
                  <div key={index} className="kiosk-card kiosk-card-height">
                    <div className="kiosk-golden-label d-flex align-items-center">
                      <img className="mr-1" alt="popular" src={premiumIcon} />
                      <span className="d-inline-block text-truncate number">
                        Premium #{index + 1}
                      </span>
                    </div>
                    <h2 className="number mt-3 m-0 lh-1 p-0">{item.data}</h2>
                    <div className="w-100 d-flex align-items-center kiosk-sub-text">
                      <p className="number m-0 plan-detail">{item.planName}</p>
                    </div>
                    <div className="kiosk-feature-container w-100 d-flex flex-wrap justify-content-around align-items-center my-0">
                      <div className="kiosk-plan-sub-text">
                        <p className="">Validity </p>
                        <p className="number m-0 d-flex justify-content-start align-items-center">
                          <span>{item.validity}</span>
                          <span className="ml-2">Days</span>
                        </p>
                      </div>
                      <div className="line-seperator first"></div>
                      <div className="kiosk-plan-sub-text">
                        <p className="">Price</p>
                        <p className="number m-0">£ {item.price}</p>
                      </div>
                      <div className="line-seperator second"></div>
                      <div
                        onClick={() => handleDetailsOpen(item)}
                        id={`premium-${index}`}
                        className="kiosk-plan-details d-flex align-items-center mt-2"
                      >
                        <BsInfoCircleFill className="mr-2 mx-0" />
                        <p className="m-0">Plan details</p>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        toggleBuy(item, index);
                      }}
                      className="btn btn-block btn-lg btn-light rounded-pill"
                    >
                      <font>BUY NOW</font>
                    </button>
                  </div>
                ))}
              </div>
              <div style={{ width: "33.33%" }} className="px-2">
                {unlimitedOrOther.length !== 0 &&
                  unlimitedOrOther.map((item, index) => (
                    <div key={index} className="kiosk-card kiosk-card-height">
                      {item.unlimitedPlan == true && (
                        <div className="kiosk-golden-label d-flex align-items-center">
                          <img
                            className="mr-1"
                            alt="unlimited"
                            src={unlimitedIcon}
                          />
                          <span className="d-inline-block text-truncate number">
                            Unlimited #{index + 1}
                          </span>
                        </div>
                      )}
                      <h2 className="number mt-3 m-0 lh-1 p-0">{item.data}</h2>
                      <div className="w-100 d-flex align-items-center kiosk-sub-text">
                        <p className="number m-0 plan-detail">
                          {item.planName}
                        </p>
                      </div>
                      <div className="kiosk-feature-container w-100 d-flex flex-wrap justify-content-around align-items-center my-0">
                        <div className="kiosk-plan-sub-text">
                          <p className="">Validity </p>
                          <p className="number m-0 d-flex justify-content-start align-items-center">
                            <span>{item.validity}</span>
                            <span className="ml-2">Days</span>
                          </p>
                        </div>
                        <div className="line-seperator first"></div>
                        <div className="kiosk-plan-sub-text">
                          <p className="">Price</p>
                          <p className="number m-0">£ {item.price}</p>
                        </div>
                        <div className="line-seperator second"></div>
                        <div
                          onClick={() => handleDetailsOpen(item)}
                          id={`unlimited-${index}`}
                          className="kiosk-plan-details d-flex align-items-center mt-2"
                        >
                          <BsInfoCircleFill className="mr-2 mx-0" />
                          <p className="m-0">Plan details</p>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          toggleBuy(item, index);
                        }}
                        className="btn btn-block btn-lg btn-light rounded-pill"
                      >
                        <font>BUY NOW</font>
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            {randomplans2 && randomplans2.length !== 0 && (
              <div className="w-100 h-100 d-flex align-items-start flex-wrap p-0 kiosk-unknown-container">
                {randomplans2.map((item, index) => (
                  <div
                    style={{ width: "33.33%" }}
                    key={index}
                    className="kiosk-unknown-plans"
                  >
                    <div className="kiosk-card kiosk-card-height kiosk-all-plan">
                      {/* <div className="kiosk-golden-label d-flex align-items-center">
                      <img
                        className="mr-1"
                        alt="popular"
                        src={premiumIcon}
                      />
                      <span className="d-inline-block text-truncate number">
                        Premium #{index + 1}
                      </span>
                    </div> */}
                      <h2 className="mb-0 number">{item.data}</h2>
                      <div className="w-100 d-flex align-items-center kiosk-sub-text">
                        <p className="number m-0 plan-detail">
                          {item.planName}
                        </p>
                      </div>
                      <div className="w-100 d-flex align-items-center kiosk-sub-text my-0">
                        <p className="number m-0 plan-detail">{item.details}</p>
                      </div>
                      <div className="kiosk-feature-container w-100 d-flex flex-wrap justify-content-around align-items-center my-0">
                        <div className="kiosk-plan-sub-text">
                          <p className="">Validity </p>
                          <p className="number m-0">{item.validity} Days</p>
                        </div>
                        <div className="line-seperator first"></div>
                        <div className="kiosk-plan-sub-text">
                          <p className="">Price</p>
                          <p className="number m-0">£ {item.price}</p>
                        </div>
                        <div className="line-seperator second"></div>
                        <div
                          onClick={() => handleDetailsOpen(item)}
                          id={`other-2-${index}`}
                          className="kiosk-plan-details d-flex align-items-center mt-2"
                        >
                          <BsInfoCircleFill className="mr-2 mx-0" />
                          <p className="m-0">Plan details</p>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          toggleBuy(item, index);
                        }}
                        className="btn btn-block btn-lg btn-light rounded-pill"
                      >
                        <font>BUY NOW</font>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* {showViewButton === true &&
            randomplans2 &&
            randomplans2.length !== 0 && (
              <div className="kiosk-view-more">
                <button
                  className="btn btn-lg btn-outline-light rounded-pill px-4 py-3"
                  onClick={viewMoreClick}
                >
                  <font>View more plans</font>
                </button>
              </div>
            )} */}
        </div>
      </div>

      <CustomModal
        isOpen={open}
        onClose={handleClose}
        backButton={count > 1 ? true : false}
        onBackClick={backClick}
        width={"normal"}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          {count == 0 || count == 1 ? (
            <>
              <div style={{ textAlign: "start" }}>
                <h3 className="kiosk-sub-title mb-3">
                  To continue please enter your email id
                </h3>
              </div>
              <div className="input-section my-2">
                <input
                  type="text"
                  id="email"
                  placeholder="Please enter your email id"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <FormFeedback
                style={{
                  display:
                    emailError === "" || emailError === undefined
                      ? "none"
                      : "block",
                }}
                className="error"
              >
                {emailError}
              </FormFeedback>

              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  onClick={addKioskUserApi}
                  style={{ height: "60px" }}
                  className="btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  <font>
                    {count == 0 ? (
                      "Continue"
                    ) : count == 1 ? (
                      <MicroLoader />
                    ) : null}
                  </font>
                </button>
              </div>
            </>
          ) : count == 2 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span
                    className="font-weight-bold text-white"
                    onClick={resendEmailClick}
                    id="resend"
                  >
                    Resend email
                  </span>
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div className="kiosk-timer">
                  <IoMdStopwatch />
                  <h2>{formattedTime}</h2>
                </div>
                <span className="info">
                  *Verification status checking is in progress
                </span>
              </div>
            </>
          ) : count == 3 ? (
            <>
              <FaPaperPlane style={{ fontSize: "42px" }} />
              <h2 className="kiosk-title text-center">Verify email</h2>
              <h3 className="kiosk-sub-title mb-3 text-center">
                Please check your email for confirmation mail and click on
                “Verify” button to continue.
              </h3>
              <div className="kiosk-resend">
                <p className="kiosk-muted-text">
                  Did not receive email?{" "}
                  <span
                    className="font-weight-bold text-white"
                    onClick={resendEmailClick}
                    id="resend"
                  >
                    Resend email
                  </span>
                </p>
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <button
                  id="proceed"
                  onClick={proceedClick}
                  style={{ height: "60px" }}
                  className="btn btn-lg btn-light py-2 rounded-pill w-50 kiosk-cta-2 mt-3"
                >
                  <font>
                    {!resendClicked ? (
                      "Proceed"
                    ) : resendClicked ? (
                      <MicroLoader />
                    ) : null}
                  </font>
                </button>
                {showVerificationError === true && (
                  <span className="error mt-3">
                    *Verification hasn't been completed from your end.
                  </span>
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </CustomModal>

      <CustomModal
        width={900}
        isOpen={detailsOpen}
        onClose={handleDetailsClose}
      >
        <div>
          <h2 className="font-weight-bold">{currentDetails.planName}</h2>

          {/* "Plan Name": item.planName,
                            "Top up": item.topupAvailable == 1 ? "Available" : "Unavailable",
                            "Coverage": item.region ? (
                              <div
                                onClick={() => {
                                  openCoverageDropdown(index, item.regionId)
                                }}
                                className="countries-label-dropdown d-flex justify-content-center align-items-center"
                              >
                                {item.countrys && <span>{item.countrys.length} Countries</span>}
                                <IoMdArrowDropdown />
                              </div>
                            ) : 
                            (
                              <div>
                                {item.planCountry && item.planCountry.length > 0 ? (
                                  <span>
                                    {item.planCountry.map((country) => country.countryDescription).join(", ")}
                                    {" - "}
                                    {item.planCountry[0].planCountryNetwork ? item.planCountry[0].planCountryNetwork.join(", ") : ''}
                                  </span>
                                ) : (
                                  <span>No coverage information available</span>
                                )}
                              </div>
                            ),
                            "Maximum Data": item.data */}
          <ul className="m-0">
            <li className="kiosk-plan-sub-text w-100 d-flex justify-content-between align-items-center py-2">
              <p style={{ fontSize: 15 }} className="m-0">
                Plan Name
              </p>
              <p className="m-0">{currentDetails.planName}</p>
            </li>
            <li className="kiosk-plan-sub-text w-100 d-flex justify-content-between align-items-center py-2">
              <p style={{ fontSize: 15 }} className="m-0">
                Top up
              </p>
              <p className="m-0">
                {currentDetails.topupAvailable == 1
                  ? "Available"
                  : "Unavailable"}
              </p>
            </li>
            <li className="kiosk-plan-sub-text w-100 d-flex justify-content-between align-items-center py-2">
              <p style={{ fontSize: 15 }} className="m-0">
                Coverage
              </p>
              {currentDetails.region ? (
                <div
                  onClick={() => {
                    openModalDropdown(currentDetails.regionId);
                  }}
                  className="countries-label-dropdown d-flex justify-content-center align-items-center position-relative"
                >
                  {currentDetails.region ? (
                    <p
                      style={{ fontSize: 18, fontWeight: 600, color: "#fff" }}
                      className="m-0"
                    >
                      {currentDetails.region.regionDescription}
                    </p>
                  ) : (
                    <p
                      style={{ fontSize: 18, fontWeight: 600, color: "#fff" }}
                      className="m-0"
                    >
                      Country
                    </p>
                  )}

                  {/* <IoMdArrowDropdown style={{color: '#f9d056', fontSize: ''}} /> */}
                </div>
              ) : (
                <div>
                  {currentDetails.planCountry &&
                    currentDetails.planCountry.length > 0 ? (
                    <p
                      style={{ fontSize: 18, fontWeight: 600, color: "#fff" }}
                      className="m-0"
                    >
                      {currentDetails.planCountry
                        .map((country) => country.countryDescription)
                        .join(", ")}
                      {" - "}
                      {currentDetails.planCountry[0].planCountryNetwork
                        ? currentDetails.planCountry[0].planCountryNetwork.join(
                          ", "
                        )
                        : ""}
                    </p>
                  ) : (
                    <p
                      style={{ fontSize: 18, fontWeight: 600, color: "#fff" }}
                      className="m-0"
                    >
                      No coverage information available
                    </p>
                  )}
                </div>
              )}
            </li>
            <li className="kiosk-plan-sub-text w-100 d-flex justify-content-between align-items-center py-2">
              <p style={{ fontSize: 15 }} className="m-0">
                Maximum data
              </p>
              <p className="m-0">{currentDetails.data}</p>
            </li>
          </ul>
        </div>
      </CustomModal>
      {isCoverageDropdownOpen ? (
        <div className="dropdowns-modal">
          <CoverageDropdown
            planCountry={regionIndex !== undefined ? regionIndex.countrys : []}
          />
        </div>
      ) : null}
      <KioskBgElement />
    </div>
    // </ErrorBoundary>
  );
};

export default KioskPlans;
