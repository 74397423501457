import React, { useState } from 'react';

const Tooltip = ({ text, children, style }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div 
      className="tooltip-container"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && <div style={style} className="tooltip-text">{text}</div>}
    </div>
  );
};

export default Tooltip;
