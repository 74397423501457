import React, { useState, useEffect, useRef } from "react";
import "../../scss/kiosk.scss";
import Header from "./components/Header";
import { ImInfo } from "react-icons/im";
import BackButton from "./components/BackButton";
import CustomModal from "./components/CustomModal";
import discountIcon from "../../assets/img/iconamoon_discount-light.png";
import checkoutPaymentIcon from "../../assets/img/iconamoon_attention-circle-checkout.png";
import scanImg from "../../assets/img/QRScanimg.png";
import { IoIosArrowBack } from "react-icons/io";
import MicroLoader from "../Alert_Popup/MicroLoader";
import { IoMdStopwatch } from "react-icons/io";
import { FaPaperPlane } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import OrderService from "../../Services/OrderService";
import { SlClose } from "react-icons/sl";
import ApplyVoucherService from "../../Services/ApplyVoucherService";
import toast, { Toaster } from "react-hot-toast";
import { RxDropdownMenu } from "react-icons/rx";
import { FormFeedback } from "reactstrap";
// import { jwtDecode } from "jwt-decode";
import AlertPopup from "../Alert_Popup/AlertPopup";
import QRCode from "qrcode.react";
import KioskBgElement from "./components/KioskBgElement";
import KioskApplyVoucherService from "./Services/KioskApplyVoucherService";
import KioskOrderService from "./Services/KioskOrderService";

const AccordionTnc = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, TermsNCondition } = props;
  const { header, id, text } = TermsNCondition;
  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">{text}</div>
      </div>
    </div>
  );
};
const AccordionPrivacyFirst = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, PrivacyPolicy } = props;
  const { header, id, text } = PrivacyPolicy;
  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const AccordionPrivacySecond = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, PrivacyPolicy } = props;
  const { header, id, text } = PrivacyPolicy;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const AccordionPrivacyThird = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, PrivacyPolicy } = props;
  const { header, id, text } = PrivacyPolicy;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? "active" : ""}`}
          onClick={() => handleToggle(id)}
        >
          <h5 className="rc-accordion-title">{header}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};

const KioskCheckout = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  // const [seconds, setSeconds] = useState(30);
  const [showScanQrCodeDiv, setShowScanQrCodeDiv] = useState(false);
  const [showApplyBtnDiv, setShowApplyBtnDiv] = useState(true);

  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);

  const [value, onChange] = useState(new Date());
  const [isPolicyOpen, setIsPolicyOpen] = useState(true);
  const history = useHistory();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const coverageDropdownRef = useRef(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [deviceChecked, setDeviceChecked] = useState(false);
  const [message, setMessage] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [applyClick, setApplyClick] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [privacypolicy, setPrivacyPolicy] = useState(false);
  const [selectionTnCActive, setSelectionTnCActive] = useState(null);
  const [selectionOnePrivacy, setSelectionOnePrivacy] = useState(null);
  const [selectionTwoPrivacy, setSelectionTwoPrivacy] = useState(null);
  const [selectionThreePrivacy, setSelectionThreePrivacy] = useState(null);

  const PrivacyPolicy = [
    {
      id: 1,
      header: "Purpose",
      text: "The purpose of this Policy is to ensure that necessary records and documents of GK Telecom Limited are adequately protected and maintained and to ensure that records that are no longer needed by GK Telecom Limited or are of no value are discarded at the proper time. This Policy is also for the purpose of aiding employees of GK Telecom Limited in understanding their obligations in retaining electronic documents – including e-mail, Web files, text files, sound and video files, PDF documents, and all Microsoft Office or other formatted files.",
    },
    {
      id: 2,
      header: "Policy",
      text: `This Policy represents GK Telecom Limited’s policy regarding the retention and disposal of records and the retention and disposal of electronic documents.`,
    },
    {
      id: 3,
      header: "Administration",
      text: (
        <>
          {
            "Attached as Appendix A is a Record Retention Schedule that is approved as the initial maintenance, retention and disposal schedule for physical records of GK Telecom Limited and the retention and disposal of electronic documents. We will make modifications to the Record Retention Schedule from time to time to ensure that it follows National legislation and includes the appropriate document and record categories for GK Telecom Limited; monitor legislation affecting record retention; annually review the record retention and disposal program; and monitor compliance with this Policy."
          }
          <br />
          <br />

          {
            "In addition, any retained information can only be used for the purpose for which it is stored. This is compliant with the Data Protection Act 1998 and the General Data"
          }
          <br />
          <br />

          {"Protection Regulation (GDPR) (Regulation (EU) 2016/679)"}
        </>
      ),
    },
    {
      id: 4,
      header:
        "Suspension of Record Disposal in Event of Legal Proceedings or Claims",
      text: (
        <>
          {
            "There are certain occasions when information needs to be preserved beyond any limits set out in the Policy. The Policy must be SUSPENDED relating to a specific customer or document and the information retained beyond the period specified in GK Telecom Limited’s Data Retention Schedule in the following circumstances:"
          }
          <br />
          <br />
          {
            "Legal proceedings or a regulatory or similar investigation or obligation to produce information are known to be likely, threatened or actual."
          }
          <br />
          <br />
          {"A crime is suspected or detected."}
          <br />
          <br />
          {
            "Information is relevant to a company in liquidation or receivership, where a debt is due to GK Telecom Limited. Information is considered by the owning unit to be of potential historical importance and this has been confirmed by the Administrator."
          }
          <br />
          <br />
          {
            "In the case of possible or actual legal proceedings, investigations or crimes occurring, the type of information that needs to be retained relates to any that will help or harm GK Telecom Limited or the other side’s case or liability or amount involved."
          }
          <br />
          <br />
          {
            "If there is any doubt over whether legal proceedings, an investigation or a crime could occur, or what information is relevant or material in these circumstances, the Administrator should be contacted, and legal advice sought. The Administrator shall take such steps as is necessary to promptly inform all staff of any suspension in the further disposal of documents."
          }
        </>
      ),
    },
    {
      id: 5,
      header: "Security of personal information",
      text: (
        <>
          {
            "GK Telecom Limited will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information."
          }
          <br />
          <br />
          {
            "GK Telecom Limited will store all personal information on our secure (password- and firewall-protected) servers."
          }
          <br />
          <br />
          {
            "The Client should acknowledge that the transmission of information over the internet is inherently insecure, and that GK Telecom Limited cannot guarantee the security of data sent over the internet."
          }
          <br />
          <br />
          {
            "The Client will be responsible for keeping their Username and Password used for accessing GK Telecom Limited’s website confidential; GK Telecom Limited will not ask for password other than when needed to log in to our website."
          }
        </>
      ),
    },
    {
      id: 6,
      header: "Amendments",
      text: `GK Telecom Limited may update this policy from time to time by publishing a new version.

      This page should be checked occasionally to ensure that the policy remains relevant.`,
    },
    {
      id: 7,
      header: "Applicability",
      text: `This Policy applies to all physical records generated during GK Telecom Limited’s operation, including both original documents and reproductions. It also applies to the electronic documents described above.`,
    },
  ];

  const PrivacyPolicySectionTopic = [
    {
      id: 1,
      header: "ACCOUNTING AND FINANCE",
      text: (
        <>
          {"Record Type and then Retention Period"}
          {
            <ul className="pl-4">
              <li>Annual Audit Reports and Financial Statements: Permanent</li>
              <li>
                Annual Audit Records, including work papers and other documents
                that relate to the audit: 7 years after completion of audit
              </li>
              <li>Annual Plans and Budgets: 7 years</li>
              <li>Bank Statements and Cancelled Cheques: 7 years</li>
              <li>Employee Expense Reports: 7 years</li>
              <li>Interim Financial Statements: 7 years</li>
            </ul>
          }
        </>
      ),
    },
    {
      id: 2,
      header: "CONTRACTS",
      text: (
        <>
          {"Record Type and then Retention Period"} <br />
          {
            "Contracts and Related Correspondence (including any proposal that resulted in the contract and all other supportive documentation):  7 years after expiration or termination"
          }
        </>
      ),
    },
    {
      id: 3,
      header: "CORPORATE RECORDS",
      text: (
        <>
          {"Record Type and then Retention Period"} <br />
          {
            <ul className="pl-4">
              <li>
                Corporate Records (minutes, signed minutes of the Board and all
                committees, record of incorporation, articles of incorporation,
                annual corporate reports): Permanent
              </li>
              <li>Licenses and Permits: Permanent</li>
            </ul>
          }
        </>
      ),
    },
    {
      id: 4,
      header: "CORRESPONDENCE AND INTERNAL MEMORANDA",
      text: (
        <>
          {
            "General Principle: Most correspondence and internal memoranda should be retained for the same period as the document to which they pertain or support. For instance, a letter pertaining to a particular contract would be retained as long as the contract (7 years after expiration). It is recommended that records that support a particular project be kept with the project and take on the retention time of that particular project file."
          }{" "}
          <br />
          {
            "Correspondence or memoranda that do not pertain to documents having a prescribed retention period should generally be discarded sooner. These may be divided into two general categories:"
          }{" "}
          <br />
          {
            <ol className="pl-4">
              <li>
                Those pertaining to non-routine matters or having significant
                lasting consequences should generally be retained permanently.
              </li>
              <li>
                Those pertaining to routine matters and having no significant,
                lasting consequences should be discarded within five years. Some
                examples include:
              </li>
              <ul className="pl-3">
                <li>
                  Routine letters and notes that require no acknowledgment or
                  follow up, such as notes of appreciation, congratulations,
                  letters of transmittal, and plans for meetings.
                </li>
                <li>Form letters that require no follow up.</li>
                <li>
                  Letters of general inquiry and replies that complete a cycle
                  of correspondence.
                </li>
                <li>
                  Letters or complaints requesting specific action that have no
                  further value after changes are made or action taken (such as
                  name or address change).
                </li>
                <li>
                  Other letters of inconsequential subject matter or that
                  definitely close correspondence to which no further reference
                  will be necessary.
                </li>
                <li>Chronological correspondence files.</li>
                <li>
                  Please note that copies of interoffice correspondence and
                  documents where a copy will be in the originating department
                  file should be read and destroyed, unless that information
                  provides reference to or direction to other documents and must
                  be kept for project traceability.
                </li>
              </ul>
            </ol>
          }
        </>
      ),
    },
    {
      id: 5,
      header: "RETAINING PERSONAL INFORMATION",
      text: (
        <>
          <ol className="pl-4">
            <li>
              This Section sets out the data retention policies and procedure of
              GK Telecom Limited, which are designed to help ensure compliance
              with legal obligations in relation to the retention and deletion
              of personal information
            </li>
            <li>
              Personal information that is processed by GK Telecom Limited for
              any purpose or purposes shall not be kept for longer than is
              necessary for that purpose or those purposes.
            </li>
          </ol>
          {
            "Without prejudice to point 2 (above) GK Telecom Limited will usually delete personal data falling within the categories set out below at the date/time set out below:"
          }
          <br />
          {"Record Type and then Retention Period"}
          <ul className="pl-4">
            <li>
              Information about a computer and about visits to and use of this
              website (including an IP address, geographical location, browser
              type and version, operating system, referral source, length of
              visit, page views and website navigation paths: 2 years
            </li>
            <li>
              Information provided when registering with our website (including
              email address): 2 years
            </li>
            <li>
              Information provided when completing a profile on our website
              (including a name, gender, date of birth, interests and hobbies,
              educational details): 2 years
            </li>
          </ul>
          <br />
          {
            "Information provided for subscribing to email notifications and/or newsletters (including a name and email address):    Indefinitely or until the client chooses to ‘unsubscribe’"
          }
          <ul className="pl-4">
            <li>
              Information provided when using the services on the website, or
              that is generated during the use of those services (including the
              timing, frequency and pattern of service use) : Indefinitely
            </li>
            <li>
              Information relating to any subscriptions made (including name,
              address, telephone number, email address and sector sought): 2
              years or until consent is withdrawn
            </li>
            <li>
              Information posted to our website for publication on the internet:
              5 years after post
            </li>
            <li>
              Information contained in or relating to any communications sent
              through the website (including the communication content and meta
              data associated with the communication): 2 years following contact
            </li>
            <li>
              Any other personal information chosen to be sent: 2 years
              following contact
            </li>
          </ul>
          <br />
          {
            "Notwithstanding the other provisions of this Section, GK Telecom Limited will retain documents (including electronic documents) containing personal data:"
          }
          <ul className="pl-4">
            <li>
              to the extent that GK Telecom Limited is required to do so by law;
            </li>
            <li>
              if GK Telecom Limited believes that the documents may be relevant
              to any ongoing or prospective legal proceedings;
            </li>
            <li>
              and to establish, exercise or defend GK Telecom Limited’s legal
              rights (including providing information to others for the purposes
              of fraud prevention and reducing credit risk).
            </li>
            <li>
              if explicit consent is given by the data subject. Consent is
              requested at least every 2 years from candidates seeking contract
              roles and at least every 12 months for candidates seeking
              permanent employment.
            </li>
          </ul>
          {
            "Each day GK Telecom Limited will run a database backup copy of all electronic data contained on GK Telecom Limited data centre, except for the one financial package. All other databases are in the cloud, connected to a Tier 3 data centre. This backup will include all information relating to current users, as well as any information that remains due to any reason contained in this policy."
          }
        </>
      ),
    },
    {
      id: 6,
      header: "ELECTRONIC DOCUMENTS",
      text: (
        <>
          {
            <ol className="pl-4">
              <li>
                Electronic Mail: Not all email needs to be retained, depending
                on the subject matter.
              </li>
              <ol className="pl-5" type="a">
                <li>
                  All e-mail—from internal or external sources – is to be
                  deleted after 12 months. Staff will strive to keep all but an
                  insignificant minority of their e-mail related to business
                  issues.
                </li>
                <li>
                  GK Telecom Limited will archive e-mail for 90 days after the
                  staff has deleted it, after which time the e-mail will be
                  permanently deleted.
                </li>
                <li>
                  Staff will take care not to send confidential/proprietary
                  information held by GK Telecom Limited to outside sources
                </li>
                <li>
                  Any e-mail staff deems vital to the performance of their job
                  should be copied to the relevant client or candidate record in
                  GK Telecom Limited CRM system.
                </li>
              </ol>
              <li>
                Electronic Documents: including Office 365 and PDF files,
                retention also depends on the subject matter.
              </li>
            </ol>
          }
          <br />
          {
            "GK Telecom Limited does not automatically delete electronic files beyond the dates specified in this Policy. It is the responsibility of all staff to adhere to the guidelines specified in this policy."
          }{" "}
          <br />
          {
            "In certain case’s a document will be maintained in both paper and electronic form. In such cases the official document will be the electronic document."
          }
        </>
      ),
    },
    {
      id: 7,
      header: "INSURANCE RECORDS",
      text: (
        <>
          {"Record Type and then Retention Period"}
          <ol className="pl-4">
            <li>Certificates Issued to GK Telecom Limited Permanent</li>
            <li>
              Claims Files (including correspondence, medical records, etc.)
              Permanent
            </li>
            <li>Insurance Policies (including expired policies) Permanent</li>
          </ol>
        </>
      ),
    },
    {
      id: 8,
      header: "LEGAL FILES AND PAPERS",
      text: (
        <>
          {"Record Type and then Retention Period"}
          <ol className="pl-4">
            <li>
              Legal Memoranda and Opinions (including all subject matter files):
              7 years after close of matter
            </li>
            <li>
              Litigation Files: 1 year after expiration of appeals or time for
              filing appeals
            </li>
            <li>Court Orders: Permanent MISCELLANEOUS</li>
          </ol>
          {"Record Type and then Retention Period"} <br />
          {
            "Material of Historical Value (including pictures, publications):    Permanent"
          }
          <ol className="pl-4">
            <li>
              Policy and Procedures Manuals – Original: Current version with
              revision history
            </li>
            <li>Annual Reports: Permanent</li>
          </ol>
        </>
      ),
    },
    {
      id: 9,
      header: "PERSONNEL RECORDS",
      text: (
        <>
          {"Record Type and then Retention Period"}
          <ul className="pl-4">
            <li>
              Employee Personnel Records (including individual attendance
              records, application forms, job or status change records,
              performance evaluations, termination papers, withholding
              information, garnishments, test results, training and
              qualification records): 6 years after separation
            </li>
            <li>
              Employment Contracts – Individual: 6 years after separation ▪
              Employment Records Correspondence with Employment Agencies and
              Advertisements for Job Openings: 3 years from date of hiring
              decision
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 10,
      header: "TAX RECORDS",
      text: (
        <>
          {
            "General Principle: GK Telecom Limited must keep books of account or records as are sufficient to establish amount of gross income, deductions, credits, or other matters required to be shown in any such return."
          }
          <br />
          {
            "These documents and records shall be kept for as long as the contents thereof may become material in the administration of state, and local income, franchise, and property tax laws."
          }
          <br />
          {"Record Type and then Retention Period"}
          <ul className="pl-4">
            <li>
              Tax-Exemption Documents and Related Correspondence: Permanent
            </li>
            <li>Tax Bills, Receipts, Statements: 7 years</li>
            <li>Tax Returns: Permanent</li>
            <li>Sales/Use Tax Records: 7 years</li>
            <li>Annual Information Returns: Permanent</li>
          </ul>
        </>
      ),
    },
  ];

  const PrivacyPolicyThird = [
    {
      id: 1,
      header: "Consent",
      text: "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
    },
    {
      id: 2,
      header: "Information we collect",
      text: (
        <>
          {
            "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information."
          }{" "}
          <br />
          {
            "If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide."
          }{" "}
          <br />
          {
            "When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number."
          }
        </>
      ),
    },
    {
      id: 3,
      header: "How we use your information",
      text: (
        <>
          {"We use the information we collect in various ways, including to:"}{" "}
          <br />
          {
            <ul className="pl-4">
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>
          }
        </>
      ),
    },
    {
      id: 4,
      header: "Log Files",
      text: "gktelecom.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.",
    },
    {
      id: 5,
      header: "Cookies and Web Beacons",
      text: "Like any other website, gktelecom.com uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimise the users’ experience by customizing our web page content based on visitors’ browser type and/or other information. For more general information on cookies, please read the Cookies article on Generate Privacy Policy website.",
    },
    {
      id: 6,
      header: "Advertising Partners Privacy Policies",
      text: "You may consult this list to find the Privacy Policy for each of the advertising partners of gktelecom.com. Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on gktelecom.com, which are sent directly to users’ browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalise the advertising content that you see on websites that you visit. Note that gktelecom.com has no access to or control over these cookies that are used by third-party advertisers.",
    },
    {
      id: 7,
      header: "Third Party Privacy Policies",
      text: "gktelecom.com’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.",
    },
    {
      id: 8,
      header: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
      text: (
        <>
          {
            "Under the CCPA, among other rights, California consumers have the right to:"
          }
          <ol className="pl-4">
            <li>
              Request that a business that collects a consumer’s personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </li>
            <li>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </li>
            <li>
              Request that a business that sells a consumer’s personal data, not
              sell the consumer’s personal data.
            </li>
          </ol>
          {
            "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."
          }
        </>
      ),
    },
    {
      id: 9,
      header: "GDPR Data Protection Rights",
      text: (
        <>
          {
            "We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:"
          }
          <ol className="pl-4">
            <li>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </li>
            <li>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
          </ol>
          {
            "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."
          }
        </>
      ),
    },
    {
      id: 10,
      header: "Children’s Information",
      text: "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. gktelecom.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.",
    },
  ];

  const TermsAndConditions = [
    {
      id: 1,
      header: "VALIDITY OF GENERAL TERMS AND CONDITIONS",
      text: (
        <>
          <p>
            The following Terms and Conditions shall apply to all services
            rendered by GKT Telecom Ltd., hereafter referred to as GKT eSIM, in
            connection with the prepaid eSIM reselling.
            <br />
            <br />
            The following Terms and Conditions are provided on the website:
            <a className="pl-1 font-weight-bold">https://esim-mobile.co.uk/.</a>
            <br />
            GKT eSIM may accept variant clauses only in the case of an explicit
            written agreement.
            <br />
            <span
              style={{ fontSize: "16px" }}
              className="font-weight-bold mb-2"
            >
              1.1 Definitions
            </span>
            <br />
            <span className="font-weight-bold">‘eSim’</span> – Embedded
            Subscriber Identity Module issued to the Customer to use the network
            without having to use a physical SIM.
            <br />
            <span className="font-weight-bold">‘Network-unlocked’</span>
            – The customer’s device is not a locked device to a specific network
            <br />
            <span className="font-weight-bold">‘Prepaid’</span> - Pay for in
            advance
            <br />
            <span className="font-weight-bold">‘Customer’</span>
            - a user of the Services
            <br />
            <span className="font-weight-bold">‘Activation Policy’</span>
            – The user has the option to activate the eSIM within the first 6
            months from the date of purchase. The validity period of the plan
            commences when the eSIM
            <br />
            <span className="font-weight-bold">‘Contract’</span>– You have the
            legal capacity to enter into contracts within the jurisdiction of
            the United Kingdom and your current place of residence. We will
            guarantee that our Telecommunications Service Providers (TSPs) are
            only enlisted through a written contract that enforces processing
            terms that are essentially no less protective of the Covered User
            Data than those outlined in the Terms of Service.
          </p>
        </>
      ),
    },
    {
      id: 2,
      header: "DESCRIPTION USE OF SERVICES",
      text: (
        <>
          <ol className="pl-4">
            <li className="font-weight-bold">
              eSIM RESELLING
              <p className="font-weight-normal">
                GKT eSIM resells prepaid eSIMs.
                <br />
                The Customer registers and buys its sim cards on{" "}
                <a className="pl-1 font-weight-bold">
                  https://esim-mobile.co.uk/
                </a>{" "}
                website and/or GKT eSIM App. Our payments are operated by
                Pay360.
              </p>
            </li>
            <br />
            <li className="font-weight-bold">
              REGISTRATION FOR USING GKT eSIM SERVICES
              <p>
                The Customer must accept the General Terms and Conditions to use
                GKT eSIM services. The client gives directly or by the
                intermediary of the service provider (Hotel, Travel Agency…) on
                the web browser under{" "}
                <a className="pl-1 font-weight-bold">
                  https://esim-mobile.co.uk/
                </a>{" "}
                the following information: First Name, Last Name, Address
                (billing address), Email address. The customer bears full
                responsibility for keeping their account and SIM secure codes
                confidential.
                <br />
                Eligibility, the Customer must be at least 18 years old to use
                the Services
              </p>
            </li>
            <br />
            <li className="font-weight-bold">
              GKT eSIM ENGAGEMENTS
              <p>
                GKT eSIM shall use reasonable endeavours to provide Customer
                quality service. However, GKT eSIM does not guarantee that the
                service will not be interrupted, provided on time, and be safe
                or fault-free.
                <br />
                Subject to the terms of this Agreement, the Company grants the
                Customer a personal, revocable, non-exclusive,
                non-sub-licensable, and non-transferable license to use the
                Services. The Customer is not allowed to reverse engineer,
                disassemble, or de-compile the SIM card or Services, nor resell,
                sublicense, or use them for data processing services to third
                parties. The Customer must not share or disclose the SIM card or
                Services with anyone else. Export and import laws apply to the
                Customer's use of the SIM card and Services, and the Customer
                agrees not to violate these laws or use them for prohibited
                purposes.
              </p>
            </li>
            <br />
            <li className="font-weight-bold">
              CUSTOMER ENGAGEMENTS
              <p>
                In using the Equipment or Services provided by GKT eSIM, the
                Customer must not engage in any action: that is abusive,
                illegal, or fraudulent; that causes the Network to be impaired
                or damaged. When the Customer breaches its obligations under
                Sec. 2.4, GKT eSIM may suspend the Customer's use of the
                Service. During any period of suspension, the Customer shall
                continue to pay all Charges due under this Agreement in respect
                of the suspended Services.
              </p>
            </li>
            <br />

            <li className="font-weight-bold">
              UNAUTHORIZED USE OF SIM CARD
              <p>
                If the Customer's eSIM card, used for accessing the Services, is
                lost, stolen, or used without permission to access their Account
                or the Services, they must promptly inform the Company. The
                Customer remains responsible for all charges on their Account
                until the Company can suspend the Services.
              </p>
            </li>
            <br />

            <li className="font-weight-bold">
              DEVICE COMPATIBILITY
              <p>
                The Customer is responsible for ensuring that their device is
                eSIM compatible and network-unlocked. Device compatibility may
                depend on the carrier and country of origin, the Customer must
                check the list of eSIM-compatible devices provided at the
                checkout.
                <br />
                By checking the box that confirms that the Customer's device is
                eSIM compatible, the Customer is then held responsible for the
                accuracy of the information they provide.
                <br />
                The eSIM compatibility list is not exhaustive, meaning newly
                announced eSIM-compatible devices may still need to be added.
              </p>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 3,
      header: "START, DURATION, AND TERMINATION OF THE CONTRACT",
      text: (
        <>
          <p>
            The service contract between GKT eSIM and the Customer begins upon
            completing the order at the GKT eSIM website{" "}
            <a className="pl-1 font-weight-bold">https://esim-mobile.co.uk/</a>{" "}
            or via the GKT eSIM app.
            <br />
            The Activation of the eSIM and acknowledgment of the Activation
            Policy is the Customer's responsibility.
            <br />
            The contract will be terminated if the Customer does not have an
            active data package or has deleted the eSIM from the target device.
          </p>
        </>
      ),
    },
    {
      id: 4,
      header: "CHARGES AND PAYMENT",
      text: (
        <>
          <p>
            The Company’s rates and charges are listed in the Rates of Services
            and are available on the Website. The rates are based on per data
            bundle charge, the volume of traffic (megabytes).Volume-based
            tariffs and rounded up in 1 MB increments at the end of each
            session. 5 day tariff Service availability time starts at the time
            of activation of the Service and ends in 5 times 24 hours. All times
            are local for a place of Service usage. The Customer is responsible
            for checking the applicable rate, before using the Services.
            <br />
            The rates and charges may be varied by GKT from time to time. The
            Customer can obtain the current rate of fees and charges by checking
            GKT eSIM website https://esim-mobile.co.uk/ or via the GKT eSIM app
            at any time.
          </p>
          <br />
          <ol className="pl-4">
            <li className="font-weight-bold">
              PAYMENT CONDITIONS
              <p>
                GKT eSIM resells prepaid eSIMs.
                <br />
                The supported payment methods for GKT eSIM services are
                Credit/Debit Card, Google Pay, Apple Pay.
                <br />
                The currency of payment is in GBP(£).
                <br />
                The credit card transaction will be processed and secured by GKT
                eSIM providers Pay360 (
                <a className="pl-1 font-weight-bold">
                  https://portal.pay360.com/
                </a>
                ).
              </p>
            </li>
            <br />

            <li className="font-weight-bold">
              CHARGES FOR USE
              <ol className="pl-3 font-weight-normal">
                <li>
                  GKT eSIM states all Charges are inclusive of VAT unless
                  otherwise specified.
                </li>
                <br />

                <li>
                  The Customer shall not be entitled to offset any of its claims
                  against claims of GKT eSIM, except where the Customer's claims
                  are undisputed or have been confirmed by final court judgment.
                </li>
                <br />

                <li>
                  When Service usage results in a charge to the Customer’s
                  Account, the Company will initially deduct from any existing
                  bonus, promotional, or other credits in the Customer’s
                  Account. After exhausting these credits, the Company will then
                  deduct from any available balance purchased using the
                  Customer’s payment methods (credit or debit card, etc.).
                </li>
                <br />

                <li>
                  The expiration date of the plan aligns with the plan purchase
                  and its validity. The validity period of the plan begins when
                  the eSIM connects to any supported network(s).
                </li>
              </ol>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 5,
      header: "DELIVERY",
      text: (
        <>
          <p>
            The Customer will immediately see the purchased eSIM under the "My
            eSIMs" tab on the GKT eSIM website{" "}
            <a className="pl-1 font-weight-bold">https://esim-mobile.co.uk/</a>{" "}
            and/or the GKT eSIM app. The Customer will receive a confirmation
            email after the purchase. All the information for installing the
            eSIM will be available only on the user's GKT eSIM account.
          </p>
        </>
      ),
    },
    {
      id: 6,
      header: "REFUND / CANCELLATION / MODIFICATION POLICY",
      text: (
        <>
          <p>
            The Customer has the right to ask for a refund or eSIM-replacement
            if the eSIM cannot be installed and used due to a technical problem
            from GKT eSIM.
          </p>
          <br />
          <ol className="pl-4">
            <li className="font-weight-bold mb-2">
              REFUNDS AND CANCELLATION
              <p>
                6.1.1. POLICIES AND GUIDELINES
                <br />
                <br />
                6.1.2. A refund request can be made within thirty (30) days from
                the date of purchase when the activation is no longer possible
                following extensive collaborative troubleshooting.
                <br />
                <br />
                6.1.3. Cooperation with the Customer to resolve the issue
                promptly is required for a refund to be granted
                <br />
                <br />
                6.1.4. Each data package has its own validity period. No refund
                of any form will be offered for the remaining data when the
                validity period expires.
                <br />
                <br />
                6.1.5. Compensation: No refund or remuneration of any kind will
                be issued due to charges from alternate phones, alternate SIM
                cards, alternate providers, hotel phones, or other charges that
                are not directly linked to the Customer's GKT eSIM eSIM account.
                (See section 7. LIABILITY AND WARRANTY in our Terms and
                Conditions)
                <br />
                <br />
                6.1.6. Fraudulent purchases: GKT eSIM reserves the right to
                refuse any form of refund if there is evidence of abuse,
                violation of our Terms and Conditions, or any fraudulent
                activity connected with using GKT eSIM products and services.
                <br />
                <br />
                6.1.7.Unauthorized purchases: The case will be subject to
                investigation and approval before processing any refund. GKT
                eSIM reserves the right to suspend any account associated with
                fraudulent activity.
                <br />
                <br />
                6.1.8. Accidental purchases: Once Customers install the eSIM, it
                will be considered used. No refunds will be offered.
                <br />
                <br />
                6.1.9. Incorrect charges: If the Customer reasonably and in good
                faith disputes an invoice or part of it, the Customer shall
                notify GKT eSIM of such a dispute within twelve (12) days of
                receipt of the invoice, providing details of why the invoiced
                amount is incorrect and, if possible, how much the Customer
                considers due. (See details in section 4.2 CHARGES FOR USE under
                Terms and Conditions)
                <br />
                <br />
                6.1.10. Replacements: eSIMs acquired exclusively through the GKT
                eSIM web (https://esim-mobile.co.uk/ ) or GKT eSIM app are
                eligible for replacement in the event of non-usage or technical
                faults, and such replacements can be processed within 30 days
                from the date of purchase.
                <br />
                <br />
                6.1.11. Other Reasons: If the refund request is not within the
                above, we will investigate the request on a case-by-case basis.
                If the refund is approved, a processing fee may apply. The
                maximum refund of credit a Customer can apply for must be equal
                to or less than the total amount they paid.
                <br />
                <br />
                6.1.12. REFUND PROCESS
                <br />
                To request a refund, contact GKT eSIM's support team via the
                Contact Us page or send a message to help@esim-mobile.co.uk.
                Please be aware that our refund policy above will apply.
                <br />
                Depending on the nature of the issue, Customers will be asked
                for further information to support their refund request, such as
                screenshots of the device settings for technical issues or
                details of why the invoiced amount is incorrect and, if
                possible, how much the Customer considers is due, etc.
                <br />
                Please refer to section 6.1.2 in this same document for refunds
                related to technical issues.
                <br />
                Customers will have the option to credit back via their original
                payment method or issued to their account.
                <br />
                Refund to the original method: Once a refund is approved and
                issued, it can take up to thirty (30) business days to appear on
                a statement depending on the bank.
              </p>
            </li>

            <li className="font-weight-bold">
              MODIFICATION
              <p>
                The eSIM data packages from GKT eSIM are offered as-is, and no
                further modifications or customization can be made based on
                individual requests once purchased.
              </p>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 7,
      header: "LIABILITY AND WARRANTY",
      text: (
        <>
          <p>
            GKT eSIM is not responsible for detriments arising due to the
            proposed service not being constantly available. GKT eSIM provides
            no guarantee of constant availability of the network service.
          </p>
        </>
      ),
    },
    {
      id: 8,
      header: "CUSTOMER SERVICE AND SUPPORT",
      text: (
        <>
          <p>
            For any request, please write an email to help@esim-mobile.co.uk.
            <br />
            Customer Support operates within standard business hours Central
            European Time, and the response time can range from 24 to 72 hours,
            depending on the nature of the query.
          </p>
        </>
      ),
    },
    {
      id: 9,
      header: "ACCEPTABLE USE POLICY (AUP)",
      text: (
        <>
          <p>
            The Acceptable Use Policy (AUP) outlines the permissible and
            prohibited uses of our connectivity services. Specifically tailored
            for traveler's and mobile business professionals, our Unlimited Data
            Plans offer dependable access to applications and communication
            while roaming. However, these plans are not designed for
            high-frequency or intensive data usage, automated or machine-based
            activities, or large file transfers.
            <br />
            Our AUP establishes the guidelines for acceptable internet usage
            when connected to our network. It strictly prohibits activities such
            as SPAM transmission, posting offensive or illegal content, and
            interference with other computer networks.
            <br />
            <br />
            <ol className="pl-4">
              <li className="font-weight-bold">
                Fair use policy
                <p>
                  Customers must not use the GTK eSim service for any
                  machine-to-machine data application Fair use policy as
                  follows: Unlimited data is offered with daily high-speed
                  allocations ranging from 1GB to 5GB. After reaching this
                  limit, the speed reduces to 1 Mbps. Plans are categorized as
                  Mini (1GB), Standard (2GB), Enhance (3GB), and Premium (5GB)
                  maximum per day.
                </p>
              </li>
            </ol>
          </p>
        </>
      ),
    },
  ];

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    if (!kioskDetails || kioskDetails.kioskId <= 0) {
      //not logged in
      history.push("/kiosk/login");
      return;
    }

    window.scrollTo(0, 0);
  }, []);

  const handleTnCSelectionToggle = (index) => {
    if (selectionTnCActive === index) {
      setSelectionTnCActive(null);
    } else {
      setSelectionTnCActive(index);
    }
  };

  const handlePrivacyOneToggle = (index) => {
    if (selectionOnePrivacy === index) {
      setSelectionOnePrivacy(null);
    } else {
      setSelectionOnePrivacy(index);
    }
  };

  const handlePrivacySelectionToggle = (index) => {
    if (selectionTwoPrivacy === index) {
      setSelectionTwoPrivacy(null);
    } else {
      setSelectionTwoPrivacy(index);
    }
  };

  const handleThirdToggle = (index) => {
    if (selectionThreePrivacy === index) {
      setSelectionThreePrivacy(null);
    } else {
      setSelectionThreePrivacy(index);
    }
  };

  const [orderDetails, setOrderDetails] = useState({
    orderId: 0,
    orderSource: {
      orderSourceId: 6,
    },
    orderType: {
      orderTypeId: 1,
    },
    orderStatus: {
      orderStatusId: 1,
    },
    orderPlan: [
      {
        esim: null,
        orderPlanId: 0,
        plan: {
          planId: 0,
        },
        quantity: 1,
        amount: 0,
        currency: null,
        // planStartDate: "" // Initialize with an empty string, you'll set it later
      },
    ],
    customer: {
      id: localStorage.getItem("userId"),
    },

    amount: 0,
    subtotalAmount: 0,
    currency: "",
    termAgreed: 1,
    deviceCompatibilityAgreed: 1,
    voucher: null,
  });

  // AuthenticationService.setupAxiosInterceptors();
  // const { location } = props;
  // const { state } = location;
  // const plan = state && state.plan;
  const [planObject, setPlanObject] = useState(null);
  const [checkDiscount, setCheckDiscount] = useState(false);

  useEffect(() => {
    if (planObject !== null) {
      setOrderDetails({
        orderId: 0,
        orderSource: {
          orderSourceId: 6,
        },
        orderType: {
          orderTypeId:
            localStorage.getItem("topUpIccid") &&
              localStorage.getItem("topUpIccid") !== "null"
              ? 2
              : 1,
        },
        orderStatus: {
          orderStatusId: 1,
        },
        orderPlan: [
          {
            esim: null,
            //         esimObject!==null
            // ?
            // [ esimObject]
            // : null,
            orderPlanId: 0,
            plan: {
              planId: Number(planObject.planId),
            },
            quantity: 1,
            amount: Number(planObject.price),
            currency: planObject.currency,
            // planStartDate: formatDate(selectedStartDate) // Initialize with an empty string, you'll set it later
          },
        ],
        customer: {
          id: Number(localStorage.getItem("userId")),
        },

        amount: Number(planObject.price),
        subtotalAmount: Number(planObject.price),
        currency: planObject.currency,
        termAgreed: 1,
        deviceCompatibilityAgreed: 1,
        voucher: null,
      });
    }
  }, [planObject]);

  const [showAlertForVoucher, setShowAlertForVoucher] = useState(false);
  const [QRLink, setQRLink] = useState();
  const [orderIdEncrypted, setOrderIdEncrypted] = useState();
  //  console.log("clicked",showAlertForVoucher)

  const orderAPI = async () => {
    const updatedOrderDetails = { ...orderDetails };
    // if (localStorage.getItem('token')) {
    //   const token = localStorage.getItem('token');
    //   const user = jwtDecode(token);
    //   const exp = user.exp
    //   const currentTime = Math.floor(Date.now() / 1000);
    //   if (exp < currentTime) {
    //     localStorage.clear();
    //     history.push("/");
    //   }
    if (!termsChecked || !deviceChecked) {
      setMessage("Please check both checkboxes before proceeding.");
      return; // Don't proceed further
    } else if (termsChecked && deviceChecked) {
      setMessage("");
      if (
        !applyClick &&
        document.getElementById("voucherField").value.trim() !== ""
      ) {
        setShowAlertForVoucher(true);
      } else {
        setShowAlertForVoucher(false);
        setShowLoader(true);
        setMessage("");
        const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
        await KioskOrderService.order(updatedOrderDetails, kioskDetails.kioskId, kioskDetails.lastLoginDate)
          .then((response) => {
            if (response.status === 200) {
              // Open link in a new tab
              if (response.data.amount === 0) {
                KioskOrderService.orderAfterPayment(
                  response.data.orderIdEncrypted
                ).then((response) => {
                  if (
                    response.status === 200 &&
                    response.data.orderStatus.orderStatusId !== 1
                  ) {
                    setShowLoader(false);
                    localStorage.setItem(
                      "orderSummaryAfterPayment",
                      JSON.stringify(response.data)
                    );
                    // clearInterval(orderInterval); // Stop calling after success
                    history.push("/kiosk/paymentSuccess");
                  }
                });
              } else {
                localStorage.setItem("order", JSON.stringify(response.data));
                history.push("/kiosk/payment");
                setShowScanQrCodeDiv(!showScanQrCodeDiv);
                setShowApplyBtnDiv(false); // Hide part1 div
                setOpen(false); // Close the modal when Buy button is clicked
                const link = response.data.payment.link;
                setOrderIdEncrypted(response.data.orderIdEncrypted);
                localStorage.setItem(
                  "orderIdEncrypted",
                  response.data.orderIdEncrypted
                );
                // if (link) {
                //     const newTab = window.open(link, '_blank', 'noopener,noreferrer');
                //     if (newTab) {
                //         newTab.blur(); // Blur the new tab to prevent focus steal
                //       }
                //     }
                // Redirect to /timer

                if (link) {
                  localStorage.setItem("QRLink", link);
                  setQRLink(link); // Redirect to the link in the same tab
                }
                // history.push(`/countdowntimer/${response.data.orderId}`);
              }
            }
          })
          .catch((error) => {
            // Handle errors here
            if (error.message === "Network Error") {
              setMessage("Network Error");
            } else {
              switch (error.response ? error.response.status : "") {
                case 401:
                  setMessage(error.response.data.failedReason);
                  setShowLoader(false);
                  // setErrorCode(401)
                  localStorage.clear();
                  localStorage.setItem("kioskSessionExpired", true);
                  history.push("/kiosk/login");
                  break;
                case 403:
                  setMessage(error.response.data.failedReason);
                  setShowLoader(false);
                  break;
                case 500:
                  setMessage(error.response.data.failedReason);
                  setShowLoader(false);
                  break;
                case 404:
                case 406:
                  setMessage(error.response.data.failedReason);
                  setShowLoader(false);
                  break;
                case 412:
                  setMessage(error.response.data.failedReason);
                  setShowLoader(false);
                  break;
                default:
                  setMessage("Unknown Error");
                  setShowLoader(false);
                  break;
              }
            }
          });
      }
    }
    // } else {
    //   localStorage.clear();
    //   history.push("/");
    // }
  };

  // const [esimObject, setEsimObject] = useState(null)
  useEffect(() => {
    localStorage.removeItem("countdownSeconds");
    const storedPlan = localStorage.getItem("kioskSelectedPlanToBuy");
    // const esim = localStorage.getItem('topUpIccid')
    if (storedPlan) {
      const parsedPlan = JSON.parse(storedPlan);
      setPlanObject(parsedPlan);
    }
    // if (esim) {
    //   const parsedEsim = JSON.parse(esim);
    //   setEsimObject(parsedEsim);
    // }
  }, []);

  const openCoverageDropdown = () => {
    document.body.style.overflowY = "hidden";
    setIsCoverageDropdownOpen(true);
  };

  const closeCoverageDropdown = () => {
    document.body.style.overflowY = "auto";

    setIsCoverageDropdownOpen(false);
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen, coverageDropdownRef]);

  const CoverageDropdown = () => {
    let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (planObject !== null) {
        setCountry(planObject.planCountry);
      }
    }, [planObject]);

    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          {planObject !== null && (
            <h4>
              Coverage is provided by{" "}
              <span>{planObject.planCountry.length}</span> countries
            </h4>
          )}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2 d-flex justify-content-center align-items-center">
                          <p className="m-0">{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>

        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);
  const networkDropdownRef = useRef(null);

  const openNetworkDropdown = () => {
    setIsNetworkDropdownOpen(true);
  };

  const closeNetworkDropdown = () => {
    setIsNetworkDropdownOpen(false);
  };

  const handleNetworkClick = (event) => {
    if (
      isNetworkDropdownOpen &&
      networkDropdownRef.current &&
      !networkDropdownRef.current.contains(event.target)
    ) {
      closeNetworkDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleNetworkClick);

    return () => {
      document.removeEventListener("click", handleNetworkClick);
    };
  }, [isNetworkDropdownOpen]);

  const NetworkDropdown = () => {
    let [country, setCountry] = useState([
      "Australia",
      "France",
      "India",
      "Australia",
      "France",
      "India",
      "Australia",
      "France",
      "India",
      "Australia",
      "France",
      "India",
    ]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];
    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={networkDropdownRef}>
          <h4>
            Network is available in <span>14</span> countries
          </h4>
          <input type="text" placeholder="Search..." />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {country.map((item, index) => (
                <li key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <span
                          key={index}
                          className={`fflag fflag-AU ff-lg ff-round`}
                        ></span>
                      </div>
                      <div className="ml-2">
                        <p>{item}</p>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <span>jio</span>
                      <span>airtel</span>
                      <span>airtel</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const [isCalendarDropdownOpen, setIsCalendarDropdownOpen] = useState(false);
  const calendarDropdownRef = useRef(null);

  const openCalendarDropdown = () => {
    setIsCalendarDropdownOpen(true);
  };

  const closeCalendarDropdown = () => {
    setIsCalendarDropdownOpen(false);
  };

  const handleCalendarClick = (event) => {
    if (
      isCalendarDropdownOpen &&
      calendarDropdownRef.current &&
      !calendarDropdownRef.current.contains(event.target)
    ) {
      closeCalendarDropdown();
    }
  };

  const handleDateChange = (date) => {
    setSelectedStartDate(date);
    closeCalendarDropdown();
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  useEffect(() => {
    document.addEventListener("click", handleCalendarClick);

    return () => {
      document.removeEventListener("click", handleCalendarClick);
    };
  }, [isCalendarDropdownOpen]);

  // const CalendarDropdown = () => {
  //   return (
  //     <div style={{ top: "-255px" }} className="calendar-dropdown">
  //       <Calendar
  //         onChange={handleDateChange}
  //         value={selectedStartDate}
  //         minDate={new Date()}
  //       />
  //     </div>
  //   );
  // };
  const [countOS, setCountOS] = useState(1);
  // console.log(count);
  const [voucherCode, setVoucherCode] = useState("");
  // const [voucherData, setVoucherData] = useState("");
  // const [updatedOrderDetails, setUpdatedOrderDetails] = useState() ;

  const dataChange = (event) => {
    const { value } = event.target;
    setVoucherCode(value);
    // if(value==""){
    setVMessage("");
    // }
  };
  const [vMessage, setVMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [discount, setDiscount] = useState();
  const [discountedAmount, setDiscountedAmount] = useState();
  const [appliedClicked, setAppliedClicked] = useState(false);
  const [responseVoucherCode, setResponseVoucherCode] = useState();
  const [voucherAppliedMessage, setVoucherAppliedMessage] = useState();

  // console.log("Apl",responseVoucherCode)

  const handleDiscount = () => {
    // setCheckDiscount(!checkDiscount);
    if (voucherCode.trim() === "") {
      setVMessage("Please enter a discount code");
    } else {
      setShowLoader(true);

      // if (localStorage.getItem('token')) {
      //   const token = localStorage.getItem('token');
      //   const user = jwtDecode(token);
      //   const exp = user.exp
      //   const currentTime = Math.floor(Date.now() / 1000);
      //   if (exp < currentTime) {
      //     localStorage.clear();
      //     history.push("/");
      //   }
      const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
      KioskApplyVoucherService.appllyVoucher(
        voucherCode,
        localStorage.getItem("userId"),
        planObject.price,
        planObject.planId,
        kioskDetails.kioskId,
        kioskDetails.lastLoginDate
      )
        .then((response) => {
          // console.log("Applied",voucherCode)
          if (response.status == 200) {
            setShowLoader(false);
            setVMessage("");
            setAppliedClicked(true);
            document.getElementById("voucherField").disabled = true;
            toast.success("Discount code has been applied succesfully!");
            setResponseVoucherCode(response.data.voucherCode);
            setVoucherAppliedMessage(response.data.voucherApplyedMessage);
            setDiscount(response.data.applicableAmount);
            const discountedAmount = (
              planObject.price - response.data.applicableAmount
            ).toFixed(2);
            setDiscountedAmount(discountedAmount);
            setOrderDetails((prevOrder) => ({
              ...prevOrder,
              voucher: response.data,
              amount: discountedAmount,
            }));
            setCountOS(3);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setVMessage("Network Error");
            setShowLoader(false);
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setVMessage(error.response.data.failedReason);
                setShowLoader(false);
                setApplyClick(false);
                localStorage.clear();
                localStorage.setItem("kioskSessionExpired", true);
                history.push("/kiosk/login");
                break;
              case 403:
                setVMessage(error.response.data.failedReason);
                setShowLoader(false);
                setApplyClick(false);
                break;
              case 500:
                setVMessage(error.response.data.failedReason);
                setShowLoader(false);
                setApplyClick(false);
                break;
              case 404:
              case 406:
                setVMessage(error.response.data.failedReason);
                setShowLoader(false);
                setApplyClick(false);
                break;
              case 412:
                setVMessage(error.response.data.failedReason);
                setShowLoader(false);
                setApplyClick(false);
                break;
              default:
                setVMessage("Unknown Error");
                setShowLoader(false);
                setApplyClick(false);
                break;
            }
          }
        });
      // } else {
      //   localStorage.clear();
      //   history.push("/");
      // }
    }
  };

  const OkBtn = () => {
    return (
      <button
        className="btn font-weight-bold text-uppercase btn-primary btn-md mt-2 mb-1"
        onClick={() => setShowAlertForVoucher(false)}
      >
        <font>OK</font>
      </button>
    );
  };

  const deleteClicked = () => {
    setResponseVoucherCode();
    setVoucherAppliedMessage();
    setVMessage("");
    setCountOS(1);
    setAppliedClicked(false);
    setApplyClick(false);
    document.getElementById("voucherField").disabled = false;
    setDiscountedAmount();
    setVoucherCode("");
    setOrderDetails((prevOrder) => ({
      ...prevOrder,
      voucher: null,
      amount: planObject.price,
    }));
  };

  const handleClick = () => { };
  const handleClose = () => {
    setOpen(false);
    setShowApplyBtnDiv(true); // Show part1 div when modal is closed
  };

  const handleVoucherClose = () => {
    setShowAlertForVoucher(false);
    // setShowApplyBtnDiv(true); // Show part1 div when modal is closed
  };
  const handleOpen = () => {
    if (!termsChecked || !deviceChecked) {
      setMessage("Please check both checkboxes before proceeding.");
      // return; // Don't proceed further
    } else if (termsChecked && deviceChecked) {
      if (
        !applyClick &&
        document.getElementById("voucherField").value.trim() !== ""
      ) {
        setShowAlertForVoucher(true);
      } else {
        setMessage("");
        setOpen(true);
        setShowApplyBtnDiv(true); // Hide part1 div when modal is opened
      }
    }
  };

  // const [seconds, setSeconds] = useState(60);
  // const [shouldCallOrder, setShouldCallOrder] = useState(true);
  // const [failedTimer, setFailedTimer] = useState(false);
  // const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  // let orderInterval;

  // const orderAfterPayment = () => {
  //   if (!isApiCallInProgress) {
  //       OrderService.orderAfterPayment(orderIdEncrypted)
  //         .then((response) => {
  //           if (response.status === 200 && response.data.orderStatus.orderStatusId !== 1) {
  //             localStorage.setItem("orderSummaryAfterPayment", JSON.stringify(response.data));
  //             clearInterval(orderInterval);
  //             history.push('/kiosk/paymentSuccess');
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //         })
  //         .finally(() => {
  //           setIsApiCallInProgress(false);
  //         });
  //   }
  // };

  // useEffect(() => {
  //   if(QRLink){
  //   const countdownInterval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds((prevSeconds) => prevSeconds - 1);
  //     } else {
  //       clearInterval(countdownInterval);
  //       setFailedTimer(true);
  //       setShouldCallOrder(false);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(countdownInterval);
  //   };
  // }
  // }, [seconds,QRLink]);

  // useEffect(() => {
  //   if(QRLink){
  //   const startOrderInterval = () => {
  //     orderInterval = setInterval(() => {
  //       if (shouldCallOrder) {
  //         orderAfterPayment();
  //       } else {
  //         clearInterval(orderInterval);
  //       }
  //     }, 10000);
  //   };

  //   startOrderInterval();

  //   return () => {
  //     clearInterval(orderInterval);
  //   };
  // }
  // }, [shouldCallOrder, isApiCallInProgress, QRLink]);

  // // console.log("isApiCallInProgress => ",isApiCallInProgress)

  const backPrompt = () => {
    // clearInterval(orderInterval)
    history.push("/kiosk");
  };

  const closeTermsAndConditionFunc = () => {
    setTermsAndCondition(false);
  };
  const closePrivacyPolicyFunc = () => {
    setPrivacyPolicy(false);
  };
  return (
    <div className="kiosk-app">
      <Header />
      <section className="w-100 m-auto kiosk-container kiosk-checkout">
        <div className="mt-3">
          <div className="kiosk-heading-grid">
            <BackButton handleClick={backPrompt} />
            <p className="kiosk-title text-truncate m-0">Payment</p>
          </div>
          <div className="row m-0 pr-3 Checkout-kiosk">
            <div className="col-12 col-lg-6 py-2 pr-2 pl-0 mt-4 px-4">
              {/* Do map from here */}
              {planObject !== null && (
                <ul className="list-group Kiosk-payment">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Plan
                    <span className="badge">{planObject.planName}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Coverage
                    <div
                      className="order-value order-popup-link"
                      style={{ position: "relative" }}
                    >
                      {planObject.planType.planTypeId === 2 ||
                        planObject.planType.planTypeId === 3 ? (
                        <div
                          className={`${isCoverageDropdownOpen ? "order-popup-active" : ""
                            }`}
                          onClick={openCoverageDropdown}
                        >
                          <span>{planObject.planCountry.length} Countries</span>
                          <RxDropdownMenu />
                        </div>
                      ) : (
                        <div>
                          {planObject.planCountry[0] &&
                            planObject.planCountry[0].planCountryNetwork !==
                            null ? (
                            <span>
                              {planObject.planCountry.map(
                                (country) => country.countryDescription
                              )}{" "}
                              -{" "}
                              {planObject.planCountry[0].planCountryNetwork
                                .map((network) => network)
                                .join(", ")}
                            </span>
                          ) : (
                            <span>
                              {planObject.planCountry.map(
                                (country) => country.countryDescription
                              )}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Plan type
                    <span className="badge">Data</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Order type
                    <span className="badge">New eSIM</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Data
                    <span className="badge">{planObject.data}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Validity
                    <span className="badge">{planObject.validity} Days</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Subtotal
                    <span className="badge">£{planObject.price}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Service fees
                    <span className="badge">0.00</span>
                  </li>
                  {countOS == 3 && (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Discount
                      <span className="badge" style={{ color: "#e55353" }}>
                        {" "}
                        £{discount}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Total
                    <span className="badge">
                      {discountedAmount !== undefined
                        ? `£${discountedAmount}`
                        : `£${planObject.price}`}
                    </span>
                  </li>
                </ul>
              )}
            </div>
            {/* {showPart1Div && ( */}
            <div
              className="col-12 col-lg-6 py-2 pr-2 pl-2 mt-5 px-4 ApplyBtnDiv"
              style={{ display: showApplyBtnDiv ? "block" : "none" }}
            >
              <div className="kisok-card w-100 d-flex justify-content-start align-items-center discount-input">
                <div>
                  <img
                    className="mr-1 ml-2"
                    alt="discout"
                    style={{ width: "47px" }}
                    src={discountIcon}
                  />
                </div>
                <input
                  type="text"
                  placeholder="Enter your discount code"
                  id="voucherField"
                  autoCapitalize="true"
                  style={{ width: "100%" }}
                  value={voucherCode}
                  onChange={dataChange}
                  className="pl-3 pr-1 w-100"
                />
                {appliedClicked === false ? (
                  <button
                    className="btn discount-apply-btn btn-light shadow-sm d-flex justify-content-center align-items-center"
                    id="discountApplyBtn"
                    onClick={() => {
                      handleDiscount();
                      setApplyClick(true);
                    }}
                  >
                    <font>Apply</font>
                  </button>
                ) : (
                  <button
                    className="btn discount-apply-btn btn-light shadow-sm d-flex justify-content-center align-items-center"
                    id="discountApplyBtn"
                    onClick={deleteClicked}
                  >
                    <font>Remove</font>
                  </button>
                )}
              </div>
              {responseVoucherCode ? (
                // // put success or error class according to the message
                // dont add success message show toast
                <div className="kiosk-success text-center mt-2">
                  {responseVoucherCode} - Successfully applied.
                </div>
              ) : null}
              {vMessage ? (
                // // put success or error class according to the message
                // dont add success message show toast
                <div className="kiosk-error text-center mt-2">{vMessage}</div>
              ) : null}

              <div className="mt-4">
                <div className="d-flex justify-content-start align-items-start">
                  <label className="custom-checkbox">
                    <div className="pt-1">
                      <input
                        type="checkbox"
                        checked={termsChecked}
                        onChange={(e) => setTermsChecked(e.target.checked)}
                      />
                    </div>
                    <p className="m-0">
                      By completing the order, you agree to our
                      <a
                        style={{ textDecoration: "underline" }}
                        className="mx-1"
                        onClick={() => setTermsAndCondition(true)}
                      >
                        Terms & Conditions
                      </a>
                      and
                      <a
                        className="mx-1"
                        style={{ textDecoration: "underline" }}
                        onClick={() => setPrivacyPolicy(true)}

                      // onClick={() => history.push("/privacypolicy")}
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </label>
                </div>
                <div className="d-flex justify-content-start align-items-start mt-3">
                  <label className="custom-checkbox">
                    <div style={{ paddingTop: "2px" }}>
                      <input
                        type="checkbox"
                        checked={deviceChecked}
                        onChange={(e) => setDeviceChecked(e.target.checked)}
                      />
                    </div>
                    Before completing this order, please confirm your device is
                    eSIM compatible and network-unlocked.
                  </label>
                </div>
              </div>
              <div className="mt-4">
                <div className="d-flex justify-content-start align-items-start payment-text">
                  <div width="26" height="26">
                    <ImInfo />
                  </div>
                  <div className="px-2">
                    <p>
                      By clicking on “Buy” button a QR will be generate to make
                      online payment
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-start payment-text">
                  <div width="26" height="26">
                    <ImInfo />
                  </div>
                  <div className="px-2">
                    <p>
                      Activation policy: The validity period starts when the
                      eSIM connects to any supported network/s.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <button
                  onClick={handleOpen}
                  className="kiosk-btn-lg kiosk-grad-btn btn mb-3"
                >
                  <font>Buy</font>
                </button>
              </div>
              <FormFeedback
                style={{ display: message ? "block" : "none" }}
                className="kiosk-error"
              >
                {message}
              </FormFeedback>
            </div>
            {/* )} */}
            <div>
              {/* {isCoverageDropdownOpen && planObject ? (
        <div className="kiosk-dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}
      </div>
              {/* {showScanQrCodeDiv && (
                <div className="col-12 col-lg-6 py-2 pr-2 pl-2 mt-5 px-4 ScanQrCodeDiv">
                  <h2
                className="kiosk-sub-title mb-4"
                style={{ textAlign: "center" }}
              >
                Scan this QR code to make payment
              </h2>
                  <div>
                  <QRCode
                    id="targetQRcode"
                    level={"H"}
                    includeMargin={true}
                    value={QRLink}
                    size={250}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {!failedTimer ? (
                    <h3>Checking your payment status...</h3>
                  ) : (
                    <h3 className="kiosk-sub-text text-center">
                      Didn't reciveved your payment yet, once completion of your
                      payment please click on "Recheck Payment Status" to
                      complete your order!
                    </h3>
                  )}
                  {!failedTimer && (
                    <>
                      <p className="kiosk-sub-text text-center">
                        Kindly do not go back or refresh your page
                      </p>
                      <h1>
                        {Math.floor(seconds / 60)}:{seconds % 60}
                      </h1>
                      <div>
                        <h4 className="mb-3">Time Remaining</h4>
                      </div>
                    </>
                  )}
                  <div className="mt-4">
                    <button
                      disabled={failedTimer ? false : true}
                      onClick={orderAfterPayment}
                      className="btn btn-block btn-lg btn-light rounded-pill"
                    >
                      Recheck Payment Status
                    </button>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
          {!failedTimer ?
            <h3>Checking your payment status...</h3> :
            <h3 className="kiosk-sub-text text-center">Didn't reciveved your payment yet, once completion of your payment please click on "Recheck Payment Status" to complete your order!</h3>
          }
          {!failedTimer && 
          <>
          <p className="kiosk-sub-text text-center">Kindly do not go back or refresh your page</p>
          <h1>{Math.floor(seconds / 60)}:{seconds % 60}</h1>
          <div>
            <h4 className="mb-3">Time Remaining</h4>
          </div>
          </>
              }
          <div className="mt-4">
            <button disabled={failedTimer ? false : true} 
            onClick={orderAfterPayment} 
            className="btn btn-block btn-lg btn-light rounded-pill">
              Recheck Payment Status
            </button>
          </div>
          </div>
          </div>

                
              )} */}
            </div>
          </div>
        </div>
      </section>
      {/* Coverage dropdown */}
      {isCoverageDropdownOpen ? (
        <div className="kiosk-dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}
      {/* Modal */}
      <CustomModal
        isOpen={open}
        onClose={handleClose}
        backButton={false}
        width={"normal"}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          <>
            <img
              className="mr-1 ml-2"
              alt="discout"
              style={{ width: "77px" }}
              src={checkoutPaymentIcon}
            />
            <h2 className="kiosk-title text-center">Device compatibility!</h2>
            <h3 className="kiosk-sub-title mb-3 text-center">
              Please make sure your device is eSIM compatible
            </h3>
            <div
              style={{ gap: "200px" }}
              className="w-100 d-flex justify-content-center align-items-center"
            >
              <button
                onClick={orderAPI}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                <font>Buy</font>
              </button>
              <button
                onClick={handleClose}
                style={{ height: "60px" }}
                className="btn btn-lg btn-outline-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                Cancel
              </button>
            </div>
          </>
        </div>
      </CustomModal>
      <Toaster position="top-center" reverseOrder={false} />
      {/* <div>

      {isCoverageDropdownOpen ? (
        <div className="dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}
      </div> */}
      <CustomModal
        isOpen={showAlertForVoucher}
        onClose={handleVoucherClose}
        backButton={false}
        width={"normal"}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          <>
            <img
              className="mr-1 ml-2"
              alt="discout"
              style={{ width: "47px" }}
              src={checkoutPaymentIcon}
            />
            <h2 className="kiosk-title text-center">
              Apply or Remove your voucher
            </h2>
            <h3 className="kiosk-sub-title mb-3 text-center">
              Please apply or remove the voucher entered in voucher field.
            </h3>
            <div
              style={{ gap: "200px" }}
              className="w-100 d-flex justify-content-center align-items-center"
            >
              <button
                onClick={handleVoucherClose}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                <font>OK</font>
              </button>
            </div>
          </>
        </div>
      </CustomModal>
      {/* Terms and conditions */}
      <CustomModal
        isOpen={termsAndCondition}
        onClose={closeTermsAndConditionFunc}
        backButton={false}
        width={1800}
        customPadding={160}
      >
        <h4 style={{ marginTop: -40 }} className="kiosk-title text-center mb-0">
          Terms & Conditions
        </h4>
        <p style={{ fontSize: 22 }} className="text-center mb-3">
          General terms and conditions
        </p>
        {TermsAndConditions.map((TermsNCondition, index) => {
          return (
            <AccordionTnc
              key={index}
              active={selectionTnCActive}
              handleToggle={handleTnCSelectionToggle}
              TermsNCondition={TermsNCondition}
            />
          );
        })}
      </CustomModal>
      {/* Privacy policy */}

      <CustomModal
        isOpen={privacypolicy}
        onClose={closePrivacyPolicyFunc}
        backButton={false}
        width={1800}
        customPadding={160}
      >
        <section className="PrivacyPolicy-container container mt-3">
          <div className="row m-0">
            <div className="col-12 p-0 mb-3">
              <h1 className="mb-3">Privacy Policy</h1>
              <p
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontStyle: "italic",
                }}
              >
                GK Telecom Limited <br />
                Privacy & Data Retention Policy <br />
                Effective Date: 01-Sept-19
              </p>
            </div>
            <div className="container mb-3">
              <div className="row justify-content-center">
                <div className="col-12 mt-2 p-0">
                  <h2>Data Retention Policy</h2>
                  <div className="card">
                    <div className="card-body p-2">
                      {PrivacyPolicy.map((PrivacyPolicy, index) => {
                        return (
                          <AccordionPrivacyFirst
                            key={index}
                            active={selectionOnePrivacy}
                            handleToggle={handlePrivacyOneToggle}
                            PrivacyPolicy={PrivacyPolicy}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mb-3">
              <div className="row justify-content-center">
                <div className="col-12 mt-2 p-0">
                  <h2>APPENDIX A RECORD RETENTION SCHEDULE</h2>
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    The Record Retention Schedule is organised as follows:{" "}
                    <br />
                    SECTION TOPIC
                  </span>
                  <ol className="pl-4 text-white pt-3">
                    <li>Accounting and Finance</li>
                    <li>Contracts</li>
                    <li>Corporate Records</li>
                    <li>Correspondence and Internal Memoranda </li>
                    <li>Personal Information </li>
                    <li>Electronic Records</li>
                    <li>Insurance Records</li>
                    <li>Legal</li>
                    <li>Miscellaneous</li>
                    <li>Personnel Records </li>
                    <li>Tax Records</li>
                  </ol>
                  <div className="card">
                    <div className="card-body p-2">
                      {PrivacyPolicySectionTopic.map((PrivacyPolicy, index) => {
                        return (
                          <AccordionPrivacySecond
                            key={index}
                            active={selectionTwoPrivacy}
                            handleToggle={handlePrivacySelectionToggle}
                            PrivacyPolicy={PrivacyPolicy}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mb-3 text-white">
              <div className="row justify-content-center">
                <div className="col-12 mt-2 p-0">
                  <p>
                    At gktelecom.com, accessible from https://gktelecom.com/,
                    one of our main priorities is the privacy of our visitors.
                    This Privacy Policy document contains types of information
                    that is collected and recorded by gktelecom.com and how we
                    use it.
                    <br />
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                    <br />
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collected in
                    gktelecom.com. This policy is not applicable to any
                    information collected offline or via channels other than
                    this website.
                  </p>

                  <div className="card">
                    <div className="card-body p-2">
                      {PrivacyPolicyThird.map((PrivacyPolicy, index) => {
                        return (
                          <AccordionPrivacyThird
                            key={index}
                            active={selectionThreePrivacy}
                            handleToggle={handleThirdToggle}
                            PrivacyPolicy={PrivacyPolicy}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CustomModal>

      {/* {showAlertForVoucher ? (
          <AlertPopup
            status={"error"}
            heading={"Apply or Remove your voucher"}
            content={
              "Please apply or remove the voucher entered in voucher field."
            }
            customFunction={<OkBtn />}
            updateState={() => setShowAlertForVoucher(false)}
          />
        ) : (
          ""
        )} */}
      <KioskBgElement />
    </div>
  );
};

export default KioskCheckout;
