import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/img/gkt_final_logo.png";
import { useHistory } from "react-router-dom";

import { IoSearch } from "react-icons/io5";
import planBgLeft from "../../assets/img/plan-bg-left.png";
import planBgRight from "../../assets/img/plan-bg-right.png";
import stars from "../../assets/img/stars.png";
import ncs from "../../assets/img/ncs_logo.png";
import tableFilterIcon from "../../assets/img/table-filter-icon.png";
import { FiFilter } from "react-icons/fi";
import { GiSettingsKnobs as filterIcon } from "react-icons/gi";
import earthLogo from "../../assets/img/earth-logo.png";
import {
  CSmartTable,
  CButton,
  CCollapse,
  CCardBody,
  CAvatar,
} from "@coreui/react-pro";
// import CSmartTable from '@coreui/react-pro/src/components/smart-table/CSmartTable';
// import { CSmartTable, CBadge, CButton, CCollapse, CCardBody } from '@coreui/react-pro-pro';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import PlansBasedOnCountryService from "../../Services/PlansBasedOnCountryService";
import PlansBasedOnRegionService from "../../Services/PlansBasedOnRegionService ";
import RegionListService from "../../Services/RegionService";
import MultiRangeSlider from "multi-range-slider-react";
import AlertPopup from "../Alert_Popup/AlertPopup";
import UserVarificationService from "../../Services/UserVarificationService";
import DashboardDetailsService from "../../Services/DashboardDetailsService";
import ContactUsForm from "../Common/ContactUsForm";
import GlobalPlansService from "../../Services/GlobalPlansService";

import Australia from "../../assets/img/mapicons/Australia.png";
import Asia from "../../assets/img/mapicons/Asia.png";
import Africa from "../../assets/img/mapicons/Africa.png";
import Europe from "../../assets/img/mapicons/Europe.png";
import Balkans from "../../assets/img/mapicons/Balkans.png";
import Caribbean from "../../assets/img/mapicons/Caribbean.png";
import Caucasus from "../../assets/img/mapicons/Caucasus.png";
import LatinAmerica from "../../assets/img/mapicons/LatinAmerica.png";
import MiddleEast from "../../assets/img/mapicons/MiddleEast.png";

import World from "../../assets/img/mapicons/World.png";
import popularIcon from "../../assets/elements/popular_icon.png";
import premiumIcon from "../../assets/elements/premium_icon.png";
import unlimitedIcon from "../../assets/elements/unlimited_icon.png";
import { BsInfoCircleFill } from "react-icons/bs";
import { jwtDecode } from "jwt-decode";

import { Button } from "reactstrap";

import TooltipBottom from "../Common/Tooltip/TooltipBottom";
import CustomModal from "../Kiosk/components/CustomModal";
import KioskPlansService from "../Kiosk/Services/KioskPlansService";

import t20Wc1 from "../../assets/img/mapicons/t20-wc-1.jpg";
import t20Wc2 from "../../assets/img/mapicons/t20-wc-2.jpg";
import t20WcIcon from "../../assets/img/mapicons/t20-wc-icon.jpg";
import useScrollToTop from "../custom_hooks/useScrollToTop";
import DocumentMeta from "react-document-meta";

const CustomFilterSection = (props) => {
  const [openTray, setOpenTray] = useState(false);
  const filterBtnRef = useRef();
  // filterBtnRef

  const [activeFilterItem, setActiveFilterItem] = useState(null);
  const [userPlans, setUserPlans] = useState([{}]);
  const [unlimitedFilter, setUnlimitedFilter] = useState(false);

  const handleFilterClick = (index) => {
    setActiveFilterItem((prevActiveItem) =>
      prevActiveItem === index ? null : index
    );
    if (index === 0 && index !== activeFilterItem) {
      props.onCheapestFilterClick();
    } else if (index === 1 && index !== activeFilterItem) {
      props.onBestPricePerGBFilterClick();
    } else if (index === 2 && index !== activeFilterItem) {
      props.onLargestGBFilterClick();
    } else if (index === 3 && index !== activeFilterItem) {
      props.onLongestValidityFilterClick();
    } else {
      props.onFilterRemoveForOthers();
    }

    // if(index===3 && index!==activeFilterItem){
    //   }else{
    //     props.onFilterRemove();
    //   }
  };
  const unlimitedToggle = () => {
    props.onUnlimitedToggle();
    if (props.unlimitedFilter !== true) {
      props.onUnlimitedFilterToggle();
      setActiveFilterItem(null);
      clearFilter();
      // props.onFilterRemoveForOthers();
    } else {
      props.onFilterRemove();
      setActiveFilterItem(null);
      clearFilter();
    }
  };

  // const handleUnlimitedFilterClick = () => {
  //  if(unlimitedFilter === true){
  //   props.onUnlimitedFilterToggle()
  //  }
  // }

  const filterListItems = [
    "Cheapest",
    "Best price/GB",
    "Largest GB",
    "Longest Validity",
  ];

  // Size Filter
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(200);
  const handleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  // Validity Filter
  const [minValidityValue, setMinValidityValue] = useState(0);
  const [maxValidityValue, setMaxValidityValue] = useState(365);
  const handleValidityInput = (e) => {
    setMinValidityValue(e.minValue);
    setMaxValidityValue(e.maxValue);
  };

  // Price Filter
  const [minPriceValue, setMinPriceValue] = useState(0);
  const [maxPriceValue, setMaxPriceValue] = useState(300);
  const handlePriceInput = (e) => {
    setMinPriceValue(e.minValue);
    setMaxPriceValue(e.maxValue);
  };

  const handleFilterChange = () => {
    // Pass all the filter values as an object to the onFilterChange prop
    props.onFilterChange({
      minValue,
      maxValue,
      minValidityValue,
      maxValidityValue,
      minPriceValue,
      maxPriceValue,
    });
  };

  // Open filter Tray
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();
  const divRef = useRef();

  const handleButtonClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleOutsideClick = (event) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target) &&
      divRef.current &&
      !divRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const clearFilter = () => {
    setMinValue(0);
    setMaxValue(200);
    setMinValidityValue(0);
    setMaxValidityValue(365);
    setMinPriceValue(0);
    setMaxPriceValue(300);
  };

  return (
    <div className="table-filter-section m-0">
      <div
        style={{ gap: "20px" }}
        className="w-100 d-flex flex-wrap justify-content-between align-items-center mb-3"
      >
        <div className="filter-tabs">
          {filterListItems.map((item, index) => (
            // <li
            //   key={index}
            //   className={index === activeFilterItem ? "tab-active" : ""}
            //   ref={filterBtnRef}
            //   onClick={() => handleFilterClick(index)}
            // >
            //   {item}
            // </li>
            <button
              key={index}
              className={index === activeFilterItem ? "tab-active" : ""}
              ref={filterBtnRef}
              onClick={() => handleFilterClick(index)}
            >
              {item}
            </button>
          ))}
        </div>
        <div
          className={`${
            props.unlimitedFilter ? "unlimited-active" : ""
          } unlimited-filter d-flex justify-content-start align-items-start`}
          onClick={unlimitedToggle}
        >
          <button className="toggle-switch" aria-label="Toggle Light Mode">
            <span></span>
            <span></span>
          </button>
          <p className="m-0 ml-2">Unlimited plans</p>
        </div>
      </div>
      <div
        style={{ gap: "20px" }}
        className="w-100 d-flex flex-wrap justify-content-between align-items-center mb-3"
      >
        <div className="search-filter">
          <label htmlFor="">Search: </label>
          <input
            type="text"
            placeholder=""
            value={props.searchText}
            onChange={props.handleSearchChange}
          />
        </div>
        <div className="filter-btn-section">
          <button
            ref={buttonRef}
            onClick={handleButtonClick}
            className="filter-btn"
          >
            <label htmlFor="">Filter</label>
            <img className="table-filter-icon" src={tableFilterIcon} alt="" />
          </button>
          {isOpen && (
            <div ref={divRef} className="filter-btn-tray-section">
              <div className="size-filter mb-2">
                <label htmlFor="">Size:</label>
                <span>
                  {minValue}GB - {maxValue}GB
                </span>
                <MultiRangeSlider
                  min={0}
                  max={200}
                  // step={5}
                  minValue={minValue}
                  maxValue={maxValue}
                  onInput={(e) => {
                    handleInput(e);
                    handleFilterChange();
                    // props.onFilterChange({ minValue, maxValue, minValidityValue, maxValidityValue, minPriceValue, maxPriceValue });
                  }}
                />
              </div>
              <div className="size-filter mb-2">
                <label htmlFor="">Validity:</label>
                <span>
                  {minValidityValue}D - {maxValidityValue}D
                </span>
                <MultiRangeSlider
                  min={0}
                  max={365}
                  // step={5}
                  minValue={minValidityValue}
                  maxValue={maxValidityValue}
                  onInput={(e) => {
                    handleValidityInput(e);
                    handleFilterChange();
                    // props.onFilterChange({ minValue, maxValue, minValidityValue, maxValidityValue, minPriceValue, maxPriceValue });
                  }}
                />
              </div>
              <div className="size-filter mb-2">
                <label htmlFor="">Price:</label>
                <span>
                  £{minPriceValue} - £{maxPriceValue}
                </span>
                <MultiRangeSlider
                  min={0}
                  max={300}
                  // step={5}
                  minValue={minPriceValue}
                  maxValue={maxPriceValue}
                  onInput={(e) => {
                    handlePriceInput(e);
                    handleFilterChange();
                    // props.onFilterChange({ minValue, maxValue, minValidityValue, maxValidityValue, minPriceValue, maxPriceValue });
                  }}
                />
              </div>
              <div className="d-flex justify-content-end align-items-center mt-3">
                <button onClick={clearFilter} className="btn btn-dark btn-sm">
                  clear
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Plans = (props) => {
  useScrollToTop();
  const [icon, setIcon] = useState(false);
  const [buy, setBuy] = useState(false);
  const [regionId, setRegionId] = useState();
  const [alpha2, setAlpha2] = useState();
  const [premium, setPremium] = useState([{}]);
  const [popular, setPopular] = useState([{}]);
  const [unlimited, setUnlimited] = useState([{}]);
  const [country, setCountry] = useState();
  const [countryOrRegion, setCountryOrRegion] = useState();
  const [message, setMessage] = useState();
  const [plansFound, setPlansFound] = useState(false);
  const [userPlans, setUserPlans] = useState([{}]);
  const [countryBg, setCountryBg] = useState();
  const [detailsState, setDetailsState] = useState([]);
  const [buyDetailsState, setBuyDetailsState] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState({});

  //for search
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [dummy, setDummy] = useState();
  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [europeItems, setEuropeItems] = useState([]);
  const [asiaItems, setAsiaItems] = useState([]);
  const [africaItems, setAfricaItems] = useState([]);
  const [americasItems, setAmericasItems] = useState([]);
  const [oceaniaItems, setOceaniaItems] = useState([]);
  const [countryBasedOnRegion, setCountrybasedOnRegion] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const history = useHistory();
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [plan, setPlan] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [isValidSuggestion, setIsValidSuggestion] = useState(true);
  const [showLogin, setShowLoginPopUp] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [unlimitedOrOther, setUnlimitedOrOther] = useState([{}]);
  const [randomplans2, setRandomPlans2] = useState();

  // Meta tag info
  const [metaTagInfo, setMetaTagInfo] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [50]);
  }, []);
  // Tooltip

  const [placement, setPlacement] = useState("bottom");

  // const OutlineTooltip = ({ placement, message, target, ...rest }) => {
  //   return (
  //     <TooltipBottom placement={placement} target={target}>
  //       <div className="w-100 d-flex align-items-start">
  //         <p className="mr-2 mb-0" style={{ fontWeight: "500" }}>
  //           Plan:
  //         </p>
  //         <p className="mb-0 w-100">{message}</p>
  //       </div>
  //     </TooltipBottom>
  //   );
  // };

  const handleFilterChange = (filterValues) => {
    // Destructure the filter values
    const {
      minValue,
      maxValue,
      minValidityValue,
      maxValidityValue,
      minPriceValue,
      maxPriceValue,
    } = filterValues;

    // Filter the plans based on the selected ranges
    const filteredData = userPlans.filter((plan) => {
      const size = parseFloat(plan.SIZE);
      const price = parseFloat(plan.PRICE.replace("£", ""));

      return (
        size >= minValue &&
        size <= maxValue &&
        parseFloat(plan.VALIDITY) >= minValidityValue &&
        parseFloat(plan.VALIDITY) <= maxValidityValue &&
        price >= minPriceValue &&
        price <= maxPriceValue
      );
    });

    setFilteredPlans(filteredData);
  };

  const filterPlans = () => {
    const filteredData = userPlans.filter((plan) => {
      // Convert all the plan data fields to lowercase for case-insensitive search
      const searchData = Object.values(plan)
        .map((value) => String(value).toLowerCase())
        .join(" ");
      return searchData.includes(searchText.toLowerCase());
    });

    setFilteredPlans(filteredData);
  };
  useEffect(() => {
    RegionListApi();
    countryList();
  }, []); // Empty dependency array ensures this runs only on component mount

  const RegionListApi = () => {
    // RegionService.
    RegionListService.regionList().then((response) => {
      setRegions(response.data);
      // setIsLoading(false)
      // setRegionId(response.data.map((rId)=>rId.regionId));
    });
  };
  const countryList = () => {
    RegionListService.countryAccordingToRegionList().then((response) => {
      setCountrybasedOnRegion(response.data);
      setEuropeItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Europe"
        )
      );
      setAsiaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Asia"
        )
      );
      setAfricaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Africa"
        )
      );
      setAmericasItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Americas"
        )
      );
      setOceaniaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Oceania"
        )
      );
    });
  };
  const handleInputChange = (event) => {
    setActiveSuggestionIndex(0);

    const userInputValue = event.target.value.trim();
    setUserInput(userInputValue);

    if (userInputValue === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    // Convert user input to lowercase
    const userInputValueLowercase = userInputValue.toLowerCase();

    // Filter regions based on user input
    const regionSuggestions = regions.filter((region) =>
      region.regionDescription.toLowerCase().startsWith(userInputValueLowercase)
    );

    // Filter countries based on user input
    const countrySuggestions = allCountries.filter((country) =>
      country.countryDescription
        .toLowerCase()
        .startsWith(userInputValueLowercase)
    );

    // Combine region and country suggestions and set the suggestions state
    const combinedSuggestions = [
      ...regionSuggestions.map((region) => ({
        suggestionType: "region",
        description: region.regionDescription,
        regionId: region.regionId,
      })),
      ...countrySuggestions.map((country) => ({
        suggestionType: "country",
        description: country.countryDescription,
        Alpha2: country.alpha2,
      })),
    ];

    setIsValidSuggestion(combinedSuggestions.length > 0);
    setSuggestions(combinedSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionSearch = (suggestion) => {
    if (suggestion.suggestionType === "region") {
      // Handle region search using the regionId
      // Call your API here with the regionId
      history.push(`/rplans/${suggestion.regionId}/${suggestion.description}`);
    } else if (suggestion.suggestionType === "country") {
      // Handle country search using the Alpha2
      // Call your API here with the Alpha2
      history.push(`/plans/${suggestion.Alpha2}/${suggestion.description}`);
    }
  };
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  const handleSearch = (suggestionType, regionId, Alpha2, description) => {
    if (suggestionType === "region") {
      // Handle region search using the regionId
      // Call your API here with the regionId
      history.push(`/rplans/${regionId}/${description}`);
    } else if (suggestionType === "country") {
      // Handle country search using the Alpha2
      // Call your API here with the Alpha2
      history.push(`/plans/${Alpha2}/${description}`);
    }
  };

  const activeSuggestionRef = useRef(null);

  const handleKeyDown = (event) => {
    const activeSuggestionElement = activeSuggestionRef.current;

    if (event.key === "ArrowDown") {
      // Move to the next suggestion when the down arrow key is pressed
      setActiveSuggestionIndex(
        (prevIndex) => (prevIndex + 1) % suggestions.length
      );
    } else if (event.key === "ArrowUp") {
      // Move to the previous suggestion when the up arrow key is pressed
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
    } else if (event.key === "Enter") {
      // Handle the Enter key press separately
      if (suggestions.length > 0 && suggestions[activeSuggestionIndex]) {
        // Set the selected suggestion before calling handleSearch
        setUserInput(suggestions[activeSuggestionIndex].description);
        setShowSuggestions(false); // Hide the suggestion list when Enter is pressed

        handleSearch(
          suggestions[activeSuggestionIndex].suggestionType,
          suggestions[activeSuggestionIndex].regionId,
          suggestions[activeSuggestionIndex].Alpha2,
          suggestions[activeSuggestionIndex].description
        );
      }
    }

    // Scroll the active suggestion into view when using the arrow keys
    if (activeSuggestionElement) {
      activeSuggestionElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };
  const allCountries = [
    ...europeItems,
    ...asiaItems,
    ...africaItems,
    ...americasItems,
    ...oceaniaItems,
  ];

  useEffect(() => {
    // Initialize the detailsState with an array of false values based on the userPlans length
    setDetailsState(new Array(userPlans.length).fill(false));
  }, [userPlans]);
  // const [alpha2, setAlpha2] = useState(props.match.params.alpha2);
  const regionPlansAPI = () => {
    PlansBasedOnRegionService.regionPlan(props.match.params.regionId)
      .then((response) => {
        if (response.status === 200) {
          setPlan(response.data);
          setCountryOrRegion(props.match.params.region);
          setRegionId(props.match.params.regionId);
          const formattedData = response.data.map((item) => {
            const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
            if (dataSize === null) {
              // Handle the case where the data field does not contain a valid numeric value
              return {
                PROVIDER: item.providerName,
                PLANNAME: item.planName,
                SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                VALIDITY: `${item.validity} Days`,
                "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
                PRICE: `£${item.price}`,
                planId: item,
              };
            }
            const pricePerGB = item.price / dataSize;
            const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
            return {
              PROVIDER: item.providerName,
              PLANNAME: item.planName,
              SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
              VALIDITY: `${item.validity} Days`,
              "PRICE/GB":
                item.unlimitedPlan == true
                  ? "NA/-"
                  : truncatedPricePerGB.toFixed(1),
              PRICE: `£${item.price}`,
              planId: item,
            };
          });
          setPlansFound(true);
          setUserPlans(formattedData);
          const regionPopularPlans = response.data.slice(0, 2);
          // setPopular(regionPopularPlans);
          const regionUnlimitedPlans = response.data
            .filter((plan) => plan.unlimitedPlan === true)
            .slice(0, 2);
          setUnlimited(regionUnlimitedPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              break;
            case 403:
              // this.props.history.push(`/accessDenied`)
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");

              break;
          }
        }
      });
  };

  const globalPlansAPI = () => {
    GlobalPlansService.globalPlan()
      .then((response) => {
        if (response.status === 200) {
          setPlan(response.data);
          const formattedData = response.data.map((item) => {
            const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
            if (dataSize === null) {
              // Handle the case where the data field does not contain a valid numeric value
              return {
                PROVIDER: item.providerName,
                PLANNAME: item.planName,
                SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                VALIDITY: `${item.validity} Days`,
                "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
                PRICE: `£${item.price}`,
                planId: item,
              };
            }
            const pricePerGB = item.price / dataSize;
            const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
            return {
              PROVIDER: item.providerName,
              PLANNAME: item.planName,
              SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
              VALIDITY: `${item.validity} Days`,
              "PRICE/GB":
                item.unlimitedPlan == true
                  ? "NA/-"
                  : truncatedPricePerGB.toFixed(1),
              PRICE: `£${item.price}`,
              planId: item,
            };
          });
          setPlansFound(true);
          setUserPlans(formattedData);
          const regionPopularPlans = response.data.slice(0, 2);
          // setPopular(regionPopularPlans);
          const regionUnlimitedPlans = response.data
            .filter((plan) => plan.unlimitedPlan === true)
            .slice(0, 2);
          setUnlimited(regionUnlimitedPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              break;
            case 403:
              // this.props.history.push(`/accessDenied`)
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");

              break;
          }
        }
      });
  };

  const plansAPI = () => {
    PlansBasedOnCountryService.counrtyPlan(props.match.params.alpha2)
      .then((response) => {
        if (response.status === 200) {
          setPlan(response.data);
          setCountryOrRegion(props.match.params.country);
          setAlpha2(props.match.params.alpha2);
          const formattedData = response.data.map((item) => {
            const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
            if (dataSize === null) {
              // Handle the case where the data field does not contain a valid numeric value
              return {
                PROVIDER: item.providerName,
                PLANNAME: item.planName,
                SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                VALIDITY: `${item.validity} Days`,
                "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
                PRICE: `£${item.price}`,
                planId: item,
              };
            }
            const pricePerGB = item.price / dataSize;
            const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
            return {
              PROVIDER: item.providerName,
              PLANNAME: item.planName,
              SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
              VALIDITY: `${item.validity} Days`,
              "PRICE/GB":
                item.unlimitedPlan == true
                  ? "NA/-"
                  : truncatedPricePerGB.toFixed(1),
              PRICE: `£${item.price}`,
              planId: item,
            };
          });
          setPlansFound(true);
          setUserPlans(formattedData);
          setCountry(
            response.data.map((item) => item.country.countryDescription)
          );
          const popularPlans = response.data.slice(0, 2);
          // setPopular(popularPlans);
          const unlimitedPlans = response.data
            .filter((plan) => plan.unlimitedPlan === true)
            .slice(0, 2);
          setUnlimited(unlimitedPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          // this.setState({
          //   message: error.message,
          //   loading: false
          // });
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              // this.setState({
              //   loading: false
              // }, );
              break;
            case 403:
              // this.props.history.push(`/accessDenied`)
              break;
            case 500:
            case 404:
            case 406:
              // this.setState({
              //   message: "No Plans Available",
              //   loading: false
              // });
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              // this.setState({
              //   // message: error.response.data.messageCode,
              //   loading: false
              // });
              break;
            default:
              setMessage("Unknown Error");

              break;
          }
        }
      });
  };
  const tileRegionPlansAPI = () => {
    PlansBasedOnRegionService.tileRegionPlan(props.match.params.regionId)
      .then((response) => {
        if (response.status === 200) {
          // setPlan(response.data);
          setPlansFound(true);
          const popularPlans = response.data.slice(0, 2);
          setPopular(popularPlans);
          const premium = response.data.slice(2, 4);
          setPremium(premium);
          const unlimitedOrOther = response.data.slice(4, 6);
          setUnlimitedOrOther(unlimitedOrOther);
          const randomPlans = response.data.slice(6);
          setRandomPlans2(randomPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              break;
            case 403:
              // this.props.history.push(`/accessDenied`)
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");

              break;
          }
        }
      });
  };

  const tileGlobalPlansAPI = () => {
    GlobalPlansService.globalAllPlan()
      .then((response) => {
        if (response.status === 200) {
          // setPlan(response.data);
          setPlansFound(true);
          const popularPlans = response.data.slice(0, 2);
          setPopular(popularPlans);
          const premium = response.data.slice(2, 4);
          setPremium(premium);
          const unlimitedOrOther = response.data.slice(4, 6);
          setUnlimitedOrOther(unlimitedOrOther);
          const randomPlans = response.data.slice(6);
          setRandomPlans2(randomPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              break;
            case 403:
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
  };

  const tilePlansAPI = () => {
    PlansBasedOnCountryService.tileCountryPlan(props.match.params.alpha2)
      .then((response) => {
        if (response.status === 200) {
          // setPlan(response.data);
          setPlansFound(true);
          const popularPlans = response.data.slice(0, 2);
          setPopular(popularPlans);
          const premium = response.data.slice(2, 4);
          setPremium(premium);
          const unlimitedOrOther = response.data.slice(4, 6);
          setUnlimitedOrOther(unlimitedOrOther);
          const randomPlans = response.data.slice(6);
          setRandomPlans2(randomPlans);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage(error.message);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              break;
            case 403:
              break;
            case 500:
            case 404:
            case 406:
              setPlansFound(false);
              setMessage("No Plans Available");
              break;
            case 412:
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
  };
  useEffect(() => {
    // Check if 'alpha2' is available in the route params
    if (!props.match.params.alpha2 && !props.match.params.regionId) {
      globalPlansAPI();
      tileGlobalPlansAPI();
    }

    // Add other conditions if needed
  }, []);

  useEffect(() => {
    // Check if 'alpha2' is available in the route params
    if (props.match.params.alpha2) {
      plansAPI();
      tilePlansAPI();
    }
    // Check if 'regionId' is available in the route params
    else if (props.match.params.regionId) {
      regionPlansAPI();
      tileRegionPlansAPI();
    }
    // Add other conditions if needed
  }, [props.match.params.alpha2, props.match.params.regionId]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1100) {
        setIcon(true);
        setBuy(true);
      } else {
        setIcon(false);
        setBuy(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sortPlansByPrice = (plans) => {
    if (!plans || plans.length === 0) {
      return [];
    }
    const sortedPlans = plans
      .slice()
      .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    return sortedPlans.map((item, index) => {
      const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
      if (dataSize === null) {
        // Handle the case where the data field does not contain a valid numeric value
        return {
          PROVIDER: item.providerName,
          PLANNAME: item.planName,
          SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
          VALIDITY: `${item.validity} Days`,
          "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
          PRICE: `£${item.price}`,
          planId: item,
        };
      }
      const pricePerGB = item.price / dataSize;
      const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
      return {
        PROVIDER: item.providerName,
        PLANNAME: item.planName,
        SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
        VALIDITY: `${item.validity} Days`,
        "PRICE/GB":
          item.unlimitedPlan == true ? "NA/-" : truncatedPricePerGB.toFixed(1),
        PRICE: `£${item.price}`,
        planId: item,
      };
    });
  };

  const handleCheapestFilterClick = () => {
    if (unlimitedFilter == true) {
      setPlan(forUnlimitedPlans);
      const sortedUnlimitedPlans = sortPlansByPrice(forUnlimitedPlans);
      setUserPlans(sortPlansByPrice(forUnlimitedPlans));
    } else {
      if (props.match.params.regionId) {
        PlansBasedOnRegionService.regionPlan(props.match.params.regionId).then(
          (response) => {
            if (response.status === 200) {
              setPlan(response.data);
              const sortedPlans = sortPlansByPrice(response.data);
              setUserPlans(sortedPlans); // Update state with the new sorted array
            }
          }
        );
      }
      if (props.match.params.alpha2) {
        PlansBasedOnCountryService.counrtyPlan(props.match.params.alpha2).then(
          (response) => {
            setPlan(response.data);
            if (response.status === 200) {
              const sortedPlans = sortPlansByPrice(response.data);
              setUserPlans(sortedPlans); // Update state with the new sorted array
            }
          }
        );
      }
      if (!props.match.params.alpha2 && !props.match.params.regionId) {
        GlobalPlansService.globalPlan().then((response) => {
          setPlan(response.data);
          if (response.status === 200) {
            const sortedPlans = sortPlansByPrice(response.data);
            setUserPlans(sortedPlans); // Update state with the new sorted array
          }
        });
      }
    }
  };

  const [unlimitedFilter, setUnlimitedFilter] = useState(false);

  const handleUnlimitedToggle = () => {
    setUnlimitedFilter(!unlimitedFilter);
  };

  const forUnlimited = (plans) => {
    if (!plans || plans.length === 0) {
      return [];
    }

    return plans.map((item) => {
      const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
      if (dataSize === null) {
        // Handle the case where the data field does not contain a valid numeric value
        return {
          PROVIDER: item.providerName,
          PLANNAME: item.planName,
          SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
          VALIDITY: `${item.validity} Days`,
          "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
          PRICE: `£${item.price}`,
          planId: item,
        };
      }
      const pricePerGB = item.price / dataSize;
      const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
      return {
        PROVIDER: item.providerName,
        PLANNAME: item.planName,
        SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
        VALIDITY: `${item.validity} Days`,
        "PRICE/GB":
          item.unlimitedPlan == true ? "NA/-" : truncatedPricePerGB.toFixed(1),
        PRICE: `£${item.price}`,
        planId: item,
      };
    });
  };

  const [forUnlimitedPlans, setForUnlimitedPlans] = useState([]);
  const unlimitedToggle = () => {
    if (props.match.params.regionId) {
      PlansBasedOnRegionService.regionPlan(props.match.params.regionId).then(
        (response) => {
          if (response.status === 200) {
            const filteredData = response.data.filter(
              (plan) => plan.unlimitedPlan === true
            );
            setPlan(filteredData);
            const unlimitedPlans = forUnlimited(filteredData);
            setForUnlimitedPlans(filteredData);
            setUserPlans(unlimitedPlans); // Update state with the new sorted array
          }
        }
      );
    }
    if (props.match.params.alpha2) {
      PlansBasedOnCountryService.counrtyPlan(props.match.params.alpha2).then(
        (response) => {
          // setPlan(response.data);
          if (response.status === 200) {
            const filteredData = response.data.filter(
              (plan) => plan.unlimitedPlan === true
            );
            setPlan(filteredData);
            const unlimitedPlans = sortPlansByPrice(filteredData);
            setForUnlimitedPlans(filteredData);
            setUserPlans(unlimitedPlans); // Update state with the new sorted array
          }
        }
      );
    }

    if (!props.match.params.alpha2 && !props.match.params.regionId) {
      GlobalPlansService.globalPlan().then((response) => {
        // setPlan(response.data);
        if (response.status === 200) {
          const filteredData = response.data.filter(
            (plan) => plan.unlimitedPlan === true
          );
          setPlan(filteredData);
          const unlimitedPlans = sortPlansByPrice(filteredData);
          setForUnlimitedPlans(filteredData);
          setUserPlans(unlimitedPlans); // Update state with the new sorted array
        }
      });
    }
  };

  function extractNumericValue(dataString) {
    const numericValue = parseFloat(dataString); // Attempt to convert the whole string to a number
    return isNaN(numericValue) ? null : numericValue; // Return null if the conversion fails
  }

  const handleBestPricePerGBFilterClick = () => {
    if (unlimitedFilter == true) {
      // Sorting the data based on "PRICE/GB" column in ascending order
      const sortBestPricePerGBUnlimitedData = forUnlimitedPlans.map((item) => {
        const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
        if (dataSize === null) {
          // Handle the case where the data field does not contain a valid numeric value
          return {
            PROVIDER: item.providerName,
            PLANNAME: item.planName,
            SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
            VALIDITY: `${item.validity} Days`,
            "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
            PRICE: `£${item.price}`,
            planId: item,
          };
        }
        const pricePerGB = item.price / dataSize;
        const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
        return {
          PROVIDER: item.providerName,
          PLANNAME: item.planName,
          SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
          VALIDITY: `${item.validity} Days`,
          "PRICE/GB":
            item.unlimitedPlan == true
              ? "NA/-"
              : truncatedPricePerGB.toFixed(1),
          PRICE: `£${item.price}`,
          planId: item,
        };
      });
      const sortedUnlimitedPlans = sortBestPricePerGBUnlimitedData
        .slice()
        .sort((a, b) => parseFloat(a["PRICE/GB"]) - parseFloat(b["PRICE/GB"]));

      setUserPlans(sortedUnlimitedPlans);
    } else {
      if (props.match.params.regionId) {
        PlansBasedOnRegionService.regionPlan(props.match.params.regionId).then(
          (response) => {
            if (response.status === 200) {
              setPlan(response.data);
              const sortBestPricePerGBData = response.data.map((item) => {
                const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
                if (dataSize === null) {
                  // Handle the case where the data field does not contain a valid numeric value
                  return {
                    PROVIDER: item.providerName,
                    PLANNAME: item.planName,
                    SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                    VALIDITY: `${item.validity} Days`,
                    "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
                    PRICE: `£${item.price}`,
                    planId: item,
                  };
                }
                const pricePerGB = item.price / dataSize;
                const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
                return {
                  PROVIDER: item.providerName,
                  PLANNAME: item.planName,
                  SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                  VALIDITY: `${item.validity} Days`,
                  "PRICE/GB":
                    item.unlimitedPlan == true
                      ? "NA/-"
                      : truncatedPricePerGB.toFixed(1),
                  PRICE: `£${item.price}`,
                  planId: item,
                };
              });

              // Sorting the data based on "PRICE/GB" column in ascending order
              sortBestPricePerGBData.sort(
                (a, b) => parseFloat(a["PRICE/GB"]) - parseFloat(b["PRICE/GB"])
              );

              setUserPlans(sortBestPricePerGBData);
            }
          }
        );
      }

      if (props.match.params.alpha2) {
        PlansBasedOnCountryService.counrtyPlan(props.match.params.alpha2).then(
          (response) => {
            if (response.status === 200) {
              setPlan(response.data);
              const sortBestPricePerGBData = response.data.map((item) => {
                const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
                if (dataSize === null) {
                  // Handle the case where the data field does not contain a valid numeric value
                  return {
                    PROVIDER: item.providerName,
                    PLANNAME: item.planName,
                    SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                    VALIDITY: `${item.validity} Days`,
                    "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
                    PRICE: `£${item.price}`,
                    planId: item,
                  };
                }
                const pricePerGB = item.price / dataSize;
                const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
                return {
                  PROVIDER: item.providerName,
                  PLANNAME: item.planName,
                  SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
                  VALIDITY: `${item.validity} Days`,
                  "PRICE/GB":
                    item.unlimitedPlan == true
                      ? "NA/-"
                      : truncatedPricePerGB.toFixed(1),
                  PRICE: `£${item.price}`,
                  planId: item,
                };
              });

              // Sorting the data based on "PRICE/GB" column in ascending order
              sortBestPricePerGBData.sort(
                (a, b) => parseFloat(a["PRICE/GB"]) - parseFloat(b["PRICE/GB"])
              );

              setUserPlans(sortBestPricePerGBData);
            }
          }
        );
      }
    }
  };

  const sortPlansByLargestGB = (plans) => {
    const sortedPlans = plans.slice().sort((a, b) => {
      const sizeA = parseInt(a.data.split(" ")[0]);
      const sizeB = parseInt(b.data.split(" ")[0]);
      return sizeB - sizeA;
    });
    return sortedPlans.map((item, index) => {
      const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
      if (dataSize === null) {
        // Handle the case where the data field does not contain a valid numeric value
        return {
          PROVIDER: item.providerName,
          PLANNAME: item.planName,
          SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
          VALIDITY: `${item.validity} Days`,
          "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
          PRICE: `£${item.price}`,
          planId: item,
        };
      }
      const pricePerGB = item.price / dataSize;
      const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
      return {
        PROVIDER: item.providerName,
        PLANNAME: item.planName,
        SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
        VALIDITY: `${item.validity} Days`,
        "PRICE/GB":
          item.unlimitedPlan == true ? "NA/-" : truncatedPricePerGB.toFixed(1),
        PRICE: `£${item.price}`,
        planId: item,
      };
    });
  };

  const handleLargestGBFilterClick = () => {
    if (unlimitedFilter == true) {
      setPlan(forUnlimitedPlans);
      const sortedUnlimitedPlans = sortPlansByLargestGB(forUnlimitedPlans);
      setUserPlans(sortedUnlimitedPlans);
    } else {
      if (props.match.params.regionId) {
        PlansBasedOnRegionService.regionPlan(props.match.params.regionId).then(
          (response) => {
            setPlan(response.data);
            if (response.status === 200) {
              const sortedPlansLGB = sortPlansByLargestGB(response.data);
              setUserPlans(sortedPlansLGB); // Update state with the new sorted array
            }
          }
        );
      }
      if (props.match.params.alpha2) {
        PlansBasedOnCountryService.counrtyPlan(props.match.params.alpha2).then(
          (response) => {
            setPlan(response.data);
            if (response.status === 200) {
              const sortedPlansLGB = sortPlansByLargestGB(response.data);
              setUserPlans(sortedPlansLGB); // Update state with the new sorted array
            }
          }
        );
      }
      if (!props.match.params.alpha2 && !props.match.params.regionId) {
        GlobalPlansService.globalPlan().then((response) => {
          setPlan(response.data);
          if (response.status === 200) {
            const sortedPlansLGB = sortPlansByLargestGB(response.data);
            setUserPlans(sortedPlansLGB); // Update state with the new sorted array
          }
        });
      }
    }
  };

  const sortPlansByLongestValidity = (plans) => {
    const sortedPlans = plans
      .slice()
      .sort((a, b) => parseInt(b.validity) - parseInt(a.validity));
    return sortedPlans.map((item, index) => {
      const dataSize = extractNumericValue(item.data); // Extract numeric value from the data field
      if (dataSize === null) {
        // Handle the case where the data field does not contain a valid numeric value
        return {
          PROVIDER: item.providerName,
          PLANNAME: item.planName,
          SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
          VALIDITY: `${item.validity} Days`,
          "PRICE/GB": "N/A", // Set a placeholder value for non-numeric data
          PRICE: `£${item.price}`,
          planId: item,
        };
      }
      const pricePerGB = item.price / dataSize;
      const truncatedPricePerGB = Math.trunc(pricePerGB * 10) / 10; // Truncate to one digit after the decimal point
      return {
        PROVIDER: item.providerName,
        PLANNAME: item.planName,
        SIZE: item.unlimitedPlan == true ? "Unlimited" : item.data,
        VALIDITY: `${item.validity} Days`,
        "PRICE/GB":
          item.unlimitedPlan == true ? "NA/-" : truncatedPricePerGB.toFixed(1),
        PRICE: `£${item.price}`,
        planId: item,
      };
    });
  };

  const handleLongestValidityFilterClick = () => {
    if (unlimitedFilter == true) {
      setPlan(forUnlimitedPlans);
      const sortedUnlimitedPlans =
        sortPlansByLongestValidity(forUnlimitedPlans);
      setUserPlans(sortedUnlimitedPlans);
    } else {
      if (props.match.params.regionId) {
        PlansBasedOnRegionService.regionPlan(props.match.params.regionId).then(
          (response) => {
            setPlan(response.data);
            if (response.status === 200) {
              const sortedPlansLG = sortPlansByLongestValidity(response.data);
              setUserPlans(sortedPlansLG); // Update state with the new sorted array
            }
          }
        );
      }
      if (props.match.params.alpha2) {
        PlansBasedOnCountryService.counrtyPlan(props.match.params.alpha2).then(
          (response) => {
            setPlan(response.data);
            if (response.status === 200) {
              const sortedPlansLG = sortPlansByLongestValidity(response.data);
              setUserPlans(sortedPlansLG); // Update state with the new sorted array
            }
          }
        );
      }
      if (!props.match.params.alpha2 && !props.match.params.regionId) {
        GlobalPlansService.globalPlan().then((response) => {
          setPlan(response.data);
          if (response.status === 200) {
            const sortedPlansLG = sortPlansByLongestValidity(response.data);
            setUserPlans(sortedPlansLG); // Update state with the new sorted array
          }
        });
      }
    }
  };

  useEffect(() => {
    fetch(
      `https://api.unsplash.com/search/photos?page=1&query=${
        props.match.params.country
          ? props.match.params.country
          : props.match.params.region
          ? props.match.params.region
          : "earth"
      }&client_id=2xy-v7zAMswuSraDWvCI6KJ8qzgOh60UExiwJfCwuQ4`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (props.match.params.region == "T20 WC Region 1") {
          setCountryBg(t20Wc1);
        } else if (props.match.params.region == "T20 WC Region 2") {
          setCountryBg(t20Wc2);
        } else {
          setCountryBg(data.results[1].urls.full);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }, [props.match.params.country]);

  const [details, setDetails] = useState([]);
  const columns = [
    {
      key: `${plansFound ? "avatar" : ""}`,
      label: "",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    {
      key: "PROVIDER",
      _style: { width: "15%" },
    },
    {
      key: "PLANNAME",
      _style: { width: "15%" },
    },
    {
      key: "SIZE",
      _style: { width: "10%" },
    },
    {
      key: "VALIDITY",
      _style: { width: "10%" },
    },
    {
      key: "PRICE/GB",
      _style: { width: "10%" },
    },

    {
      key: "PRICE",
      _style: { width: "5%" },
    },

    // {
    //   key: `${plansFound ? "show_details" : ""}`,
    //   label: "",
    //   _style: { width: "10%" },
    //   filter: false,
    //   sorter: false,
    // },
    {
      key: `${plansFound ? "show_Buy" : ""}`,
      label: "",
      _style: { width: "15%" },
      filter: false,
      sorter: false,
    },
  ];
  const usersData = [
    {
      id: 1,

      PROVIDER: "Samppa Nori",
      avatar: logo,
      PLANNAME: "eSIM Pheonix",
      SIZE: "100MB",
      VALIDITY: "100 Days",
      PRICEGB: "$30",
      PRICE: "$3",
      view: "",
    },
  ];
  // const getBadge = (VALIDITY) => {
  //   switch (VALIDITY) {
  //     case "Active":
  //       return "success";
  //     case "Inactive":
  //       return "secondary";
  //     case "Pending":
  //       return "warning";
  //     case "Banned":
  //       return "danger";
  //     default:
  //       return "primary";
  //   }
  // };
  // const toggleDetails = (index) => {
  //   const position = details.indexOf(index);
  //   let newDetails = details.slice();
  //   if (position !== -1) {
  //     newDetails.splice(position, 1);
  //   } else {
  //     newDetails = [...details, index];
  //   }
  //   setDetails(newDetails);
  // };
  const toggleDetails = (index) => {
    setDetailsState((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const toggleBuy = (planId, rowIndex) => {
    // Check user verification status in local storage
    const email = localStorage.getItem("emailId");
    if (!email || email === "" || email === undefined || email === null) {
      setShowLoginPopUp(true);
    } else if (email || email !== "" || email !== undefined) {
      // var userVerificationStatus = localStorage.getItem("userVerificationStatus");
      // if(localStorage.getItem("userVerificationStatus") == 0){
      if (localStorage.getItem("token")) {
        const token = localStorage.getItem("token");
        const user = jwtDecode(token);
        const exp = user.exp;
        const currentTime = Math.floor(Date.now() / 1000);
        if (exp < currentTime) {
          localStorage.clear();
          history.push("/");
        }
        UserVarificationService.getVerificationStatus(
          localStorage.getItem("userId")
        ).then((response) => {
          if (response.status == 200) {
            // localStorage.setItem("userVerificationStatus", response.data);
            const userVerificationStatus = response.data;
            if (!userVerificationStatus || userVerificationStatus === "0") {
              // Show popup for unverified user
              setShowVerifyPopup(true);
            } else if (
              userVerificationStatus ||
              userVerificationStatus === "1"
            ) {
              // User is verified
              DashboardDetailsService.dashboardDetailService(
                localStorage.getItem("userId")
              ).then((response) => {
                if (response.status === 200) {
                  localStorage.setItem(
                    "referralCode",
                    response.data.referralCode
                  );
                  const plan = planId;
                  localStorage.setItem("plan", JSON.stringify(plan));
                  history.push({
                    pathname: "/OrderSummary",
                    // state: { plan }
                  });
                }
              });
            }
          }
          // }

          // else

          // {
          //   // User is verified
          //   console.log("User is verified.");
          //   const plan = planId;
          //   localStorage.setItem("plan", JSON.stringify(plan));
          //   history.push({
          //     pathname: "/OrderSummary",
          //     // state: { plan }
          //   });
          // }
        });
      } else {
        localStorage.clear();
        history.push("/");
      }
    }
    // else {
    //   const plan = planId
    //   history.push({
    //     pathname: '/OrderSummary',
    //     state: { plan }
    //   });

    // }
  };

  const [showTopup, setShowTopUp] = useState(false);
  const [goAheadClicked, setGoAheadClicked] = useState(false);
  // const [topUpPlan, setTopUpPlan] = useState(null);
  const [esimPlanRecharge, setEsimPlanRecharge] = useState(null);

  const topUpClicked = (planId, rowIndex) => {
    const foundPlan = esimPlanTopUp.find(
      (p) => p.esimPlan.plan.planId.toString() === planId.planId.toString()
    );

    if (foundPlan) {
      const esimPlanRecharge = foundPlan.esimPlan.esimPlanRecharge[0];

      if (esimPlanRecharge.dataRemainingInMb > 0) {
        setTopUpPlan(foundPlan);
        setEsimPlanRecharge(esimPlanRecharge);
        setShowTopUp(true);

        const plan = planId;
        localStorage.setItem("plan", JSON.stringify(plan));
      } else {
        toggleBuy(planId, rowIndex);
      }
    }
  };

  const updateLoginPopupStatus = () => {
    setShowLoginPopUp(false);
  };
  const closeTopUpAlert = () => {
    setShowTopUp(false);
    localStorage.removeItem("plan");
  };
  const updateVarificationPopupStatus = () => {
    setShowVerifyPopup(false);
  };

  const proceedClicked = () => {
    history.push({
      pathname: "/OrderSummary",
      // state: { plan }
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    filterPlans();
  }, [searchText, userPlans]);

  const loginClicked = () => {
    history.push(`/login`);
  };

  const resendClicked = () => {
    const revarification = localStorage.getItem("emailId");
    UserVarificationService.resendVerificationLink(revarification).then(
      (response) => {
        if (response.status == 200) {
          setShowVerifyPopup(false);
        }
      }
    );
  };
  const ProceedAndCancelBtn = () => {
    return (
      <div className="w-100 d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-danger rounded-pill text-uppercase btn-md"
          onClick={() => closeTopUpAlert()}
        >
          CANCEL
        </button>
        <button
          className="btn rounded-pill text-uppercase btn-success btn-md"
          onClick={() => proceedClicked()}
        >
          PROCEED ANYWAY
        </button>
      </div>
    );
  };

  const LoginCtaBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={loginClicked}
      >
        LOGIN
      </button>
    );
  };

  const ResendEmailBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={resendClicked}
      >
        RESEND EMAIL
      </button>
    );
  };

  const [selectedPlan, setSelectedPlan] = useState(null);

  const [topUpPlan, setTopUpPlan] = useState();

  // useEffect(() => {
  //   const storedOrderDetails = localStorage.getItem("topUpIccid");
  //   if (storedOrderDetails) {
  //     const parsedOrderDetails = JSON.parse(storedOrderDetails);
  //     setTopUpPlan(parsedOrderDetails);
  //   }
  // }, []);

  const [esimPlanTopUp, setEsimPlanTopUp] = useState([]);
  useEffect(() => {
    // Retrieve the planId from the URL parameter
    const topUpList = localStorage.getItem("topUpList");
    const esimPlanTopUp = JSON.parse(topUpList) || [];
    setEsimPlanTopUp(esimPlanTopUp);
    const plan1String = localStorage.getItem("topUpIccid");

    if (plan1String) {
      const plan1 = JSON.parse(plan1String);
      const planId = plan1.esimPlan.plan.planId.toString();

      // Assuming 'plan' is an array containing plan objects
      const foundPlan = plan.find((p) => p.planId.toString() === planId);

      // Update the selectedPlan state
      setSelectedPlan(foundPlan);
    }
  }, [props.match.params.planId, plan]);

  useEffect(() => {
    if (!props.match.params.planId) {
      // Retrieve the planId from the URL parameter
      localStorage.removeItem("topUpIccid");
      localStorage.removeItem("myEsimList");
      localStorage.removeItem("topUpList");
    }
  }, [props.match.params.planId, plan]);

  const handleDetailsOpen = (data) => {
    setDetailsOpen(true);
    setCurrentDetails(data);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
    setCurrentDetails({});
  };

  const openModalDropdown = (res) => {
    // setModalDropdown(true);
  };

  // google analytics

  // useEffect(() => {
  //   document.querySelector('title').classList.add('notranslate');
  //   document.title = `GKT eSIM Plans Page/${props.match.params.alpha2 ? props.match.params.alpha2 : props.match.params.regionId ? props.match.params.regionId : "Global"}`;
  // }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  // useEffect(() => {
  //   document.querySelector('title').classList.add('notranslate');
  // document.title = `GKT eSIM Plans Page/${
  //   props.match.params.alpha2
  //     ? props.match.params.alpha2
  //     : props.match.params.regionId
  //     ? props.match.params.regionId
  //     : "Global"
  // }`;
  // }, []);

  useEffect(() => {
    const meta = {
      title: props.match.params.country
        ? props.match.params.country == "China"
          ? "Flexible eSIM Plans & Free Data in China | eSIM Mobile"
          : props.match.params.country == "United Kingdom"
          ? "Buy eSIM UK | Top eSIM Plans & Providers | eSIM Mobile"
          : "GKT eSIM Plans"
        : props.match.params.region
        ? props.match.params.region == "Middle East"
          ? "Flexible eSIM Plans & Free Data UAE | eSIM Mobile"
          : "GKT eSIM Plans"
        : "GKT eSIM Plans",
      description: props.match.params.country
        ? props.match.params.country == "China"
          ? "Enjoy fast, roaming-free mobile data in China with eSIM Mobile. Explore flexible eSIM plans and get free eSIM data for seamless connectivity."
          : props.match.params.country == "United Kingdom"
          ? "Get the best eSIM data plans in the UK with eSIM Mobile. Explore top eSIM providers and buy your eSIM for seamless connectivity."
          : "GKT eSIM Plans"
        : props.match.params.region
        ? props.match.params.region == "Middle East"
          ? "Get fast, roaming-free mobile data in the UAE with eSIM Mobile. Explore flexible eSIM plans and enjoy free eSIM data for seamless connectivity."
          : "GKT eSIM Plans"
        : "GKT eSIM Plans",
      meta: {
        charset: "utf-8",
        name: {
          keywords: props.match.params.country
            ? props.match.params.country == "China"
              ? "Flexible eSIM plans China, Free eSIM Data China, Fast mobile data China, Roaming-free data China"
              : props.match.params.country == "United Kingdom"
              ? "Buy eSIM UK, eSIM plans UK, eSIM providers UK, Best eSIM data plans"
              : "GKT eSIM Plans"
            : props.match.params.region
            ? props.match.params.region == "Middle East"
              ? "Flexible eSIM plans UAE, Free eSIM Data UAE, Fast mobile data UAE, Roaming-free data UAE"
              : "GKT eSIM Plans"
            : "GKT eSIM Plans",
        },
      },
    };

    setMetaTagInfo(meta);
  }, [props.match.params]);

  return (
    <DocumentMeta {...metaTagInfo}>
      <div className="plan-section">
        <Header />
        {/*header search bar  */}
        <header
          style={{ height: "100%" }}
          className="navBar justify-content-between container"
        >
          {/* <img style={{height: '42px'}} src={logo} alt="logo" /> */}
          <div
            style={{ marginRight: "90px" }}
            className="country-tag d-flex justify-content-between align-items-center "
          >
            <div>
              {props.match.params.alpha2 ? (
                <span
                  className={`fflag fflag-${props.match.params.alpha2} ff-lg ff-round`}
                ></span>
              ) : (
                <span
                  className={`${
                    countryOrRegion == "Europe" ? "fflag-EU" : ""
                  } fflag ff-lg ff-round`}
                >
                  {countryOrRegion != "Europe" ? (
                    <img
                      className="regionLogo"
                      src={
                        countryOrRegion == "Africa"
                          ? Africa
                          : countryOrRegion == "Asia"
                          ? Asia
                          : countryOrRegion == "Australia"
                          ? Australia
                          : countryOrRegion == "Caribbean"
                          ? Caribbean
                          : countryOrRegion == "Latin America"
                          ? LatinAmerica
                          : countryOrRegion == "Balkans"
                          ? Balkans
                          : countryOrRegion == "Caucasus"
                          ? Caucasus
                          : countryOrRegion == "Middle East"
                          ? MiddleEast
                          : countryOrRegion == "T20 WC Region 1"
                          ? t20WcIcon
                          : countryOrRegion == "T20 WC Region 2"
                          ? t20WcIcon
                          : World
                      }
                    />
                  ) : (
                    ""
                  )}
                </span>
              )}
            </div>
            <div style={{ overflow: "hidden" }}>
              <h2>{countryOrRegion}</h2>
              {!countryOrRegion && <h2>Global Plans</h2>}
            </div>
          </div>
          <div className="plan-search-bar d-flex justify-content-between align-items-center">
            <input
              placeholder="Search by country or continent"
              type="text"
              value={userInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className="d-flex justify-content-center align-items-center">
              <button
                onClick={() => {
                  if (suggestions[activeSuggestionIndex]?.suggestionType) {
                    handleSearch(
                      suggestions[activeSuggestionIndex]?.suggestionType,
                      suggestions[activeSuggestionIndex]?.regionId,
                      suggestions[activeSuggestionIndex]?.Alpha2,
                      suggestions[activeSuggestionIndex]?.description
                    );
                  }
                  // if (selectedSuggestion?.suggestionType) {
                  //   handleSearch(
                  //     selectedSuggestion.suggestionType,
                  //     selectedSuggestion.regionId,
                  //     selectedSuggestion.Alpha2,
                  //     selectedSuggestion.description
                  //   );
                  // }
                }}
              >
                <IoSearch />
              </button>
            </div>

            {showSuggestions && suggestions.length > 0 && (
              <div className="search-suggestion-section">
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className={
                        index === activeSuggestionIndex
                          ? "suggestion-active"
                          : ""
                      }
                      ref={
                        index === activeSuggestionIndex
                          ? activeSuggestionRef
                          : null
                      }
                      onClick={() => {
                        handleSuggestionSearch(suggestion);
                        setUserInput(suggestion.description);
                        setSelectedSuggestion(suggestion);
                        setSuggestions([]);
                        setShowSuggestions(false); // Hide the suggestion list when a suggestion is selected
                      }}
                    >
                      {suggestion.suggestionType === "region" ? (
                        <span>{suggestion.description}</span>
                      ) : (
                        <span>{suggestion.description}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!isValidSuggestion && userInput && (
              <div className="search-suggestion-section">No result found</div>
            )}
          </div>
        </header>

        {/* Cards section */}
        <section className="d-flex flex-column justify-content-center align-items-center">
          <div className="card-container">
            <div className="row m-0 cards-bg">
              <div className="col-12 col-md-3 p-2">
                {/* Remove "d-none" class to show top-up card */}
                {selectedPlan && (
                  <div
                    style={{ border: "3px solid #E58359", padding: "0" }}
                    className={`card-ticket ${
                      selectedPlan ? "d-flex" : "d-none"
                    }`}
                  >
                    <div
                      className="mb-2"
                      style={{
                        borderRadius: "15px 15px 0 0",
                        backgroundImage:
                          "linear-gradient(271deg, rgb(61, 52, 151) -1.99%, #CA5E8F 49.34%, #E68557 101.38%)",
                        padding: "4px 10px",
                        color: "#fff",
                        width: "100%",
                      }}
                    >
                      LAST RECHARGE
                    </div>
                    <div style={{ padding: "0 12px" }} className="w-100">
                      <div className="w-100" style={{ position: "relative" }}>
                        <img
                          className="stars"
                          style={{ zIndex: "-1" }}
                          src={stars}
                          alt=""
                        />
                        <h2
                          className="mb-2 mb-md-4"
                          style={{ fontSize: "45px" }}
                        >
                          {selectedPlan.data}
                        </h2>
                        <div className="d-flex flex-md-column align-items-md-start align-items-center">
                          <div>
                            <p
                              style={{
                                color: "#A0A0A0",
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Validity
                            </p>
                            <p
                              style={{ fontSize: "20px", fontWeight: "600" }}
                            >{`${selectedPlan.validity} Days`}</p>
                          </div>
                          <div className="mt-0 mt-md-3 ml-5 ml-md-0">
                            <p
                              style={{
                                color: "#A0A0A0",
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                              }}
                            >
                              Price
                            </p>
                            <p style={{ fontSize: "20px", fontWeight: "600" }}>
                              £ {selectedPlan.price}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100" style={{ padding: "12px" }}>
                      <button
                        onClick={() => {
                          topUpClicked(selectedPlan);
                        }}
                        className="btn"
                      >
                        TOP UP
                      </button>
                    </div>
                  </div>
                )}
                {/* Insert "d-none" class to show top-up card*/}
                <div
                  className={`bg-img ${selectedPlan ? "d-none " : ""}`}
                  style={{
                    backgroundImage: `url(${
                      countryBg
                        ? countryBg
                        : "https://images.unsplash.com/photo-1531266752426-aad472b7bbf4?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0NzcxNDJ8MHwxfHNlYXJjaHwyfHx3b3JsZHxlbnwwfHx8fDE2OTAxODQ2Mjd8MA&ixlib=rb-4.0.3&q=85"
                    })`,
                  }}
                ></div>
              </div>
              <div className="col-12 col-md-3 p-2 popular-section">
                {plansFound &&
                  popular.map((plan, index) => (
                    <div className="col-12 col-sm-6 col-md-12 p-0">
                      <div key={index} className="card-ticket">
                        <div className="golden-label d-flex align-items-center">
                          <img
                            className="mr-1"
                            style={{ width: "16px" }}
                            src={popularIcon}
                            alt="popular"
                          />
                          <span className="d-inline-block text-truncate number">
                            Popular #{index + 1}
                          </span>
                        </div>

                        {plan.unlimitedPlan === true ? (
                          <h2 className="mt-2 number">Unlimited</h2>
                        ) : (
                          <h2 className="mt-2 number">{plan.data}</h2>
                        )}
                        <p className="number m-0 plan-detail">
                          {plan.planName}
                        </p>
                        <div className="w-100 d-flex flex-wrap justify-content-around align-items-center mt-2">
                          <div className="plan-validity px-2">
                            <p className="muted-text">validity</p>
                            <p className="number">{plan.validity} Days</p>
                          </div>
                          <div className="line-seperator"></div>
                          <div className="plan-price px-2">
                            <p className="muted-text">price</p>
                            <p className="number">£ {plan.price}</p>
                          </div>
                          <div className="line-seperator second"></div>
                          <div
                            onClick={() => handleDetailsOpen(plan)}
                            className="plan-details px-2 d-flex align-items-center mt-2"
                          >
                            <BsInfoCircleFill className="mr-1" />
                            <p>Details</p>
                          </div>
                        </div>
                        {esimPlanTopUp &&
                        esimPlanTopUp.some(
                          (topUp) => topUp.esimPlan.plan.planId === plan.planId
                        ) ? (
                          <button
                            onClick={() => {
                              topUpClicked(plan, index);
                            }}
                            className="btn"
                          >
                            TOP UP
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              toggleBuy(plan, index);
                            }}
                            className="btn"
                          >
                            BUY NOW
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="col-12 col-md-3 p-2 premium-section">
                {plansFound
                  ? premium.map((plan, index) => (
                      <div className="col-12 col-sm-6 col-md-12 p-0">
                        <div className="card-ticket">
                          <div className="golden-label d-flex align-items-center">
                            <img
                              className="mr-1"
                              style={{ width: "14px" }}
                              src={premiumIcon}
                              alt="premium"
                            />
                            <span className="d-inline-block text-truncate number">
                              Premium #{index + 1}
                            </span>
                          </div>
                          {plan.unlimitedPlan === true ? (
                            <h2 className="mt-2 number">Unlimited</h2>
                          ) : (
                            <h2 className="mt-2 number">{plan.data}</h2>
                          )}
                          <p className="number m-0 plan-detail">
                            {plan.planName}
                          </p>
                          <div className="w-100 d-flex flex-wrap justify-content-around align-items-center mt-2">
                            <div className="plan-validity px-2">
                              <p className="muted-text">validity</p>
                              <p className="number">{plan.validity} Days</p>
                            </div>
                            <div className="line-seperator"></div>
                            <div className="plan-price px-2">
                              <p className="muted-text">price</p>
                              <p className="number">£ {plan.price}</p>
                            </div>
                            <div className="line-seperator second"></div>
                            <div
                              onClick={() => handleDetailsOpen(plan)}
                              className="plan-details px-2 d-flex align-items-center mt-2"
                            >
                              <BsInfoCircleFill className="mr-1" />
                              <p>Details</p>
                            </div>
                          </div>
                          {/* {console.log("unlimited",unlimited)} */}
                          {esimPlanTopUp &&
                          esimPlanTopUp.some(
                            (topUp) =>
                              topUp.esimPlan.plan.planId === plan.planId
                          ) ? (
                            <button
                              className="btn"
                              onClick={() => {
                                topUpClicked(plan, index);
                              }}
                            >
                              TOP UP
                            </button>
                          ) : (
                            <button
                              className="btn"
                              onClick={() => {
                                toggleBuy(plan, index);
                              }}
                            >
                              BUY NOW
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
              <div className="col-12 col-md-3 p-2 unlimited-section">
                {plansFound
                  ? unlimitedOrOther.map((plan, index) => (
                      <div className="col-12 col-sm-6 col-md-12 p-0">
                        <div className="card-ticket">
                          {plan.unlimitedPlan == true && (
                            <div className="golden-label d-flex align-items-center">
                              <img
                                className="mr-1"
                                style={{ width: "18px" }}
                                src={unlimitedIcon}
                                alt="unlimited"
                              />
                            </div>
                          )}
                          <h2 className="mt-2 number">{plan.data}</h2>
                          <p className="number m-0 plan-detail">
                            {plan.planName}
                          </p>
                          <div className="w-100 d-flex flex-wrap justify-content-around align-items-center mt-2">
                            <div className="plan-validity px-2">
                              <p className="muted-text">validity</p>
                              <p className="number">{plan.validity} Days</p>
                            </div>
                            <div className="line-seperator"></div>
                            <div className="plan-price px-2">
                              <p className="muted-text">price</p>
                              <p className="number">£ {plan.price}</p>
                            </div>
                            <div className="line-seperator second"></div>
                            <div
                              onClick={() => handleDetailsOpen(plan)}
                              className="plan-details px-2 d-flex align-items-center mt-2"
                            >
                              <BsInfoCircleFill className="mr-1" />
                              <p>Details</p>
                            </div>
                          </div>
                          {esimPlanTopUp &&
                          esimPlanTopUp.some(
                            (topUp) =>
                              topUp.esimPlan.plan.planId === plan.planId
                          ) ? (
                            <button
                              className="btn"
                              onClick={() => {
                                topUpClicked(plan, index);
                              }}
                            >
                              TOP UP
                            </button>
                          ) : (
                            <button
                              className="btn"
                              onClick={() => {
                                toggleBuy(plan, index);
                              }}
                            >
                              BUY NOW
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </section>

        {/* Table section */}
        {/* {console.log("filteredPlans",filteredPlans)} */}
        <section className="d-flex flex-column justify-content-center align-items-center">
          <div id="table-section" className="table-section">
            <CustomFilterSection
              onFilterChange={handleFilterChange}
              searchText={searchText}
              handleSearchChange={handleSearchChange}
              onCheapestFilterClick={handleCheapestFilterClick}
              onBestPricePerGBFilterClick={handleBestPricePerGBFilterClick}
              onUnlimitedToggle={handleUnlimitedToggle}
              unlimitedFilter={unlimitedFilter}
              onFilterRemove={() => {
                props.match.params.regionId
                  ? regionPlansAPI()
                  : props.match.params.alpha2
                  ? plansAPI()
                  : globalPlansAPI();
              }}
              onFilterRemoveForOthers={() => {
                forUnlimitedPlans.length !== 0
                  ? unlimitedToggle()
                  : props.match.params.regionId
                  ? regionPlansAPI()
                  : props.match.params.alpha2
                  ? plansAPI()
                  : globalPlansAPI();
              }}
              onLongestValidityFilterClick={handleLongestValidityFilterClick}
              onLargestGBFilterClick={handleLargestGBFilterClick}
              onUnlimitedFilterToggle={unlimitedToggle}
            />
            {/* {plan.map((tableItem, index) => ( */}
            <CSmartTable
              clickableRows
              columns={columns}
              //  columnSorter // Enable column sorting
              items={filteredPlans}
              pagination
              //  pagination={false} // Disable pagination
              // onFilteredItemsChange={(items) => {
              // }}
              // onSelectedItemsChange={(items) => {
              // }}
              scopedColumns={{
                avatar: (item) => (
                  <td>
                    <CAvatar src={logo} />
                  </td>
                ),
                // VALIDITY: (item) => (
                //   <td>
                //     <CBadge color={getBadge(item.VALIDITY)}>{item.VALIDITY}</CBadge>
                //   </td>
                // ),
                // show_details: (item, rowIndex) => {
                //   return (
                //     <td className="py-2" style={{ textAlign: "right" }}>
                //       <CButton
                //         className="table-view-more-btn"
                //         color="light"
                //         variant="outline"
                //         shape="square"
                //         size="sm"
                //         onClick={() => {
                //           toggleDetails(rowIndex);
                //         }}
                //       >
                //         {icon ? "View" : "View Plan"}
                //       </CButton>
                //     </td>
                //   );
                // },
                show_Buy: (item, rowIndex) => {
                  return (
                    <td className="py-2" style={{ textAlign: "center" }}>
                      {/* {console.log("item", item.planId)} */}
                      {item.planId &&
                      esimPlanTopUp &&
                      esimPlanTopUp.some(
                        (topUp) =>
                          topUp.esimPlan.plan.planId === item.planId.planId
                      ) ? (
                        <CButton
                          className="table-view-more-btn"
                          color="light"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            topUpClicked(item.planId, rowIndex);
                          }}
                        >
                          {buy ? "TOP UP" : "TOP UP"}

                          {/* {details.includes(item.id) ? "View Plan" : "View Plan"} */}
                        </CButton>
                      ) : (
                        <CButton
                          className="table-view-more-btn"
                          color="light"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            toggleBuy(item.planId, rowIndex);
                          }}
                        >
                          {buy ? "Buy Now" : "Buy Now"}

                          {/* {details.includes(item.id) ? "View Plan" : "View Plan"} */}
                        </CButton>
                      )}
                    </td>
                  );
                },
                details: (item, rowIndex) => {
                  return (
                    // <p>Sanket</p>
                    <CCollapse visible={detailsState[rowIndex]}>
                      <CCardBody className="p-3">
                        <h4>{item.username}</h4>
                        <p className="text-muted">
                          User since: {item.PLANNAME}
                        </p>
                        <CButton size="sm" color="info">
                          User Settings
                        </CButton>
                        <CButton size="sm" color="danger" className="ml-1">
                          Delete
                        </CButton>
                      </CCardBody>
                    </CCollapse>
                  );
                },
              }}
              // selectable
              // sorterValue={{ column: "VALIDITY", state: "asc" }}
              // tableFilterLabel="Search:"
              // tableFilter
              tableProps={{
                className: "add-this-class",
                responsive: true,
                striped: true,
                hover: true,
              }}
              tableBodyProps={{
                className: "align-middle",
              }}
            />
            {/* ))} */}
          </div>
        </section>
        <footer style={{ marginTop: "80px" }}>
          <Footer />
        </footer>
        {/* place for AlertPopup */}

        {showVerifyPopup && (
          <AlertPopup
            status={"warning"}
            note={
              "Note* If account is verified then please sign out and sign in again."
            }
            heading={"To proceed please verify your account"}
            content={"Verification link sent to your registered email address."}
            customFunction={<ResendEmailBtn />}
            updateState={updateVarificationPopupStatus}
          />
        )}
        {showLogin && (
          <AlertPopup
            status={"warning"}
            heading={"Login required to proceed"}
            content={"To gain full access please Login"}
            customFunction={<LoginCtaBtn />}
            updateState={updateLoginPopupStatus}
          />
        )}
        {showTopup && (
          <AlertPopup
            status={"warning"}
            heading={`You currently have ${topUpPlan.esimPlan.esimPlanRecharge[0].dataRemaining} of remaining data.`}
            content={
              "Refreshing the plan will erase your remaining data. If you want to retain your data, please click Cancel and consider purchasing another data plan, or Proceed Anyway"
            }
            customFunction={<ProceedAndCancelBtn />}
            updateState={closeTopUpAlert}
          />
        )}
        <CustomModal
          width={768}
          isOpen={detailsOpen}
          onClose={handleDetailsClose}
          customPadding={130}
        >
          <div className="normal-custom-modal">
            <h3 className="title mb-0">{currentDetails.planName}</h3>
            <ul className="m-0">
              <li className="w-100 d-flex justify-content-between align-items-center py-2">
                <p className="m-0">Plan Name</p>
                <p className="m-0">{currentDetails.planName}</p>
              </li>
              <li className="w-100 d-flex justify-content-between align-items-center py-2">
                <p className="m-0">Top up</p>
                <p className="m-0">
                  {currentDetails.topupAvailable == 1
                    ? "Available"
                    : "Unavailable"}
                </p>
              </li>
              {currentDetails.planType &&
              currentDetails.planType.planTypeId != 3 ? (
                <li className="w-100 d-flex justify-content-between align-items-center py-2">
                  <p className="m-0">Coverage</p>
                  {currentDetails.region ? (
                    <div
                      onClick={() => {
                        openModalDropdown(currentDetails.regionId);
                      }}
                      className="countries-label-dropdown d-flex justify-content-center align-items-center position-relative"
                    >
                      {currentDetails.region ? (
                        <p
                          style={{ fontSize: 22, color: "#fff" }}
                          className="m-0"
                        >
                          {currentDetails.region.regionDescription}
                        </p>
                      ) : (
                        <p className="m-0">Country</p>
                      )}
                    </div>
                  ) : (
                    <div>
                      {currentDetails.planCountry &&
                      currentDetails.planCountry.length > 0 ? (
                        <span>
                          {currentDetails.planCountry
                            .map((country) => country.countryDescription)
                            .join(", ")}
                          {" - "}
                          {currentDetails.planCountry[0].planCountryNetwork
                            ? currentDetails.planCountry[0].planCountryNetwork.join(
                                ", "
                              )
                            : ""}
                        </span>
                      ) : (
                        <span>No coverage information available</span>
                      )}
                    </div>
                  )}
                </li>
              ) : (
                <li className="w-100 d-flex justify-content-between align-items-center py-2">
                  <p className="m-0">Coverage</p>
                  <p style={{ fontSize: 22, color: "#fff" }} className="m-0">
                    Global
                  </p>
                </li>
              )}
              <li className="w-100 d-flex justify-content-between align-items-center py-2">
                <p className="m-0">Maximum data</p>
                <p className="m-0">{currentDetails.data}</p>
              </li>
            </ul>
          </div>
        </CustomModal>
        <ContactUsForm />
        {/* Bg gradient Images  */}
        <img className="planBgLeft" src={planBgLeft} alt="" />
        <img className="planBgRight" src={planBgRight} alt="" />
      </div>
    </DocumentMeta>
  );
};
export default Plans;
