import React from "react";
import Confetti from "react-confetti";
import "../../../scss/kiosk.scss";
import stars from "./assets/stars.png";
import stars1 from "./assets/stars_1.png";
import { useHistory } from "react-router-dom";

const STWModal = ({
  prize,
  prizeImg,
  setShowReward,
  prizeId,
  prizeName,
  prizeTitle,
  prizeSubTitleOne,
  prizeSubTitleTwo,
  prizeBody,
  confetti,
  voucherName,
  jaguarGolf,
}) => {
  const history = useHistory();
  return (
    <div className="stw-modal">
      {confetti ? (
        <Confetti
          style={{ width: "100%", minHeight: "100vh" }}
          initialVelocityX={20}
          numberOfPieces={300}
        />
      ) : null}
      <div className="reward">
        {prizeId >= 0 && prizeId <= 25 && prizeId != null && (
          <>
            <h2 className="kiosk-text text-center mb-3">{prizeTitle}</h2>

            {/* {jaguarGolf && prizeId === 15 ? (
              <p
                style={{ lineHeight: 1.25 }}
                className="kiosk-sub-text text-center mb-4"
              >
                We appreciate your donation for a good cause.
                <br />
                <span
                  style={{
                    fontFamily: "Poller One, serif",
                    fontSize: 62,
                    color: "#F2CD32",
                  }}
                >
                  £5
                </span>
              </p>
            ) : null} */}

            {prizeId == 8 || prizeId == 12 ? (
              <p
                style={{ lineHeight: 1.25 }}
                className="kiosk-sub-text text-center"
              >
                You have won a free gift voucher from {prizeName}.
                <br />
                <span style={{ fontSize: 28 }} className="font-weight-bold">
                  {voucherName}
                </span>
              </p>
            ) : prizeId>=13 && prizeId<=25 ? (
              prizeId === 25 ? (
                <p
                  style={{ lineHeight: 1.25 }}
                  className="kiosk-sub-text text-center mb-4"
                >
                  Congratulations, you've won a free voucher from
                  <br />
                  <span style={{ fontSize: 28 }} className="font-weight-bold">
                    GKT eSIM
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily: "Poller One, serif",
                      fontSize: 30,
                      color: "#F2CD32",
                    }}
                  >
                    {voucherName}
                  </span>
                </p>
              ) : 
                <p
                  style={{ lineHeight: 1.25 }}
                  className="kiosk-sub-text text-center mb-4"
                >
                  {prizeSubTitleOne}
                  <br />
                  <span
                    style={{
                      fontFamily: "Poller One, serif",
                      fontSize: 62,
                      color: "#F2CD32",
                    }}
                  >
                    {prizeSubTitleTwo}
                  </span>
                </p>
            ) : (
              <p
                style={{ lineHeight: 1.25 }}
                className="kiosk-sub-text text-center"
              >
                You have won a free gift from {prizeName}.
                <br />
                {/* <span style={{ fontSize: 28 }} className="font-weight-bold">
          Voucher Name
        </span> */}
              </p>
            )}
            <div className="stw-modal-img">
              <img src={prizeImg} alt={prize} />
              {confetti ? (
                <>
                  <img src={stars} alt="" />
                  <img src={stars1} alt="" />
                </>
              ) : null}
            </div>
            {prizeId == 8 || prizeId == 12 || prizeId == 12 || prizeId == 25 ? (
              <p className="font-weight-bold mt-2">
                You will receive a voucher code via email.
              </p>
            ) : (
              ""
            )}
          </>
        )}
        {prizeId == -1 && prizeId != null && (
          <>
            <h2 className="kiosk-text text-center mb-3">We're sorry.</h2>
            <p
              style={{ lineHeight: 1.25 }}
              className="kiosk-sub-text text-center"
            >
              Our SPIN wheel is currently not in operation or our gifts have
              been fully claimed. Please check back later for more opportunities
              to win exciting prizes.
              <br />
            </p>

            <div className="stw-modal-img">
              <img src={prizeImg} alt={prize} />
              {confetti ? (
                <>
                  <img src={stars} alt="" />
                  <img src={stars1} alt="" />
                </>
              ) : null}
            </div>
            <p className="font-weight-bold mt-2">
              Thank you for your understanding.
            </p>
          </>
        )}
        {prizeId == -2 && prizeId != null && (
          <>
            <h2 className="kiosk-text text-center mb-3">
              We're glad you're enjoying spin the wheel!
            </h2>
            <p
              style={{ lineHeight: 1.25 }}
              className="kiosk-sub-text text-center"
            >
              However, each participant is allowed to play spin the wheel only
              once per day.
              <br />
            </p>

            <div className="stw-modal-img">
              <img src={prizeImg} alt={prize} />
            </div>
            <p className="font-weight-bold mt-2">
              Thank you for your understanding.
            </p>
          </>
        )}
        {prizeId == -3 && prizeId != null && (
          <>
            <h2 className="kiosk-text text-center mb-3">We're sorry.</h2>
            <p
              style={{ lineHeight: 1.25 }}
              className="kiosk-sub-text text-center"
            >
              Something went wrong, Please try again later.
              <br />
            </p>

            <div className="stw-modal-img">
              <img src={prizeImg} alt={prize} />
            </div>
            <p className="font-weight-bold mt-2">
              Thank you for your understanding.
            </p>
          </>
        )}

{prizeId <=55 && prizeId >=26  && prizeId != null && (
          <>
          {/* {console.log("here came 1",prizeId,prizeTitle,prizeBody)} */}
            <h2 className="kiosk-text text-center mb-3">{prizeTitle}</h2>
            {/* <p
              style={{ lineHeight: 1.25 }}
              className="kiosk-sub-text text-center"
            >
              {prizeBody}
              <br />
            </p> */}
            <p style={{ lineHeight: 1.25 }} className="kiosk-sub-text text-center">
      {/* Conditional message based on prizeId */}
      {(() => {
        if (prizeId === 41 || prizeId === 45) {
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">SMARTY.</span>
              <br />
              <span className="font-weight-bold mt-2">Cineworld Voucher.</span>
            </>
          );
        } else if (prizeId === 37 || prizeId === 39 || prizeId === 43) {
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">SMARTY.</span>
              <br />
              <span className="font-weight-bold mt-2">WON £15 SIM.</span>
            </>
          );
        } else if (prizeId === 31 || prizeId === 35) {
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">VOXI.</span>
              <br />
              <span className="font-weight-bold mt-2">Cineworld Voucher.</span>
            </>
          );
        } else if (prizeId === 27 || prizeId === 29) {
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">VOXI.</span>
              <br />
              <span className="font-weight-bold mt-2">
                Loaded £10 SIM Card.
              </span>
            </>
          );
        } else if (prizeId === 33) {
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">VOXI.</span>
              <br />
              <span className="font-weight-bold mt-2">Goody Bag.</span>
            </>
          );
        } else if (prizeId === 51 || prizeId === 55) {
          // console.log("Inside gg voucher ------------");
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">giffgaff.</span>
              <br />
              <span className="font-weight-bold mt-2">Cineworld Voucher.</span>
            </>
          );
        } else if (prizeId === 47 || prizeId === 49 || prizeId === 53) {
          // console.log("Inside gg physical gift ------------");
          return (
            <>
              <span className="font-weight-normal mt-2">
                You have won a free gift from
              </span>{" "}
              <span className="font-weight-bold mt-2">giffgaff.</span>
              <br />
              <span className="font-weight-bold mt-2">
                Student SIM with £10 PAYG plan.
              </span>
            </>
          );
        } else {
          // console.log("Inside else of all prize id  ------------");
          // Default case to show the prizeBody as it is
          return prizeBody;
        }
      })()}
      <br />
    </p>

            <div className="stw-modal-img">
              <img style={{ width: "250px", margin: "20px 0" }} src={prizeImg} alt={prize} />
            </div> 
            {(prizeId == 31 || prizeId == 35 || prizeId == 41 || prizeId == 45 || prizeId == 51 || prizeId == 55) &&
           <>
            {/* {console.log("inside when it is voucher")}          */}
           <p className="font-weight-bold mt-2">
            You will receive a voucher code via email.
            </p>
            </>
            }
          </>
        )}


        <div className="w-100 d-flex justify-content-center align-items-center stw-close-btn">
          <button
            // onClick={() => {
            //   // setShowReward(false);
            //   const target = document.querySelector(".reset-btn");
            //   console.log(target, "target");
            //   target.click();
            //   // window.location.reload();
            //   // history.push("/kiosk/spinTheWheel");
            // }}
            onClick={() => {
              setShowReward(false); // You can uncomment this line if you want to execute it.
              localStorage.removeItem("order");
              localStorage.removeItem("QRLink");
              localStorage.removeItem("userId");
              localStorage.removeItem("stopPos");
              localStorage.removeItem("prize");
              localStorage.removeItem("orderSummaryAfterPayment");
              localStorage.removeItem("kioskSelectedPlanToBuy");
              window.location.reload();
              // const target = document.querySelector(".reset-btn");
              // if (target) {
              //     console.log(target, "target");
              //     target.click();
              // } else {
              //     console.error("Element with class name 'reset-btn' not found.");
              // }
            }}
            className="btn-close px-4 my-2 d-flex justify-content-center align-items-center stw-close-btn btn btn-outline-danger rounded-pill px-3 mt-4"
          >
            {/* <IoClose className="mr-2" /> */}
            <font>Close</font>
          </button>
        </div>
      </div>
    </div>
  );
};

export default STWModal;
