import axios from 'axios';
import { API_URL } from '../Common/Constant';
class PlansBasedOnRegionService {

    regionPlan(regionId) {
        return axios.get(`${API_URL}/webappapi/popularPlanByRegion/${regionId}`, {
        });
    }
    regionPremiumPlan(regionId) {
        return axios.get(`${API_URL}/webappapi/premiumPlanByRegion/${regionId}`, {
        });
    }
    tileRegionPlan(regionId) {
        return axios.get(`${API_URL}/webappapi/plan/region/${regionId}`, {
        });
    }
}
export default new PlansBasedOnRegionService()