import axios from 'axios'
class AuthenticationService {

    setupAxiosInterceptors() {
        // axios.defaults.headers.common['Authorization'] = '';
        // delete axios.defaults.headers.common['Authorization'];
        // console.log("############## Going to call axios interceptos################", localStorage.getItem('curUser'));
        if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== "") {
            // console.log("Inside set up axios");
            let basicAuthHeader = 'Bearer '+localStorage.getItem('token')
            // console.log("basicAuth1",basicAuthHeader)
            axios.defaults.headers.common['Authorization'] = basicAuthHeader;
            // console.log("basicAuth",basicAuthHeader)
            axios.interceptors.request.use(
                (config) => {
                    // console.log("config",config)
                    // config.headers.authorization = basicAuthHeader;
                    return config;
                    // console.log("config",config)
                }
            )


            // // Add a response interceptor
            // axios.interceptors.response.use(function (response) {
            //     return response;
            // }, function (error) {
            //     return Promise.reject(error);
            // });
        }

    }

    isUserLoggedIn(){
        let user = localStorage.getItem('token')
        // console.log("user====", user)
        if (user===null) return false
        return true
    }
    
    
    validateLogin() {
        // console.log("authT",localStorage.getItem('token'))
        if(localStorage.getItem('token')){
            if (localStorage.getItem('token') == null && localStorage.getItem('token') == "") {
                return true;
            }else{
                return false;
            }
        }else{
                return true;
        }
        
    }

}



export default new AuthenticationService();