import React, { useState, useEffect } from "react";
import loginBottomGrad from '../../assets/img/login-bottom-gradient.png';
import loginLeftGrad from '../../assets/img/login-left-gradient.png';
import LoginService from "../../Services/LoginService";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import paymentSuccess from "../../assets/img/paymentsuccessIcon.png";
import UserVarificationService from "../../Services/UserVarificationService";

const KioskPaymentRedirect = (props) => {

    const history = useHistory();
    const [message, setMessage] = useState();
    const [verification, setVerification] = useState({
        verificationId: props.match.params.verificationId,
        userId: props.match.params.userId,
    });
    const [code, setCode] = useState();


    const loginClick = () => {
        history.push(`/login`)
    }
    const setClicked = () => {
        history.push(`/setPassword/${props.match.params.userId}`)
    }

     // google analytics

   useEffect(() => {
    document.title = "kiosk after payment redirect page";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

    return (
        <div style={{ overflow: 'hidden' }} className="container-fluid p-0">
            <div className="row no-gutter">
                <div className="col-md-6 login-bg first-section">
                    <div className="login d-flex align-items-center py-5">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                                    {/* {message==}   */}
                                    <div className="d-flex flex-column text-center align-items-center">
                                        <img
                                            className="mr-1 ml-2"
                                            alt="payment success"
                                            style={{ width: "85px" }}
                                            src={paymentSuccess}
                                        />
                                        <h1 className="kiosk-title mb-3">Payment successful!</h1>
                                    </div>
                                    <h6 className="text-muted mb-2 text-center">👤 Log in to GKT eSIM website</h6>

                                    <form className="d-flex flex-column">

                                        {/* <div className="custom-control ml-auto mb-3">
                      
                      <a className="pull-right" href="#">Forgot password?</a>
                      
                    </div> */}
                                        <button
                                            onClick={() => loginClick()}
                                            type="submit"
                                            className="btn btn-custom-gradient w-100 mb-2 px-4 py-lg-2 shadow-sm"
                                        >
                                            Login
                                        </button>
                                        <div className="or-seperator">
                                            <div className="line"></div>
                                            <p>OR</p>
                                            <div className="line"></div>
                                        </div>

                                        <h6 className="text-muted mb-2 text-center">🔐 Complete your GKT eSIM profile by setting your password.</h6>

                                        <button
                                            type="submit"
                                            className="btn btn-light  text-uppercase mb-2 px-4 py-lg-2 shadow-sm rounded-pill"
                                            onClick={() => setClicked()}>
                                            Set password
                                        </button>
                                        {/* <p className="text-muted mb-4 text-center"> */}
                                        {/* 🔐 Log in to GKT eSIM website <span onClick={loginClick}><a href="#">
                    
                    Login Now
                    </a> 
                    </span> */}
                                        {/* </p> */}

                                        {/* <br/>🌐 Explore and buy our eSIM plans: */}
                                        <br />

                                    </form>
                                    <div>
                                        <FormFeedback
                                            style={{
                                                display:
                                                    message === "" ||
                                                        message === undefined
                                                        ? "none"
                                                        : "block",
                                            }}
                                            className="red"
                                        >
                                            {message}
                                        </FormFeedback>
                                    </div>
                                    <div className="px-4 mt-4 register-footer">
                                        <p className="text-muted mb-4 text-center">
                                            📱 Download our{" "}
                                            <a
                                                className="text-center"
                                                target="_blank"
                                                href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
                                            >
                                                iOS
                                            </a>{" "}
                                            and
                                            <a
                                                className="text-center"
                                                target="_blank"
                                                href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
                                            >
                                                Android app<br />
                                            </a>
                                            Welcome to GKT eSIM family. Enjoy the convenience and flexibility of our services!.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
                    <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
                </div>
                {/* <div className="col-md-6 d-none d-md-flex login-bg-image"></div> */}
                <div className="col-md-6 d-none d-md-flex login-bg-image">
                    {/* <div className="world-cup-content">
            <div>
              <h3 className="mb-2 text-center">
                Secure Your
                <br />
                 2023 World Cup Ticket
              </h3>
              <h3 className="text-center">
                <span>

                JOIN LUCKY DRAW 
                </span>
                <br />
                Register & Verify Your Email by October 31st!
              </h3>
            </div>

            <div style={{marginBottom: '10px'}} className="mt-auto">
              <h1>REGISTER NOW!</h1>
              <p>
                <span style={{fontSize: '8.8px',fontWeight: '700'}}>
                2023 World Cup Ticket Lucky Draw T&C: </span>Register by Oct 31st to
                Enter! Unique Email Id is a must. The number of users signing up
                through your unique code will be considered. The number of
                conversion will be considered. Organisers have all rights
                regarding the final decision in this campaign.
              </p>
            </div>
          </div> */}
                </div>
            </div>


        </div>
    );
};

export default KioskPaymentRedirect;
