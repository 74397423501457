import axios from 'axios';
import { API_URL } from '../../../Common/Constant';
class KioskPlansService {

    counrtyPlan(alpha2, kioskId, lastLoginDate) {
        const headers = {
            'kioskId': kioskId,
            'lastLoginDate': lastLoginDate
        };
        // console.log("lastLoginDate",lastLoginDate);
        return axios.get(`${API_URL}/kiosk/plan/country/${alpha2}`, { headers });
    }
    regionPlan(regionId, kioskId, lastLoginDate) {
        const headers = {
            'kioskId': kioskId,
            'lastLoginDate': lastLoginDate
        };
        return axios.get(`${API_URL}/kiosk/plan/region/${regionId}`, { headers });
    }
}
export default new KioskPlansService()