import React from "react";


const OrderListComp = ({ order, value, breakLine }) => {
    return (
      <div className={`order-list ${breakLine ? "list-break-line" : ""}`}>
        <div className="order-key">
          <p>{order}</p>
        </div>
        <div className="order-value">
          <p>{value}</p>
        </div>
      </div>
    );
  };

  export default OrderListComp;