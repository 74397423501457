import React, { useState, useEffect, useRef, useContext } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { FiSmartphone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { BiTimeFive, BiLogoFacebook, BiLogoInstagram } from "react-icons/bi";
import { FaRegComments } from "react-icons/fa6";
import { RxTwitterLogo } from "react-icons/rx";
import { AiOutlineYoutube, AiOutlineLinkedin } from "react-icons/ai";
import logo from "../../assets/img/gkt_final_logo.png";
import gkTelecom from "../../assets/img/slider_logo_gktele.png";
import { useHistory } from "react-router-dom";
import UserVarificationService from "../../Services/UserVarificationService";
import { jwtDecode } from 'jwt-decode';

const Footer = () => {
  const history = useHistory();
  const [verificationStatus, setVarificationStatus] = useState();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp > currentTime) {

        UserVarificationService.getVerificationStatus(
          localStorage.getItem("userId")
        ).then((response) => {
          if (response.status === 200) {
            setVarificationStatus(response.data);
          }
          // console.log("verStat",response.data)
        });
      }
    } else {
      localStorage.clear();
      // history.push("/");
    }
  }, []);
  const affiliateClicked = (e) => {
    e.preventDefault();
    const affId = localStorage.getItem("affiliateUserId");
    if (affId && affId === "0") {
      history.push("/ReferralModule");
    } else if (affId && affId !== 0) {
      history.push("/dashboard");
    }
  };
  const dashboardClicked = (e) => {
    e.preventDefault();

    history.push("/dashboard");

  };

  return (
    <div className="footer w-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="single_footer mt-5">
              <h4>Menu</h4>
              <ul>
                <li onClick={() => { history.push('/aboutus') }}>
                  <a target="_blank" >About Us</a>
                </li>
                {verificationStatus === 1 && localStorage.getItem('userId') && localStorage.getItem('affiliateUserId') === '0' && (<li onClick={affiliateClicked}>
                  <a target="_blank">Affiliate</a>
                </li>)}
                {verificationStatus === 1 && localStorage.getItem('userId') && localStorage.getItem('affiliateUserId') !== '0' && (<li onClick={dashboardClicked}>
                  <a target="_blank">Dashboard</a>
                </li>)}

                <li onClick={() => { history.push('/faq') }}>
                  <a target="_blank">FAQ's</a>
                </li>
                <li>
                  <a target="_blank">Blog</a>
                </li>
                <li onClick={() => history.push("/privacypolicy")}>
                  <a>
                    Privacy Policy
                  </a>
                </li>
                <li onClick={() => history.push("/termsandcondition")}>
                  <a>
                    Terms & Conditions
                  </a>
                </li>
                <li onClick={() => history.push("/acceptableusepolicy")}>
                  <a>
                    Acceptable Use Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="mt-5 single_footer single_footer_address">
              <h4>COMPANY DETAILS</h4>
              <p>
                Co. No: 7002607
                <br />
                VAT No: GB 322 637 908
              </p>
            </div>
            <div className="mt-5 single_footer single_footer_address">
              <h4>OFFICE ADDRESS</h4>
              <p>Cranbourne House, 135 Bath Road, Slough SL1 3UX</p>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="mt-5 single_footer single_footer_address">
              <h4>REGISTERED ADDRESS</h4>
              <p>
                GK Telecom Ltd, Alpha House, 296 Kenton Road, Kenton, Harrow,
                Middlesex HA3 8DD
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="mt-5 single_footer footer-contact single_footer_address">
              <h4>Contact</h4>
              <ul>
                {/* <li className="mb-3">
                  <div className="d-flex  align-items-center">
                    <div>
                      <FiSmartphone />
                    </div>
                    <p>01753 568 441</p>
                  </div>
                </li> */}
                <li className="mb-3">
                  <div className="d-flex align-items-start">
                    <div>
                      <HiOutlineMail style={{ pointerEvents: 'none' }} />
                    </div>
                    <p>help@esim-mobile.co.uk</p>
                  </div>
                </li>
                {/* <li className="mb-3">
                  <div className="d-flex  align-items-center">
                    <div>
                      <BiTimeFive />
                    </div>
                    <p>Monday to Friday 09:00am to 05:30pm</p>
                  </div>
                </li> */}
                <li className="mb-3">
                  <div className="d-flex align-items-start">
                    <div>
                      <FaRegComments style={{ pointerEvents: 'none' }} />
                    </div>
                    <p>24/7 Stay connected through our dedicated email address or conveniently reach out to us via our contact form</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="social_profile col-12 col-md-6">

            <div className="social-icon-section d-flex justify-content-between align-items-center col-8 col-md-12">
              <a target="_blank" href="https://www.facebook.com/gktesim">
                <BiLogoFacebook />
              </a>
              <a target="_blank" href="https://www.instagram.com/gktesim/">
                <BiLogoInstagram />
              </a>
              <a target="_blank" href="https://twitter.com/gktesim">
                <RxTwitterLogo />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/gktesim/?viewAsMember=true">
                <AiOutlineLinkedin />
              </a>
              <a target="_blank" href="https://gktelecom.com/">
                <img src={gkTelecom} alt="gkTelecom" />
                {/* <AiOutlineYoutube /> */}
              </a>
            </div>

          </div>

          <div className="social_profile col-12 col-md-6">
            <h5 style={{ color: "#fff" }}>
              Stay up to date with our Newsletter
            </h5>

            <div className="signup_form">
              <form action="#" className="subscribe">
                <input
                  type="text"
                  className="subscribe__input"
                  placeholder="Enter Email Address"
                />
                <button type="button" className="subscribe__btn">
                  <RiSendPlaneFill />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="row copyright-section">
          <div className="break-line"></div>
          <div className=" col-lg-12 col-12 d-flex justify-content-center align-items-center">
            <img className="copyright-logo" src={logo} alt="" />
            <p className="copyright">
              Copyright 2022 © Gk Telecom | All rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
