import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useRef, useState } from "react";
import { RxDropdownMenu } from "react-icons/rx";
import { SlClose } from "react-icons/sl";
import { useHistory } from "react-router-dom";
import MyEsimService from "../../Services/MyEsimService";
import Loader from "../Alert_Popup/Loader";
import ContactUsForm from "../Common/ContactUsForm";
import CtaBanner from "../Common/CtaBanner";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import EsimDetailsService from "../../Services/EsimDetailsService";
import useScrollToTop from '../custom_hooks/useScrollToTop';

const MyEsim = () => {
  useScrollToTop()
  const history = useHistory();
  const [myEsims, setMyEsims] = useState(null);
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);
  const [esimsCountry, setEsimsCountry] = useState([]);
  const [esimTicketIndex, setEsimTicketIndex] = useState();
  const [esimForIndex, setEsimForIndex] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statusCode, setStatusCode] = useState();
  const [message, setMessage] = useState();
  const [esimDetail, setEsimDetail] = useState(new Map());
  // var esimForIndex ;

  useEffect(() => {
    myEsimApi();
  }, []);

  // useEffect(() => {
  //   if (myEsims !== null) {
  //     myEsims.forEach((esim) => {
  //       if (!esimDetail || esimDetail.get(esim.esimId) === undefined) {
  //         updatedEsimDetail(esim.esimIdEncrypted, esim.esimPlan.plan.planIdEncrypted);
  //       }
  //     });
  //   }
  // }, [myEsims, esimDetail]);
  const [esimDetailsLoading, setEsimDetailsLoading] = useState([]);

  const myEsimApi = () => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = new Date(user.exp * 1000);
      const currentTime = new Date();
      console.log("exp: " + exp);
      console.log("curtime: " + currentTime);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      } else {
          MyEsimService.myesim(localStorage.getItem('userId')).then((response) => {
            if (response.status === 200) {
              setMyEsims(response.data)
              localStorage.setItem("myEsimList", JSON.stringify(response.data))
              setIsLoading(false)
              // setCountry(response.data.map((esim) => esim.esimPlan.plan.planCountry));
              // Initialize loading state for each esim to true
      setEsimDetailsLoading(response.data.map(() => true));

      // Fetch esim details for each esim
      response.data.forEach((esim, index) => {
        updatedEsimDetail(esim.esimIdEncrypted, esim.esimPlan.plan.planIdEncrypted, index);
      });
            }
          })
      }
    } else {
      localStorage.clear();
      history.push("/");
    }
  }

  const updatedEsimDetail = (esimId, planId,index) => {
    // console.log("here me");
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      EsimDetailsService.esimDetail(esimId, planId)
        .then((response) => {
          if (response.status === 200) {
            setStatusCode(200);
            setEsimDetailsLoading((prevLoading) => {
              const newLoading = [...prevLoading];
              newLoading[index] = false;
              return newLoading;
            });
            // Update the remaining data for the specific esim index
      setMyEsims((prevEsims) => {
        const newEsims = [...prevEsims];
        newEsims[index].remainingData = response.data.esimPlan.esimPlanRecharge[0].dataRemaining;
        newEsims[index].dataStatus = response.data.esimPlan.esimPlanRecharge[0].dataStatus;
        newEsims[index].topupFlag = response.data.topupFlag
        return newEsims;
      });
            setEsimDetail(response.data);
            // setIsLoading(false);
            // setStatusCode(200);
          }
        })
        .catch((error) => {
          setEsimDetailsLoading((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[index] = false;
            return newLoading;})
          if (error.message === "Network Error") {
            setIsLoading(false);
            setMessage("Something went wrong, Please try again later");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode(401);
                break;
              case 403:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode(403);
                break;
              case 500:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode(500);
                break;
              case 404:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode(404);
                break;
              case 406:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode(406);
                break;
              case 412:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode(412);
                break;
              default:
                // setMessage("Something went wrong, Please try again later");
                // setIsLoading(false);
                setStatusCode();
                break;
            }
          }
        });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  // console.log("here1=>",esimDetail)
  const esimIccIdClicked = (encIccid, planId) => {
    history.push(`/esimDetails/${encIccid}/${planId}`);
  };

  const topUpClicked = (planId, country, alpha2, esim) => {
    // console.log("aaa1",planId)
    // console.log("aaa2",country)
    // console.log("aaa3",alpha2)
    const matchingEsim = JSON.parse(localStorage.getItem("myEsimList")).filter(
      (item) => item.iccid === esim.iccid
    );

    // Store the filtered list in localStorage
    localStorage.setItem("topUpList", JSON.stringify(matchingEsim));

    localStorage.setItem("topUpIccid", JSON.stringify(esim))
    history.push(`/plans/${planId}/${alpha2}/${country}`);
  };

  const topUpClickedForRegion = (planId, region, regionId, esim) => {
    const matchingEsim = JSON.parse(localStorage.getItem("myEsimList")).filter(
      (item) => item.iccid === esim.iccid
    );
    // Store the filtered list in localStorage
    localStorage.setItem("topUpList", JSON.stringify(matchingEsim));

    localStorage.setItem("topUpIccid", JSON.stringify(esim))
    history.push(`/rplans/${planId}/${regionId}/${region}`);
  };


  useEffect(() => {
    if (myEsims != null) {
      let target = myEsims.map((items) => items);
      setEsimsCountry(target);
    }
  }, [myEsims]);

  const coverageDropdownRef = useRef(null);
  const openCoverageDropdown = (index, esimId) => {
    setIsCoverageDropdownOpen(true);
    document.body.style.overflowY = "hidden";
    setEsimTicketIndex(index);
    // setEsimForIndex(esimIndex)
  };
  var esimIndex = esimsCountry[esimTicketIndex];
  const closeCoverageDropdown = () => {
    setIsCoverageDropdownOpen(false);
    document.body.style.overflowY = "auto";
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen]);

  const CoverageDropdown = ({ planCountry }) => {
    // let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);
    useEffect(() => {
      const filtered = planCountry.filter(
        (item) =>
          item &&
          item.countryDescription
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, planCountry]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          <h4>
            Coverage is provided by <span>{planCountry.length}</span> countries
          </h4>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2">
                          <p>{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
        <SlClose className="modal-close-icon" />
      </div>
    );
  };

   // google analytics

   useEffect(() => {
    document.title = "My eSIM";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div className="my-esim-section" style={{ color: "#fff", position: "relative" }}>
      <header>
        <Header />
      </header>
      {myEsims !== null && myEsims.length !== 0 ?
        <section className="container">
          <div className="col-12">
            <h1 className="text-center title">My eSIMs</h1>
          </div>

          <div className="row w-100 m-0 flex-wrap">
            {myEsims &&
              myEsims.map((esim, index) => (
                <div
                  style={{ position: 'relative' }}
                  key={esim.esimId}
                  className="col-12 col-md-6 col-lg-4 mt-3"
                >
                  <div style={{ display: 'flex', flexDirection: 'column', height: '400px' }} className="cards-bg esim-card p-3">
                    <h2 className="font-weight-bold pb-1">{esim.esimPlan.plan.planName}</h2>
                    <div className="d-flex bottomBreak py-2 px-1 justify-content-between align-items-center">
                      <span>ICCID</span>
                      <p>{esim.iccid}</p>
                    </div>
                    <div className="d-flex bottomBreak py-2 px-1 justify-content-between align-items-center">
                      <span>COVERAGE</span>
                      {esim.esimPlan.plan.region.regionId || esim.esimPlan.plan.planType.planTypeId === 3 ? (
                        <div
                          className="order-value order-popup-link"
                          style={{ position: "relative", overflow: "hidden", maxHeight: "64px" }}
                        >
                          <div
                            className={`${isCoverageDropdownOpen ? "order-popup-active" : ""
                              }`}
                            onClick={() => openCoverageDropdown(index, esim.esimId)}
                          >
                            <span>
                              {esim.esimPlan.plan.planCountry.length} Countries
                            </span>
                            <RxDropdownMenu />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="order-value"
                          style={{ position: "relative", overflow: "hidden", maxHeight: "64px" }}
                        >
                          {esim.esimPlan.plan.planCountry[0]
                            .planCountryNetwork !== null ? (
                            <span>
                              {esim.esimPlan.plan.planCountry.map(
                                (country) => country.countryDescription
                              )}{" "}
                              -  {esim.esimPlan.plan.planCountry[0].planCountryNetwork.map(
                                (network) => network
                              ).join(', ')}
                            </span>
                          ) : (
                            <span>
                              {esim.esimPlan.plan.planCountry.map(
                                (country) => country.countryDescription
                              )}
                            </span>
                          )}
                        </div>
                      )}

                    </div>
                    {/* insert "error" class to show error */}
                    {console.log("here=>",esimDetailsLoading[index])}
                    <div className="d-flex bottomBreak py-2 px-1 justify-content-between align-items-center">
                      <span>REMAINING DATA</span>
                      {esimDetailsLoading[index] ? (
                        <div className="small_loader"></div>
                          ) : (
                        <p>{esim.remainingData}</p>
                      )}
                      
                    </div>
                    {/* replace "d-none" to "error & d-flex" class to show error */}
                    <div className="d-flex bottomBreak py-2 px-1 justify-content-between align-items-center">
                      <span>PLAN STATUS</span>
                      {esimDetailsLoading[index] ? (
                        <div className="small_loader"></div>
                           ) : (<p>{esim.dataStatus}</p>
                      ) }
                    </div>
                    <div className="d-flex bottomBreak py-2 px-1 justify-content-between align-items-center">
                      <span>ORDER TYPE</span>
                      <p>{esim.esimPlan.esimPlanOrderType.orderTypeDesc}</p>
                    </div>
                    <div style={{ gap: '12px' }} className="d-flex justify-content-center align-items-center mt-auto">
                      <button onClick={() => esimIccIdClicked(esim.esimIdEncrypted, esim.esimPlan.plan.planIdEncrypted)} className="w-100 rounded-pill btn btn-lg btn-outline-light">
                        Details
                      </button>
                      {/* remove "d-none" class to show error */}
                      {/* {esim.esimPlan.plan.vendor.vendorId ===1 && 
                      <>
                      {esim.topupFlag=== true && 
                      <button onClick = {()=> esim.esimPlan.plan.region.regionId===0 ? topUpClicked(esim.esimPlan.plan.planId,esim.esimPlan.plan.country.countryDescription,esim.esimPlan.plan.country.alpha2,esim) :topUpClickedForRegion(esim.esimPlan.plan.planId,esim.esimPlan.plan.region.regionDescription,esim.esimPlan.plan.region.regionId,esim) } className="d-error w-100 btn-lg btn btn-custom-gradient rounded-pill shadow-sm">
                        Top up
                      </button>}
                      </>
                      } */}
                      {esim.esimPlan.plan.vendor.vendorId === 2 && 
                      <>
                      {esim.topupFlag === true && (
                      <button onClick = {()=> esim.esimPlan.plan.region.regionId===0 ? topUpClicked(esim.esimPlan.plan.planId,esim.esimPlan.plan.country.countryDescription,esim.esimPlan.plan.country.alpha2,esim) :topUpClickedForRegion(esim.esimPlan.plan.planId,esim.esimPlan.plan.region.regionDescription,esim.esimPlan.plan.region.regionId,esim) } className="d-error w-100 btn-lg btn btn-custom-gradient rounded-pill shadow-sm">
                        Top Up
                      </button>
                      )}
                      </>
                      }
                    </div>
                  </div>

                </div>

              ))}
          </div>
        </section>
        :
        <section className="container">
          <div className="col-12">
            <h1 className="text-center">No Esim Found</h1>
          </div>
        </section>
      }
      {isCoverageDropdownOpen ? (
        <div className="dropdowns-modal">
          <CoverageDropdown planCountry={esimIndex !== undefined ? esimIndex.esimPlan.plan.planCountry : []} />
        </div>
      ) : null}
      {isLoading === true && <Loader />}

      <CtaBanner />
      <ContactUsForm />
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MyEsim;
