import axios from 'axios';
import { API_URL } from '../Common/Constant';
import React, { Component } from 'react';

class VerifyService {
    
    verify(varificationId) {
        // console.log("userId",userId);
        return axios.get(`${API_URL}/authentication/customerVerification`, {headers:varificationId});

    }
    resendVerificationLink(emailId) {
        // console.log("userId",userId);
        return axios.get(`${API_URL}/authentication/resendCustomerVerificationLink`, {headers:{"emailId":emailId}});

    }
    getVerificationStatus(userId) {
        return axios.get(`${API_URL}/webappapi/user/${userId}/verificationstatus`,{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
    
    getVerificationStatus(userId) {
        return axios.get(`${API_URL}/webappapi/user/${userId}/verificationstatus`,{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
    
}



export default new VerifyService()
