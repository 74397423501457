import axios from 'axios';
import { API_URL } from '../Common/Constant';
class MyEsimService {

    myesim(userId) {
        return axios.get(`${API_URL}/webappapi/esim/user/${userId}`,{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
    test(){
        return axios.get(`${API_URL}/webappapi/test`,{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
}
export default new MyEsimService()