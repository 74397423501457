import React, { createContext, useContext, useState } from 'react';

const ContactContext = createContext();

function ContactProvider({ children }) {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleContacForm = () => {
    setShowContactForm(!showContactForm);
    // setValidationErrors({});
  };
  return (
    <ContactContext.Provider value={{ showContactForm, handleContacForm }}>
      {children}
    </ContactContext.Provider>
  );
}

export default ContactProvider;
export {ContactContext};
