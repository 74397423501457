import React, { useState, useEffect, useRef, useContext } from "react";
import logo from "../../../assets/img/gkt_final_logo.png";
import Cookies from "js-cookie";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { IoIosHelpCircleOutline, IoMdHelpCircleOutline } from "react-icons/io";
import $ from "jquery"; // Import jQuery
import { LuLogIn } from "react-icons/lu";
import { TfiHelpAlt } from "react-icons/tfi";

const Header = ({isLoginPage = false}) => {
  const [language, setLanguage] = useState();
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const [showLogout, setshowLogout] = useState(false);
  const helpModalRef = useRef(null);
  const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;

  const isoCodes = [
    "af",
    "sq",
    "am",
    "ar",
    "hy",
    "as",
    "ay",
    "az",
    "bm",
    "eu",
    "be",
    "bn",
    "bho",
    "bs",
    "bg",
    "ca",
    "ceb",
    "zh-CN",
    "zh-TW",
    "co",
    "hr",
    "cs",
    "da",
    "dv",
    "doi",
    "nl",
    "en",
    "eo",
    "et",
    "ee",
    "fil",
    "fi",
    "fr",
    "fy",
    "gl",
    "ka",
    "de",
    "el",
    "gn",
    "gu",
    "ht",
    "ha",
    "haw",
    "he",
    "hi",
    "hmn",
    "hu",
    "is",
    "ig",
    "ilo",
    "id",
    "ga",
    "it",
    "ja",
    "jv",
    "kn",
    "kk",
    "km",
    "rw",
    "gom",
    "ko",
    "kri",
    "ku",
    "ckb",
    "ky",
    "lo",
    "la",
    "lv",
    "ln",
    "lt",
    "lg",
    "lb",
    "mk",
    "mai",
    "mg",
    "ms",
    "ml",
    "mt",
    "mi",
    "mr",
    "mni-Mtei",
    "lus",
    "mn",
    "my",
    "ne",
    "no",
    "ny",
    "or",
    "om",
    "ps",
    "fa",
    "pl",
    "pt",
    "pa",
    "qu",
    "ro",
    "ru",
    "sm",
    "sa",
    "gd",
    "nso",
    "sr",
    "st",
    "sn",
    "sd",
    "si",
    "sk",
    "sl",
    "so",
    "es",
    "su",
    "sw",
    "sv",
    "tl",
    "tg",
    "ta",
    "tt",
    "te",
    "th",
    "ti",
    "ts",
    "tr",
    "tk",
    "ak",
    "uk",
    "ur",
    "ug",
    "uz",
    "vi",
    "cy",
    "xh",
    "yi",
    "yo",
    "zu",
  ];

  const languageCodes = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'hy', name: 'Armenian' },
    { code: 'as', name: 'Assamese' },
    { code: 'ay', name: 'Aymara' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'bm', name: 'Bambara' },
    { code: 'eu', name: 'Basque' },
    { code: 'be', name: 'Belarusian' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bho', name: 'Bhojpuri' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'ceb', name: 'Cebuano' },
    { code: 'zh-CN', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'co', name: 'Corsican' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'dv', name: 'Dhivehi' },
    { code: 'doi', name: 'Dogri' },
    { code: 'nl', name: 'Dutch' },
    { code: 'en', name: 'English' },
    { code: 'eo', name: 'Esperanto' },
    { code: 'et', name: 'Estonian' },
    { code: 'ee', name: 'Ewe' },
    { code: 'fil', name: 'Filipino' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'fy', name: 'Frisian' },
    { code: 'gl', name: 'Galician' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'el', name: 'Greek' },
    { code: 'gn', name: 'Guarani' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'haw', name: 'Hawaiian' },
    { code: 'he', name: 'Hebrew' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hmn', name: 'Hmong' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'ig', name: 'Igbo' },
    { code: 'ilo', name: 'Iloko' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'jv', name: 'Javanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'km', name: 'Khmer' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'gom', name: 'Konkani' },
    { code: 'ko', name: 'Korean' },
    { code: 'kri', name: 'Krio' },
    { code: 'ku', name: 'Kurdish' },
    { code: 'ckb', name: 'Kurdish (Sorani)' },
    { code: 'ky', name: 'Kyrgyz' },
    { code: 'lo', name: 'Lao' },
    { code: 'la', name: 'Latin' },
    { code: 'lv', name: 'Latvian' },
    { code: 'ln', name: 'Lingala' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lg', name: 'Luganda' },
    { code: 'lb', name: 'Luxembourgish' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'mai', name: 'Maithili' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mi', name: 'Maori' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mni-Mtei', name: 'Manipuri (Meitei)' },
    { code: 'lus', name: 'Mizo' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'my', name: 'Burmese' },
    { code: 'ne', name: 'Nepali' },
    { code: 'no', name: 'Norwegian' },
    { code: 'ny', name: 'Chichewa' },
    { code: 'or', name: 'Odia (Oriya)' },
    { code: 'om', name: 'Oromo' },
    { code: 'ps', name: 'Pashto' },
    { code: 'fa', name: 'Persian' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'qu', name: 'Quechua' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ru', name: 'Russian' },
    { code: 'sm', name: 'Samoan' },
    { code: 'sa', name: 'Sanskrit' },
    { code: 'gd', name: 'Scottish Gaelic' },
    { code: 'nso', name: 'Northern Sotho' },
    { code: 'sr', name: 'Serbian' },
    { code: 'st', name: 'Southern Sotho' },
    { code: 'sn', name: 'Shona' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'es', name: 'Spanish' },
    { code: 'su', name: 'Sundanese' },
    { code: 'sw', name: 'Swahili' },
    { code: 'sv', name: 'Swedish' },
    { code: 'tl', name: 'Tagalog' },
    { code: 'tg', name: 'Tajik' },
    { code: 'ta', name: 'Tamil' },
    { code: 'tt', name: 'Tatar' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'ti', name: 'Tigrinya' },
    { code: 'ts', name: 'Tsonga' },
    { code: 'tr', name: 'Turkish' },
    { code: 'tk', name: 'Turkmen' },
    { code: 'ak', name: 'Akan' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'ug', name: 'Uyghur' },
    { code: 'uz', name: 'Uzbek' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'cy', name: 'Welsh' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'yi', name: 'Yiddish' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'zu', name: 'Zulu' },
  ];

  const history = useHistory();
  const getGeoInfo = () => {
    // console.log("here is me ");
    // axios
    //   .get("https://ipapi.co/json/")
    //   .then((response) => {
    //     let data = response.data;
    //     let languagesArray = data.languages.split(",");
    //     let primaryLanguages = languagesArray.map(language => language.substring(0, 2));
    //     let firstLanguage = findMatchingLanguage(primaryLanguages);
    //     console.log("res1", firstLanguage);

    //     // Store the first language in the state called language
        setLanguage("en");
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
  };

  const findMatchingLanguage = (languagesArray) => {
    for (let language of languagesArray) {
      if (isoCodes.includes(language)) {
        return language;
      }
    }
    // If no matching language found, return the first language from the array
    return languagesArray[0];
  };
  // useEffect(() => {
  //   // Set the default language translation to Portuguese
  //   getGeoInfo();
  //   // Cookies.set("googtrans", "/en/mr");
  // }, []);

  useEffect(() => {
    // Set the default language translation to localized language
    if (language) {
      document.cookie = `googtrans=/en/${language};`;
    }
  }, [language]);

  useEffect(() => {
    // Set the default language translation to localized language
    const googtransLanguage = Cookies.get("googtrans");
    if (googtransLanguage) {
        const languageFromCookie = googtransLanguage.split("/")[2];
        const firstLanguage = findMatchingLanguage(languageFromCookie);
        if (firstLanguage && firstLanguage !== language) {
            // If the language from cookie matches the language obtained from getGeoInfo,
            // set it as the language
            setLanguage(languageFromCookie);
        } else {
            // If the language from cookie doesn't match or if there's no match,
            // set the language obtained from getGeoInfo()
            getGeoInfo();
        }
    } else {
        // If no language is set in the cookie, set it to the default language based on location
        getGeoInfo();
    }
}, []);

// console.log("lang=>",language);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        autoDisplay: false,
        // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
        // includedLanguages: 'af,am,ar,az,be,bg,bn,bs,ca,ceb,co,cs,cy,da,de,el,en,eo,es,et,eu,fa,fi,fr,fy,ga,gd,gl,gu,ha,haw,he,hi,hmn,hr,ht,hu,hy,id,ig,is,it,iw,ja,jv,ka,kk,km,kn,ko,kur,ky,la,lb,lo,lt,lv,mg,mhr,mi,mk,ml,mn,mr,mrj,ms,mt,my,ne,nl,no,ny,or,pa,pap,pl,ps,pt,ro,ru,rw,si,sk,sl,sm,sq,sr,su,sv,sw,ta,te,tg,th,tl,tr,tt,ug,uk,ur,uz,vi,xh,yi,yo,zh,zu',
      }, 'google_translate_element');
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    document.cookie = `googtrans=/en/${language};`;
    window.location.reload();
    // Call translation function here if needed
  };


  const goToHome = () => {
    history.push("/kiosk");
  };
  const goToLogin = () => {
    history.push("/kiosk/login");
  };

  const logout = () => {
    localStorage.removeItem("kioskLoginDetails");
    history.push("/kiosk/login");
  };

  useEffect(() => {
    if(kioskDetails){
      setshowLogout(true);
    }
    // Select the element with the class name and modify it
    // console.log("=================mainElement11");
    const elementList = document.querySelectorAll(
      ".VIpgJd-ZVi9od-aZ2wEe-wOHMyf"
    );
    var element_array = [...elementList]; // converts NodeList to Array
    element_array.forEach((elementItem) => {
      if (elementItem) {
        // console.log("=================mainElement12", elementItem);
        elementItem.style.display = 'none';
      }
    });

    setTimeout(() => {
      // console.log("=================mainElement21");
      const elementList = document.querySelectorAll('.VIpgJd-ZVi9od-aZ2wEe-wOHMyf');
      var element_array = [...elementList]; // converts NodeList to Array
      element_array.forEach((elementItem) => {
        if (elementItem) {
          // console.log("=================mainElement22", elementItem);
          elementItem.style.display = "none";
        }
      });
    }, 5000);

    const element = document.querySelector(".VIpgJd-ZVi9od-aZ2wEe");
    if (element) {
      // console.log("element1", element)
      // Modify the element, for example, hide it
      element.style.display = "none";
    }
  }, []);
  const showHelp = () => {
    setShowHelpMenu(true);
  };

  const closeHelpModal = () => {
    setShowHelpMenu(false);
  };
  const handleHelpClick = (event) => {
    if (
      showHelpMenu &&
      helpModalRef.current &&
      !helpModalRef.current.contains(event.target)
    ) {
      closeHelpModal();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleHelpClick);

    return () => {
      document.removeEventListener("click", handleHelpClick);
    };
  }, [showHelpMenu]);

  

  return (
    <header className="kiosk-navbar row px-4 position-relative d-flex justify-content-between align-items-center">
      <div className="col-4 position-relative d-flex align-items-center">
        {showHelpMenu && (
          <div
            ref={helpModalRef}
            className="kioskHelpOptions d-flex flex-column"
          >
            <p style={{ pointerEvents: "none" }} className="m-0">
              email
            </p>
            <p style={{ pointerEvents: "none" }} className="m-0">
              help@esim-mobile.co.uk
            </p>
          </div>
        )}
        <TfiHelpAlt
          onClick={showHelp}
          className="m-0 mr-3"
          style={{ fontSize: 48 }}
        />
        <div className="d-flex justify-content-end align-items-center notranslate">
        <select className="kiosk-help-btn" value={language} onChange={handleLanguageChange}>
          {/* <option value="">Select Language</option> */}
          {languageCodes.map((language) => (
            <option key={language.code} value={language.code}>
              {language.name}
            </option>
          ))}
        </select>
        </div>
      </div>
      <div
        className="col-4 d-flex justify-content-center align-items-center logo-container"
        onClick={goToHome}
      >
        <img className="logo" src={logo} alt="GKT eSIM" />
      </div>
      <div
        style={{ gap: 8 }}
        className="col-4 d-flex justify-content-end align-items-center"
      >
        {showLogout ?
          <button
            onClick={logout}
            className="d-flex justify-content-start align-items-center kiosk-help-btn"
          >
            <p style={{ fontSize: 20, fontWeight: 600 }} className="m-0 ml-2">
              Log Out
            </p>
            <LuLogIn style={{ fontSize: 24 }} />
          </button>
          : 
          isLoginPage ?
          ""
          :
          <button
            onClick={goToLogin}
            className="d-flex justify-content-start align-items-center kiosk-help-btn"
          >
            <p style={{ fontSize: 20, fontWeight: 600 }} className="m-0 ml-2">
              Log in
            </p>
            <LuLogIn style={{ fontSize: 24 }} />
          </button>
        }
      </div>
    </header>
  );
};

export default Header;
