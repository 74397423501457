import React, { useState, useEffect, useRef, useContext } from "react";
import logo from "../../assets/img/gkt_final_logo.png";
import { FaTimes } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { PiSignOutBold } from "react-icons/pi";
import userProfileImg from "../../assets/img/user_icon.png";
import { useHistory } from "react-router-dom";
import HeaderService from "../../Services/HeaderService";
import { GiProcessor } from "react-icons/gi";
import { BiCopy } from "react-icons/bi";
import UserVarificationService from "../../Services/UserVarificationService";
import { ContactContext } from "../Common/ContactProvider";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { React_URL } from "../../Common/Constant";

const Header = () => {
  const { showContactForm, handleContacForm } = useContext(ContactContext);
  const [expanded, setExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProfileOptionOpen, setIsProfileOptionOpen] = useState(false);

  const [withoutLogin, setWithoutLogin] = useState(false);

  const history = useHistory();
  const [getEsimIccids, setEsimIccids] = useState(false);
  const navDropdownRef = useRef(null);

  // console.log(showContactForm, "Header showContactForm");
  const handleNavMenu = (event) => {
    if (
      isProfileOptionOpen &&
      navDropdownRef.current &&
      !navDropdownRef.current.contains(event.target)
    ) {
      closeMenu();
    }
  };
  const [copySuccess, setCopySuccess] = useState(false);
  const [urlCopySuccess, setUrlCopySuccess] = useState(false);

  const handleCopyClick = async () => {
    if (localStorage.getItem("referralCode")) {
      await navigator.clipboard.writeText(localStorage.getItem("referralCode"));
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 10000); // 10 seconds
    }
  };

  const handleURLCopyClick = async () => {
    if (localStorage.getItem("referralCode")) {
      await navigator.clipboard.writeText(
        React_URL+"register/" +
          localStorage.getItem("referralCode")
      );
      setUrlCopySuccess(true);
      setTimeout(() => {
        setUrlCopySuccess(false);
      }, 10000); // 10 seconds
    }
  };

  const closeMenu = () => {
    setIsProfileOptionOpen(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleNavMenu);

    return () => {
      document.removeEventListener("click", handleNavMenu);
    };
  }, [isProfileOptionOpen]);

  const affiliateClicked = (e) => {
    e.preventDefault();
    const affId = localStorage.getItem("affiliateUserId");
    if (affId && affId !== "0") {
      history.push("/dashboard");
    } else {
      history.push("/ReferralModule");
    }
  };
  const dashboardClicked = (e) => {
    e.preventDefault();

    history.push("/dashboard");
  };

  const logInClicked = () => {
    history.push("/login");
  };
  const signUpClicked = () => {
    history.push("/register");
  };
  const signOutClicked = () => {
    //localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("emailId");
    localStorage.removeItem("name");
    localStorage.removeItem("countryName");
    localStorage.removeItem("userId");
    localStorage.removeItem("referralCode");
    localStorage.removeItem("affiliateUserId");
  };
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const [verificationStatus, setVarificationStatus] = useState();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      const user = jwtDecode(token);
      const exp = user.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp > currentTime) {
        if (localStorage.getItem("userId")) {
          UserVarificationService.getVerificationStatus(
            localStorage.getItem("userId")
          )
            .then((response) => {
              if (response.status === 200) {
                setVarificationStatus(response.data);
              }
              // console.log("verStat",response.data)
            })
            .catch((error) => {
              if (error.message === "Network Error") {
                // setIsLoading(false);
                setMessage("Something went wrong, Please try again later");
              } else {
                switch (error.response ? error.response.status : "") {
                  case 401:
                    setMessage(error.response.data.failedReason);
                    const message = "User is disabled";
                    history.push({
                      pathname: "/login",
                      state: { message },
                    });
                    // setIsLoading(false);
                    setStatusCode(401);
                    localStorage.clear();
                    break;
                  case 403:
                    setMessage(error.response.data.failedReason);
                    // setIsLoading(false);
                    setStatusCode(403);
                    break;
                  case 500:
                    setMessage(error.response.data.failedReason);
                    // setIsLoading(false);
                    setStatusCode(500);
                    break;
                  case 404:
                    setMessage(error.response.data.failedReason);
                    // setIsLoading(false);
                    setStatusCode(404);
                    break;
                  case 406:
                    setMessage(error.response.data.failedReason);
                    // setIsLoading(false);
                    setStatusCode(406);
                    break;
                  case 412:
                    setMessage(error.response.data.failedReason);
                    // setIsLoading(false);
                    setStatusCode(412);
                    break;
                  default:
                    setMessage("Something went wrong, Please try again later");
                    // setIsLoading(false);
                    setStatusCode();
                    break;
                }
              }
            });
        }
      } else {
        localStorage.clear();
      }
    }
  }, []);
  // const esimIccid = () => {
  //   HeaderService.esimIccids(localStorage.getItem("userId"))
  //     .then((response) => {
  //       // HeaderService.esimIccids(1).then(response=>{
  //       setEsimIccids(response.data);
  //     })
  //     .catch((error) => {
  //       setEsimIccids([]);
  //     });
  // };
  const homeClick = () => {
    history.push("/");
  };
  // useEffect(() => {
  //   esimIccid();
  // }, []);
  const esimIccIdClicked = (encIccid) => {
    history.push("/esimDetails/" + encIccid);
  };
  const myEsimClicked = () => {
    history.push("/myesim");
  };

  //Google translator

  const isoCodes = [
    "af",
    "sq",
    "am",
    "ar",
    "hy",
    "as",
    "ay",
    "az",
    "bm",
    "eu",
    "be",
    "bn",
    "bho",
    "bs",
    "bg",
    "ca",
    "ceb",
    "zh-CN",
    "zh-TW",
    "co",
    "hr",
    "cs",
    "da",
    "dv",
    "doi",
    "nl",
    "en",
    "eo",
    "et",
    "ee",
    "fil",
    "fi",
    "fr",
    "fy",
    "gl",
    "ka",
    "de",
    "el",
    "gn",
    "gu",
    "ht",
    "ha",
    "haw",
    "he",
    "hi",
    "hmn",
    "hu",
    "is",
    "ig",
    "ilo",
    "id",
    "ga",
    "it",
    "ja",
    "jv",
    "kn",
    "kk",
    "km",
    "rw",
    "gom",
    "ko",
    "kri",
    "ku",
    "ckb",
    "ky",
    "lo",
    "la",
    "lv",
    "ln",
    "lt",
    "lg",
    "lb",
    "mk",
    "mai",
    "mg",
    "ms",
    "ml",
    "mt",
    "mi",
    "mr",
    "mni-Mtei",
    "lus",
    "mn",
    "my",
    "ne",
    "no",
    "ny",
    "or",
    "om",
    "ps",
    "fa",
    "pl",
    "pt",
    "pa",
    "qu",
    "ro",
    "ru",
    "sm",
    "sa",
    "gd",
    "nso",
    "sr",
    "st",
    "sn",
    "sd",
    "si",
    "sk",
    "sl",
    "so",
    "es",
    "su",
    "sw",
    "sv",
    "tl",
    "tg",
    "ta",
    "tt",
    "te",
    "th",
    "ti",
    "ts",
    "tr",
    "tk",
    "ak",
    "uk",
    "ur",
    "ug",
    "uz",
    "vi",
    "cy",
    "xh",
    "yi",
    "yo",
    "zu",
  ];

  const [language, setLanguage] = useState();

  const languageCodes = [
    { code: "af", name: "Afrikaans" },
    { code: "sq", name: "Albanian" },
    { code: "am", name: "Amharic" },
    { code: "ar", name: "Arabic" },
    { code: "hy", name: "Armenian" },
    { code: "as", name: "Assamese" },
    { code: "ay", name: "Aymara" },
    { code: "az", name: "Azerbaijani" },
    { code: "bm", name: "Bambara" },
    { code: "eu", name: "Basque" },
    { code: "be", name: "Belarusian" },
    { code: "bn", name: "Bengali" },
    { code: "bho", name: "Bhojpuri" },
    { code: "bs", name: "Bosnian" },
    { code: "bg", name: "Bulgarian" },
    { code: "ca", name: "Catalan" },
    { code: "ceb", name: "Cebuano" },
    { code: "zh-CN", name: "Chinese (Simplified)" },
    { code: "zh-TW", name: "Chinese (Traditional)" },
    { code: "co", name: "Corsican" },
    { code: "hr", name: "Croatian" },
    { code: "cs", name: "Czech" },
    { code: "da", name: "Danish" },
    { code: "dv", name: "Dhivehi" },
    { code: "doi", name: "Dogri" },
    { code: "nl", name: "Dutch" },
    { code: "en", name: "English" },
    { code: "eo", name: "Esperanto" },
    { code: "et", name: "Estonian" },
    { code: "ee", name: "Ewe" },
    { code: "fil", name: "Filipino" },
    { code: "fi", name: "Finnish" },
    { code: "fr", name: "French" },
    { code: "fy", name: "Frisian" },
    { code: "gl", name: "Galician" },
    { code: "ka", name: "Georgian" },
    { code: "de", name: "German" },
    { code: "el", name: "Greek" },
    { code: "gn", name: "Guarani" },
    { code: "gu", name: "Gujarati" },
    { code: "ht", name: "Haitian Creole" },
    { code: "ha", name: "Hausa" },
    { code: "haw", name: "Hawaiian" },
    { code: "he", name: "Hebrew" },
    { code: "hi", name: "Hindi" },
    { code: "hmn", name: "Hmong" },
    { code: "hu", name: "Hungarian" },
    { code: "is", name: "Icelandic" },
    { code: "ig", name: "Igbo" },
    { code: "ilo", name: "Iloko" },
    { code: "id", name: "Indonesian" },
    { code: "ga", name: "Irish" },
    { code: "it", name: "Italian" },
    { code: "ja", name: "Japanese" },
    { code: "jv", name: "Javanese" },
    { code: "kn", name: "Kannada" },
    { code: "kk", name: "Kazakh" },
    { code: "km", name: "Khmer" },
    { code: "rw", name: "Kinyarwanda" },
    { code: "gom", name: "Konkani" },
    { code: "ko", name: "Korean" },
    { code: "kri", name: "Krio" },
    { code: "ku", name: "Kurdish" },
    { code: "ckb", name: "Kurdish (Sorani)" },
    { code: "ky", name: "Kyrgyz" },
    { code: "lo", name: "Lao" },
    { code: "la", name: "Latin" },
    { code: "lv", name: "Latvian" },
    { code: "ln", name: "Lingala" },
    { code: "lt", name: "Lithuanian" },
    { code: "lg", name: "Luganda" },
    { code: "lb", name: "Luxembourgish" },
    { code: "mk", name: "Macedonian" },
    { code: "mai", name: "Maithili" },
    { code: "mg", name: "Malagasy" },
    { code: "ms", name: "Malay" },
    { code: "ml", name: "Malayalam" },
    { code: "mt", name: "Maltese" },
    { code: "mi", name: "Maori" },
    { code: "mr", name: "Marathi" },
    { code: "mni-Mtei", name: "Manipuri (Meitei)" },
    { code: "lus", name: "Mizo" },
    { code: "mn", name: "Mongolian" },
    { code: "my", name: "Burmese" },
    { code: "ne", name: "Nepali" },
    { code: "no", name: "Norwegian" },
    { code: "ny", name: "Chichewa" },
    { code: "or", name: "Odia (Oriya)" },
    { code: "om", name: "Oromo" },
    { code: "ps", name: "Pashto" },
    { code: "fa", name: "Persian" },
    { code: "pl", name: "Polish" },
    { code: "pt", name: "Portuguese" },
    { code: "pa", name: "Punjabi" },
    { code: "qu", name: "Quechua" },
    { code: "ro", name: "Romanian" },
    { code: "ru", name: "Russian" },
    { code: "sm", name: "Samoan" },
    { code: "sa", name: "Sanskrit" },
    { code: "gd", name: "Scottish Gaelic" },
    { code: "nso", name: "Northern Sotho" },
    { code: "sr", name: "Serbian" },
    { code: "st", name: "Southern Sotho" },
    { code: "sn", name: "Shona" },
    { code: "sd", name: "Sindhi" },
    { code: "si", name: "Sinhala" },
    { code: "sk", name: "Slovak" },
    { code: "sl", name: "Slovenian" },
    { code: "so", name: "Somali" },
    { code: "es", name: "Spanish" },
    { code: "su", name: "Sundanese" },
    { code: "sw", name: "Swahili" },
    { code: "sv", name: "Swedish" },
    { code: "tl", name: "Tagalog" },
    { code: "tg", name: "Tajik" },
    { code: "ta", name: "Tamil" },
    { code: "tt", name: "Tatar" },
    { code: "te", name: "Telugu" },
    { code: "th", name: "Thai" },
    { code: "ti", name: "Tigrinya" },
    { code: "ts", name: "Tsonga" },
    { code: "tr", name: "Turkish" },
    { code: "tk", name: "Turkmen" },
    { code: "ak", name: "Akan" },
    { code: "uk", name: "Ukrainian" },
    { code: "ur", name: "Urdu" },
    { code: "ug", name: "Uyghur" },
    { code: "uz", name: "Uzbek" },
    { code: "vi", name: "Vietnamese" },
    { code: "cy", name: "Welsh" },
    { code: "xh", name: "Xhosa" },
    { code: "yi", name: "Yiddish" },
    { code: "yo", name: "Yoruba" },
    { code: "zu", name: "Zulu" },
  ];

  const getGeoInfo = () => {
    // console.log("here is me ");
    // axios
    //   .get("https://ipapi.co/json/")
    //   .then((response) => {
    //     let data = response.data;
    //     let languagesArray = data.languages.split(",");
    //     let primaryLanguages = languagesArray.map(language => language.substring(0, 2));
    //     let firstLanguage = findMatchingLanguage(primaryLanguages);
    //     console.log("res1", firstLanguage);

    //     // Store the first language in the state called language
    setLanguage("en");
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  const findMatchingLanguage = (languagesArray) => {
    for (let language of languagesArray) {
      if (isoCodes.includes(language)) {
        return language;
      }
    }
    // If no matching language found, return the first language from the array
    return languagesArray[0];
  };
  // useEffect(() => {
  //   // Set the default language translation to Portuguese
  //   getGeoInfo();
  //   // Cookies.set("googtrans", "/en/mr");
  // }, []);

  useEffect(() => {
    // Set the default language translation to localized language
    if (language) {
      document.cookie = `googtrans=/en/${language};`;
    }
  }, [language]);

  useEffect(() => {
    // Set the default language translation to localized language
    const googtransLanguage = Cookies.get("googtrans");
    if (googtransLanguage) {
      const languageFromCookie = googtransLanguage.split("/")[2];
      const firstLanguage = findMatchingLanguage(languageFromCookie);
      if (firstLanguage && firstLanguage !== language) {
        // If the language from cookie matches the language obtained from getGeoInfo,
        // set it as the language
        setLanguage(languageFromCookie);
      } else {
        // If the language from cookie doesn't match or if there's no match,
        // set the language obtained from getGeoInfo()
        getGeoInfo();
      }
    } else {
      // If no language is set in the cookie, set it to the default language based on location
      getGeoInfo();
    }
  }, []);

  // console.log("lang=>",language);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        autoDisplay: false,
        // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
        // includedLanguages: 'af,am,ar,az,be,bg,bn,bs,ca,ceb,co,cs,cy,da,de,el,en,eo,es,et,eu,fa,fi,fr,fy,ga,gd,gl,gu,ha,haw,he,hi,hmn,hr,ht,hu,hy,id,ig,is,it,iw,ja,jv,ka,kk,km,kn,ko,kur,ky,la,lb,lo,lt,lv,mg,mhr,mi,mk,ml,mn,mr,mrj,ms,mt,my,ne,nl,no,ny,or,pa,pap,pl,ps,pt,ro,ru,rw,si,sk,sl,sm,sq,sr,su,sv,sw,ta,te,tg,th,tl,tr,tt,ug,uk,ur,uz,vi,xh,yi,yo,zh,zu',
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    document.cookie = `googtrans=/en/${language};`;
    window.location.reload();
    // Call translation function here if needed
  };

  useEffect(() => {
    // Select the element with the class name and modify it
    // console.log("=================mainElement11");
    const elementList = document.querySelectorAll(
      ".VIpgJd-ZVi9od-aZ2wEe-wOHMyf"
    );
    var element_array = [...elementList]; // converts NodeList to Array
    element_array.forEach((elementItem) => {
      if (elementItem) {
        // console.log("=================mainElement12", elementItem);
        elementItem.style.display = "none";
      }
    });

    setTimeout(() => {
      // console.log("=================mainElement21");
      const elementList = document.querySelectorAll(
        ".VIpgJd-ZVi9od-aZ2wEe-wOHMyf"
      );
      var element_array = [...elementList]; // converts NodeList to Array
      element_array.forEach((elementItem) => {
        if (elementItem) {
          // console.log("=================mainElement22", elementItem);
          elementItem.style.display = "none";
        }
      });
    }, 5000);

    const element = document.querySelector(".VIpgJd-ZVi9od-aZ2wEe");
    if (element) {
      // console.log("element1", element)
      // Modify the element, for example, hide it
      element.style.display = "none";
    }
  }, []);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => localStorage.setItem("ip", data.ip))
      .catch((error) => console.log(error));
  }, []);

  return (
    <nav
      style={{ position: "relative" }}
      className="py-2 py-lg-2 navBar container w-100 d-flex justify-content-between align-items-center"
    >
      {copySuccess && (
        <span
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            top: "75px",
            right: "10px",
            padding: "2px 8px",
            borderRadius: "4px",
            color: "#000",
          }}
        >
          Code copied!
        </span>
      )}
      {urlCopySuccess && (
        <span
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            top: "75px",
            right: "10px",
            padding: "2px 8px",
            borderRadius: "4px",
            color: "#000",
          }}
        >
          URL copied!
        </span>
      )}

      <div className="d-flex justify-content-center align-items-center">
        <div onClick={homeClick} style={{ position: "relative", marginLeft: 8 }}>
          <img className="logo" src={logo} alt="logo" />
        </div>
        <ul className="d-flex justify-content-center align-items-center web-menu">
          <li onClick={homeClick}>
            <a>Home</a>
          </li>
          {/* {localStorage.getItem("token") && verificationStatus === 1  ? ( */}
          <li onClick={affiliateClicked}>
            <span>
              <a>Affiliate</a>
            </span>
          </li>
          {/* ) : null} */}

          <li onClick={() => history.push("/partner")}>
            <a>Partner</a>
          </li>

          <li onClick={() => history.push("/aboutus")}>
            <a>About Us</a>
          </li>
          <li>
            <a>Blog</a>
          </li>
          <li onClick={() => history.push("/faq")}>
            <a>FAQ's</a>
          </li>
        </ul>
      </div>

      {localStorage.getItem("token") ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-end align-items-center notranslate mr-2">
            {/* <div
          className="kiosk-google-translate"
          id="google_translate_element"
          onClick={foo}
        ></div> */}
            {/* <div className="kiosk-google-translate"> */}
            <select
              className="translate-btn"
              value={language}
              onChange={handleLanguageChange}
            >
              {/* <option value="">Select Language</option> */}
              {languageCodes.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{ position: "relative" }}
            ref={navDropdownRef}
            onClick={() => {
              setIsProfileOptionOpen(!isProfileOptionOpen);
            }}
            className="profile-button"
          >
            <img src={userProfileImg} alt="userProfileImg" />
            {/* <img src={dummyProfile} alt="" /> */}
            {!isProfileOptionOpen ? (
              <FaBars style={{ margin: "0 9px" }} />
            ) : (
              <FaTimes style={{ margin: "0 9px" }} />
            )}

            <div
              style={{ display: isProfileOptionOpen ? "flex" : "none" }}
              className="profileOptions"
            >
              <div className="mob-menu-options-tray w-100 flex-column justify-content-center align-items-start">
                {/* <div className="mob-menu-options d-flex justify-content-start align-items-center p-2 w-100">
                <span>Home</span>
              </div>
              <div className="mob-menu-options d-flex justify-content-start align-items-center p-2 w-100">
                <span onClick={(e)=>affiliateClicked(e)}>Affiliate</span>
              </div>
              <div className="mob-menu-options d-flex justify-content-start align-items-center p-2 w-100">
                <span>About</span>
              </div>
              <div className="mob-menu-options d-flex justify-content-start align-items-center p-2 w-100">
                <span>Blog</span>
              </div>
              <div className="mob-menu-options d-flex justify-content-start align-items-center p-2 w-100">
                <span>FAQ's</span>
              </div> */}
              </div>

              {/* <div
              className="profileOptions-div d-flex justify-content-start align-items-center p-2 w-100"
            >
              <p>GKT-{localStorage.getItem("referralCode")}</p>
            </div> */}
              {localStorage.getItem("name") &&
              localStorage.getItem("name").length > 0 ? (
                <div
                  title={localStorage.getItem("name")}
                  className="profileOptions-div d-flex flex-column justify-content-start align-items-start py-2 px-3 w-100"
                >
                  <span
                    style={{ fontSize: "10px", fontWeight: "600" }}
                    className="text-muted"
                  >
                    Name
                  </span>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <p className="text-truncate">{localStorage.getItem("name")}</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {localStorage.getItem("countryName") &&
              localStorage.getItem("countryName") !== "null" &&
              localStorage.getItem("countryName").length > 0 ? (
                <div
                  title={localStorage.getItem("countryName")}
                  className="profileOptions-div d-flex flex-column justify-content-start align-items-start py-2 px-3 w-100"
                >
                  <span
                    style={{ fontSize: "10px", fontWeight: "600" }}
                    className="text-muted"
                  >
                    Country Name
                  </span>
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <p className="text-truncate">{localStorage.getItem("countryName")}</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                title={localStorage.getItem("emailId")}
                className="profileOptions-div d-flex flex-column justify-content-start align-items-start py-2 px-3 w-100"
              >
                <span
                  style={{ fontSize: "10px", fontWeight: "600" }}
                  className="text-muted"
                >
                  Email ID
                </span>
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <p className="text-truncate">{localStorage.getItem("emailId")}</p>
                </div>
              </div>

              <div
                title={localStorage.getItem("referralCode")}
                className="profileOptions-div d-flex flex-column justify-content-start align-items-start py-2 px-3 w-100"
              >
                <span
                  style={{ fontSize: "10px", fontWeight: "600" }}
                  className="text-muted"
                >
                  Referral code
                </span>
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <p>{localStorage.getItem("referralCode")}</p>
                  <BiCopy
                    title="Copy"
                    style={{ fontSize: "25px" }}
                    onClick={handleCopyClick}
                  />
                </div>
              </div>
              {localStorage.getItem("referralCode") &&
                localStorage.getItem("referralCode") != "" && (
                  <div
                    title={localStorage.getItem("referralCode")}
                    className="profileOptions-div d-flex flex-column justify-content-start align-items-start py-2 px-3 w-100"
                  >
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <p>Referral URL</p>
                      <BiCopy
                        title="Copy"
                        style={{ fontSize: "25px" }}
                        onClick={handleURLCopyClick}
                      />
                    </div>
                  </div>
                )}

              {/* {!getEsimIccids ?<></>: getEsimIccids.map((item,index)=><div
              key={index}
              // style={{ overflowX: "hidden" }}
              title={"Esim-"+item.iccid}
              onClick={()=>esimIccIdClicked(item.esimIdEncrypted)}
              className="profileOptions-div d-flex justify-content-start align-items-center p-2 w-100"
            >
              <p
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                }}
              >
                {"Esim-"+item.iccid}
              </p>
            </div>
)} */}

              <div
                onClick={myEsimClicked}
                className="profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
              >
                <GiProcessor />
                <p>My eSIM</p>
              </div>
              {verificationStatus === 1 && (
                <div
                  onClick={(e) => dashboardClicked(e)}
                  className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                >
                  <span>Dashboard</span>
                </div>
              )}

              <div className="mob-menu-options-tray w-100 flex-column justify-content-center align-items-start">
                <div
                  onClick={homeClick}
                  className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                >
                  <span>Home</span>
                </div>

                <div
                  onClick={(e) => affiliateClicked(e)}
                  className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                >
                  <span>Affiliate</span>
                </div>

                <div
                  onClick={() => history.push("/partner")}
                  className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                >
                  <span>Partner</span>
                </div>

                <div
                  onClick={() => history.push("/aboutus")}
                  className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                >
                  <span>About Us</span>
                </div>
                <div className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100">
                  <span>Blog</span>
                </div>
                <div
                  style={{ borderBottom: "1px solid #616161" }}
                  onClick={() => history.push("/faq")}
                  className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                >
                  <span>FAQ's</span>
                </div>
              </div>
              <div
                onClick={signOutClicked}
                className="last-header-option profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
              >
                <PiSignOutBold />
                <p>Sign Out</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-end align-items-center notranslate">
            {/* <div
          className="kiosk-google-translate"
          id="google_translate_element"
          onClick={foo}
        ></div> */}
            {/* <div className="kiosk-google-translate"> */}
            <select
              className="translate-btn"
              value={language}
              onChange={handleLanguageChange}
            >
              {/* <option value="">Select Language</option> */}
              {languageCodes.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
          <div
            onClick={() => {
              setWithoutLogin(!withoutLogin);
            }}
            className="without-login-section"
          >
            {withoutLogin ? (
              <FaTimes style={{ margin: "0 9px" }} />
            ) : (
              <FaBars style={{ margin: "0 9px" }} />
            )}

            {withoutLogin ? (
              <div style={{ display: "flex" }} className="profileOptions">
                <div className="mob-menu-options-tray w-100 flex-column justify-content-center align-items-start">
                  <div className="mob-menu-options-tray-links">
                    <div
                      onClick={homeClick}
                      className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                    >
                      <span>Home</span>
                    </div>
                    {/* <div className="mob-menu-options d-flex justify-content-start align-items-center p-2 w-100">
                      <span>Affiliate</span>
                    </div> */}
                    <div
                      onClick={() => history.push("/partner")}
                      className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                    >
                      <span>Partner</span>
                    </div>

                    <div
                      onClick={(e) => affiliateClicked(e)}
                      className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                    >
                      <span>Affiliate</span>
                    </div>

                    <div
                      onClick={() => history.push("/aboutus")}
                      className="mob-menu-options  profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                    >
                      <span>About Us</span>
                    </div>
                    <div className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100">
                      <span>Blog</span>
                    </div>
                    <div
                      onClick={() => history.push("/faq")}
                      className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                    >
                      <span>FAQ's</span>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      logInClicked();
                      setIsLoggedIn(true);
                    }}
                    className="mob-menu-options profileOptions-div d-flex justify-content-start align-items-center py-2 px-3 w-100"
                  >
                    <span>Log In</span>
                  </div>
                  <div
                    onClick={() => {
                      signUpClicked();
                    }}
                    className="last-header-option mob-menu-options profileOptions-div sign-up-btn d-flex justify-content-start align-items-center py-2 px-3 w-100"
                  >
                    <span>Sign Up</span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="web-log-cta justify-content-center align-items-center">
            <button
              onClick={() => {
                logInClicked();
                setIsLoggedIn(true);
              }}
              className="btn rounded-pill btn-outline-light"
            >
              <font>Log in</font>
            </button>
            <button
              onClick={() => {
                signUpClicked();
              }}
              className="btn rounded-pill btn-light  sign-up-btn"
            >
              <font>Sign up</font>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
