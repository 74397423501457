import React, { useState, useEffect, useRef } from "react";
import "../../scss/kiosk.scss";
import Header from "./components/Header";
import { ImInfo } from "react-icons/im";
import BackButton from "./components/BackButton";
import CustomModal from "./components/CustomModal";
import discountIcon from "../../assets/img/iconamoon_discount-light.png";
import checkoutPaymentIcon from "../../assets/img/iconamoon_attention-circle-checkout.png";
import scanImg from "../../assets/img/QRScanimg.png";
import { IoIosArrowBack } from "react-icons/io";
import MicroLoader from "../Alert_Popup/MicroLoader";
import { IoMdStopwatch } from "react-icons/io";
import { FaPaperPlane } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import OrderService from "../../Services/OrderService";
import { SlClose } from "react-icons/sl";
import ApplyVoucherService from "../../Services/ApplyVoucherService";
import toast, { Toaster } from "react-hot-toast";
import { RxDropdownMenu } from "react-icons/rx";
import { FormFeedback } from "reactstrap";
// import { jwtDecode } from 'jwt-decode';
import AlertPopup from "../Alert_Popup/AlertPopup";
import QRCode from "qrcode.react";
import KioskBgElement from "./components/KioskBgElement";
import KioskOrderService from "./Services/KioskOrderService";

const KioskPayment = () => {
  const [seconds, setSeconds] = useState(99);
  const [shouldCallOrder, setShouldCallOrder] = useState(true);
  const [failedTimer, setFailedTimer] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const history = useHistory();
  let orderInterval;
  const [orderDetails, setOrderDetails] = useState();
  const [planObject, setPlanObject] = useState(null);
  const [isCoverageDropdownOpen, setIsCoverageDropdownOpen] = useState(false);
  const coverageDropdownRef = useRef(null);

  useEffect(() => {
    //check if kiosk device is loogged in
    const kioskDetails = localStorage.getItem("kioskLoginDetails") ? JSON.parse(localStorage.getItem("kioskLoginDetails")) : undefined;
    if (!kioskDetails || kioskDetails.kioskId <= 0) {
      //not logged in
      history.push("/kiosk/login");
      return;
    }
    const storedOrderDetails = localStorage.getItem("order");
    if (storedOrderDetails) {
      const parsedOrderDetails = JSON.parse(storedOrderDetails);
      setOrderDetails(parsedOrderDetails);
    }
    const storedPlan = localStorage.getItem("kioskSelectedPlanToBuy");
    // const esim = localStorage.getItem('topUpIccid')
    if (storedPlan) {
      const parsedPlan = JSON.parse(storedPlan);
      setPlanObject(parsedPlan);
    }
  }, []);

  const orderAfterPayment = () => {
    if (!isApiCallInProgress) {
      KioskOrderService.orderAfterPayment(
        localStorage.getItem("orderIdEncrypted")
      )
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.orderStatus.orderStatusId !== 1
          ) {
            localStorage.setItem(
              "orderSummaryAfterPayment",
              JSON.stringify(response.data)
            );
            clearInterval(orderInterval);
            history.push("/kiosk/paymentSuccess");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsApiCallInProgress(false);
        });
    }
  };

  useEffect(() => {
    // if(localStorage.getItem("QRLink")){
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(countdownInterval);
        setFailedTimer(true);
        setShouldCallOrder(false);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
    //   }
  }, [seconds]);

  useEffect(() => {
    // if(localStorage.getItem("QRLink")){
    const startOrderInterval = () => {
      orderInterval = setInterval(() => {
        if (shouldCallOrder) {
          orderAfterPayment();
        } else {
          clearInterval(orderInterval);
        }
      }, 10000);
    };

    startOrderInterval();

    return () => {
      clearInterval(orderInterval);
    };
    //   }
  }, [shouldCallOrder, isApiCallInProgress]);

  useEffect(() => {
    // Reset timer when component mounts or remounts
    setSeconds(99);
    // setFailedTimer(false);
    // setShouldCallOrder(true);
  }, []);

  // console.log("isApiCallInProgress => ",isApiCallInProgress)
  const [modleOpen, setModelOpen] = useState();
  const backPrompt = () => {
    clearInterval(orderInterval);
    setModelOpen(true);
    // history.push("/kiosk");
  };

  const openCoverageDropdown = () => {
    document.body.style.overflowY = "hidden";
    setIsCoverageDropdownOpen(true);
  };

  const closeCoverageDropdown = () => {
    document.body.style.overflowY = "auto";

    setIsCoverageDropdownOpen(false);
  };

  const handleCoverageClick = (event) => {
    if (
      isCoverageDropdownOpen &&
      coverageDropdownRef.current &&
      !coverageDropdownRef.current.contains(event.target)
    ) {
      closeCoverageDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [isCoverageDropdownOpen, coverageDropdownRef]);

  const CoverageDropdown = () => {
    let [country, setCountry] = useState([]);
    let flags = [
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
      "AU",
      "FR",
      "IN",
    ];
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
      if (planObject !== null) {
        setCountry(planObject.planCountry);
      }
    }, [planObject]);

    useEffect(() => {
      const filtered = country.filter((item) =>
        item.countryDescription
          .toLowerCase()
          .startsWith(searchTerm.toLowerCase())
      );
      setFilteredCountries(filtered);
    }, [searchTerm, country]);

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <div className="coverage-dropdown">
        <div className="w-100" ref={coverageDropdownRef}>
          {planObject !== null && (
            <h4>
              Coverage is provided by{" "}
              <span>{planObject.planCountry.length}</span> countries
            </h4>
          )}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="coverage-dropdown-list mt-2">
            <ul>
              {searchTerm && filteredCountries.length === 0 ? (
                <li className="no-results">No results found</li>
              ) : (
                filteredCountries.map((item, index) => (
                  <li key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <span
                            key={index}
                            className={`fflag fflag-${item.alpha2} ff-lg ff-round`}
                          ></span>
                        </div>
                        <div className="ml-2 d-flex justify-content-center align-items-center">
                          <p className="m-0">{item.countryDescription}</p>
                        </div>
                      </div>
                      {item.planCountryNetwork !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          {item.planCountryNetwork.map(
                            (network, networkIndex) => (
                              <span key={networkIndex}>{network}</span>
                            )
                          )}

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      ) : (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <span></span>

                          {/* <span>airtel</span>
                      <span>airtel</span> */}
                        </div>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>

        <SlClose className="modal-close-icon" />
      </div>
    );
  };

  const formattedTime = `00:${seconds}`;

  return (
    <div className="kiosk-app">
      <Header />

      <section className="w-100 m-auto kiosk-container kiosk-checkout">
        <div className="mt-3">
          <div className="kiosk-heading-grid">
            <BackButton handleClick={backPrompt} />
            <p className="kiosk-title text-truncate m-0">Payment</p>
          </div>
          <div className="row m-0 pr-3 Checkout-kiosk">
            <div className="col-12 col-lg-6 py-2 pr-2 pl-0 mt-4 px-4">
              {/* Do map from here */}
              {planObject !== null && (
                <ul className="list-group Kiosk-payment">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Plan
                    <span className="badge">{planObject.planName}</span>
                  </li>
                  {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                    Coverage
                    <div
                      className="order-value order-popup-link"
                      style={{ position: "relative" }}
                    >
                      {planObject.planType.planTypeId === 2 ||
                      planObject.planType.planTypeId === 3 ? (
                        <div
                          className={`${
                            isCoverageDropdownOpen ? "order-popup-active" : ""
                          }`}
                          onClick={openCoverageDropdown}
                        >
                          <span>{planObject.planCountry.length} Countries</span>
                          <RxDropdownMenu />
                        </div>
                      ) : (
                        <div>
                          {planObject.planCountry[0].planCountryNetwork !==
                          null ? (
                            <span>
                              {planObject.planCountry.map(
                                (country) => country.countryDescription
                              )}{" "}
                              -{" "}
                              {planObject.planCountry[0].planCountryNetwork
                                .map((network) => network)
                                .join(", ")}
                            </span>
                          ) : (
                            <span>
                              {planObject.planCountry.map(
                                (country) => country.countryDescription
                              )}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </li> */}
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Plan type
                    <span className="badge">Data</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Order type
                    <span className="badge">New eSIM</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Data
                    <span className="badge">{planObject.data}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Validity
                    <span className="badge">{planObject.validity} Days</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Subtotal
                    <span className="badge">£{planObject.price}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Service fees
                    <span className="badge">0.00</span>
                  </li>
                  {orderDetails.voucher !== null && (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Discount
                      <span className="badge" style={{ color: "#e55353" }}>
                        {" "}
                        £{orderDetails.voucher.applicableAmount}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Total
                    <span className="badge">£{orderDetails.amount}</span>
                  </li>
                </ul>
              )}
            </div>
            {/* {showPart1Div && ( */}
            <div className="col-12 col-lg-6 py-2 pr-2 pl-2 mt-5 px-4 ScanQrCodeDiv">
              <h2
                className="kiosk-sub-title mb-4"
                style={{ textAlign: "center" }}
              >
                Scan this QR code to make payment
              </h2>
              <div>
                <QRCode
                  id="targetQRcode"
                  level={"H"}
                  includeMargin={true}
                  value={localStorage.getItem("QRLink")}
                  size={300}
                />
              </div>

              {/* </div> */}

              <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                {!failedTimer ? (
                  <h3 className="kiosk-sub-title m-0">
                    Awaiting your payment...
                  </h3>
                ) : (
                  <h3 className="kiosk-sub-title">
                    Once your payment is completed, click on Recheck Payment
                    Status.
                  </h3>
                )}
                {!failedTimer && (
                  <>
                    <p className="kiosk-sub-title">
                      Kindly do not go back or refresh your page
                    </p>
                    <h1 className="kiosk-title">{formattedTime}</h1>
                    <div>
                      <h4 className="mb-3">Time Remaining</h4>
                    </div>
                  </>
                )}
                <div className="w-100 mt-4">
                  <button
                    disabled={failedTimer ? false : true}
                    onClick={orderAfterPayment}
                    style={{fontSize: 26}}
                    className="btn kiosk-grad-btn btn-block rounded-pill px-4 py-3"
                  >
                    <font>Recheck Payment Status</font>
                  </button>
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
      {isCoverageDropdownOpen ? (
        <div className="kiosk-dropdowns-modal">
          <CoverageDropdown />
        </div>
      ) : null}
      <CustomModal
        isOpen={modleOpen}
        onClose={() => setModelOpen(false)}
        backButton={false}
        width={"normal"}
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
          <>
            <img
              className="mr-1 ml-2"
              alt="discout"
              style={{ width: "77px" }}
              src={checkoutPaymentIcon}
            />
            {/* <h2 className="kiosk-title text-center">Device compatibility</h2> */}
            <h3 className="kiosk-sub-title mb-3 text-center">
              Are you sure, you want to go back ?
            </h3>
            <div
              style={{ gap: "200px" }}
              className="w-100 d-flex justify-content-center align-items-center"
            >
              <button
                onClick={() => history.push("/kiosk/checkout")}
                style={{ height: "60px" }}
                className="btn btn-lg btn-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                <font>OK</font>
              </button>
              <button
                onClick={() => setModelOpen(false)}
                style={{ height: "60px" }}
                className="btn btn-lg btn-outline-light py-2 rounded-pill w-30 kiosk-cta-2 mt-3"
              >
                Cancel
              </button>
            </div>
          </>
        </div>
      </CustomModal>
      <KioskBgElement />
    </div>
  );
};

export default KioskPayment;
