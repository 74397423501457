import React, { useState,useEffect } from "react";
import loginBottomGrad from "../../assets/img/login-bottom-gradient.png";
import loginLeftGrad from "../../assets/img/login-left-gradient.png";
import LoginService from "../../Services/LoginService";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import ForgotPasswordService from "../../Services/ForgotPasswordService";
import Header from "../Header/Header";
import ContactUsForm from "../Common/ContactUsForm";
import AlertPopup from "../Alert_Popup/AlertPopup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


let initialValues = {
  email: "",
  
};

const validationSchema = function (values) {
  return Yup.object().shape({
    email: Yup.string().required("Email is Required").email("Invalid Email"),
    // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/, ('Invalid Email Format')),

  });
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const ForgotPassword = (props) => {
    const history = useHistory();
    const { state } = useLocation();
    const loginEmail = state && state.email;
    const [loginEmailId, setLoginEmailId] = useState(loginEmail);
    const [emailId, setEmailId] = useState(loginEmailId ? loginEmailId : "")
    const [message, setMessage] = useState()
    const [propMessage, setPropMessage] = useState()

  
  useEffect(() => {
    setLoginEmailId(loginEmail);

    const initialValues = {
      email: loginEmail || "",
    };
    setEmailId(initialValues.email);
  }, [loginEmail]);
  
    const touchAll = (setTouched, errors) => {
        setMessage("");
        setTouched({
          email: true,
        });
        validateForm(errors);
      };
    
      const validateForm = (errors) => {
        findFirstError("forgotPassword", (fieldName) => {
          return Boolean(errors[fieldName]);
        });
      };
    
      const findFirstError = (formName, hasError) => {
        const form = document.forms[formName];
        for (let i = 0; i < form.length; i++) {
          if (hasError(form[i].name)) {
            form[i].focus();
            break;
          }
        }
      };

    const dataChange = (event) => {
        const { name, value } = event.target;
        setEmailId(value); // Set the value directly
    };

    const forgotPasswordApi = () =>{
    ForgotPasswordService.forgotPassword(emailId).then((response) => {
        if (response.status===200) {
            // setPropMessage("Email Sent")
            const message = "Email Sent"
            history.push({
              pathname: '/login',
              state: { message }
            });
          }
    }).catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason)
              break;
            case 403:
              setMessage(error.response.data.failedReason)
              break;
            case 500:
              setMessage(error.response.data.failedReason)
              break;
            case 404:
            case 406:
              setMessage(error.response.data.failedReason);
              break;
            case 412:
              setMessage(error.response.data.failedReason)
              break;
            default:
              setMessage("Unknown Error");
              break;
          }
        }
      });
}
  
const closePopup = () => {
  setMessage("");
};

const goToRegister = (e) => {
    e.preventDefault();
    history.push("/register");
  };
  const goToRegisterBtn = (e) => {
    e.preventDefault();
    const email = emailId
    history.push({
      pathname: "/register",
      state: { email },
    });
    // history.push("/register");
  };
  const RegisterBtn = () => {
    return (
      <button
      className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={goToRegisterBtn}
      >
        REGISTER
      </button>
    );
  };

   // google analytics

   useEffect(() => {
    document.title = "Forgot Password Page";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div style={{ overflow: "hidden" }} className="container-fluid p-0">
      <header className="w-100" style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>
        <Header/>
      </header>
      <div className="row no-gutter">
        <div className="col-md-6 login-bg first-section">
          <div className="login d-flex align-items-center py-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                  <h1 className="login-heading text-center">Forgot Password</h1>
                  <p className="text-muted mb-2 text-center">
                    Enter your email to reset your password
                  </p>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{ email: emailId }} 
                    validate={validate(validationSchema)}
                    validateOnChange={false}
                    validateOnBlur={false}
                    // onSubmit={registerClicked}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      forgotPasswordApi();
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      setTouched,
                      handleReset,
                    }) => (
                      <form
                        name="forgotPassword"
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        noValidate
                        autoComplete="on"
                      >
                        <div className="form-group mb-3">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Email</label>
                          </div>
                          <input
                            name="email"
                            valid={!errors.email}
                            invalid={touched.email && !!errors.email}
                            onChange={(e) => {
                              handleChange(e);
                              dataChange(e);
                            }}
                            onBlur={handleBlur}
                            value={emailId}
                            id="inputEmail"
                            type="email"
                            placeholder="Enter your email"
                            required=""
                            className={`form-control border-0 shadow-sm custom-input
                            ${
                              errors.email === "" || errors.email === undefined
                                ? ""
                                :
                                 "input-error"
                            }`
                            
                        }
                          />
                          <FormFeedback
                            style={{
                              display:
                                errors.email === "" ||
                                errors.email === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {errors.email}
                          </FormFeedback>
                        </div>
                       
                        <button
                          onClick={() => touchAll(setTouched, errors)}
                        //   onClick={forgotPasswordApi}
                          type="submit"
                          className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                        >
                          Submit
                        </button>
                        <p className="text-muted mb-4 text-center">
                          Not a member yet?{" "}
                          <span 
                          onClick={goToRegister}
                          >
                            <a href="">Register Now</a>
                          </span>
                        </p>
                      </form>
                    )}
                  />
                  <div>
                    <FormFeedback
                      style={{
                        display:
                          message === "" || message === undefined
                            ? "none"
                            : "block",
                      }}
                      className="red"
                    >
                      {message}
                    </FormFeedback>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
          <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
        </div>
        {/* <div className="col-md-6 d-none d-md-flex login-bg-image"></div> */}
        <div className="col-md-6 d-none d-md-flex login-bg-image">
        {/* <div className="world-cup-content">
            <div>
              <h3 className="mb-2 text-center">
                Secure Your
                <br />
                 2023 World Cup Ticket
              </h3>
              <h3 className="text-center">
                <span>

                JOIN LUCKY DRAW 
                </span>
                <br />
                Register & Verify Your Email by October 31st!
              </h3>
            </div>

            <div style={{marginBottom: '10px'}} className="mt-auto">
              <h1>REGISTER NOW!</h1>
              <p>
                <span style={{fontSize: '8.8px',fontWeight: '700'}}>
                2023 World Cup Ticket Lucky Draw T&C: </span>Register by Oct 31st to
                Enter! Unique Email Id is a must. The number of users signing up
                through your unique code will be considered. The number of
                conversion will be considered. Organisers have all rights
                regarding the final decision in this campaign.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      {
                    message === "User does not exists" ?
                    <AlertPopup
                    status={"error"}
                    heading={"User does not exists"}
                    content={"You have entered an incorrect email ID. Please ensure the correct email ID or click 'Register' below."}
                    customFunction={<RegisterBtn />}
                    updateState={closePopup}
                  /> : ""
                  }
      <ContactUsForm/>
    </div>
  );
};

export default ForgotPassword;
