import React, { useEffect, useState } from "react";
import Roulette from "react-roulette-game";
import "./WheelComponent.css";
// import highlight_img from "./assets/hightlight.png";
// smarty
import smartyBg from "./assets/campaign/smarty/wheel_smarty.png";
import smartyRing from "./assets/campaign/smarty/outer_ring_smarty.png";
import smartyPointer from "./assets/campaign/smarty/pointer_smarty.png";

// voxi
import voxiBg from "./assets/campaign/voxi/wheel_voxi.png";
import voxiRing from "./assets/campaign/voxi/outer_ring_voxi.png";
import voxiPointer from "./assets/campaign/voxi/pointer_voxi.png";

// giffgaff
import giffgaffBg from "./assets/campaign/giffgaff/wheel_giffgaff.png";
import giffgaffRing from "./assets/campaign/giffgaff/outer_ring_giffgaff.png";
import giffgaffPointer from "./assets/campaign/giffgaff/pointer_giffgaff.png";

// default
import defaultBg from "./assets/rou_under_high.png";
import defaultOuterRing from "./assets/rou_on_high.png";
import defaultPointer from "./assets/pointer.png";

// Prices
import gktPrize from "./assets/prizeImages/gktPrize.png";
import clPrize from "./assets/prizeImages/clPrize.png";
import threePrize from "./assets/prizeImages/threePrize.png";
import vodafonePrize from "./assets/prizeImages/vodafonePrize.png";

const WheelComponent = ({
  setShowReward,
  stopPos,
  setPrize,
  setPrizeImg,
  start,
  reset,
  campaign,
}) => {
  const [activeCampaign, setActiveCampaign] = useState(campaign);
  const [activeCampaignWheel, setActiveCampaignWheel] = useState(null);
  const [activeCampaignRing, setActiveCampaignRing] = useState(null);
  const [activePointer, setActivePointer] = useState(null);

  useEffect(() => {
    // console.log("active me =>",activeCampaign)
    const activeCampaignNumber = Number(activeCampaign);
    switch (activeCampaignNumber) {
      case 4:
        // console.log("when campaign id 4 smarty");
        setActiveCampaignWheel(smartyBg);
        setActiveCampaignRing(smartyRing);
        setActivePointer(smartyPointer);
        break;
      case 3:
        // console.log("when campaign id 3 voxi");
        setActiveCampaignWheel(voxiBg);
        setActiveCampaignRing(voxiRing);
        setActivePointer(voxiPointer);
        break;
      case 5:
        // console.log("when campaign id 5 giffgaff");
        setActiveCampaignWheel(giffgaffBg);
        setActiveCampaignRing(giffgaffRing);
        setActivePointer(giffgaffPointer);
        break;

      default:
        setActiveCampaignWheel(defaultBg);
        setActiveCampaignRing(defaultOuterRing);
        setActivePointer(defaultPointer);

        break;
    }
  }, [activeCampaign]);

  const on_complete = (prize) => {
    setShowReward(true);
    // switch (prize) {
    //   case "network3_1":
    //     setPrize("network3_1");
    //     setPrizeImg(threePrize);
    //     break;
    //   case "clearLifestyle_1":
    //     setPrize("clearLifestyle_1");
    //     setPrizeImg(clPrize);
    //     break;
    //   case "vodafone_1":
    //     setPrize("vodafone_1");
    //     setPrizeImg(vodafonePrize);
    //     break;
    //   case "gkt_1":
    //     setPrize("gkt_1");
    //     setPrizeImg(gktPrize);
    //     break;
    //   case "network3_2":
    //     setPrize("network3_2");
    //     setPrizeImg(threePrize);
    //     break;
    //   case "clearLifestyle_2":
    //     setPrize("clearLifestyle_2");
    //     setPrizeImg(clPrize);
    //     break;
    //   case "vodafone_2":
    //     setPrize("vodafone_2");
    //     setPrizeImg(vodafonePrize);
    //     break;
    //   case "gkt_2":
    //     setPrize("gkt_2");
    //     setPrizeImg(gktPrize);
    //     break;
    //   case "network3_3":
    //     setPrize("network3_3");
    //     setPrizeImg(threePrize);
    //     break;
    //   case "clearLifestyle_3":
    //     setPrize("clearLifestyle_3");
    //     setPrizeImg(clPrize);
    //     break;
    //   case "vodafone_3":
    //     setPrize("vodafone_3");
    //     setPrizeImg(vodafonePrize);
    //     break;
    //   case "gkt_3":
    //     setPrize("gkt_3");
    //     setPrizeImg(gktPrize);
    //     break;
    //   default:
    //     break;
    // }
  };
  // console.log("reset1111",reset)
  const prize_arr_voxi = [
  0,1,2,3,4,5,6,7,8,9
   ]
  const  prize_arr_smarty = [
  0,1,2,3,4,5,6,7,8,9
   ]
   const prize_arr_gg = [
    0,1,2,3,4,5,6,7,8,9
   ]
   const prize_arr = [
    "vodafone_1",
    "smarty",
    "ee",
    "network3_1",
    "voxi",
    "o2_1",
    "lebara",
    "network3_2",
    "giffGaff_1",
    "vodafone_2",
    "o2_2",
    "giffGaff_1",
    "gkt",
  ]
  const roulette_props = {
    roulette_img_under_highlight: activeCampaignWheel,
    roulette_img_on_highlight: activeCampaignRing,
    // highlight_img,
    pointer_img: activePointer,
    prize_arr: activeCampaign === "5" ? prize_arr_gg: activeCampaign==="3"?prize_arr_voxi:activeCampaign==="4"?prize_arr_smarty : prize_arr,
    on_complete,
    has_reset: true,
    start_text: "",
    stop_pos: localStorage.getItem("stopPos"),
    start: start,
    reset: reset,
  };
  // console.log("roulette_props=>",roulette_props)

  // useEffect(() => {
  //   let spinBtn = document.querySelector(".start-btn");
  //   let spinImage = document.querySelector(".epbebv");
  //////   spinImage.classList.add("spinImageEnter");

  //   const handleMouseEnter = () => {
  //     spinImage.classList.add("spinImageEnter");
  //   };
  //   const handleMouseLeave = () => {
  //     spinImage.classList.remove("spinImageEnter");
  //   };

  //   if (spinBtn) {
  //     spinBtn.addEventListener("mouseenter", handleMouseEnter);
  //     spinBtn.addEventListener("mouseleave", handleMouseLeave);
  //   }

  //   return () => {
  //     if (spinBtn) {
  //       spinBtn.removeEventListener("mouseenter", handleMouseEnter);
  //       spinBtn.removeEventListener("mouseleave", handleMouseLeave);
  //     }
  //   };
  // }, []);

  return (
    <>
      <div className="game-box">
        <Roulette {...roulette_props} />
      </div>
    </>
  );
};

export default WheelComponent;
