import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import Carousel from "../Common/Carousel";
import leftTopImg from "../../assets/img/left-top-img_.png";
import rightBottomImg from "../../assets/img/right-grad-top.png";
import leftBottomImg from "../../assets/img/left-bottom-img.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router-dom";
import ListSection from "./ListSection";
import RegionListService from "../../Services/RegionService";
import star from "../../assets/img/stars.png";
import advImg from "../../assets/advertisment/student_adv.jpg";
import UserVarificationService from "../../Services/UserVarificationService";
import DashboardDetailsService from "../../Services/DashboardDetailsService";
import AlertPopup from "../Alert_Popup/AlertPopup";
import infoGradient1 from "../../assets/img/gradient_3page_1_1.png";
import infoGradient2 from "../../assets/img/gradient_3page_2_1.png";
import { TbDeviceMobileCheck } from "react-icons/tb";
import { BiCheckShield, BiCartAlt } from "react-icons/bi";
import { MdOutlineInstallMobile } from "react-icons/md";
import CtaBanner from "../Common/CtaBanner";
import ContactUsService from "../../Services/ContactUsService";
import Loader from "../Alert_Popup/Loader";
import ContactUsForm from "../Common/ContactUsForm";
// Carousel
import slideOneBgLg from "../../assets/img/carousel/advt_large_1.jpg";
import slideOneBgSm from "../../assets/img/carousel/advt_small_1.jpg";
import slideTwoBgLg from "../../assets/img/carousel/advt_large_2.jpg";
import slideTwoBgSm from "../../assets/img/carousel/advt_small_2.jpg";
import slideThreeBgLg from "../../assets/img/carousel/advt_large_3.png";
import slideThreeBgSm from "../../assets/img/carousel/advt_small_3.png";
import slideFourBgLg from "../../assets/img/carousel/advt_large_4.jpg";
import slideFourBgSm from "../../assets/img/carousel/advt_small_4.jpg";
import slideFiveBgLg from "../../assets/img/carousel/advt_large_5.jpg";
import slideFiveBgSm from "../../assets/img/carousel/advt_small_5.jpg";
import slideSixBgLg from "../../assets/img/carousel/advt_large_6.jpg";
import slideSixBgSm from "../../assets/img/carousel/advt_small_6.jpg";

import {
  IoIosCloseCircleOutline,
  IoIosPlay,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import VideoPlayer from "../Common/VideoPlayer";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useScrollToTop from "../custom_hooks/useScrollToTop";
import DocumentMeta from "react-document-meta";
// let initialValues = {
//   name: "",
//   emailId: "",
//   mobileNo: "",
//   content: "",
// };

// const validationSchema = function (values) {
//   return Yup.object().shape({
//     name: Yup.string()
//     .matches(/^[A-Za-z ]+$/,"Please enter a valid Name")
//     .required("Name is Required"),
//     emailId: Yup.string().required("Email is Required").email("Invalid Email"),
//     // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/, ('Invalid Email Format')),
//     mobileNo: Yup.string()
//       .test(
//         "isValidContactNumber",
//         "Invalid contact number",
//         customContactNumberValidation
//       ),
//     content: Yup.string().required("Issue is Required"),
//   });
// };

// const validate = (getValidationSchema) => {
//   return (values) => {
//     const validationSchema = getValidationSchema(values);
//     try {
//       validationSchema.validateSync(values, { abortEarly: false });
//       return {};
//     } catch (error) {
//       return getErrorsFromValidationError(error);
//     }
//   };
// };

// const getErrorsFromValidationError = (validationError) => {
//   const FIRST_ERROR = 0;
//   return validationError.inner.reduce((errors, error) => {
//     return {
//       ...errors,
//       [error.path]: error.errors[FIRST_ERROR],
//     };
//   }, {});
// };

// const customContactNumberValidation = (value) => {
//   if (value && value.trim().length > 0) {
//     return /^[+\d]+$/.test(value);
//   }
//   return true;
// };

const Hero = (props) => {
  useScrollToTop();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [changeImg, setChangeImg] = useState(true);
  const [image, setImage] = useState();
  const [dummy, setDummy] = useState();
  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [europeItems, setEuropeItems] = useState([]);
  const [asiaItems, setAsiaItems] = useState([]);
  const [africaItems, setAfricaItems] = useState([]);
  const [americasItems, setAmericasItems] = useState([]);
  const [oceaniaItems, setOceaniaItems] = useState([]);
  const [countryBasedOnRegion, setCountrybasedOnRegion] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const history = useHistory();
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [isValidSuggestion, setIsValidSuggestion] = useState(true);

  const [divWidth, setDivWidth] = useState(0);
  const [divHeight, setDivHeight] = useState(0);

  const [showLogin, setShowLoginPopUp] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);

  const deviceCompatibleContent = useRef(null);

  const [showAdv, setShowAdv] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  const popMessage = state && state.message;
  const [popupMessage, setPopupMessage] = useState(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [message, setMessage] = useState();
  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    mobileNo: "",
    content: "",
    createdBy: {
      id: localStorage.getItem("userId") ? localStorage.getItem("userId") : 0,
    },
  });
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isVideoOff2, setIsVideoOff2] = useState(false);
  const listSectionRef = useRef(null);

  const dataChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setPopupMessage(popMessage);
  }, [popMessage]);

  const closePopup = () => {
    setPopupMessage(undefined);
  };

  // const touchAll = (setTouched, errors) => {
  //   setMessage("");
  //   console.log ("called")
  //   console.log("call",setTouched)
  //   setTouched({
  //     name: true,
  //     emailId: true,
  //     mobileNo: true,
  //     content: true,
  //   });
  //   validateForm(errors);
  // };

  // const validateForm = (errors) => {
  //   console.log("call2")
  //   findFirstError("contactUs", (fieldName) => {
  //     return Boolean(errors[fieldName]);
  //   });
  // };

  // const findFirstError = (formName, hasError) => {
  //   console.log("call3")
  //   const form = document.forms[formName];
  //   for (let i = 0; i < form.length; i++) {
  //     if (hasError(form[i].name)) {
  //       form[i].focus();
  //       break;
  //     }
  //   }
  // };

  useEffect(() => {
    const isFirstRender = localStorage.getItem("isFirstRender") === null;
    if (isFirstRender) {
      setTimeout(() => {
        setShowAdv(true);
      }, 200);

      localStorage.setItem("isFirstRender", "false");
    } else {
      setShowModal(true);
    }
  }, []);

  const closeModalPopup = () => {
    setShowAdv(false);
    setTimeout(() => {
      setShowModal(true);
    }, 700);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const earnLearnContent = [
    {
      id: 0,
      title: "Why Choose Our “Earn While You Learn” Program?",
      desc: "Our program offers industry-leading rewards, high-quality eSIM, competitive data rate, real time tracking and dedicated support to help you succeed.",
    },
    {
      id: 1,
      title: "Who Can Benefit?",
      desc: "If you have a strong online presence, whether through a blog, social media, or website, you can capitalise on our program and earn substantial Rewards.",
    },
    {
      id: 2,
      title: "How to Get Started?",
      desc: "Joining our program is quick and simple. Just register on the program, receive your unique code, and start promoting. Begin earning Rewards immediately!",
    },
  ];

  const handleResize = () => {
    if (deviceCompatibleContent.current) {
      setDivWidth(deviceCompatibleContent.current.offsetWidth);
      setDivHeight(deviceCompatibleContent.current.offsetHeight);
    }
  };

  useEffect(() => {
    handleResize(); // Set initial dimensions
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log("work", deviceCompatibleContent);
    // console.log("work", deviceCompatibleContent);
  }, [deviceCompatibleContent]);

  useEffect(() => {
    RegionListApi();
    countryList();
  }, []); // Empty dependency array ensures this runs only on component mount

  // Function to fetch countries based on the selected region
  const RegionListApi = () => {
    // RegionService.
    RegionListService.regionList().then((response) => {
      // console.log("regionHero", response.data);
      // console.log("region", response.data);
      setRegions(response.data);
      // setIsLoading(false)
      // setRegionId(response.data.map((rId)=>rId.regionId));
    });
  };
  const countryList = () => {
    RegionListService.countryAccordingToRegionList().then((response) => {
      // console.log(
      //   "countryAccordingToRegion",
      //   response.data.filter(
      //     (country) => country.subRegion.region.regionDescription === "Europe"
      //   ).length
      // );
      setCountrybasedOnRegion(response.data);
      setEuropeItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Europe"
        )
      );
      setAsiaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Asia"
        )
      );
      setAfricaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Africa"
        )
      );
      setAmericasItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Americas"
        )
      );
      setOceaniaItems(
        response.data.filter(
          (country) => country.subRegion.region.regionDescription === "Oceania"
        )
      );
    });
  };
  const handleInputChange = (event) => {
    setActiveSuggestionIndex(0);

    const userInputValue = event.target.value.trim();
    setUserInput(userInputValue);

    if (userInputValue === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    // Convert user input to lowercase
    const userInputValueLowercase = userInputValue.toLowerCase();

    // Filter regions based on user input
    const regionSuggestions = regions.filter((region) =>
      region.regionDescription.toLowerCase().startsWith(userInputValueLowercase)
    );

    // Filter countries based on user input
    const countrySuggestions = allCountries.filter((country) =>
      country.countryDescription
        .toLowerCase()
        .startsWith(userInputValueLowercase)
    );

    // Combine region and country suggestions and set the suggestions state
    const combinedSuggestions = [
      ...regionSuggestions.map((region) => ({
        suggestionType: "region",
        description: region.regionDescription,
        regionId: region.regionId,
      })),
      ...countrySuggestions.map((country) => ({
        suggestionType: "country",
        description: country.countryDescription,
        Alpha2: country.alpha2,
      })),
    ];
    setIsValidSuggestion(combinedSuggestions.length > 0);
    setSuggestions(combinedSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionSearch = (suggestion) => {
    if (suggestion.suggestionType === "region") {
      history.push(`/rplans/${suggestion.regionId}/${suggestion.description}`);
    } else if (suggestion.suggestionType === "country") {
      history.push(`/plans/${suggestion.Alpha2}/${suggestion.description}`);
    }
  };

  const handleSearch = (suggestionType, regionId, Alpha2, description) => {
    if (suggestionType === "region") {
      setUserInput(description);
      history.push(`/rplans/${regionId}/${description}`);
    } else if (suggestionType === "country") {
      setUserInput(description);
      history.push(`/plans/${Alpha2}/${description}`);
    }
  };

  const activeSuggestionRef = useRef(null);

  const handleKeyDown = (event) => {
    const activeSuggestionElement = activeSuggestionRef.current;

    if (event.key === "ArrowDown") {
      // Move to the next suggestion when the down arrow key is pressed
      setActiveSuggestionIndex(
        (prevIndex) => (prevIndex + 1) % suggestions.length
      );
    } else if (event.key === "ArrowUp") {
      // Move to the previous suggestion when the up arrow key is pressed
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
    } else if (event.key === "Enter") {
      // Handle the Enter key press separately
      if (suggestions.length > 0 && suggestions[activeSuggestionIndex]) {
        // Set the selected suggestion before calling handleSearch
        setUserInput(suggestions[activeSuggestionIndex].description);
        setShowSuggestions(false); // Hide the suggestion list when Enter is pressed

        handleSearch(
          suggestions[activeSuggestionIndex].suggestionType,
          suggestions[activeSuggestionIndex].regionId,
          suggestions[activeSuggestionIndex].Alpha2,
          suggestions[activeSuggestionIndex].description
        );
      }
    }

    // Scroll the active suggestion into view when using the arrow keys
    if (activeSuggestionElement) {
      activeSuggestionElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const allCountries = [
    ...europeItems,
    ...asiaItems,
    ...africaItems,
    ...americasItems,
    ...oceaniaItems,
  ];
  // console.log("allCountries", allCountries);
  // useEffect(() => {
  //   fetch('https://api.unsplash.com/search/photos?page=1&query=europe&client_id=2xy-v7zAMswuSraDWvCI6KJ8qzgOh60UExiwJfCwuQ4')
  // .then(response => {
  //   if (!response.ok) {
  //     throw new Error('Network response was not ok');
  //   }
  //   return response.json();
  // })
  // .then(data => {
  // })
  // .catch(error => {
  // });
  // }, []);
  const joinNowClick = () => {
    // Check user verification status in local storage
    const affId = localStorage.getItem("affiliateUserId");
    if ((affId && affId === "0") || !affId) {
      const email = localStorage.getItem("emailId");
      if (!email || email === "" || email === undefined || email === null) {
        setShowLoginPopUp(true);
      } else if (email || email !== "" || email !== undefined) {
        // if (!userVerificationStatus || userVerificationStatus === "0") {
        if (localStorage.getItem("token")) {
          const token = localStorage.getItem("token");
          const user = jwtDecode(token);
          const exp = user.exp;
          const currentTime = Math.floor(Date.now() / 1000);
          if (exp < currentTime) {
            localStorage.clear();
            history.push("/");
          }
          UserVarificationService.getVerificationStatus(
            localStorage.getItem("userId")
          ).then((response) => {
            if (response.status == 200) {
              // localStorage.setItem("userVerificationStatus", response.data);
              const userVerificationStatus = response.data;
              if (userVerificationStatus == 1) {
                if (localStorage.getItem("token")) {
                  const token = localStorage.getItem("token");
                  const user = jwtDecode(token);
                  const exp = user.exp;
                  const currentTime = Math.floor(Date.now() / 1000);
                  if (exp < currentTime) {
                    localStorage.clear();
                    history.push("/");
                  }
                  DashboardDetailsService.dashboardDetailService(
                    localStorage.getItem("userId")
                  ).then((response) => {
                    if (response.status === 200) {
                      localStorage.setItem(
                        "referralCode",
                        response.data.referralCode
                      );
                      history.push("/ReferralModule");
                    }
                  });
                } else {
                  localStorage.clear();
                  history.push("/");
                }
              } else {
                // Show popup for unverified user
                setShowVerifyPopup(true);
              }
              // console.log("User is not verified. Show popup.");
              // }
              if (userVerificationStatus || userVerificationStatus === "1") {
                // User is verified
                // console.log("User is verified.");
                history.push("/ReferralModule");
              }
            }
          });
        } else {
          localStorage.clear();
          history.push("/");
        }
      }

      // console.log("logPop11", showLogin);
      // console.log("logPopemail", email);
    } else if (affId && affId !== 0) {
      history.push("/dashboard");
    }
    // console.log("affId", localStorage.getItem("affiliateUserId"));
  };

  const updateLoginPopupStatus = () => {
    setShowLoginPopUp(false);
  };
  const updateVarificationPopupStatus = () => {
    setShowVerifyPopup(false);
  };

  const loginClicked = () => {
    history.push(`/login`);
  };

  const resendClicked = () => {
    const revarification = localStorage.getItem("emailId");
    UserVarificationService.resendVerificationLink(revarification).then(
      (response) => {
        if (response.status == 200) {
          setShowVerifyPopup(false);
          // console.log("res1", response.data);
        }
        // console.log("status", response.status);
      }
    );
  };
  let a =
    "GKT eSIM Roam-Free Data Plans: Designed for celebrities like you, ensuring seamless connectivity on global adventures. Stay connected in iconic destinations with uninterrupted communication.";
  const LoginCtaBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={loginClicked}
      >
        <font>LOGIN</font>
      </button>
    );
  };

  const ResendEmailBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={resendClicked}
      >
        <font>RESEND EMAIL</font>
      </button>
    );
  };

  const scrollToOnBoarding = () => {
    const onBoardingSection = document.querySelector(".on-boarding-section");
    if (onBoardingSection) {
      onBoardingSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [validationErrors, setValidationErrors] = useState({});

  const sendClicked = (e) => {
    e.preventDefault();
    // Manually validate the form fields
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else {
      if (!/^[A-Za-z ]+$/.test(formData.name)) {
        errors.name = "Please enter a valid Name";
      }
    }

    if (!formData.emailId.trim()) {
      errors.emailId = "Email is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        formData.emailId
      )
    ) {
      errors.emailId = "Invalid Email";
    }

    if (formData.mobileNo) {
      if (!/^[+\d]+$/.test(formData.mobileNo)) {
        errors.mobileNo = "Invalid contact number";
      }
    }

    if (!formData.content.trim()) {
      errors.content = "Issue is required";
    }

    // Check if there are any validation errors
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      // No validation errors, proceed with the API call
      ContactUsService.contactUs(formData).then((response) => {
        if (response.status === 201) {
          document.getElementById("name").value = "";
          document.getElementById("emailId").value = "";
          document.getElementById("mobileNo").value = "";
          document.getElementById("content").value = "";
          // console.log("sended mail");
          toast.success("Mail sent successfully!");
          setFormData({
            name: "",
            emailId: "",
            mobileNo: "",
            content: "",
            createdBy: {
              id: localStorage.getItem("userId")
                ? localStorage.getItem("userId")
                : 0,
            },
          });
          setShowContactForm(false);
          setValidationErrors({});
          setIsLoading(false);
        }
      });
    } else {
      // Validation errors exist, set them in the state to display to the user
      setValidationErrors(errors);
    }
  };
  // const showContacForm = () => {
  //   setShowContactForm(true);
  // };
  const handleContacForm = () => {
    setShowContactForm(!showContactForm);
    setValidationErrors({});
  };
  const closeVideoPlayer = () => {
    setIsVideoOff(false);
    setIsVideoOff2(false);
  };
  const openVideoPlayer = (id) => {
    if (id == 1) {
      setIsVideoOff(true);
    }
    if (id == 3) {
      setIsVideoOff2(true);
    }
  };
  useEffect(() => {
    if (isVideoOff == true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isVideoOff]);
  useEffect(() => {
    if (isVideoOff2 == true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isVideoOff2]);
  const goToPlans = () => {
    if (listSectionRef.current) {
      listSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const joinPartners = () => {
    history.push("/partner");
  };
  const carouselData = [
    {
      id: 1,
      title: (
        <>
          🌍 Calling All International and UK Students <br />
          <span className="">
            <span
              className="d-none d-md-flex flex-column flex-md-row mt-1"
              style={{ fontSize: 15, lineHeight: 1.2, color: "#E8BE61" }}
            >
              2GB FREE Data & a free eSIM! <br className="d-md-none" />{" "}
              <span className="mx-1 d-none d-md-block">◆</span> Unlock More
              Savings! <br className="d-md-none" />{" "}
              <span className="mx-1 d-none d-md-block">◆</span> Extend Your
              Savings!
            </span>

            <span
              className="d-md-none d-flex flex-wrap justify-content-between align-items-end mt-1"
              style={{ fontSize: 15, lineHeight: 1.2, color: "#E8BE61" }}
            >
              <span className="d-flex flex-column">
                <span className="flex">
                  <span className="mx-1">◆</span>{" "}
                  <span>2GB FREE Data & a free eSIM!</span>
                </span>
                <span className="flex">
                  <span className="mx-1">◆</span>{" "}
                  <span>Unlock More Savings!</span>
                </span>
                <span className="flex">
                  <span className="mx-1">◆</span>{" "}
                  <span>Extend Your Savings!</span>
                </span>
              </span>

              <span
                style={{ fontSize: "0.875rem" }}
                className="btn rounded-pill text-capitalize font-weight-normal px-3 py-lg-2 btn-custom-gradient carousel-cta-btn shadow-sm mt-2"
                onClick={() => history.push("/studentRegistration")}
              >
                <font>Join Now</font>
              </span>
            </span>
          </span>
        </>
      ),
      description: (
        <span className="d-none d-md-block">
          <span>
            Enjoy seamless connectivity as you begin your journey in the UK.
          </span>
          <br />
          <span>
            Enjoy{" "}
            <span style={{ fontSize: 14, lineHeight: 1, color: "#E8BE61" }}>
              20% OFF
            </span>{" "}
            your second purchase.
          </span>
          <br />
          <span>
            Save{" "}
            <span style={{ fontSize: 14, lineHeight: 1, color: "#E8BE61" }}>
              30%
            </span>{" "}
            on your third purchase.
          </span>
        </span>
      ),
      // "Looking to monetize your audience and earn passive income?  Look no further! Join the GKT eSim Revolution!",
      largeBg: slideSixBgLg,
      smallBg: slideSixBgSm,
      cta1: "Join now",
      cta2: "",
      alt: "2GB FREE Data & a free eSIM!   ◆  Unlock More Savings!  ◆  Extend Your Savings!",
      ctaOnefunction: () => history.push("/studentRegistration"),
      ctaTwofunction: null,
      customBtnClass:
        "d-none d-md-block btn-custom-gradient carousel-cta-btn shadow-sm",
    },
    {
      id: 1,
      title: (
        <>
          Are you an <br />
          <span style={{ fontSize: 15, lineHeight: 1, color: "#E8BE61" }}>
            Influencer | Content Creator | Blogger | Affiliate?
          </span>
        </>
      ),
      description:
        "Looking to monetize your audience and earn passive income?  Look no further! Join the GKT eSim Revolution!",
      largeBg: slideFourBgLg,
      smallBg: slideFourBgSm,
      cta1: "Join now",
      cta2: "",
      alt: "Are you an Influencer | Content Creator | Blogger | Affiliate ?",
      ctaOnefunction: joinNowClick,
      ctaTwofunction: null,
      customBtnClass: "btn-light px-4",
    },
    {
      id: 2,
      title: (
        <>
          Global eSIM for
          <br />
          <span style={{ fontSize: 15, lineHeight: 1, color: "#E8BE61" }}>
            Inbound & Outbound Travel and Tourism
          </span>
        </>
      ),
      description:
        "🚀 Enhance your customer experience by providing eSIM services in over 200 destinations and increase revenue with GKT eSIM kiosks and touchscreen Digital Standees, integrated with our cloud-based EPOS system. 🌍✨",
      largeBg: slideFiveBgLg,
      smallBg: slideFiveBgSm,
      cta1: "Join now",
      cta2: "",
      alt: "Attention! Hotel | Resort Owners | Shopping Malls | Airport Shops.",
      ctaOnefunction: joinPartners,
      ctaTwofunction: null,
      customBtnClass: "",
    },
    {
      id: 3,
      title: "Experience the World Without Limits! 🌐✈️",
      description:
        "GKT eSIM Roam-Free Data Plans are designed for celebrities like you, providing uninterrupted connectivity on your global adventures. Enjoy the freedom to stay connected in iconic destinations",
      largeBg: slideOneBgLg,
      smallBg: slideOneBgSm,
      cta1: "Buy now",
      cta2: (
        <>
          Watch <IoIosPlay />
        </>
      ),
      alt: "Experience the World Without Limits! 🌐✈️",
      ctaOnefunction: goToPlans,
      ctaTwofunction: () => openVideoPlayer(1),
      customBtnClass: null,
    },
    {
      id: 4,
      title: "Wherever Business Takes You! 🌍👔",
      description:
        "Stay Connected, Stay Productive! GKT eSIM offers hassle-free global connectivity for your business travels. Roam-Free Data Plans, Reliable Coverage, and Instant Activation. Elevate your business mobility!",
      largeBg: slideTwoBgLg,
      smallBg: slideTwoBgSm,
      cta1: "Buy now",
      // cta2: "Know more",
      alt: "Wherever Business Takes You! 🌍👔",
      ctaOnefunction: goToPlans,
      ctaTwofunction: "",
      customBtnClass: null,
    },
    {
      id: 5,
      title: "Earn while you learn",
      description: "Explore eSIM Potential with AI-Enhanced Sales",
      largeBg: slideThreeBgLg,
      smallBg: slideThreeBgSm,
      cta1: "Join now",
      cta2: (
        <>
          Watch <IoIosPlay />
        </>
      ),
      alt: "Earn while you learn",
      ctaOnefunction: joinNowClick,
      ctaTwofunction: () => openVideoPlayer(3),
      customBtnClass: null,
    },
  ];

  // google analytics

  // useEffect(() => {
  //   document.querySelector("title").classList.add("notranslate");
  //   document.title = "Hero Page";
  // }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  const meta = {
    title: "Global eSIM Solutions by eSIM Mobile | Top eSIM Provider",
    description: "Discover eSIM Mobile for unlimited data plans, global eSIMs, and top international eSIM cards. Get seamless connectivity with our eSIM data plans and travel SIMs.",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Global eSIM, Top eSIM provider , esim unlimited data, esim data plan, unlimited data esim, e sim data plan, international e sim, international e sim card, e travel sim",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="esim-section" style={{ position: "relative" }}>
        <header>
          <Header contactForm={handleContacForm} />
        </header>
        <section className="p-0 position-relative">
          <div className="hero-section position-relative">
            <div className="container position-relative">
              <div className="mb-4">
                <div className="card carousel-card m-0 overflow-hidden position-relative">
                  <Carousel isVideoOff={isVideoOff} isVideoOff2={isVideoOff2}>
                    {carouselData.map((item, index) => (
                      <React.Fragment key={index}>
                        <img
                          className="carousel-image d-block d-lg-none"
                          src={item.smallBg}
                          alt={item.alt}
                        />
                        <img
                          className="carousel-image d-none d-lg-block"
                          src={item.largeBg}
                          alt={item.alt}
                        />
                        <div className="gradient-mask d-block d-lg-none"></div>
                        <div className="position-absolute h-100 d-flex flex-column carousel-content">
                          <div
                            style={{ marginBottom: "2.25rem" }}
                            className="mt-auto mt-lg-0 carousel-content-width"
                          >
                            <h1 className="m-0">{item.title}</h1>
                            <p className="m-0">{item.description}</p>
                            <div
                              style={{ gap: "20px" }}
                              className="mt-2 mt-lg-5 mb-0 mb-lg-2 d-flex"
                            >
                              <button
                                onClick={item.ctaOnefunction}
                                style={{ fontSize: "0.875rem" }}
                                className={`${
                                  item.customBtnClass
                                    ? item.customBtnClass
                                    : "btn-custom-gradient carousel-cta-btn shadow-sm"
                                } btn rounded-pill text-capitalize font-weight-normal mb-2 px-2 py-lg-2`}
                              >
                                <font>{item.cta1}</font>
                              </button>
                              {item.cta2 && (
                                <button
                                  onClick={item.ctaTwofunction}
                                  className="btn btn-outline-light carousel-cta-btn rounded-pill text-capitalize font-weight-normal mb-2 px-3 py-lg-2"
                                >
                                  <font>{item.cta2}</font>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </Carousel>
                  {/* <div style={{ position: "relative" }} className="imgBx">
                  <img
                    className="hero-img"
                    src={changeImg ? heroImg : heroImg2}
                    alt=""
                  />
                  <span
                    className="hero-tag py-1 pl-2 pr-4 justify-content-center align-items-center"
                    style={{}}
                  >
                    <p className="text-start m-0 ">
                      Seamless experience for users.{" "}
                    </p>
                  </span>
                </div>
                <div className="details">
                  <div className="content">
                    <h1>Find the best local & regional eSIMs</h1>
                    <span>
                      Search over 10,000+ eSIM data plans in 200+ countries
                    </span>
                  </div>
                  <div className="content-footer d-flex flex-column justify-content-center align-items-start mt-auto">
                    <p className="text-start mb-2 font-weight-bold">
                      Get your GKT eSIM app
                    </p>

                    <div className="w-100 d-flex flex-wrap justify-content-between align-items-center ">
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.altius.gkteSim"
                      >
                        <img src={googlePlay} alt="googlePlay" />
                      </a>
                      <a
                        target="_blank"
                        href="https://apps.apple.com/us/app/gkt-esim/id6464306833"
                      >
                        <img src={appStore} alt="appStore" />
                      </a>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>

              {/* -------------------------------------------- Earn while your learn ------------------------------- */}
              {/* <div className="my-4 p-0 earn-learn-section cards-bg">
              <div className="row m-0">
                <div
                  style={{ position: "relative" }}
                  className="col-12 col-md-6 d-flex flex-column justify-content-end align-items-end p-0"
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "1",
                    }}
                    className="p-3 text-start"
                  >
                    <h2 className="earn-learn-content-heading">
                      Earn While You Learn
                    </h2>
                    <p className="earn-learn-content-para">
                      Explore eSIM Potential with AI-Enhanced Sales
                    </p>
                  </div>
                  <div className="earn-learn-hero-img"></div>
                </div>
                <div className="col-12 col-md-6 p-4 earn-learn-content-web">
                  <h2>Earn While You Learn</h2>
                  <p>Explore eSIM Potential with AI-Enhanced Sales</p>

                  <div className="earn-learn-list">
                    {earnLearnContent.map((items, index) => (
                      <div
                        key={index}
                        className="list-1 d-flex justify-content-center align-items-start"
                      >
                        <div>
                          <img src={star} alt="star" />
                        </div>
                        <div className="px-2">
                          <h3>{items.title}</h3>
                          <p>{items.desc}</p>
                        </div>
                      </div>
                    ))}

                    <div className="d-flex justify-content-between align-items-center mt-4 w-100">
                      <button
                        onClick={joinNowClick}
                        className="btn-block btn btn-custom-gradient rounded-pill shadow-sm text-uppercase"
                      >
                        Join Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              {/* <div className="earn-learn-content-mob-carousel">
              <div style={{ position: "relative" }}>
                <div className="mob-carousel-section">
                  <div className="carousel-wrapper">
                    <Carousel>
                      {earnLearnContent.map((items, index) => (
                        <div className="p-3  cards-bg">
                          <div
                            key={index}
                            className="list-1 d-flex justify-content-center align-items-start"
                          >
                            <div>
                              <img src={star} alt="star" />
                            </div>
                            <div className="px-2">
                              <h3>{items.title}</h3>
                              <p>{items.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>

              <div className="earn-learn-mob-join-btn my-4 justify-content-center align-items-center">
                <button
                  onClick={joinNowClick}
                  className="btn btn-custom-gradient btn-block rounded-pill shadow-sm text-uppercase"
                >
                  Join Now
                </button>
              </div>
            </div> */}

              {/*----------------------------------------------- Device compatibility ---------------------------------------*/}
              <div className="my-4 device-compatiable-section cards-bg row m-0 w-100">
                <div className="device-compatible-bg"></div>
                <div
                  ref={deviceCompatibleContent}
                  className="row flex-sm-column-reverse flex-md-row col-12 device-compatible-content m-0"
                >
                  <div className="col-12 col-md-8 p-2 d-flex flex-column justify-content-center align-items-start">
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                      <h2 className="text-center">
                        Is your device ready for the eSIM Revolution?
                      </h2>
                      <button
                        onClick={scrollToOnBoarding}
                        className="btn btn-custom-gradient text-uppercase shadow-sm px-4 mt-auto"
                      >
                        <font>Check Device Compatibility</font>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 d-none d-md-block"></div>
                </div>
              </div>
              {/*------------------------------------------------ search section ---------------------------------------------------*/}
              <div
                ref={listSectionRef}
                className="search-section flex-column my-3"
              >
                <div className="w-100 search-section-heading mb-3">
                  <h2 className="mb-2">Find the best local & regional eSIMs</h2>
                  <p className="mb-0">
                    Search over 10,000+ eSIM data plans in 200+ countries
                  </p>
                  <img src={star} alt="" />
                </div>

                <div className="search-bar" style={{ position: "relative" }}>
                  <input
                    placeholder="Search by country or continent..."
                    type="text"
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />

                  <button
                    onClick={() => {
                      if (suggestions[activeSuggestionIndex]?.suggestionType) {
                        const selectedSuggestion =
                          suggestions[activeSuggestionIndex];
                        handleSearch(
                          selectedSuggestion.suggestionType,
                          selectedSuggestion.regionId,
                          selectedSuggestion.Alpha2,
                          selectedSuggestion.description
                        );
                      }
                      // if (selectedSuggestion?.suggestionType) {
                      //   handleSearch(
                      //     selectedSuggestion.suggestionType,
                      //     selectedSuggestion.regionId,
                      //     selectedSuggestion.Alpha2,
                      //     selectedSuggestion.description
                      //   );
                      // }
                    }}
                  >
                    <font>Search</font>
                  </button>

                  {showSuggestions && suggestions.length > 0 && (
                    <div className="search-suggestion-section">
                      <ul>
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            className={
                              index === activeSuggestionIndex
                                ? "suggestion-active"
                                : ""
                            }
                            ref={
                              index === activeSuggestionIndex
                                ? activeSuggestionRef
                                : null
                            }
                            onClick={() => {
                              handleSuggestionSearch(suggestion);
                              setUserInput(suggestion.description);
                              setSelectedSuggestion(suggestion);
                              setSuggestions([]);
                              setShowSuggestions(false); // Hide the suggestion list when a suggestion is selected
                            }}
                          >
                            {suggestion.suggestionType === "region" ? (
                              <span>{suggestion.description}</span>
                            ) : (
                              <span>{suggestion.description}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!isValidSuggestion && userInput && (
                    <div className="search-suggestion-section">
                      No result found
                    </div>
                  )}
                </div>
              </div>
            </div>

            <ListSection listSectionRef={listSectionRef} />
          </div>
        </section>
        <section>
          <div style={{ position: "relative" }} className="on-boarding-section">
            <div className="info-section info-padding  d-flex flex-column justify-content-center align-items-center">
              <div className="instruction container py-3">
                <h2>Onboarding for an GKT eSIM</h2>
                <div
                  className="instruction-row "
                  style={{ flexWrap: "nowrap" }}
                >
                  <div className="service">
                    <TbDeviceMobileCheck />
                    <h4>Device Compatibility</h4>
                    <p>
                      Check your device eSIM compatibility with our smart
                      detection features.
                    </p>
                  </div>
                  <div className="service">
                    <BiCartAlt />
                    <h4>Buy GKT eSIM</h4>
                    <p>
                      Explore and buy your eSIM plan that best suits to your
                      needs.
                    </p>
                  </div>
                  <div className="service">
                    {/* <GrInstallOption /> */}
                    {/* <BsCheckCircle/> */}
                    <MdOutlineInstallMobile />
                    <h4>Install eSIM</h4>
                    <p>
                      Just scan the QR code sent on your email and follow the
                      instructions.
                    </p>
                  </div>
                  <div className="service">
                    <BiCheckShield />
                    {/* <BiDetail /> */}
                    <h4>Instant Activation</h4>
                    <p>
                      Effortless one-click activation for an installed eSIM,
                      ensuring a seamless user experience.
                    </p>
                  </div>
                </div>
              </div>

              <CtaBanner />

              {/* <img
              className="infoGradient1"
              src={infoGradient1}
              alt="infoGradient1"
            />
            <img
              className="infoGradient2"
              src={infoGradient2}
              alt="infoGradient2"
            /> */}
            </div>
          </div>
        </section>
        <footer className="p-0">
          <Footer />
        </footer>
        {isVideoOff ? (
          <VideoPlayer
            videoSource="https://www.youtube.com/watch?v=1TuprRCHR00"
            closeVideoPlayer={closeVideoPlayer}
            isVideoOff={isVideoOff}
            id="1"
          />
        ) : null}
        {isVideoOff2 ? (
          <VideoPlayer
            videoSource="https://www.youtube.com/watch?v=o8poP2ewOzo"
            closeVideoPlayer={closeVideoPlayer}
            isVideoOff2={isVideoOff2}
            id="2"
          />
        ) : null}

        {showVerifyPopup && (
          <AlertPopup
            status={"warning"}
            heading={"To proceed please verify your account"}
            content={"Verification link sent to your registered email address."}
            customFunction={<ResendEmailBtn />}
            updateState={updateVarificationPopupStatus}
          />
        )}
        {showLogin && (
          <AlertPopup
            status={"warning"}
            heading={"Login required to proceed"}
            content={"To gain full access please Login"}
            customFunction={<LoginCtaBtn />}
            updateState={updateLoginPopupStatus}
          />
        )}
        <div className=""></div>
        {/* <img className="leftTopImg" src={leftTopImg} alt="" /> */}
        <div className="left-top-grad"></div>
        <div className="right-top-grad"></div>
        <div className="left-bottom-grad"></div>
        {/* <div className="left-bottom-grad top-grad"></div> */}
        {/* <img className="leftBottomImg" src={leftBottomImg} alt="" /> */}

        {/* Advertisment */}
        {/* <div
        className={`advertisment-popup ${showAdv ? "show" : ""} ${
          showModal ? "disnone" : "dis"
        }`}
      >
        <div className="advt-container">
          <img
            onClick={() => history.push("/studentRegistration")}
            src={advImg}
            alt=""
          />
          <IoIosCloseCircleOutline
            onClick={closeModalPopup}
            style={{ color: "#fff" }}
          />
        </div>
      </div> */}

        <ContactUsForm />
        {isLoading === true && <Loader />}
        {popupMessage != undefined ? (
          <AlertPopup
            heading={popupMessage}
            content={
              popupMessage === "Email Sent"
                ? "Success! A Varification link has been sent to your registered email address. Please check your inbox and follow the link to verify your account."
                : null
            }
            status={"success"}
            updateState={closePopup}
          />
        ) : (
          ""
        )}
      </div>
    </DocumentMeta>
  );
};
export default Hero;
