import axios from 'axios';
import { API_URL } from '../Common/Constant';
import React, { Component } from 'react';

class LoginService {
    
    loginUser(json) {
        return axios.get(`${API_URL}/authentication/signinWeb`,{headers: json});

    }
    
}



export default new LoginService()
