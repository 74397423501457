import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import ReactApexChart from "react-apexcharts";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FaRegUser, FaQuestion } from "react-icons/fa";
import {
  MdOutlinePayment,
  MdOutlineNotifications,
  MdContentCopy,
} from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import userProfileImg from "../../assets/img/user_icon.png";
import fbLogo from "../../assets/img/facebook-logo.png";
import instaLogo from "../../assets/img/instagram-logo.png";
import tweetLogo from "../../assets/img/twitter-logo.png";
import mailLogo from "../../assets/img/mail.png";
import internetLogo from "../../assets/img/internet.png";
import { PiArrowLineUpRightBold } from "react-icons/pi";
import Carousel from "react-elastic-carousel";
import star from "../../assets/img/stars.png";
import progressDot from "../../assets/img/progress-dot.png";
import progressSuccess from "../../assets/img/progress-succes.png";
import moment from "moment";
import { Chart } from "chart.js";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { FormFeedback } from "reactstrap";
import { AiOutlineScan } from "react-icons/ai";
import { SlClose } from "react-icons/sl";
import qrSample from "../../assets/img/qr-sample.png";
import qrOutline from "../../assets/img/qr-scan-rect.svg";
import { BiQrScan } from "react-icons/bi";
import { jwtDecode } from 'jwt-decode';

import {
  CSmartTable,
  CButton,
  CCollapse,
  CCardBody,
  CAvatar,
  CBadge,
} from "@coreui/react-pro";

import refLeftGrad1 from "../../assets/img/ref-left-grad-1.png";
import refLeftGrad2 from "../../assets/img/ref-left-grad-2.png";
import refLeftGrad3 from "../../assets/img/ref-left-grad-3.png";
import refLeftGrad4 from "../../assets/img/ref-left-grad-4.png";
import refRightGrad1 from "../../assets/img/right-grad-top.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import DashboardDetailsService from "../../Services/DashboardDetailsService";
import { BiCopy } from "react-icons/bi";
import CtaBanner from "../Common/CtaBanner";
import QRCode from "qrcode.react";
import Loader from "../Alert_Popup/Loader";
import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import ContactUsForm from "../Common/ContactUsForm";
import useScrollToTop from "../custom_hooks/useScrollToTop";
import { React_URL } from "../../Common/Constant";
// import download from 'downloadjs';

let initialValues = {
  accountName: "",
  bankName: "",
  sortCode: "",
  accountNumber: "",
};

const validationSchema = function (values) {
  return Yup.object().shape({
    accountName: Yup.string()
      .matches(/^[a-zA-Z .'\\-]*$/, "Invalid Account Name format")
      .min(3, "Account Name is too short, minimum length is 3 characters")
      .max(70, "Account Name is too long, maximum length is 70 characters")
      .required("Account Name is required"),
    bankName: Yup.string()
      .matches(/^[a-zA-Z .\']*$/, "Invalid Bank Name format")
      .min(3, "Bank Name is too short, minimum length is 3 characters")
      .max(70, "Bank Name is too long, maximum length is 70 characters")
      .required("Bank Name is required"),
    sortCode: Yup.string()
      .matches(
        /^\d{2}(-|\s)\d{2}(-|\s)\d{2}$/,
        "Sort code must be a 6-digit number format XX-XX-XX"
      )
      .test("isValidSortCode", "Invalid sort code", customSortCodeValidation)
      .min(8, "Sort Code is too short, minimum length is 8 characters")
      .max(8, "Sort Code is too long, maximum length is 8 characters")
      .required("Sort code is required"),
    accountNumber: Yup.string()
      .matches(/^[0-9]{7,8}$/, "Account number should only be digits")
      .min(7, "Account Number is too short, minimum length is 7 characters")
      .max(8, "Account Number Name is too long, maximum length is 8 characters")
      .required("Account Number is required"),
  });
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const customSortCodeValidation = (value) => {
  let flag = false;
  if (/^\d{2}(-|\s)\d{2}(-|\s)\d{2}$/.test(value)) {
    const parts = value.split(/[-\s]/);
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] !== "00") {
        flag = true;
        break;
      } else {
        flag = false;
      }
    }
  }
  return flag;
};

const Dashboard = () => {
  useScrollToTop()
  const [profileActive, setProfileActive] = useState(true);
  const [paymentActive, setPaymentActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);
  const [faqActive, setFaqActive] = useState(false);
  const [websiteActive, setWebsiteActive] = useState(false);
  const [referralStatsDivWidth, setReferralStatsDivWidth] = useState(0);
  const [referralStatsDivHeight, setReferralStatsDivHeight] = useState(0);

  const [referralTrendDivWidth, setReferralTrendDivWidth] = useState(0);
  const [referralTrendDivHeight, setReferralTrendDivHeight] = useState(0);

  const referralStatsDiv = useRef(null);
  const referralTrendDiv = useRef(null);

  const [dashboardDetails, setDashboardDetails] = useState(null);

  const [copySuccess, setCopySuccess] = useState(false);

  const [notificationList, setNotificationList] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState(1);
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountInformationStatus, setAccountInformationStatus] = useState("");

  const [message, setMessage] = useState("");
  const [notificationDot, setNotificationDot] = useState(false);

  const [qrOptionActive, setQrOptionActive] = useState(false);

  const qrModalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory();

  // Pagination
  const [page, setPage] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [filterData, setFilterData] = useState();
  const n = 1;
  useEffect(() => {
    setFilterData(
      notificationList.filter((item, index) => {
        return (index >= page * n) & (index < (page + 1) * n);
      })
    );
  }, [page, notificationActive, notificationFilter, notificationList]);
  // Pagination end

  const touchAll = (setTouched, errors) => {
    setMessage("");
    setTouched({
      accountName: true,
      bankName: true,
      sortCode: true,
      accountNumber: true,
    });
    validateForm(errors);
  };

  const validateForm = (errors) => {
    findFirstError("paymentInformation", (fieldName) => {
      return Boolean(errors[fieldName]);
    });
  };

  const findFirstError = (formName, hasError) => {
    const form = document.forms[formName];
    for (let i = 0; i < form.length; i++) {
      if (hasError(form[i].name)) {
        form[i].focus();
        break;
      }
    }
  };

  const handleCopyClick = async () => {
    if (dashboardDetails !== null) {
      await navigator.clipboard.writeText(
        `${React_URL}register/${dashboardDetails.referralCode}`
      );
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 10000); // 10 seconds
    }
  };

  const [statusCode, setStatusCode] = useState();

  useEffect(() => {
    dashboardDetailsAPI();
    notificationAPI(1);
  }, [localStorage.getItem("userId")]);

  const dashboardDetailsAPI = () => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      DashboardDetailsService.dashboardDetailService(
        localStorage.getItem("userId")
      ).then((response) => {
        if (response.status === 200) {
          setStatusCode(200);
          setDashboardDetails(response.data);
          setBankName(
            response.data.bankDetails !== null
              ? response.data.bankDetails.bankName
              : ""
          );
          setAccountName(
            response.data.bankDetails !== null
              ? response.data.bankDetails.accountName
              : ""
          );
          setSortCode(
            response.data.bankDetails !== null
              ? response.data.bankDetails.sortCode
              : ""
          );
          setAccountNumber(
            response.data.bankDetails !== null
              ? response.data.bankDetails.accountNo
              : ""
          );
          initialValues = {
            accountName: response.data.bankDetails !== null
              ? response.data.bankDetails.accountName
              : "",
            bankName: response.data.bankDetails !== null
              ? response.data.bankDetails.bankName
              : "",
            sortCode: response.data.bankDetails !== null
              ? response.data.bankDetails.sortCode
              : "",
            accountNumber: response.data.bankDetails !== null
              ? response.data.bankDetails.accountNo
              : "",
          }
          setIsLoading(false)
        }
      }).catch((error) => {
        if (error.message === "Network Error") {
          setIsLoading(false);
          setMessage("Something went wrong, Please try again later");
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason);
              const message = "User is disabled"
              history.push({
                pathname: "/login",
                state: { message },
              });
              setIsLoading(false);
              setStatusCode(401);
              break;
            case 403:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              setStatusCode(403);
              break;
            case 500:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              setStatusCode(500);
              break;
            case 404:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              setStatusCode(404);
              break;
            case 406:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              setStatusCode(406);
              break;
            case 412:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              setStatusCode(412);
              break;
            default:
              setMessage("Something went wrong, Please try again later");
              setIsLoading(false);
              setStatusCode();
              break;
          }
        }
      });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const notificationAPI = (dateFlag) => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      DashboardDetailsService.notificationService(
        localStorage.getItem("userId"),
        dateFlag
      ).then((response) => {
        if (response.status === 200) {
          response.data.map((e) =>
            e.read == true ? "" : setNotificationDot(true)
          );
          setNotificationList(response.data);
        }
      });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const updateNotificationAPI = () => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      DashboardDetailsService.updateNotificationService(
        localStorage.getItem("userId")
      ).then((response) => {
        if (response.status === 200) {
          setNotificationDot(false);
        }
      });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const bankDetailsAPI = () => {
    var json = {
      bankName: bankName,
      accountName: accountName,
      accountNo: accountNumber,
      sortCode: sortCode,
    };
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const user = jwtDecode(token);
      const exp = user.exp
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      DashboardDetailsService.bankDetailsService(
        localStorage.getItem("userId"),
        json
      ).then((response) => {
        if (response.status === 200) {
          setAccountInformationStatus("Account Details Updated");
          setTimeout(() => {
            setAccountInformationStatus("");
          }, 5000);
        }
      });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const ReferralStatsTicket = ({
    title,
    description,
    value,
    valueDescription,
  }) => {
    return (
      <div
        style={{ borderRadius: "5px" }}
        className="w-100 cards-bg referral-stats-ticket d-flex flex-column justify-content-start align-items-start"
      >
        <div className="w-100 d-flex flex-column justify-content-center align-items-start">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              <h4>{title}</h4>
            </div>
            <div className="ticket-icon justify-content-center align-items-center">
              <PiArrowLineUpRightBold />
            </div>
          </div>
          <p>{description}</p>
        </div>
        <div className="mt-auto">
          <div className="d-flex justify-content-start align-items-end flex-wrap">
            <h5>
              {valueDescription == "£" ? "£" : ""}
              {value}
            </h5>
            <span>{valueDescription == "£" ? "" : valueDescription}</span>
          </div>
        </div>
        <div className="ticket-gradient-line"></div>
      </div>
    );
  };

  const LeaderBoardList = ({ name, date, time, earning }) => {
    return (
      <li className="py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <img src={userProfileImg} alt="userProfileImg" />
            <p className="ml-2">{name}</p>
          </div>
          <div>
            <p className="text-muted">{date}</p>
          </div>
          <div>
            <p className="text-muted">{time}</p>
          </div>
          <div>
            <span className="font-weight-bold">{earning}</span>
          </div>
        </div>
      </li>
    );
  };

  const CommunicationHubTicket = ({ date, description }) => {
    return (
      <div className="communication-hub-ticket w-100 d-flex justify-content-start align-items-center mb-3">
        <img src={star} alt="star" />
        <div className="cards-bg ml-2 py-2 px-3 w-100 d-flex justify-content-start align-items-center">
          <div className="col-md-9 p-0">
            <p className="m-0 p-0">{description}</p>
          </div>
          <div className="col-md-3 p-0 text-md-center text-right">
            <span style={{ fontSize: "10px" }} className="font-italic">
              {date}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const handleResize = () => {
    if (referralStatsDiv.current) {
      setReferralStatsDivWidth(referralStatsDiv.current.offsetWidth);
      setReferralStatsDivHeight(referralStatsDiv.current.offsetHeight);
    }
    if (referralTrendDiv.current) {
      setReferralTrendDivWidth(referralTrendDiv.current.offsetWidth);
      setReferralTrendDivHeight(referralTrendDiv.current.offsetHeight);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(false);
  const [stageThree, setStageThree] = useState(false);
  const [progressDotOne, setProgressDotOne] = useState(true);
  const [progressDotTwo, setProgressDotTwo] = useState(false);
  const [progressDotThree, setProgressDotThree] = useState(false);

  const [count, setCount] = useState(0);

  const handleForm = () => {
    if (count < 2) {
      setCount((prevCount) => prevCount + 1);
    }
  };
  const backStageOne = () => {
    // setUser(user); // Reset user to the original data
    // console.log("user",user)
    // console.log("user1",{ ...originalUser })

    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }

  }
  const changeNotificationFilter = (e) => {
    setNotificationFilter(e.target.value);
    notificationAPI(e.target.value);
    // if(e.target.value==1){
    setActivePage(0);
    setPage(0);
    // }else if()
  };

  const dataChange = (event, flag) => {
    if (flag == 1) {
      setAccountName(event.target.value);
    } else if (flag == 2) {
      setBankName(event.target.value);
    } else if (flag == 3) {
      setSortCode(event.target.value);
    } else if (flag == 4) {
      setAccountNumber(event.target.value);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      notificationAPI(notificationFilter);
    }, 1000 * 60 * 2);

    return () => clearInterval(interval);
  }, [notificationFilter]);

  useEffect(() => {
    if (count == 0) {
      setStageOne(true);
      setStageTwo(false);
      setStageThree(false);
      setProgressDotOne(true);
      setProgressDotTwo(false);
      setProgressDotThree(false);
    } else if (count == 1) {
      setStageOne(false);
      setStageTwo(true);
      setStageThree(false);
      setProgressDotOne(true);
      setProgressDotTwo(true);
      setProgressDotThree(false);
    } else if (count == 2) {
      setStageOne(false);
      setStageTwo(false);
      setStageThree(true);
      setProgressDotOne(true);
      setProgressDotTwo(true);
      setProgressDotThree(true);
    } else {
    }
  }, [count]);

  const RewardInfo = ({ info }) => {
    return (
      <div
        style={{ borderRadius: "10px" }}
        className="reward-info my-3 cards-bg p-2"
      >
        <div className="d-flex justtify-content-center align-items-center">
          <img src={star} alt="star" />
        </div>
        <div className="d-flex justtify-content-start align-items-center ml-2">
          <p className="m-0">{info}</p>
        </div>
      </div>
    );
  };

  const chartRef = useRef(null);

  let selectedOpt;


  useEffect(() => {
    if (profileActive == true && dashboardDetails !== null) {
      // selectedOpt = "Today"
      createChart();
    } else {
    }
  }, [profileActive, dashboardDetails]);


  const createChart = () => {
    if (!dashboardDetails || !chartRef.current) return;

    const myChartRef = chartRef.current.getContext("2d");

    // Extract data for each period
    const data = dashboardDetails.referralTrends.map((trend) => ({
      period: trend.period,
      totalSignups: trend.totalSignups,
      successfulConversions: trend.successfulConversions,
    }));

    const periods = data.map((item) => item.period);
    const totalSignups = data.map((item) => item.totalSignups);
    const successfulConversions = data.map((item) => item.successfulConversions);

    new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: periods,
        datasets: [
          {
            label: "Successful Conversion",
            backgroundColor: "#4339F2",
            data: successfulConversions,
            borderRadius: 10,
            borderColor: "red",
          },
          {
            label: "Total Sign ups",
            backgroundColor: "#34B53A",
            data: totalSignups,
            borderRadius: 10,
          },
        ],
      },
      options: {
        tooltips: {
          displayColors: true,
          mode: "x",
        },
        scales: {
          xAxes: [
            {
              stacked: false,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: false,
              borderRadius: 10,
              ticks: {
                beginAtZero: true,
              },
              type: "linear",
              borderRadius: 10,
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: "bottom" },
        borderRadius: 10,
      },
    });
  };


  // const handleSelectChange = (event) => {
  //   // setSelectedOption(event.target.value);
  //   selectedOpt= event.target.value
  //   createChart();
  //   console.log("seleOp",selectedOpt)
  // };

  // profile Table

  const userColumns = [
    {
      key: "avatar",
      label: "",
      filter: false,
      sorter: false,
    },
    {
      key: "name",
      _style: { width: "20%" },
    },
    "registered",
    {
      key: "time",
      _style: { width: "20%" },
    },
    {
      key: "price",
      _style: { width: "20%" },
    },
  ];
  const usersData = [
    {
      id: 1,
      name: "Samppa Nori",
      avatar: "1.jpg",
      registered: "2022/01/01",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 2,
      name: "Estavan Lykos",
      avatar: "2.jpg",
      registered: "2022/02/07",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 3,
      name: "Chetan Mohamed",
      avatar: "3.jpg",
      registered: "2022/02/07",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 4,
      name: "Derick Maximinus",
      avatar: "4.jpg",
      registered: "2022/03/19",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 5,
      name: "Friderik Dávid",
      avatar: "5.jpg",
      registered: "2022/01/21",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 6,
      name: "Yiorgos Avraamu",
      avatar: "6.jpg",
      registered: "2022/01/01",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 7,
      name: "Avram Tarasios",
      avatar: "7.jpg",
      registered: "2022/02/07",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 8,
      name: "Quintin Ed",
      avatar: "8.jpg",
      registered: "2022/02/07",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 9,
      name: "Enéas Kwadwo",
      avatar: "9.jpg",
      registered: "2022/03/19",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 10,
      name: "Agapetus Tadeáš",
      avatar: "10.jpg",
      registered: "2022/01/21",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 11,
      name: "Carwyn Fachtna",
      avatar: "11.jpg",
      registered: "2022/01/01",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 12,
      name: "Nehemiah Tatius",
      avatar: "12.jpg",
      registered: "2022/02/07",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 13,
      name: "Ebbe Gemariah",
      avatar: "13.jpg",
      registered: "2022/02/07",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 14,
      name: "Eustorgios Amulius",
      avatar: "14.jpg",
      registered: "2022/03/19",
      time: "02:35PM",
      price: "£130.00",
    },
    {
      id: 15,
      name: "Leopold Gáspár",
      avatar: "15.jpg",
      registered: "2022/01/21",
      time: "02:35PM",
      price: "£130.00",
    },
  ];

  // payment Table

  const paymentColumns = [
    {
      key: "Reciever",
      _style: { width: "30%" },
    },
    {
      key: "Type",
      _style: { width: "25%" },
    },
    {
      key: "Date",
      _style: { width: "20%" },
    },
    {
      key: "Price",
      _style: { width: "20%" },
    },
  ];

  const paymentData = [
    {
      id: 0,
      Reciever: "James Franco",
      Type: "Conversional",
      Date: "26 Aug 2023",
      Price: "£75.67",
    },
    {
      id: 1,
      Reciever: "James Franco",
      Type: "Conversional",
      Date: "04 Aug 2023",
      Price: "£25",
    },
    {
      id: 2,
      Reciever: "James Franco",
      Type: "Conversional",
      Date: "26 July 2023",
      Price: "£19.5",
    },
    {
      id: 3,
      Reciever: "James Franco",
      Type: "Conversional",
      Date: "06 July 2023",
      Price: "£35",
    },
    {
      id: 4,
      Reciever: "James Franco",
      Type: "Conversional",
      Date: "16 June 2023",
      Price: "£42",
    },
  ];

  const showQrOption = () => {
    document.body.style.overflowY = "hidden";
    setQrOptionActive(true);
  };

  const hideQrOption = () => {
    document.body.style.overflowY = "auto";
    setQrOptionActive(false);
  };

  const handleCoverageClick = (event) => {
    if (
      qrOptionActive &&
      qrModalRef.current &&
      !qrModalRef.current.contains(event.target)
    ) {
      hideQrOption();
    }
  };

   // google analytics

   useEffect(() => {
    document.title = "Dashboard";
    document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  useEffect(() => {
    document.addEventListener("click", handleCoverageClick);

    return () => {
      document.removeEventListener("click", handleCoverageClick);
    };
  }, [qrOptionActive]);

  const QrPopup = () => {
    const qrCodeRef = useRef(null);

    const downloadQRCode = () => {
      const canvas = document.getElementById("targetQRcode");
      const pngUrl = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `GKTeSIM-${dashboardDetails.referralCode}.jpeg`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    return (
      <div className="dropdowns-modal">
        <div
          ref={qrModalRef}
          style={{ height: "auto", top: "-70px" }}
          className="coverage-dropdown"
        >
          <div className="qr-modal flex flex-column justify-content-center align-items-center w-100">
            <div
              style={{ position: "relative", margin: "40px 0" }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              {dashboardDetails !== null &&
            <QRCode id="targetQRcode" ref={qrCodeRef} value={`${React_URL}register/${dashboardDetails.referralCode}`}  size={250} level={"H"} includeMargin={true} />
              }
              {/* <img
                ref={qrCodeRef}
                src={qrSample}
                style={{ width: "200px", height: "200px" }}
                alt="QR-code"
              /> */}
              <img className="qr-attribute" src={qrOutline} alt="" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="d-flex justify-content-center align-items-center">
                <AiOutlineScan />
                <p className="">Scan the QR code to get a link</p>
              </span>
              <button className="btn btn-light my-3" onClick={downloadQRCode}>
                Download QR
              </button>
            </div>
          </div>
          <SlClose
            onClick={hideQrOption}
            style={{ bottom: "-60px" }}
            className="modal-close-icon"
          />
        </div>
      </div>
    );
  };


  return (
    // <>
    // {statusCode !== 200 ? (
    //   <h2 className="text-center">{message}</h2>
    // ) : (
    <div className="dashboard">
      <header>
        <Header />
      </header>
      <header className="container post-navbar-dashboard w-100 d-flex justify-content-between align-items-center">
        <div
          onClick={() => {
            setProfileActive(true);
            setPaymentActive(false);
            setNotificationActive(false);
            setFaqActive(false);
            setWebsiteActive(false);
          }}
          className={`${
            profileActive ? "dashboard-option-active" : ""
          } dashboard-option d-flex flex-wrap justify-content-center align-items-center mx-md-3 mx-1`}
        >
          <div className="dashboard-icon-bg d-flex justify-content-center align-items-center">
            <FaRegUser />
          </div>
          <div className="ml-md-2 ml-0">
            <p>Profile</p>
          </div>
        </div>
        <div
          onClick={() => {
            setProfileActive(false);
            setPaymentActive(true);
            setNotificationActive(false);
            setFaqActive(false);
            setWebsiteActive(false);
          }}
          className={`${
            paymentActive ? "dashboard-option-active" : ""
          } dashboard-option d-flex flex-wrap justify-content-center align-items-center mx-md-3 mx-1`}
        >
          <div className="dashboard-icon-bg d-flex justify-content-center align-items-center">
            <MdOutlinePayment />
          </div>
          <div className="ml-md-2 ml-0">
            <p>Payment</p>
          </div>
        </div>
        <div
          onClick={() => {
            setProfileActive(false);
            setPaymentActive(false);
            setNotificationActive(true);
            setFaqActive(false);
            setWebsiteActive(false);
            updateNotificationAPI();
          }}
          className={`${
            notificationActive ? "dashboard-option-active" : ""
          } dashboard-option d-flex flex-wrap justify-content-center align-items-center mx-md-3 mx-1`}
        >
          <div className="dashboard-icon-bg d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center mb-1">
              <div style={{ position: "relative" }}>
                <MdOutlineNotifications />
                {notificationDot && (
                  <div
                    style={{ right: "-2px", top: "13px" }}
                    className="notification-dot"
                  ></div>
                )}
              </div>
            </div>
          </div>
          <div className="ml-md-2 ml-0">
            <p>Notification</p>
          </div>
        </div>
        <div
          style={{ display: "none" }}
          onClick={() => {
            setProfileActive(false);
            setPaymentActive(false);
            setNotificationActive(false);
            setFaqActive(true);
            setWebsiteActive(false);
          }}
        >
          <div className="dashboard-icon-bg d-flex justify-content-center align-items-center">
            <FaQuestion />
          </div>
          <div className="ml-md-2 ml-0">
            <p>FAQ's</p>
          </div>
        </div>
        <div
          style={{ display: "none" }}
          onClick={() => {
            setProfileActive(false);
            setPaymentActive(false);
            setNotificationActive(false);
            setFaqActive(false);
            setWebsiteActive(true);
          }}
        >
          <div className="dashboard-icon-bg d-flex justify-content-center align-items-center">
            <TbWorld />
          </div>
          <div className="ml-md-2 ml-0">
            <p>Website</p>
          </div>
        </div>
      </header>

          <section className="container referral-dashboard-section">
            <h2 className="ref-web-title">
              {profileActive
                ? "Your Referral Dashboard"
                : paymentActive
                  ? "Payments Dashboard"
                  : notificationActive
                    ? "Notifications"
                    : null}
            </h2>
            <div className="row">
              <div
                ref={referralStatsDiv}
                className="col-md-8 col-12 order-md-1 order-2"
              >
                <h2 className="ref-mob-title">Your Referral Dashboard</h2>

                <div className="cards-bg referral-stats p-3">
                  {profileActive ||
                    (paymentActive && (
                      <h3>
                        {profileActive
                          ? "Referral Statistics"
                          : paymentActive
                            ? "Payment/ Account Information"
                            : null}
                      </h3>
                    ))}
                  {/* {<p> {accountInformationStatus} </p>} */}

                  {profileActive ? (
                    <>
                      <div className="referral-stats-mob row m-0">
                        <div className="carousel-wrapper">
                          {dashboardDetails !== null && (
                            <Carousel>
                              <>
                                <div className="col-6 col-md-4 pr-2 p-0">
                                  <ReferralStatsTicket
                                    title={"Total Signups"}
                                    description={"Last updated today"}
                                    value={dashboardDetails.totalSignUps}
                                    valueDescription={"Signups"}
                                  />
                                </div>

                                <div className="col-6 col-md-4 p-0">
                                  <ReferralStatsTicket
                                    title={"Successful Conversions"}
                                    description={"Last updated today"}
                                    value={dashboardDetails.successfulConversions}
                                    valueDescription={"Conversions"}
                                  />
                                </div>
                              </>

                              <>
                                <div className="col-6 col-md-4 pr-2 p-0">
                                  <ReferralStatsTicket
                                    title={"Pending Referrals"}
                                    description={"Last updated today"}
                                    value={dashboardDetails.pendingReferrals}
                                    valueDescription={"Pending"}
                                  />
                                </div>

                                <div className="col-6 col-md-4 p-0">
                                  <ReferralStatsTicket
                                    title={"Signup Earnings "}
                                    description={"Last updated today"}
                                    value={dashboardDetails.earningsFromSignUp}
                                    valueDescription={"£"}
                                  />
                                </div>
                              </>
                              <>
                                <div className="col-6 col-md-4 pr-2 p-0">
                                  <ReferralStatsTicket
                                    title={"Conversion Earnings "}
                                    description={"Last updated today"}
                                    value={dashboardDetails.earningsFromConversion}
                                    valueDescription={"£"}
                                  />
                                </div>

                                <div className="col-6 col-md-4 p-0">
                                  <ReferralStatsTicket
                                    title={"Total Earnings"}
                                    description={"Last updated today"}
                                    value={dashboardDetails.totalEarnings}
                                    valueDescription={"£"}
                                  />
                                </div>
                              </>
                            </Carousel>
                          )}
                        </div>
                      </div>
                      {dashboardDetails !== null && (
                        <div className="referral-stats-web row m-0 ">
                          <div className="col-12 col-md-4">
                            <ReferralStatsTicket
                              title={"Total Signups"}
                              description={"Last updated today"}
                              value={dashboardDetails.totalSignUps}
                              valueDescription={"Signups"}
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <ReferralStatsTicket
                              title={"Successful Conversions"}
                              description={"Last updated today"}
                              value={dashboardDetails.successfulConversions}
                              valueDescription={"Conversions"}
                            />
                          </div>

                          <div className="col-12 col-md-4 ">
                            <ReferralStatsTicket
                              title={"Pending Referrals"}
                              description={"Last updated today"}
                              value={dashboardDetails.pendingReferrals}
                              valueDescription={"Pending"}
                            />
                          </div>
                        </div>
                      )}
                      {dashboardDetails !== null && (
                        <div className="referral-stats-web row m-0 mt-3">
                          <div className="col-12 col-md-4 ">
                            <ReferralStatsTicket
                              title={"Signup Earnings "}
                              description={"Last updated today"}
                              value={dashboardDetails.earningsFromSignUp}
                              valueDescription={"£"}
                            />
                          </div>

                          <div className="col-12 col-md-4 ">
                            <ReferralStatsTicket
                              title={"Conversion Earnings "}
                              description={"Last updated today"}
                              value={dashboardDetails.earningsFromConversion}
                              valueDescription={"£"}
                            />
                          </div>

                          <div className="col-12 col-md-4 ">
                            <ReferralStatsTicket
                              title={"Total Earnings"}
                              description={"Last updated today"}
                              value={dashboardDetails.totalEarnings}
                              valueDescription={"£"}
                            />
                          </div>
                        </div>
                      )}
                    </>

                  ) : paymentActive ? (
                    <>
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={validate(validationSchema)}
                        validateOnChange={false}
                        validateOnBlur={false}
                        // onSubmit={registerClicked}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                          bankDetailsAPI();
                        }}
                        render={({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          setTouched,
                          handleReset,
                        }) => (
                          <form
                            name="paymentInformation"
                            className="referral-form"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                            noValidate
                            autoComplete="off"
                          >
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label for="inputEmail4">Account Name*</label>
                                <input
                                  name="accountName"
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    dataChange(e, 1);
                                  }}
                                  value={accountName}
                                  onBlur={handleBlur}
                                  id="accountName"
                                  valid={!errors.accountName}
                                  invalid={
                                    touched.accountName && !!errors.accountName
                                  }
                                  placeholder="Please enter"
                                  className={`form-control border-0 shadow-sm custom-input
                            ${errors.accountName === "" ||
                                      errors.accountName === undefined
                                      ? ""
                                      : "input-error"
                                    }`}
                                />
                                <FormFeedback
                                  style={{
                                    display:
                                      errors.accountName === "" ||
                                        errors.accountName === undefined
                                        ? "none"
                                        : "block",
                                  }}
                                  className="red"
                                >
                                  {errors.accountName}
                                </FormFeedback>
                              </div>
                              <div className="form-group col-md-6">
                                <label for="inputPassword4">Bank Name*</label>
                                <input
                                  name="bankName"
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    dataChange(e, 2);
                                  }}
                                  value={bankName}
                                  onBlur={handleBlur}
                                  id="bankName"
                                  valid={!errors.bankName}
                                  invalid={touched.bankName && !!errors.bankName}
                                  placeholder="Please enter"
                                  className={`form-control border-0 shadow-sm custom-input
                            ${errors.bankName === "" ||
                                      errors.bankName === undefined
                                      ? ""
                                      : "input-error"
                                    }`}
                                />
                                <FormFeedback
                                  style={{
                                    display:
                                      errors.bankName === "" ||
                                        errors.bankName === undefined
                                        ? "none"
                                        : "block",
                                  }}
                                  className="red"
                                >
                                  {errors.bankName}
                                </FormFeedback>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label for="inputEmail4">Sort Code*</label>
                                <input
                                  name="sortCode"
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    dataChange(e, 3);
                                  }}
                                  value={sortCode}
                                  onBlur={handleBlur}
                                  id="sortCode"
                                  valid={!errors.sortCode}
                                  invalid={touched.sortCode && !!errors.sortCode}
                                  placeholder="Please enter XX-XX-XX"
                                  className={`form-control border-0 shadow-sm custom-input
                            ${errors.sortCode === "" ||
                                      errors.sortCode === undefined
                                      ? ""
                                      : "input-error"
                                    }`}
                                />
                                <FormFeedback
                                  style={{
                                    display:
                                      errors.sortCode === "" ||
                                        errors.sortCode === undefined
                                        ? "none"
                                        : "block",
                                  }}
                                  className="red"
                                >
                                  {errors.sortCode}
                                </FormFeedback>
                              </div>
                              <div className="form-group col-md-6">
                                <label for="inputPassword4">A/C Number*</label>
                                <input
                                  name="accountNumber"
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    dataChange(e, 4);
                                  }}
                                  value={accountNumber}
                                  onBlur={handleBlur}
                                  id="accountNumber"
                                  valid={!errors.accountNumber}
                                  invalid={
                                    touched.accountNumber && !!errors.accountNumber
                                  }
                                  placeholder="Please enter"
                                  className={`form-control border-0 shadow-sm custom-input
                            ${errors.accountNumber === "" ||
                                      errors.accountNumber === undefined
                                      ? ""
                                      : "input-error"
                                    }`}
                                />
                                <FormFeedback
                                  style={{
                                    display:
                                      errors.accountNumber === "" ||
                                        errors.accountNumber === undefined
                                        ? "none"
                                        : "block",
                                  }}
                                  className="red"
                                >
                                  {errors.accountNumber}
                                </FormFeedback>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12 d-flex justify-content-center align-items-center m-0">
                                <button
                                  onClick={() => touchAll(setTouched, errors)}
                                  type="submit"
                                  className="w-50 rounded-pill btn btn-custom-gradient text-uppercase shadow-sm"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                            <FormFeedback
                              style={{
                                display:
                                  accountInformationStatus === "" ||
                                    accountInformationStatus === undefined
                                    ? "none"
                                    : "block",
                                fontSize: '15px',
                                lineHeight: '1'
                              }}
                              className="green text-center font-weight-medium mb-0 mt-3"
                            >
                              {accountInformationStatus}
                            </FormFeedback>
                          </form>
                        )}
                      />
                    </>
                  ) : notificationActive ? (
                    <>
                      <div className="communication-hub-section">
                        <div className="row m-0 mb-3 w-100 d-flex justify-content-end align-items-center">
                          <select
                            className="form-select"
                            onChange={changeNotificationFilter}
                            aria-label="Default select example"
                          >
                            <option value="1" selected>
                              last 7 days
                            </option>
                            <option value="2">last 15 days</option>
                            <option value="3">last 30 days</option>
                            <option value="0">overall</option>
                          </select>
                        </div>
                        <div className="communication-mob-ticket">
                          <div className="col-12 p-0 communication-mob-ticket-section">
                            {/* <Carousel> */}
                            {filterData.map((e) => (
                              <CommunicationHubTicket
                                date={moment(
                                  e.createdDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("DD MMM, YYYY hh:mm")}
                                description={
                                  e.notificationType +
                                  ": " +
                                  e.referredUserName +
                                  (e.notificationType == "New Referral"
                                    ? " signed up."
                                    : "'s successful purchase plan.")
                                }
                              />
                            ))}
                            {/* </Carousel> */}
                            <ReactPaginate
                              showSizeChanger={true}
                              containerClassName={"pagination"}
                              activeClassName={"paginate-active"}
                              pageClassName={"page-item"}
                              onPageChange={(event) => {
                                setPage(event.selected)
                                setActivePage(event.selected);
                              }}
                              forcePage={activePage}
                              breakLabel="..."
                              pageCount={Math.ceil(notificationList.length / n)}
                              previousLabel={
                                <IconContext.Provider
                                  value={{ color: "rgb(118, 118, 118)", size: "28px" }}
                                >
                                  <AiFillLeftCircle />
                                </IconContext.Provider>
                              }
                              nextLabel={
                                <IconContext.Provider
                                  value={{ color: "rgb(118, 118, 118)", size: "28px" }}
                                >
                                  <AiFillRightCircle />
                                </IconContext.Provider>
                              }
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={1}
                            />
                          </div>
                        </div>
                        <div className="communication-web-ticket row mb-3 m-0 w-100">
                          <h2>{notificationList == 0 ? "No Data" : ""}</h2>
                          {notificationList.map((e) => (
                            <div className="col-12 col-md-12 d-flex justify-content-start w-100 mb-2 align-items-center p-0">
                              <CommunicationHubTicket
                                date={moment(
                                  e.createdDate,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("DD MMM, YYYY hh:mm")}
                                description={
                                  e.notificationType +
                                  ": " +
                                  e.referredUserName +
                                  (e.notificationType == "New Referral"
                                    ? " signed up."
                                    : "'s successful purchase plan.")
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4 col-12 order-md-2 order-1">
                <div
                  style={{ height: "100%" }}
                  className="cards-bg referral-profile d-flex flex-column justify-content-around align-items-center p-3"
                >
                  <div className="mb-2">
                    <img src={userProfileImg} alt="userProfileImg" />
                  </div>
                  {dashboardDetails !== null && dashboardDetails.affiliateUser && (
                    <div className="mb-2">
                      <h4>Hello {dashboardDetails.affiliateUser.fullName}</h4>
                    </div>
                  )}
                  {/* <p
                style={{ fontSize: "12px", fontWeight: "600" }}
                className="mb-2"
              >
                Copy the link or Scan the QR code
                <BiQrScan style={{ fontSize: "18px" }} className="ml-1" />
              </p> */}
              {dashboardDetails !== null && (
                <div className="ref-pop-div">
                  <div
                    style={{ borderRadius: "10px", position: "relative" }}
                    className="referral-input w-100 d-flex align-items-center cards-bg"
                  >
                    <div className="w-100">
                      <input
                        value={`${React_URL}register/${dashboardDetails.referralCode}`}
                        title={`${React_URL}register/${dashboardDetails.referralCode}`}
                        readOnly
                        className=""
                        type="text"
                      />
                    </div>
                    <div className="referral-copy-icon d-flex justify-content-center align-items-center">
                      <BiCopy
                        title="Copy"
                        style={{ fontSize: "25px" }}
                        onClick={handleCopyClick}
                      />
                    </div>
                  </div>
                  <div onClick={showQrOption} className="cards-bg qr-btn">
                    <p>QR</p>
                  </div>

                      {copySuccess && <span>Copied to clipboard!</span>}
                      {/* <span>Copied to clipboard!</span> */}
                    </div>
                  )}
                  <div className="dashboard-social-icons d-flex flex-wrap justify-content-start align-items-center mt-2">
                    <label className="font-weight-bold m-0 mr-3">Share via</label>
                    <div>
                      <a target="_blank" href="https://www.facebook.com">
                        <img className="mx-2" src={fbLogo} alt="fbLogo" />
                      </a>
                      <a target="_blank" href="https://www.instagram.com/">
                        <img className="mx-2" src={instaLogo} alt="instaLogo" />
                      </a>
                      <a target="_blank" href="https://twitter.com">
                        <img className="mx-2" src={tweetLogo} alt="tweetLogo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {profileActive &&
              <div className="mt-4">
                <p className="text-danger">
                  *The signup amount is redeemable only when there is at least one purchase associated with that signup
                </p>
              </div>}
          </section>

          <section className="referral-stats-section container">
            <div className="row">
              <div
                ref={referralTrendDiv}
                style={{ marginBottom: "20px" }}
                className="col-12 col-md-5 "
              >
                <div
                  style={{
                    position: "relative",
                    display: profileActive ? "block" : "none"
                    // profileActive || paymentActive || notificationActive
                    //   ? "none"
                    //   : "",
                  }}
                  className="cards-bg p-3"
                >
                  <div
                    style={{ width: "10px", borderRadius: "25px 0 0 25px" }}
                    className="ticket-gradient-line"
                  ></div>
                  {profileActive ? (
                    <div className="profile-cont">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 w-100">
                        <p className="mb-1">Referral Trends</p>

                        {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedOpt}
                      onChange={handleSelectChange}
                    >
                    {dashboardDetails &&
            dashboardDetails.referralTrends.map((trend) => (
              <option key={trend.period} value={trend.period}>
                {trend.period}
              </option>
            ))}
                    </select> */}
                      </div>

                      <div className="profile-chart-container">
                        <canvas height={280} ref={chartRef} id="myChart4"></canvas>
                      </div>
                    </div>
                  ) : paymentActive ? (
                    <div className="payment-cont">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="font-weight-bold">Payout Threshold</p>
                          <p>Last updated today</p>
                        </div>
                        <div className="d-flex justify-content-center align-items-end">
                          <span>50</span>
                          <p className="m-0 mb-3">Signups</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="font-weight-bold">Payout Clain</p>
                          <p>Last updated today</p>
                        </div>
                        <div className="d-flex justify-content-center align-items-end">
                          <span>30</span>
                          <p className="m-0 mb-3">£</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-custom-gradient btn-block rounded-pill text-uppercase shadow-sm">
                          Claim Now
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {paymentActive && <p>Payment redeem option coming soon.</p>}
              <div
                style={{
                  marginBottom: "20px",
                  display:
                    profileActive || paymentActive || notificationActive
                      ? "none"
                      : "",
                }}
                className="col-12 col-md-7"
              >
                <div className="w-100 cards-bg p-3">
                  <div className="d-flex justify-content-between align-items-center flex-wrap m-0 w-100">
                    <p className="m-0">
                      {profileActive ? "Leader's Board" : "Payout History"}
                    </p>
                  </div>

                  <div className="leader-board">
                    {profileActive ? (
                      <div className="profile-table">
                        <CSmartTable
                          activePage={1}
                          // clickableRows
                          columns={userColumns}
                          // columnFilter
                          // columnSorter
                          items={usersData}
                          // itemsPerPageSelect
                          itemsPerPage={5}
                          pagination
                          scopedColumns={{
                            avatar: (item) => (
                              <td>
                                <CAvatar src={`/img/avatars/${item.avatar}`} />
                              </td>
                            ),
                          }}
                          // selectable
                          sorterValue={{ column: "status", state: "asc" }}
                          tableProps={{
                            className: "add-this-class",
                            responsive: true,
                            striped: true,
                            hover: true,
                          }}
                          tableBodyProps={
                            {
                              // className: "align-middle",
                            }
                          }
                        />
                      </div>
                    ) : (
                      <div className="payment-table">
                        <CSmartTable
                          activePage={1}
                          clickableRows
                          columns={paymentColumns}
                          items={paymentData}
                          pagination
                          selectable
                          sorterValue={{ column: "status", state: "asc" }}
                          tableProps={{
                            className: "add-this-class",
                            responsive: true,
                            striped: true,
                            hover: true,
                          }}
                          tableBodyProps={{
                            className: "align-middle",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            style={{
              display: profileActive ? "none" : "none",
              marginBottom: "20px",
            }}
            className="communication-hub-section container"
          >
            <div className="row m-0">
              <div className="col-12 w-100 cards-bg p-3">
                <div className="row m-0 mb-3 w-100 d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-center align-items-center mb-1">
                    <div style={{ position: "relative" }}>
                      <MdOutlineNotifications />
                      <div className="notification-dot"></div>
                    </div>
                    <p
                      style={{ fontWeight: "600", fontSize: "16px" }}
                      className="m-0 ml-2"
                    >
                      Communication Hub
                    </p>
                  </div>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>This week</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="communication-mob-ticket">
                  <div className="col-12">
                    <Carousel>
                      <CommunicationHubTicket
                        date={"20/08/24"}
                        description={"New Referral: Jane Smith signed up."}
                      />
                      <CommunicationHubTicket
                        date={"19/08/24"}
                        description={"New Referral: Joan Cole signed up."}
                      />
                      <CommunicationHubTicket
                        date={"19/08/24"}
                        description={
                          "Conversion: Jane Smith's successful purchase plan."
                        }
                      />
                      <CommunicationHubTicket
                        date={"18/08/24"}
                        description={
                          "Conversion: Robert Jone’s successful purchase plan."
                        }
                      />
                    </Carousel>
                  </div>
                </div>
                <div className="communication-web-ticket row mb-3 m-0 w-100">
                  <div className="col-12 col-md-6 d-flex justify-content-start w-100 align-items-center">
                    <CommunicationHubTicket
                      date={"20/08/24"}
                      description={"New Referral: Jane Smith signed up."}
                    />
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-start w-100 align-items-center">
                    <CommunicationHubTicket
                      date={"19/08/24"}
                      description={"New Referral: Joan Cole signed up."}
                    />
                  </div>
                </div>
                <div className="communication-web-ticket row mb-3 m-0 w-100">
                  <div className="col-12 col-md-6 d-flex justify-content-start w-100 align-items-center">
                    <CommunicationHubTicket
                      date={"19/08/24"}
                      description={
                        "Conversion: Jane Smith's successful purchase plan."
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-start w-100 align-items-center">
                    <CommunicationHubTicket
                      date={"18/08/24"}
                      description={
                        "Conversion: Robert Jone’s successful purchase plan."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {dashboardDetails !== null && dashboardDetails.affiliateUser && (
            <section
              style={{ display: profileActive ? "block" : "none" }}
              className="referral-reward-section"
            >
              <div className="container">
                <div className="referral-progress-bar">
                  <div
                    // onClick={() => {
                    //   setStageOne(true);
                    //   setStageTwo(false);
                    //   setStageThree(false);
                    // }}
                    style={{ position: "relative" }}
                  >
                    {progressDotOne ? (
                      <img src={progressSuccess} alt="progressSuccess" />
                    ) : (
                      <img src={progressDot} alt="progressDot" />
                    )}
                    {stageOne ? (
                      <div className="stage-one-msg">Personal</div>
                    ) : null}
                  </div>
                  <div
                    className={`progress-line progress-line-1 ${progressDotTwo ? "progress-line-success" : ""
                      }`}
                  ></div>
                  <div
                    // onClick={() => {
                    //   setStageOne(false);
                    //   setStageTwo(true);
                    //   setStageThree(false);
                    // }}
                    style={{ position: "relative" }}
                  >
                    {progressDotTwo ? (
                      <img src={progressSuccess} alt="progressSuccess" />
                    ) : (
                      <img src={progressDot} alt="progressDot" />
                    )}
                    {stageTwo ? <div className="stage-two-msg">Social</div> : null}
                  </div>
                  <div
                    className={`progress-line progress-line-2 ${progressDotThree ? "progress-line-success" : ""
                      }`}
                  ></div>
                  <div
                    // onClick={() => {
                    //   setStageOne(false);
                    //   setStageTwo(false);
                    //   setStageThree(true);
                    // }}
                    style={{ position: "relative" }}
                  >
                    {progressDotThree ? (
                      <img src={progressSuccess} alt="progressSuccess" />
                    ) : (
                      <img src={progressDot} alt="progressDot" />
                    )}
                    {stageThree ? (
                      <div className="stage-three-msg">Address</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="referral-form mt-4">
                {stageOne ? (
                  <div className="form-stage-one">
                    <div className="container">
                      <h3>Personal Information</h3>
                    </div>
                    <div className="cards-bg p-3 container">
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">
                              Affiliate ID / Unique Referral Code
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail4"
                              placeholder={`GKT-${dashboardDetails.referralCode}`}
                              value={dashboardDetails.referralCode}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6 d-flex justify-content-center align-items-center m-0">
                            <div className="d-flex justify-content-start align-items-center">
                              <div>
                                <label
                                  className="font-weight-bold m-0 mr-3"
                                  for="share"
                                >
                                  Share via
                                </label>
                              </div>
                              <div className="social-share-icons d-flex justify-content-center align-items-center">
                                <a target="_blank" href="https://www.facebook.com">
                                  <img className="mx-3" src={fbLogo} alt="fbLogo" />
                                </a>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/"
                                >
                                  <img
                                    className="mx-3"
                                    src={instaLogo}
                                    alt="instaLogo"
                                  />
                                </a>
                                <a target="_blank" href="https://twitter.com">
                                  <img
                                    className="mx-3"
                                    src={tweetLogo}
                                    alt="tweetLogo"
                                  />
                                </a>
                                {/* <img className="mx-3" src={mailLogo} alt="mailLogo" />
                          <img
                            className="mx-3"
                            src={internetLogo}
                            alt="internetLogo"
                          />  */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Full Name*</label>
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail4"
                              placeholder={dashboardDetails.affiliateUser.fullName}
                              value={dashboardDetails.affiliateUser.fullName}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputPassword4">Email Id*</label>
                            <input
                              type="email"
                              className="form-control"
                              id="inputPassword4"
                              placeholder={dashboardDetails.affiliateUser.emailId}
                              value={dashboardDetails.affiliateUser.emailId}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6 form-contact-input">
                            <label for="inputEmail4">Contact No*</label>{" "}
                            <div className="d-flex justify-content-center align-items-center">
                              <input
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder={
                                  dashboardDetails.affiliateUser.contactNo
                                }
                                value={dashboardDetails.affiliateUser.contactNo}
                                readOnly
                              />
                              {/* <button className="btn btn-success rounded-pill">
                          Verify
                        </button> */}
                            </div>
                          </div>
                          {/* <div className="form-group col-md-6">
                      <label for="inputPassword4">OTP Verification*</label>
                      <input
                        type="password"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Enter your OTP"
                      />
                    </div> */}
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Date of Birthday*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              placeholder={
                                dashboardDetails.affiliateUser.dateOfBirth
                              }
                              value={dashboardDetails.affiliateUser.dateOfBirth}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputPassword4">University*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword4"
                              placeholder={
                                dashboardDetails.affiliateUser.university
                              }
                              value={dashboardDetails.affiliateUser.university}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-cta my-3 container">
                          <button
                            onClick={(e) => {
                              handleForm(e);
                            }}
                            className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2"
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : null}

                {stageTwo ? (
                  <div className="form-stage-one">
                    <div className="container">
                      <h3>
                        Social Media
                        {/* <span>(Add any 2 links)</span> */}
                      </h3>
                    </div>
                    <div className="cards-bg p-3 container">
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Instagram Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Please enter the link"
                              value={dashboardDetails.affiliateUser.instaLink}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputPassword4">Facebook Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword4"
                              value={dashboardDetails.affiliateUser.fbLink}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Youtube Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={dashboardDetails.affiliateUser.youtubeLink}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputPassword4">Twitter Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword4"
                              value={dashboardDetails.affiliateUser.twitterLink}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Website Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={dashboardDetails.affiliateUser.websiteLink}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputPassword4">Blog Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword4"
                              value={dashboardDetails.affiliateUser.blogLink}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Tiktok Link</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={dashboardDetails.affiliateUser.tiktokLink}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6"></div>
                        </div>
                        <div className="form-cta my-3 container">
                          <button onClick={backStageOne} className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2">
                            Back
                          </button>
                          <button
                            onClick={(e) => {
                              handleForm(e);
                            }}
                            className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2"
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : null}

                {stageThree ? (
                  <div className="form-stage-one">
                    <div className="container">
                      <h3>Your Address</h3>
                    </div>
                    <div className="cards-bg p-3 container">
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label for="inputEmail4">Door / Flat no*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={dashboardDetails.affiliateUser.flatNo}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-8">
                            <label for="inputPassword4">Address 1*</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword4"
                              value={dashboardDetails.affiliateUser.address1}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="inputEmail4">Address 2*</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            value={dashboardDetails.affiliateUser.address2}
                            readOnly
                          />
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">City *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={dashboardDetails.affiliateUser.city}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputPassword4">State</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword4"
                              value={dashboardDetails.affiliateUser.state}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label for="inputEmail4">Zipcode/Postcode *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={dashboardDetails.affiliateUser.pinCode}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label for="inputState">Country *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              value={
                                dashboardDetails.affiliateUser.countryId
                                  .countryDescription
                              }
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-cta my-3 container">
                          <button onClick={backStageOne} className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2">
                            Back
                          </button>

                        </div>
                      </form>
                    </div>
                  </div>
                ) : null}

                {/* <div className="form-cta my-3 container">
              <button
                onClick={handleForm}
                className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase"
              >
                Next
              </button>
            </div> */}
              </div>
            </section>
          )}

          <CtaBanner />

          <img className="ref-left-grad-1" src={refLeftGrad1} alt="refLeftGrad1" />
          <img className="ref-left-grad-2" src={refLeftGrad2} alt="refLeftGrad2" />
          {/* <img className="ref-left-grad-3" src={refLeftGrad3} alt="refLeftGrad3" /> */}
          {/* <img className="ref-left-grad-4" src={refLeftGrad4} alt="refLeftGrad4" /> */}

          {/* <img className="ref-right-grad-1"src={refRightGrad1} alt="refRightGrad1"/> */}
          <img
            className="ref-right-grad-3"
            src={refRightGrad3}
            alt="refRightGrad1"
          />

          {qrOptionActive ? <QrPopup /> : null}
          {isLoading === true && <Loader />}

      <ContactUsForm/>
      <footer>
        <Footer />
      </footer>
    </div>
    //  )}
    //  </>   
  );
};

export default Dashboard;
