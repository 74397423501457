import axios from "axios";
import { API_URL } from "../Common/Constant";
class EsimDetailsService {


    esimDetail(esimId,planId) {
        return axios.get(`${API_URL}/webappapi/esim`,{headers:{"esimId":esimId, "planId":planId, 'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
    
}
export default new EsimDetailsService();
