import axios from 'axios';
import { API_URL } from '../Common/Constant';
class ReferralModuleService {

    verifyContact(varification) {
        return axios.get(`${API_URL}/referralApi/verifyContactNo`,{headers:{varification,  'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
    }
    addAfiliateUser(userId,json) {
        
        return axios.post(`${API_URL}/referralApi/addAffiliateUser`,json,{headers:{"userId":userId, 'Authorization':`Bearer ${localStorage.getItem('token')}`}},{
        });
    }
    
}
export default new ReferralModuleService()