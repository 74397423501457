import axios from 'axios';
import { API_URL } from '../Common/Constant';
class RegionListService {

    regionList() {
        return axios.get(`${API_URL}/webappapi/regionList`, {
        });
    }
    countryAccordingToRegionList() {
        return axios.get(`${API_URL}/webappapi/countryListWithRegionAndSubRegion`, {
        });
    }

    countryList() {
        return axios.get(`${API_URL}/webappapi/country`, {
        });
    }
}
export default new RegionListService()