import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import { FiInfo, FiArrowRight } from "react-icons/fi";
import star from "../../assets/img/stars.png";
import Carousel from "react-elastic-carousel";
import Footer from "../Footer/Footer";
import rocket from "../../assets/img/rocket.png";
import progressDot from "../../assets/img/progress-dot.png";
import progressSuccess from "../../assets/img/progress-succes.png";
import fbLogo from "../../assets/img/facebook-logo.png";
import instaLogo from "../../assets/img/instagram-logo.png";
import tweetLogo from "../../assets/img/twitter-logo.png";
import mailLogo from "../../assets/img/mail.png";
import internetLogo from "../../assets/img/internet.png";
import refLeftGrad1 from "../../assets/img/ref-left-grad-1.png";
import refLeftGrad2 from "../../assets/img/ref-left-grad-2.png";
import refLeftGrad3 from "../../assets/img/ref-left-grad-3.png";
import refLeftGrad4 from "../../assets/img/ref-left-grad-4.png";
import refRightGrad1 from "../../assets/img/right-grad-top.png";
import refRightGrad3 from "../../assets/img/ref-right-grad-3.png";
import ReferralModuleService from "../../Services/ReferralModuleService";
import AuthenticationService from "../Common/AuthenticationService";
import PopularDestinationServices from "../../Services/PopularDestinationServices";
import RegionService from "../../Services/RegionService";
import { useHistory } from "react-router-dom";
import CityService from "../../Services/CityService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { BiCalendar } from "react-icons/bi";
import ContactUsForm from "../Common/ContactUsForm";
import UserVarificationService from "../../Services/UserVarificationService";
import DashboardDetailsService from "../../Services/DashboardDetailsService";
import AlertPopup from "../Alert_Popup/AlertPopup";
import { jwtDecode } from "jwt-decode";
import useScrollToTop from "../custom_hooks/useScrollToTop";
import DocumentMeta from "react-document-meta";

const ReferralModule = () => {
  useScrollToTop();
  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(false);
  const [stageThree, setStageThree] = useState(false);
  const [progressDotOne, setProgressDotOne] = useState(true);
  const [progressDotTwo, setProgressDotTwo] = useState(false);
  const [progressDotThree, setProgressDotThree] = useState(false);
  const [popuplarDestination, setPopularDestination] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();
  const [selectedCountryId, setSelectedCountryId] = useState(0);

  const [verification, setVerification] = useState({
    contactNo: "",
    userId: "",
  });
  const [user, setUser] = useState({
    affiliateUserId: 0,
    fullName: "",
    emailId: localStorage.getItem("emailId"),
    contactNo: "",
    dateOfBirth: "",
    university: "",
    flatNo: "",
    address1: "",
    address2: "",
    state: "",
    // city: {
    //   cityId: 0,
    //   cityDescription: "",
    countryId: {
      countryId: 0,
    },
    // },
    city: "",
    pinCode: "",
    instaLink: "",
    fbLink: "",
    youtubeLink: "",
    twitterLink: "",
    websiteLink: "",
    blogLink: "",
    tiktokLink: "",
    createdBy: {
      accountNonExpired: true,
      accountNonLocked: true,
      credentialsNonExpired: true,
      enabled: true,
    },
  });
  const [originalUser, setOriginalUser] = useState({ ...user }); // Make a copy of the initial user object

  // const [startDate, setStartDate] = useState(null);

  const handleDateChange = (date) => {
    // setStartDate(date)
    const fDate = moment(date).format("YYYY-MM-DD");
    setUser((prevUser) => ({
      ...prevUser,
      dateOfBirth: fDate,
    }));
  };
  const [enteredOTP, setEnteredOTP] = useState("");
  const [responseOTP, setResponseOTP] = useState("");
  const dataChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
      countryId: { ...prevUser.countryId, countryId: selectedCountryId }, // Update countryId
    }));
  };
  const [formError, setFormError] = useState(false);
  const [formErrorLink, setFormErrorLink] = useState({});

  const [count, setCount] = useState(0);
  const [linkMessage, setLinkMessage] = useState();

  const [fieldErrors, setFieldErrors] = useState({
    instaLink: "",
    fbLink: "",
    youtubeLink: "",
    twitterLink: "",
    websiteLink: "",
    blogLink: "",
    tiktokLink: "",
  });

  // const handleForm = (e) => {
  //   e.preventDefault();
  //   const inputFields = [
  //     { name: "instaLink", value: user.instaLink },
  //     { name: "fbLink", value: user.fbLink },
  //     { name: "youtubeLink", value: user.youtubeLink },
  //     { name: "twitterLink", value: user.twitterLink },
  //     { name: "websiteLink", value: user.websiteLink },
  //     { name: "blogLink", value: user.blogLink },
  //     { name: "tiktokLink", value: user.tiktokLink },
  //   ];

  //   const initiallyFilledFields = inputFields.filter(
  //     (field) => field.value.trim() !== ""
  //   );

  //   if (initiallyFilledFields.length >= 0) {
  //     setLinkMessage("");
  //     const validLinkFormats = {
  //       instaLink: /^https?:\/\/(www\.)?instagram\.com\/[A-Za-z0-9_]+/,
  //       fbLink: /^https?:\/\/(www\.)?facebook\.com\/[A-Za-z0-9_]+/,
  //       youtubeLink: /^https?:\/\/(www\.)?youtube\.com\/[A-Za-z0-9_]+/,
  //       twitterLink: /^https?:\/\/(www\.)?twitter\.com\/[A-Za-z0-9_]+/,
  //       websiteLink: /^https?:\/\/(www\.)?[A-Za-z0-9_]+\.[A-Za-z0-9]+/,
  //       blogLink: /^https?:\/\/(www\.)?[A-Za-z0-9_]+\.[A-Za-z0-9]+/,
  //       tiktokLink: /^https?:\/\/(www\.)?tiktok\.com\/[A-Za-z0-9_]+/,
  //     };

  //     const fieldErrorsCopy = { ...fieldErrors }; // Make a copy of fieldErrors

  //     initiallyFilledFields.forEach((field) => {
  //       const fieldName = Object.keys(validLinkFormats).find((key) =>
  //         validLinkFormats[key].test(field.value)
  //       );

  //       if (!fieldName) {
  //         fieldErrorsCopy[field.name] = "Invalid Link Format";
  //       } else {
  //         fieldErrorsCopy[field.name] = ""; // Clear the error message for this field
  //       }
  //     });

  //     // Clear errors for empty fields
  //     inputFields.forEach((field) => {
  //       if (field.value.trim() === "") {
  //         fieldErrorsCopy[field.name] = "";
  //       }
  //     });

  //     const hasErrors = Object.values(fieldErrorsCopy).some(
  //       (error) => error !== ""
  //     );

  //     if (!hasErrors) {
  //       setCount((prevCount) => prevCount + 1);
  //       setFormError(false);
  //     } else {
  //       setFormError(true);
  //     }

  //     setFieldErrors(fieldErrorsCopy); // Update the fieldErrors state
  //   }
  //   // else if (initiallyFilledFields.length === 1) {
  //   //   setFormError(true);
  //   //   setLinkMessage("Please enter at least two links");
  //   //   const fieldErrorsCopy = { ...fieldErrors }; // Make a copy of fieldErrors
  //   //   initiallyFilledFields.forEach((field) => {
  //   //     if (field.value.trim() !== "") {
  //   //       fieldErrorsCopy[field.name] = "Invalid Link Format";
  //   //     } else {
  //   //       fieldErrorsCopy[field.name] = ""; // Clear the error message for this field if it's empty
  //   //     }
  //   //   });
  //   //   setFieldErrors(fieldErrorsCopy);
  //   // } else {
  //   //   setFormError(true);
  //   //   setLinkMessage("Please enter at least two links");
  //   //   const fieldErrorsCopy = { ...fieldErrors }; // Make a copy of fieldErrors
  //   //   inputFields.forEach((field) => {
  //   //     fieldErrorsCopy[field.name] = ""; // Clear the error message for this field
  //   //   });
  //   //   setFieldErrors(fieldErrorsCopy);
  //   // }
  // };

  const handleForm = (e) => {
    e.preventDefault();

    // Define regular expressions for each social media platform
    const instagramRegex =
      /^(https?:\/\/)?(www\.)?instagram\.com(\/[\w-]+(\/[^\s?]+)?(\?.*)?)?$/;
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com(\/\S*)?$/;
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)(\/\S*)?$/;
    const twitterRegex =
      /^(https?:\/\/)?(www\.)?(mobile\.)?twitter\.com(\/[a-zA-Z0-9_]+)?(\/\S*)?(\?.*)?$/;
    const websiteRegex =
      /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/;
    const blogRegex =
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    const tiktokRegex =
      /^(https?:\/\/)?(www\.)?tiktok\.com(\/(@[\w.-]+\/video\/\d+))?(\?.*)?$/;

    // Create a formData object to store field values
    const formData = {
      instaLink: document.getElementById("inputEmail4_insta").value,
      fbLink: document.getElementById("inputPassword4_fbLink").value,
      youtubeLink: document.getElementById("inputEmail4_youtube").value,
      twitterLink: document.getElementById("inputPassword4_twitter").value,
      websiteLink: document.getElementById("inputEmail4_website").value,
      blogLink: document.getElementById("inputPassword4_blog").value,
      tiktokLink: document.getElementById("inputEmail4_tiktok").value,
    };

    let newErrors = {};

    // Validate Instagram Link
    if (formData.instaLink) {
      if (!instagramRegex.test(formData.instaLink)) {
        newErrors.instaLink = "Invalid Instagram link";
      }
    }

    // Validate Facebook Link
    if (formData.fbLink) {
      if (!facebookRegex.test(formData.fbLink)) {
        newErrors.fbLink = "Invalid Facebook link";
      }
    }

    // Validate Youtube Link
    if (formData.youtubeLink) {
      if (!youtubeRegex.test(formData.youtubeLink)) {
        newErrors.youtubeLink = "Invalid Youtube link";
      }
    }

    // Validate Twitter Link
    if (formData.twitterLink) {
      if (!twitterRegex.test(formData.twitterLink)) {
        newErrors.twitterLink = "Invalid Twitter link";
      }
    }

    // Validate Website Link
    if (formData.websiteLink) {
      if (!websiteRegex.test(formData.websiteLink)) {
        newErrors.websiteLink = "Invalid Website link";
      }
    }

    // Validate Blog Link
    if (formData.blogLink) {
      if (!blogRegex.test(formData.blogLink)) {
        newErrors.blogLink = "Invalid Blog link";
      }
    }

    // Validate Tiktok Link
    if (formData.tiktokLink) {
      if (!tiktokRegex.test(formData.tiktokLink)) {
        newErrors.tiktokLink = "Invalid Tiktok link";
      }
    }

    // Check if there are any validation errors
    if (Object.keys(newErrors).length === 0) {
      if (count < 2) {
        setCount((prevCount) => prevCount + 1);
      }
      // Clear form errors if there are no errors
      setFormErrorLink({});
    } else {
      // console.log("newErr",newErrors);
      setFormErrorLink(newErrors);
    }
  };

  const handleFormStage1 = (e) => {
    e.preventDefault();
    const errors = {};

    if (!user.fullName) {
      errors.fullName = "Full Name is required.";
    } else {
      if (!/^[A-Za-z ]+$/.test(user.fullName)) {
        errors.fullName = "Please enter a valid Name";
      }
    }
    if (!user.emailId) {
      errors.emailId = "Email Id is required.";
    }
    // else {
    //   if(!(user.emailId).email){
    //     errors.emailId = "Invalid EmailId";
    //   }
    // }
    if (!user.contactNo) {
      errors.contactNo = "Contact No is required.";
    } else {
      // Validate if contactNo contains only numeric values
      if (!/^[+\d]+$/.test(user.contactNo)) {
        errors.contactNo = "Invalid Contact No.";
      }
    }
    if (!user.dateOfBirth) {
      errors.dateOfBirth = "Date of Birth is required.";
    }

    if (!user.university) {
      errors.university = "University is required.";
    }

    // if (!enteredOTP) {
    //   errors.otpVerification = "Please Enter your OTP that is received after clicking on Verify";
    // }else{
    //   if (enteredOTP!=responseOTP){
    //   errors.otpVerification = "Please Enter correct OTP";
    //   }
    // }

    if (Object.keys(errors).length === 0) {
      if (count < 2) {
        setCount((prevCount) => prevCount + 1);
      }
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }
  };

  const backStageOne = () => {
    setUser(user); // Reset user to the original data
    // console.log("user",user)
    // console.log("user1",{ ...originalUser })

    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  // const backStageOne = () => {
  //   setUser({ ...originalUser }); // Reset user to the original data
  //   setStageTwo(false); // Set Stage 2 to false to navigate back to Stage 1
  // };

  const [countries, setCountries] = useState([{}]);
  const [cities, setCities] = useState([{}]);

  useEffect(() => {
    countryAPI();
  }, []);

  useEffect(() => {
    console.log("selectedCountryId", selectedCountryId);
  }, [selectedCountryId]);

  // useEffect(()=>{
  //   CityAPI()
  // }, [selectedCityId.countryId])

  const countryAPI = () => {
    RegionService.countryAccordingToRegionList().then((response) => {
      setCountries(response.data);
    });
  };
  // const CityAPI = () => {
  //   if (selectedCountry) {
  //     CityService.city(selectedCountry.countryId).then((response) => {
  //       setCities(response.data);

  //     });
  //   }
  // };

  const [selectedCountry, setSelectedCountry] = useState(null);

  // const handleCountryChange = (event) => {
  //   const selectedCountryId = parseInt(event.target.value);
  //   const selectedCountry = countries.find(country => country.countryId === selectedCountryId);
  //   setSelectedCountry(selectedCountry);
  //   if (selectedCountry) {
  //     CityService.city(selectedCountry.countryId).then((response) => {
  //       setCities(response.data);
  //     });
  //   }
  // };
  const handleContactNumberChange = (event) => {
    setVerification({
      contactNo: event.target.value,
      userId: localStorage.getItem("userId"),
    });
  };
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState();
  const [otpSentMessage, setOtpSentMessage] = useState();

  const verifyContact = async (event) => {
    event.preventDefault();
    setMessage("");
    AuthenticationService.setupAxiosInterceptors();
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      const user = jwtDecode(token);
      const exp = user.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      await ReferralModuleService.verifyContact(verification)
        .then((response) => {
          if (response.status === 200) {
            setOtpSentMessage("OTP sent successfully");
            setResponseOTP(response.data);
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                setMessage(error.response.data.failedReason);
                break;
              case 404:
              case 406:
                setFormError();
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setMessage(error.response.data.failedReason);
                break;
              default:
                setMessage("Unknown Error");
                break;
            }
          }
        });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };

  const addAffiliateUserApi = async (e) => {
    e.preventDefault();
    // AuthenticationService.setupAxiosInterceptors();

    const errors = {};

    if (!user.flatNo) {
      errors.flatNo = "Flat No is required.";
    }
    if (!user.address1) {
      errors.address1 = "Address 1 is required.";
    }
    if (!user.address2) {
      errors.address2 = "Address 2 is required.";
    }
    if (!user.pinCode) {
      errors.pinCode = "Zipcode is required.";
    }
    if (!user.city) {
      errors.city = "City is required.";
    }
    if (!selectedCountryId) {
      errors.country = "Country is required.";
    }

    if (Object.keys(errors).length === 0) {
      // Proceed with form submission
      addAfiliateUser();
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }
  };

  const addAfiliateUser = async () => {
    if (localStorage.getItem("token")) {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      const curUser = jwtDecode(token);
      const exp = curUser.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (exp < currentTime) {
        localStorage.clear();
        history.push("/");
      }
      await ReferralModuleService.addAfiliateUser(userId, user)
        .then((response) => {
          if (response.status === 200) {
            setFormError();
            localStorage.setItem("affiliateUserId", response.data);
            history.push("/Dashboard");
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setMessage("Network Error");
          } else {
            switch (error.response ? error.response.status : "") {
              case 401:
                setMessage(error.response.data.failedReason);
                break;
              case 403:
                setMessage(error.response.data.failedReason);
                break;
              case 500:
                setMessage(error.response.data.failedReason);
                break;
              case 404:
              case 406:
                setFormError();
                setMessage(error.response.data.failedReason);
                break;
              case 412:
                setMessage(error.response.data.failedReason);
                break;
              default:
                setMessage("Unknown Error");
                break;
            }
          }
        });
    } else {
      localStorage.clear();
      history.push("/");
    }
  };
  useEffect(() => {
    if (count == 0) {
      setStageOne(true);
      setStageTwo(false);
      setStageThree(false);
      setProgressDotOne(true);
      setProgressDotTwo(false);
      setProgressDotThree(false);
    } else if (count == 1) {
      setStageOne(false);
      setStageTwo(true);
      setStageThree(false);
      setProgressDotOne(true);
      setProgressDotTwo(true);
      setProgressDotThree(false);
    } else if (count == 2) {
      setStageOne(false);
      setStageTwo(false);
      setStageThree(true);
      setProgressDotOne(true);
      setProgressDotTwo(true);
      setProgressDotThree(true);
    } else {
    }
  }, [count]);
  const [showLogin, setShowLoginPopUp] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const joinNowClick = () => {
    // Check user verification status in local storage
    const affId = localStorage.getItem("affiliateUserId");
    if ((affId && affId === "0") || !affId) {
      const email = localStorage.getItem("emailId");
      if (!email || email === "" || email === undefined || email === null) {
        setShowLoginPopUp(true);
      } else if (email || email !== "" || email !== undefined) {
        // if (!userVerificationStatus || userVerificationStatus === "0") {
        if (localStorage.getItem("token")) {
          const token = localStorage.getItem("token");
          const user = jwtDecode(token);
          const exp = user.exp;
          const currentTime = Math.floor(Date.now() / 1000);
          if (exp < currentTime) {
            localStorage.clear();
            history.push("/");
          }
          UserVarificationService.getVerificationStatus(
            localStorage.getItem("userId")
          ).then((response) => {
            if (response.status == 200) {
              // localStorage.setItem("userVerificationStatus", response.data);
              const userVerificationStatus = response.data;
              if (userVerificationStatus == 1) {
                const token = localStorage.getItem("token");
                const user = jwtDecode(token);
                const exp = user.exp;
                const currentTime = Math.floor(Date.now() / 1000);
                if (exp < currentTime) {
                  localStorage.clear();
                  history.push("/");
                }
                DashboardDetailsService.dashboardDetailService(
                  localStorage.getItem("userId")
                ).then((response) => {
                  if (response.status === 200) {
                    localStorage.setItem(
                      "referralCode",
                      response.data.referralCode
                    );
                    setShowForm(true);
                  }
                });
              } else {
                // Show popup for unverified user
                setShowVerifyPopup(true);
              }
              // console.log("User is not verified. Show popup.");
              // }
              if (userVerificationStatus || userVerificationStatus === "1") {
                // User is verified
                // console.log("User is verified.");
                setShowForm(true);
              }
            }
          });
        } else {
          localStorage.clear();
          history.push("/");
        }
      }
      // console.log("logPop11", showLogin);
      // console.log("logPopemail", email);
    } else if (affId && affId !== 0) {
      history.push("/dashboard");
    }
    // console.log("affId", localStorage.getItem("affiliateUserId"));
  };

  const updateLoginPopupStatus = () => {
    setShowLoginPopUp(false);
  };
  const updateVarificationPopupStatus = () => {
    setShowVerifyPopup(false);
  };

  const loginClicked = () => {
    history.push(`/login`);
  };

  const resendClicked = () => {
    const revarification = localStorage.getItem("emailId");
    UserVarificationService.resendVerificationLink(revarification).then(
      (response) => {
        if (response.status == 200) {
          setShowVerifyPopup(false);
          // console.log("res1", response.data);
        }
        // console.log("status", response.status);
      }
    );
  };

  const LoginCtaBtn = () => {
    return (
      <button
        className="btn font-weight-bold text-uppercase btn-primary btn-md mt-2 mb-1"
        onClick={loginClicked}
      >
        LOGIN
      </button>
    );
  };

  const ResendEmailBtn = () => {
    return (
      <button
        className="btn font-weight-bold text-uppercase btn-primary btn-md mt-2 mb-1"
        onClick={resendClicked}
      >
        RESEND EMAIL
      </button>
    );
  };

  const RewardInfo = ({ info }) => {
    return (
      <div
        style={{ borderRadius: "10px" }}
        className="reward-info my-3 cards-bg p-2"
      >
        <div className="d-flex justtify-content-center align-items-center">
          <img src={star} alt="star" />
        </div>
        <div className="d-flex justtify-content-start align-items-center ml-2">
          <p className="m-0">{info}</p>
        </div>
      </div>
    );
  };
  const earnLearnContent = [
    {
      id: 0,
      title: "Why Choose Our “Earn While You Learn” Program?",
      desc: "Our program offers industry-leading rewards, high-quality eSIM, competitive data rate, real time tracking and dedicated support to help you succeed.",
    },
    {
      id: 1,
      title: "Who Can Benefit?",
      desc: "If you have a strong online presence, whether through a blog, social media, or website, you can capitalise on our program and earn substantial Rewards.",
    },
    {
      id: 2,
      title: "How to Get Started?",
      desc: "Joining our program is quick and simple. Just register on the program, receive your unique code, and start promoting. Begin earning Rewards immediately!",
    },
  ];

  const scrollToReferralRewardSection = () => {
    const referralRewardSection = document.querySelector(
      ".referral-profile-form"
    );
    if (referralRewardSection) {
      referralRewardSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // google analytics

  //  useEffect(() => {
  //   document.querySelector('title').classList.add('notranslate');
  //   document.title = "Referral Form";
  // }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  const meta = {
    title: "Best eSIM Deals & Affiliate Program | eSIM Mobile Referral",
    description:
      "Explore eSIM Mobile for the best eSIM deals, free trials, and our affiliate program. Refer and earn with eSIM Mobile’s referral commissions!",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Best eSIM deals, eSIM affiliate program, Refer and earn eSIM, eSIM referral commission, eSIM free trial",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="referral-module-section">
        <header>
          <Header />
        </header>

        <section
          style={{ position: "relative", zIndex: "1" }}
          className="container my-4"
        >
          <div className="my-3 p-0 earn-learn-section cards-bg">
            <div className="row m-0">
              <div
                style={{ position: "relative" }}
                className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center p-0"
              >
                <h1 className="earn-learn-content-heading mt-4 text-center px-2">
                  Earn While You Learn
                </h1>
                <p className="earn-learn-content-para text-center px-2">
                  Explore eSIM Potential with AI-Enhanced Sales
                </p>
                <div className="earn-learn-hero-img"></div>
                {/* <div className="earn-mob-learn-footer d-flex justify-content-center align-items-center">
                <FiInfo />
                <div className="d-flex justify-content-center align-items-center">
                  <span className="ml-2">learn more</span>
                  <FiArrowRight />
                </div>
              </div> */}
              </div>
              <div className="col-12 col-md-6 p-4 earn-learn-content-web">
                <h1>Earn While You Learn</h1>
                <p>Explore eSIM Potential with AI-Enhanced Sales</p>

                <div className="earn-learn-list">
                  {earnLearnContent.map((items, index) => (
                    <div
                      key={index}
                      className="list-1 d-flex justify-content-center align-items-start"
                    >
                      <div>
                        <img src={star} alt="star" />
                      </div>
                      <div className="px-2">
                        <h3>{items.title}</h3>
                        <p>{items.desc}</p>
                      </div>
                    </div>
                  ))}

                  <div className="d-flex mt-4 justify-content-between align-items-center w-100">
                    {/* <button
                    onClick={scrollToReferralRewardSection}
                    className="btn-block btn btn-custom-gradient rounded-pill shadow-sm text-uppercase"
                  >
                    Join Now
                  </button> */}

                    {/* <div className="earn-web-learn-footer d-flex justify-content-center align-items-center">
                    <FiInfo />
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="ml-2">learn more</span>
                      <FiArrowRight />
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="earn-learn-content-mob-carousel">
            <div style={{ position: "relative" }}>
              <div className="mob-carousel-section">
                <div className="carousel-wrapper">
                  <Carousel>
                    {earnLearnContent.map((items, index) => (
                      <div key={index} className="p-3  cards-bg">
                        <div
                          key={index}
                          className="list-1 d-flex justify-content-center align-items-start"
                        >
                          <div>
                            <img src={star} alt="star" />
                          </div>
                          <div className="px-2">
                            <h3>{items.title}</h3>
                            <p>{items.desc}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div className="earn-learn-mob-join-btn my-4 justify-content-center align-items-center">
              {/* <button
              onClick={scrollToReferralRewardSection}
              className="btn btn-custom-gradient btn-block rounded-pill shadow-sm text-uppercase"
            >
              Join Now
            </button> */}
            </div>
          </div>
        </section>

        <section className="referral-reward-section">
          <div className="container my-4 referral-reward-web">
            <h2 className="referral-reward-heading">
              Generous Rewards Structure
            </h2>
            <p className="referral-reward-para font-weight-bold">
              Earn attractive rewards for various actions:
            </p>
            <div className="row m-0">
              <div className="col-12 col-md-6 p-0">
                <RewardInfo
                  info={
                    "Rewards on each sale generated through your referral link."
                  }
                />
                <RewardInfo
                  info={"Additional bonuses for exceeding targets."}
                />
                <RewardInfo
                  info={"Monthly and Yearly best performer awards."}
                />
                <RewardInfo info={"Job opportunities and many more"} />
              </div>
              <div className="col-12 col-md-6">
                <RewardInfo
                  info={"Rewards on each successful signup referred by you."}
                />
                <RewardInfo
                  info={
                    "Regular updates on new product launches and promotions."
                  }
                />
                <RewardInfo
                  info={"Performance-based rewards and incentives."}
                />
                <RewardInfo info={"Free Data While travelling Abroad*"} />
              </div>
            </div>
          </div>

          <div className="referral-reward-mob container">
            <h2 className="referral-reward-heading">
              Generous Rewards Structure
            </h2>
            <p className="referral-reward-para font-weight-bold">
              Earn attractive rewards for various actions:
            </p>
            <div className="carousel-wrapper">
              <div className="row m-0">
                <Carousel>
                  <div className="col-12 col-md-6 p-0">
                    <RewardInfo
                      info={
                        "Rewards on each sale generated through your referral link."
                      }
                    />
                    <RewardInfo
                      info={"Additional bonuses for exceeding targets."}
                    />
                    <RewardInfo
                      info={"Monthly and Yearly best performer awards."}
                    />
                    <RewardInfo info={"Job opportunities and many more"} />
                  </div>
                  <div className="col-12 col-md-6">
                    <RewardInfo
                      info={
                        "Rewards on each successful signup referred by you."
                      }
                    />
                    <RewardInfo
                      info={
                        "Regular updates on new product launches and promotions."
                      }
                    />
                    <RewardInfo
                      info={"Performance-based rewards and incentives."}
                    />
                    <RewardInfo info={"Free Data While travelling Abroad*"} />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="referral-form-section my-lg-4 my-3">
          <div className="container d-flex justify-contnent-center align-items-start">
            <div>
              <img src={rocket} alt="rocket" />
            </div>
            <div>
              <h2>Ready to Take the Next Step?</h2>
              <p>
                Join our "Earn While You Learn" program today and start earning.
                Don't miss out on this opportunity to grow your income while
                promoting our top-notch product.
              </p>
            </div>
          </div>
          <div className="container d-flex mt-2 justify-content-center align-items-center w-100">
            <button
              onClick={joinNowClick}
              className="btn w-50 btn-custom-gradient rounded-pill shadow-sm text-uppercase"
            >
              Join Now
            </button>
          </div>
        </section>
        {showForm === true ? (
          <section className="referral-reward-section referral-profile-form">
            <div className="container">
              <div className="referral-progress-bar">
                <div
                  // onClick={() => {
                  //   setStageOne(true);
                  //   setStageTwo(false);
                  //   setStageThree(false);
                  // }}
                  style={{ position: "relative" }}
                >
                  {progressDotOne ? (
                    <img src={progressSuccess} alt="progressSuccess" />
                  ) : (
                    <img src={progressDot} alt="progressDot" />
                  )}
                  {stageOne ? (
                    <div className="stage-one-msg">Personal</div>
                  ) : null}
                </div>
                <div
                  className={`progress-line progress-line-1 ${
                    progressDotTwo ? "progress-line-success" : ""
                  }`}
                ></div>
                <div
                  // onClick={() => {
                  //   setStageOne(false);
                  //   setStageTwo(true);
                  //   setStageThree(false);
                  // }}
                  style={{ position: "relative" }}
                >
                  {progressDotTwo ? (
                    <img src={progressSuccess} alt="progressSuccess" />
                  ) : (
                    <img src={progressDot} alt="progressDot" />
                  )}
                  {stageTwo ? (
                    <div className="stage-two-msg">Social</div>
                  ) : null}
                </div>
                <div
                  className={`progress-line progress-line-2 ${
                    progressDotThree ? "progress-line-success" : ""
                  }`}
                ></div>
                <div
                  // onClick={() => {
                  //   setStageOne(false);
                  //   setStageTwo(false);
                  //   setStageThree(true);
                  // }}
                  style={{ position: "relative" }}
                >
                  {progressDotThree ? (
                    <img src={progressSuccess} alt="progressSuccess" />
                  ) : (
                    <img src={progressDot} alt="progressDot" />
                  )}
                  {stageThree ? (
                    <div className="stage-three-msg">Address</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="referral-form mt-4">
              {stageOne ? (
                <div className="form-stage-one">
                  <div className="container">
                    <h3>Personal Information</h3>
                  </div>
                  <div className="cards-bg p-3 container">
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Affiliate ID / Unique Referral Code
                          </label>
                          <input
                            readOnly
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder={`GKT-${localStorage.getItem(
                              "referralCode"
                            )}`}
                          />
                        </div>
                        <div className="form-group col-md-6 d-flex justify-content-center align-items-center m-0">
                          <div className="d-flex justify-content-start align-items-center">
                            <div>
                              <label
                                className="font-weight-bold m-0 mr-3"
                                for="share"
                              >
                                Share via
                              </label>
                            </div>
                            <div className="social-share-icons d-flex justify-content-center align-items-center">
                              <img className="mx-3" src={fbLogo} alt="fbLogo" />
                              <img
                                className="mx-3"
                                src={instaLogo}
                                alt="instaLogo"
                              />
                              <img
                                className="mx-3"
                                src={tweetLogo}
                                alt="tweetLogo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Full Name*</label>
                          <input
                            name="fullName"
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            value={user.fullName}
                            placeholder="Please enter your full name"
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrors.fullName && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.fullName}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">Email Id</label>
                          <input
                            name="emailId"
                            readOnly
                            type="email"
                            className="form-control"
                            id="inputPassword4"
                            placeholder={localStorage.getItem("emailId")}
                            value={user.emailId}
                          />
                          {formErrors.emailId && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.emailId}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 form-contact-input">
                          <label for="inputEmail4">Contact No*</label>{" "}
                          <div className="d-flex justify-content-center align-items-center">
                            <input
                              name="contactNo"
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Please enter your contact number"
                              value={user.contactNo}
                              // onChange={handleContactNumberChange}
                              onChange={(e) => {
                                dataChange(e);
                                handleContactNumberChange(e);
                              }}
                            />
                            {/* <button onClick={(event)=>verifyContact(event)} className="btn btn-success rounded-pill">
                          Verify
                        </button> */}
                          </div>
                          {formErrors.contactNo && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.contactNo}
                            </div>
                          )}
                          {/* {message && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {message}
                        </div>
                      )} */}
                          {/* {otpSentMessage && (
                        <div
                          className={`${
                            otpSentMessage ? "green" : ""
                          } invalid-feedback`}
                          style={{ display: "block" }}
                        >
                          {otpSentMessage}
                        </div>
                      )} */}
                        </div>
                        {/* <div className="form-group col-md-6">
                      <label for="inputPassword4">OTP Verification*</label>
                      <input
                        name="otpVerification"
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        placeholder="Enter your OTP"
                        value={enteredOTP}
                        onChange={(e) => setEnteredOTP(e.target.value)}
                      />
                      {formErrors.otpVerification && (
                      <div className={`invalid-feedback`} style={{display:"block"}}>{formErrors.otpVerification}</div>
                      )}
                    </div> */}
                      </div>
                      <div className="form-row">
                        <div className="form-group d-flex flex-column col-md-6">
                          <label for="inputEmail4">Date of Birthday*</label>
                          <div style={{ position: "relative" }}>
                            <DatePicker
                              name="dateOfBirth"
                              selected={
                                user.dateOfBirth === ""
                                  ? ""
                                  : new Date(user.dateOfBirth)
                              }
                              onChange={(e) => {
                                handleDateChange(e);
                              }}
                              dateFormat="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown
                              minDate={new Date(1900, 0, 1)} // Adjust the minimum date
                              maxDate={new Date()} // Set the maximum date to today
                              dropdownMode="select" // Use select mode for year and month dropdowns
                              className={`form-control ${
                                formErrors.dateOfBirth ? "is-invalid" : ""
                              }`}
                              placeholderText="Select your Date of Birth"
                            />

                            {/* <BiCalendar className="ref-calendar-icon"/> */}
                          </div>

                          {formErrors.dateOfBirth && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.dateOfBirth}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="inputPassword4">University*</label>
                          <input
                            name="university"
                            type="text"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="Enter your University name"
                            value={user.university}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrors.university && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.university}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-cta my-3 container">
                        <button
                          onClick={(e) => {
                            handleFormStage1(e);
                          }}
                          className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase"
                        >
                          Save & Proceed
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}

              {stageTwo ? (
                <div className="form-stage-one">
                  <div className="container">
                    <h3>
                      Add Social Media
                      {/* <span>(Add any 2 links)</span> */}
                    </h3>
                  </div>
                  <div className="cards-bg p-3 container">
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Instagram Link</label>
                          <input
                            name="instaLink"
                            type="text"
                            className={`form-control ${
                              formErrorLink.instaLink && "is-invalid"
                            }`}
                            id="inputEmail4_insta"
                            placeholder="Please enter the link"
                            value={user.instaLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.instaLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.instaLink}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">Facebook Link</label>
                          <input
                            name="fbLink"
                            type="text"
                            className="form-control"
                            id="inputPassword4_fbLink"
                            placeholder="Please enter the link"
                            value={user.fbLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.fbLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.fbLink}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Youtube Link</label>
                          <input
                            name="youtubeLink"
                            type="text"
                            className="form-control"
                            id="inputEmail4_youtube"
                            placeholder="Please enter the link"
                            value={user.youtubeLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.youtubeLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.youtubeLink}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">Twitter Link</label>
                          <input
                            name="twitterLink"
                            type="text"
                            className="form-control"
                            id="inputPassword4_twitter"
                            placeholder="Please enter the link"
                            value={user.twitterLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.twitterLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.twitterLink}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Website Link</label>
                          <input
                            name="websiteLink"
                            type="text"
                            className="form-control"
                            id="inputEmail4_website"
                            placeholder="Please enter the link"
                            value={user.websiteLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.websiteLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.websiteLink}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">Blog Link</label>
                          <input
                            name="blogLink"
                            type="text"
                            className="form-control"
                            id="inputPassword4_blog"
                            placeholder="Please enter the link"
                            value={user.blogLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.blogLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.blogLink}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Tiktok Link</label>
                          <input
                            name="tiktokLink"
                            type="text"
                            className="form-control"
                            id="inputEmail4_tiktok"
                            placeholder="Please enter the link"
                            value={user.tiktokLink}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrorLink.tiktokLink && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrorLink.tiktokLink}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6"></div>
                      </div>
                      <div className="form-cta my-3 container">
                        <button
                          onClick={backStageOne}
                          className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2"
                        >
                          Back
                        </button>
                        <button
                          onClick={(e) => {
                            handleForm(e);
                          }}
                          className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2"
                        >
                          Save & Proceed
                        </button>
                      </div>
                      {/* {formError && <p className="text-danger">{linkMessage}</p>} */}
                    </form>
                  </div>
                </div>
              ) : null}

              {stageThree ? (
                <div className="form-stage-one">
                  <div className="container">
                    <h3>Add Your Address</h3>
                  </div>
                  <div className="cards-bg p-3 container">
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label for="inputEmail4">Door / Flat no*</label>
                          <input
                            name="flatNo"
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Please enter"
                            value={user.flatNo}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrors.flatNo && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.flatNo}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-8">
                          <label for="inputPassword4">Address 1*</label>
                          <input
                            name="address1"
                            type="text"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="Please enter your address"
                            value={user.address1}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrors.address1 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.address1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label for="inputEmail4">Address 2*</label>
                        <input
                          name="address2"
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Please enter your address"
                          value={user.address2}
                          onChange={(e) => {
                            dataChange(e);
                          }}
                        />
                        {formErrors.address2 && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {formErrors.address2}
                          </div>
                        )}
                      </div>

                      <div className="form-row">
                        {/* {popuplarDestination.map(item => ( */}
                        <div className="form-group col-md-6">
                          <label for="inputState">Country *</label>
                          <select
                            id="inputState"
                            className="form-control"
                            // onChange={handleCountryChange}
                            value={selectedCountryId}
                            onChange={(e) =>
                              setSelectedCountryId(Number(e.target.value))
                            }
                          >
                            <option value="" selected>
                              Choose...
                            </option>
                            {countries.map((country) => (
                              <option
                                key={country.countryId}
                                value={country.countryId}
                              >
                                {country.countryDescription}
                              </option>
                            ))}
                          </select>
                          {formErrors.country && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.country}
                            </div>
                          )}
                        </div>
                        {/* ))} */}
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">State</label>
                          <input
                            name="state"
                            type="text"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="Please enter your state"
                            value={user.state}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">Zipcode/Postcode *</label>
                          <input
                            name="pinCode"
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Please enter your zipcode"
                            value={user.pinCode}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrors.pinCode && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.pinCode}
                            </div>
                          )}
                        </div>
                        <div
                          className="form-group col-md-6"
                          // key={item.cityId}
                        >
                          <label htmlFor="inputState">City *</label>
                          <input
                            name="city"
                            type="text"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="Please enter your city"
                            value={user.city}
                            onChange={(e) => {
                              dataChange(e);
                            }}
                          />
                          {formErrors.city && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {formErrors.city}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-cta my-3 container">
                        <button
                          onClick={backStageOne}
                          className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase mx-2"
                        >
                          Back
                        </button>

                        <button
                          onClick={(e) => {
                            addAffiliateUserApi(e);
                          }}
                          className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase"
                        >
                          Save & Proceed
                        </button>
                      </div>

                      {message && <p className="text-danger">{message}</p>}
                      <div className="px-4 mt-4 register-footer">
                        <p className="text-muted mb-4 text-center">
                          By Joining our program, you agree to our{" "}
                          <a
                            onClick={() => history.push("/termsandcondition")}
                            className="text-center"
                          >
                            Terms & Conditions
                          </a>{" "}
                          and <br />
                          <a
                            onClick={() => history.push("/privacypolicy")}
                            className="text-center"
                          >
                            Privacy Policy
                          </a>
                          . Rest assured, we'll keep your personal information
                          private and spam-free.
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
              {/* <div className="form-cta my-3 container">
            <button
              onClick={handleForm}
              className="btn btn-custom-gradient rounded-pill shadow-sm text-uppercase"
            >
              Save & Proceed
            </button>
          </div> */}
            </div>
          </section>
        ) : (
          ""
        )}
        <img
          className="ref-left-grad-1"
          src={refLeftGrad1}
          alt="refLeftGrad1"
        />
        <img
          className="ref-left-grad-2"
          src={refLeftGrad2}
          alt="refLeftGrad2"
        />
        <img
          className="ref-left-grad-3"
          src={refLeftGrad3}
          alt="refLeftGrad3"
        />
        <img
          className="ref-left-grad-4"
          src={refLeftGrad4}
          alt="refLeftGrad4"
        />

        <img
          className="ref-right-grad-1"
          src={refRightGrad1}
          alt="refRightGrad1"
        />
        <img
          className="ref-right-grad-3"
          src={refRightGrad3}
          alt="refRightGrad1"
        />
        <ContactUsForm />
        <footer>
          <Footer />
        </footer>
        {showVerifyPopup && (
          <AlertPopup
            status={"warning"}
            heading={"To proceed please verify your account"}
            content={"Verification link sent to your registered email address."}
            customFunction={<ResendEmailBtn />}
            updateState={updateVarificationPopupStatus}
          />
        )}
        {showLogin && (
          <AlertPopup
            status={"warning"}
            heading={"Login required to proceed"}
            content={"To gain full access please Login"}
            customFunction={<LoginCtaBtn />}
            updateState={updateLoginPopupStatus}
          />
        )}
      </div>
    </DocumentMeta>
  );
};

export default ReferralModule;
