import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import popupCheck from "../../assets/img/popup-check.png";
import popupCross from "../../assets/img/popup-cross.png";
import popupWarning from "../../assets/img/popup-warning.png";
import popupPassword from "../../assets/img/popup-password.png";

const AlertPopup = ({
  heading,
  content,
  customFunction,
  status,
  updateState,
  note,
}) => {
  return (
    <div className="AlertPopup">
      <div className="alert-container p-3">
        <div className="d-flex flex-column justify-content-start align-items-center">
          <div className="alert-popup-header mt-3">
            <img
              src={
                status == "success"
                  ? popupCheck
                  : status == "warning"
                  ? popupWarning
                  : status == "error"
                  ? popupCross
                  : status == "password"
                  ? popupPassword
                  : ""
              }
              alt="status"
            />
            <h2 className="ml-3">{heading}</h2>
          </div>
          {content && (
            <div className="alert-popup-body">
              <p className="mb-0 mt-2">{content}</p>
            </div>
          )}
          {note && (
            <div className="alert-popup-body d-flex mt-3 ">
              <span className="text-center font-weight-bold">{note}</span>
            </div>
          )}
          {customFunction && (
            <div className="alert-popup-cta mt-3 w-100">{customFunction}</div>
          )}
        </div>
        <IoIosClose className="verify-close-icon" onClick={updateState} />
      </div>
    </div>
  );
};

export default AlertPopup;

{
  /* <div className="AlertPopup">
      <div className="alert-container">
        <div className="d-flex flex-column justify-content-start align-items-center">
          <GoAlertFill />
          
          <h2>To proceed please verify your account</h2>
          <p>Verification link sent to your registered email address.</p>
          {
            customFunction
          }
          <button className="btn btn-primary btn-md mt-2 mb-1">
            RESEND EMAIL
          </button>
        </div>
        onClick={closeVerify}
        <GrFormClose className="verify-close-icon" />
      </div>
    </div> */
}
