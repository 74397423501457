import React, { useState, useEffect } from "react";
import OrderService from "../../Services/OrderService";
import { useHistory } from 'react-router-dom';
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../assets/img/gkt_final_logo.png"
import { jwtDecode } from 'jwt-decode';

const CountdownTimer = (props) => {
  const [seconds, setSeconds] = useState(60);
  const [shouldCallOrder, setShouldCallOrder] = useState(true);
  const [failedTimer, setFailedTimer] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const history = useHistory();
  let orderInterval;

  const orderAfterPayment = () => {
    if (!isApiCallInProgress) {
        OrderService.orderAfterPayment(props.match.params.orderId)
          .then((response) => {
            if (response.status === 200 && response.data.orderStatus.orderStatusId !== 1) {
              localStorage.setItem("orderSummaryAfterPayment", JSON.stringify(response.data));
              clearInterval(orderInterval);
              history.push('/OrderConfirmation');
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          })
          .finally(() => {
            setIsApiCallInProgress(false);
          });
    }
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(countdownInterval);
        setFailedTimer(true);
        setShouldCallOrder(false);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [seconds]);

  useEffect(() => {
    const startOrderInterval = () => {
      orderInterval = setInterval(() => {
        if (shouldCallOrder) {
          orderAfterPayment();
        } else {
          clearInterval(orderInterval);
        }
      }, 5000);
    };

    startOrderInterval();

    return () => {
      clearInterval(orderInterval);
    };
  }, [shouldCallOrder, isApiCallInProgress]);

  // console.log("isApiCallInProgress => ",isApiCallInProgress)

  const backPrompt = () => {
    var cf = window.confirm("Are you sure ?");
    if (cf == true) {
      history.push('/');
    }
  };

  const goToHome = () => {
    history.push('/');
  };

   // google analytics

   useEffect(() => {
     document.title = "After Payment Countdown Page";
     document.querySelector('title').classList.add('notranslate');
  }, []);

   useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once


  return (
    <div style={{ position: "relative" }} className="order-summary-section">
      <div className="container mt-5 d-flex flex-column justify-content-center align-items-center">
        <img style={{ margin: '20px auto', width: '80px' }} src={logo} alt="logo" />
        <Prompt
          when={1 === 1}
          message={(location, action) => {
            if (action === "POP") {
              backPrompt();
              return false;
            } else {
              return true;
            }
          }}
        />
        <div className="d-flex flex-column justify-content-center align-items-center">
          {!failedTimer ?
            <h3>Your Payment is processing...</h3> :
            <h3>Payment Failed!</h3>
          }
          <p>Kindly do not go back or refresh your page</p>
          <h1>{Math.floor(seconds / 60)}:{seconds % 60}</h1>
          <div>
            <h4 className="mb-3">Time Remaining</h4>
          </div>
          <div className="mt-4">
            <button disabled={failedTimer ? false : true} onClick={goToHome} className="btn btn-primary muted">
              Return to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
