import React from "react";
import BackButton from "./BackButton";
import { MdClose } from "react-icons/md";
import "../../../scss/kiosk.scss";

const CustomModal = ({
  isOpen,
  onClose,
  backButton,
  children,
  onBackClick,
  width,
  customPadding,
}) => {
  if (!isOpen) return null;

  return (
    <div
      style={{ paddingTop: customPadding ? customPadding : 360 }}
      className="kiosk-modal"
    >
      <div
        style={{ maxWidth: width == "normal" ? 1100 : width }}
        className="kiosk-modal-container mx-4"
      >
        <div className="w-100 d-flex justify-content-between align-items-center">
          {backButton ? <BackButton handleClick={onBackClick} /> : <div></div>}
          <MdClose className="cross-btn" onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
