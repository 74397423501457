import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { jwtDecode } from "jwt-decode";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { FormFeedback } from "reactstrap";
import * as Yup from "yup";
import LoginService from "../../Services/LoginService";
import loginBottomGrad from "../../assets/img/login-bottom-gradient.png";
import loginLeftGrad from "../../assets/img/login-left-gradient.png";
import AlertPopup from "../Alert_Popup/AlertPopup";
import Loader from "../Alert_Popup/Loader";
import ContactUsForm from "../Common/ContactUsForm";
import Header from "../Header/Header";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

let initialValues = {
  email: "",
  password: "",
};

const validationSchema = function (values) {
  return Yup.object().shape({
    email: Yup.string().required("Email is Required").email("Invalid Email"),
    // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/, ('Invalid Email Format')),

    password: Yup.string().required("Password is Required"),
  });
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const Login = (props) => {
  const { state } = useLocation();
  const popMessage = state && state.message;
  const history = useHistory();
  const [pMessaage, setPMessaage] = useState();
  const [popupMessage, setPopupMessage] = useState(null);
  const [message, setMessage] = useState();
  const registerEmail = state && state.email;
  const [registerEmailId, setRegisteredEmailId] = useState(registerEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: registerEmailId ? registerEmailId : "",
  });

  useEffect(() => {
    setRegisteredEmailId(registerEmail);

    const initialValues = {
      email: registerEmail || "",
      password: "",
    };
    setLogin((prevLogin) => ({
      ...prevLogin,
      ...initialValues,
    }));
  }, [registerEmail]);
  useEffect(() => {
    setPopupMessage(popMessage);
  }, [popMessage]);

  const touchAll = (setTouched, errors) => {
    setMessage("");
    setTouched({
      email: true,
      password: true,
    });
    validateForm(errors);
  };

  const validateForm = (errors) => {
    findFirstError("login", (fieldName) => {
      return Boolean(errors[fieldName]);
    });
  };

  const findFirstError = (formName, hasError) => {
    const form = document.forms[formName];
    for (let i = 0; i < form.length; i++) {
      if (hasError(form[i].name)) {
        form[i].focus();
        break;
      }
    }
  };

  const dataChange = (event) => {
    const { name, value } = event.target;
    setLogin((prevLogin) => ({
      ...prevLogin,
      [name]: value,
    }));
  };

  const loginClicked = (event) => {
    setIsLoading(true);
    LoginService.loginUser(login)
      .then((response) => {
        if (response.status == 200) {
          const token = response.data.access_token;
          localStorage.setItem("token", token);
          const user = jwtDecode(token).user;
          console.log(user);
          localStorage.setItem("emailId", user.email);
          localStorage.setItem("name", user.name);
          localStorage.setItem("countryName", user.countryName);
          // localStorage.setItem("userVerificationStatus", response.data.isVerified);
          localStorage.setItem("userId", user.id);
          localStorage.setItem("referralCode", user.referralCode);
          localStorage.setItem("affiliateUserId", user.affiliateUserId);

          setPMessaage("login successful");
          const message = pMessaage;

          history.push({
            pathname: "/",
            state: { message },
          });
          setIsLoading(false);
          // }
        } else {
          setPMessaage("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setMessage("Network Error");
          setIsLoading(false);
        } else {
          switch (error.response ? error.response.status : "") {
            case 401:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              // setErrorCode(401)
              break;
            case 403:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              break;
            case 500:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              break;
            case 404:
            case 406:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              break;
            case 412:
              setMessage(error.response.data.failedReason);
              setIsLoading(false);
              break;
            default:
              setMessage("Unknown Error");
              setIsLoading(false);
              break;
          }
        }
      });
  };
  const closeForgotPopup = () => {
    setMessage("");
  };

  const closeDisablePopup = () => {
    setMessage("");
  };

  const goToRegister = (e) => {
    e.preventDefault();
    history.push("/register");
  };

  const goToRegisterBtn = (e) => {
    e.preventDefault();
    const email = login.email;
    history.push({
      pathname: "/register",
      state: { email },
    });
    // history.push("/register");
  };
  const forgotClicked = (e) => {
    e.preventDefault();
    history.push("/forgotPassword");
  };
  const forgotClickedBtn = (e) => {
    e.preventDefault();
    const email = login.email;
    history.push({
      pathname: "/forgotPassword",
      state: { email },
    });
    // history.push("/forgotPassword");
  };
  const closePopup = () => {
    setPopupMessage(undefined);
  };
  useEffect(() => {
    const studentRegistered = sessionStorage.getItem("studentRegistered");
    if (studentRegistered) {
      setPopupMessage("Credentials Sent To Your Email");
      sessionStorage.removeItem("studentRegistered");
    }
    const targetElement = document.querySelector(".log-in-btn");
    if (targetElement) {
      targetElement.style.display = "none";
    }
  }, []);

  const ForgotPasswordBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={forgotClickedBtn}
      >
        FORGOT PASSWORD
      </button>
    );
  };

  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const RegisterBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={goToRegisterBtn}
      >
        REGISTER
      </button>
    );
  };
  const OkBtn = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={closePopup}
      >
        OK
      </button>
    );
  };

  const OkBtn2 = () => {
    return (
      <button
        className="btn rounded-pill font-weight-bold text-uppercase btn-outline-light custom-width-btn btn-md mt-2 mb-1"
        onClick={closeDisablePopup}
      >
        OK
      </button>
    );
  };

  // google analytics

  useEffect(() => {
    document.title = "Login Page";
    document.querySelector("title").classList.add("notranslate");
  }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-CNPPGN8KCG";

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CNPPGN8KCG');
    gtag('user_id', localStorage.getItem("ip"));

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div style={{ overflow: "hidden" }} className="container-fluid p-0">
      <header
        className="w-100"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Header />
      </header>

      <div className="row no-gutter">
        <div className="col-md-6 login-bg first-section pt-2 pt-md-4">
          <div className="login d-flex align-items-center py-5 mt-3 mt-md-0">
            <div className="container">
              {/* <div className="world-cup-mob-container">
                  <img style={{width: '100%'}} src={worldCupMob} alt="world-cup-mob" />
                  </div> */}
              <div className="row">
                <div className="col-lg-10 col-xl-10 mx-auto px-5">
                  <div className="d-md-none login-card text-white text-center p-3 mb-5">
                    <p className="m-0">
                      <span
                        style={{
                          color: "#E8BE61",
                          fontWeight: 600,
                          fontSize: 20,
                        }}
                      >
                        For International & UK Student’s
                      </span>
                      <br />
                      <b style={{ fontSize: 24, margin: "6 0" }}>
                        FREE eSIM & 2GB DATA
                      </b>
                      <br />
                      for use in the UK for your first month.
                    </p>
                    <button
                      style={{ fontSize: 16 }}
                      className="notranslate btn-custom-gradient carousel-cta-btn shadow-sm text-sm btn rounded-pill text-capitalize font-weight-normal mt-2 px-3"
                      onClick={() => history.push("/studentRegistration")}
                    >
                      <font>Join Now</font>
                    </button>
                  </div>

                  <h1 className="login-heading text-center">Welcome Back!</h1>
                  <p className="text-muted mb-2 text-center">
                    We are happy to have you back
                  </p>
                  <Formik
                    enableReinitialize={true}
                    initialValues={login}
                    validate={validate(validationSchema)}
                    validateOnChange={false}
                    validateOnBlur={false}
                    // onSubmit={registerClicked}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      loginClicked();
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      setTouched,
                      handleReset,
                    }) => (
                      <form
                        name="login"
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        noValidate
                        autocomplete="on"
                      >
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Email*</label>
                          </div>
                          <input
                            // autoComplete="email"
                            name="email"
                            valid={!errors.email}
                            invalid={touched.email && !!errors.email}
                            onChange={(e) => {
                              handleChange(e);
                              dataChange(e);
                            }}
                            onBlur={handleBlur}
                            value={login.email}
                            id="inputEmail"
                            type="email"
                            placeholder="Enter your email"
                            required=""
                            className={`notranslate form-control border-0 shadow-sm custom-input ${
                              errors.email === "" || errors.email === undefined
                                ? ""
                                : "input-error"
                            }`}
                          />
                          <FormFeedback
                            style={{
                              display:
                                errors.email === "" ||
                                errors.email === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {errors.email}
                          </FormFeedback>
                        </div>
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Password*</label>
                          </div>
                          <div style={{ position: "relative" }}>
                            <input
                              name="password"
                              valid={!errors.password}
                              invalid={touched.password && !!errors.password}
                              onChange={(e) => {
                                handleChange(e);
                                dataChange(e);
                              }}
                              onBlur={handleBlur}
                              value={login.password}
                              id="inputPassword"
                              type={!isVisible ? "password" : "text"}
                              placeholder="Enter your password"
                              required=""
                              className={`notranslate form-control border-0 shadow-sm custom-input ${
                                errors.password === "" ||
                                errors.password === undefined
                                  ? ""
                                  : "input-error"
                              }`}
                            />
                            <span className="password-icon" onClick={toggle}>
                              {isVisible ? <IoEyeOff /> : <IoEye />}
                            </span>
                          </div>
                          <FormFeedback
                            style={{
                              display:
                                errors.password === "" ||
                                errors.password === undefined
                                  ? "none"
                                  : "block",
                            }}
                            className="red"
                          >
                            {errors.password}
                          </FormFeedback>
                        </div>
                        <div className="custom-control ml-auto mb-3">
                          {/* <input
                        id="customCheck1"
                        type="checkbox"
                        checked
                        className="custom-control-input"
                      /> */}
                          <span onClick={forgotClicked}>
                            <a href="" className="pull-right">
                              Forgot password?
                            </a>
                          </span>
                          {/* <label for="customCheck1" className="custom-control-label">
                        Remember password
                      </label> */}
                        </div>
                        <button
                          onClick={() => touchAll(setTouched, errors)}
                          type="submit"
                          className="btn btn-custom-gradient btn-block text-uppercase mb-2 px-4 py-lg-2 shadow-sm"
                        >
                          Login
                        </button>
                        <p className="text-muted mb-4 text-center">
                          Not a member yet?{" "}
                          <span onClick={goToRegister}>
                            <a href="#">Register Now</a>
                          </span>
                        </p>
                      </form>
                    )}
                  />

                  {message === "No user found" ? (
                    <AlertPopup
                      status={"error"}
                      heading={"No user found"}
                      content={
                        "This email ID is not registered with us. Please ensure your email ID or click 'Register' below."
                      }
                      customFunction={<RegisterBtn />}
                      updateState={closeForgotPopup}
                    />
                  ) : (
                    ""
                  )}

                  {message === "Bad Credentials" ? (
                    <AlertPopup
                      status={"error"}
                      heading={"Bad Credentials"}
                      content={
                        "You have entered an incorrect password. Please ensure the correct password or click 'Forgot Password' below."
                      }
                      customFunction={<ForgotPasswordBtn />}
                      updateState={closeForgotPopup}
                    />
                  ) : (
                    ""
                  )}

                  {message === "User is disabled" ? (
                    <AlertPopup
                      status={"error"}
                      heading={"User is disabled"}
                      customFunction={<OkBtn2 />}
                      updateState={closeDisablePopup}
                    />
                  ) : (
                    ""
                  )}

                  {popupMessage != undefined ? (
                    <AlertPopup
                      heading={popupMessage}
                      content={
                        popupMessage === "Email Sent"
                          ? "Success! A password reset link has been sent to your registered email address. Please check your inbox and follow the link to create a new password."
                          : popupMessage === "Credentials Sent To Your Email"
                          ? "Credentials are sent to your registered email ID. Please use it to login."
                          : null
                      }
                      status={
                        popupMessage === "Email Sent" ||
                        popupMessage === "Password reset successfully" ||
                        popupMessage === "Credentials Sent To Your Email"
                          ? "success"
                          : popupMessage === "User is disabled"
                          ? "error"
                          : ""
                      }
                      updateState={closePopup}
                      customFunction={<OkBtn />}
                    />
                  ) : (
                    ""
                  )}
                  <div>
                    <FormFeedback
                      style={{
                        display:
                          message === "" || message === undefined
                            ? "none"
                            : "block",
                      }}
                      className="red"
                    >
                      {message}
                    </FormFeedback>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={loginLeftGrad} className="loginLeftGrad" alt="" />
          <img src={loginBottomGrad} className="loginBottomGrad" alt="" />
        </div>
        <div className="col-md-6 d-none d-md-flex flex-column login-bg-image">
          <div
            style={{ minHeight: "100%", padding: "85px 0 60px 0" }}
            className="w-100 d-flex flex-column justify-content-between align-items-center"
          >
            <div className="login-card text-white text-center p-3">
              <p className="m-0">
                <span
                  style={{ color: "#E8BE61", fontWeight: 600, fontSize: 20 }}
                >
                  For International & UK Student’s
                </span>
                <br />
                <b style={{ fontSize: 24, margin: "6 0" }}>
                  FREE eSIM & 2GB DATA
                </b>
                <br />
                for use in the UK for your first month.
              </p>
              <button
                style={{ fontSize: 16 }}
                className="btn-custom-gradient carousel-cta-btn shadow-sm text-sm btn rounded-pill text-capitalize font-weight-normal mt-2 px-3"
                onClick={() => history.push("/studentRegistration")}
              >
                <font>Join Now</font>
              </button>
            </div>
            <div className="login-card text-white text-center p-3">
              <p style={{ fontSize: 18 }} className="m-0">
                Enjoy a
                <b style={{ fontSize: 22, margin: "0 4px" }}> 20% discount </b>
                on your second month's plan. <br />
                Receive a
                <b style={{ fontSize: 22, margin: "0 4px" }}> 30% discount </b>
                on your third month's plan.
              </p>
            </div>
          </div>
          {/* <div className="world-cup-content">
            <div>
              <h3 className="mb-2 text-center">
                Secure Your
                <br />
                 2023 World Cup Ticket
              </h3>
              <h3 className="text-center">
                <span>

                JOIN LUCKY DRAW 
                </span>
                <br />
                Register & Verify Your Email by October 31st!
              </h3>
            </div>

            <div style={{marginBottom: '10px'}} className="mt-auto">
              <h1>REGISTER NOW!</h1>
              <p>
                <span style={{fontSize: '8.8px',fontWeight: '700'}}>
                2023 World Cup Ticket Lucky Draw T&C: </span>Register by Oct 31st to
                Enter! Unique Email Id is a must. The number of users signing up
                through your unique code will be considered. The number of
                conversion will be considered. Organisers have all rights
                regarding the final decision in this campaign.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <ContactUsForm />
      {isLoading === true && <Loader />}
    </div>
  );
};

export default Login;
