import axios from 'axios';
import { API_URL } from '../../../Common/Constant';
import React, { Component } from 'react';

class AddKioskUserService {
    
        addKioskUser(emailId, kioskId, lastLoginDate) {
            const headers = {
                'email': emailId,
                'kioskId': kioskId,
                'lastLoginDate': lastLoginDate
            };
    
            return axios.post(`${API_URL}/kiosk/addKioskUser`, {}, { headers });
        }

        getVerificationStatus(userId, kioskId, lastLoginDate) {
            const headers = {
                'kioskId': kioskId,
                'lastLoginDate': lastLoginDate
            };
            return axios.get(`${API_URL}/kiosk/user/${userId}/verificationstatus`, { headers });
        }
}



export default new AddKioskUserService()
