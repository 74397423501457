import axios from 'axios';
import { API_URL } from '../Common/Constant';
class HeaderService {

    

    esimIccids(userId) {
        return axios.get(`${API_URL}/webappapi/esim/user/${userId}`,{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}}, {
        });
        
    }
    
}
export default new HeaderService()