import axios from 'axios';
import { API_URL } from '../Common/Constant';
class GlobalPlansService {

    globalPlan() {
        return axios.get(`${API_URL}/webappapi/popularGlobalPlan`, {
        });
    }
    globalPremiumPlan() {
        return axios.get(`${API_URL}/webappapi/premiumGlobalPlan`, {
        });
    }
    globalCountryList() {
        return axios.get(`${API_URL}/webappapi/countryListForGlobalPlan`, {
        });
    }
    globalAllPlan() {
        return axios.get(`${API_URL}/webappapi/globalPlan`, {
        });
    }
}
export default new GlobalPlansService()