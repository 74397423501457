import axios from 'axios';
import { API_URL } from '../Common/Constant';
// import KioskSTWService from '../views/Kiosk/Services/KioskSTWService';
class KioskService {

    getActiveCampaign(kioskId) {
        return axios.get(`${API_URL}/kiosk/getActiveCampaign`, 
            { headers:{
                'kioskId': kioskId
                
            }});
        
    }
   
    
}
export default new KioskService()