import axios from 'axios';
import { API_URL } from '../Common/Constant';
class StudentService{

    async checkStudentExistsByEmailId(studentEmailId) {
        return await axios.get(`${API_URL}/webappapi/checkStudentExistsByEmailId`,{headers:{"emailId":studentEmailId}});
    }

    async getStudentUniversity() {
        return await axios.get(`${API_URL}/webappapi/studentUniversity`, {headers:{}});
    }

    async getStudentNetwork() {
        return await axios.get(`${API_URL}/webappapi/network`, {headers:{}});
    }

    async student(student) {
        return await axios.post(`${API_URL}/webappapi/student`, student, {headers:{}});
    }

    async getCompatibleDevice() {
        return await axios.get(`${API_URL}/webappapi/compatibleDevice`, {headers:{}});
    }
}
export default new StudentService()