import React, { useState, useEffect } from "react";
import "../../scss/Carousel.css";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import pauseBg from "../../assets/elements/pause_toggle.png";
import playBg from "../../assets/elements/play_toggle.png";
import stopBg from "../../assets/elements/stop_toggle.png";

const Carousel = ({ children, isVideoOff, isVideoOff2}) => {
  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(false);
  const content = children;
  const handleNext = () => {
    if (counter !== content.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  //console.log(counter);
  const handlePre = () => {
    if (counter !== 1) {
      setCounter(counter - 1);
    } else {
      setCounter(content.length);
    }
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      } else {
        clearInterval(interval);
      }
    }, 15000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (isVideoOff == true) {
      setPause(true);
    } else {
      setPause(false);
    }
  }, [isVideoOff]);

  useEffect(() => {
    if (isVideoOff2 == true) {
      setPause(true);
    } else {
      setPause(false);
    }
  }, [isVideoOff2]);
  return (
    <>
      <div style={{ position: "relative" }} className="slide notranslate">
        <img
          id="play-pause-toggle"
          className={`${pause ? "active" : ""} play-pause-toggle`}
          src={pause ? stopBg : pauseBg}
          alt=""
        />
        {content.map((item, index) => (
          <div
            className={counter - 1 === index ? "show" : "not-show"}
            key={index}
          >
            {item}
          </div>
        ))}
        <div className="slider-control carousel-controls">
          <TfiArrowCircleLeft className="prev" onClick={handlePre} />
          <div className="notation">
            <p className="m-0">
              {counter} of {content.length}
            </p>
          </div>
          <TfiArrowCircleRight className="next" onClick={handleNext} />
          <div
            className="carousel-play-pause-btn"
            onClick={() => setPause((prev) => !prev)}
          >
            {pause ? <IoIosPlay title="Play" /> : <IoIosPause title="Pause" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
